import { Button, Col, DatePicker, Row } from "antd";
import { FC, useCallback } from "react";
import { LeftOutlined, RightOutlined } from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import { setSelectedMonthAction } from "../../actions/schedulerExploreViewActions";
import ReduxStateModel from "../../models/ReduxStateModel";
import moment from "moment";
import DownloadMonthViewExcelButton from "./DownloadMonthViewExcelButton";

const MonthSelector: FC<MonthSelectorProps> = () => {
  const dispatch = useDispatch();
  const selectedMonth = useSelector((state: ReduxStateModel) => state?.schedulerExploreViewReducer?.selectedMonth ?? moment());
  const selectedEndMoment = useSelector(
    (state: ReduxStateModel) => state?.schedulerExploreViewReducer?.selectedEndDateMonthView ?? moment()
  );

  const onPrevMonthClicked = useCallback(() => {
    const prevMonth = selectedMonth.clone().subtract(1, "month");
    dispatch(setSelectedMonthAction(prevMonth, prevMonth.clone().add(1, "month")));
  }, [dispatch, selectedMonth]);

  const onNextMonthClicked = useCallback(() => {
    const nextMonth = selectedMonth.clone().add(1, "month");
    dispatch(setSelectedMonthAction(nextMonth, nextMonth.clone().add(1, "month")));
  }, [dispatch, selectedMonth]);

  const handleStartDateChanged = useCallback(
    (date: moment.Moment | null) => {
      if (!date) return;
      dispatch(setSelectedMonthAction(date, selectedEndMoment));
    },
    [dispatch, selectedEndMoment]
  );

  const handleEndDateChanged = useCallback(
    (date: moment.Moment | null) => {
      if (!date) return;
      dispatch(setSelectedMonthAction(selectedMonth, date));
    },
    [dispatch, selectedMonth]
  );

  return (
    <Row className="month-selector">
      <Col xs={24} md={22}>
        <Row
          style={{
            alignItems: "center",
            justifyContent: "space-around",
          }}
        >
          <Col>
            <Button className="prev-month-btn" onClick={onPrevMonthClicked}>
              <LeftOutlined />
            </Button>
          </Col>
          <Col className="date-pickers">
            <Row
              gutter={4}
              style={{
                alignItems: "center",
                justifyContent: "space-around",
              }}
            >
              <Col span={12} style={{ display: "flex", justifyContent: "center" }}>
                <DatePicker
                  value={selectedMonth}
                  onChange={handleStartDateChanged}
                  format="DD MMMM"
                  style={{
                    borderRadius: "20px",
                    borderColor: "rgb(238, 238, 238)",
                  }}
                />
              </Col>
              <Col span={12} style={{ display: "flex", justifyContent: "center" }}>
                <DatePicker
                  value={selectedEndMoment}
                  format="DD MMMM"
                  onChange={handleEndDateChanged}
                  style={{
                    borderRadius: "20px",
                    borderColor: "rgb(238, 238, 238)",
                  }}
                  disabledDate={(date) => {
                    return date.isSameOrBefore(selectedMonth);
                  }}
                />
              </Col>
            </Row>
          </Col>
          <Col>
            <Button className="next-month-btn" onClick={onNextMonthClicked}>
              <RightOutlined />
            </Button>
          </Col>
        </Row>
      </Col>
      <Col md={2} xs={0}>
        <DownloadMonthViewExcelButton />
      </Col>
    </Row>
  );
};

interface MonthSelectorProps {}

export default MonthSelector;
