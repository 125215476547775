import { orderType } from "../types";
import { orderServices } from "../services";
import { showMessage } from "../components/common/notification";

export const orderAction = {
  ordersCount,
  orderResult,
  getIndividualOrder,
  updatedOrderData,
  singleOrder,
};

function ordersCount(partnerUUID) {
  return (dispatch) => {
    orderServices.ordersCount(partnerUUID).then((response) => {
      dispatch(success(response.data));
    });
  };

  function success(data) {
    return { type: orderType.GET_ORDER_DATA, data };
  }
}

function singleOrder(data, onSuccess) {
  return (dispatch) => {
    dispatch(request());
    dispatch(success(data));
    onSuccess();
  };

  function request() {
    return { type: orderType.SINGLE_ORDER_LOADER_TRUE };
  }
  function success(data) {
    return { type: orderType.GET_SINGLE_ORDER_DATA, data };
  }
}

function orderResult(updateObject, onSuccess, successMessage) {
  return () => {
    orderServices.orderResult(updateObject).then((response) => {
      response.data.success === 1
        ? showMessage("success", successMessage ?? "Report uploaded!")
        : showMessage("error", "Failed to upload the report, try again!");

      if (response?.data?.success === 1) {
        onSuccess(updateObject);
      }

      return response;
    });
  };
}

function getIndividualOrder(orderUUID, onSuccess) {
  return () => {
    orderServices.getIndividualOrder(orderUUID).then((res) => {
      if (onSuccess) {
        onSuccess(res);
      }
    });
  };
}

function updatedOrderData(data) {
  return (dispatch) => {
    dispatch(updateOrder(data));
  };

  function updateOrder(data) {
    return { type: orderType.GET_UPDATED_ORDER_DATA, data };
  }
}
