import UploadFilePayloadModel from "../../models/UploadFilePayloadModel";
import { Button, Upload } from "antd";
import { UploadChangeParam, UploadFile } from "antd/lib/upload";
import { FC } from "react";
import { BsPlus } from "react-icons/bs";
import { getBase64 } from "../../utils/utilFunctions";
import { ProfileImageCardProps } from "./ProfileImageCard";

const StaffMemberProfileImage: FC<StaffMemberProfileImageProps> = ({ setMember, staffMember }) => {
  const imageSize = "150px";

  const handleProfileImageChanged = async (e: UploadChangeParam<UploadFile<any>>) => {
    const base64 = await getBase64(e.file);
    const imageObj: UploadFilePayloadModel = {
      data: `data:${e.file.type};base64,${base64}`,
      fileName: e.file.name,
      type: e.file.type ?? "",
    };
    setMember((prevValue) => {
      const newValue = { ...prevValue };
      newValue.profileImageData = imageObj;
      return newValue;
    });
  };

  return (
    <div style={{ position: "relative" }}>
      <img
        style={{
          width: imageSize,
          height: imageSize,
          minWidth: imageSize,
          minHeight: imageSize,
          borderRadius: "50%",
          border: "1px solid #f1f1f1",
          objectFit: "cover",
        }}
        src={staffMember?.profileImageData?.data ?? staffMember?.profileImage ?? staffMember.profilePhoto}
        alt={`${staffMember?.firstName ?? ""} ${staffMember?.lastName ?? ""}`}
      />
      <div style={{ position: "absolute", border: "4px solid white", bottom: "0px", right: "0px", borderRadius: "50%" }}>
        <Upload
          name="profileImage"
          showUploadList={false}
          accept="image/*"
          beforeUpload={() => false}
          onChange={handleProfileImageChanged}
        >
          <Button
            icon={<BsPlus style={{ fontSize: "30px" }} />}
            style={{
              color: "white",
              border: "none",
              backgroundColor: "black",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
            shape="circle"
            type="ghost"
          />
        </Upload>
      </div>
    </div>
  );
};

interface StaffMemberProfileImageProps extends ProfileImageCardProps {}

export default StaffMemberProfileImage;
