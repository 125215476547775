import React, { FC, useCallback } from "react";
import { Box, Tabs, Tab, useTheme } from "@mui/material";
import { SiGoogleanalytics } from "react-icons/si";
import TopBarTabContent from "./TopBarTabContent";
import AllInboxRoundedIcon from "@mui/icons-material/AllInboxRounded";

const TopBarTabsMenu: FC<TopBarTabsMenuProps> = ({ value, onChange }) => {
  const theme = useTheme();
  // const location = useLocation();
  // const value = mapTopBarTabToIndex[location.pathname] ?? 0;

  // useEffect(() => {
  //   if (location.pathname === "/") {
  //     //   setTopBar((value) => ({ ...value, selectedTab: "bookings-board" }));
  //   } else if (location.pathname === "/revenue-tracker") {
  //     //   setTopBar((value) => ({ ...value, selectedTab: "revenue-tracker" }));
  //   } else if (location.pathname === "/driver-scheduler") {
  //     //   setTopBar((value) => ({ ...value, selectedTab: "driver-scheduler" }));
  //   } else if (location.pathname === "/team") {
  //     //   setTopBar((value) => ({ ...value, selectedTab: "staff" }));
  //   }
  // }, [location.pathname]);

  const handleChange = useCallback(
    (_: React.SyntheticEvent, newValue: number) => {
      // const tab = mapTopBarToTabName[newValue];
      // history.push(tab);
      onChange(newValue);
    },
    [onChange]
  );

  return (
    <Box sx={{ borderBottom: 1, borderColor: "#edeae9", backgroundColor: "white" }}>
      <Tabs
        value={value}
        onChange={handleChange}
        aria-label="Tab Bar Tabs Menu"
        TabIndicatorProps={{ style: { backgroundColor: theme.typography.body1.color } }}
        sx={{
          padding: "0px 24px",
          height: "28px",
          minHeight: "28px",
          "& .tab-actions-button": {
            display: "none",
          },
          "& .MuiTab-root": {
            color: theme.typography.caption.color,
            fill: theme.typography.caption.color,
            // fontSize: "14px",
            textTransform: "none",
            padding: "4px 8px",
            height: "28px",
            minHeight: "28px",
            borderTopRightRadius: "6px",
            borderTopLeftRadius: "6px",
            borderBottom: "2px solid white",
            "&:hover": {
              backgroundColor: "#f9f8f8",
              borderBottom: `2px solid #acacac`,
            },
          },
          "& .MuiTab-root.Mui-selected": {
            color: theme.typography.body1.color,
            fill: theme.typography.body1.color,
            "& .tab-actions-button": {
              display: "inline-flex",
              color: theme.typography.body1.color,
            },
          },
        }}
      >
        <Tab
          disableRipple={true}
          label={
            <TopBarTabContent
              icon={
                <AllInboxRoundedIcon style={{ fontSize: "14px" }} />
                // <svg
                //   style={{ width: "12px", height: "12px" }}
                //   aria-current="true"
                //   viewBox="0 0 24 24"
                //   aria-hidden="true"
                //   focusable="false"
                // >
                //   <path d="M21,2h-4h-2H9H7H3C1.3,2,0,3.3,0,5v10c0,1.7,1.3,3,3,3h4v1c0,1.7,1.3,3,3,3h4c1.7,0,3-1.3,3-3v-3h4c1.7,0,3-1.3,3-3V5  C24,3.3,22.7,2,21,2z M3,16c-0.6,0-1-0.4-1-1V5c0-0.6,0.4-1,1-1h4v12H3z M15,19c0,0.6-0.4,1-1,1h-4c-0.6,0-1-0.4-1-1v-0.9V4h6v12.2  V19z M22,13c0,0.6-0.4,1-1,1h-4V4h4c0.6,0,1,0.4,1,1V13z"></path>
                // </svg>
              }
              label={"Board"}
            />
          }
          {...a11yProps(0)}
        />
        <Tab
          disableRipple={true}
          label={
            <TopBarTabContent
              icon={
                <span style={{ fontSize: "13px" }}>
                  <SiGoogleanalytics />
                </span>
                // <svg
                //   style={{ width: "12px", height: "12px" }}
                //   aria-current="false"
                //   viewBox="0 0 24 24"
                //   aria-hidden="true"
                //   focusable="false"
                // >
                //   <path d="m6,10H2c-1.103,0-2-.897-2-2v-4c0-1.103.897-2,2-2h4c1.103,0,2,.897,2,2v4c0,1.103-.897,2-2,2ZM2,4v4h4.001v-4H2Zm4,18H2c-1.103,0-2-.897-2-2v-4c0-1.103.897-2,2-2h4c1.103,0,2,.897,2,2v4c0,1.103-.897,2-2,2Zm-4-6v4h4.001v-4H2ZM24,6c0-.552-.447-1-1-1h-12c-.552,0-1,.448-1,1s.448,1,1,1h12c.553,0,1-.448,1-1Zm0,12c0-.553-.447-1-1-1h-12c-.552,0-1,.447-1,1s.448,1,1,1h12c.553,0,1-.447,1-1Z"></path>
                // </svg>
              }
              label={"Revenue Tracker"}
            />
          }
          {...a11yProps(1)}
        />
        {/* <Tab
          disableRipple={true}
          label={
            <TopBarTabContent
              icon={
                <svg
                  style={{ width: "12px", height: "12px" }}
                  aria-current="false"
                  viewBox="0 0 24 24"
                  aria-hidden="true"
                  focusable="false"
                >
                  <path d="M7,14h3v2H7c-0.6,0-1,0.4-1,1v2c0,0.6,0.4,1,1,1h3v2H7c-1.7,0-3-1.3-3-3v-2C4,15.3,5.3,14,7,14z M24,17v2c0,1.7-1.3,3-3,3  h-7v1c0,0.6-0.4,1-1,1s-1-0.4-1-1V1c0-0.6,0.4-1,1-1s1,0.4,1,1v1h3c1.7,0,3,1.3,3,3v2c0,1.7-1.3,3-3,3h-3v4h7C22.7,14,24,15.3,24,17  z M14,8h3c0.6,0,1-0.4,1-1V5c0-0.6-0.4-1-1-1h-3V8z M22,17c0-0.6-0.4-1-1-1h-7v4h7c0.6,0,1-0.4,1-1V17z M0,5v2c0,1.7,1.3,3,3,3h7V8  H3C2.4,8,2,7.6,2,7V5c0-0.6,0.4-1,1-1h7V2H3C1.3,2,0,3.3,0,5z"></path>
                </svg>
              }
              label={"Driver Scheduler"}
            />
          }
          {...a11yProps(2)}
        /> */}
        {/* <Tab
          disableRipple={true}
          label={
            <TopBarTabContent
              icon={
                <svg
                  style={{ width: "12px", height: "12px" }}
                  aria-current="false"
                  viewBox="0 0 24 24"
                  aria-hidden="true"
                  focusable="false"
                >
                  <path d="M19.1,2H18V1c0-0.6-0.4-1-1-1s-1,0.4-1,1v1H8V1c0-0.6-0.4-1-1-1S6,0.4,6,1v1H4.9C2.2,2,0,4.2,0,6.9v12.1  C0,21.8,2.2,24,4.9,24h14.1c2.7,0,4.9-2.2,4.9-4.9V6.9C24,4.2,21.8,2,19.1,2z M4.9,4H6v1c0,0.6,0.4,1,1,1s1-0.4,1-1V4h8v1  c0,0.6,0.4,1,1,1s1-0.4,1-1V4h1.1C20.7,4,22,5.3,22,6.9V8H2V6.9C2,5.3,3.3,4,4.9,4z M19.1,22H4.9C3.3,22,2,20.7,2,19.1V10h20v9.1  C22,20.7,20.7,22,19.1,22z"></path>
                </svg>
              }
              label={"Team"}
            />
          }
          {...a11yProps(3)}
        /> */}
      </Tabs>
    </Box>
  );
};

interface TopBarTabsMenuProps {
  value: number;
  onChange: (v: number) => void;
}

function a11yProps(index: number) {
  return {
    id: `top-bar-tab-${index}`,
    "aria-controls": `top-bar-tab-${index}`,
  };
}

export default TopBarTabsMenu;
