import StaffMemberProfileImage from "./StaffMemberProfileImage";
import useCheckIfNurseIsAvailableForBookingDates from "../../customHooks/useCheckIfNurseIsAvailableForBookingDates";
import moment from "moment";
import { Divider, Modal, Spin, Switch } from "antd";
import { Dispatch, FC, SetStateAction, useState } from "react";
import { useSelector } from "react-redux";
import { StaffMemberModel } from "../../models/StaffMemberModel";
import { nurseService } from "../../services";
import { useMutation } from "@tanstack/react-query";
import { MdOutlineWorkspacePremium } from "react-icons/md";
import { qualificationMap } from "../../constants";
import Mixpanel from "../../utils/Mixpanel";
import DeleteNurseButton from "./DeleteNurseButton";
import { getMomentDaysBetweenDays } from "../SchdulerDetailView/getMomentDaysBetweenDays";
import ReduxStateModel from "../../models/ReduxStateModel";
import ProfessionalStatusIcon from "../NewTeamPage/ProfessionalStatusIcon";

const ProfileImageCard: FC<ProfileImageCardProps> = ({
  showActiveSwitch,
  staffMember,
  setMember,
  handleNurseUpdatedSuccessfully,
}) => {
  const [showDisabledNurseConfirmationModal, setShowDisableNurseConfirmationModal] = useState<boolean>(false);
  const partnerUUID = useSelector((state: ReduxStateModel) => state.authReducer?.setting?.partnerUUID);
  const authReducer = useSelector((state: ReduxStateModel) => state?.authReducer);

  const editNurseMutation = useMutation({ mutationFn: nurseService.editNurse });
  const changeActiveStatusMutation = useMutation({
    mutationFn: nurseService.changeNurseActiveStatus,
  });

  // const handleDisabledNurseConfirmed = () => {
  //   setShowDisableNurseConfirmationModal(false);
  //   setMember((prevValue) => {
  //     const newValue: StaffMemberModel = { ...prevValue };
  //     newValue.isActive = false;
  //     return newValue;
  //   });
  //   const updatedNurse: StaffMemberModel = {
  //     ...staffMember,
  //     isActive: false,
  //     // isDeleted: false,
  //     partnerUUID: partnerUUID,
  //     nurseUUID: staffMember?.nurseUUID,
  //   };
  //   Mixpanel.track("Disable nurse", {
  //     payload: updatedNurse,
  //     user: authReducer?.setting?.email,
  //   });
  //   changeActiveStatusMutation.mutateAsync({
  //     isActive: false,
  //     nurseUUID: staffMember?.nurseUUID ?? "",
  //   });
  //   handleNurseUpdatedSuccessfully(updatedNurse);
  // };

  const {
    data: nurseAvailabilityErrors,
    refetch: fetchCheckIfNurseIsAvailable,
    isFetching: checkingIfNurseIsAvailable,
  } = useCheckIfNurseIsAvailableForBookingDates({
    onlyCheckForBookings: true,
    selectedNurseUUID: staffMember?.nurseUUID,
    momentUserClickOnFromCalendar: moment(),
    startTime: moment("00:00", "HH:mm"),
    endTime: moment("23:59", "HH:mm"),
    editMode: false,
    isSingleDay: false,
    selectedDatesArray: getMomentDaysBetweenDays({
      start: moment(),
      end: moment().add(2, "month"),
    }),
    enableQuery: false,
  });

  const handleExcludeFromDriverScheduleChanged = async (value: boolean) => {
    const updatedNurse: StaffMemberModel = {
      ...staffMember,
      skipInScheduler: value,
      partnerUUID: partnerUUID,
      nurseUUID: staffMember?.nurseUUID,
      // isDeleted: false,
    };
    setMember((prevValue) => {
      const newValue: StaffMemberModel = { ...prevValue };
      newValue.skipInScheduler = value;
      return newValue;
    });
    await editNurseMutation.mutateAsync(updatedNurse);
    handleNurseUpdatedSuccessfully(updatedNurse);
  };

  const handleIsEnabledChanged = async (value: boolean) => {
    const updatedNurse: StaffMemberModel = {
      ...staffMember,
      isActive: value,
      partnerUUID: partnerUUID,
      nurseUUID: staffMember?.nurseUUID,
      // isDeleted: false,
    };
    if (value) {
      setMember((prevValue) => {
        const newValue: StaffMemberModel = { ...prevValue };
        newValue.isActive = value;
        return newValue;
      });
      await changeActiveStatusMutation.mutateAsync({
        isActive: value,
        nurseUUID: staffMember?.nurseUUID ?? "",
      });
      Mixpanel.track("Activate nurse", {
        payload: updatedNurse,
        user: authReducer?.setting?.email,
      });
      handleNurseUpdatedSuccessfully(updatedNurse);
    } else {
      const res = await fetchCheckIfNurseIsAvailable();
      if (res.data?.assignNurseErrors?.length) {
        setShowDisableNurseConfirmationModal(true);
      } else {
        setMember((prevValue) => {
          const newValue: StaffMemberModel = { ...prevValue };
          newValue.isActive = value;
          return newValue;
        });
        await changeActiveStatusMutation.mutateAsync({
          isActive: value,
          nurseUUID: staffMember?.nurseUUID ?? "",
        });
        Mixpanel.track("Disable nurse", {
          payload: updatedNurse,
          user: authReducer?.setting?.email,
        });
        handleNurseUpdatedSuccessfully(updatedNurse);
      }
    }
  };

  return (
    <div
      style={{
        width: "100%",
        minWidth: "100%",
        padding: "16px 0px",
        borderRadius: "5px",
        border: "1px solid #f1f1f1",
        backgroundColor: "white",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        // height: "fit-content",
      }}
    >
      <div style={{ marginBottom: "16px" }}>
        <StaffMemberProfileImage
          showActiveSwitch={showActiveSwitch}
          handleNurseUpdatedSuccessfully={handleNurseUpdatedSuccessfully}
          setMember={setMember}
          staffMember={staffMember}
        />
      </div>
      <div
        style={{
          fontSize: "16px",
          fontWeight: "500",
          color: "black",
          textAlign: "center",
        }}
      >
        {`${staffMember?.firstName ?? ""} ${staffMember?.lastName ?? ""}`}{" "}
        {staffMember.status && <ProfessionalStatusIcon status={staffMember.status} rejectReason={staffMember.rejectReason} />}
      </div>
      <div>{qualificationMap.get(staffMember.qualification ?? "") ?? ""}</div>
      <Divider style={{ margin: "16px 0px" }} />
      {showActiveSwitch && (
        <>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              width: "100%",
              padding: "0px 16px",
            }}
          >
            <div style={{ color: "black" }}>{staffMember.isActive ? "Active" : "Disabled"}</div>
            <Spin spinning={checkingIfNurseIsAvailable}>
              <Switch checked={staffMember?.isActive ?? false} onChange={handleIsEnabledChanged} />
            </Spin>
          </div>
          <Divider style={{ margin: "16px 0px" }} />
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              width: "100%",
              padding: "0px 16px",
            }}
          >
            <div style={{ color: "black" }}>Exclude from driver schedule</div>
            <div>
              <Switch checked={staffMember.skipInScheduler} onChange={handleExcludeFromDriverScheduleChanged} />
            </div>
          </div>
          <Divider style={{ margin: "16px 0px" }} />
          {staffMember.isPremium && (
            <>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  width: "100%",
                  padding: "0px 16px",
                }}
              >
                <div style={{ color: "black" }}>Premium Professional</div>
                <div>
                  <MdOutlineWorkspacePremium style={{ fontSize: "22px", color: "#ffc107" }} />
                </div>
              </div>
              <Divider style={{ margin: "16px 0px" }} />
            </>
          )}
          <DeleteNurseButton nurse={staffMember} />
          {/* <Divider style={{ margin: "16px 0px" }} /> */}
        </>
      )}
      <Modal
        open={showDisabledNurseConfirmationModal}
        // title={`Are you sure you would like to disable ${staffMember?.firstName ?? ""} ${staffMember?.lastName ?? ""} ?`}
        title={`${staffMember?.firstName ?? ""} ${staffMember?.lastName ?? ""} is assigned to other bookings`}
        okText="Ok"
        cancelText="Cancel"
        // onCancel={}
        onOk={() => setShowDisableNurseConfirmationModal(false)}
        okButtonProps={{ shape: "round" }}
        cancelButtonProps={{ shape: "round", style: { display: "none" } }}
      >
        <div style={{ display: "flex", flexDirection: "column", gap: "16px" }}>
          {nurseAvailabilityErrors?.assignNurseErrors?.map<JSX.Element>((errorMessage, index) => {
            return <div key={index}>{errorMessage}</div>;
          })}
          <div>
            <strong>Please assign those bookings to another professional first.</strong>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export interface ProfileImageCardProps {
  staffMember: StaffMemberModel;
  handleNurseUpdatedSuccessfully: (nurse: StaffMemberModel) => void;
  setMember: Dispatch<SetStateAction<StaffMemberModel>>;
  showActiveSwitch: boolean;
}

export default ProfileImageCard;
