import { useQuery } from "@tanstack/react-query";
import { getProductsForConsultation } from "../services";
import { useMemo } from "react";

export default function useProductsForConsultationQuery() {
  const queryKey = useMemo(() => ["/apps/get-products-for-consultation"], []);

  const query = useQuery(queryKey, getProductsForConsultation);

  return { query, queryKey };
}
