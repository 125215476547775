import { FC, useMemo, useState } from "react";
import { PartnerInventoryRequestModel } from "../../services/inventory";
import { Box, Button, Collapse, Divider, Table, TableBody, TableCell, TableHead, TableRow, Typography } from "@mui/material";
import { grey } from "@mui/material/colors";
import { showMessage } from "../../components/common/notification";
import ContentCopyRoundedIcon from "@mui/icons-material/ContentCopyRounded";
import MapRoundedIcon from "@mui/icons-material/MapRounded";
import useDeliveryOrderStatusText from "../../hooks/useDeliveryOrderStatusText";
import useAllPartnersQuery from "../../hooks/useAllPartnersQuery";
import usePartnerContactQuery from "../../hooks/usePartnerContactQuery";
import ArrowForwardIosRoundedIcon from "@mui/icons-material/ArrowForwardIosRounded";
import DeliveryPartnerIngredientListItem from "../../components/DeliveryPartnerOrdersList/DeliveryPartnerIngredientListItem";
import UploadDeliveryNoteButton from "../../components/DeliveryPartnerOrdersList/UploadDeliveryNoteButton";
import usePartnerAddressQuery from "../../hooks/usePartnerAddressQuery";
import useInventoryListQuery from "../../hooks/useInventoryListQuery";
import ViewPrescription from "./ViewPrescription";

const RevenueTableListItem: FC<RevenueTableListItemProps> = ({ order, isLast }) => {
  const [expand, setExpand] = useState(false);
  const { statusText } = useDeliveryOrderStatusText({ status: order.status ?? "ACCEPTED" });

  const { partnersIDMap } = useAllPartnersQuery();

  const partner = partnersIDMap.get(order?.partnerUUID ?? "");

  const { query: contactQuery } = usePartnerContactQuery({ contactUUID: order.partnerContactUUID });

  const { itemsMap } = useInventoryListQuery();

  const totalOrderPrice = useMemo<number>(() => {
    let value = 0;
    for (const item of order.inventoryRequirements ?? []) {
      const itemPrice = item.supplierPrice ?? itemsMap.get(item.name ?? "")?.supplierPrice ?? 0;
      value += itemPrice * (item.quantity ?? 0);
    }
    return value;
  }, [itemsMap, order.inventoryRequirements]);

  const { query: addressQuery } = usePartnerAddressQuery({ addressUUID: order.partnerAddressUUID });
  const address = addressQuery.data?.address;

  return (
    <>
      <Box borderBottom={isLast ? undefined : `2px solid ${grey[400]}`} paddingY={1} sx={{ position: "relative" }}>
        <Box
          sx={{ cursor: "pointer" }}
          onClick={() => {
            setExpand((value) => !value);
          }}
        >
          <Box display={"flex"} alignItems={"center"} gap={1}>
            <Typography fontWeight={500}>{order.orderUUID}</Typography>
            <Typography variant="overline">{statusText}</Typography>
          </Box>
          <Typography
            marginBottom={"6px"}
          >{`${contactQuery.data?.contact?.name} (${contactQuery.data?.contact?.phoneNumber})`}</Typography>
          <Typography variant="subtitle2" color={grey[700]}>
            {partner?.name ?? ""}
          </Typography>
        </Box>
        <ArrowForwardIosRoundedIcon
          sx={{
            color: grey[700],
            position: "absolute",
            top: "35%",
            right: "0px",
            transition: "transform 0.3s ease-out",
            transform: expand ? "rotate(-90deg)" : "rotate(0deg)",
          }}
        />
        <Collapse in={expand} timeout="auto" unmountOnExit>
          <Box sx={{ paddingX: 2, paddingTop: 2, marginBottom: 4 }}>
            <Divider sx={{ marginY: 1, marginRight: 4 }} />
            <Box marginBottom={2}>
              <Typography>Delivery Address:</Typography>
              <Typography fontWeight={500}>
                {`${address?.streetName} ${address?.buildingName ?? ""} ${address?.floorNumber ?? ""}`.trim()}
              </Typography>
              <Box display={"flex"} gap={2} width={"100%"} marginTop={2}>
                <a
                  href={`https://maps.google.com/?q=${address?.latitude},${address?.longitude}`}
                  target="_blank"
                  onClick={(e) => {
                    e.stopPropagation();
                  }}
                >
                  <Button variant="outlined" startIcon={<MapRoundedIcon />}>
                    View in maps
                  </Button>
                </a>
                <Button
                  startIcon={<ContentCopyRoundedIcon />}
                  variant="outlined"
                  onClick={(e) => {
                    e.stopPropagation();
                    navigator.clipboard.writeText(`https://maps.google.com/?q=${address?.latitude},${address?.longitude}`);
                    showMessage("success", "Location copied to clipboard");
                  }}
                >
                  Copy location
                </Button>
              </Box>
            </Box>
            <Divider sx={{ marginY: 1, marginRight: 4 }} />
            <ViewPrescription prescriptionURL={order.prescription ?? ""} />
            <Divider sx={{ marginY: 1, marginRight: 4 }} />
            <Typography variant="h6" gutterBottom component="div">
              Items
            </Typography>
            <Table size="small" aria-label="purchases">
              <TableHead>
                <TableRow>
                  <TableCell>Name</TableCell>
                  <TableCell align="center">Unite Price</TableCell>
                  <TableCell align="center">Quantity</TableCell>
                  <TableCell align="center">Total</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {order.inventoryRequirements?.map((item) => (
                  <DeliveryPartnerIngredientListItem key={item.name} item={item} />
                ))}
                <TableRow key={"total"}>
                  <TableCell align="center"></TableCell>
                  <TableCell align="center"></TableCell>
                  <TableCell align="center">Total:</TableCell>
                  <TableCell align="center" sx={{ fontWeight: 600 }}>
                    {`AED ${totalOrderPrice.toLocaleString()}`}
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
            <Box width="100%" display={"flex"} justifyContent={"flex-end"}>
              <UploadDeliveryNoteButton request={order} />
            </Box>
          </Box>
        </Collapse>
      </Box>
    </>
  );
};

interface RevenueTableListItemProps {
  order: PartnerInventoryRequestModel;
  isLast: boolean;
}

export default RevenueTableListItem;
