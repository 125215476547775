import moment from "moment";
import { FC, useMemo } from "react";
import { Divider, Popover } from "antd";
import SchedulerExploreViewResponseModel, {
  getBookingsNurseDatesMap,
  NurseBookingModel,
  NursesDatum,
} from "../../models/SchedulerExploreViewResponseModel";
import NurseTableLeadingCell from "./NurseTableLeadingCell";
import MonthViewTableCell from "./MonthViewTableCell";
import { useSelector } from "react-redux";
import ReduxStateModel from "../../models/ReduxStateModel";

const CalendarMonthView: FC<CalendarMonthViewProps> = ({ data }) => {
  const selectedMonth = useSelector((state: ReduxStateModel) => state?.schedulerExploreViewReducer?.selectedMonth ?? moment());
  const selectedEndDate = useSelector(
    (state: ReduxStateModel) => state?.schedulerExploreViewReducer?.selectedEndDateMonthView ?? moment()
  );
  const searchInputValue: string = useSelector(
    (state: ReduxStateModel) => state?.schedulerExploreViewReducer?.searchInputValue ?? ""
  );

  const daysOfMonth = useMemo<moment.Moment[]>(() => {
    const days: moment.Moment[] = [];
    const currentDate = selectedMonth.clone();
    while (currentDate.isBefore(selectedEndDate)) {
      days.push(currentDate.clone());
      currentDate.add(1, "day");
    }
    return days;
  }, [selectedEndDate, selectedMonth]);

  const nurseDateBookingsMap = useMemo<{ [prop: string]: NurseBookingModel[] }>(() => {
    return getBookingsNurseDatesMap(data.nursesData);
  }, [data.nursesData]);

  const filteredNursesData = useMemo<NursesDatum[] | undefined>(() => {
    if (searchInputValue.trim().length) {
      return data.nursesData?.filter((nurseData) => {
        return nurseData.name?.trim().toLocaleLowerCase().includes(searchInputValue.trim().toLocaleLowerCase());
      });
    }
    return data.nursesData;
  }, [data.nursesData, searchInputValue]);

  return (
    <div className="calendar-month-view dd-table explore-view-table">
      <div className="table-body" id="explore-view-table-body">
        <div className="table-row header">
          <div className="cell table-col-1">Professionals ({data.nursesData?.length})</div>
          {daysOfMonth.map<JSX.Element>((dayMoment) => {
            return (
              <div key={dayMoment.valueOf()} className="cell">
                {dayMoment.format("MMM DD")}
              </div>
            );
          })}
        </div>
        {filteredNursesData?.map<JSX.Element>((nurseData) => {
          // const firstDayOfWeek = firstDay.clone().add(weekNumber, "week");
          // const rowKey = `${startOfMonth.format("MMMM")}-${weekNumber}`;
          const totalHoursAssigned = nurseData.totalHoursAssigned ?? 0;
          const totalHoursServed = nurseData.totalHoursServed ?? 0;
          const key = `${nurseData.nurseUUID}_${selectedMonth.format()}`;
          return (
            <div key={key} className="table-row" id={nurseData.nurseUUID}>
              <Popover
                placement="rightTop"
                title={nurseData.name}
                content={
                  <div style={{ maxWidth: "250px", fontSize: "0.7rem" }}>
                    {totalHoursAssigned > 0 && (
                      <div>
                        Total Hours Assigned: <span style={{ fontSize: "0.9rem" }}>{nurseData.totalHoursAssigned}</span>
                      </div>
                    )}
                    {totalHoursServed > 0 && (
                      <div>
                        Total Hours Served: <span style={{ fontSize: "0.9rem" }}>{nurseData.totalHoursServed}</span>
                      </div>
                    )}
                    {(nurseData.services?.length ?? 0) > 0 && totalHoursAssigned > 0 && totalHoursServed > 0 && (
                      <Divider style={{ margin: "8px 0px" }} />
                    )}
                    {nurseData.services && nurseData.services.length && (
                      <>
                        <div>Services</div>
                        <div>{nurseData.services.join(", ")}</div>
                      </>
                    )}
                  </div>
                }
              >
                <div className="cell table-col-1" key={key}>
                  <NurseTableLeadingCell
                    nurseData={nurseData}
                    name={nurseData.name?.trim()?.split(" ")[0] ?? ""}
                    img={nurseData.profileImage ?? ""}
                  />
                </div>
              </Popover>
              {daysOfMonth.map((dayMoment) => {
                return (
                  <MonthViewTableCell
                    key={`${nurseData.nurseUUID}-${dayMoment.valueOf()}`}
                    cellDayMoment={dayMoment}
                    nurseData={nurseData}
                    cellBookings={nurseDateBookingsMap[`${nurseData.nurseUUID}_${dayMoment.format()}`]}
                  />
                );
              })}
            </div>
          );
        })}
      </div>
    </div>
  );
};

interface CalendarMonthViewProps {
  // startOfMonth: moment.Moment;
  data: SchedulerExploreViewResponseModel;
}

export default CalendarMonthView;
