export const sortType = {
  REQUEST_SORT: "REQUEST_SORT",
  REQUEST_SORT_DONE: "REQUEST_SORT_DONE",
  GET_SORTED_ORDERS: "GET_SORTED_ORDERS",
  SET_SORT_BODY: "SET_SORT_BODY",
  ACTIVATE_SORT: "ACTIVATE_SORT",
  CLEAR_SORT: "CLEAR_SORT",
  SORT_PAGE_NUMBER_INCREMENT: "SORT_PAGE_NUMBER_INCREMENT",
  SORT_PAGE_NUMBER_DECREMENT: "SORT_PAGE_NUMBER_DECREMENT",
};
