export const searchType = {
  REQUEST_SEARCH: "REQUEST_SEARCH",
  REQUEST_SEARCH_DONE: "REQUEST_SEARCH_DONE",
  SET_SEARCH: "SET_SEARCH",
  SET_SEARCH_BODY: "SET_SEARCH_BODY",
  SEARCH_DATA: "SEARCH_DATA",
  CLEAR_SEARCH: "CLEAR_SEARCH",
  SEARCH_PAGE_NUMBER_INCREMENT: "SEARCH_PAGE_NUMBER_INCREMENT",
  SEARCH_PAGE_NUMBER_DECREMENT: "SEARCH_PAGE_NUMBER_DECREMENT",
};
