import { FC } from "react";
import { MdWebAsset } from "react-icons/md";
import { Typography } from "@mui/material";
import { useSelector } from "react-redux";
import ReduxStateModel from "../../models/ReduxStateModel";

const TopBarLeading: FC<TopBarLeadingProps> = () => {
  const authReducer = useSelector((state: ReduxStateModel) => state.authReducer);

  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
      }}
    >
      <div
        style={{
          width: "48px",
          height: "48px",
          borderRadius: "12px",
          backgroundColor: "#a69ff3",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          marginRight: "16px",
        }}
      >
        <MdWebAsset style={{ fontSize: "30px" }} />
      </div>
      <Typography sx={{ marginRight: "8px" }} variant="h4">
        {authReducer?.setting?.name}
      </Typography>
    </div>
  );
};

interface TopBarLeadingProps {}

export default TopBarLeading;
