import { Typography } from "@mui/material";
import { FC } from "react";

const DetailViewLabelValueItem: FC<DetailViewLabelValueItemProps> = ({ label, value }) => {
  return (
    <div style={{ display: "flex", marginBottom: "16px" }}>
      <Typography variant="caption" sx={{ width: "150px" }}>
        {label}
      </Typography>
      <Typography variant="body2" sx={{ fontWeight: "500" }}>
        {value}
      </Typography>
    </div>
  );
};

interface DetailViewLabelValueItemProps {
  label: string;
  value: React.ReactNode;
}

export default DetailViewLabelValueItem;
