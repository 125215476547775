import { Form, Select } from "antd";
import { FC, useMemo } from "react";
import { StaffMemberModel } from "../../models/StaffMemberModel";
import useAllPartnerNurses from "../../hooks/useAllPartnerNurses";

const NurseAssignDriverFormItem: FC = () => {
  const { query } = useAllPartnerNurses();

  const driversList = useMemo<StaffMemberModel[]>(() => {
    return query.data?.data.drivers?.filter((driver: StaffMemberModel) => driver.isActive) ?? [];
  }, [query.data?.data.drivers]);

  return (
    <Form.Item label="Assign to a driver" name="skillNumber">
      <Select
        allowClear={true}
        options={driversList.map((driver: StaffMemberModel) => {
          return {
            label: driver.name ?? "",
            value: driver.skillNumber,
          };
        })}
      />
    </Form.Item>
  );
};

export default NurseAssignDriverFormItem;
