import { locationType } from "../types";

export const initialState = {
  allLocation: [],
  loader: false,
};

export function locationReducer(state = initialState, action) {
  switch (action.type) {
    case locationType.REQUEST_LOCATION:
      return {
        ...state,
        loader: true,
      };

    case locationType.GET_ALL_LOCATION:
      return {
        ...state,
        allLocation: action.data,
        loader: false,
      };

    default: {
      return {
        ...state,
      };
    }
  }
}
