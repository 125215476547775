import { FC } from "react";
import BookingModel from "../../../models/BookingModel";
import AssignNurseModalPatientCard from "./AssignNurseModalPatientCard";

const AssignNurseModalPatientsList: FC<AssignNurseModalPatientsListProps> = ({ order }) => {
  return (
    <>
      <p
        style={{
          color: "gray",
          marginBottom: "0.5rem",
        }}
      >
        {order?.patient?.length === 1 ? "Patient" : "Patients"}
      </p>
      <div
        style={{
          display: "flex",
          gap: "2rem",
          marginBottom: "1.5rem",
          flexWrap: "wrap",
        }}
      >
        {order?.patient?.map((patient) => {
          return <AssignNurseModalPatientCard patient={patient} key={patient._id} />;
        })}
      </div>
    </>
  );
};

interface AssignNurseModalPatientsListProps {
  order: BookingModel;
}

export default AssignNurseModalPatientsList;
