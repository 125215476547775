import { Box, Typography, useTheme } from "@mui/material";
import { FC, useCallback, useMemo } from "react";
import { useHistory } from "react-router-dom";
import { grey } from "@mui/material/colors";
import { authService, getOrderDetails } from "../services";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useSelector } from "react-redux";
import ReduxStateModel, { PartnerNotificationModel } from "../models/ReduxStateModel";
import useUserData from "../hooks/useUserData";
import moment from "moment";

const NotificationsDrawerListItem: FC<NotificationsDrawerListItemProps> = ({ notification }) => {
  const history = useHistory();
  const theme = useTheme();
  const partnerUUID = useSelector((state: ReduxStateModel) => state.authReducer?.setting?.partnerUUID);
  const queryClient = useQueryClient();
  const { queryKey } = useUserData({});

  const notificationMoment = useMemo(() => {
    return moment(notification.dateCreated);
  }, [notification.dateCreated]);

  const markAllReadMutation = useMutation({ mutationFn: authService.readNotification });

  const handleClick = useCallback(async () => {
    const orderRes = await getOrderDetails({ orderUUID: notification.orderUUID ?? "" });
    const res = await markAllReadMutation.mutateAsync({
      partnerUUID: partnerUUID ?? "",
      notificationUUIDArray: [notification.notificationUUID ?? ""],
    });
    if (res.data.success) {
      queryClient.invalidateQueries(queryKey);
    }
    history.push(`/order/${orderRes?.order?.orderUUID}`);
  }, [history, markAllReadMutation, notification.notificationUUID, notification.orderUUID, partnerUUID, queryClient, queryKey]);

  return (
    <Box
      key={notification.notificationUUID}
      onClick={handleClick}
      sx={{
        cursor: "pointer",
        padding: "0px 1rem",
        margin: "1rem 0px",
        borderLeft: notification.status === "UNREAD" ? `3px solid ${theme.palette.primary.main}` : undefined,
      }}
    >
      <Typography fontWeight={600} sx={{ color: "black", marginBottom: "8px" }} variant="body1">
        {notification.message}
      </Typography>
      <Typography variant="body2" sx={{ color: grey[600] }}>
        {notificationMoment.format("hh:mm A")}
      </Typography>
    </Box>
  );
};

interface NotificationsDrawerListItemProps {
  notification: PartnerNotificationModel;
}

export default NotificationsDrawerListItem;
