import BookingPopover from "./BookingPopover";
import { Popover } from "antd";
import { FC, useCallback, useMemo } from "react";
import { NurseBookingModel, NursesDatum } from "../../models/SchedulerExploreViewResponseModel";
import { useHistory, useRouteMatch } from "react-router-dom";

const MonthViewCellBookingTile: FC<MonthViewCellBookingTileProps> = ({ booking, nurseData, id }) => {
  const history = useHistory();
  const { url } = useRouteMatch();

  const spanTwoDaysBooking = useMemo(() => {
    const value = booking.endMoment.isAfter(booking.startMoment.clone().endOf("day"));
    return value;
  }, [booking.endMoment, booking.startMoment]);

  const handleBookingClicked = useCallback(() => {
    if (booking.type === "BREAK") {
      return history.replace(`${url}/view-break-time`, { booking: JSON.stringify(booking), nurseData: nurseData });
    }
    history.replace(`${url}/booking-details`, {
      booking: {
        orderUUID: booking.orderUUID,
        nurseOrderID: booking.nurseOrderID,
        nurseUUID: booking.nurseUUID,
        startMoment: booking.startMoment.valueOf(),
        endMoment: booking.endMoment.valueOf(),
        patients: booking.patients,
        nurseName: booking.nurseName,
        nurseProfileImg: booking.nurseProfileImg,
        service: booking.service,
        caseDescription: booking.caseDescription,
        nurseData: nurseData,
        locationLat: booking.locationLat,
        locationLng: booking.locationLng,
        address: booking.address,
      },
    });
  }, [booking, history, nurseData, url]);

  return (
    <Popover
      id={id}
      title={`${booking.startMoment.format(spanTwoDaysBooking ? "DD/MM hh:mm A" : "hh:mm A")} - ${booking.endMoment.format(
        spanTwoDaysBooking ? "DD/MM hh:mm A" : "hh:mm A"
      )}`}
      content={
        booking.type === "BOOKING" && (booking.patients?.length ?? 0) > 0 ? (
          <div>
            <div>{booking.patients?.length === 1 ? "PATIENT:" : "PATIENTS:"}</div>
            <BookingPopover patients={booking.patients ?? []} />
          </div>
        ) : (
          <div>Break</div>
        )
      }
    >
      <div
        style={{
          height: "10px",
          width: "100%",
          backgroundColor: booking.type === "BOOKING" ? "#25bcbd" : "#ffe4e1",
          cursor: "pointer",
        }}
        onClick={(e) => {
          e.stopPropagation();
          handleBookingClicked();
        }}
      />
    </Popover>
  );
};

interface MonthViewCellBookingTileProps {
  booking: NurseBookingModel;
  nurseData: NursesDatum;
  id: string;
}

export default MonthViewCellBookingTile;
