import moment from "moment";
import NurseOrderModel from "../../models/NurseOrderModel";
import AssignPatientsModalForm from "./AssignPatientsModalForm";
import SchedulerExploreViewResponseModel, {
  NurseBookingType,
  SlotPatientModel,
  TimeslotElementModel,
} from "../../models/SchedulerExploreViewResponseModel";
import { TemporaryAddressModel } from "../../services";

const staffSchedulerQueryUpdater: StaffSchedulerQueryUpdaterType = ({
  schedulerExploreViewData,
  formValues,
  nurseUUID,
  nurseOrders,
  googleMapsPlace,
  tempDropAddress,
  tempPickupAddress,
  location,
  type,
}) => {
  if (!schedulerExploreViewData) return schedulerExploreViewData;
  const newValue: SchedulerExploreViewResponseModel = JSON.parse(JSON.stringify(schedulerExploreViewData));
  const nurse = newValue.nursesData?.find((nurse) => nurse.nurseUUID === nurseUUID);
  if (!nurse?.data) return schedulerExploreViewData;
  for (const nurseOrder of nurseOrders ?? []) {
    const nurseOrderStartTimeMoment = moment(nurseOrder.startTime);
    const nurseDate = nurse.data.find((date) => date.date === nurseOrderStartTimeMoment.format("DD/MM/YYYY"));
    const endTime = moment(nurseOrder.endTime);
    const timeSlot = `${nurseOrderStartTimeMoment?.format("hh:mmA")} - ${endTime?.format("hh:mmA")}`;
    const patient: SlotPatientModel = { name: formValues.patients ?? "", caseDescription: formValues.caseDescription };
    const newTimeSlot: TimeslotElementModel = {
      addressDetails: formValues.addressDetails ?? "",
      temporaryDropOffAddress: tempDropAddress,
      temporaryPickupAddress: tempPickupAddress,
      timeslot: timeSlot,
      nurseOrderID: nurseOrder._id,
      patients: [patient],
      caseDescription: formValues.caseDescription,
      service: formValues.service,
      patientName: formValues.patients,
      address: `${googleMapsPlace?.name ?? ""} ${googleMapsPlace?.formatted_address ?? ""}`.trim(),
      locationLat: location?.lat().toString(),
      locationLng: location?.lng().toString(),
      type: type,
    };
    if (nurseDate) {
      nurseDate.timeslots.push(newTimeSlot);
    } else {
      nurse?.data?.push({
        date: nurseOrderStartTimeMoment.format("DD/MM/YYYY"),
        timeslots: [newTimeSlot],
      });
    }
  }
  return newValue;
};

export type StaffSchedulerQueryUpdaterParams = {
  schedulerExploreViewData: SchedulerExploreViewResponseModel | null | undefined;
  formValues: AssignPatientsModalForm;
  nurseUUID: string;
  nurseOrders?: NurseOrderModel[];
  googleMapsPlace?: google.maps.places.PlaceResult | undefined;
  tempDropAddress?: TemporaryAddressModel;
  tempPickupAddress?: TemporaryAddressModel;
  location?: google.maps.LatLng;
  type: NurseBookingType;
};

export type StaffSchedulerQueryUpdaterType = (
  params: StaffSchedulerQueryUpdaterParams
) => SchedulerExploreViewResponseModel | null | undefined;

export default staffSchedulerQueryUpdater;
