import { Accordion, AccordionDetails, AccordionSummary, Grid } from "@mui/material";
import { FC } from "react";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import BookingModel, { BookingPatientModel } from "../../models/BookingModel";
import PatientAssesmentFormItem from "./PatientAssesmentFormItem";

const PatientAssesmentFormAccordion: FC<PatientAssesmentFormAccordionProps> = ({ patient }) => {
  if (!patient?.programAssesment && !patient?.wlpProgramAssessment) return null;

  const programAssesment = patient.programAssesment ?? patient.wlpProgramAssessment;

  return (
    <Accordion style={{ marginTop: "1rem", width: "100%" }}>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <div style={{ fontWeight: "bold" }}>Assesment Form</div>
      </AccordionSummary>
      <AccordionDetails>
        <Grid container>
          <Grid xs={12} md={6}>
            <PatientAssesmentFormItem
              label={"Eligible"}
              value={
                <span style={{ color: programAssesment?.isEligible ? "green" : "red" }}>
                  {programAssesment?.isEligible ? "Yes" : "No"}
                </span>
              }
            />
          </Grid>
          <Grid xs={12} md={6}>
            <PatientAssesmentFormItem label={"Age"} value={`${programAssesment?.age} Years `} />
          </Grid>
          <Grid xs={12} md={6}>
            <PatientAssesmentFormItem label={"Bariatric Surgery"} value={programAssesment?.bariatricSurgery ? "Yes" : "No"} />
          </Grid>
          <Grid xs={12} md={6}>
            <PatientAssesmentFormItem
              label={"BMI"}
              value={programAssesment?.bmi?.toLocaleString(undefined, { maximumFractionDigits: 1 }) ?? ""}
            />
          </Grid>
          <Grid xs={12} md={6}>
            <PatientAssesmentFormItem label={"Gallbladder Disease"} value={programAssesment?.gallBladderDisease ? "Yes" : "No"} />
          </Grid>
          <Grid xs={12} md={6}>
            <PatientAssesmentFormItem label={"Gender"} value={programAssesment?.gender ?? ""} />
          </Grid>
          <Grid xs={12} md={6}>
            <PatientAssesmentFormItem label={"Allergies"} value={programAssesment?.hasAllergy ? "Yes" : "No"} />
          </Grid>
          <Grid xs={12} md={6}>
            <PatientAssesmentFormItem
              label={"Tried Weight Loss Programs Before"}
              value={programAssesment?.haveTriedWeightLossProgram ? "Yes" : "No"}
            />
          </Grid>
          <Grid xs={12} md={6}>
            <PatientAssesmentFormItem
              label={"Height"}
              value={`${programAssesment?.height?.toLocaleString(undefined, { maximumFractionDigits: 2 }) ?? ""} CM`}
            />
          </Grid>
          <Grid xs={12} md={6}>
            <PatientAssesmentFormItem
              label={"Anorexia Or Blulimia"}
              value={programAssesment?.isAnorexiaOrBulimia ? "Yes" : "No"}
            />
          </Grid>
          <Grid xs={12} md={6}>
            <PatientAssesmentFormItem label={"Breast Feeding"} value={programAssesment?.isBreastFeeding ? "Yes" : "No"} />
          </Grid>
          <Grid xs={12} md={6}>
            <PatientAssesmentFormItem
              label={"Diagnosed With Cancer"}
              value={programAssesment?.isDiagnosedWithCancer ? "Yes" : "No"}
            />
          </Grid>
          <Grid xs={12} md={6}>
            <PatientAssesmentFormItem
              label={"Diagnosed With Diabetes"}
              value={programAssesment?.isDiagnosedWithDiabetes ? "Yes" : "No"}
            />
          </Grid>
          <Grid xs={12} md={6}>
            <PatientAssesmentFormItem label={"High Cholestrol"} value={programAssesment?.isHighCholestrol ? "Yes" : "No"} />
          </Grid>
          <Grid xs={12} md={6}>
            <PatientAssesmentFormItem label={"Hyper Tension"} value={programAssesment?.isHyperTension ? "Yes" : "No"} />
          </Grid>
          <Grid xs={12} md={6}>
            <PatientAssesmentFormItem
              label={"Medullary Thyroid Cancer Or MEN Syndrome"}
              value={programAssesment?.isMedullaryThyroidCancerOrMENSyndrome ? "Yes" : "No"}
            />
          </Grid>
          <Grid xs={12} md={6}>
            <PatientAssesmentFormItem
              label={"Non Alcoholic Fatty Liver"}
              value={programAssesment?.isNonAlcoholicFattyLiver ? "Yes" : "No"}
            />
          </Grid>
          <Grid xs={12} md={6}>
            <PatientAssesmentFormItem
              label={"Obstructive Sleep Apnea"}
              value={programAssesment?.isObstructiveSleepApnea ? "Yes" : "No"}
            />
          </Grid>
          <Grid xs={12} md={6}>
            <PatientAssesmentFormItem label={"Pcos"} value={programAssesment?.isPcos ? "Yes" : "No"} />
          </Grid>
          <Grid xs={12} md={6}>
            <PatientAssesmentFormItem label={"Pregnant"} value={programAssesment?.isPregnant ? "Yes" : "No"} />
          </Grid>
          <Grid xs={12} md={6}>
            <PatientAssesmentFormItem label={"Thyroid Disorder"} value={programAssesment?.isThyroidDisorder ? "Yes" : "No"} />
          </Grid>
          <Grid xs={12} md={6}>
            <PatientAssesmentFormItem label={"Pancreatitis"} value={programAssesment?.pancreatitis ? "Yes" : "No"} />
          </Grid>
          <Grid xs={12} md={6}>
            <PatientAssesmentFormItem
              label={"Weight"}
              value={`${programAssesment?.weight?.toLocaleString(undefined, { maximumFractionDigits: 1 })} KG`}
            />
          </Grid>
          <Grid xs={12} md={6}>
            <PatientAssesmentFormItem
              label={"Have been trying to lose weight for"}
              value={`${programAssesment?.weightLoseTimePeriod ?? ""}`}
            />
          </Grid>
        </Grid>
      </AccordionDetails>
    </Accordion>
  );
};

interface PatientAssesmentFormAccordionProps {
  booking: BookingModel;
  patient: BookingPatientModel;
}

export default PatientAssesmentFormAccordion;
