import { Autocomplete, Box, Button, TextField } from "@mui/material";
import { FC, useMemo } from "react";

const UploadReportSelectTestsField: FC<UploadReportSelectTestsFieldProps> = ({
  options,
  value,
  didSelectAllTests,
  onChange,
  onSelectAllClicked,
}) => {
  const currentValue = useMemo(() => {
    return value?.map((v) => ({ label: v, id: v }));
  }, [value]);

  return (
    <Box
      sx={{
        gap: "1rem",
        justifyContent: "space-between",
        alignItems: "center",
        display: "flex",
        "@media (max-width: 700px)": {
          flexWrap: "wrap",
          "& .MuiButtonBase-root": {
            width: "100%",
          },
        },
      }}
    >
      <Autocomplete
        fullWidth={true}
        disablePortal={true}
        multiple={true}
        value={currentValue}
        disableCloseOnSelect={true}
        renderInput={(params) => <TextField {...params} label="Select Tests" />}
        isOptionEqualToValue={(option, value) => {
          return option.id === value.id;
        }}
        onChange={(_, values) => {
          onChange(values.map((v) => v.label));
        }}
        options={options.map((test) => {
          return { label: test, id: test };
        })}
      />
      <Button variant="outlined" sx={{ minWidth: "30px", width: "30%", height: "100%" }} onClick={onSelectAllClicked}>
        {didSelectAllTests ? "Unselect All" : "Select All"}
      </Button>
    </Box>
  );
};

interface UploadReportSelectTestsFieldProps {
  options: string[];
  value?: string[];
  didSelectAllTests: boolean;
  onSelectAllClicked: () => void;
  onChange: (values: string[]) => void;
}

export default UploadReportSelectTestsField;
