import { useMemo } from "react";
import { useParams } from "react-router-dom";
import useOrderDetails from "../../hooks/useOrderDetails";
import decrypt from "../../services/decrypt";
import StreamVideoCaller from "./StreamVideoCaller";
import useGetTokenForGetStream from "../../customHooks/useGetTokenForGetStream";
import "./styles.css";

const StreamVideoCall = () => {
  const { id: orderUUID, userUUID } = useParams<{
    id?: string;
    userUUID?: string;
  }>();

  const { query } = useOrderDetails({ orderUUID, refetchOnWindowFocus: false, cacheTime: Infinity });
  const booking = query.data?.order;

  const callId = useMemo(() => "csb-" + Math.random().toString(16).substring(2), []);

  const queryUserUUID = useMemo(
    () =>
      userUUID ? decrypt(userUUID?.toString().replaceAll("xMl3Jk", "+").replaceAll("Por21Ld", "/").replaceAll("Ml32", "=")) : "",
    [userUUID]
  );

  const { query: tokenQuery } = useGetTokenForGetStream({ userUUID: queryUserUUID });

  const token = useMemo(() => {
    const token = tokenQuery.data?.token;
    return token;
  }, [tokenQuery.data]);

  // useEffect(() => {
  //   const loom = document.getElementById("loom-widget");
  //   if (loom) loom.style.display = "none";
  //   return () => {
  //     if (loom) {
  //       loom.style.display = "block";
  //     }
  //   };
  // }, []);

  return booking?.isNormalVideoCall ? (
    token && userUUID && booking?.userUUID && (
      <StreamVideoCaller
        callId={callId}
        booking={booking}
        calleeIds={[booking.userUUID]}
        user_id={booking.assignedNurses?.[0].nurseUUID ?? ""}
        token={token}
      />
    )
  ) : (
    <div className="call-loading">Loading...</div>
  );
};

export default StreamVideoCall;
