import { Box, Typography } from "@mui/material";
import { grey } from "@mui/material/colors";
import { FC } from "react";

const RevenueHeaderItem: FC<RevenueHeaderItemProps> = ({ subtitle, title }) => {
  return (
    <Box padding={2} borderRight={"1px solid"} borderColor={"divider"} flexShrink={"0"}>
      <Typography marginBottom={1} color={grey[800]}>
        {title}
      </Typography>
      <Typography variant="h6">{subtitle}</Typography>
    </Box>
  );
};

interface RevenueHeaderItemProps {
  title: string;
  subtitle: string;
}

export default RevenueHeaderItem;
