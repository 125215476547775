import { LoadingButton } from "@mui/lab";
import { Button, InputAdornment, TextField } from "@mui/material";
import { FC, useState } from "react";
import { Link } from "react-router-dom";

const LoginForm: FC<LoginFormProps> = ({ handleFormSubmit, loadingLogin }) => {
  const [mobileNumber, setMobileNumber] = useState("");

  return (
    <form
      style={{ display: "flex", flexDirection: "column", gap: "16px", padding: "0px 16px" }}
      onSubmit={(e) => {
        e.preventDefault();
        e.stopPropagation();
        handleFormSubmit({
          mobileNumber: mobileNumber.replaceAll(" ", "").trim(),
        });
      }}
    >
      <TextField
        autoFocus={true}
        InputProps={{
          startAdornment: <InputAdornment position="start">+971</InputAdornment>,
        }}
        prefix="+971"
        value={mobileNumber}
        onChange={(e) => {
          setMobileNumber(e.target.value);
        }}
        required={true}
        id="phone"
        label="Mobile Number"
        sx={{ width: "100%" }}
      />
      <LoadingButton variant="contained" type="submit" loading={loadingLogin}>
        <span style={{ color: "white" }}>Login</span>
      </LoadingButton>
      <div style={{ display: "flex", justifyContent: "center" }}>
        <Link to={"/legacy-login"}>
          <Button variant="outlined">Use Legacy Login</Button>
        </Link>
      </div>
    </form>
  );
};

interface LoginFormProps {
  loadingLogin: boolean;
  handleFormSubmit: (params: { mobileNumber: string }) => void;
}

export default LoginForm;
