import { LoadingButton } from "@mui/lab";
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField } from "@mui/material";
import { FC, useCallback, useRef, useState } from "react";
import { Autocomplete, GoogleMap, Libraries, LoadScript } from "@react-google-maps/api";
import { initialMapCenter } from "../constants";
import useAppSettings from "../hooks/useAppSettings";
import LoadingSpinner from "./LoadingSpinner";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { createPartnerAddress, getAllPartnerAddresses, PartnerAddressModel } from "../services/inventory";
import ReduxStateModel from "../models/ReduxStateModel";
import { useSelector } from "react-redux";
import { showMessage } from "./common/notification";
import useAllPartnerAddressesQuery from "../hooks/useAllPartnerAddressesQuery";

const libraries: Libraries = ["places", "maps", "geocoding"];

const AddPartnerAddressModal: FC<AddPartnerAddressModalProps> = ({ onClose, onAddressCreated, open }) => {
  const authReducer = useSelector((state: ReduxStateModel) => state.authReducer?.setting);
  const [mapCenter, setMapCenter] = useState<google.maps.LatLngLiteral>(initialMapCenter);
  const [zoom, setZoom] = useState(12);
  const [buildingName, setBuildingName] = useState("");
  const [floorNumber, setFloorNumber] = useState("");
  const [addressName, setAddressName] = useState("");
  const formattedAddressRef = useRef("");
  const queryClient = useQueryClient();

  const { queryKey } = useAllPartnerAddressesQuery({ partnerUUID: authReducer?.partnerUUID });

  const [map, setMap] = useState<google.maps.Map | undefined>();

  const createAddressMutation = useMutation({ mutationFn: createPartnerAddress });

  const handelSaveClicked = useCallback(async () => {
    const lat = mapCenter.lat.toString();
    const lng = mapCenter.lng.toString();
    const address: PartnerAddressModel = {
      addressName: addressName.trim(),
      buildingName: buildingName.trim(),
      floorNumber: floorNumber.trim(),
      latitude: lat,
      longitude: lng,
      partnerUUID: authReducer?.partnerUUID,
      streetName: formattedAddressRef.current.trim(),
    };
    const res = await createAddressMutation.mutateAsync({ address: address });
    if (res?.success) {
      const allPartnerAddresses = await getAllPartnerAddresses({ partnerUUID: authReducer?.partnerUUID ?? "" });
      const addressFromAPI = allPartnerAddresses?.addresses.find((add) => {
        return add.latitude === lat && add.longitude === lng && add.streetName === formattedAddressRef.current;
      });
      queryClient.resetQueries(queryKey);
      showMessage("success", "Address was added successfully");
      formattedAddressRef.current = "";
      onAddressCreated(addressFromAPI ?? address);
      setZoom(12);
      setMapCenter(initialMapCenter);
      setAddressName("");
      setBuildingName("");
      setFloorNumber("");
      onClose();
    } else {
      showMessage("error", "Something went wrong");
    }
  }, [
    addressName,
    authReducer?.partnerUUID,
    buildingName,
    createAddressMutation,
    floorNumber,
    mapCenter.lat,
    mapCenter.lng,
    onAddressCreated,
    onClose,
    queryClient,
    queryKey,
  ]);

  const { query } = useAppSettings();
  const googleAPIKey = query.data?.key?.googleKey;

  const handlePlaceChanged = useCallback(() => {
    if (!map) return;
    const service = new google.maps.places.PlacesService(map);
    const inputElement = document.getElementById("add-partner-address-autocomplete") as HTMLInputElement;
    const searchText = inputElement.value;
    service.textSearch({ query: searchText, region: "ae" }, (results) => {
      if (results?.length) {
        const lat = results[0].geometry?.location?.lat();
        const lng = results[0].geometry?.location?.lng();
        formattedAddressRef.current = results[0].formatted_address ?? "";
        if (lat && lng) {
          setMapCenter({ lat: lat, lng: lng });
          setZoom(16);
        }
      }
    });
  }, [map]);

  const handleDragEnd = useCallback(() => {
    const newCenter = map?.getCenter();
    if (newCenter) {
      setMapCenter({ lat: newCenter.lat(), lng: newCenter.lng() });
    }
    const geocoder = new google.maps.Geocoder();
    geocoder.geocode({ location: mapCenter, region: "ae" }, (results) => {
      if (results?.length) {
        const inputElement = document.getElementById("add-partner-address-autocomplete") as HTMLInputElement;
        inputElement.value = results[0].formatted_address;
        formattedAddressRef.current = results[0].formatted_address ?? "";
      }
    });
  }, [map, mapCenter]);

  return (
    <Dialog open={open} onClose={onClose} fullWidth={true} maxWidth="sm">
      <form
        onSubmit={(e) => {
          e.preventDefault();
          handelSaveClicked();
        }}
      >
        <DialogTitle>{"Add Contact"}</DialogTitle>
        <DialogContent>
          {query.isLoading && (
            <LoadingSpinner spinning={true} styles={{ minHeight: "100px" }} showBackdrop={false} children={undefined} />
          )}
          {googleAPIKey && (
            <LoadScript libraries={libraries} googleMapsApiKey={googleAPIKey}>
              <Box>
                <Box width={"100%"} position={"relative"}>
                  {map && (
                    <img
                      src="https://dardocstorageaccount.blob.core.windows.net/dardocpictures/map-marker.png"
                      alt="Map marker"
                      style={{ position: "absolute", top: "50%", left: "50%", zIndex: 10, width: "30px" }}
                      draggable={false}
                    />
                  )}
                  <Box
                    width={"100%"}
                    height={"400px"}
                    sx={{
                      "& > div": {
                        width: "100%",
                        height: "100%",
                      },
                    }}
                  >
                    <GoogleMap
                      center={mapCenter}
                      // onIdle={(map) => {
                      //   const center = map.getCenter();
                      //   if (center) {
                      //     const lat = center.lat();
                      //     const lng = center.lng();
                      //     if (lat !== mapCenter.lat || lng !== mapCenter.lng) {
                      //       setMapCenter({ lat: center.lat(), lng: center.lng() });
                      //     }
                      //   }
                      // }}
                      onLoad={setMap}
                      mapContainerStyle={{ width: "100%", height: "100%" }}
                      zoom={zoom}
                      onDragEnd={handleDragEnd}
                      onZoomChanged={() => {
                        const newZoom = map?.getZoom();
                        if (newZoom) {
                          setZoom(newZoom);
                        }
                      }}
                      options={{
                        mapTypeControl: false,
                        zoomControl: true,
                        fullscreenControl: false,
                        streetViewControl: false,
                      }}
                    ></GoogleMap>
                  </Box>
                </Box>
                <Autocomplete onPlaceChanged={handlePlaceChanged} className="autocomplete-class" restrictions={{ country: "ae" }}>
                  <TextField
                    id="add-partner-address-autocomplete"
                    // label="Name"
                    placeholder="Search for location"
                    variant="outlined"
                    fullWidth={true}
                    sx={{ marginTop: "32px", position: "relative", zIndex: "1400" }}
                    size="small"
                    required={true}
                    // value={name}
                    // onChange={(e) => {
                    //   setName(e.target.value);
                    // }}
                  />
                </Autocomplete>
                <TextField
                  placeholder="Building Name"
                  variant="outlined"
                  fullWidth={true}
                  sx={{ marginTop: "32px" }}
                  size="small"
                  // required={true}
                  value={buildingName}
                  onChange={(e) => {
                    setBuildingName(e.target.value);
                  }}
                />
                <TextField
                  placeholder="Floor Number"
                  variant="outlined"
                  fullWidth={true}
                  sx={{ marginTop: "32px" }}
                  size="small"
                  // required={true}
                  value={floorNumber}
                  onChange={(e) => {
                    setFloorNumber(e.target.value);
                  }}
                />
                <TextField
                  placeholder="Address Name Ex. Abu Dhabi Office"
                  variant="outlined"
                  fullWidth={true}
                  sx={{ marginTop: "32px" }}
                  size="small"
                  required={true}
                  value={addressName}
                  onChange={(e) => {
                    setAddressName(e.target.value);
                  }}
                />
              </Box>
            </LoadScript>
          )}
          {/* <TextField
            label="Name"
            variant="outlined"
            fullWidth={true}
            sx={{ marginTop: "5px" }}
            size="small"
            required={true}
            value={name}
            onChange={(e) => {
              setName(e.target.value);
            }}
          /> */}
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose}>Cancel</Button>
          <LoadingButton loading={createAddressMutation.isLoading} variant="contained" disableElevation={true} type="submit">
            <Box color={"white"}>Save</Box>
          </LoadingButton>
        </DialogActions>
      </form>
    </Dialog>
  );
};

interface AddPartnerAddressModalProps {
  open: boolean;
  onAddressCreated: (address: PartnerAddressModel) => void;
  onClose: () => void;
}

export default AddPartnerAddressModal;
