import { useMemo } from "react";
import { useSelector } from "react-redux";
import ReduxStateModel from "../models/ReduxStateModel";
import moment from "moment";

const useGetStaffSchedulerMonthViewQueryKey: () => [string, string, number, number] = () => {
  const partnerUUID = useSelector((state: ReduxStateModel) => state?.authReducer?.setting?.partnerUUID ?? "");
  const selectedMonth = useSelector((state: ReduxStateModel) => state?.schedulerExploreViewReducer?.selectedMonth ?? moment());
  const selectedEndMoment = useSelector(
    (state: ReduxStateModel) => state?.schedulerExploreViewReducer?.selectedEndDateMonthView ?? moment()
  );

  const monthViewQueryKey = useMemo<[string, string, number, number]>(
    () => ["nursetimeslots/getallnursedatawithtimeslot", partnerUUID, selectedMonth.valueOf(), selectedEndMoment.valueOf()],
    [partnerUUID, selectedEndMoment, selectedMonth]
  );

  return monthViewQueryKey;
};

export default useGetStaffSchedulerMonthViewQueryKey;
