import { FC } from "react";

const TopBarTabContent: FC<TopBarTabContentProps> = ({ icon, label }) => {
  // const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  // const open = Boolean(anchorEl);

  // const handleClick = useCallback((event: React.MouseEvent<HTMLButtonElement>) => {
  //   event.stopPropagation();
  //   // setAnchorEl(event.currentTarget);
  // }, []);

  // const handleClose = useCallback(() => {
  //   setAnchorEl(null);
  // }, []);

  return (
    <div className="tab-bar-tab-content" style={{ display: "flex", alignItems: "center", justifyContent: "center", gap: "4px" }}>
      {icon}
      <div>{label}</div>
      {/* <div>
        <Tooltip title="Tab Actions" placement="top">
          <IconButton
            className="tab-actions-button"
            // disableRipple={true}
            id="basic-button"
            size="small"
            aria-controls={open ? "basic-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={open ? "true" : undefined}
            onClick={handleClick}
          >
            <MoreHorizIcon />
          </IconButton>
        </Tooltip>
        <Menu
          id="basic-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          MenuListProps={{
            "aria-labelledby": "basic-button",
          }}
        >
          <MenuItem onClick={handleClose}>Profile</MenuItem>
          <MenuItem onClick={handleClose}>My account</MenuItem>
          <MenuItem onClick={handleClose}>Logout</MenuItem>
        </Menu>
      </div> */}
    </div>
  );
};

interface TopBarTabContentProps {
  label: string;
  icon: React.ReactNode;
}

export default TopBarTabContent;
