import React from "react";
import "./style.css";

function Line({ color }) {
  return (
    <>
      <hr
        className="dardoc__line"
        style={{
          border: "none",
          backgroundColor: `${color ? color : "rgb(192, 192, 192, 0.7)"}`,
          height: "0.5px",
          width: "100%",
          margin: "0px",
        }}
      />
    </>
  );
}

export default Line;
