import { useQueryClient } from "@tanstack/react-query";
import { Button, Modal, Tooltip } from "antd";
import { FC, useMemo, useState } from "react";
import { MdTimeline } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import useAutomatedDriverScheduleQueryKey from "../../hooks/useAutomatedDriverScheduleQueryKey";
import { GeneratedDriverScheduleItem } from "../../services";
import { driverSchedulerTypes } from "../../types";
import TimelineView from "../AutoGeneratedDriverSchedule/TimelineView";

const ShowTimelineButton: FC<ShowTimelineButtonProps> = () => {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();
  const selectedDate: moment.Moment = useSelector((state: any) => state?.driverSchedulerReducer?.selectedDate);

  const queryKey = useAutomatedDriverScheduleQueryKey({ selectedDate });

  const automatedDriverScheduleQueryData = queryClient.getQueryData<
    { [driverName: string]: GeneratedDriverScheduleItem[] } | undefined | null
  >(queryKey);

  const driverNames = useMemo<string[]>(() => {
    return Object.keys(automatedDriverScheduleQueryData ?? {});
  }, [automatedDriverScheduleQueryData]);

  const [showTimelineModal, setShowTimelineModal] = useState<boolean>(false);

  return (
    <>
      <Tooltip title="Show driver schedule timeline view">
        <Button
          onClick={() => setShowTimelineModal(true)}
          type="default"
          shape="circle"
          style={{
            fontWeight: "500",
            fontSize: "14px",
            border: "none",
            boxShadow: "rgb(187 187 187 / 48%) 6px 2px 16px 0px, rgb(255 255 255 / 80%) -6px -2px 16px 0px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            gap: "4px",
            color: "black",
          }}
          size="large"
          icon={<MdTimeline style={{ color: "black" }} />}
        />
      </Tooltip>
      <Modal
        title="Timeline"
        okButtonProps={{ style: { display: "none" } }}
        cancelText="Close"
        cancelButtonProps={{ style: { borderRadius: "5px" } }}
        centered={true}
        width={1000}
        open={showTimelineModal}
        onCancel={() => setShowTimelineModal(false)}
      >
        <TimelineView
          showCopyScheduleButton={false}
          showDatePicker={false}
          handleCopyGeneratedScheduleClicked={() => {}}
          selectedDate={selectedDate}
          driversList={driverNames}
          setSelectedDate={(value) => {
            dispatch({ type: driverSchedulerTypes.SET_SELECTED_DATE, payload: value });
          }}
        />
      </Modal>
    </>
  );
};

interface ShowTimelineButtonProps {}

export default ShowTimelineButton;
