import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { Avatar, Drawer, List, Switch, Typography } from "antd";
import { FC } from "react";
import { useSelector } from "react-redux";
import { showMessage } from "../../components/common/notification";
import { StaffMemberModel } from "../../models/StaffMemberModel";
import { editDriver, nurseService } from "../../services";
import useAutomatedDriverScheduleQueryKey from "../../hooks/useAutomatedDriverScheduleQueryKey";
import ReduxStateModel from "../../models/ReduxStateModel";
import moment from "moment";

const DriverSettingsDrawer: FC<DriverSettingsDrawerProps> = ({ onClose, open }) => {
  const selectedDate: moment.Moment = useSelector(
    (state: ReduxStateModel) => state?.driverSchedulerReducer?.selectedDate ?? moment()
  );
  const partnerUserUUID: string | undefined = useSelector(
    (state: ReduxStateModel) => state?.authReducer?.setting?.partnerUserUUID
  );
  const partnerUUID = useSelector((state: ReduxStateModel) => state.authReducer?.setting?.partnerUUID);
  const queryClient = useQueryClient();
  const automatedDriverScheduleQueryKey = useAutomatedDriverScheduleQueryKey({ selectedDate });

  const queryKey: [string, string | undefined] = ["nurses", partnerUserUUID];

  const { data, isLoading } = useQuery(queryKey, () => nurseService.getAllNurses(partnerUserUUID ?? ""), {
    enabled: !!partnerUserUUID,
    onSuccess: (res) => {
      if (!res?.data?.success) {
        showMessage("error", "Somethint went wrong");
      }
    },
    onError: () => {
      showMessage("error", "Somethint went wrong");
    },
  });

  const editDriverMutation = useMutation({
    mutationFn: editDriver,
    onError: () => {
      showMessage("error", "Something went wrong");
    },
    onSuccess: (res) => {
      if (res?.success) {
        queryClient.resetQueries(automatedDriverScheduleQueryKey);
        queryClient.invalidateQueries(queryKey);
      } else {
        showMessage("error", "Something went wrong");
      }
    },
  });

  return (
    <Drawer title="Driver settings" open={open} onClose={onClose} placement="left">
      <List
        loading={isLoading}
        dataSource={data?.data?.drivers ?? []}
        renderItem={(driver: StaffMemberModel) => {
          return (
            <List.Item>
              <div style={{ display: "flex", justifyContent: "space-between", width: "100%" }}>
                <div style={{ display: "flex", gap: "8px" }}>
                  <Avatar src={driver.profileImageUrl} />
                  <Typography.Text>{driver.name}</Typography.Text>
                </div>
                <Switch
                  checked={driver.isActive ?? false}
                  checkedChildren="Active"
                  unCheckedChildren="Disabled"
                  onChange={(value) => {
                    editDriverMutation.mutate({
                      activeDays: driver.activeDays ?? [],
                      endTime: driver.endTime ?? "",
                      startTime: driver.startTime ?? "",
                      breakEndTime: driver.breakEndTime,
                      breakStartTime: driver.breakStartTime,
                      holidays: driver.holidays ?? [],
                      isActive: value,
                      isDeleted: false,
                      partnerUUID: partnerUUID ?? "",
                      address: driver.address ?? "",
                      addressLink: driver.addressLink ?? "",
                      capacity: driver.capacity ?? 0,
                      dob: driver.dob ?? "",
                      driverUUID: driver.driverUUID!,
                      email: driver.email ?? "",
                      emiratesIdBackImageUrl: driver.emiratesIdBackImageUrl ?? "",
                      emiratesIdFrontImageUrl: driver.emiratesIdFrontImageUrl ?? "",
                      licenseImageUrl: driver.licenseImageUrl ?? "",
                      mobileNumber: driver.mobileNumber ?? "",
                      name: driver.name ?? "",
                      nationality: driver.nationality ?? "",
                      passportImageUrl: driver.passportImageUrl ?? "",
                      profileImageUrl: typeof driver.profileImage === "string" ? driver.profileImage : "",
                      profileImage: typeof driver.profileImage !== "string" ? driver.profileImage : undefined,
                      sex: driver.sex ?? "",
                    });
                    queryClient.setQueryData(queryKey, (oldValue: typeof data) => {
                      if (!oldValue?.data) return oldValue;
                      const newValue: typeof data = JSON.parse(JSON.stringify(oldValue));
                      newValue!.data.drivers = newValue?.data.drivers?.map((member: StaffMemberModel) => {
                        if (driver.driverUUID === member.driverUUID) {
                          member.isActive = value;
                        }
                        return member;
                      });
                      return newValue;
                    });
                  }}
                />
              </div>
            </List.Item>
          );
        }}
      />
    </Drawer>
  );
};

interface DriverSettingsDrawerProps {
  open: boolean;
  onClose: () => void;
}

export default DriverSettingsDrawer;
