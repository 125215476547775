import { Button, Tooltip } from "antd";
import { FC } from "react";
import { IoMdCopy } from "react-icons/io";
import { showMessage } from "../../components/common/notification";
import { GeneratedDriverScheduleItem } from "../../services";

const CopyScheduleButton: FC<CopyScheduleButtonProps> = ({ dateToCheck, scheduleData }) => {
  const handleCopyGeneratedScheduleClicked = () => {
    // if (Object.keys(selectedTableRows).length) {
    //   return handleCopySelectedRows();
    // }
    const driverSchedulesStr = Object.keys(scheduleData ?? {}).map<string>((driverName) => {
      let str = `${driverName.toUpperCase()}\n\n`;
      for (const driverTask of scheduleData![driverName]) {
        str = `${driverName.toUpperCase()} - ${str}${driverTask.type === "pickup" ? "Pickup" : "Drop"} - ${
          driverTask.nurseName
        } - ${dateToCheck} ${driverTask.time} - ${driverTask.locationLink}\n\n`;
      }
      return str;
    });
    const formattedString = driverSchedulesStr.join(
      "\n\n==================================================================\n==================================================================\n\n"
    );
    navigator.clipboard.writeText(formattedString);
    showMessage("success", "Schedule was copied to your clipboard");
  };

  return (
    <Tooltip title="Copy driver schedule">
      <Button
        disabled={!Object.keys(scheduleData ?? {}).length}
        onClick={handleCopyGeneratedScheduleClicked}
        icon={<IoMdCopy />}
        shape="circle"
        size="large"
        style={{
          fontWeight: "500",
          fontSize: "14px",
          border: "none",
          boxShadow: "rgb(187 187 187 / 48%) 6px 2px 16px 0px, rgb(255 255 255 / 80%) -6px -2px 16px 0px",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          gap: "4px",
          color: "black",
        }}
      />
    </Tooltip>
  );
};

interface CopyScheduleButtonProps {
  dateToCheck: string;
  scheduleData:
    | {
        [driverName: string]: GeneratedDriverScheduleItem[];
      }
    | null
    | undefined;
}

export default CopyScheduleButton;
