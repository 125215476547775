import { Button, Tooltip } from "antd";
import { FC, useCallback, useMemo } from "react";
import { FaFileExcel } from "react-icons/fa";
import { excelFileType } from "../../constants";
import { saveAs as fileServerSaveAs } from "file-saver";
import useStaffSchedulerQuery from "../../hooks/useStaffSchedulerQuery";
import * as XLSX from "xlsx";
import moment from "moment";
import enumerateDateStringBetweenDates from "../../utils/enumerateDateStringBetweenDates";

const DownloadMonthViewExcelButton: FC = () => {
  const { query, queryKey } = useStaffSchedulerQuery({ viewType: "month" });

  const startMoment = useMemo<moment.Moment>(() => {
    return moment(queryKey[2]);
  }, [queryKey]);

  const endMoment = useMemo(() => {
    return moment(queryKey[3]);
  }, [queryKey]);

  const dateStringsBetweenStartAndEndDate = useMemo<string[]>(() => {
    const value = enumerateDateStringBetweenDates({ startDate: startMoment, endDate: endMoment });
    return value.dateStrArray;
  }, [endMoment, startMoment]);

  const onClick = useCallback(() => {
    const excelFileData: (string | number | undefined)[][] = [
      [`Professionals (${query.data?.nursesData?.length ?? 0})`, ...dateStringsBetweenStartAndEndDate],
    ];
    const scheduleData = query.data?.nursesData ?? [];
    excelFileData.push(
      ...scheduleData.map<(string | number | undefined)[]>((nurseData) => {
        return [
          nurseData.name?.trim(),
          ...dateStringsBetweenStartAndEndDate.map((dateStr) => {
            const isOffDay = nurseData.holidays?.includes(dateStr);
            const nurseDateBookings = nurseData.data?.find((dateBookings) => dateBookings.date === dateStr);
            if (!isOffDay && !nurseDateBookings) return;
            if (isOffDay && !nurseDateBookings) {
              return "On leave";
            }
            const bookingsStrArray =
              nurseDateBookings?.timeslots.map<string>((bookingData) => {
                return `${bookingData.timeslot}\n${bookingData.patients?.map<string>((p) => p.name.trim() ?? "").join("\n")}`;
              }) ?? [];
            const dividerStr = "=========";
            const bookingsStr = bookingsStrArray.join(dividerStr);
            if (isOffDay) {
              return `${bookingsStr}\n${dividerStr}\nOn leave for rest of the day`;
            } else {
              return bookingsStr;
            }
          }),
        ];
      })
    );
    const ws = XLSX.utils.json_to_sheet(excelFileData);
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], { type: excelFileType });
    fileServerSaveAs(data, `dardoc-schedule-${startMoment.format("DD/MM/YYYY")}-${endMoment.format("DD/MM/YYYY")}.xlsx`);
  }, [dateStringsBetweenStartAndEndDate, endMoment, query.data?.nursesData, startMoment]);

  return (
    <Tooltip title="Download Excel">
      <Button icon={<FaFileExcel />} onClick={onClick} style={{ color: "#157641" }} />
    </Tooltip>
  );
};

export default DownloadMonthViewExcelButton;
