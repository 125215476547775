import { Backdrop, CircularProgress } from "@mui/material";
import { FC, ReactNode } from "react";

const LoadingSpinner: FC<LoadingSpinnerProps> = ({
  borderRadius,
  spinning,
  children,
  styles,
  showBackdrop = true,
  unmountOnNotLoading = true,
}) => {
  if (!spinning && unmountOnNotLoading) return children;

  return (
    <div
      className="loading-spinner"
      style={{
        position: "relative",
        width: "100%",
        minHeight: "100vh",
        ...(styles ?? {}),
      }}
    >
      {children}
      {spinning && (
        <div
          style={{
            position: "absolute",
            top: "0px",
            left: "0px",
            right: "0px",
            bottom: "0px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {showBackdrop ? (
            <Backdrop
              open={spinning}
              style={{
                width: "100%",
                height: "100%",
                position: "relative",
                zIndex: 3,
                borderRadius: borderRadius ? `${borderRadius}px` : undefined,
              }}
            >
              <CircularProgress sx={{ zIndex: 3 }} />
            </Backdrop>
          ) : (
            <CircularProgress sx={{ zIndex: 3 }} />
          )}
        </div>
      )}
    </div>
  );
};

interface LoadingSpinnerProps {
  spinning: boolean;
  children: ReactNode;
  showBackdrop?: boolean;
  borderRadius?: number;
  unmountOnNotLoading?: boolean;
  styles?: React.CSSProperties;
}

export default LoadingSpinner;
