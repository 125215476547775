import { LoadingOutlined } from "@ant-design/icons";
import { CircularProgress } from "@mui/material";
import { Col, Row, Spin } from "antd";
import { FC, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Route, Switch, useRouteMatch } from "react-router-dom";
import { setExploreViewNurseDayBookings } from "../../actions/schedulerExploreViewActions";
import "../../assets/styles/scheduler_detail_view.scss";
import "../../assets/styles/styles.scss";
import useStaffSchedulerQuery from "../../hooks/useStaffSchedulerQuery";
import { getBookingsNurseDatesMap } from "../../models/SchedulerExploreViewResponseModel";
import AssignDateModal from "../SchdulerDetailView/AssignDateModal";
import AssignPatientsConfirmationModal from "../SchdulerDetailView/AssignPatientsConfirmationModal";
import AssignPatientsModal from "../SchdulerDetailView/AssignPatientsModal";
import AssignStartEndTimeModal from "../SchdulerDetailView/AssignStartEndTimeModal";
import BookingDetailsModal from "../SchdulerDetailView/BookingDetailsModal";
import DeleteBookingModal from "../SchdulerDetailView/DeleteBookingModal";
import AddBreakTimeModal from "./AddBreakTimeModal";
import BreakTimeDetailsModal from "./BreakTimeDetailsModal";
import CalendarDayView from "./CalendarDayView";
import CalendarMonthView from "./CalendarMonthView";
import CalendarWeekView from "./CalendarWeekView";
import ExploreViewControls from "./ExploreViewControls";
import MarkLeaveOrAssignNurseModal from "./MarkLeaveOrAssignNurseModal";
import MarkNurseOnLeaveModal from "./MarkNurseOnLeaveModal";
import RemoveNurseOnLeaveModal from "./RemoveNurseOnLeaveModal";
import BasePageLayout from "../../components/BasePageLayout";
import BasePageLayoutSideMenuItems from "../../components/BasePageLayoutSideMenuItems";

const SchedulerExploreView: FC = () => {
  const dispatch = useDispatch();
  const [viewType, setViewType] = useState<CalendarViewType>("week");
  const { path } = useRouteMatch();

  const { query } = useStaffSchedulerQuery({ viewType });
  const { data, isLoading, isFetching, error } = query;

  useEffect(() => {
    if (!data) return;
    dispatch(setExploreViewNurseDayBookings(getBookingsNurseDatesMap(data.nursesData)));
  }, [dispatch, data]);

  // useEffect(() => {
  //   if (!data?.nursesData) return;
  //   const tableBodyBox = document.getElementById("explore-view-table-body")?.getBoundingClientRect();
  //   const weekEndMoment = weekStartMoment.clone().endOf("week");
  //   const dateFormat = "DD/MM/YYYY";
  //   setFreeSlotsComponents(
  //     data?.nursesData.map<JSX.Element>((item) => {
  //       const { nurseUUID } = item;
  //       const dates = (item.data ?? []).filter((item) => {
  //         const dateMoment = moment(item.date, dateFormat);
  //         return dateMoment.isSameOrAfter(weekStartMoment) && dateMoment.isBefore(weekEndMoment);
  //       });
  //       if (!dates || !dates.length) return <></>;
  //       const firstDate = moment(dates[0].date, dateFormat);
  //       const lastDate = moment((item.data ?? [])[(item.data?.length ?? 1) - 1].date, dateFormat);
  //       // console.log(
  //       //   "--- id",
  //       //   `table-cell-${nurseUUID}-${firstDate.clone().set("hour", 0).set("minute", 0).set("second", 0).format()}`
  //       // );
  //       const firstDateBox = document
  //         .getElementById(
  //           `table-cell-${nurseUUID}-${firstDate.clone().set("hour", 0).set("minute", 0).set("second", 0).format()}`
  //         )
  //         ?.getBoundingClientRect();
  //       const lastDateBox = document
  //         .getElementById(`table-cell-${nurseUUID}-${lastDate.clone().set("hour", 0).set("minute", 0).set("second", 0).format()}`)
  //         ?.getBoundingClientRect();
  //       if (!firstDateBox || !lastDateBox) return <></>;
  //       const top = (firstDateBox?.top ?? 0) + (firstDateBox?.height ?? 0) / 2 - 10;
  //       const left = firstDateBox?.left ?? 0;
  //       const width = (lastDateBox?.right ?? 0) - left;
  //       return (
  //         <div
  //           className="empty-slot"
  //           style={{
  //             overflow: "hidden",
  //             height: "40px",
  //             width: `${width}px`,
  //             position: "absolute",
  //             top: `${top - (tableBodyBox?.top ?? 0)}px`,
  //             left: `${left - (tableBodyBox?.left ?? 0)}px`,
  //             zIndex: "1",
  //             borderRadius: "6px",
  //           }}
  //         >
  //           <img
  //             style={{
  //               width: "100%",
  //               height: "100%",
  //               objectFit: "cover",
  //             }}
  //             src={emptySlot}
  //             alt={`Empty slot for ${item.name}`}
  //           />
  //         </div>
  //       );
  //     })
  //   );
  // }, [data, weekStartMoment]);

  // useEffect(() => {
  //   if (!data?.nursesData) return;
  //   const components = getBookinsList(data).map<JSX.Element>((item) => {
  //     const key = `calendar-booking-item-${item.nurseUUID}-${item.startMoment.format()}`;
  //     return <CalendarBookingItem key={key} nurseBooking={item} id={key} />;
  //   });
  //   setBookingComponents(components);
  // }, [data]);

  let content;
  if (isLoading) {
    content = (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          padding: "1rem 0px",
        }}
      >
        <CircularProgress />
      </div>
    );
  } else if (error) {
    content = (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          padding: "1rem 0px",
        }}
      >
        Something went wrong
      </div>
    );
  } else if (!data?.nursesData || data.nursesData.length === 0) {
    content = (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          padding: "1rem 0px",
        }}
      >
        No data found
      </div>
    );
  } else if (viewType === "week") {
    content = <CalendarWeekView data={data} />;
  } else if (viewType === "month") {
    content = <CalendarMonthView data={data} />;
  } else {
    content = <CalendarDayView data={data} />;
  }

  return (
    <BasePageLayout
      sideMenuItems={BasePageLayoutSideMenuItems}
      mainContent={
        <div className="" style={{ backgroundColor: "white" }}>
          <Row style={{ width: "100%" }}>
            <Col xs={24} sm={24} md={24} lg={24} xl={24}>
              <div
                style={{
                  height: isFetching && !isLoading ? "25px" : "0px",
                  width: "100%",
                  maxWidth: "100%",
                  minWidth: "100%",
                  backgroundColor: "#25bcbd",
                  color: "white",
                  textAlign: "center",
                  // padding: "4px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  gap: "8px",
                  fontSize: 12,
                  overflow: "hidden",
                  transition: "height 0.3s ease-out",
                }}
              >
                Checking for available updates
                <Spin style={{ color: "white" }} indicator={<LoadingOutlined style={{ fontSize: 14 }} spin />} />
              </div>
              <div
                className="scheduler-explore-view"
                style={{
                  flex: "1 1",
                  padding: "1rem 1rem",
                  width: "100%",
                  maxWidth: "100%",
                  minWidth: "100%",
                }}
              >
                <div className="explore-view-container">
                  <ExploreViewControls
                    viewType={viewType}
                    isFetching={isFetching}
                    isLoading={isLoading}
                    onViewTypeClicked={(value) => setViewType(value)}
                  />
                  {content}
                </div>
              </div>
            </Col>
          </Row>
          <Switch>
            <Route
              exact
              path={`${path}/view-break-time`}
              render={() => {
                return <BreakTimeDetailsModal />;
              }}
            />
            <Route
              exact
              path={`${path}/add-break-time`}
              render={() => {
                return <AddBreakTimeModal viewType={viewType} />;
              }}
            />
            <Route
              exact
              path={`${path}/assign-patients`}
              render={() => {
                return <AssignPatientsModal viewType={viewType} />;
              }}
            />
            <Route
              exact
              path={`${path}/edit-order`}
              render={() => {
                return <AssignPatientsModal viewType={viewType} />;
              }}
            />
            <Route exact path={`${path}/assign-date`}>
              <AssignDateModal />
            </Route>
            <Route exact path={`${path}/assign-start-end-time`}>
              <AssignStartEndTimeModal />
            </Route>
            <Route exact path={`${path}/assign-patinets-confirmation`}>
              <AssignPatientsConfirmationModal />
            </Route>
            <Route exact path={`${path}/booking-details`}>
              <BookingDetailsModal />
            </Route>
            <Route exact path={`${path}/delete-booking`}>
              <DeleteBookingModal />
            </Route>
            <Route exact path={`${path}/mark-as-leave-or-assign-nurse`}>
              <MarkLeaveOrAssignNurseModal />
            </Route>
            <Route
              exact
              path={`${path}/mark-nurse-as-on-leave`}
              render={() => {
                return <MarkNurseOnLeaveModal renderModals={true} viewType={viewType} />;
              }}
            />
            <Route
              exact
              path={`${path}/remove-nurse-on-leave`}
              render={() => {
                return <RemoveNurseOnLeaveModal viewType={viewType} />;
              }}
            />
          </Switch>
        </div>
      }
    />
  );
};

export type CalendarViewType = "day" | "week" | "month";

export default SchedulerExploreView;
