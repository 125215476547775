import { Button, Chip } from "@mui/material";
import { FC } from "react";
import { FilterBookingsType } from "../../components/SearchAndFilterBookingButton";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";

const ShowSelectedFilters: FC<ShowSelectedFiltersProps> = ({ filterValue, setFilterValue }) => {
  return (
    <div style={{ display: "flex", flexWrap: "wrap", gap: "8px", alignItems: "center", marginBottom: "1rem" }}>
      {filterValue && (
        <Button
          startIcon={<CancelOutlinedIcon />}
          variant="outlined"
          sx={{ borderRadius: "20px" }}
          size="small"
          onClick={() => {
            setFilterValue(undefined);
          }}
        >
          Clear Filters
        </Button>
      )}
      {filterValue?.sort && (
        <Chip
          sx={{ height: "auto", minHeight: "32px" }}
          label={
            filterValue?.sort === "booking-date-ascending" ? <strong>Sort Ascending</strong> : <strong>Sort Decsending</strong>
          }
          variant="outlined"
          onDelete={() => {
            setFilterValue((value) => {
              const newValue: FilterBookingsType = { ...value };
              delete newValue.sort;
              if (Object.keys(newValue).length === 0) {
                return undefined;
              }
              return newValue;
            });
          }}
        />
      )}
      {(filterValue?.selectedFilterNurses?.length ?? 0) > 0 && (
        <Chip
          variant="outlined"
          sx={{ height: "auto", minHeight: "32px" }}
          label={
            <div style={{ display: "flex", gap: "1rem", flexWrap: "wrap" }}>
              <div>Filter Professionals: </div>
              {filterValue?.selectedFilterNurses?.map((nurse) => {
                return (
                  <div key={nurse.nurseUUID}>
                    <strong>{`${nurse.firstName} ${nurse.lastName}`.trim()}</strong>
                  </div>
                );
              })}
            </div>
          }
          onDelete={() => {
            setFilterValue((value) => {
              const newValue: FilterBookingsType = { ...value };
              delete newValue.selectedFilterNurses;
              if (Object.keys(newValue).length === 0) {
                return undefined;
              }
              return newValue;
            });
          }}
        />
      )}
      {(filterValue?.selectedFilterProducts?.length ?? 0) > 0 && (
        <Chip
          sx={{ height: "auto", minHeight: "32px" }}
          label={
            <div style={{ display: "flex", gap: "4px", flexWrap: "wrap" }}>
              <div>Filter Services: </div>
              {filterValue?.selectedFilterProducts?.map((prod) => {
                return (
                  <div key={prod.productUUID}>
                    <strong>{prod.engProductName}</strong>
                  </div>
                );
              })}
            </div>
          }
          variant="outlined"
          onDelete={() => {
            setFilterValue((value) => {
              const newValue: FilterBookingsType = { ...value };
              delete newValue.selectedFilterProducts;
              if (Object.keys(newValue).length === 0) {
                return undefined;
              }
              return newValue;
            });
          }}
        />
      )}
    </div>
  );
};

interface ShowSelectedFiltersProps {
  filterValue?: FilterBookingsType;
  setFilterValue: React.Dispatch<React.SetStateAction<FilterBookingsType | undefined>>;
}

export default ShowSelectedFilters;
