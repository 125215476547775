import { notification } from "antd";

let width = window.screen.width;

export function showMessage(type, msg, duration) {
  switch (type) {
    case "success":
      openNotificaiton(type, msg);
      break;
    case "error":
      openNotificaiton(type, msg, duration ?? 5);
      break;

    case "info":
      openNotificaiton(type, msg);
      break;

    case "warning":
      openNotificaiton(type, msg, 5);
      break;
    default:
      openNotificaiton("info", msg);
      break;
  }
}

const openNotificaiton = (type, msg, time) => {
  notification[type]({
    message: msg,
    placement: `${width <= 500 ? "bottomRight" : "topRight"}`,
    duration: time ? time : 3,
  });
};
