import moment from "moment";
import SchedulerExploreViewResponseModel, { NurseBookingType } from "../../models/SchedulerExploreViewResponseModel";
import useGetStaffSchedulerWeekViewQueryKey from "../../hooks/useGetStaffSchedulerWeekViewQueryKey";
import useGetStaffSchedulerMonthViewQueryKey from "../../hooks/useGetStaffSchedulerMonthViewQueryKey";
import useGetStaffSchedulerDayViewQueryKey from "../../hooks/useGetStaffSchedulerDayViewQueryKey";
import { Button, Divider, Modal } from "antd";
import { FC, useMemo } from "react";
import { Redirect, useHistory, useParams, useRouteMatch } from "react-router-dom";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { removeNurseBookingFromSchedule } from "../../services/nurseTimeSlotService";
import { SlotPatientModel } from "../../models/SchedulerDetailViewResponseModel";
import { showMessage } from "../../components/common/notification";

const DeleteBookingModal: FC = () => {
  const history = useHistory<{
    booking?: {
      nurseUUID: string;
      startMoment: number;
      endMoment: number;
      patients?: SlotPatientModel[];
      nurseName?: string;
      nurseProfileImg?: string;
      nurseOrderID?: string;
      type: NurseBookingType;
    };
  }>();
  const booking = history.location.state?.booking;
  const nurseBookingType = history.location.state.booking?.type;
  // const selectedDetailViewWeek: moment.Moment = useSelector((state) => (state as any)?.schedulerDetailViewReducer?.selectedWeek);
  // const selectedExploreViewWeek: moment.Moment = useSelector(
  //   (state) => (state as any)?.schedulerExploreViewReducer?.selectedWeek
  // );
  // const selectedDay: moment.Moment = useSelector((state: any) => state?.schedulerExploreViewReducer?.selectedDay);
  // const selectedMonth: moment.Moment = useSelector((state: any) => state?.schedulerExploreViewReducer?.selectedMonth);
  const queryClient = useQueryClient();
  const nurseUUID = useParams<{ nurseUUID?: string }>().nurseUUID ?? history.location.state.booking?.nurseUUID;
  // const partnerUUID: string | undefined = useSelector((state) => (state as any)?.authReducer?.setting?.partnerUUID);
  const { url } = useRouteMatch();
  const bookingStartMoment = useMemo<moment.Moment>(
    () => moment(history.location.state?.booking?.startMoment),
    [history.location.state?.booking?.startMoment]
  );
  const bookingEndMoment = useMemo<moment.Moment>(
    () => moment(history.location.state?.booking?.endMoment),
    [history.location.state?.booking?.endMoment]
  );

  // console.log("--- delete", history.location);

  const weekViewQueryKey = useGetStaffSchedulerWeekViewQueryKey();
  const monthViewQueryKey = useGetStaffSchedulerMonthViewQueryKey();
  const dayViewQueryKey = useGetStaffSchedulerDayViewQueryKey();

  const mutation = useMutation({
    mutationFn: removeNurseBookingFromSchedule,
    onSuccess: (res, params) => {
      // const weekViewQueryKey = [
      //   "nursetimeslots/getallnursedatawithtimeslot",
      //   partnerUUID,
      //   selectedExploreViewWeek.valueOf(),
      //   selectedExploreViewWeek.clone().add(6, "day").valueOf(),
      // ];
      // const monthViewQueryKey = [
      //   "nursetimeslots/getallnursedatawithtimeslot",
      //   partnerUUID,
      //   selectedMonth.valueOf(),
      //   selectedMonth.clone().endOf("month").valueOf(),
      // ];
      // const dayViewQueryKey = [
      //   "nursetimeslots/getallnursedatawithtimeslot",
      //   partnerUUID,
      //   selectedDay.valueOf(),
      //   selectedDay.clone().add(1, "day").valueOf(),
      // ];
      const queryUpdater = (oldValue: SchedulerExploreViewResponseModel | null | undefined) => {
        if (!oldValue) return oldValue;
        const newValue: SchedulerExploreViewResponseModel = JSON.parse(JSON.stringify(oldValue));
        const nurse = newValue.nursesData?.find((nurse) => nurse.nurseUUID === nurseUUID);
        const bookingDate = bookingStartMoment.format("DD/MM/YYYY");
        const nurseDate = nurse?.data?.find((date) => date.date === bookingDate);
        if (params.nurseOrderID) {
          nurseDate!.timeslots! = nurseDate?.timeslots.filter((timeSlot) => timeSlot.nurseOrderID !== params.nurseOrderID) ?? [];
        } else {
          const timeSlotStr = `${bookingStartMoment.format("hh:mmA")} - ${bookingEndMoment.format("hh:mmA")}`;
          nurseDate!.timeslots! = nurseDate?.timeslots.filter((timeSlot) => timeSlot.timeslot !== timeSlotStr) ?? [];
        }
        return newValue;
      };
      queryClient.setQueryData(weekViewQueryKey, queryUpdater);
      queryClient.setQueryData(monthViewQueryKey, queryUpdater);
      queryClient.setQueryData(dayViewQueryKey, queryUpdater);
      if (res?.success) {
        showMessage("success", "Nurse booking was removed successfully");
      }
      const baseURL = url.split("/")[1];
      if (baseURL === "scheduler-details") {
        history.replace(`/${baseURL}/${nurseUUID}`, {});
      } else {
        history.replace(`/${baseURL}`, {});
      }
    },
    onError: (error) => {
      console.log("--- mutation onError bookNurseSlot", error);
      showMessage("error", "Something went wrong");
    },
  });

  // console.log("--- nurseUUID", nurseUUID);

  if (!booking?.nurseUUID) {
    const urlBase = url.split("/")[1];
    if (urlBase === "scheduler-details") {
      return <Redirect to={`/${urlBase}/${nurseUUID}`} />;
    } else {
      return <Redirect to={`/scheduler`} />;
    }
  }

  const handleYesClicked = () => {
    // const startMoment = moment(booking.startMoment);
    mutation.mutate({
      nurseOrderID: history.location.state.booking?.nurseOrderID,
      nurseUUID: nurseUUID ?? "",
      date: bookingStartMoment.format("DD/MM/YYYY"),
      time: `${bookingStartMoment.format("hh:mm")} - ${bookingStartMoment.clone().add(30, "minute").format("hh:mmA")}`,
    });
  };

  const onCancel = () => {
    const baseURL = url.split("/")[1];
    if (baseURL === "scheduler-details") {
      history.replace(`/${baseURL}/${nurseUUID}`, {});
    } else {
      history.replace(`/${baseURL}`, {});
    }
  };

  return (
    <Modal
      centered
      className="scheduler-modal scheduler-delete-booking-modal"
      title={
        nurseBookingType === "BOOKING"
          ? "Are you sure you want to delete this booking ?"
          : "Are you sure you would like to remove this break time ?"
      }
      open={true}
      maskClosable={false}
      footer={null}
      onCancel={onCancel}
    >
      <div className="modal-container">
        <div className="detail-row">
          <div className="label">Professional:</div>
          <div className="value">
            <strong>{booking.nurseName}</strong>
          </div>
        </div>
        {booking.patients && booking.patients.length > 0 && (
          <div className="detail-row">
            <div className="label">Patient:</div>
            <div className="value">
              <strong>{booking.patients?.map<string>((patient) => patient.name).join(", ")}</strong>
            </div>
          </div>
        )}
        <div className="detail-row">
          <div className="label">Date:</div>
          <div className="value">
            <strong>{bookingStartMoment.format("DD MMMM YYYY")}</strong>
          </div>
        </div>
        <div className="detail-row">
          <div className="label">From:</div>
          <div className="value">
            <strong>{bookingStartMoment.format("hh:mm A")}</strong>
          </div>
        </div>
        <div className="detail-row">
          <div className="label">To:</div>
          <div className="value">
            <strong>{bookingEndMoment.format("hh:mm A")}</strong>
          </div>
        </div>
      </div>
      <Divider
        style={{
          margin: "0px",
        }}
      />
      <div
        style={{
          paddingLeft: "24px",
          paddingRight: "24px",
          paddingBottom: "12px",
          paddingTop: "12px",
          display: "flex",
          justifyContent: "space-between",
          alignContent: "center",
          gap: "1rem",
        }}
      >
        <Button onClick={onCancel} type="default">
          No
        </Button>
        <Button danger onClick={handleYesClicked} loading={mutation.isLoading}>
          Yes
        </Button>
      </div>
    </Modal>
  );
};

export default DeleteBookingModal;
