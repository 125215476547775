import TeamListProfileImage from "./TeamListProfileImage";
import checkIfNurseHasMissingData from "../../components/Sidebar/checkIfNurseHasMissingData";
import useNurseServedHoursQuery from "../../hooks/useNurseServedHoursQuery";
import { Divider, Tooltip } from "antd";
import { FC, useMemo } from "react";
import { AiFillStar } from "react-icons/ai";
import { FiChevronRight } from "react-icons/fi";
import { useHistory } from "react-router-dom";
import { StaffMemberModel } from "../../models/StaffMemberModel";
import { MdInfoOutline } from "react-icons/md";
import { MdOutlineWorkspacePremium } from "react-icons/md";
import ProfessionalStatusIcon from "./ProfessionalStatusIcon";
import { Box } from "@mui/material";

const NurseListItem: FC<NurseListItemProps> = ({ nurse, renderDivider }) => {
  const history = useHistory();
  // const dispatch = useDispatch();

  const { query } = useNurseServedHoursQuery(nurse.nurseUUID);

  const openNurseProfile = () => {
    // localStorage.setItem("nurseUUID", nurse!.nurseUUID!);
    // dispatch(nurseAction.getSingleNurse({ ...nurse, page: "edit" }));
    history.push(`/profile/professional/${nurse.nurseUUID}`, {
      edit: true,
      nurse,
    });
  };

  const profileHasMessingData = useMemo<boolean>(() => {
    return checkIfNurseHasMissingData(nurse);
  }, [nurse]);

  return (
    <>
      <li
        className={!nurse.isActive ? "disabled-nurse-list-item" : ""}
        onClick={openNurseProfile}
        style={{
          cursor: "pointer",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          gap: "16px",
          padding: "10px 0px",
        }}
      >
        <div style={{ width: "100%", display: "flex", gap: "1rem", justifyContent: "space-between" }}>
          <div style={{ display: "flex", gap: "1rem" }}>
            <TeamListProfileImage
              src={nurse.profileImage ?? nurse.profilePhoto}
              alt={`${nurse.firstName ?? ""} ${nurse.lastName ?? ""}`}
            />
            <div style={{ display: "flex", flexDirection: "column", gap: "0.7rem" }}>
              <div style={{ display: "flex", gap: "0.5rem" }}>
                <div style={{ color: "black" }}>
                  {nurse.firstName} {nurse.lastName}
                </div>
                {nurse.status && <ProfessionalStatusIcon status={nurse.status} rejectReason={nurse.rejectReason} />}
                {profileHasMessingData && (
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <Tooltip title="Please fill all the professional's mandatory fields">
                      <MdInfoOutline size="24" color="red" />
                    </Tooltip>
                  </div>
                )}
              </div>
              <div style={{ display: "flex", gap: "1rem", alignItems: "center", flexWrap: "wrap" }}>
                <Box className="tag">{nurse.isActive ? "Active" : "Disabled"}</Box>
                {(query.data?.servedHoursData?.monthlyHour ?? 0) > 0 && (
                  <>
                    <Box
                      sx={{
                        width: "4px",
                        height: "4px",
                        backgroundColor: "black",
                        borderRadius: "50%",
                        "@media (max-width: 700px)": {
                          display: "none",
                        },
                      }}
                    />
                    <Box
                      sx={{
                        "@media (max-width: 700px)": {
                          display: "none",
                        },
                      }}
                      className="tag"
                    >
                      Served hours this month: {query.data?.servedHoursData?.monthlyHour} Hours
                    </Box>
                  </>
                )}
                {(query.data?.servedHoursData?.totalHours ?? 0) > 0 && (
                  <>
                    <Box
                      sx={{
                        width: "4px",
                        height: "4px",
                        backgroundColor: "black",
                        borderRadius: "50%",
                        "@media (max-width: 700px)": {
                          display: "none",
                        },
                      }}
                    />
                    <Box
                      sx={{
                        "@media (max-width: 700px)": {
                          display: "none",
                        },
                      }}
                      className="tag"
                    >
                      Total served hours: {query.data?.servedHoursData?.totalHours} Hours
                    </Box>
                  </>
                )}
                {(query.data?.servedHoursData?.monthlyTest ?? 0) > 0 && (
                  <>
                    <Box
                      sx={{
                        width: "4px",
                        height: "4px",
                        backgroundColor: "black",
                        borderRadius: "50%",
                        "@media (max-width: 700px)": {
                          display: "none",
                        },
                      }}
                    />
                    <Box
                      sx={{
                        "@media (max-width: 700px)": {
                          display: "none",
                        },
                      }}
                      className="tag"
                    >
                      Total tests this month: {query.data?.servedHoursData?.monthlyTest} Tests
                    </Box>
                  </>
                )}
                {(query.data?.servedHoursData?.totalTest ?? 0) > 0 && (
                  <>
                    <Box
                      sx={{
                        width: "4px",
                        height: "4px",
                        backgroundColor: "black",
                        borderRadius: "50%",
                        "@media (max-width: 700px)": {
                          display: "none",
                        },
                      }}
                    />
                    <Box
                      sx={{
                        "@media (max-width: 700px)": {
                          display: "none",
                        },
                      }}
                      className="tag"
                    >
                      Total tests: {query.data?.servedHoursData?.totalTest} Tests
                    </Box>
                  </>
                )}
                {(query.data?.servedHoursData?.totalSession ?? 0) > 0 && (
                  <>
                    <Box
                      sx={{
                        width: "4px",
                        height: "4px",
                        backgroundColor: "black",
                        borderRadius: "50%",
                        "@media (max-width: 700px)": {
                          display: "none",
                        },
                      }}
                    />
                    <Box
                      sx={{
                        "@media (max-width: 700px)": {
                          display: "none",
                        },
                      }}
                      className="tag"
                    >
                      Total sessions: {query.data?.servedHoursData?.totalSession} Sessions
                    </Box>
                  </>
                )}
                {nurse.avgRatings && (
                  <div
                    style={{
                      width: "4px",
                      height: "4px",
                      backgroundColor: "black",
                      borderRadius: "50%",
                    }}
                  />
                )}
                {nurse.avgRatings && (
                  <div
                    className="tag"
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: "4px",
                    }}
                  >
                    {nurse.avgRatings}
                    <AiFillStar style={{ color: "#ffc107" }} />
                  </div>
                )}
                {nurse.isPremium && (
                  <div
                    className="tag"
                    style={{
                      padding: "3px 5px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <MdOutlineWorkspacePremium style={{ fontSize: "12px", color: "#ffc107" }} />
                  </div>
                )}
              </div>
            </div>
          </div>
          <div
            style={{
              padding: "0px 16px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              fontSize: "20px",
            }}
          >
            <FiChevronRight />
          </div>
        </div>
      </li>
      {renderDivider && <Divider style={{ margin: "0px" }} />}
    </>
  );
};

interface NurseListItemProps {
  nurse: StaffMemberModel;
  renderDivider: boolean;
}

export default NurseListItem;
