import { useMemo } from "react";
import { useSelector } from "react-redux";
import ReduxStateModel from "../models/ReduxStateModel";
import moment from "moment";

const useGetStaffSchedulerDayViewQueryKey: () => [string, string | undefined, number, number] = () => {
  const partnerUUID = useSelector((state: ReduxStateModel) => state?.authReducer?.setting?.partnerUUID);
  const selectedDay = useSelector((state: ReduxStateModel) => state?.schedulerExploreViewReducer?.selectedDay ?? moment());

  const dayViewQueryKey = useMemo<[string, string | undefined, number, number]>(() => {
    return [
      "nursetimeslots/getallnursedatawithtimeslot",
      partnerUUID,
      selectedDay.valueOf(),
      selectedDay.clone().add(1, "day").valueOf(),
    ];
  }, [partnerUUID, selectedDay]);

  return dayViewQueryKey;
};

export default useGetStaffSchedulerDayViewQueryKey;
