import { FC } from "react";
import PatientInfoItem from "./PatientInfoItem";
import BookingModel from "../../models/BookingModel";
import useProductDetails from "../../hooks/useProductDetails";

const ShowUserEmail: FC<ShowUserEmailProps> = ({ booking }) => {
  const { query } = useProductDetails({ area: booking?.emirate ?? "", productUUID: booking?.patientId?.[0].productUUID ?? "" });

  if (query.isLoading) return null;
  if (booking?.isDeliveryOrder) return null;

  return (
    <PatientInfoItem
      name="User's Email"
      value={query.data?.product?.isDarLabProduct ? booking?.patientId?.[0].newEmailId ?? booking?.userEmail : booking?.userEmail}
    />
  );
};

interface ShowUserEmailProps {
  booking?: BookingModel;
}

export default ShowUserEmail;
