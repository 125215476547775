import { FC, useMemo } from "react";
import { BookingPatientIDModel } from "../../models/BookingModel";
import { Divider } from "@mui/material";
import { RiParentFill } from "react-icons/ri";
import { green } from "@mui/material/colors";
import usePatientDetails from "../../hooks/usePatientDetails";
import PersonRoundedIcon from "@mui/icons-material/PersonRounded";

const BookingTableRowPatientCell: FC<BookingTableRowPatientCellProps> = ({ patientId, guardian }) => {
  return (
    <>
      {patientId?.map((patient) => {
        return <PatientCellItem key={patient.id} patientID={patient.id ?? ""} />;
      })}
      {guardian && (
        <>
          <Divider style={{ margin: "8px 0px" }} />
          <div style={{ fontSize: "12px", color: "gray" }}>Guardian</div>
          <PatientCellItem patientID={guardian} icon={<RiParentFill size={20} color={green[500]} />} />
        </>
      )}
    </>
  );
};

const PatientCellItem: FC<{ patientID: string; icon?: React.ReactNode }> = ({ patientID, icon }) => {
  const { query } = usePatientDetails({ patientID: patientID });

  const trimName = useMemo(() => {
    const limit = 15;
    const name = query.data?.patient?.name?.toLocaleLowerCase()?.trim() ?? "";
    if (name.startsWith("guest ")) return "";
    if (name.length < limit) return name;
    return `${name.slice(0, limit)}...`;
  }, [query.data?.patient?.name]);

  if (!trimName) return null;

  return (
    <div style={{ display: "flex", alignItems: "flex-start", gap: "4px", width: "100%", maxWidth: "100%", overflow: "hidden" }}>
      {icon ?? <PersonRoundedIcon color="primary" />}
      {trimName}
    </div>
  );
};

interface BookingTableRowPatientCellProps {
  patientId?: BookingPatientIDModel[];
  guardian?: string;
}

export default BookingTableRowPatientCell;
