import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  InputAdornment,
  TextField,
  Typography,
} from "@mui/material";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { FC, useCallback, useRef, useState } from "react";
import { createPartnerContact, getAllPartnerContacts } from "../services/inventory";
import { useSelector } from "react-redux";
import { showMessage } from "./common/notification";
import ReduxStateModel from "../models/ReduxStateModel";
import PartnerContactModel from "../models/PartnerContactModel";
import { LoadingButton } from "@mui/lab";
import useAllPartnerContactsQuery from "../hooks/useAllPartnerContactsQuery";

const AddPartnerContactModal: FC<AddPartnerContactModalProps> = ({ onContactCreated, onClose, open }) => {
  const authReducer = useSelector((state: ReduxStateModel) => state.authReducer?.setting);
  const formRef = useRef<HTMLFormElement>(null);
  const queryClient = useQueryClient();
  const [name, setName] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");

  const { queryKey } = useAllPartnerContactsQuery({ partnerUUID: authReducer?.partnerUUID });

  const createContactMutation = useMutation({
    mutationFn: createPartnerContact,
    onError: () => {
      showMessage("error", "Something went wrong");
    },
  });

  const handelSaveClicked = useCallback(async () => {
    const fullPhoneNumber = `+971${phoneNumber.trim()}`;
    const nameToSend = name.trim();
    const contact: PartnerContactModel = {
      name: nameToSend,
      phoneNumber: fullPhoneNumber,
      partnerUUID: authReducer?.partnerUUID ?? "",
    };
    const res = await createContactMutation.mutateAsync({ contact: contact });
    if (res?.success) {
      const allPartnerContacts = await getAllPartnerContacts({ partnerUUID: authReducer?.partnerUUID ?? "" });
      const contactFromAPI = allPartnerContacts?.contacts?.find(
        (c) => c.name === nameToSend && c.phoneNumber === fullPhoneNumber
      );
      queryClient.resetQueries(queryKey);
      showMessage("success", "Contact was added successfully");
      onContactCreated(contactFromAPI ?? contact);
      setName("");
      setPhoneNumber("");
      onClose();
    } else {
      showMessage("error", "Something went wrong");
    }
  }, [authReducer?.partnerUUID, createContactMutation, name, onClose, onContactCreated, phoneNumber, queryClient, queryKey]);

  return (
    <Dialog open={open} onClose={onClose} fullWidth={true} maxWidth="sm">
      <form
        ref={formRef}
        onSubmit={(e) => {
          e.preventDefault();
          handelSaveClicked();
        }}
      >
        <DialogTitle>{"Add Contact"}</DialogTitle>
        <DialogContent>
          <TextField
            label="Name"
            variant="outlined"
            fullWidth={true}
            sx={{ marginTop: "5px" }}
            size="small"
            autoFocus={true}
            required={true}
            value={name}
            onChange={(e) => {
              setName(e.target.value);
            }}
          />
          <TextField
            label="Phone Number"
            variant="outlined"
            fullWidth={true}
            sx={{ marginTop: "32px" }}
            value={phoneNumber}
            onChange={(e) => {
              setPhoneNumber(e.target.value);
            }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Typography color={"black"}>+971</Typography>
                </InputAdornment>
              ),
            }}
            size="small"
            required={true}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose}>Cancel</Button>
          <LoadingButton loading={createContactMutation.isLoading} variant="contained" disableElevation={true} type="submit">
            <Box color={"white"}>Save</Box>
          </LoadingButton>
        </DialogActions>
      </form>
    </Dialog>
  );
};

interface AddPartnerContactModalProps {
  open: boolean;
  onClose: () => void;
  onContactCreated: (contact: PartnerContactModel) => void;
}

export default AddPartnerContactModal;
