import { useQuery } from "@tanstack/react-query";
import { getAllPartnerAddresses, PartnerAddressModel } from "../services/inventory";
import { useMemo } from "react";

export default function useAllPartnerAddressesQuery({ partnerUUID }: { partnerUUID?: string }) {
  const queryKey = useMemo(() => ["inventory-requests/get-all-partner-addresses", partnerUUID], [partnerUUID]);

  const query = useQuery(queryKey, async () => getAllPartnerAddresses({ partnerUUID: partnerUUID ?? "" }), {
    enabled: !!partnerUUID,
  });

  const addressesMap = useMemo(() => {
    const value = new Map<string, PartnerAddressModel>();
    for (const address of query.data?.addresses ?? []) {
      value.set(`${address.latitude},${address.longitude}`, address);
    }
    return value;
  }, [query.data?.addresses]);

  return { query, queryKey, addressesMap };
}
