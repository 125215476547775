import { Form, FormInstance, TimePicker } from "antd";
import { FC } from "react";
import { DriverForm } from "./OldDriverProfile";

const BreakEndTime: FC<BreakEndTimeProps> = ({ form }) => {
  const startTime = Form.useWatch("startTime", form);
  const endTime = Form.useWatch("endTime", form);
  const breakStartTime = Form.useWatch("breakStartTime", form);

  return (
    <Form.Item
      name="breakEndTime"
      label="Break end time"
      style={{ width: "100%" }}
      rules={[
        () => ({
          validator(_, value) {
            if (breakStartTime && !value) {
              return Promise.reject("Please enter break end time");
            }
            if (!value) return Promise.resolve();
            if (value.isSameOrBefore(startTime) || value.isSameOrAfter(endTime)) {
              return Promise.reject("Break start time must be between shift start and end times");
            } else if (value?.isSameOrBefore(breakStartTime)) {
              return Promise.reject("Break end time must be after break start time");
            }
            return Promise.resolve();
          },
        }),
      ]}
    >
      <TimePicker
        style={{ width: "100%" }}
        minuteStep={5}
        format="hh:mmA"
        onSelect={(value) => {
          form.setFieldValue("breakEndTime", value);
        }}
      />
    </Form.Item>
  );
};

interface BreakEndTimeProps {
  form: FormInstance<DriverForm>;
}

export default BreakEndTime;
