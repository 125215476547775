import { IconButton, ListItem, ListItemText, Tooltip } from "@mui/material";
import { FC, useState } from "react";
import { PartnerUserModel } from "../../models/PartnerUserModel";
import { useSelector } from "react-redux";
import { Divider } from "antd";
import DeleteIcon from "@mui/icons-material/Delete";
import ReduxStateModel from "../../models/ReduxStateModel";
import DeleteUserModal from "./DeleteUserModal";
import EditIcon from "@mui/icons-material/Edit";
import EditUserModal from "./EditUserModal";
import { userRoleMap } from "../../constants";

const PartnerUserListItem: FC<PartnerUserListItemProps> = ({ user, isLastItem, onDeleteSuccess }) => {
  const authReducer = useSelector((state: ReduxStateModel) => state.authReducer?.setting);
  const [openDeleteModal, setDeleteModal] = useState(false);
  const [openEditModal, setOpenEditModal] = useState(false);

  return (
    <div>
      <ListItem
        key={user.partnerUserUUID}
        secondaryAction={
          <div style={{ display: "flex", alignItems: "center", gap: "16px" }}>
            {authReducer?.role === "SUPER_ADMIN" && (
              <Tooltip title="Edit User" placement="top">
                <IconButton edge="end" aria-label="edit" color="primary" onClick={() => setOpenEditModal(true)}>
                  <EditIcon />
                </IconButton>
              </Tooltip>
            )}
            {authReducer?.partnerUserUUID !== user.partnerUserUUID && authReducer?.role === "SUPER_ADMIN" && (
              <Tooltip title="Delete User" placement="top">
                <IconButton edge="end" aria-label="delete" color="error" onClick={() => setDeleteModal(true)}>
                  <DeleteIcon />
                </IconButton>
              </Tooltip>
            )}
          </div>
        }
      >
        <ListItemText primary={<span style={{ color: "black" }}>{user.name}</span>} secondary={userRoleMap[user.role ?? ""]} />
        <DeleteUserModal
          user={user}
          open={openDeleteModal}
          onClose={function (): void {
            setDeleteModal(false);
          }}
          onDeleteSuccess={onDeleteSuccess}
        />
        <EditUserModal
          open={openEditModal}
          user={user}
          onClose={function (): void {
            setOpenEditModal(false);
          }}
          onEditSuccess={onDeleteSuccess}
        />
      </ListItem>
      {!isLastItem && <Divider style={{ margin: "0px" }} />}
    </div>
  );
};

interface PartnerUserListItemProps {
  user: PartnerUserModel;
  isLastItem: boolean;
  onDeleteSuccess: () => void;
}

export default PartnerUserListItem;
