import { authType } from "../types";

export const initialState = {
  setting: {},
};

export function authReducer(state = initialState, action) {
  switch (action.type) {
    case authType.LOG_OUT:
      return {
        setting: {},
      };
    case authType.SAVE_SETTINGS:
      return {
        ...state,
        setting: action.data,
      };
    case authType.CLEAR_SETTINGS:
      return {
        ...state,
        setting: {},
      };

    default: {
      return {
        ...state,
      };
    }
  }
}
