import { LoadingButton } from "@mui/lab";
import { Button, Dialog, DialogContent, DialogTitle } from "@mui/material";
import { FC, useCallback } from "react";
import PartnerUserInviteModel from "../../models/PartnerUserInviteModel";
import { useMutation } from "@tanstack/react-query";
import { deletePartnerUserInvite } from "../../services/partner.service";
import { useSelector } from "react-redux";
import ReduxStateModel from "../../models/ReduxStateModel";
import { showMessage } from "../../components/common/notification";

const DeletePartnerUserInviteModal: FC<DeletePartnerUserInviteModalProps> = ({ onDeleteSuccess, onClose, open, invite }) => {
  const authReducer = useSelector((state: ReduxStateModel) => state.authReducer?.setting);
  const deleteMutation = useMutation({ mutationFn: deletePartnerUserInvite });

  const handleYesClicked = useCallback(async () => {
    const res = await deleteMutation.mutateAsync({ currentUserID: authReducer?.partnerUserUUID ?? "", id: invite._id ?? "" });
    if (res?.success) {
      showMessage("success", "Invite was deleted successfully");
      onClose();
      onDeleteSuccess();
    } else {
      showMessage("error", "Something went wrong");
    }
  }, [authReducer?.partnerUserUUID, deleteMutation, invite._id, onClose, onDeleteSuccess]);

  return (
    <Dialog open={open} onClose={onClose} aria-labelledby="delete-partner-user-modal" maxWidth="sm" fullWidth={true}>
      <DialogTitle sx={{ textAlign: "center" }}>
        Are you sure you would like to delete the invitation for {invite.email} ?
      </DialogTitle>
      <DialogContent>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <Button variant="outlined" onClick={onClose}>
            Cancel
          </Button>
          <LoadingButton variant="outlined" color="error" onClick={handleYesClicked} loading={deleteMutation.isLoading}>
            Yes, Delete
          </LoadingButton>
        </div>
      </DialogContent>
    </Dialog>
  );
};

interface DeletePartnerUserInviteModalProps {
  open: boolean;
  invite: PartnerUserInviteModel;
  onClose: () => void;
  onDeleteSuccess: () => void;
}

export default DeletePartnerUserInviteModal;
