import moment from "moment";
import schedulerExploreViewTypes from "../types/schedulerExploreViewType";
import { NurseBookingModel } from "../models/SchedulerExploreViewResponseModel";

export const setExploreViewNurseDayBookings = (bookings: { [prop: string]: NurseBookingModel[] }) => {
  return {
    type: schedulerExploreViewTypes.SET_NURSE_DAY_BOOKINGS,
    payload: bookings,
  };
};

export const setSelectedWeekAction = (weekStart: moment.Moment) => {
  return {
    type: schedulerExploreViewTypes.EXPLORE_VIEW_SET_SELECTED_WEEK,
    payload: weekStart,
  };
};

export const setSelectedDayAction = (day: moment.Moment) => {
  return { type: schedulerExploreViewTypes.SET_SELECTED_DAY, payload: day };
};

export const setSelectedMonthAction = (monthStart: moment.Moment, monthEnd: moment.Moment) => {
  return { type: schedulerExploreViewTypes.SET_SELECTED_MONTH, payload: { startMoment: monthStart, endMoment: monthEnd } };
};
