import { FC } from "react";
import BoardViewCardList from "./BoardViewCardList";
import usePharmacyOrders from "../../hooks/usePharmacyOrders";

const BoardView: FC<BoardViewProps> = () => {
  const { query: newOrdersQuery } = usePharmacyOrders({ status: "PRESCRIPTION_UPLOADED" });
  const { query: dispatchedOrdersQuery } = usePharmacyOrders({ status: "DISPATCHED" });
  const { query: deliveredOrdersQuery } = usePharmacyOrders({ status: "DELIVERED" });

  return (
    <>
      <div
        style={{
          display: "flex",
          gap: "16px",
          height: "100%",
          maxHeight: "100%",
          overflow: "scroll",
          backgroundColor: "#f9f8f8",
          padding: "16px",
          width: "100%",
          maxWidth: "100%",
        }}
        className="board-view"
      >
        <BoardViewCardList title={"New"} loading={newOrdersQuery.isLoading} orders={newOrdersQuery.data?.pharmacyOrders ?? []} />
        <BoardViewCardList
          title={"Dispatched"}
          loading={dispatchedOrdersQuery.isLoading}
          orders={dispatchedOrdersQuery.data?.pharmacyOrders ?? []}
        />
        <BoardViewCardList
          title={"Delivered"}
          loading={deliveredOrdersQuery.isLoading}
          orders={deliveredOrdersQuery.data?.pharmacyOrders ?? []}
        />
      </div>
    </>
  );
};

interface BoardViewProps {}

export default BoardView;
