import { completebookingType } from "../types";

export const initialState = {
  unreportedorders: [],
  reportedOrders: [],
  loader: false,
  showComplete: false,
};

export function completebookingReducer(state = initialState, action) {
  switch (action.type) {
    case completebookingType.REQUEST_COMPLETE_REPORTING:
      return {
        ...state,
        loader: true,
      };

    case completebookingType.REQUEST_COMPLETE_REPORTING_DONE:
      return {
        ...state,
        loader: false,
      };
    case completebookingType.GET_COMPLETE_UNREPORTED_ORDERS:
      return {
        ...state,
        unreportedorders: action.data,
        loader: false,
      };

    case completebookingType.CHECK_COMPLETE_VIEW_EXIST:
      return {
        ...state,
        showComplete: action?.data?.length > 0 ? true : false,
      };

    case completebookingType.GET_COMPLETE_REPORTED_ORDERS:
      return {
        ...state,
        reportedOrders: action.data,
        loader: false,
      };

    default: {
      return {
        ...state,
      };
    }
  }
}
