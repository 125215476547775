import { useQuery } from "@tanstack/react-query";
import { getInventoryList, PartnerInventoryItemModel } from "../services/inventory";
import { useMemo } from "react";

export default function useInventoryListQuery() {
  const queryKey = useMemo(() => ["inventory-requests/get-inventory-list"], []);

  const query = useQuery(queryKey, getInventoryList);

  const itemsMap = useMemo(() => {
    const value = new Map<string, PartnerInventoryItemModel>();
    for (const item of query.data?.list ?? []) {
      value.set(item.name ?? "", item);
    }
    return value;
  }, [query.data?.list]);

  return { query, queryKey, itemsMap };
}
