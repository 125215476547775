import { red, orange, grey } from "@mui/material/colors";
import { useQueryClient } from "@tanstack/react-query";
import { useState } from "react";
import { useHistory } from "react-router-dom";
import useBookingHasDelayedNurse from "../../hooks/useBookingHasDelayedNurse";
import useBookingHasUnassignedDates from "../../hooks/useBookingHasUnassignedDates";
import usePartnerOrders from "../../hooks/usePartnerOrders";
import BookingModel from "../../models/BookingModel";

export default function useBookingsTableRow({ booking: bookingProp }: { booking: BookingModel }) {
  const history = useHistory();
  // const dispatch = useDispatch();
  const queryClient = useQueryClient();
  const [booking, setBooking] = useState<BookingModel>(() => ({ ...bookingProp }));

  const hasUnassignedDates = useBookingHasUnassignedDates({ order: booking });
  const { delayedNurseData } = useBookingHasDelayedNurse({ order: booking });
  const showDelayedServiceAlert = !!delayedNurseData;

  const { queryKey } = usePartnerOrders({
    status: "ACCEPTED",
    loadMoreElementInView: false,
    enabled: true,
  });

  let border: string | undefined;
  let borderColor: string;
  if (hasUnassignedDates) {
    borderColor = red[500];
    border = `1px solid ${borderColor}`;
  } else if (showDelayedServiceAlert) {
    borderColor = orange[400];
    border = `1px solid ${borderColor}`;
  } else {
    borderColor = grey[400];
  }

  return {
    border,
    queryKey,
    queryClient,
    delayedNurseData,
    history,
    booking,
    showDelayedServiceAlert,
    borderColor,
    hasUnassignedDates,
    setBooking,
  };
}
