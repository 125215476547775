import { useQuery } from "@tanstack/react-query";
import { GetUserDataResponseModel, authService } from "../services";
import { useSelector } from "react-redux";
import { useMemo } from "react";
import ReduxStateModel from "../models/ReduxStateModel";

function useUserData({ onSuccess }: { onSuccess?: (res: { data?: GetUserDataResponseModel }) => void }) {
  const authReducer = useSelector((state: ReduxStateModel) => state.authReducer?.setting);

  const queryKey = useMemo(
    () => ["/api/v1/partneraccounts/userdata", authReducer?.phoneNumber, authReducer?.userName],
    [authReducer?.phoneNumber, authReducer?.userName]
  );

  const query = useQuery(
    queryKey,
    () =>
      authService.getUserData({
        phoneNumber: authReducer?.phoneNumber ?? "",
        userName: authReducer?.userName ?? "",
      }),
    { enabled: !!authReducer?.phoneNumber || !!authReducer?.userName, onSuccess: onSuccess }
  );

  return { query, queryKey };
}

export default useUserData;
