import { TableCell, TableRow } from "@mui/material";
import { FC, useMemo } from "react";
import PartnerInventoryListItemModel from "../../models/PartnerInventoryListItemModel";
import useInventoryListQuery from "../../hooks/useInventoryListQuery";

const DeliveryPartnerIngredientListItem: FC<DeliveryPartnerIngredientListItemProps> = ({ item }) => {
  const { itemsMap } = useInventoryListQuery();

  const itemPrice = useMemo(() => itemsMap.get(item.name ?? "")?.supplierPrice ?? 0, [item.name, itemsMap]);

  const totalPrice = useMemo(() => {
    return itemPrice * (item.quantity ?? 0);
  }, [item.quantity, itemPrice]);

  return (
    <TableRow key={item.name}>
      <TableCell component="th" scope="row">
        {item.name}
      </TableCell>
      <TableCell align="center" component="th" scope="row">
        {`AED ${itemPrice.toLocaleString()}`}
      </TableCell>
      <TableCell align="center">{item.quantity}</TableCell>
      <TableCell align="center">{`AED ${totalPrice}`}</TableCell>
    </TableRow>
  );
};

interface DeliveryPartnerIngredientListItemProps {
  item: PartnerInventoryListItemModel;
}

export default DeliveryPartnerIngredientListItem;
