import { FC, useMemo, useState } from "react";
import { initialMapCenter } from "../../constants";
import { Spin } from "antd";
import { Wrapper } from "@googlemaps/react-wrapper";
import { GeneratedDriverScheduleItem } from "../../services";
import { Box } from "@mui/material";
import { useSelector } from "react-redux";
import useAutomatedDriverScheduleQuery from "../../hooks/useAutomatedDriverScheduleQuery";
import RenderGoogleMapsWrapper from "../../components/common/RenderGoogleMapsWrapper";
import GoogleMap, { Marker } from "../../components/GoogleMap";
import mapStyles from "../../assets/google-map-styles.json";
import MapDriverPolyline from "../AutoGeneratedDriverSchedule/MapDriverPolyline";
import moment from "moment";
import AutoNavMapViewControlsCard from "./AutoNavMapViewControlsCard";
import ReduxStateModel from "../../models/ReduxStateModel";
import useAppSettings from "../../hooks/useAppSettings";

const AutoNavMapView: FC<AutoNavMapViewProps> = () => {
  const { query } = useAppSettings();
  const googleAPIKey = query.data?.key?.googleKey;
  const selectedDate: moment.Moment = useSelector(
    (state: ReduxStateModel) => state?.driverSchedulerReducer?.selectedDate ?? moment()
  );
  const [map, setMap] = useState<google.maps.Map | undefined>();

  const {
    query: { isLoading: generatedScheduleIsLoading },
  } = useAutomatedDriverScheduleQuery({
    selectedDate,
  });

  const cardGroups: GeneratedDriverScheduleItem[][] = useSelector(
    (state: ReduxStateModel) => state?.driverSchedulerReducer?.tasksCardGroups ?? []
  );
  const selectedCellID = useSelector((state: ReduxStateModel) => state?.driverSchedulerReducer?.selectedCellID);

  const selectedCardGroup = useMemo<GeneratedDriverScheduleItem[] | undefined>(() => {
    return cardGroups.find((group) => {
      return `${group[0].driverName}-${group[0].time}-${group[0].nurseName}` === selectedCellID;
    });
  }, [cardGroups, selectedCellID]);

  const driverPolyline = useMemo<JSX.Element | undefined>(() => {
    if (!map) return;
    if (!selectedCardGroup?.length) return;
    return (
      <MapDriverPolyline
        strokeColor="black"
        key={selectedCardGroup
          .map<string>(
            (task) => `${task.bookingUUID}-${task.driverName}-${task.locationLink}-${task.nurseName}-${task.time}-${task.type}`
          )
          .join("-")}
        driverStops={selectedCardGroup}
        selectedDate={selectedDate}
        driverName={selectedCardGroup[0].driverName}
        map={map}
      />
    );
    // return [
    // ];
  }, [map, selectedCardGroup, selectedDate]);

  const mapMarkers = useMemo<JSX.Element[] | undefined>(() => {
    const markers: JSX.Element[] = [];
    if (!map) return markers;
    return selectedCardGroup?.map<JSX.Element>((stop) => {
      const locationURL = new URL(stop.locationLink);
      const [lat, lng] = locationURL.searchParams.get("q")?.split(",") ?? [];
      return (
        <Marker
          key={`${stop.driverName}_${stop.locationLink}_${selectedDate.format()}_${stop.time}_${stop.type}_${stop.bookingUUID}_${
            stop.nurseName
          }`}
          icon={{
            path: "M10,0A10,10 0 1,0 20,10A10,10 0 0,0 10,0Z",
            fillColor: "#000000",
            fillOpacity: 1,
            strokeWeight: 0,
            rotation: 0,
            scale: 0.5,
            anchor: new google.maps.Point(0, 0),
          }}
          map={map}
          toolTipContent={`<div>
      <div style="font-weight: 600; font-size: 10px">${
        stop.type === "delivery" && stop.driverName === "unassigned" ? "---" : stop.time
      }</div>
      <div style="font-weight: 500; font-size: 12px; color: black">${stop.type === "pickup" ? "Pickup" : "Drop"} ${
            stop.nurseName
          }</div>
      </div>`}
          position={{ lat: +lat, lng: +lng }}
          title={stop.driverName}
        />
      );
    });
  }, [map, selectedCardGroup, selectedDate]);

  return (
    <Box
      sx={{
        flex: "2",
        position: "relative",
        "@media (max-width: 700px)": {
          display: "none",
        },
      }}
    >
      {driverPolyline}
      <div
        style={{
          position: "absolute",
          top: "20px",
          left: "0px",
          right: "0px",
          zIndex: "1",
          display: "flex",
          justifyContent: "center",
        }}
      >
        <Spin spinning={generatedScheduleIsLoading}>
          <AutoNavMapViewControlsCard />
        </Spin>
      </div>
      {googleAPIKey && (
        <Wrapper render={RenderGoogleMapsWrapper} libraries={["places"]} apiKey={googleAPIKey}>
          <GoogleMap
            center={initialMapCenter}
            zoom={12}
            onMapCreated={(map) => {
              setMap(map);
              map.setOptions({
                styles: mapStyles,
                mapTypeControl: false,
                zoomControl: true,
                fullscreenControl: false,
                streetViewControl: false,
              });
            }}
            style={{
              height: "100%",
              width: "100%",
            }}
          >
            {mapMarkers}
          </GoogleMap>
        </Wrapper>
      )}
    </Box>
  );
};

interface AutoNavMapViewProps {}

export default AutoNavMapView;
// function mapGeneratedScheduleResponseToCardGroupsArray(arg0: {
//   [driverName: string]: import("../../services").GeneratedDriverScheduleItem[];
// }): any {
//   throw new Error("Function not implemented.");
// }
