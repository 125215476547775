import { Form, Input, Button, Carousel, Modal, Spin } from "antd";
import { FC } from "react";
import { LoadingOutlined } from "@ant-design/icons";
import Slide1 from "../../components/Slide1/Slide1";
import logo from "./logo.svg";
import envelope from "./envelope.png";
import useNewSignupPage from "./useNewSignupPage";
import moment from "moment";

const NewSignupPage: FC = () => {
  const { form, showConfirmationLinkSentModal, signupUserMutation, handleFormFinishedValidation, handleLoginClick } =
    useNewSignupPage();

  const email: string | undefined = Form.useWatch("email", form);

  return (
    <div>
      <Form
        form={form}
        layout="vertical"
        onFinish={handleFormFinishedValidation}
        // onFinishFailed={onFinishFailed}
        // form={form}
        autoComplete="off"
      >
        <div style={{ maxWidth: "2000px" }}>
          <div
            style={{
              display: "flex",
              justifyContent: "start",
              alignSelf: "start",
              backgroundColor: "white",
              minHeight: "100vh",
            }}
          >
            <div>
              <img style={{ width: "40px", margin: "30px", cursor: "pointer" }} src={logo} alt="DarDoc" />
            </div>
            <div
              style={{
                display: "flex",
                paddingRight: "8rem",
                width: "70vh",
                flexDirection: "column",
                justifyContent: "center",
                alignSelf: "center",
                minHeight: "80vh",
              }}
            >
              <Spin spinning={signupUserMutation.isLoading} indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />}>
                <h1
                  style={{
                    fontWeight: "bold",
                    fontSize: "30px",
                    marginBottom: "20px",
                  }}
                >
                  Sign Up
                </h1>
                <Form.Item
                  label="Email"
                  name="email"
                  rules={[
                    {
                      type: "email",
                      message: "Please enter a valid email address!",
                    },
                    {
                      required: true,
                      message: "Please enter your email address!",
                    },
                  ]}
                >
                  <Input
                    placeholder="Enter your email"
                    style={{
                      minWidth: "400px",
                      minHeight: "40px",
                      borderRadius: "4px",
                    }}
                  />
                </Form.Item>
                <Form.Item
                  label="First Name"
                  name="firstName"
                  rules={[
                    {
                      required: true,
                      message: "Please enter your first name!",
                    },
                  ]}
                >
                  <Input
                    placeholder="Enter your first name"
                    style={{
                      minWidth: "400px",
                      minHeight: "40px",
                      borderRadius: "4px",
                    }}
                  />
                </Form.Item>
                <Form.Item
                  label="Last Name"
                  name="lastName"
                  rules={[
                    {
                      required: true,
                      message: "Please enter your last name!",
                    },
                  ]}
                >
                  <Input
                    placeholder="Enter your last name"
                    style={{
                      minWidth: "400px",
                      minHeight: "40px",
                      borderRadius: "4px",
                    }}
                  />
                </Form.Item>
                <Form.Item
                  label="Organisation Name"
                  name="organizationName"
                  rules={[
                    {
                      required: true,
                      message: "Please enter your organisation name!",
                    },
                  ]}
                >
                  <Input
                    placeholder="Enter your organisation name"
                    style={{
                      minWidth: "400px",
                      minHeight: "40px",
                      borderRadius: "4px",
                    }}
                  />
                </Form.Item>
                <Form.Item>
                  <Button
                    type="primary"
                    htmlType="submit"
                    className="login-btn custom-button-hover"
                    style={{
                      borderRadius: "4px",
                      color: "#ffffff",
                      marginTop: "1.5rem",
                      fontSize: "15px",
                      minWidth: "450px",
                      minHeight: "40px",
                      border: "none",
                      textAlign: "center",
                    }}
                  >
                    Sign up
                  </Button>
                </Form.Item>

                <p style={{ alignSelf: "center", color: "#7d7d7d" }}>
                  Already have an account?{" "}
                  <span
                    style={{
                      cursor: "pointer",
                      color: "#25bcbd",
                      fontWeight: 500,
                    }}
                    onClick={handleLoginClick}
                  >
                    Log in
                  </span>
                </p>

                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "center",
                    marginTop: "1rem",
                  }}
                >
                  <p
                    style={{
                      position: "fixed",
                      bottom: 0,
                      fontSize: "12px",
                      color: "#7D7D7D",
                    }}
                  >
                    © DarDoc Health Technologies Limited ADGM {moment().format("YYYY")}
                  </p>
                </div>
              </Spin>
            </div>

            {/* –----------------------Right Section–---------------------- */}

            <div
              style={{
                background: "linear-gradient(150.57deg, #FEFEFF 9.28%, rgba(249, 248, 254, 0.95) 98.71%)",
                minHeight: "80vh",
                margin: "1rem",
                padding: "1rem",
                paddingTop: "5vh",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignSelf: "center",
                width: "50vh",
                border: "1.5px solid #eee",
                borderRadius: "20px",
              }}
              className="teal-section "
            >
              <Carousel autoplay>
                <Slide1
                  url="slide1"
                  title="Driver Scheduler"
                  desc="Ride-sharing platform aim to match drivers with riders in an efficient manner while maximizing utilization and minimizing wait times."
                />
                <Slide1
                  url="slide2"
                  title="Staff Scheduler"
                  desc="Manage nurse bookings, track availability and leave, enable efficient scheduling of nursing staff for optimal patient care."
                />
              </Carousel>
            </div>
          </div>
        </div>
      </Form>

      <Modal
        open={showConfirmationLinkSentModal}
        onOk={handleLoginClick}
        onCancel={handleLoginClick}
        cancelButtonProps={{ style: { display: "none" } }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            alignContent: "center",
          }}
        >
          <h1
            style={{
              color: "#1e1e1e",
              marginBottom: "2rem",
              marginTop: "0.5rem",
              fontWeight: "500",
              // fontFamily: "helvetica-medium",
            }}
          >
            You've Got Mail!
          </h1>
          <img style={{ width: "120px" }} src={envelope} alt="email" />
          <p
            style={{
              marginBottom: "0px",
              marginTop: "1.5rem",
              fontWeight: "400",
            }}
          >
            We sent a confirmation link to:
          </p>
          <h3
            style={{
              // fontFamily: "helvetica-bold",
              fontWeight: "600",
              color: "#1e1e1e",
              marginTop: "0px",
            }}
          >
            {email}
          </h3>
        </div>
      </Modal>
      {/* {isModal2Visible && (
          <Modal
            title="Reset Email"
            visible={isModal2Visible}
            onOk={handleOk}
            onCancel={handleOk}
          >
            <p>Enter your email address:</p>
            <Input
              placeholder="Email"
              value={email}
              onChange={handleEmailChange}
            />
          </Modal>
        )} */}
      {/* {isModal3Visible && (
        <Modal open={isModal3Visible} onOk={handleOk} onCancel={handleOk}>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              alignContent: "center",
            }}
          >
            <h1
              style={{
                color: "#1e1e1e",
                marginBottom: "2rem",
                marginTop: "0.5rem",
                fontWeight: "black",
                fontFamily: "helvetica-medium",
              }}
            >
              You've Got Mail!
            </h1>
            <img style={{ width: "120px" }} src={envelope} alt="email" />
            <p
              style={{
                marginBottom: "0px",
                marginTop: "1.5rem",
                fontWeight: "400",
              }}
            >
              We sent a confirmation link to:
            </p>
            <h3
              style={{
                fontFamily: "helvetica-bold",
                color: "#1e1e1e",
                marginTop: "0px",
              }}
            >
              {email}
            </h3>
          </div>
        </Modal>
      )} */}
    </div>
  );
};

export default NewSignupPage;
