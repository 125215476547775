import axios from "../utils/axiosHelpers";
import decrypt from "./decrypt";
import encrypt from "./encrypt";

export const getUserData = async function ({
  email,
  firstName,
  lastName,
  organizationName,
  link,
}) {
  try {
    const body = {
      email,
      firstName,
      lastName,
      organizationName,
      link,
    };

    const encryptedBody = encrypt(body);
    // console.log("Encrypted user data:", encryptedBody);

    const data = await axios.post(`/api/v1/partners/signup`, {
      data: encryptedBody,
    });
    console.log("-------SignUp Data-------", data);
    const res = { ...data, data: decrypt(data?.data) };

    console.log("-------Decrypted Data-------", decrypt(data?.data));
    return res;
  } catch (e) {
    console.log("--- getUserData error");
    console.log(e);
    return null;
  }
};
