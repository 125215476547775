import { useEffect, useState } from "react";
import { getPartnerEligibleProducts } from "../services/analytics.service";
import { useSelector } from "react-redux";
import ReduxStateModel from "../models/ReduxStateModel";

function useEligibleProductsForPartner(): [boolean, PartnerEligibleProductModel[] | null | undefined] {
  const [loading, setLoading] = useState<boolean>(true);
  const [products, setProducts] = useState<PartnerEligibleProductModel[] | null | undefined>(null);
  const partnerUserUUID = useSelector((state: ReduxStateModel) => state?.authReducer?.setting?.partnerUserUUID);

  useEffect(() => {
    if (!partnerUserUUID) return;
    (async () => {
      const res = await getPartnerEligibleProducts(partnerUserUUID);
      setProducts(res?.data?.products);
      setLoading(false);
    })();
  }, [partnerUserUUID]);

  return [loading, products];
}

export interface PartnerEligibleProductModel {
  key: string;
  value: string;
}

export default useEligibleProductsForPartner;
