import { FC } from "react";
import { Button } from "@mui/material";
import UploadReportSelectPatientField from "./UploadReportSelectPatientField";
import UploadReportDraggerField from "./UploadReportDraggerField";
import LoadingButton from "@mui/lab/LoadingButton";
import UploadReportSelectTestsField from "./UploadReportSelectTestsField";
import BookingModel from "../../models/BookingModel";
import BaseResponseModel from "../../models/BaseResponseModel";
import useUploadBookingReportForm from "../../hooks/useUploadBookingReportForm";
import { Col, Row } from "antd";

const UploadBookingReportForm: FC<UploadBookingReportFormProps> = (props) => {
  const { booking, onCancelClicked } = props;
  const {
    disableUploadButton,
    selectedPatiet,
    didSelectAllTests,
    submitReportMutation,
    selectedTests,
    testsOptionsList,
    pdfFile,
    setSelectedTests,
    setPDFFile,
    handleSelectAllTestsClicked,
    onPatientChanged,
    handleUploadClicked,
  } = useUploadBookingReportForm(props);

  return (
    <Row
      style={{
        rowGap: "16px",
      }}
    >
      <Col span={24} style={{ marginTop: "9px" }}>
        <UploadReportSelectPatientField
          disabled={booking.patient?.length === 1}
          patients={booking.patient ?? []}
          onChange={onPatientChanged}
          value={selectedPatiet}
        />
      </Col>
      <Col span={24}>
        <UploadReportSelectTestsField
          options={testsOptionsList}
          value={selectedTests}
          didSelectAllTests={didSelectAllTests}
          onSelectAllClicked={handleSelectAllTestsClicked}
          onChange={setSelectedTests}
        />
      </Col>
      <Col span={24}>
        <UploadReportDraggerField file={pdfFile} onChange={setPDFFile} />
      </Col>
      <Col span={24}>
        <Row
          style={{
            justifyContent: "space-between",
          }}
        >
          <Button variant="outlined" onClick={onCancelClicked}>
            Cancel
          </Button>
          <LoadingButton
            variant="contained"
            sx={{ color: "white" }}
            disabled={disableUploadButton}
            onClick={handleUploadClicked}
            loading={submitReportMutation.isLoading}
          >
            <span style={{ color: "white" }}>Upload</span>
          </LoadingButton>
        </Row>
      </Col>
    </Row>
  );
};

export interface UploadBookingReportResponseModel extends BaseResponseModel {
  order?: BookingModel;
}

export interface UploadBookingReportFormProps {
  onCancelClicked: () => void;
  onReportUploadedSuccess: (order: BookingModel) => void;
  booking: BookingModel;
}

export default UploadBookingReportForm;
