import { useQuery } from "@tanstack/react-query";
import { getOutsourceInventoryItems } from "../services/inventory";
import { useMemo } from "react";

export default function useOutsourceInventoryItemsQuery() {
  const queryKey = useMemo(() => ["inventory-requests/get-outsource-inventory-items"], []);

  const query = useQuery(queryKey, getOutsourceInventoryItems);

  return { query };
}
