import { Button, Dialog, DialogContent, DialogTitle } from "@mui/material";
import { FC, useCallback } from "react";
import { PartnerUserModel } from "../../models/PartnerUserModel";
import { LoadingButton } from "@mui/lab";
import { useMutation } from "@tanstack/react-query";
import { deletePartnerUser } from "../../services/partner.service";
import { useSelector } from "react-redux";
import ReduxStateModel from "../../models/ReduxStateModel";
import { showMessage } from "../../components/common/notification";

const DeleteUserModal: FC<DeleteUserModalProps> = ({ onClose, open, user, onDeleteSuccess }) => {
  const authReducer = useSelector((state: ReduxStateModel) => state.authReducer?.setting);
  const deleteMutation = useMutation({ mutationFn: deletePartnerUser });

  const handleYesClicked = useCallback(async () => {
    const res = await deleteMutation.mutateAsync({
      currentUserID: authReducer?.partnerUserUUID ?? "",
      partnerUserUUID: user.partnerUserUUID ?? "",
    });
    if (res?.success) {
      showMessage("success", "User was deleted successfully");
      onClose();
      onDeleteSuccess();
    } else {
      showMessage("error", "Something went wrong");
    }
  }, [authReducer?.partnerUserUUID, deleteMutation, onClose, onDeleteSuccess, user.partnerUserUUID]);

  return (
    <Dialog open={open} onClose={onClose} aria-labelledby="delete-partner-user-modal" maxWidth="sm" fullWidth={true}>
      <DialogTitle sx={{ textAlign: "center" }}>Are you sure you would like to delete user {user.name} ?</DialogTitle>
      <DialogContent>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <Button variant="outlined" onClick={onClose}>
            Cancel
          </Button>
          <LoadingButton variant="outlined" color="error" onClick={handleYesClicked}>
            Yes, Delete
          </LoadingButton>
        </div>
      </DialogContent>
    </Dialog>
  );
};

interface DeleteUserModalProps {
  open: boolean;
  user: PartnerUserModel;
  onDeleteSuccess: () => void;
  onClose: () => void;
}

export default DeleteUserModal;
