import { useMutation } from "@tanstack/react-query";
import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { authService } from "../../services";
import { showMessage } from "../common/notification";
import useHandleUserAuthSuccess from "../../hooks/useHandleUserAuthSuccess";
import { PartnerSettingModel } from "../../models/ReduxStateModel";

function useNewLoginPage() {
  const history = useHistory();
  const [email, setEmail] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [showDraftStatusModal, setShowDraftStatusModal] = useState<boolean>(false);
  const { handleUserAuthSuccess } = useHandleUserAuthSuccess();

  const loginUserMutation = useMutation({ mutationFn: authService.userLogin });

  const handleSignup = () => {
    history.push("/signup");
  };

  // const handleForgetPassword = () => {
  //   history.push("/forgetpassword");
  // };

  const handleFormSubmitted = async () => {
    if (!email.trim().length || !password.trim().length) {
      return showMessage("error", "Please enter your email and password");
    }
    const res = await loginUserMutation.mutateAsync({
      username: email,
      password: password,
    });
    if (!res?.data?.success) {
      return showMessage("error", "Invalid credentials");
    }
    handleLoginSuccess(res.data.settings);
  };

  // function iOS() {
  //   return (
  //     ["iPad Simulator", "iPhone Simulator", "iPod Simulator", "iPad", "iPhone", "iPod"].includes(navigator.platform) ||
  //     // iPad on iOS 13 detection
  //     (navigator.userAgent.includes("Mac") && "ontouchend" in document)
  //   );
  // }
  const handleLoginSuccess = (authSettings: PartnerSettingModel) => {
    // console.log("login api response", authSettings);
    // console.log("status", authSettings?.status);
    handleUserAuthSuccess(authSettings);

    // if (iOS()) history.push("/acceptedbookings");
    // else
    history.push("/");

    // if (authSettings?.status === "DRAFT" && authSettings?.tradeLicenseUrl && authSettings?.healthLicenseUrl) {
    //   setShowDraftStatusModal(true);
    //   console.log("Status in DRAFT");
    // } else if (authSettings?.status === "DRAFT" && !authSettings?.tradeLicenseUrl && !authSettings?.healthLicenseUrl) {
    //   history.push("/uploadlicense", { authSettings });
    //   console.log("No licenses");
    // } else if (authSettings?.status === "ACTIVE" && authSettings?.tradeLicenseUrl && authSettings?.healthLicenseUrl) {
    //   handleUserAuthSuccess(authSettings);
    //   history.push("/");
    // }
  };

  useEffect(() => {
    document.title = "DarDoc: Login";
  }, []);

  return {
    email,
    showPassword,
    password,
    loginUserMutation,
    showDraftStatusModal,
    setShowDraftStatusModal,
    setShowPassword,
    setEmail,
    setPassword,
    handleSignup,
    handleFormSubmitted,
  };
}

export default useNewLoginPage;
