import { Autocomplete, Box, Button, TextField, Tooltip } from "@mui/material";
import { FC, useMemo, useState } from "react";
import { PartnerAddressModel } from "../services/inventory";
import { useSelector } from "react-redux";
import ControlPointOutlinedIcon from "@mui/icons-material/ControlPointOutlined";
import useAllPartnerAddressesQuery from "../hooks/useAllPartnerAddressesQuery";
import ReduxStateModel from "../models/ReduxStateModel";
import AddPartnerAddressModal from "./AddPartnerAddressModal";

const AddInventorySelectAddress: FC<AddInventorySelectAddressProps> = ({ onAddressCreated, onChange, value }) => {
  const authReducer = useSelector((state: ReduxStateModel) => state.authReducer?.setting);
  const [showAddAddressModal, setShowAddAddressModal] = useState(false);

  const { query } = useAllPartnerAddressesQuery({ partnerUUID: authReducer?.partnerUUID });

  const strValue = useMemo(() => {
    if (!value) return null;
    return value.streetName ?? null; // `${value.latitude},${value.longitude}`;
  }, [value]);

  return (
    <>
      <Box display={"flex"} gap={2} marginTop={4}>
        <Autocomplete
          fullWidth={true}
          size="small"
          multiple={false}
          value={strValue}
          loading={query.isLoading}
          onChange={(_, values) => {
            const value = values;
            if (!value) {
              return onChange(null);
            }
            // const [latitude, longitude] = value.split(",");
            const contactFromQuery =
              query.data?.addresses?.find((address) => {
                return address.streetName === value;
              }) ?? null;
            onChange(contactFromQuery);
          }}
          options={query.data?.addresses.map((address) => address.streetName) ?? []}
          renderOption={(props, option) => {
            // const address = addressesMap.get(option);
            return (
              <Box {...props} component="li">
                {option}
              </Box>
            );
          }}
          renderInput={(params) => <TextField {...params} label={"Select address"} size="small" />}
        />
        <Tooltip title="Add address">
          <Button
            variant="outlined"
            size="small"
            onClick={() => {
              setShowAddAddressModal(true);
            }}
          >
            <ControlPointOutlinedIcon />
          </Button>
        </Tooltip>
      </Box>
      <AddPartnerAddressModal
        open={showAddAddressModal}
        onAddressCreated={onAddressCreated}
        onClose={function (): void {
          setShowAddAddressModal(false);
        }}
      />
    </>
  );
};

interface AddInventorySelectAddressProps {
  value: PartnerAddressModel | null;
  onChange: (address: PartnerAddressModel | null) => void;
  onAddressCreated: (address: PartnerAddressModel) => void;
}

export default AddInventorySelectAddress;
