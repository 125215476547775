import { Alert, Button, Divider, Form, Modal, Select, TimePicker, Tooltip, Typography } from "antd";
import { FC } from "react";
import { CalendarViewType } from ".";
import ModalHeaderProfessionalAvatar from "../SchdulerDetailView/ModalHeaderProfessionalAvatar";
import useAddBreakTimeModal from "./useAddBreakTimeModal";

const AddBreakTimeModal: FC<AddBreakTimeModalProps> = ({ viewType }) => {
  const { history, momentUserClickOnFromCalendar, form, checkNurseAvailableResponse, handleFormFinishedValidation } =
    useAddBreakTimeModal({ viewType: viewType });

  return (
    <Modal
      centered={true}
      width={700}
      open={true}
      maskClosable={false}
      footer={null}
      className={`scheduler-modal scheduler-assign-patients-modal`}
      title={
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          {history.location.state?.nurseData && (
            <ModalHeaderProfessionalAvatar professionalData={history.location.state?.nurseData} />
          )}
        </div>
      }
    >
      <Form layout="vertical" size="large" form={form} onFinish={handleFormFinishedValidation}>
        {(checkNurseAvailableResponse?.assignNurseErrors?.length ?? 0) > 0 && (
          <Alert
            type="error"
            message={
              <div style={{ display: "flex", flexDirection: "column", gap: "8px" }}>
                {checkNurseAvailableResponse?.assignNurseErrors?.map((errorMsg, index) => {
                  return (
                    <div key={index} style={{ textAlign: "center" }}>
                      <Typography.Text strong={true} style={{ textAlign: "center" }}>
                        {errorMsg}
                      </Typography.Text>
                    </div>
                  );
                })}
              </div>
            }
          />
        )}
        <div style={{ padding: "1rem" }}>
          <h6>Add break time</h6>
          <h4 style={{ fontWeight: "600" }}>{momentUserClickOnFromCalendar.format("DD MMMM dddd")}</h4>
          <div style={{ display: "flex", gap: "1rem" }}>
            <Form.Item
              name="startTime"
              label="Start Time"
              style={{ width: "100%" }}
              rules={[
                {
                  required: true,
                  message: "Please select the start time",
                },
              ]}
            >
              <TimePicker
                style={{ width: "100%" }}
                format="hh:mm A"
                showSecond={false}
                minuteStep={30}
                showNow={false}
                onSelect={(value) => {
                  form.setFieldValue("startTime", value);
                }}
              />
            </Form.Item>
            <Form.Item
              name="duration"
              label="Duration"
              style={{ width: "100%" }}
              rules={[
                {
                  required: true,
                  message: "Please select the duration",
                },
              ]}
            >
              <Select placeholder="Hours" options={selectHoursOptions} />
            </Form.Item>
          </div>
        </div>
        <div className="modal-footer">
          <Divider
            style={{
              margin: "0px",
            }}
          />
          <div
            style={{
              paddingLeft: "24px",
              paddingRight: "24px",
              paddingBottom: "12px",
              paddingTop: "12px",
              display: "flex",
              justifyContent: "space-between",
              alignContent: "center",
              width: "100%",
            }}
          >
            <div />
            <div style={{ display: "flex", gap: "16px" }}>
              <Button
                onClick={() => {
                  history.replace("/scheduler");
                }}
                type="default"
              >
                Cancel
              </Button>
              <Tooltip
                title={
                  (checkNurseAvailableResponse?.assignNurseErrors?.length ?? 0) > 0
                    ? checkNurseAvailableResponse?.assignNurseErrors?.[0]
                    : undefined
                }
              >
                <Button
                  style={{ backgroundColor: "#12AFB8", borderColor: "#12AFB8", color: "white" }}
                  disabled={(checkNurseAvailableResponse?.assignNurseErrors?.length ?? 0) > 0}
                  htmlType="submit"
                >
                  Save
                </Button>
              </Tooltip>
            </div>
          </div>
        </div>
      </Form>
    </Modal>
  );
};

const selectHoursOptions = [
  {
    label: "30 minutes",
    value: 0.5,
  },
  {
    label: "1 Hour",
    value: 1,
  },
  {
    label: "2 Hours",
    value: 2,
  },
  {
    label: "3 Hours",
    value: 3,
  },
  {
    label: "4 Hours",
    value: 4,
  },
  {
    label: "5 Hours",
    value: 5,
  },
  {
    label: "6 Hours",
    value: 6,
  },
  {
    label: "7 Hours",
    value: 7,
  },
  {
    label: "8 Hours",
    value: 8,
  },
];

interface AddBreakTimeModalProps {
  viewType: CalendarViewType;
}

export default AddBreakTimeModal;
