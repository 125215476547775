import moment from "moment";
import { useQuery } from "@tanstack/react-query";
import { FC, useEffect, useMemo } from "react";
import { GeneratedDriverScheduleItem } from "../../services";
import { GoogleMapsDirectionsAPIRequestModel, googleMapsDirections } from "../../services/google.maps.service";
import { showMessage } from "../../components/common/notification";

const MapDriverPolyline: FC<MapDriverPolylineProps> = ({ driverName, map, selectedDate, driverStops, strokeColor }) => {
  const directionsRequest = useMemo<GoogleMapsDirectionsAPIRequestModel>(() => {
    const firstStop = new URL(driverStops[0].locationLink).searchParams.get("q") ?? "";
    const lastStop = new URL(driverStops[driverStops.length - 1].locationLink).searchParams.get("q") ?? "";
    const wayPoints = driverStops.slice(1, driverStops.length - 1).map<string>((stop) => {
      return new URL(stop.locationLink).searchParams.get("q") ?? "";
    });
    const req: GoogleMapsDirectionsAPIRequestModel = {
      origin: firstStop,
      destination: lastStop,
      wayPoints: wayPoints,
    };
    return req;
  }, [driverStops]);

  const { data: directionsResponse } = useQuery(
    ["google", "directions", driverName, selectedDate.format(), directionsRequest],
    () => googleMapsDirections(directionsRequest),
    {
      enabled: driverStops.length > 1,
      onError: () => {
        showMessage("error", "Something went wrong");
      },
      onSuccess: (response) => {
        if (!response) {
          return showMessage("error", "Something went wrong");
        } else if (!response || !response.routes?.length || !response.routes[0].legs?.length) {
          return showMessage("error", "Something went wrong");
        }
      },
      staleTime: Infinity,
      cacheTime: Infinity,
      refetchOnWindowFocus: false,
    }
  );

  useEffect(() => {
    if (!directionsResponse) return;
    let directionsRenderer: google.maps.DirectionsRenderer | null = new google.maps.DirectionsRenderer({
      suppressMarkers: true,
    });
    directionsRenderer.setOptions({
      markerOptions: {},
      polylineOptions: {
        strokeColor: strokeColor,
        strokeOpacity: 1,
        strokeWeight: 2,
      },
    });
    directionsRenderer.setMap(map);
    directionsRenderer.setDirections(directionsResponse);
    // directionsRenderer.setOptions({ suppressMarkers: true });
    return () => {
      directionsRenderer?.setMap(null);
      directionsRenderer?.unbindAll();
      directionsRenderer = null;
    };
  }, [directionsResponse, driverName, driverStops, map, selectedDate, strokeColor]);

  return null;
};

interface MapDriverPolylineProps {
  selectedDate: moment.Moment;
  strokeColor: string;
  driverName: string;
  map: google.maps.Map;
  driverStops: GeneratedDriverScheduleItem[];
}

export default MapDriverPolyline;
