import { Divider } from "antd";
import { FC } from "react";
import { City } from "../../../models/ReduxStateModel";
import useProductDetails from "../../../hooks/useProductDetails";

const BookingIncludedTestsList: FC<BookingIncludedTestsListProps> = ({ productUUID, area }) => {
  // if (!includedTests?.length) return null;

  const { query } = useProductDetails({ area: area, productUUID: productUUID });

  if (!query.data?.product?.testsIncluded?.length) return null;

  return (
    <div
      style={{
        border: "1px solid #ccc",
        borderRadius: "6px",
        padding: "8px",
        display: "flex",
        flexDirection: "column",
        width: "fit-content",
        minWidth: "200px",
      }}
    >
      <div>Included Tests</div>
      <Divider style={{ margin: "8px 0px" }} />
      <ul style={{ paddingLeft: "16px" }}>
        {query.data?.product?.testsIncluded?.map((item, index) => {
          return (
            <li key={index} style={{ color: "rgb(0, 134, 144)" }}>
              <strong>{item}</strong>
            </li>
          );
        })}
      </ul>
    </div>
  );
};

interface BookingIncludedTestsListProps {
  productUUID: string;
  area: City;
}

export default BookingIncludedTestsList;
