import { useMutation } from "@tanstack/react-query";
import { Divider, Switch } from "antd";
import { Dispatch, FC, SetStateAction } from "react";
import { useSelector } from "react-redux";
import { StaffMemberModel } from "../../models/StaffMemberModel";
import { editDriver } from "../../services";
import DriverProfileImage from "./DriverProfileImage";

const DriverProfileImageCard: FC<DriverProfileImageCardProps> = ({
  handleDriverUpdatedSuccessfully,
  setMember,
  staffMember,
  showActiveSwitch,
}) => {
  const partnerUUID = useSelector(
    (state: any) => state.authReducer?.setting?.partnerUUID
  );

  const editDriverMutation = useMutation({ mutationFn: editDriver });

  const handleIsEnabledChanged = async (value: boolean) => {
    const updatedDriver: StaffMemberModel = {
      ...staffMember,
      isActive: value,
      partnerUUID: partnerUUID,
      driverUUID: staffMember.driverUUID,
      isDeleted: false,
    };
    setMember((prevValue) => {
      const newValue: StaffMemberModel = { ...prevValue };
      newValue.isActive = value;
      return newValue;
    });
    await editDriverMutation.mutateAsync({
      activeDays: updatedDriver.activeDays ?? [],
      endTime: updatedDriver.endTime ?? "",
      startTime: updatedDriver.startTime ?? "",
      breakEndTime: updatedDriver.breakEndTime,
      breakStartTime: updatedDriver.breakStartTime,
      address: updatedDriver.address ?? "",
      addressLink: updatedDriver.addressLink ?? "",
      capacity: updatedDriver.capacity ?? 1,
      dob: updatedDriver.dob ?? "",
      driverUUID: staffMember.driverUUID!,
      email: updatedDriver.email ?? "",
      emiratesIdBackImageUrl: updatedDriver.emiratesIdBackImageUrl ?? "",
      emiratesIdFrontImageUrl: updatedDriver.emiratesIdFrontImageUrl ?? "",
      isActive: updatedDriver.isActive ?? false,
      isDeleted: false,
      licenseImageUrl: updatedDriver.licenseImageUrl ?? "",
      mobileNumber: updatedDriver.mobileNumber ?? "",
      name: updatedDriver.name ?? "",
      nationality: updatedDriver.nationality ?? "",
      partnerUUID: partnerUUID,
      passportImageUrl: updatedDriver.passportImageUrl ?? "",
      profileImageUrl: updatedDriver.profileImageUrl ?? "",
      sex: updatedDriver.sex ?? "male",
      emiratesIdBack: updatedDriver.emiratesIdBack,
      emiratesIdFront: updatedDriver.emiratesIdFront,
      holidays: updatedDriver.holidays ?? [],
      license: updatedDriver.license,
      passport: updatedDriver.passport,
      profileImage: updatedDriver.profileImageData,
    });
    handleDriverUpdatedSuccessfully(updatedDriver);
  };

  return (
    <div
      style={{
        width: "100%",
        minWidth: "100%",
        padding: "16px 0px",
        borderRadius: "5px",
        border: "1px solid #f1f1f1",
        backgroundColor: "white",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <div style={{ marginBottom: "16px" }}>
        <DriverProfileImage
          handleDriverUpdatedSuccessfully={handleDriverUpdatedSuccessfully}
          setMember={setMember}
          staffMember={staffMember}
        />
      </div>
      <div
        style={{
          fontSize: "16px",
          fontWeight: "500",
          color: "black",
          textAlign: "center",
        }}
      >
        {staffMember?.name}
      </div>
      <Divider style={{ margin: "16px 0px" }} />
      {showActiveSwitch && (
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            width: "100%",
            padding: "0px 16px",
          }}
        >
          <div style={{ color: "black" }}>
            {staffMember.isActive ? "Active" : "Disabled"}
          </div>
          <Switch
            checked={staffMember?.isActive ?? false}
            onChange={handleIsEnabledChanged}
          />
        </div>
      )}
    </div>
  );
};

interface DriverProfileImageCardProps {
  staffMember: StaffMemberModel;
  handleDriverUpdatedSuccessfully: (driver: StaffMemberModel) => void;
  setMember: Dispatch<SetStateAction<StaffMemberModel>>;
  showActiveSwitch: boolean;
}

export default DriverProfileImageCard;
