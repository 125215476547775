import axios from "axios";

export const environment: string = "prod"; // prod // local // dev
export const apiKey: string = "enq5hnqqhduv";

export const linkURL = () => {
  if (environment === "dev") {
    return "https://partner-dev.dardoc.com/setpassword/";
  }
  if (environment === "prod") {
    return "https://partner.dardoc.com/setpassword/";
  }
  if (environment === "local") {
    return "http://localhost:3000/setpassword/";
  }
};

export const setAxiosConfig = () => {
  let baseURL = "";
  if (window.location.host === "partner.dardoc.com") {
    baseURL = "https://api.dardoc.com";
  } else if (window.location.host === "partner-dev.dardoc.com") {
    baseURL = "https://api-dev.dardoc.com";
  } else if (environment === "dev") {
    baseURL = "https://api-dev.dardoc.com";
  } else if (environment === "prod") {
    baseURL = "https://api.dardoc.com";
  } else if (environment === "local") {
    baseURL = "http://localhost:8080";
  }
  axios.defaults.baseURL = baseURL;
  // axios.interceptors.response.use((response) => response, handleError);
};

export const setAuthorizationToken = () => {
  // axios.defaults.headers.common["Authorization"] = `Bearer ${localStorage.token}`;
};

// const handleError = () => {
//   return { data: { success: false } };
// };

export default axios;
