import { FC, useMemo } from "react";
import { Avatar, Button } from "@mui/material";
import BookingModel from "../../../models/BookingModel";
import useProductDetails from "../../../hooks/useProductDetails";
import useNurseDetails from "../../../hooks/useNurseDetails";
import useCurrentBookingDateObj from "../../../hooks/useCurrentBookingDateObj";

const ShowBookingAssignedNurse: FC<ShowBookingAssignedNurseProps> = ({ booking, onAssignNurseClicked }) => {
  const { query: productDetailsQuery } = useProductDetails({
    productUUID: booking.patientId?.[0].productUUID,
    area: booking.emirate,
  });

  const disableClick = useMemo<boolean>(() => {
    if (productDetailsQuery.data?.product?.isDarLabProduct && booking.isSampleCollected) {
      return true;
    }
    if (booking.status === "DRAFT" || booking.status === "CANCELLED" || booking.status === "REPORTED") {
      return true;
    }
    return false;
  }, [booking.isSampleCollected, booking.status, productDetailsQuery.data?.product?.isDarLabProduct]);

  const { currentAssignedNurse, nurseWithMostDays } = useCurrentBookingDateObj({ order: booking });
  const assignedNurse = currentAssignedNurse?.nurse ?? nurseWithMostDays;

  const { query: nurseDetailsQuery } = useNurseDetails({ nurseUUID: assignedNurse?.nurseUUID });

  const nurseFullName = useMemo(() => {
    const value = `${nurseDetailsQuery.data?.nurse?.firstName} ${nurseDetailsQuery.data?.nurse?.lastName}`.trim();
    const limit = 15;
    if (value.length < limit) return value;
    return `${value.slice(0, limit)}...`;
  }, [nurseDetailsQuery.data?.nurse?.firstName, nurseDetailsQuery.data?.nurse?.lastName]);

  if (assignedNurse && !nurseDetailsQuery.data?.nurse?.nurseUUID) return null;

  return (
    <Button
      variant={assignedNurse ? "text" : "outlined"}
      disabled={disableClick}
      style={{
        color: "black",
        fontWeight: "600",
        display: "flex",
        alignItems: "center",
        textAlign: "start",
        gap: "8px",
      }}
      onClick={
        disableClick
          ? undefined
          : (e) => {
              e.stopPropagation();
              onAssignNurseClicked();
            }
      }
    >
      {assignedNurse ? (
        <>
          <Avatar
            alt={nurseFullName}
            style={{ width: "30px", height: "30px", objectFit: "cover", borderRadius: "50%" }}
            src={
              nurseDetailsQuery.data?.nurse?.profileImageUrl ??
              nurseDetailsQuery.data?.nurse?.profilePhoto ??
              nurseDetailsQuery.data?.nurse?.profileImage
            }
          />
          <div>{nurseFullName}</div>
        </>
      ) : (
        <span style={{ color: "#25bcbd" }}>assign Professional</span>
      )}
    </Button>
  );
};

interface ShowBookingAssignedNurseProps {
  booking: BookingModel;
  onAssignNurseClicked: () => void;
}

export default ShowBookingAssignedNurse;
