import moment from "moment";
import { TemporaryAddressModel } from "../services";

interface SchedulerExploreViewResponseModel {
  success: number | boolean;
  nursesData?: NursesDatum[];
}

export interface NursesDatum {
  nurseUUID: string;
  name?: string;
  startTime?: string;
  endTime?: string;
  profileImage?: string;
  data?: DatumModel[];
  holidays?: string[];
  services?: string[];
  totalHoursAssigned?: number;
  totalHoursServed?: number;
}

export interface DatumModel {
  date: string;
  timeslots: TimeslotElementModel[];
}

export interface TimeslotElementModel {
  orderUUID?: string;
  nurseOrderID?: string;
  service?: string;
  caseDescription?: string;
  patientName?: string;
  timeslot: string;
  type?: NurseBookingType;
  locationLat?: string;
  locationLng?: string;
  address: string;
  addressDetails: string;
  temporaryDropOffAddress?: TemporaryAddressModel;
  temporaryPickupAddress?: TemporaryAddressModel;
  patients?: SlotPatientModel[];
}

export interface SlotPatientModel {
  patientUUID?: string;
  name: string;
  caseDescription?: string;
  source?: string;
}

export type NurseBookingType = "BOOKING" | "BREAK";

export interface NurseBookingModel {
  orderUUID?: string;
  nurseOrderID?: string;
  service?: string;
  caseDescription?: string;
  nurseUUID: string;
  startMoment: moment.Moment;
  endMoment: moment.Moment;
  patients?: SlotPatientModel[];
  nurseName?: string;
  nurseProfileImg?: string;
  nurseServices?: string[];
  nurseTotalHoursAssigned?: number;
  nurseTotalHoursServed?: number;
  locationLat?: string;
  locationLng?: string;
  address?: string;
  addressDetails: string;
  type: NurseBookingType;
  temporaryDropOffAddress?: TemporaryAddressModel;
  temporaryPickupAddress?: TemporaryAddressModel;
}

export function getNursePatientsList(nurse: NursesDatum): SlotPatientModel[] {
  const addedPatients = new Map<string, boolean>();
  const patients: SlotPatientModel[] = [];
  for (const date of nurse.data ?? []) {
    for (const timeslot of date.timeslots ?? []) {
      for (const patient of timeslot?.patients ?? []) {
        if (!addedPatients.get(patient.patientUUID ?? patient.name)) {
          patients.push(patient);
          addedPatients.set(patient.patientUUID ?? patient.name, true);
        }
      }
    }
  }
  return patients;
}

export function getBookingsNurseDatesMap(nursesData?: NursesDatum[]): { [prop: string]: NurseBookingModel[] } {
  const bookingsList = getBookinsList(nursesData);
  // const map = new Map<string, NurseBookingModel[]>();
  const map: { [prop: string]: NurseBookingModel[] } = {};
  for (const booking of bookingsList) {
    const key = `${booking.nurseUUID}_${booking.startMoment.clone().set("hour", 0).set("minute", 0).set("second", 0).format()}`;
    if (map[key]) {
      // map[key] = [...(map[key] ?? []), booking];
      map[key].push(booking);
    } else {
      map[key] = [booking];
    }
  }
  for (const arr of Object.values(map)) {
    arr.sort((booking1, booking2) => {
      const value1 = booking1?.startMoment.valueOf();
      const value2 = booking2?.startMoment.valueOf();
      if (value1 > value2) {
        return 1;
      } else if (value1 < value2) {
        return -1;
      }
      return 0;
    });
  }
  return map;
}

export function getBookinsList(nursesData?: NursesDatum[]): NurseBookingModel[] {
  const dateFormat = "DD/MM/YYYY hh:mmA";
  const bookings: NurseBookingModel[] = [];
  // const nurseBookings = new Map<string, NurseBookingModel[]>();
  for (const nurse of nursesData ?? []) {
    for (const date of nurse.data ?? []) {
      for (const bookingTime of date.timeslots) {
        const [startTime, endTime] = bookingTime.timeslot.split(" - ");
        const startMoment = moment(`${date.date} ${startTime}`, dateFormat);
        let endMoment = moment(`${date.date} ${endTime}`, dateFormat);
        if (endMoment.isSameOrBefore(startMoment)) {
          endMoment = endMoment.add(1, "day");
        }
        const booking: NurseBookingModel = {
          type: bookingTime.type ?? "BOOKING",
          addressDetails: bookingTime.addressDetails,
          temporaryDropOffAddress: bookingTime.temporaryDropOffAddress,
          temporaryPickupAddress: bookingTime.temporaryPickupAddress,
          orderUUID: bookingTime.orderUUID,
          nurseOrderID: bookingTime.nurseOrderID,
          caseDescription: bookingTime.caseDescription,
          service: bookingTime.service,
          nurseUUID: nurse.nurseUUID,
          startMoment: startMoment,
          endMoment: endMoment,
          // startMoment: moment.utc(`${date.date} ${startTime}`, dateFormat).local(),
          // endMoment: moment.utc(`${date.date} ${endTime}`, dateFormat).local(),
          patients: bookingTime.patients,
          nurseName: nurse.name,
          nurseProfileImg: nurse.profileImage,
          nurseServices: nurse.services,
          nurseTotalHoursAssigned: nurse.totalHoursAssigned,
          nurseTotalHoursServed: nurse.totalHoursServed,
          address: bookingTime.address,
          locationLat: bookingTime.locationLat,
          locationLng: bookingTime.locationLng,
        };
        // const start = booking.startMoment.format();
        // const end = booking.endMoment.format();
        // console.log(start);
        // console.log(end);
        bookings.push(booking);
      }
    }
  }
  return bookings;
}

export default SchedulerExploreViewResponseModel;
