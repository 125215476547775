import BookingModel from "../models/BookingModel";
import { useMemo } from "react";

export default function useBookingsList({ bookings }: { bookings?: BookingModel[] }) {
  const filteredBookings = useMemo(() => {
    const seenIDs = new Map<string, boolean>();
    return bookings?.filter((booking) => {
      if (seenIDs.get(booking.orderUUID ?? "")) return false;
      seenIDs.set(booking.orderUUID ?? "", true);
      return true;
    });
  }, [bookings]);

  return { filteredBookings };
}
