import moment from "moment";
import useAutomatedDriverScheduleQuery from "../../hooks/useAutomatedDriverScheduleQuery";
import { useMutation, useQuery } from "@tanstack/react-query";
import { Button, Tooltip } from "antd";
import { FC, useMemo, useState } from "react";
import { AiOutlineNotification } from "react-icons/ai";
import { useSelector } from "react-redux";
import { nurseService, sendDriverScheduleNotification } from "../../services";
import { StaffMemberModel } from "../../models/StaffMemberModel";
import { showMessage } from "../../components/common/notification";
import { FaCheck } from "react-icons/fa";
import ReduxStateModel from "../../models/ReduxStateModel";
import useCurrentUserRole from "../../hooks/useCurrentUserRole";

const SendDriverScheduleNotificationButton: FC = () => {
  const { canUpdate } = useCurrentUserRole();
  const [disableButton, setDisableButton] = useState<boolean>(false);
  const selectedDate: moment.Moment = useSelector(
    (state: ReduxStateModel) => state?.driverSchedulerReducer?.selectedDate ?? moment()
  );
  const partnerUUID: string | undefined = useSelector((state) => (state as ReduxStateModel)?.authReducer?.setting?.partnerUUID);
  const partnerUserUUID: string | undefined = useSelector(
    (state) => (state as ReduxStateModel)?.authReducer?.setting?.partnerUserUUID
  );

  const sendNotificationMutation = useMutation({ mutationFn: sendDriverScheduleNotification });

  const queryKey: [string, string | undefined] = ["nurses", partnerUserUUID];
  const { data: teamPageData } = useQuery(queryKey, () => nurseService.getAllNurses(partnerUserUUID ?? ""), {
    enabled: !!partnerUserUUID,
  });

  const {
    query: { data: driverScheduleData },
  } = useAutomatedDriverScheduleQuery({
    selectedDate,
  });

  const nurseUUIDs = useMemo<string[]>(() => {
    const nurseNamesMap = new Map<string, boolean>();
    for (const driverName of Object.keys(driverScheduleData ?? {})) {
      for (const job of driverScheduleData![driverName]) {
        nurseNamesMap.set(job.nurseName.trim(), true);
      }
    }
    return (
      teamPageData?.data.nurses
        ?.filter((nurse: StaffMemberModel) => {
          return nurseNamesMap.get(`${nurse.firstName ?? ""} ${nurse.lastName}`.trim()) ?? false;
        })
        .map((nurse: StaffMemberModel) => nurse.nurseUUID ?? "") ?? []
    );
  }, [driverScheduleData, teamPageData?.data.nurses]);

  if (selectedDate.isAfter(moment().startOf("day").add(2, "day"))) {
    return null;
  }

  const handleSendNotificationClicked = async () => {
    const res = await sendNotificationMutation.mutateAsync({ partnerUUID: partnerUUID!, nurseUUIDs: nurseUUIDs });
    if (!res?.success) {
      return showMessage("error", "Something went wrong");
    }
    showMessage("success", "Notifications was sent successfully");
    setDisableButton(true);
  };

  return (
    <Tooltip title={disableButton ? "Notification was sent successfully" : "Send schedule notification to drivers and nurses"}>
      <Button
        className="send-schedule-notifications-button"
        onClick={handleSendNotificationClicked}
        icon={disableButton ? <FaCheck /> : <AiOutlineNotification />}
        shape="circle"
        size="large"
        disabled={disableButton || !canUpdate}
        loading={sendNotificationMutation.isLoading}
        style={{
          fontWeight: "500",
          fontSize: "14px",
          border: "none",
          boxShadow: "rgb(187 187 187 / 48%) 6px 2px 16px 0px, rgb(255 255 255 / 80%) -6px -2px 16px 0px",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          gap: "4px",
          color: disableButton ? "white" : "black",
          backgroundColor: disableButton ? "green" : "white",
        }}
      />
    </Tooltip>
  );
};

export default SendDriverScheduleNotificationButton;
