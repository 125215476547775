import ReduxStateModel from "../models/ReduxStateModel";
import useDropDelaySelect from "./useDropDelaySelect";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { useSelector } from "react-redux";
import { useMemo, useState } from "react";
import { showMessage } from "../components/common/notification";
import { settingService } from "../services";
import moment from "moment";
import Mixpanel from "../utils/Mixpanel";

export default function useAutoNavMapViewControlsCard() {
  const selectedDate = useSelector((state: ReduxStateModel) => state?.driverSchedulerReducer?.selectedDate ?? moment());
  const queryClient = useQueryClient();
  const partnerUUID: string | undefined = useSelector((state: ReduxStateModel) => state?.authReducer?.setting?.partnerUUID);
  const partnerUserUUID: string | undefined = useSelector(
    (state: ReduxStateModel) => state?.authReducer?.setting?.partnerUserUUID
  );

  const [bookingEndPickupDelay, setBookingEndPickupDelay] = useState<number | undefined>();
  const [selectedNurseVehicleTime, setSelectedNurseVehicleTime] = useState<number | undefined>();
  const [avoidTolls, setAvoidTolls] = useState<boolean>(false);
  const [useDriverSkills, setUseDriverSkills] = useState<boolean>(false);

  const dateToCheck = useMemo(() => selectedDate.format("DD/MM/YYYY"), [selectedDate]);
  const previousDate = useMemo(() => selectedDate.clone().subtract(1, "day").format("DD/MM/YYYY"), [selectedDate]);

  const { delayOptions, selectedNurseDelay, handleNurseDelayChanged, setSelectedNurseDelay } = useDropDelaySelect({
    avoidTolls,
    bookingEndPickupDelay,
    selectedDate,
    selectedNurseVehicleTime,
    useDriverSkills,
  });

  const updateDriverScheduleSettingsMutation = useMutation({
    mutationFn: settingService.updateDriverScheduleSettings,
    onError: () => {
      showMessage("error", "Something went wrong");
    },
    onSuccess: (res) => {
      if (!res?.success) {
        showMessage("error", "Something went wrong");
      }
      queryClient.resetQueries(["driverschedules/automatedriverschedule", dateToCheck, previousDate, partnerUUID]);
    },
  });

  const { isFetching: loadingDriverSettings } = useQuery(
    ["partnerSettings", partnerUserUUID],
    () => settingService.getManageDateTime(partnerUserUUID),
    {
      enabled: !!partnerUserUUID,
      refetchOnWindowFocus: false,
      onSuccess: (res) => {
        if (!res?.data?.success) {
          return showMessage("error", "Something went wrong");
        }
        setSelectedNurseDelay(res.data?.partnerSetting?.partnerSettings?.nurseLateLeniency ?? 15);
        setBookingEndPickupDelay(res.data?.partnerSetting?.partnerSettings?.bookingEndPickupDelay ?? 15);
        setSelectedNurseVehicleTime(res.data?.partnerSetting?.partnerSettings?.nurseTravelTime ?? 40);
        setAvoidTolls(res.data?.partnerSetting?.partnerSettings?.avoidTolls ?? false);
        setUseDriverSkills(res.data?.partnerSetting?.partnerSettings?.useSkills ?? false);
      },
      onError: () => {
        showMessage("error", "Something went wrong");
      },
    }
  );

  const handleBookingEndPickupDelayChange = (value: number) => {
    Mixpanel.track("Update driver schedule retrieval delay", {
      partner: { userName: localStorage.getItem("userName"), partnerUserUUID: localStorage.getItem("partnerUserUUID") },
    });
    setBookingEndPickupDelay(value);
    updateDriverScheduleSettingsMutation.mutate({
      avoidTolls: avoidTolls,
      useSkills: useDriverSkills,
      bookingEndPickupDelay: value,
      nurseLateLeniency: selectedNurseDelay,
      nurseTravelTime: selectedNurseVehicleTime,
      partnerUUID: partnerUUID,
    });
  };

  const handleAvoidTollsChanged = (value: boolean) => {
    Mixpanel.track("Update driver schedule avoid tolls", {
      partner: { userName: localStorage.getItem("userName"), partnerUserUUID: localStorage.getItem("partnerUserUUID") },
    });
    setAvoidTolls(value);
    updateDriverScheduleSettingsMutation.mutate({
      avoidTolls: value,
      useSkills: useDriverSkills,
      bookingEndPickupDelay: bookingEndPickupDelay,
      nurseLateLeniency: selectedNurseDelay,
      nurseTravelTime: selectedNurseVehicleTime,
      partnerUUID: partnerUUID,
    });
  };

  const handleUseDriverSkillsChanged = (value: boolean) => {
    Mixpanel.track("Update driver schedule use driver skills", {
      partner: { userName: localStorage.getItem("userName"), partnerUserUUID: localStorage.getItem("partnerUserUUID") },
    });
    setUseDriverSkills(value);
    updateDriverScheduleSettingsMutation.mutate({
      useSkills: value,
      avoidTolls: avoidTolls,
      bookingEndPickupDelay: bookingEndPickupDelay,
      nurseLateLeniency: selectedNurseDelay,
      nurseTravelTime: selectedNurseVehicleTime,
      partnerUUID: partnerUUID,
    });
  };

  return {
    handleUseDriverSkillsChanged,
    handleAvoidTollsChanged,
    handleBookingEndPickupDelayChange,
    handleNurseDelayChanged,
    loadingDriverSettings,
    bookingEndPickupDelay,
    selectedNurseDelay,
    useDriverSkills,
    delayOptions,
    avoidTolls,
  };
}
