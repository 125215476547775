import { FC, useMemo } from "react";
import { LayoutSideMenuListItem } from "../models/LayoutSideMenuListItem";
import AllInboxRoundedIcon from "@mui/icons-material/AllInboxRounded";
import SettingsRoundedIcon from "@mui/icons-material/SettingsRounded";
import BasePageLayout, { BasePageLayoutProps } from "./BasePageLayout";

const PharmacyBasePageLayout: FC<PharmacyBasePageLayoutProps> = ({ mainContent, appBarContent, className }) => {
  const sideMenuItems = useMemo(() => {
    const value: LayoutSideMenuListItem[] = [
      {
        href: "/pharmacy",
        icon: <AllInboxRoundedIcon />,
        label: "Board",
      },
      {
        href: "/pharmacy-settings",
        icon: <SettingsRoundedIcon />,
        label: "Settings",
      },
    ];
    return value;
  }, []);

  return (
    <BasePageLayout
      backgroundColor="#2e2e30"
      textColor="white"
      notificationsIconColor="white"
      mainContent={mainContent}
      sideMenuItems={sideMenuItems}
      appBarContent={appBarContent}
      className={className}
    />
  );
};

export interface PharmacyBasePageLayoutProps extends Omit<BasePageLayoutProps, "sideMenuItems"> {}

export default PharmacyBasePageLayout;
