import axios from "axios";
import decrypt from "./decrypt";
import encrypt from "./encrypt";
import BaseResponseModel from "../models/BaseResponseModel";
import BookingModel from "../models/BookingModel";

export const sortServices = {
  sortOrders,
};

// export type SortOrdersParamsType = {
//   pageNumber: number;
//   partnerUUID: string;
//   sortUnreportedOrdersOnly?: boolean;
//   value: { startDate?: Date; endDate?: Date };
//   type: "ASCENDING" | "DESCENDING" | "DATERANGE";
//   view: "ACCEPT" | "ASSIGN" | "REPORT" | "COMPLETE" | "CANCELLED";
// };

export interface SortOrdersParamsType {
  partnerUUID: string;
  type: "ASCENDING" | "DESCENDING" | "DATERANGE";
  view: "ACCEPT" | "ASSIGN" | "REPORT" | "COMPLETE" | "CANCELLED";
  pageNumber: number;
  value: SortOrdersValueModel;
  sortUnreportedOrdersOnly?: boolean;
  filter: SortOrdersFilterModel;
}

export interface SortOrdersFilterModel {
  nurse?: string[];
  nurseStatus?: string[];
  nurseQualification?: string[];
  // nurseRating?: any[];
  service?: string[];
}

export interface SortOrdersValueModel {
  startDate?: string;
  endDate?: string;
}

export interface SortOrdersResponseModel extends BaseResponseModel {
  orders?: BookingModel[];
}

async function sortOrders(body: SortOrdersParamsType) {
  const bodydata = { ...body };
  if (bodydata.type === "DATERANGE") {
    bodydata.sortUnreportedOrdersOnly = true;
  }
  const data = await axios.post(`/api/v1/orders/sort`, {
    data: encrypt(bodydata),
  });
  const res: SortOrdersResponseModel = decrypt(data?.data);
  return { ...data, data: res };
}
