import { useEffect, useState } from "react";

const useTimer = (timeInSeconds: number) => {
  const [remainingTime, setRemainingTime] = useState<number>(timeInSeconds);

  useEffect(() => {
    const intervalID = setInterval(() => {
      setRemainingTime((prevValue) => {
        if (prevValue <= 0) return prevValue;
        return prevValue - 1;
      });
    }, 1000);
    return () => {
      clearInterval(intervalID);
    };
  }, []);

  return { remainingTime };
};

export default useTimer;
