import BookingModel from "../../models/BookingModel";
import { FC, useCallback } from "react";
import { Button, Form, Input, Modal } from "antd";
import { useMutation } from "@tanstack/react-query";
import { updateDoctorConsultationBooking } from "../../services";
import { showMessage } from "../../components/common/notification";

const MarkVideoConsultationCompleteModal: FC<MarkVideoConsultationCompleteModalProps> = ({
  booking,
  open,
  setBooking,
  onClose,
}) => {
  const markCompleteMutation = useMutation({ mutationFn: updateDoctorConsultationBooking });

  const handleFormValidationFinished = useCallback(
    async (values: { otp?: string }) => {
      const res = await markCompleteMutation.mutateAsync({ orderUUID: booking?.orderUUID ?? "", otp: values.otp ?? "" });
      if (res?.success) {
        showMessage("success", "User was verified successfully");
        setBooking((value) => ({ ...value, isOtpVerified: true }));
        // history.replace("/");
        onClose();
      } else {
        showMessage("error", "Invalid OTP");
      }
    },
    [booking?.orderUUID, markCompleteMutation, onClose, setBooking]
  );

  return (
    <Modal open={open} onCancel={onClose} footer={null}>
      <div>
        <Form layout="vertical" onFinish={handleFormValidationFinished}>
          <Form.Item
            label="Please enter booking OTP"
            name="otp"
            rules={[
              {
                validator(_, value) {
                  if (!value?.trim()?.length) {
                    return Promise.reject("Please enter the OTP");
                  } else if ((value?.trim()?.length ?? 0) !== 4) {
                    return Promise.reject("Please enter a valid OTP");
                  }
                  return Promise.resolve();
                },
              },
            ]}
          >
            <Input style={{ textAlign: "center" }} />
          </Form.Item>
          <Button block={true} htmlType="submit" type="primary" style={{ borderRadius: "10px" }}>
            Confirm
          </Button>
        </Form>
      </div>
    </Modal>
  );
};

interface MarkVideoConsultationCompleteModalProps {
  open: boolean;
  booking?: BookingModel;
  setBooking: React.Dispatch<React.SetStateAction<BookingModel>>;
  onClose: () => void;
}

export default MarkVideoConsultationCompleteModal;
