import { darviewType } from "../types";

export const initialState = {
  newOrders: [],
  loader: false,
};

export function darViewReducer(state = initialState, action) {
  switch (action.type) {
    case darviewType.REQUEST_DARVIEW:
      return {
        ...state,
        loader: true,
      };
    case darviewType.REQUEST_DARVIEW_DONE:
      return {
        ...state,
        loader: false,
      };
    case darviewType.GET_DAREVIEW_ORDERS:
      if (action.pagenumber === 1) {
        return {
          ...state,
          newOrders: action.data,
          loader: false,
        };
      } else {
        return {
          ...state,
          newOrders:
            action.data.length > 0
              ? [...state.newOrders, ...action.data]
              : state.newOrders,
          loader: false,
        };
      }

    default: {
      return {
        ...state,
      };
    }
  }
}
