import { FC, useState } from "react";
import { Alert } from "@mui/material";
import ReportRoundedIcon from "@mui/icons-material/ReportRounded";
import NewAssignNurseModal from "../common/BookingsTable/NewAssignNurseModal";
import BookingModel from "../../models/BookingModel";

const UnassignedNurseTableRowAlert: FC<UnassignedNurseTableRowAlertProps> = ({ booking, onAssignNurseSuccess, setBooking }) => {
  const [showAssignNurseModal, setShowAssignNurseModal] = useState(false);

  return (
    <>
      <Alert
        onClick={(e) => {
          e.stopPropagation();
          setShowAssignNurseModal(true);
        }}
        color="error"
        icon={<ReportRoundedIcon />}
        sx={{
          position: "absolute",
          top: "0",
          left: "0",
          width: "100%",
        }}
      >
        {(booking.date?.length ?? 0) > 1
          ? `Please assign a professional for all the booking days`
          : "Please assign a professional"}
      </Alert>
      {booking?.orderUUID && (
        <NewAssignNurseModal
          open={showAssignNurseModal}
          order={booking}
          setOrder={setBooking}
          onClose={function (): void {
            setShowAssignNurseModal(false);
          }}
          onAssignNurseSuccess={onAssignNurseSuccess}
        />
      )}
    </>
  );
};

interface UnassignedNurseTableRowAlertProps {
  booking: BookingModel;
  setBooking: React.Dispatch<React.SetStateAction<BookingModel>>;
  onAssignNurseSuccess: (booking: BookingModel) => void;
}

export default UnassignedNurseTableRowAlert;
