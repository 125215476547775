import { FC, useState } from "react";
import { driverSchedulePageBackgroundColor } from "../../constants";
import { Col, Row, Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import TractionHeader from "./TractionHeader";
import TractionTable from "./TractionTable";
import moment from "moment";
import useTractionHeadersQuery from "../../hooks/useTractionHeadersQuery";
import useTractionTableQuery from "../../hooks/useTractionTableQuery";
import BasePageLayout from "../../components/BasePageLayout";
import "./styles.css";

const Traction: FC = () => {
  const [selectedPeriod, setSelectedPeriod] = useState<FilterPeriodKeysType>("current-month");
  const [selectedDateRange, setSelectedDateRange] = useState<[moment.Moment, moment.Moment] | undefined>();
  // const sidebarCollapsed: boolean | undefined = useSelector((state: ReduxStateModel) => state?.sidebarReducer?.collapsed);

  // const width = `calc(100% - ${sidebarCollapsed ? sideMenuCollapsedWidth : sideMenuExpandedWidth}px)`;
  const { query: headerQuery } = useTractionHeadersQuery(selectedPeriod, selectedDateRange);
  const { isLoading: loadingHeader } = headerQuery;

  const { isLoading: loadingTable } = useTractionTableQuery(selectedPeriod, selectedDateRange);

  const loadingIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

  return (
    <BasePageLayout
      mainContent={
        <div className="">
          <div
            className="main_page traction web"
            style={{
              marginBottom: "6rem",
              width: "100%",
              minHeight: "100vh",
              backgroundColor: driverSchedulePageBackgroundColor,
            }}
          >
            <Row className="main-row">
              <Col span={24}>
                <Spin indicator={loadingIcon} spinning={loadingHeader || loadingTable}>
                  <TractionHeader
                    selectedDateRange={selectedDateRange}
                    setSelectedDateRange={setSelectedDateRange}
                    selectedPeriod={selectedPeriod}
                    setSelectedPeriod={setSelectedPeriod}
                  />
                </Spin>
              </Col>
              <Col span={24}>
                <Spin indicator={loadingIcon} spinning={loadingHeader || loadingTable}>
                  <TractionTable
                    selectedPeriod={selectedPeriod}
                    selectedDateRange={selectedDateRange}
                    setSelectedDateRange={setSelectedDateRange}
                  />
                </Spin>
              </Col>
            </Row>
          </div>
          <div
            className="main_page traction mobile"
            style={{
              marginBottom: "6rem",
              width: "100%",
              minHeight: "100vh",
              backgroundColor: driverSchedulePageBackgroundColor,
            }}
          >
            <Row className="main-row">
              <Col span={24}>
                <Spin indicator={loadingIcon} spinning={loadingHeader || loadingTable}>
                  <TractionHeader
                    selectedDateRange={selectedDateRange}
                    setSelectedDateRange={setSelectedDateRange}
                    selectedPeriod={selectedPeriod}
                    setSelectedPeriod={setSelectedPeriod}
                  />
                </Spin>
              </Col>
              <Col span={24}>
                <Spin indicator={loadingIcon} spinning={loadingHeader || loadingTable}>
                  <TractionTable
                    selectedPeriod={selectedPeriod}
                    selectedDateRange={selectedDateRange}
                    setSelectedDateRange={setSelectedDateRange}
                  />
                </Spin>
              </Col>
            </Row>
          </div>
        </div>
      }
    />
  );
};

export type FilterPeriodKeysType = "current-month" | "previous-month" | "last-3-month" | "select-range";

// export function getStartEndDatesFromSelectedPeriod(
//   period: FilterPeriodKeysType,
//   selecedRange: [moment.Moment, moment.Moment] | undefined
// ): [moment.Moment, moment.Moment] {
//   const now = moment().startOf("day");
//   if (selecedRange) {
//     return selecedRange;
//   }
//   if (period === "current-month") {
//     return [now.clone().startOf("month"), now];
//   } else if (period === "previous-month") {
//     const startOfLastMonth = now.clone().startOf("month").subtract(1, "month");
//     return [startOfLastMonth, startOfLastMonth.clone().endOf("month")];
//   } else if (period === "last-3-month") {
//     return [now.clone().subtract(3, "month"), now.clone()];
//   } else {
//     return [now.clone().subtract(3, "month"), now.clone()];
//   }
// }

export default Traction;
