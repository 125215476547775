import moment from "moment";

const showTimeIn24HrsFormat = (order, index) => {
  if (!order?.patient[0]?.isDarLabProduct) {
    const atime = order?.date[index]?.time?.replace(/ /g, "");
    const splittedATime = atime?.split("-");
    return moment(splittedATime[1], "hh:mmA").subtract(30, "minute").format("hh:mm A");
    // console.log("--- !order?.patient[0]?.isDarLabProduct", splittedATime);

    // let amOrPm;
    // const amOrPmToCheck = splittedATime[1]?.slice(-2);
    // if (splittedATime[0] === "11:30") {
    //   amOrPm = amOrPmToCheck === "AM" ? "PM" : "AM";
    // } else {
    //   amOrPm = amOrPmToCheck;
    // }

    // let startResultTime = moment(`${splittedATime[0]}${amOrPm}`, "hh:mmA").format("HH:mm");
    // let endResultTime = moment(`${splittedATime[1]}`, "hh:mmA").format("HH:mm");

    // let combineTime = `${startResultTime} - ${endResultTime}`;
    // return combineTime;
  }

  if (order?.date?.length) {
    const atime = order?.date[0]?.time?.replace(/ /g, "");
    const splittedATime = atime?.split("-");
    return moment(splittedATime[1], "hh:mmA").subtract(30, "minute").format("hh:mm A");
    // console.log("--- order?.date?.length", splittedATime);

    // let amOrPm;
    // const amOrPmToCheck = splittedATime[1]?.slice(-2);
    // if (splittedATime[0] === "11:30") {
    //   amOrPm = amOrPmToCheck === "AM" ? "PM" : "AM";
    // } else {
    //   amOrPm = amOrPmToCheck;
    // }

    // let startResultTime = moment(`${splittedATime[0]}${amOrPm}`, "hh:mmA").format("HH:mm");
    // let endResultTime = moment(`${splittedATime[1]}`, "hh:mmA").format("HH:mm");

    // let combineTime = `${startResultTime} - ${endResultTime}`;
    // return combineTime;
  }
};

export default showTimeIn24HrsFormat;
