import { orderType } from "../types";

export const initialState = {
  orderCountData: {},
  userImage: {},
  updatedOrder: {},
  singleOrder: {},
  singleOrderLoader: false,
};

export function orderReducer(state = initialState, action) {
  switch (action.type) {
    case orderType.LOG_OUT:
      return {
        orderCountData: {},
        userImage: {},
        updatedOrder: {},
      };
    case orderType.GET_ORDER_DATA:
      return {
        ...state,
        orderCountData: action.data,
      };

    case orderType.SINGLE_ORDER_LOADER_TRUE:
      return {
        ...state,
        singleOrderLoader: true,
      };
    case orderType.GET_SINGLE_ORDER_DATA:
      return {
        ...state,
        singleOrder: action.data,
        singleOrderLoader: false,
      };
    case orderType.GET_IMAGE:
      return {
        ...state,
        userImage: action.data,
      };

    case orderType.GET_UPDATED_ORDER_DATA:
      return {
        ...state,
        updatedOrder: action.data,
      };

    default: {
      return {
        ...state,
      };
    }
  }
}
