import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useMemo, useState } from "react";
import { settingService } from "../services";
import { showMessage } from "../components/common/notification";
import { useSelector } from "react-redux";
import moment from "moment";
import ReduxStateModel from "../models/ReduxStateModel";
import Mixpanel from "../utils/Mixpanel";

export default function useDropDelaySelect({
  avoidTolls,
  selectedDate,
  useDriverSkills,
  bookingEndPickupDelay,
  selectedNurseVehicleTime,
}: {
  selectedDate: moment.Moment;
  avoidTolls: boolean;
  useDriverSkills: boolean;
  bookingEndPickupDelay?: number;
  selectedNurseVehicleTime?: number;
}) {
  const [selectedNurseDelay, setSelectedNurseDelay] = useState<number | undefined>();
  const queryClient = useQueryClient();

  const partnerUUID = useSelector((state: ReduxStateModel) => state?.authReducer?.setting?.partnerUUID);

  const dateToCheck = useMemo(() => selectedDate.format("DD/MM/YYYY"), [selectedDate]);
  const previousDate = useMemo(() => selectedDate.clone().subtract(1, "day").format("DD/MM/YYYY"), [selectedDate]);

  const updateDriverScheduleSettingsMutation = useMutation({
    mutationFn: settingService.updateDriverScheduleSettings,
    onError: () => {
      showMessage("error", "Something went wrong");
    },
    onSuccess: (res) => {
      if (!res?.success) {
        showMessage("error", "Something went wrong");
      }
      queryClient.resetQueries(["driverschedules/automatedriverschedule", dateToCheck, previousDate, partnerUUID]);
    },
  });

  const handleNurseDelayChanged = (value: number) => {
    Mixpanel.track("Update driver schedule drop delay", {
      partner: { userName: localStorage.getItem("userName"), partnerUserUUID: localStorage.getItem("partnerUserUUID") },
    });
    setSelectedNurseDelay(value);
    updateDriverScheduleSettingsMutation.mutate({
      avoidTolls: avoidTolls,
      useSkills: useDriverSkills,
      bookingEndPickupDelay: bookingEndPickupDelay,
      nurseLateLeniency: value,
      nurseTravelTime: selectedNurseVehicleTime,
      partnerUUID: partnerUUID,
    });
  };

  const delayOptions = useMemo(
    () => [
      {
        label: "1 minute",
        value: 1,
      },
      {
        label: "5 minutes",
        value: 5,
      },
      {
        label: "10 minutes",
        value: 10,
      },
      {
        label: "15 minutes",
        value: 15,
      },
      {
        label: "20 minutes",
        value: 20,
      },
      {
        label: "25 minutes",
        value: 25,
      },
      {
        label: "30 minutes",
        value: 30,
      },
    ],
    []
  );

  return { handleNurseDelayChanged, setSelectedNurseDelay, selectedNurseDelay, delayOptions };
}
