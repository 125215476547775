import { Checkbox, Col, Form, Row } from "antd";
import { FC } from "react";

const NurseWeekDaysAvailability: FC = () => {
  return (
    <Row
      className="nurse-week-days-availability"
      style={{
        rowGap: "8px",
        width: "100%",
      }}
    >
      <Col span={24}>Availability on weekdays:</Col>
      <Col span={24}>
        <div className="nurse-week-days">
          <DayCheckboxItem dayName="Monday" />
          <DayCheckboxItem dayName="Tuesday" />
          <DayCheckboxItem dayName="Wednesday" />
          <DayCheckboxItem dayName="Thursday" />
          <DayCheckboxItem dayName="Friday" />
          <DayCheckboxItem dayName="Saturday" />
          <DayCheckboxItem dayName="Sunday" />
        </div>
      </Col>
      <Col span={24}>
        <Form.Item
          className="available-for-night-shift-form-item"
          valuePropName="checked"
          name="isNightShift"
          label="Available for night shift"
          style={{ display: "flex" }}
        >
          <Checkbox />
        </Form.Item>
      </Col>
    </Row>
  );
};

const DayCheckboxItem: FC<{ dayName: string }> = ({ dayName }) => {
  return (
    <Form.Item
      valuePropName="checked"
      name={`nurseAvtiveDay${dayName}`}
      label={dayName}
      className="nurseAvtiveDay-checkbox"
      // style={{ display: "flex", flexDirection: "column" }}
    >
      <Checkbox />
    </Form.Item>
  );
};

export default NurseWeekDaysAvailability;
