export interface AppSettingsState {
  googleAPIKey?: string;
}

export const initialState: AppSettingsState = {};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function appSettingsReducer(state = initialState, action: { type: string; payload: any }) {
  if (action.type === "SET_GOOGLE_API_KEY") {
    return Object.assign<object, AppSettingsState, AppSettingsState>({}, state, { googleAPIKey: action.payload });
  }
  return state;
}
