import { LoadingButton } from "@mui/lab";
import { TextField } from "@mui/material";
import moment from "moment";
import { FC, useMemo, useState } from "react";
import useTimer from "../../hooks/useTimer";

const OTPForm: FC<OTPFormProps> = ({
  handleFormSubmit,
  handleResendOTPClicked,
  handleCallRequestClicked,
  requestCallLoading,
  mobileNumber,
  submitLoading,
  resendOTPLoading,
}) => {
  const [otp, setOTP] = useState("");
  const { remainingTime: remainingResendOTPTimeInSeconds } = useTimer(30);
  const { remainingTime: remainingCallTimeInSeconds } = useTimer(30);

  const remainingResendOTPDuration = useMemo(() => {
    const duration = moment.duration(remainingResendOTPTimeInSeconds * 1000);
    return `(${duration.minutes()}:${duration.seconds()})`;
  }, [remainingResendOTPTimeInSeconds]);

  const remainingCallDuration = useMemo(() => {
    const duration = moment.duration(remainingCallTimeInSeconds * 1000);
    return `(${duration.minutes()}:${duration.seconds()})`;
  }, [remainingCallTimeInSeconds]);

  return (
    <div>
      <h5 style={{ textAlign: "center", fontSize: "20px", marginBottom: "1rem" }}>An OTP was sent to {mobileNumber}</h5>
      <form
        style={{ display: "flex", flexDirection: "column", gap: "16px", padding: "0px 16px" }}
        onSubmit={(e) => {
          e.preventDefault();
          e.stopPropagation();
          handleFormSubmit({ otp: otp.replaceAll(" ", "").trim() });
        }}
      >
        <TextField
          autoFocus={true}
          value={otp}
          onChange={(e) => {
            const value = e.target.value.replaceAll(" ", "").trim();
            if (value.length > 6) return;
            setOTP(value);
          }}
          required={true}
          inputProps={{ style: { textAlign: "center" } }}
          id="otp"
          label="OTP"
          sx={{ width: "100%", textAlign: "center" }}
        />
        <LoadingButton variant="contained" type="submit" loading={submitLoading}>
          <span style={{ color: "white" }}>Continue</span>
        </LoadingButton>
        <LoadingButton
          variant="outlined"
          onClick={handleResendOTPClicked}
          loading={resendOTPLoading}
          disabled={remainingResendOTPTimeInSeconds > 0}
        >
          {`${remainingResendOTPTimeInSeconds > 0 ? remainingResendOTPDuration : ""} Resend OTP`.trim()}
        </LoadingButton>
        <LoadingButton
          variant="outlined"
          onClick={handleCallRequestClicked}
          disabled={remainingCallTimeInSeconds > 0}
          loading={requestCallLoading}
        >
          {`${remainingCallTimeInSeconds > 0 ? remainingCallDuration : ""} Send to Whatsapp`}
        </LoadingButton>
      </form>
    </div>
  );
};

interface OTPFormProps {
  submitLoading: boolean;
  resendOTPLoading: boolean;
  mobileNumber: string;
  requestCallLoading: boolean;
  handleResendOTPClicked: () => void;
  handleFormSubmit: (params: { otp: string }) => void;
  handleCallRequestClicked: () => void;
}

export default OTPForm;
