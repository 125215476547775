import { IconButton } from "@mui/material";
import { FC, useCallback } from "react";
import { FaFileExcel } from "react-icons/fa";
import { excelFileType } from "../../constants";
import { saveAs as fileServerSaveAs } from "file-saver";
import * as XLSX from "xlsx";
import usePharmacyTractionTable from "../../hooks/usePharmacyTractionTable";
import { FilterPeriodKeysType } from "../Traction";
import moment from "moment";

const PharmacyDownloadRevenueExcel: FC<PharmacyDownloadRevenueExcelProps> = ({ selectedPeriod, selectedDateRange }) => {
  const { query } = usePharmacyTractionTable(selectedPeriod, selectedDateRange);

  const handleClick = useCallback(() => {
    const excelFileData = query.data?.table?.map<string[]>((row) => {
      return [
        row.orderUUID ?? "",
        moment(row.createdAt).format("DD/MM/YYYY hh:mm A"),
        row.patient?.join(", ") ?? "",
        row.bookingType === "CASH" ? "Prescription Cash" : "Insurance Cash",
        `% ${row.ourPercent}`,
        row.partnerPrice?.toLocaleString() ?? "",
        row.ourPrice?.toLocaleString() ?? "",
      ];
    });
    excelFileData?.unshift([
      "Booking ID",
      "Date & Time",
      "Patient",
      "Transaction Type",
      "Insurance Provider",
      "Invoice Amount",
      "DarDoc Commision",
      "Receivable From DarDoc",
      "Payable To DarDoc",
    ]);
    const ws = XLSX.utils.json_to_sheet(excelFileData ?? []);
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], { type: excelFileType });
    fileServerSaveAs(data, `dardoc-revenue-tracker.xlsx`);
  }, [query.data?.table]);

  return (
    <IconButton
      onClick={handleClick}
      sx={{
        fontSize: "18px",
        color: "#04723a",
        backgroundColor: "white",
        boxShadow: "rgba(187, 187, 187, 0.48) 6px 2px 16px 0px, rgba(255, 255, 255, 0.8) -6px -2px 16px 0px",
      }}
    >
      <FaFileExcel />
    </IconButton>
  );
};

interface PharmacyDownloadRevenueExcelProps {
  selectedPeriod: FilterPeriodKeysType;
  selectedDateRange?: [moment.Moment, moment.Moment];
}

export default PharmacyDownloadRevenueExcel;
