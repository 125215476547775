import { FC, useCallback, useMemo, useState } from "react";
import { Badge, Box, Drawer, IconButton, Tooltip, Typography, useTheme } from "@mui/material";
import { grey } from "@mui/material/colors";
import { Spin } from "antd";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import ReduxStateModel, { PartnerNotificationModel } from "../models/ReduxStateModel";
import DraftsRoundedIcon from "@mui/icons-material/DraftsRounded";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import CircleNotificationsRoundedIcon from "@mui/icons-material/CircleNotificationsRounded";
import useUserData from "../hooks/useUserData";
import moment from "moment";
import NotificationsDrawerListItem from "./NotificationsDrawerListItem";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { authService } from "../services";
import { useSelector } from "react-redux";
import { showMessage } from "./common/notification";

const NotificationsButton: FC<NotificationsButtonProps> = ({ iconColor }) => {
  const [open, setOpen] = useState(false);
  const { query, queryKey } = useUserData({});
  const queryClient = useQueryClient();
  const theme = useTheme();
  const partnerUUID = useSelector((state: ReduxStateModel) => state.authReducer?.setting?.partnerUUID);

  const groupedNotificationsByDate = useMemo(() => {
    const value = new Map<string, PartnerNotificationModel[]>();
    for (const notification of query.data?.data?.settings?.notifications ?? []) {
      const date = moment(notification.dateCreated).format("DD/MM/YYYY");
      const notificationsList = value.get(date) ?? [];
      notificationsList.push(notification);
      value.set(date, notificationsList);
    }
    return value;
  }, [query.data?.data?.settings?.notifications]);

  const sortedMapKeys = useMemo<string[]>(() => {
    return Array.from(groupedNotificationsByDate.keys()).sort((date1, date2) => {
      const moment1 = moment(date1, "DD/MM/YYYY");
      const moment2 = moment(date2, "DD/MM/YYYY");
      return moment2.diff(moment1);
    });
  }, [groupedNotificationsByDate]);

  const unreadNotificationsCount = useMemo(() => {
    return query.data?.data?.settings?.notifications?.filter((n) => n.status === "UNREAD").length ?? 0;
  }, [query.data?.data?.settings?.notifications]);

  const showMarkReadButton = useMemo(() => {
    return query.data?.data?.settings?.notifications?.some((notification) => notification.status === "UNREAD") ?? false;
  }, [query.data?.data?.settings?.notifications]);

  const markAllReadMutation = useMutation({ mutationFn: authService.readNotification });
  const clearAllMutation = useMutation({ mutationFn: authService.deleteNotification });

  const handleClearAllClicked = useCallback(async () => {
    const res = await clearAllMutation.mutateAsync({ notificationUUID: [], partnerUUID: partnerUUID ?? "", deleteType: "ALL" });
    if (res.data.success) {
      queryClient.invalidateQueries(queryKey);
    } else {
      showMessage("error", "Something went wrong");
    }
  }, [clearAllMutation, partnerUUID, queryClient, queryKey]);

  const handleMarkAllReadClick = useCallback(async () => {
    const notifications = query.data?.data?.settings?.notifications ?? [];
    const res = await markAllReadMutation.mutateAsync({
      partnerUUID: partnerUUID ?? "",
      notificationUUIDArray:
        notifications
          .filter((notification) => notification.status === "UNREAD")
          .map<string>((notification) => notification.notificationUUID ?? "") ?? [],
    });
    if (res.data.success) {
      queryClient.invalidateQueries(queryKey);
    } else {
      showMessage("error", "Something went wrong");
    }
  }, [markAllReadMutation, partnerUUID, query.data?.data?.settings?.notifications, queryClient, queryKey]);

  return (
    <>
      <Badge
        badgeContent={unreadNotificationsCount}
        color="primary"
        sx={{
          "& .MuiBadge-badge": {
            color: "white",
            right: "10px",
            top: "10px",
          },
        }}
      >
        <IconButton
          className="notifications-button"
          sx={{ color: iconColor ?? grey[900] }}
          onClick={() => {
            setOpen(true);
          }}
        >
          <CircleNotificationsRoundedIcon fontSize="large" />
        </IconButton>
      </Badge>
      <Drawer
        anchor="right"
        open={open}
        onClose={() => {
          setOpen(false);
        }}
      >
        <Box
          sx={{
            width: "400px",
            height: "100vh",
            display: "flex",
            flexDirection: "column",
            backgroundColor: theme.components?.MuiPaper?.defaultProps?.color,
            "@media (max-width: 700px)": {
              width: "100vw",
            },
          }}
        >
          <Box
            sx={{
              padding: "1rem",
              borderBottom: `1px solid ${grey[300]}`,
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Typography variant="h6">Notifications</Typography>
            <div>
              {sortedMapKeys.length > 0 && (
                <Tooltip title="Clear all">
                  <IconButton onClick={handleClearAllClicked}>
                    <DeleteForeverIcon />
                  </IconButton>
                </Tooltip>
              )}
              {showMarkReadButton && (
                <Tooltip title="Mark all as read">
                  <IconButton onClick={handleMarkAllReadClick}>
                    <DraftsRoundedIcon />
                  </IconButton>
                </Tooltip>
              )}
              <IconButton
                onClick={() => {
                  setOpen(false);
                }}
              >
                <CloseRoundedIcon />
              </IconButton>
            </div>
          </Box>
          {query.isLoading ? (
            <Box sx={{ padding: "1rem", display: "flex", justifyContent: "center" }}>
              <Spin />
            </Box>
          ) : (
            <Box sx={{ height: "100%", overflowY: "scroll", position: "relative" }}>
              {sortedMapKeys.length === 0 ? (
                <Typography sx={{ textAlign: "center", paddingTop: "2rem" }}>No new notifications</Typography>
              ) : null}
              {sortedMapKeys.map((date) => {
                return (
                  <Box key={date}>
                    <Box>
                      <Box
                        sx={{
                          position: "sticky",
                          top: "0",
                          padding: "1rem",
                          backgroundColor: grey[200],
                          borderTop: `1px solid ${grey[300]}`,
                          fontWeight: "500",
                        }}
                      >
                        {date}
                      </Box>
                      {groupedNotificationsByDate.get(date)?.map((notification) => {
                        return <NotificationsDrawerListItem key={notification.notificationUUID} notification={notification} />;
                      })}
                    </Box>
                  </Box>
                );
              })}
            </Box>
          )}
        </Box>
      </Drawer>
    </>
  );
};

interface NotificationsButtonProps {
  iconColor?: string;
}

export default NotificationsButton;
