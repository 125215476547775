import { useQuery } from "@tanstack/react-query";
import { getPartnerAddress } from "../services/inventory";
import { useMemo } from "react";

export default function usePartnerAddressQuery({ addressUUID }: { addressUUID?: string }) {
  const queryKey = useMemo(() => ["inventory-requests/get-partner-address", addressUUID], [addressUUID]);

  const query = useQuery(queryKey, () => getPartnerAddress({ addressUUID: addressUUID ?? "" }), { enabled: !!addressUUID });

  return { query, queryKey };
}
