import moment from "moment";
import { Dropdown, DatePicker } from "antd";
import { FC, useMemo } from "react";
import { FilterPeriodKeysType } from "../Traction";
import { TiArrowSortedDown } from "react-icons/ti";
import { capacityUtilizationCardTextColor } from "./StaffCapacityUtilizationCard";

const HomeNursingPeriodDropdown: FC<ServiceVolumePeriodDropdownProps> = ({
  openDropdown,
  selectedDateRange,
  selectedPeriod,
  setSelectedPeriod,
  setSelectedDateRange,
  setOpenDropdown,
}) => {
  const now = useMemo(() => moment(), []);

  const startAndEndDatesOfSelectedPeriod = useMemo(() => {
    return getStartEndDatesFromSelectedPeriod(selectedPeriod, selectedDateRange);
  }, [selectedDateRange, selectedPeriod]);

  let dropdownContent: JSX.Element;
  if (selectedDateRange) {
    dropdownContent = (
      <div>
        {startAndEndDatesOfSelectedPeriod[0].format("DD MMMM")} - {startAndEndDatesOfSelectedPeriod[1].format("DD MMMM")}
      </div>
    );
  } else if (selectedPeriod === "current-month") {
    dropdownContent = <div>Current month ({startAndEndDatesOfSelectedPeriod[0].format("MMMM")})</div>;
  } else if (selectedPeriod === "prev-month") {
    dropdownContent = <div>Previous month ({startAndEndDatesOfSelectedPeriod[0].format("MMMM")})</div>;
  } else {
    dropdownContent = (
      <div>
        {startAndEndDatesOfSelectedPeriod[0].format("DD MMMM")} - {startAndEndDatesOfSelectedPeriod[1].format("DD MMMM")}
      </div>
    );
  }

  return (
    <Dropdown
      open={openDropdown}
      onOpenChange={(value) => {
        setOpenDropdown(value);
      }}
      menu={{
        items: filterPeriods.map((item) => {
          if (item.key === "select-range") {
            return {
              key: item.key,
              label: (
                <DatePicker.RangePicker
                  style={{ borderRadius: "10px" }}
                  disabledDate={(date) => {
                    return date.isAfter(now);
                  }}
                  value={selectedDateRange}
                  onChange={(e) => {
                    if (!e) {
                      setSelectedDateRange(undefined);
                    } else if (e[0] && e[1]) {
                      setOpenDropdown(false);
                      setSelectedDateRange([e[0]?.clone(), e[1]?.clone()]);
                    } else {
                      setSelectedDateRange(undefined);
                    }
                  }}
                />
              ),
            };
          }
          return {
            key: item.key,
            label: <div>{item.label}</div>,
            onClick: (e) => {
              setSelectedDateRange(undefined);
              setOpenDropdown(false);
              setSelectedPeriod(e.key as FilterPeriodKeysType);
            },
          };
        }),
      }}
      trigger={["click"]}
    >
      <div
        style={{
          display: "flex",
          alignItems: "center",
          gap: "8px",
          color: capacityUtilizationCardTextColor,
          border: `1px solid ${capacityUtilizationCardTextColor}`,
          width: "fit-content",
          padding: "8px 16px",
          borderRadius: "30px",
        }}
      >
        <div style={{ textTransform: "capitalize", fontSize: "11px" }}>{dropdownContent}</div>
        <TiArrowSortedDown style={{ fontSize: "14px" }} />
      </div>
    </Dropdown>
  );
};

interface ServiceVolumePeriodDropdownProps {
  openDropdown: boolean;
  selectedPeriod: string;
  selectedDateRange?: [moment.Moment, moment.Moment];
  setSelectedPeriod: React.Dispatch<React.SetStateAction<string>>;
  setSelectedDateRange: React.Dispatch<React.SetStateAction<[moment.Moment, moment.Moment] | undefined>>;
  setOpenDropdown: React.Dispatch<React.SetStateAction<boolean>>;
}

export function getStartEndDatesFromSelectedPeriod(
  period: string,
  selecedRange: [moment.Moment, moment.Moment] | undefined
): [moment.Moment, moment.Moment] {
  const now = moment().startOf("day");
  if (selecedRange) {
    return selecedRange;
  }
  if (period === "current-month") {
    return [now.clone().startOf("month"), now.clone().endOf("month")];
  } else if (period === "prev-month" || period === "previous-month") {
    const startOfLastMonth = now.clone().startOf("month").subtract(1, "month");
    return [startOfLastMonth, startOfLastMonth.clone().endOf("month")];
  } else if (period === "last-3-month") {
    return [now.clone().subtract(2, "month").startOf("month"), now.clone().endOf("month")];
  } else if (period === "year-quarters") {
    return [now.clone().startOf("year"), now.clone().endOf("year")];
  } else {
    return [now.clone().subtract(3, "month"), now.clone()];
  }
}

const filterPeriods = [
  { key: "current-month", label: "Current month" },
  { key: "prev-month", label: "Previous month" },
  { key: "select-range", label: "Select range" },
];

export default HomeNursingPeriodDropdown;
