import { useMutation } from "@tanstack/react-query";
import { Form } from "antd";
import { useState } from "react";
import { useHistory } from "react-router-dom";
import { getUserData } from "../../services/signup";
import { showMessage } from "../../components/common/notification";

function useNewSignupPage() {
  const history = useHistory();
  const [form] = Form.useForm<SignUpForm>();
  const [showConfirmationLinkSentModal, setShowConfirmationLinkSentModal] = useState<boolean>(false);

  const signupUserMutation = useMutation({ mutationFn: getUserData });

  const handleFormFinishedValidation = async (values: SignUpForm) => {
    const baseUrl = window.location.origin;
    const link = baseUrl + "/setpassword/";
    const res = await signupUserMutation.mutateAsync({ ...values, link: link });
    if (!res?.data?.success) {
      return showMessage("error", "Something went wrong");
    }
    if (res.data.success === 1 && res.data.isUserExist === false && res.data.isVerificationEmailSent === true) {
      setShowConfirmationLinkSentModal(true);
    } else if (res.data.success === 1 && res.data.isUserExist === true && res.data.isVerificationEmailSent === false) {
      history.push("/forgetpassword");
    } else if (res.data.success === 1 && res.data.isUserExist === true && res.data.isVerificationEmailSent === true) {
      setShowConfirmationLinkSentModal(true);
    } else {
      showMessage("error", "Something went wrong");
    }
  };

  const handleLoginClick = () => {
    history.push("/login");
  };

  return {
    handleFormFinishedValidation,
    handleLoginClick,
    showConfirmationLinkSentModal,
    setShowConfirmationLinkSentModal,
    form,
    signupUserMutation,
  };
}

interface SignUpForm {
  email: string;
  firstName: string;
  lastName: string;
  organizationName: string;
}

export default useNewSignupPage;
