import { Form, Input } from "antd";
import { FC, useMemo } from "react";
import { StaffMemberModel } from "../../models/StaffMemberModel";
import useAllPartnerNurses from "../../hooks/useAllPartnerNurses";

const DriverPriorityFormItem: FC<DriverPriorityFormItemProps> = ({ driver }) => {
  const { query } = useAllPartnerNurses();

  const otherDrivers = useMemo<StaffMemberModel[] | undefined>(() => {
    const drivers: StaffMemberModel[] | undefined = query.data?.data.drivers;
    if (!driver) return drivers;
    return drivers?.filter((d) => d.driverUUID !== driver.driverUUID);
  }, [driver, query.data?.data.drivers]);

  return (
    <Form.Item
      label="Priority"
      style={{ width: "100%" }}
      name={"priority"}
      rules={[
        {
          validator(_, value) {
            if (!value) return Promise.resolve();
            const numberValue = Number(value);
            if (isNaN(numberValue)) return Promise.reject("Please enter a valid number");
            if (numberValue < 0) return Promise.reject("Please enter a positive number");
            const driverWithSamePriority = otherDrivers?.find((d) => d.priority === value);
            if (driverWithSamePriority) {
              return Promise.reject(
                `Driver ${driverWithSamePriority.name} has the same priority. Each driver should have a unique priority number`
              );
            }
            return Promise.resolve();
          },
        },
      ]}
    >
      <Input style={{ width: "100%" }} />
    </Form.Item>
  );
};

interface DriverPriorityFormItemProps {
  driver?: StaffMemberModel;
}

export default DriverPriorityFormItem;
