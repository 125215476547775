import { Tooltip } from "antd";
import { FC } from "react";

const TractionHeaderItem: FC<TractionHeaderItemProps> = ({ title, subtitle, bottom, difference }) => {
  const splittedSubtitle = subtitle?.toString().split(".") ?? [];

  let differenceTextColor: string;
  let differenceBackgroundColor: string;
  if ((difference ?? 0) > 0) {
    differenceBackgroundColor = "#4caf502b";
    differenceTextColor = "rgb(76, 175, 80)";
  } else if ((difference ?? 0) < 0) {
    differenceBackgroundColor = "#f2e7bb";
    differenceTextColor = "rgb(156, 72, 40)";
  } else {
    differenceBackgroundColor = "#edf1f4";
    differenceTextColor = "#697a88";
  }

  return (
    <div className="traction-item">
      <Tooltip title={title}>
        <div
          className="title"
          style={{
            color: "#697a88",
            // marginBottom: "12px",
            // height: "66px",
            // minHeight: "66px",
            textTransform: "capitalize",
          }}
        >
          {title}
        </div>
      </Tooltip>
      <div
        style={{
          fontWeight: "500",
          color: "black",
          fontSize: "20px",
          lineHeight: "1",
        }}
      >
        {splittedSubtitle[0]}
        {splittedSubtitle[1] && (
          <span
            style={{
              color: "#697a88",
              fontWeight: "400",
              fontSize: "14px",
            }}
          >
            .{splittedSubtitle[1]}
          </span>
        )}
      </div>
      {bottom && <div style={{ color: "#697a88", fontSize: "11px" }}>{bottom}</div>}
      {(difference || difference === 0) && (
        <div
          style={{
            marginTop: "auto",
            backgroundColor: differenceBackgroundColor,
            color: differenceTextColor,
            borderRadius: "4px",
            padding: "0px 4px",
            width: "fit-content",
            fontSize: "12px",
          }}
        >
          {difference > 0
            ? `+${difference.toLocaleString(undefined, {
                maximumFractionDigits: 2,
              })}`
            : difference.toLocaleString(undefined, {
                maximumFractionDigits: 2,
              })}
          %
        </div>
      )}
    </div>
  );
};

interface TractionHeaderItemProps {
  title: string;
  subtitle: React.ReactNode;
  bottom?: string;
  difference?: number;
}

export default TractionHeaderItem;
