import moment from "moment";

function enumerateDateStringBetweenDates({ startDate, endDate }: { startDate: moment.Moment; endDate: moment.Moment }): {
  dateStrArray: string[];
  datesStrMap: Map<string, boolean>;
} {
  const currentDate = startDate.clone().startOf("day");
  const dateStrArray: string[] = [];
  while (currentDate.isSameOrBefore(endDate)) {
    dateStrArray.push(currentDate.format("DD/MM/YYYY"));
    currentDate.add(1, "day");
  }
  const datesStrMap = new Map<string, boolean>();
  for (const dateStr of dateStrArray) {
    datesStrMap.set(dateStr, true);
  }
  return { dateStrArray, datesStrMap };
}

export default enumerateDateStringBetweenDates;
