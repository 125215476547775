import { FC } from "react";
import { TableRow, TableCell } from "@mui/material";
import BookingTableDateTime from "../common/BookingsTable/BookingTableDateTime";
import TableRowPatientCellContent from "./TableRowPatientCellContent";
import BookingStatusCellContent from "./BookingStatusCellContent";
import ShowBookingAddress from "../../pages/ShowOrder/ShowBookingAddress";
import UnassignedNurseTableRowAlert from "./UnassignedNurseTableRowAlert";
import DelayedNurseStartServiceTableRowAlert from "./DelayedNurseStartServiceTableRowAlert";
import BookingTableServiceCellContent from "./BookingTableServiceCellContent";
import BookingsTableRowProps from "./BookingsTableRowProps";
import useBookingsTableRow from "./useBookingsTableRow";

const BookingsTableRow: FC<BookingsTableRowProps> = ({ booking: bookingProp, handleInvalidateQueries }) => {
  const {
    border,
    history,
    queryClient,
    queryKey,
    booking,
    delayedNurseData,
    showDelayedServiceAlert,
    hasUnassignedDates,
    borderColor,
    setBooking,
  } = useBookingsTableRow({ booking: bookingProp });

  return (
    <TableRow
      hover={true}
      key={booking.orderUUID}
      component={"div"}
      onClick={(e) => {
        e.stopPropagation();
        history.push(`/order/${booking.orderUUID}`);
      }}
      sx={{
        cursor: "pointer",
        position: "relative",
        border: border,
        borderBottom: `1px solid ${borderColor}`,
        "&:befor": {
          content: "'Assing booking'",
          width: "100%",
          height: "10px",
          backgroundColor: "red",
        },
        "& .MuiTableCell-root": {
          paddingTop: showDelayedServiceAlert || hasUnassignedDates ? "66px" : undefined,
        },
        "&:last-child td, &:last-child th": { border: 0 },
      }}
    >
      {hasUnassignedDates && (
        <UnassignedNurseTableRowAlert booking={booking} setBooking={setBooking} onAssignNurseSuccess={setBooking} />
      )}
      {showDelayedServiceAlert && delayedNurseData && <DelayedNurseStartServiceTableRowAlert data={delayedNurseData} />}
      <TableCell component="th" scope="row" className="service-cell">
        <BookingTableServiceCellContent booking={booking} />
      </TableCell>
      <TableCell className="date-time-cell">
        <BookingTableDateTime order={booking} />
      </TableCell>
      <TableCell className="patient-cell">
        <TableRowPatientCellContent
          booking={booking}
          setBooking={setBooking}
          onReportedUploadedSuccess={handleInvalidateQueries}
        />
      </TableCell>
      <TableCell
        className="status-cell"
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        <BookingStatusCellContent
          booking={booking}
          setBooking={setBooking}
          onAssignNurseSuccess={(booking) => {
            queryClient.resetQueries(queryKey);
            setBooking(booking);
          }}
        />
      </TableCell>
      <TableCell className="address-cell">
        <ShowBookingAddress booking={booking} labelWidth="100px" />
      </TableCell>
    </TableRow>
  );
};

export default BookingsTableRow;
