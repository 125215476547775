import { Autocomplete, TextField } from "@mui/material";
import { FC } from "react";

const MultipleSelectDropdown: FC<MultipleSelectDropdownProps> = ({ onChange, value, options, label, loading }) => {
  return (
    <Autocomplete
      fullWidth={true}
      disablePortal={true}
      disableCloseOnSelect={true}
      multiple={true}
      value={value}
      loading={loading}
      // renderOption={
      //   options?.[0]?.img
      //     ? (props, option) => {
      //         const { key, ...optionProps } = props;
      //         return (
      //           <Box
      //             // key={option.id}
      //             key={key}
      //             sx={{
      //               paddingX: 2,
      //               paddingY: 1,
      //               display: "flex",
      //               alignItems: "center",
      //               gap: 1,
      //               color: "black",
      //               width: "100%",
      //               justifyContent: "flex-start",
      //             }}
      //             {...optionProps}
      //           >
      //             {option.img && <Avatar sx={{ width: 24, height: 24 }} src={option.img} alt={option.label?.toString()} />}
      //             <Typography fontSize={16}>{option.label?.toLocaleString()}</Typography>
      //             <Divider />
      //           </Box>
      //         );
      //       }
      //     : undefined
      // }
      // renderOption={renderOption ? (_, option) => renderOption(option) : undefined}
      isOptionEqualToValue={(option, value) => {
        return option.id === value.id;
      }}
      onChange={(_, values) => {
        onChange(values);
      }}
      options={options}
      sx={{ marginTop: "1rem" }}
      renderInput={(params) => <TextField {...params} label={label} />}
    />
  );
};

interface MultipleSelectDropdownProps {
  value: MultipleSelectDropdownItem[];
  options: MultipleSelectDropdownItem[];
  label: string;
  loading?: boolean;
  // renderOption?: (option: MultipleSelectDropdownItem) => React.ReactNode;
  onChange: (values: MultipleSelectDropdownItem[]) => void;
}

export interface MultipleSelectDropdownItem {
  id: string;
  label: React.ReactNode;
  img?: string;
}

export default MultipleSelectDropdown;
