import { FC } from "react";
import NurseShiftStartFormItem from "./NurseShiftStartFormItem";
import NurseShiftEndFormItem from "./NurseShiftEndFormItem";
import NurseWeekDaysAvailability from "./NurseWeekDaysAvailability";
import { AddEditNurseForm } from "./OldNurseProfile";
import { Col, FormInstance, Row } from "antd";

const SPServiceAvailabilityFormItems: FC<
  SPServiceAvailabilityFormItemsProps
> = ({ form }) => {
  return (
    <Row style={{ width: "100%" }}>
      {/* <Divider orientation="left" style={{ fontSize: "10pt" }}>
        Scheduled Procedure Availability
      </Divider> */}
      <Col span={24}>
        <Row
          style={{
            rowGap: "1rem",
            flexWrap: "wrap",
            justifyContent: "space-between",
            alignItems: "flex-start",
          }}
        >
          <Col xs={24} sm={11} md={11} lg={11}>
            <NurseShiftStartFormItem form={form} />
          </Col>
          <Col xs={24} sm={11} md={11} lg={11}>
            <NurseShiftEndFormItem form={form} />
          </Col>
        </Row>
      </Col>
      <Col span={24}>
        <NurseWeekDaysAvailability />
      </Col>
    </Row>
  );
};

interface SPServiceAvailabilityFormItemsProps {
  form: FormInstance<AddEditNurseForm>;
}

export default SPServiceAvailabilityFormItems;
