import { FC } from "react";
import { Typography } from "@mui/material";
import { useHistory, useRouteMatch } from "react-router-dom";
import PharmacyBookingModel from "../../models/PharmacyBookingModel";

const CardListItem: FC<CardListItemProps> = ({ order, isNew = false }) => {
  const history = useHistory();

  // const [openDetailView, setOpenDetailView] = useState(false);
  const { url } = useRouteMatch();

  return (
    <>
      <div
        onClick={() => {
          history.push(`${url}/order-details/${order.orderUUID}`, { order: order });
        }}
        className="card-list-item"
        style={{
          padding: "14px",
          borderRadius: "8px",
          backgroundColor: "white",
          border: "1px solid white",
          cursor: "pointer",
        }}
      >
        <Typography variant="body2" component={"p"} sx={{ fontWeight: "600", marginBottom: "4px" }}>
          {order.itemsPaidFor?.map((item) => item.dosage).join(", ")}
        </Typography>
        {isNew ? (
          <Typography variant="caption" component={"p"} sx={{ marginBottom: "4px" }}>
            {order.itemsPaidFor?.[0].productName}
          </Typography>
        ) : (
          <Typography variant="caption" component={"p"} sx={{ marginBottom: "4px" }}>
            {order.itemsPaidFor?.[0].productName}
          </Typography>
        )}
        <Typography variant="caption" component={"p"}>
          {order.emirate}
        </Typography>
      </div>
      {/* <Link to={`${url}/order-details/${order.orderUUID}`}>
      </Link> */}
      {/* <Switch>
        <Route
          path={`${path}/order-details/${order.orderUUID}`}
          render={() => {
            return (
              <PharmacyDetailsDrawer
                order={order}
                onClose={function (): void {
                  // setOpenDetailView(false);
                  history.goBack();
                }}
              />
            );
          }}
        />
      </Switch> */}
    </>
  );
};

interface CardListItemProps {
  order: PharmacyBookingModel;
  isNew: boolean;
}

export default CardListItem;
