import { useQuery } from "@tanstack/react-query";
import { nurseService } from "../services";
import { useMemo } from "react";
import ProfessionalRatingsDetailsResponseModel from "../models/ProfessionalRatingsDetailsResponseModel";

function useNurseDetailedReviews(nurseUUID?: string) {
  const queryKey = useMemo(() => ["nurses/getdetailedratingsandreview", nurseUUID], [nurseUUID]);

  const query = useQuery<ProfessionalRatingsDetailsResponseModel>(
    queryKey,
    () => nurseService.getNurseDetailedRatingReviews(nurseUUID ?? ""),
    {
      enabled: !!nurseUUID,
      staleTime: Infinity,
      cacheTime: Infinity,
      refetchOnWindowFocus: false,
    }
  );

  return { query, queryKey };
}

export default useNurseDetailedReviews;
