import { FC, useMemo } from "react";
import { Skeleton, Typography } from "@mui/material";
import CardListHeader from "./CardListHeader";
import PharmacyBookingModel from "../../models/PharmacyBookingModel";
import CardListItem from "./CardListItem";

const BoardViewCardList: FC<BoardViewCardListProps> = ({ title, orders, loading }) => {
  const content = useMemo(() => {
    if (loading) {
      return (
        <div style={{ display: "flex", flexDirection: "column", gap: "12px" }}>
          <Skeleton variant="rounded" width={280} height={97} />
          <Skeleton variant="rounded" width={280} height={97} />
          <Skeleton variant="rounded" width={280} height={97} />
        </div>
      );
    }
    if (orders?.length === 0) {
      return (
        <Typography variant="caption" sx={{ textAlign: "center" }}>
          No bookings
        </Typography>
      );
    }
    if (orders) {
      return orders.map<JSX.Element>((order) => {
        return <CardListItem key={order.orderUUID} order={order} isNew={title.toLocaleLowerCase() === "new"} />;
      });
    }
  }, [loading, orders, title]);

  return (
    <div
      className="board-view-card-list"
      style={{
        borderRadius: "6px",
        height: "100%",
        maxHeight: "100%",
        width: "305px",
        minWidth: "305px",
        // boxShadow: "0 0 0 1px #edeae9",
        display: "flex",
        flexDirection: "column",
        gap: "12px",
      }}
    >
      <div style={{ padding: "12px 12px 0px 12px" }}>
        <CardListHeader title={title} />
      </div>
      <div
        id={`${title}-card-scroll-list`}
        style={{
          position: "relative",
          display: "flex",
          flexDirection: "column",
          gap: "12px",
          height: "100%",
          maxHeight: "100%",
          overflow: "scroll",
          padding: "2px 12px 12px 12px",
        }}
      >
        {content}
      </div>
    </div>
  );
};

interface BoardViewCardListProps {
  title: string;
  loading: boolean;
  orders: PharmacyBookingModel[];
}

export default BoardViewCardList;
