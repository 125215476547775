import { TiCancel } from "react-icons/ti";
import BookingModel from "../../../models/BookingModel";
import { Chip } from "@mui/material";
import { FaClipboardCheck, FaUserNurse } from "react-icons/fa";
import { FiCheckCircle } from "react-icons/fi";
import { IoDocumentAttachSharp, IoDocumentAttach } from "react-icons/io5";

export function getOrderStatusForAssignNurseModal(order: BookingModel) {
  return (
    <>
      {order?.status === "CANCELLED" ? (
        <Chip
          size={"small"}
          label={
            <div
              style={{
                fontWeight: "bold",
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "center",
                marginRight: "1rem",
              }}
            >
              <TiCancel size="26" color="#e86956" />
              &nbsp;&nbsp;Cancelled
            </div>
          }
          variant="outlined"
        />
      ) : order?.status === "ACCEPTED" ? (
        <Chip
          size={"small"}
          label={
            <div
              style={{
                fontWeight: "bold",
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "center",
                marginRight: "1rem",
              }}
            >
              <FaClipboardCheck color="#006400" />
              &nbsp;&nbsp;Accepted
            </div>
          }
          variant="outlined"
        />
      ) : order?.status === "PROCESSING" && order?.isSampleCollected ? (
        <Chip
          size={"small"}
          label={
            <div
              style={{
                fontWeight: "bold",
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "center",
                marginRight: "1rem",
              }}
            >
              <IoDocumentAttachSharp color="#e86956" />
              &nbsp;&nbsp;Sample Collected
            </div>
          }
          variant="outlined"
        />
      ) : order?.status === "PROCESSING" && order?.assignedNurses && order?.assignedNurses?.length ? (
        <Chip
          size={"small"}
          label={
            <div
              style={{
                fontWeight: "bold",
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "center",
                marginRight: "1rem",
              }}
            >
              <FaUserNurse color="#ffb803" />
              &nbsp;&nbsp;Assigned
            </div>
          }
          variant="outlined"
        />
      ) : order?.status === "REPORTED" ? (
        !order?.patient![0]?.isDarLabProduct ? (
          <Chip
            size={"small"}
            label={
              <div
                style={{
                  fontWeight: "bold",
                  display: "flex",
                  justifyContent: "flex-start",
                  alignItems: "center",
                  marginRight: "1rem",
                }}
              >
                <FiCheckCircle color="#008690" />
                &nbsp;&nbsp;Booking Complete
              </div>
            }
            variant="outlined"
          />
        ) : (
          <Chip
            size={"small"}
            label={
              <div
                style={{
                  fontWeight: "bold",
                  display: "flex",
                  justifyContent: "flex-start",
                  alignItems: "center",
                  marginRight: "1rem",
                }}
              >
                <IoDocumentAttach size="15" color="#008690" />
                &nbsp;&nbsp;Reported
              </div>
            }
            variant="outlined"
          />
        )
      ) : null}
    </>
  );
}
