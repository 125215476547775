import { Box, Button, Drawer } from "@mui/material";
import { FC, useCallback, useState } from "react";
import { StaffMemberModel } from "../models/StaffMemberModel";
import ProductModel from "../models/ProductModel";
import SearchAndFilterBookingsDrawer from "./SearchAndFilterBookingsDrawer";

export type SortBookingsType = "booking-date-ascending" | "booking-date-decsending";

const SearchAndFilterBookingButton: FC<SearchAndFilterBookingButtonProps> = ({
  onApplyFilterClicked,
  onClearFilterClicked,
  initialValues,
}) => {
  const [openDrawer, setOpenDrawer] = useState(false);

  const handleApplyFilter = useCallback(
    (params: FilterBookingsType) => {
      onApplyFilterClicked(params);
      setOpenDrawer(false);
    },
    [onApplyFilterClicked]
  );

  const handleClearFilter = useCallback(() => {
    onClearFilterClicked();
    setOpenDrawer(false);
  }, [onClearFilterClicked]);

  return (
    <>
      <Button variant="outlined" sx={{ marginTop: "5px" }} onClick={() => setOpenDrawer(true)}>
        Sort & Filter
      </Button>
      <Drawer anchor={"right"} open={openDrawer} onClose={() => setOpenDrawer(false)}>
        <Box
          sx={{
            width: "500px",
            padding: "16px",
            "@media (max-width: 700px)": {
              width: "100vw",
            },
          }}
        >
          {openDrawer && (
            <SearchAndFilterBookingsDrawer
              initialValues={initialValues}
              onApplyFilterClicked={handleApplyFilter}
              onClearFilterClicked={handleClearFilter}
            />
          )}
        </Box>
      </Drawer>
    </>
  );
};

export type FilterBookingsType = {
  sort?: SortBookingsType;
  selectedFilterNurses?: StaffMemberModel[];
  selectedFilterProducts?: ProductModel[];
};

interface SearchAndFilterBookingButtonProps {
  onApplyFilterClicked: (params: FilterBookingsType) => void;
  onClearFilterClicked: () => void;
  initialValues?: FilterBookingsType;
}

export default SearchAndFilterBookingButton;
