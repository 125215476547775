import axios from "axios";
import Mixpanel from "../utils/Mixpanel";
import decrypt from "./decrypt";
import encrypt from "./encrypt";
import BaseResponseModel from "../models/BaseResponseModel";
import { PartnerSettingModel } from "../models/ReduxStateModel";

export const authService = {
  userLogin,
  getUserData,
  upadteDeviceId,
  deleteDeviceId,
  readNotification,
  deleteNotification,
  setPassword,
  getPassowrdResetLink,
  userLoginWithPhoneNumber,
  getFirebaseCustomToken,
  setUserForceLogout,
};

export interface FirebaseCustomTokenResponseModel extends BaseResponseModel {
  token?: string;
}

export async function getFirebaseCustomTokenForPartnerDashboard(params: {
  firebaseUUID?: string;
}): Promise<FirebaseCustomTokenResponseModel | null> {
  try {
    const res = await axios.post<string>("/api/v1/apps/create-firebase-token-for-partner-dashboard", { data: encrypt(params) });
    const data: FirebaseCustomTokenResponseModel = decrypt(res.data);
    return data;
  } catch (error) {
    console.log("--- create-firebase-token-for-partner-dashboard error", error);
    return null;
  }
}

export interface GetFBTokenResponseModel extends BaseResponseModel {
  token?: string;
}

async function getFirebaseCustomToken(params: { userUUID?: string }): Promise<GetFBTokenResponseModel | null> {
  try {
    const res = await axios.post<string>("/api/v1/apps/create-firebase-custom-token-for-partner-dashboard", {
      data: encrypt(params),
    });
    const data: GetFBTokenResponseModel = decrypt(res.data);
    return data;
  } catch (error) {
    console.log("--- getFirebaseCustomToken error", error);
    return null;
  }
}

async function userLoginWithPhoneNumber(body: { phoneNumber: string }) {
  // console.log("------Login body------", body);
  // const data = await axios.post("/api/v1/partneraccounts/loginnew", {
  //   data: encrypt(body),
  // });
  const data = await axios.post("/api/v1/partneraccounts/login-with-phone-number", {
    data: encrypt(body),
  });
  const decryptedData = decrypt(data?.data);
  // console.log("----- login decryptedData-----", decryptedData);
  return { ...data, data: decryptedData };
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
async function getPassowrdResetLink(body: any) {
  delete body.status;
  Mixpanel.track("getPassowrdResetLink", { payload: body });
  const data = await axios.post("/api/v1/partners/edit", {
    data: encrypt(body),
  });
  return { ...data, data: decrypt(data?.data) };
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
async function setPassword(body: any) {
  delete body.status;
  Mixpanel.track("setPassword", { payload: body });
  const data = await axios.post("/api/v1/partners/edit", {
    data: encrypt(body),
  });
  return { ...data, data: decrypt(data?.data) };
}

async function userLogin(body: { username: string; password: string }) {
  // console.log("------Login body------", body);
  // const data = await axios.post("/api/v1/partneraccounts/loginnew", {
  //   data: encrypt(body),
  // });
  const data = await axios.post("/api/v1/partneraccounts/login", {
    data: encrypt(body),
  });
  const decryptedData = decrypt(data?.data);
  // console.log("----- login decryptedData-----", decryptedData);
  return { ...data, data: decryptedData };
}

export interface GetUserDataResponseModel extends BaseResponseModel {
  settings?: PartnerSettingModel;
}

export interface SetForceUserLogoutResponseModel extends BaseResponseModel {
  user?: PartnerSettingModel;
}

async function setUserForceLogout(params: { didForceLogout: boolean; partnerUserUUID: string }) {
  try {
    const res = await axios.post<string>("/api/v1/partneraccounts/set-partner-user-force-logout", { data: encrypt(params) });
    const data: SetForceUserLogoutResponseModel = decrypt(res.data);
    return data;
  } catch (e) {
    console.log("--- setUserForceLogout error", e);
    return null;
  }
}

async function getUserData(body: { phoneNumber: string; userName: string }) {
  try {
    if (localStorage.getItem("newAuthLogic")) {
      const data = await axios.post("/api/v1/partneraccounts/userdata-with-phone-number", {
        data: encrypt(body),
      });
      const decryptedData: GetUserDataResponseModel = decrypt(data?.data);
      if (decryptedData.settings?.notifications) {
        decryptedData.settings.notifications = decryptedData.settings?.notifications?.slice(0, 200);
      }
      return { ...data, data: decryptedData };
    }
    const data = await axios.post("/api/v1/partneraccounts/userdata", {
      data: encrypt(body),
    });
    const decryptedData: GetUserDataResponseModel = decrypt(data?.data);
    if (decryptedData.settings?.notifications) {
      decryptedData.settings.notifications = decryptedData.settings?.notifications?.slice(0, 200);
    }
    return { ...data, data: decryptedData };
  } catch (e) {
    console.log("--- getUserData error", e);
    return null;
  }
}

async function upadteDeviceId(body: object) {
  const data = await axios.post("/api/v1/apps/updatepartnerdeviceids", {
    data: encrypt(body),
  });
  const decryptedData = decrypt(data?.data);
  return { ...data, data: decryptedData };
}

async function deleteDeviceId(body: object) {
  const data = await axios.post("/api/v1/apps/deletepartnerdeviceid", {
    data: encrypt(body),
  });
  return { ...data, data: decrypt(data?.data) };
}

async function readNotification(body: { partnerUUID: string; notificationUUIDArray: string[] }) {
  const data = await axios.post("/api/v1/partners/marknotificationsasread", {
    data: encrypt(body),
  });
  const res: BaseResponseModel = decrypt(data?.data);
  return { ...data, data: res };
}

async function deleteNotification(body: { deleteType?: "ALL"; partnerUUID: string; notificationUUID: string[] }) {
  const data = await axios.post("/api/v1/partners/deletepartnernotification", {
    data: encrypt(body),
  });
  const res: BaseResponseModel = decrypt(data?.data);
  return { ...data, data: res };
}
