import { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { authAction } from "../actions";
import { useHistory } from "react-router-dom";
import { useQueryClient } from "@tanstack/react-query";
import { orderType } from "../types";
import ReduxStateModel from "../models/ReduxStateModel";
import useFireAuth from "./useFireAuth";

function useLogout({ pushToLogin = true }: { pushToLogin?: boolean }) {
  const dispatch = useDispatch();
  const history = useHistory();
  const queryClient = useQueryClient();
  const partnerUUID = useSelector((state: ReduxStateModel) => state.settingReducer?.manageDateTime?.partnerSettings?.partnerUUID);

  const { fireAuth } = useFireAuth();

  const logout = useCallback(() => {
    fireAuth?.signOut();
    localStorage.clear();
    dispatch({ type: orderType.LOG_OUT });
    dispatch(
      authAction.userLogout(
        {
          firebaseToken: localStorage?.FirebaseToken,
          partnerUUID: partnerUUID,
        },
        () => {
          queryClient.resetQueries();
          if (pushToLogin) {
            history.push("/login");
          }
        }
      )
    );
  }, [dispatch, fireAuth, history, partnerUUID, pushToLogin, queryClient]);

  return { logout };
}

export default useLogout;
