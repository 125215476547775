import { Box, Button, Divider, Drawer, IconButton, Typography } from "@mui/material";
import { grey } from "@mui/material/colors";
import { FC, useCallback, useState } from "react";
import { useSelector } from "react-redux";
import AddIcon from "@mui/icons-material/Add";
import CancelRoundedIcon from "@mui/icons-material/CancelRounded";
import useAllPartnerRequestsQuery from "../hooks/useAllPartnerRequestsQuery";
import ReduxStateModel from "../models/ReduxStateModel";
import LoadingSpinner from "./LoadingSpinner";
import AddInventoryRequest from "./AddInventoryRequest";

const InventorySideDrawer: FC<InventorySideDrawerProps> = ({ onClose, openDrawer, open }) => {
  const authReducer = useSelector((state: ReduxStateModel) => state.authReducer?.setting);
  const [showAddRequestDrawer, setShowAddRequestDrawer] = useState(false);

  const { query } = useAllPartnerRequestsQuery({ partnerUUID: authReducer?.partnerUUID });

  const handleOpenAddRequestDrawer = useCallback(() => {
    setShowAddRequestDrawer(true);
    onClose();
  }, [onClose]);

  const handleCloseAddRequestDrawer = useCallback(() => {
    openDrawer();
    setShowAddRequestDrawer(false);
  }, [openDrawer]);

  return (
    <>
      <Drawer anchor={"right"} open={open} onClose={onClose}>
        <Box width={"600px"} maxWidth={"100vw"} height={"100vh"}>
          <Box
            height={"50px"}
            width={"100%"}
            borderBottom={`1px solid ${grey[200]}`}
            display={"flex"}
            alignItems={"center"}
            justifyContent={"space-between"}
            paddingX={2}
          >
            <Typography fontWeight={500}>Inventory</Typography>
            <Box>
              <Button
                variant="contained"
                disableElevation={true}
                startIcon={<AddIcon sx={{ color: "white" }} />}
                onClick={handleOpenAddRequestDrawer}
              >
                <Box color={"white"}>Add Request</Box>
              </Button>
              <IconButton onClick={onClose}>
                <CancelRoundedIcon />
              </IconButton>
            </Box>
          </Box>
          <Box paddingX={2} paddingY={2}>
            <Typography>Requests History</Typography>
            {query.isLoading && (
              <LoadingSpinner styles={{ minHeight: "100px" }} spinning={true} children={undefined} showBackdrop={false} />
            )}
            {(query.data?.requests?.length ?? 0) > 0 ? (
              query.data?.requests?.map((req) => {
                return (
                  <Box key={req._id} marginTop={2}>
                    <Typography fontWeight={500}>
                      {req.orderUUID} - {req.status?.split("_").join(" ")}
                    </Typography>
                    <Divider sx={{ marginTop: 2 }} />
                  </Box>
                );
              })
            ) : (
              <Typography marginTop={4} textAlign={"center"} color={"grey"}>
                No requests found
              </Typography>
            )}
          </Box>
        </Box>
      </Drawer>
      <AddInventoryRequest open={showAddRequestDrawer} onClose={handleCloseAddRequestDrawer} />
    </>
  );
};

interface InventorySideDrawerProps {
  open: boolean;
  openDrawer: () => void;
  onClose: () => void;
}

export default InventorySideDrawer;
