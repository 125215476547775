export const nurseType = {
  GET_NURSES: "GET_NURSES",
  GET_LOADER: "GET_LOADER",
  SINGLE_NURSE: "SINGLE_NURSE",
  LOG_OUT: "LOG_OUT",
  GET_NURSE_UPDATE_LOADER: "GET_NURSE_UPDATE_LOADER",
  GET_NURSE_REVIEW: "GET_NURSE_REVIEW",
  GET_VALID_NURSES_FOR_ORDER: "GET_VALID_NURSES_FOR_ORDER",
  GET_VALID_NURSES_LOADER: "GET_VALID_NURSES_LOADER",
};
