import BookingPopover from "./BookingPopover";
import { Popover } from "antd";
import { FC, useCallback, useMemo } from "react";
import { NurseBookingModel, NursesDatum } from "../../models/SchedulerExploreViewResponseModel";
import { useHistory, useRouteMatch } from "react-router-dom";

const WeekViewBookingTile: FC<WeekViewBookingTileProps> = ({ booking, index, renderSmallTile, nurseData }) => {
  const history = useHistory();
  const { url } = useRouteMatch();

  const spanTwoDaysBooking = useMemo(() => {
    const value = booking.endMoment.isAfter(booking.startMoment.clone().endOf("day"));
    return value;
  }, [booking.endMoment, booking.startMoment]);

  const handleBookingClicked = useCallback(() => {
    if (booking.type === "BREAK") {
      return history.replace(`${url}/view-break-time`, { booking: JSON.stringify(booking), nurseData: nurseData });
    }
    history.replace(`${url}/booking-details`, {
      booking: {
        orderUUID: booking.orderUUID,
        nurseUUID: booking.nurseUUID,
        startMoment: booking.startMoment.valueOf(),
        endMoment: booking.endMoment.valueOf(),
        patients: booking.patients,
        nurseName: booking.nurseName,
        nurseProfileImg: booking.nurseProfileImg,
        nurseOrderID: booking.nurseOrderID,
        service: booking.service,
        caseDescription: booking.caseDescription,
        nurseData: nurseData,
        locationLat: booking.locationLat,
        locationLng: booking.locationLng,
        address: booking.address,
        addressDetails: booking.addressDetails,
        nursePickupAddress: booking.temporaryPickupAddress,
        nurseDropAddress: booking.temporaryDropOffAddress,
      },
    });
  }, [booking, history, nurseData, url]);

  const popoverTitle = (booking.patients?.length ?? 0) > 1 ? "PATIENTS:" : "PATIENT:";

  return (
    <Popover
      key={booking.nurseOrderID ?? `${booking.nurseUUID}_${booking.startMoment.valueOf()}_${index}`}
      title={booking.type === "BOOKING" ? popoverTitle : undefined}
      content={booking.type === "BOOKING" ? <BookingPopover patients={booking.patients ?? []} /> : undefined}
      placement="bottomLeft"
    >
      <div
        onClick={handleBookingClicked}
        className={booking.type === "BREAK" ? "on-leave-cell-content" : ""}
        style={{
          // backgroundColor: "#25bcbd",
          backgroundColor: booking.type === "BOOKING" ? "#25bbbb1a" : undefined,
          borderLeft: booking.type === "BOOKING" ? "2px solid #25bcbd" : undefined,
          // height: "80px",
          // borderRadius: "5px",
          minHeight: renderSmallTile ? "25px" : "55px",
          flex: "1",
          padding: "4px 4px",
          fontSize: "0.7rem",
          textAlign: "start",
          display: "flex",
          flexDirection: "column",
          gap: "0.3rem",
          color: booking.type === "BOOKING" ? "#515151" : undefined,
          cursor: "pointer",
        }}
      >
        <div style={{ fontWeight: "600" }}>
          {booking.type === "BREAK" ? "Break " : ""}
          {`${booking.startMoment.format(spanTwoDaysBooking ? "DD/MM hh:mm a" : "hh:mm a")} - ${booking.endMoment.format(
            spanTwoDaysBooking ? "DD/MM hh:mm a" : "hh:mm a"
          )}`}
        </div>
        {!renderSmallTile ? (
          <div>
            {booking.patients && booking.patients.length ? (
              <div>
                {booking.patients.length === 1
                  ? booking.patients[0].name
                  : `${booking.patients[0].name} & ${booking.patients.length - 1} ${
                      booking.patients.length - 1 === 1 ? "other" : "others"
                    }`}
              </div>
            ) : (
              ""
            )}
          </div>
        ) : undefined}
      </div>
    </Popover>
  );
};

interface WeekViewBookingTileProps {
  booking: NurseBookingModel;
  index: number;
  renderSmallTile: boolean;
  nurseData: NursesDatum;
}

export default WeekViewBookingTile;
