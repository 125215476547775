import { FC, useEffect, useMemo, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { StaffMemberCertificateModel, StaffMemberModel } from "../../models/StaffMemberModel";
import { useQueryClient, useMutation } from "@tanstack/react-query";
import { showMessage } from "../../components/common/notification";
import { nurseService } from "../../services";
import { useSelector } from "react-redux";
import { Button, Col, Divider, Form, Row, Spin, Tabs } from "antd";
import { AddEditNurseForm, ProfessionalSDAvailability } from "./OldNurseProfile";
import {
  allAbuDhabiAreas,
  allAlAinAreas,
  allDubaiAreas,
  allRAKAreas,
  allSharjahAreas,
  driverSchedulePageBackgroundColor,
} from "../../constants";
import ProfileImageCard from "./ProfileImageCard";
import PersonalDetailsTab from "./PersonalDetailsTab";
import ProfessionalDetailsTab from "./ProfessionalDetailsTab";
import ServicesAndAvailabilityTab from "./ServicesAndAvailabilityTab";
import moment from "moment";
import ReviewsAndRatingsTab from "./ReviewsAndRatingsTab";
import CoverImage from "../NewDriverProfile/CoverImage";
import useAutomatedDriverScheduleQueryKey from "../../hooks/useAutomatedDriverScheduleQueryKey";
import Mixpanel from "../../utils/Mixpanel";
import ReduxStateModel from "../../models/ReduxStateModel";
import useCurrentUserRole from "../../hooks/useCurrentUserRole";
import useNurseDetails from "../../hooks/useNurseDetails";
import BasePageLayout from "../../components/BasePageLayout";
import "./new-styles.css";

type NurseProfileTabKey = "personal-details" | "professional-details" | "services-and-availability" | "reviews-and-ratings";

const NewNurseProfile: FC = () => {
  const { canUpdate } = useCurrentUserRole();
  const history = useHistory();
  const queryClient = useQueryClient();
  const authReducer = useSelector((state: ReduxStateModel) => state?.authReducer);
  const partnerUserUUID: string | undefined = authReducer?.setting?.partnerUserUUID;
  const partnerUUID = authReducer?.setting?.partnerUUID;
  const { id: nurseUUID } = useParams<{ id?: string }>();
  const [selectedTab, setSelectedTab] = useState<NurseProfileTabKey>("personal-details");
  const [nurse, setNurse] = useState<StaffMemberModel>({});
  const [form] = Form.useForm<AddEditNurseForm>();

  const teamPageQueryKey: [string, string | undefined] = ["nurses", partnerUserUUID];

  const { query: nurseDetailsQuery, queryKey: nurseDetailsQueryKey } = useNurseDetails({
    nurseUUID: nurseUUID,
    onSuccess: (res) => {
      if (res?.nurse) {
        const nurseLocations = new Map<string, boolean>();
        for (const location of res.nurse.locations ?? []) {
          if (location.includes("Abu-Dhabi")) {
            nurseLocations.set("Abu-Dhabi", true);
          } else if (location.includes("Dubai")) {
            nurseLocations.set("Dubai", true);
          } else if (location.includes("Sharjah")) {
            nurseLocations.set("Sharjah", true);
          } else if (location.includes("Al-Ain")) {
            nurseLocations.set("Al-Ain", true);
          } else if (location.includes("RAK")) {
            nurseLocations.set("RAK", true);
          }
        }
        res.nurse.locations = Array.from(nurseLocations.keys());
        setNurse(res.nurse);
      } else {
        showMessage("error", "Something went wrong");
      }
    },
    onError: () => showMessage("error", "Something went wrong"),
  });
  const { isFetching: fetchingNurseDetails, data: nurseDetailsResponse } = nurseDetailsQuery;

  const selectedDate = useSelector((state: ReduxStateModel) => state?.driverSchedulerReducer?.selectedDate ?? moment());

  const driverScheduleQueryKey = useAutomatedDriverScheduleQueryKey({
    selectedDate: selectedDate,
  });

  const handleNurseCreatedOrUpdatedSuccessfully = (updatedNurse: StaffMemberModel) => {
    showMessage("success", nurseUUID ? "Professional was updated successfully" : "Professional was created successfully");
    if (nurseUUID) {
      queryClient.setQueryData(teamPageQueryKey, (prevValue: unknown) => {
        if (!prevValue) return prevValue;
        const newValue = JSON.parse(JSON.stringify(prevValue));
        newValue.data.nurses = newValue.data.nurses.map((nurse: StaffMemberModel) => {
          if (nurse.nurseUUID === updatedNurse.nurseUUID) {
            return updatedNurse;
          }
          return nurse;
        });
        return newValue;
      });
    } else {
      queryClient.setQueryData(teamPageQueryKey, (prevValue: unknown) => {
        if (!prevValue) return prevValue;
        const newValue = JSON.parse(JSON.stringify(prevValue));
        newValue.data.nurses.unshift(updatedNurse);
        return newValue;
      });
    }
    queryClient.invalidateQueries(teamPageQueryKey);
    queryClient.invalidateQueries(nurseDetailsQueryKey);
    queryClient.resetQueries(driverScheduleQueryKey);
  };

  const nurseActiveDaysInitialValues = useMemo(() => {
    const nurseActiveDaysMap = new Map<string, boolean>();
    for (const day of nurse?.activeDays ?? []) {
      nurseActiveDaysMap.set(day, true);
    }
    const values = {
      nurseAvtiveDayMonday: false,
      nurseAvtiveDayTuesday: false,
      nurseAvtiveDayWednesday: false,
      nurseAvtiveDayThursday: false,
      nurseAvtiveDayFriday: false,
      nurseAvtiveDaySaturday: false,
      nurseAvtiveDaySunday: false,
    };
    if (nurseActiveDaysMap.get("monday")) {
      values.nurseAvtiveDayMonday = true;
    }
    if (nurseActiveDaysMap.get("tuesday")) {
      values.nurseAvtiveDayTuesday = true;
    }
    if (nurseActiveDaysMap.get("wednesday")) {
      values.nurseAvtiveDayWednesday = true;
    }
    if (nurseActiveDaysMap.get("thursday")) {
      values.nurseAvtiveDayThursday = true;
    }
    if (nurseActiveDaysMap.get("friday")) {
      values.nurseAvtiveDayFriday = true;
    }
    if (nurseActiveDaysMap.get("saturday")) {
      values.nurseAvtiveDaySaturday = true;
    }
    if (nurseActiveDaysMap.get("sunday")) {
      values.nurseAvtiveDaySunday = true;
    }
    return values;
  }, [nurse?.activeDays]);

  useEffect(() => {
    if (!nurseDetailsResponse?.nurse?.nurseUUID) return;
    setNurse(nurseDetailsResponse?.nurse);
  }, [nurseDetailsResponse]);

  const editNurseMutation = useMutation({ mutationFn: nurseService.editNurse });
  const createNurseMutation = useMutation({
    mutationFn: nurseService.addNurse,
  });
  const editNurseTimeslot = useMutation({
    mutationFn: nurseService.updateNurseTimeslot,
  });

  const handleFormValidationFinished = (values: AddEditNurseForm) => {
    if (!nurse.address || !nurse.addressLink) {
      setSelectedTab("professional-details");
      return showMessage("error", "Please select the nurse address");
    }
    try {
      new URL(nurse.addressLink);
    } catch (error) {
      setSelectedTab("professional-details");
      return showMessage("error", "Please select the nurse address");
    }
    const profileImage = nurse.profileImageData?.data ?? nurse.profileImage ?? nurse.profilePhoto;
    if (!nurseUUID && !nurse.profileImageData) {
      return showMessage("error", "Please select a profile image");
    } else if (!profileImage) {
      return showMessage("error", "Please select a profile image");
    }
    // const didSelectSDProduct = selectedProducts.some((prod) => prod.productType === "S-P");
    if (
      // didSelectSDProduct &&
      !values.nurseAvtiveDayFriday &&
      !values.nurseAvtiveDayMonday &&
      !values.nurseAvtiveDaySaturday &&
      !values.nurseAvtiveDaySunday &&
      !values.nurseAvtiveDayThursday &&
      !values.nurseAvtiveDayTuesday &&
      !values.nurseAvtiveDayWednesday
    ) {
      setSelectedTab("services-and-availability");
      return showMessage("error", "Please select at least one working day for the professional");
    }
    const activeDays: string[] = [];
    if (values.nurseAvtiveDayFriday) {
      activeDays.push("friday");
    }
    if (values.nurseAvtiveDayMonday) {
      activeDays.push("monday");
    }
    if (values.nurseAvtiveDaySaturday) {
      activeDays.push("saturday");
    }
    if (values.nurseAvtiveDaySunday) {
      activeDays.push("sunday");
    }
    if (values.nurseAvtiveDayThursday) {
      activeDays.push("thursday");
    }
    if (values.nurseAvtiveDayTuesday) {
      activeDays.push("tuesday");
    }
    if (values.nurseAvtiveDayWednesday) {
      activeDays.push("wednesday");
    }
    const nurseLocations: string[] = [];
    if (values.locations?.some((location) => location.startsWith("Abu-Dhabi"))) {
      nurseLocations.push(...allAbuDhabiAreas);
    }
    if (values.locations?.some((location) => location.startsWith("Dubai"))) {
      nurseLocations.push(...allDubaiAreas);
    }
    if (values.locations?.some((location) => location.startsWith("Sharjah"))) {
      nurseLocations.push(...allSharjahAreas);
    }
    if (values.locations?.some((location) => location.startsWith("Al-Ain"))) {
      nurseLocations.push(...allAlAinAreas);
    }
    if (values.locations?.some((location) => location.startsWith("RAK"))) {
      nurseLocations.push(...allRAKAreas);
    }
    const phoneNumber = `${values.mobileNumber?.countryCode ?? ""}${
      values.mobileNumber?.number?.startsWith("0") ? values.mobileNumber?.number.slice(1) : values.mobileNumber?.number ?? ""
    }`.replaceAll(" ", "");
    // const isAllSelectedProductsAreSD = selectedProducts.every((prod) => prod.productType === "S-D");
    const updatedNurse: StaffMemberModel = {
      ...nurse,
      isDayShift: values.sdAvailability === "day-shift",
      isFlexible: values.sdAvailability === "flexible",
      skillNumber: values.skillNumber,
      activeDays: activeDays,
      bio: values.bio,
      certificationsList: values.certificationsList?.map<StaffMemberCertificateModel>((name) => ({
        name,
      })),
      dob: values.dob?.format("DD/MM/YYYY"),
      email: values.email,
      startTime: values.startTime ? moment(values.startTime).format() : nurse.startTime,
      endTime: values.endTime ? moment(values.endTime).format() : nurse.endTime,
      experienceInYears: values.experienceInYears,
      firstName: values.firstName,
      lastName: values.lastName,
      languages: values.languages,
      licenseNumber: values.licenseNumber,
      locations: nurseLocations,
      mobileNumber: phoneNumber,
      nationality: values.nationality,
      profileImage: nurse?.profileImage,
      qualification: values.qualification,
      services: values.services,
      sex: values.sex,
      skills: values.skills,
      isNightShift: values.isNightShift ?? false, // values.sdAvailability === "night-shift",
      totalYearsOfExperience: values.totalYearsOfExperience,
      skipInScheduler: nurse.skipInScheduler,
      partnerUserUUID: partnerUserUUID,
      partnerUUID: partnerUUID,
      isActive: nurse.nurseUUID ? nurse.isActive : true,
    };
    if (nurseUUID) {
      handleNurseUpdate(updatedNurse);
    } else {
      handleCreateNurse(updatedNurse);
    }
  };

  const handleCreateNurse = async (newNurse: StaffMemberModel) => {
    const res = await createNurseMutation.mutateAsync(newNurse);
    if (res?.data?.success && res?.data?.nurseUUID) {
      await editNurseTimeslot.mutateAsync({
        nurseUUID: res?.data?.nurseUUID,
        isShiftTimeChange: true,
        isNewNurse: true,
        isDeleted: false,
        newDays: newNurse.activeDays ?? [],
        newHoliday: newNurse.holidays ?? [],
        deletedHoliday: [],
        deletedDays: [],
      });
      handleNurseCreatedOrUpdatedSuccessfully(newNurse);
      Mixpanel.track("Create nurse", {
        payload: newNurse,
        user: authReducer?.setting?.email,
      });
      history.replace("/team");
    } else {
      Mixpanel.track("Create nurse error", {
        payload: newNurse,
        user: authReducer?.setting?.email,
        error: res?.data?.error,
      });
      showMessage("error", res.data.error ?? "Something went wrong");
    }
  };

  const handleNurseUpdate = async (updatedNurse: StaffMemberModel) => {
    const promiseResults = await Promise.all([
      editNurseTimeslot.mutateAsync({
        nurseUUID: nurseUUID,
        isShiftTimeChange: true,
        isNewNurse: false,
        isDeleted: false,
        newDays: updatedNurse.activeDays ?? [],
        newHoliday: updatedNurse.holidays ?? [],
        deletedHoliday: [],
        deletedDays: [],
      }),
      editNurseMutation.mutateAsync(updatedNurse),
    ]);
    const res = promiseResults[1];
    if (res?.data?.success) {
      Mixpanel.track("Update nurse", {
        payload: updatedNurse,
        user: authReducer?.setting?.email,
      });
      handleNurseCreatedOrUpdatedSuccessfully(updatedNurse);
    } else {
      Mixpanel.track("Update nurse error", {
        payload: updatedNurse,
        user: authReducer?.setting?.email,
        error: res?.data?.error,
      });
      showMessage("error", res.data.error ?? "Something went wrong");
    }
  };

  const tabItems = useMemo(() => {
    const arr = [
      {
        label: `Personal details`,
        key: "personal-details",
      },
      {
        label: `Professional details`,
        key: "professional-details",
      },
      {
        label: `Services & availability`,
        key: "services-and-availability",
      },
    ];
    if (nurseUUID) {
      arr.push({
        label: "Performance review",
        key: "reviews-and-ratings",
      });
    }
    return arr;
  }, [nurseUUID]);

  const handleFormValidationFailed = (errors: { errorFields?: { name?: (string | number)[] }[] }) => {
    showMessage("error", "Please fill all the mandatory fields");
    const errorFieldNames: string[] = [];
    for (const errorField of errors.errorFields ?? []) {
      errorFieldNames.push(...((errorField.name ?? []) as string[]));
    }
    for (const fieldName of errorFieldNames) {
      if (personalDetailsTabRequiredFieldNames.includes(fieldName)) {
        return setSelectedTab("personal-details");
      } else if (professionalDetailsTabRequiredFieldNames.includes(fieldName)) {
        return setSelectedTab("professional-details");
      } else if (servicesTabRequiredFieldNames.includes(fieldName)) {
        return setSelectedTab("services-and-availability");
      }
    }
  };

  let content = (
    <Spin
      spinning={
        fetchingNurseDetails || editNurseMutation.isLoading || createNurseMutation.isLoading || editNurseTimeslot.isLoading
      }
    >
      <Row
        style={{
          width: "100%",
          rowGap: "1rem",
          marginTop: "2rem",
          zIndex: 1,
          alignItems: "flex-start",
          justifyContent: "space-between",
        }}
        // className="container"
      >
        <Col xs={24} sm={24} md={24} lg={8}>
          <ProfileImageCard
            showActiveSwitch={!!nurseUUID}
            handleNurseUpdatedSuccessfully={handleNurseCreatedOrUpdatedSuccessfully}
            setMember={setNurse}
            staffMember={nurse}
          />
        </Col>
        <Col xs={24} sm={24} md={24} lg={15}>
          <div
            style={{
              padding: "16px 16px",
              borderRadius: "5px",
              border: "1px solid #f1f1f1",
              backgroundColor: "white",
            }}
          >
            <Tabs
              defaultActiveKey="personal-details"
              activeKey={selectedTab}
              onChange={(e) => {
                setSelectedTab(e as NurseProfileTabKey);
              }}
              items={tabItems}
            />
            <div
              style={{
                display: selectedTab === "personal-details" ? "flex" : "none",
                width: "100%",
              }}
            >
              <PersonalDetailsTab />
            </div>
            <div
              style={{
                display: selectedTab === "professional-details" ? "flex" : "none",
                width: "100%",
              }}
            >
              <ProfessionalDetailsTab
                showActiveSwitch={!!nurseUUID}
                form={form}
                handleNurseUpdatedSuccessfully={handleNurseCreatedOrUpdatedSuccessfully}
                setMember={setNurse}
                staffMember={nurse}
              />
            </div>
            <div
              style={{
                display: selectedTab === "services-and-availability" ? "flex" : "none",
                width: "100%",
              }}
            >
              <ServicesAndAvailabilityTab form={form} />
            </div>
            {nurseUUID && (
              <div
                style={{
                  display: selectedTab === "reviews-and-ratings" ? "flex" : "none",
                  width: "100%",
                }}
              >
                <ReviewsAndRatingsTab />
              </div>
            )}
            <Divider />
            {selectedTab !== "reviews-and-ratings" && (
              <div>
                <Button
                  loading={editNurseMutation.isLoading || createNurseMutation.isLoading}
                  htmlType="submit"
                  type="primary"
                  style={{ borderRadius: "5px" }}
                >
                  Save
                </Button>
              </div>
            )}
          </div>
        </Col>
      </Row>
    </Spin>
  );

  if (nurse?.nurseUUID || !nurseUUID) {
    const nurseStartTime = nurse?.nurseUUID ? moment(nurse?.startTime) : undefined;
    const nurseEndTime = nurse?.nurseUUID ? moment(nurse?.endTime) : undefined;
    const initialStartTime = nurse?.nurseUUID
      ? moment(`${moment().format("DD/MM/YYYY")} ${nurseStartTime?.format("hh:mm A")}`, "DD/MM/YYYY hh:mm A")
      : undefined;
    let initialEndTime = nurse?.nurseUUID
      ? moment(`${moment().format("DD/MM/YYYY")} ${nurseEndTime?.format("hh:mm A")}`, "DD/MM/YYYY hh:mm A")
      : undefined;
    if (initialEndTime?.isSame(moment().startOf("day"))) {
      initialEndTime = initialEndTime.endOf("day").set("second", 0).set("millisecond", 0);
    }
    let sdAvailabilit: ProfessionalSDAvailability;
    if (nurse.isDayShift) {
      sdAvailabilit = "day-shift";
    } else if (nurse.isNightShift) {
      sdAvailabilit = "night-shift";
    } else {
      sdAvailabilit = "flexible";
    }
    const initialValues: AddEditNurseForm = {
      sdAvailability: sdAvailabilit,
      firstName: nurse?.firstName ?? "",
      lastName: nurse?.lastName ?? "",
      qualification: nurse?.qualification,
      dob: nurse?.dob ? moment(nurse?.dob, "DD/MM/YYYY") : undefined,
      sex: nurse?.sex,
      email: nurse?.email,
      nationality: nurse?.nationality,
      mobileNumber: {
        countryCode: "+971",
        number: nurse?.mobileNumber?.replace("+971", ""),
      },
      languages: nurse?.languages,
      locations: nurse?.locations,
      totalYearsOfExperience: nurse?.totalYearsOfExperience,
      licenseNumber: nurse?.licenseNumber,
      services: nurse?.services,
      startTime: nurse?.startTime ? initialStartTime?.format() : undefined,
      endTime: nurse?.endTime ? initialEndTime?.format() : undefined,
      isNightShift: nurse?.isNightShift,
      nurseAvtiveDayMonday: nurseActiveDaysInitialValues.nurseAvtiveDayMonday,
      nurseAvtiveDayTuesday: nurseActiveDaysInitialValues.nurseAvtiveDayTuesday,
      nurseAvtiveDayWednesday: nurseActiveDaysInitialValues.nurseAvtiveDayWednesday,
      nurseAvtiveDayThursday: nurseActiveDaysInitialValues.nurseAvtiveDayThursday,
      nurseAvtiveDayFriday: nurseActiveDaysInitialValues.nurseAvtiveDayFriday,
      nurseAvtiveDaySaturday: nurseActiveDaysInitialValues.nurseAvtiveDaySaturday,
      nurseAvtiveDaySunday: nurseActiveDaysInitialValues.nurseAvtiveDaySunday,
      certificationsList: nurse?.certificationsList?.map<string>((cert) => cert.name),
      skills: nurse?.skills,
      bio: nurse?.bio,
      experienceInYears: nurse?.experienceInYears,
      skipInScheduler: nurse?.skipInScheduler ?? false,
      skillNumber: nurse.skillNumber,
    };
    content = (
      <Form
        onFinish={handleFormValidationFinished}
        form={form}
        layout="vertical"
        size="large"
        disabled={!canUpdate}
        scrollToFirstError={true}
        onFinishFailed={handleFormValidationFailed}
        initialValues={initialValues}
      >
        {content}
      </Form>
    );
  }

  return (
    <BasePageLayout
      mainContent={
        <div
          className=""
          style={{
            height: "100%",
            display: "flex",
            flexDirection: "column",
            backgroundColor: driverSchedulePageBackgroundColor,
          }}
        >
          <div
            className="main_page nurse-profile-form web"
            style={{
              width: "100%",
              maxWidth: "100%",
              height: "100%",
              position: "relative",
              // marginBottom: "6rem",
              padding: "0 2rem",
            }}
          >
            <CoverImage alt={nurse.firstName ?? ""} />
            {content}
          </div>
          <div
            className="main_page nurse-profile-form mobile"
            style={{
              width: "100%",
              maxWidth: "100%",
              position: "relative",
              padding: "0rem 1rem 6rem 1rem",
            }}
          >
            <CoverImage alt={nurse.firstName ?? ""} />
            {content}
          </div>
        </div>
      }
    />
  );
};

const personalDetailsTabRequiredFieldNames = [
  "firstName",
  "lastName",
  "dob",
  "sex",
  "email",
  "mobileNumber",
  "nationality",
  "languages",
];
const professionalDetailsTabRequiredFieldNames = ["locations", "qualification", "licenseNumber", "totalYearsOfExperience", "bio"];
const servicesTabRequiredFieldNames = ["services", "startTime", "endTime", "sdAvailability"];

export default NewNurseProfile;
