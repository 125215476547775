import axios from "axios";
import decrypt from "./decrypt";
import encrypt from "./encrypt";
import Mixpanel from "../utils/Mixpanel";

export const partnerEditService = {
  managePartnerStatus,
};

async function managePartnerStatus(body) {
  delete body.status;
  Mixpanel.track("managePartnerStatus", { payload: body });
  const data = await axios.post("/api/v1/partners/edit", { data: encrypt(body) });
  return { ...data, data: decrypt(data?.data) };
}
