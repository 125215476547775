import { Divider, Spin } from "antd";
import { FC } from "react";
import { useSelector } from "react-redux";
import { List } from "@mui/material";
import { useQueryClient } from "@tanstack/react-query";
import useGetPartnerUsers from "../../hooks/useGetPartnerUsers";
import ReduxStateModel from "../../models/ReduxStateModel";
import PartnerUserListItem from "./PartnerUserListItem";
import usePartnerUsersInvites from "../../hooks/usePartnerUsersInvites";
import PartnerUserInviteListItem from "./PartnerUserInviteListItem";

const PartnerUsersList: FC = () => {
  const queryClient = useQueryClient();
  const authReducer = useSelector((state: ReduxStateModel) => state.authReducer?.setting);

  const { query, queryKey } = useGetPartnerUsers({ partnerUUID: authReducer?.partnerUUID });

  const { query: invitesQuery, queryKey: invitesQueryKey } = usePartnerUsersInvites({ partnerUUID: authReducer?.partnerUUID });

  return (
    <>
      <Divider orientation="left" style={{ marginBottom: "0px" }}>
        Users
      </Divider>
      <Spin spinning={query.isLoading}>
        <List>
          {query.data?.users?.map((user, i) => {
            return (
              <PartnerUserListItem
                user={user}
                key={user.partnerUserUUID}
                onDeleteSuccess={function (): void {
                  queryClient.resetQueries(queryKey);
                }}
                isLastItem={i === (query.data?.users?.length ?? 0) - 1}
              />
            );
          })}
          {(invitesQuery.data?.invites?.length ?? 0) > 0 && (
            <Divider orientation="left" style={{ marginBottom: "0px" }}>
              Sent Invites
            </Divider>
          )}
          {invitesQuery.data?.invites?.map((invite) => {
            return (
              <PartnerUserInviteListItem
                key={invite._id}
                invite={invite}
                onDeleteSuccess={function (): void {
                  queryClient.resetQueries(invitesQueryKey);
                }}
              />
            );
          })}
        </List>
      </Spin>
    </>
  );
};

export default PartnerUsersList;
