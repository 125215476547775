import moment from "moment";
import { FC, useMemo } from "react";
import { useHistory } from "react-router-dom";
import { NursesDatum } from "../../models/SchedulerExploreViewResponseModel";
import { getNursePatientsList } from "../../models/SchedulerExploreViewResponseModel";
import useCurrentUserRole from "../../hooks/useCurrentUserRole";

const DayViewTableCell: FC<DayViewTableCellProps> = ({ cellMoment, nurseData }) => {
  const key = `day-table-cell-${nurseData.nurseUUID}_${cellMoment.format()}`;
  const history = useHistory();
  const { canUpdate } = useCurrentUserRole();

  const disableClick = useMemo<boolean>(() => {
    return (
      cellMoment.isBefore(moment().startOf("day")) ||
      (nurseData.holidays?.includes(cellMoment.format("DD/MM/YYYY")) ?? false) ||
      !canUpdate
    );
  }, [canUpdate, cellMoment, nurseData.holidays]);

  const handleAssignNurseClicked = () => {
    if (disableClick) return;
    history.push(`/scheduler/assign-patients`, {
      initialSelectedDate: cellMoment.valueOf(),
      nurseUUID: nurseData.nurseUUID,
      patientsList: getNursePatientsList(nurseData),
      nurseData: nurseData,
    });
  };

  return <div key={key} className="cell empty-cell" id={key} onClick={disableClick ? undefined : handleAssignNurseClicked} />;
};

interface DayViewTableCellProps {
  cellMoment: moment.Moment;
  nurseData: NursesDatum;
}

export default DayViewTableCell;
