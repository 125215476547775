import moment from "moment";
import { useQuery } from "@tanstack/react-query";
import { BookingDateModel } from "../models/BookingModel";
import { checkIfNurseCanServeBooking } from "../services";

const useCheckIfNurseIsAvailableForBookingDates = ({
  endTime,
  isSingleDay,
  selectedDatesArray,
  selectedNurseUUID,
  startTime,
  editMode,
  momentUserClickOnFromCalendar,
  onlyCheckForBookings,
  enableQuery,
}: {
  selectedNurseUUID: string | undefined;
  selectedDatesArray: moment.Moment[];
  startTime: moment.Moment | undefined;
  endTime: moment.Moment | undefined;
  isSingleDay: boolean;
  editMode: boolean;
  momentUserClickOnFromCalendar: moment.Moment;
  onlyCheckForBookings: boolean;
  enableQuery: boolean;
}) => {
  const query = useQuery(
    [
      "orders/check-if-nurse-is-available-for-booking-dates",
      selectedNurseUUID,
      selectedDatesArray,
      startTime,
      endTime,
      isSingleDay,
      onlyCheckForBookings,
    ],
    () => {
      let orderDates: BookingDateModel[];
      if (isSingleDay || editMode) {
        const bookingStartMoment = moment(
          `${momentUserClickOnFromCalendar.format("DD/MM/YYYY")} ${startTime?.format("HH:mm")}`,
          "DD/MM/YYYY HH:mm"
        );
        const bookingEndMoment = moment(
          `${momentUserClickOnFromCalendar.format("DD/MM/YYYY")} ${endTime?.format("HH:mm")}`,
          "DD/MM/YYYY HH:mm"
        );
        orderDates = [
          {
            date: bookingStartMoment.format("DD/MM/YYYY"),
            time: `${bookingStartMoment.format("hh:mm")} - ${bookingStartMoment.clone().add(30, "minute").format("hh:mmA")}`,
            numberOfDurationOrSession: moment.duration(bookingEndMoment.diff(bookingStartMoment)).asHours(),
          },
        ];
      } else {
        orderDates = selectedDatesArray.map<BookingDateModel>((date) => {
          // const date = moment(dateValue);
          const bookingStartMoment = moment(`${date.format("DD/MM/YYYY")} ${startTime?.format("HH:mm")}`, "DD/MM/YYYY HH:mm");
          const bookingEndMoment = moment(`${date.format("DD/MM/YYYY")} ${endTime?.format("HH:mm")}`, "DD/MM/YYYY HH:mm");
          if (bookingEndMoment.isSameOrBefore(bookingStartMoment)) {
            bookingEndMoment.add(1, "day");
          }
          return {
            date: bookingStartMoment.format("DD/MM/YYYY"),
            time: `${bookingStartMoment.format("hh:mm")} - ${bookingStartMoment.clone().add(30, "minute").format("hh:mmA")}`,
            numberOfDurationOrSession: moment.duration(bookingEndMoment.diff(bookingStartMoment)).asHours(),
          };
        });
      }
      return checkIfNurseCanServeBooking({ nurseUUID: selectedNurseUUID ?? "", orderDates: orderDates, onlyCheckForBookings });
    },
    {
      enabled: enableQuery
        ? !!selectedNurseUUID && !!(selectedDatesArray.length || isSingleDay || editMode) && !!startTime && !!endTime
        : false,
    }
  );

  return query;
};

export default useCheckIfNurseIsAvailableForBookingDates;
