import { FC } from "react";
import { Grid } from "@mui/material";
import TopBarLeading from "./TopBarLeading";
import TopBarTabsMenu from "./TopBarTabsMenu";

const PharmacyTopBar: FC<PharmacyTopBarProps> = ({ onChange, value }) => {
  return (
    <>
      <div
        style={{
          width: "100%",
          // height: "80px",
          backgroundColor: "white",
          // borderBottom: "1px solid #edeae9",
          padding: "16px 24px",
          display: "flex",
          justifyContent: "space-between",
          // borderBottom: "1px solid #edeae9",
        }}
      >
        <Grid container={true}>
          <Grid item={true} md={8} xs={12}>
            <TopBarLeading />
          </Grid>
          {/* <Grid item={true} md={4} xs={12} sx={{ display: "flex", alignItems: "center", justifyContent: "flex-end" }}>
            <TopBarTrailing />
          </Grid> */}
        </Grid>
      </div>
      <TopBarTabsMenu value={value} onChange={onChange} />
    </>
  );
};

interface PharmacyTopBarProps {
  value: number;
  onChange: (v: number) => void;
}

export default PharmacyTopBar;
