import { Accordion, AccordionDetails, AccordionSummary, useTheme } from "@mui/material";
import { FC } from "react";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ViewPrescriptionButton from "./ViewPrescriptionButton";

const PrescriptionsView: FC<PrescriptionsViewProps> = ({ prescriptions }) => {
  const theme = useTheme();

  return (
    <Accordion
      sx={{
        boxShadow: "none",
        border: `1px solid ${theme.components?.MuiDivider?.defaultProps?.style?.borderColor}`,
        borderLeft: `1px solid ${theme.components?.MuiDivider?.defaultProps?.style?.borderColor}`,
        marginTop: "16px",
        "&.MuiPaper-root": {
          borderRadius: "6px",
          backgroundColor: "rgb(250, 250, 250)",
          borderLeft: `1px solid ${theme.components?.MuiDivider?.defaultProps?.style?.borderColor}`,
        },
        "&.MuiPaper-root::before": {
          display: "none",
        },
        "& .MuiAccordionSummary-root.Mui-expanded": {
          borderBottom: `1px solid ${theme.components?.MuiDivider?.defaultProps?.style?.borderColor}`,
          height: "48px",
          minHeight: "48px",
        },
        "& .MuiAccordionDetails-root": {
          padding: "16px",
        },
      }}
    >
      <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1-content" id="panel1-header">
        Prescriptions
      </AccordionSummary>
      <AccordionDetails>
        {prescriptions.map((url, i) => {
          // return (
          //   <a
          //     key={url}
          //     href={url}
          //     target="_blank"
          //     onClick={(e) => {
          //       e.preventDefault();
          //       window.open(url, "_blank");
          //     }}
          //   >
          //     {/* <Button variant="outlined">View prescription {i + 1}</Button> */}
          //   </a>
          // );
          return <ViewPrescriptionButton key={url} prescriptionURL={url} index={i} />;
        })}
      </AccordionDetails>
    </Accordion>
  );
};

interface PrescriptionsViewProps {
  prescriptions: string[];
}

export default PrescriptionsView;
