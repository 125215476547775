import { Menu, MenuProps } from "antd";
import { MenuClickEventHandler } from "rc-menu/lib/interface";
import { FC, useMemo } from "react";
import { FaUserNurse, FaCar } from "react-icons/fa";
import { IoIosWoman } from "react-icons/io";
import { TbPhysotherapist } from "react-icons/tb";
import { StaffCountsModel } from ".";
import { StaffMemberModel } from "../../models/StaffMemberModel";

const TabsMenu: FC<TabsMenuProps> = ({ selectedItemKey, staffCounts, teamMembers, onItemClicked }) => {
  // useEffect(() => {
  //   const selectedItemElement = document.getElementById(`team-menu-item-${selectedItemKey}`);
  //   if (!selectedItemElement) {
  //     console.log("--- no menu item element");
  //     return;
  //   }
  //   const selectedItemBar = document.getElementById("menu-selected-item-bar");
  //   const selectedItemRect = selectedItemElement.getBoundingClientRect();
  // }, [selectedItemKey]);

  const tabMenuItems = useMemo<MenuProps["items"]>(() => {
    return [
      {
        label: <TeamMenuItem count={teamMembers.length} itemKey="all" name="All" />,
        key: "all",
      },
      {
        label: (
          <TeamMenuItem
            count={staffCounts.certified}
            itemKey="caregivers"
            name="Caregivers"
            icon={<IoIosWoman style={{ fontSize: "22px" }} />}
          />
        ),
        key: "caregivers",
      },
      {
        label: (
          <TeamMenuItem
            count={staffCounts.registered}
            itemKey="nurses"
            name="Nurses"
            icon={<FaUserNurse style={{ fontSize: "16px" }} />}
          />
        ),
        key: "nurses",
      },
      {
        label: (
          <TeamMenuItem
            count={staffCounts.therapists}
            itemKey="therapists"
            name="Therapists"
            icon={<TbPhysotherapist style={{ fontSize: "22px" }} />}
          />
        ),
        key: "therapists",
      },
      {
        label: (
          <TeamMenuItem
            count={staffCounts.drivers}
            itemKey="drivers"
            name="Drivers"
            icon={<FaCar style={{ fontSize: "18px" }} />}
          />
        ),
        key: "drivers",
      },
    ];
  }, [staffCounts.certified, staffCounts.drivers, staffCounts.registered, staffCounts.therapists, teamMembers.length]);

  return (
    <div className="menu-container" style={{ position: "relative" }}>
      <div id="menu-selected-item-bar" className="menu-selected-item-bar" style={{}} />
      <Menu onClick={onItemClicked} selectedKeys={[selectedItemKey]} mode="horizontal" items={tabMenuItems} />
    </div>
  );
};

const TeamMenuItem: FC<{ itemKey: string; name: string; count: number; icon?: JSX.Element }> = ({
  itemKey,
  name,
  count,
  icon,
}) => {
  return (
    <div
      id={`team-menu-item-${itemKey}`}
      key={itemKey}
      style={{ display: "flex", alignItems: "center", gap: "8px" }}
      className="team-menu-item"
    >
      {icon && <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>{icon}</div>}
      <div>{name}</div>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          width: menuCountCircleSize,
          height: menuCountCircleSize,
          minHeight: menuCountCircleSize,
          minWidth: menuCountCircleSize,
          backgroundColor: "#f2f2f2",
          color: "#838383",
          borderRadius: "5px",
        }}
      >
        {count}
      </div>
    </div>
  );
};

const menuCountCircleSize = "20px";

interface TabsMenuProps {
  staffCounts: StaffCountsModel;
  teamMembers: StaffMemberModel[];
  selectedItemKey: string;
  onItemClicked: MenuClickEventHandler; // (info: MenuInfo) => {};
}

export default TabsMenu;
