import { useCallback, useEffect, useMemo, useState } from "react";
import { showMessage } from "../../components/common/notification";
import { ConfirmationResult, signInWithCustomToken } from "firebase/auth";
import { SignUpFormType } from "./SignUpForm";
import { useMutation } from "@tanstack/react-query";
import {
  authService,
  checkUniquePartnerUserData,
  createPartnerUser,
  getFirebaseCustomTokenForPartnerDashboard,
  sendWhatsappOTP,
  verifyWhatsappOTP,
} from "../../services";
import { useHistory, useParams } from "react-router-dom";
import useSendMobileNumberOTPWithFirebase from "../../hooks/useSendMobileNumberOTPWithFirebase";
import useVerifyMobileNumberOTPWithFirebase from "../../hooks/useVerifyMobileNumberOTPWithFirebase";
import decrypt from "../../services/decrypt";
import useHandleUserAuthSuccess from "../../hooks/useHandleUserAuthSuccess";
import usePartnerUserInvite from "../../hooks/usePartnerUserInvite";
import useLogout from "../../hooks/useLogout";
import { PartnerUserRoleType } from "../../models/PartnerUserModel";
import useFireAuth from "../../hooks/useFireAuth";

export type PartnerUserURLData = { name?: string; role: PartnerUserRoleType; email: string; partnerUUID: string };
export type FA2LoginFormType = { mobileNumber?: string };

function useUserSignUpLink({
  firebaseConfirmationResult,
  mobileNumber,
  mode,
}: {
  firebaseConfirmationResult?: ConfirmationResult;
  mobileNumber?: string;
  mode: "SIGNUP" | "LOGIN";
}) {
  const history = useHistory();
  const params = useParams<{ userData: string }>();
  const [resendOTPLoading, setResendOTPLoading] = useState(false);
  const [formValues, setFormValues] = useState<SignUpFormType>();
  const [sendFireAuthOTPConfirmationResult, setSendFireAuthOTPConfirmationResult] = useState<ConfirmationResult>();
  const [vonageVerifyRequestID, setVonageVerifyRequestID] = useState<string>();

  const userLinkData = useMemo<PartnerUserURLData | null>(() => {
    try {
      return decrypt(decodeURIComponent(params.userData));
    } catch (e) {
      return null;
    }
  }, [params.userData]);

  const { query: inviteQuery } = usePartnerUserInvite({ email: userLinkData?.email });

  const { logout } = useLogout({ pushToLogin: false });

  useEffect(() => {
    if (mode === "SIGNUP" && userLinkData?.email && inviteQuery.data?.success && !inviteQuery.data?.invite?._id) {
      console.log("sign up link no invite");
      showMessage("error", "Invalid sign up link");
      history.replace("/login");
    }
    //  else if (mode === "SIGNUP" && userLinkData?.email && inviteQuery.data?.success && inviteQuery.data?.invite?._id) {
    //   logout();
    // }
  }, [history, inviteQuery.data?.invite?._id, inviteQuery.data?.success, logout, mode, userLinkData?.email]);

  useEffect(() => {
    if (!userLinkData?.email && mode === "SIGNUP") {
      console.log("sign up invalid");
      showMessage("error", "Invalid sign up link");
      history.replace("/login");
    }
  }, [history, mode, userLinkData?.email]);

  const { loading: sendOTPLoading, sendPhoneOTP } = useSendMobileNumberOTPWithFirebase({
    recaptchaContainerID: "login-form-container",
    recaptchaElementID: "login-captcha",
  });

  const { loading: confirmFirebaseOTPLoading, verifyOTP: verifyOTPFirebaseOTP } = useVerifyMobileNumberOTPWithFirebase({
    confirmationResult: firebaseConfirmationResult ?? sendFireAuthOTPConfirmationResult,
  });

  const checkUniqueUserMutation = useMutation({ mutationFn: checkUniquePartnerUserData });

  const handleFormSubmit = useCallback(
    async (form: SignUpFormType) => {
      setFormValues(form);
      const phoneNumber = form.mobileNumber.startsWith("0") ? form.mobileNumber.slice(1) : form.mobileNumber;
      const fullPhoneNumber = `+971${phoneNumber.replaceAll(" ", "")}`;
      const res = await checkUniqueUserMutation.mutateAsync({ email: form.email, mobileNumber: fullPhoneNumber });
      if (res?.error) {
        return showMessage("error", res.error ?? "Something went wrong");
      }
      const confirmationResult = await sendPhoneOTP(fullPhoneNumber);
      if (typeof confirmationResult === "string") {
        return showMessage("error", confirmationResult);
      }
      setSendFireAuthOTPConfirmationResult(confirmationResult);
    },
    [checkUniqueUserMutation, sendPhoneOTP]
  );

  const createUserMutation = useMutation({ mutationFn: createPartnerUser });
  const loginPartnerUser = useMutation({ mutationFn: authService.userLoginWithPhoneNumber });
  // const verifyVonageCodeMutation = useMutation({ mutationFn: verifyVonageCode });
  const firebaseCustomTokenMutation = useMutation({ mutationFn: getFirebaseCustomTokenForPartnerDashboard });
  // const { handleUserAuthSuccess } = useUserAuthSuccess();
  const { handleUserAuthSuccess } = useHandleUserAuthSuccess();

  const verifyWhatsappOTPMutation = useMutation({ mutationFn: verifyWhatsappOTP });

  const { fireAuth } = useFireAuth();

  const handleUserAuthentication = useCallback(
    async ({ otp, type }: { otp: string; type: "LOGIN" | "SIGNUP" }) => {
      if (!fireAuth) {
        console.log("error no fire auth");
        return showMessage("error", "Something went wrong");
      }
      const phoneNumber = formValues?.mobileNumber.startsWith("0") ? formValues?.mobileNumber.slice(1) : formValues?.mobileNumber;
      const fullMobileNumber = mobileNumber ?? `+971${phoneNumber}`;
      const promesisResults = await Promise.all([
        verifyOTPFirebaseOTP(otp),
        verifyWhatsappOTPMutation.mutateAsync({ orderID: vonageVerifyRequestID ?? "", otp: otp, phoneNumber: fullMobileNumber }),
      ]);
      const userCredentials = promesisResults[0];
      const vonageVerifyCodeResponse = promesisResults[1];
      const vonageVerifySuccess = vonageVerifyCodeResponse?.isOTPVerified ?? false;
      if (typeof userCredentials === "string" && !vonageVerifySuccess) {
        return showMessage("error", userCredentials);
      }
      let customTokenUserID = "";
      if (typeof userCredentials === "string" && vonageVerifySuccess) {
        const tokenRes = await firebaseCustomTokenMutation.mutateAsync({});
        if (!tokenRes?.token) {
          console.log("--- get custom token error");
          return showMessage("error", "Something went wrong");
        }
        const customTokenCredentials = await signInWithCustomToken(fireAuth, tokenRes?.token ?? "");
        if (typeof customTokenCredentials !== "string") {
          customTokenUserID = customTokenCredentials.user.uid;
        } else {
          console.log("--- signInWithCustomToken error");
          return showMessage("error", customTokenCredentials ?? "Something went wrong");
        }
      }
      if (type === "SIGNUP") {
        const createUserResponse = await createUserMutation.mutateAsync({
          email: formValues?.email ?? "",
          firebaseUID: typeof userCredentials === "string" ? customTokenUserID : userCredentials.user.uid,
          name: formValues?.name ?? "",
          partnerUUID: userLinkData?.partnerUUID ?? "",
          phoneNumber: fullMobileNumber,
          role: userLinkData?.role ?? "",
        });
        if (!createUserResponse?.user?.phoneNumber) {
          return showMessage("error", "Something went wrong");
        }
      }
      const loginRes = await loginPartnerUser.mutateAsync({ phoneNumber: fullMobileNumber });
      localStorage.setItem("newAuthLogic", "true");
      handleUserAuthSuccess(loginRes.data.settings);
      if (type === "SIGNUP" && userLinkData?.role === "SUPER_ADMIN") {
        history.replace("/settings");
      } else {
        history.replace("/");
      }
    },
    [
      createUserMutation,
      fireAuth,
      firebaseCustomTokenMutation,
      formValues?.email,
      formValues?.mobileNumber,
      formValues?.name,
      handleUserAuthSuccess,
      history,
      loginPartnerUser,
      mobileNumber,
      userLinkData?.partnerUUID,
      userLinkData?.role,
      verifyOTPFirebaseOTP,
      verifyWhatsappOTPMutation,
      vonageVerifyRequestID,
    ]
  );

  const handleLoginOTPEntered = useCallback(
    async ({ otp, mobileNumber }: { otp: string; mobileNumber: string }) => {
      const checkUniqueUserRes = await checkUniqueUserMutation.mutateAsync({ mobileNumber: mobileNumber });
      if (!checkUniqueUserRes?.user?.phoneNumber) {
        return showMessage("error", "Invalid mobile number");
      }
      handleUserAuthentication({ otp, type: "LOGIN" });
    },
    [checkUniqueUserMutation, handleUserAuthentication]
  );

  const handleSignupOTPEntered = useCallback(
    async ({ otp }: { otp: string }) => {
      const phoneNumber = formValues?.mobileNumber.startsWith("0") ? formValues?.mobileNumber.slice(1) : formValues?.mobileNumber;
      const fullPhoneNumber = `+971${phoneNumber?.replaceAll(" ", "")}`;
      const checkUniqueUserRes = await checkUniqueUserMutation.mutateAsync({
        email: formValues?.email ?? "",
        mobileNumber: fullPhoneNumber,
      });
      if (checkUniqueUserRes?.error) {
        return showMessage("error", checkUniqueUserRes.error ?? "Something went wrong");
      }
      handleUserAuthentication({ otp, type: "SIGNUP" });
    },
    [checkUniqueUserMutation, formValues?.email, formValues?.mobileNumber, handleUserAuthentication]
  );

  const handleResendOTPClicked = useCallback(async () => {
    setResendOTPLoading(true);
    const phoneNumber = formValues?.mobileNumber.startsWith("0") ? formValues.mobileNumber.slice(1) : formValues?.mobileNumber;
    const confirmationResult = await sendPhoneOTP(mobileNumber ?? `+971${phoneNumber?.replaceAll(" ", "") ?? ""}`);
    setResendOTPLoading(false);
    if (typeof confirmationResult === "string") {
      return showMessage("error", confirmationResult);
    }
    setSendFireAuthOTPConfirmationResult(confirmationResult);
    showMessage("success", mobileNumber ? `OTP has been sent to ${mobileNumber}` : `OTP has been sent to +971${phoneNumber}`);
  }, [formValues?.mobileNumber, mobileNumber, sendPhoneOTP]);

  const sendWhatsappOTPMutation = useMutation({ mutationFn: sendWhatsappOTP });

  const handleRequestCallClicked = useCallback(async () => {
    const phoneNumber = formValues?.mobileNumber.startsWith("0") ? formValues?.mobileNumber.slice(1) : formValues?.mobileNumber;
    const fullMobileNumber = mobileNumber ?? `+971${phoneNumber}`;
    const res = await sendWhatsappOTPMutation.mutateAsync({ phoneNumber: fullMobileNumber });
    if (res?.orderID) {
      setVonageVerifyRequestID(res.orderID);
      showMessage("success", `OTP has been sent on Whatsapp ${fullMobileNumber}`);
    } else {
      showMessage("error", "Something went wrong");
    }
  }, [formValues?.mobileNumber, mobileNumber, sendWhatsappOTPMutation]);

  return {
    sendFireAuthOTPConfirmationResult,
    checkUniqueUserMutation,
    sendOTPLoading,
    resendOTPLoading,
    formValues,
    confirmFirebaseOTPLoading,
    sendWhatsappOTPMutation,
    verifyWhatsappOTPMutation,
    firebaseCustomTokenMutation,
    loginPartnerUser,
    handleLoginOTPEntered,
    handleRequestCallClicked,
    handleResendOTPClicked,
    handleSignupOTPEntered,
    handleFormSubmit,
  };
}

export default useUserSignUpLink;
