import { Col, DatePicker, Form, Input, Row, Select } from "antd";
import moment from "moment";
import { FC } from "react";
import { nationalities } from "../../components/common/contry.data";
import { listOfLanguages } from "../../components/common/languages";

const PersonalDetailsTab: FC = () => {
  const yearsAgo18 = moment().subtract(18, "years");

  return (
    <Row style={{ width: "100%" }}>
      <Col span={24}>
        <Row
          style={{
            rowGap: "1rem",
            flexWrap: "wrap",
            justifyContent: "space-between",
            alignItems: "flex-start",
          }}
        >
          <Col xs={24} sm={11} md={11} lg={11}>
            <Form.Item
              name="firstName"
              label="First name"
              rules={[
                { required: true, message: "Please enter the first name" },
              ]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col xs={24} sm={11} md={11} lg={11}>
            <Form.Item
              name="lastName"
              label="Last name"
              rules={[
                { required: true, message: "Please enter the last name" },
              ]}
            >
              <Input />
            </Form.Item>
          </Col>
        </Row>
      </Col>
      <Col span={24}>
        <Row
          style={{
            rowGap: "1rem",
            flexWrap: "wrap",
            justifyContent: "space-between",
            alignItems: "flex-start",
          }}
        >
          <Col xs={24} sm={11} md={11} lg={11}>
            <Form.Item
              name="dob"
              label="Date of birth"
              rules={[
                { required: true, message: "Please enter the date of birth" },
              ]}
            >
              <DatePicker
                style={{ width: "100%" }}
                format="DD/MM/YYYY"
                disabledDate={(date) => {
                  return date.isAfter(yearsAgo18);
                }}
              />
            </Form.Item>
          </Col>
          <Col xs={24} sm={11} md={11} lg={11}>
            <Form.Item
              name="sex"
              label="Gender"
              rules={[
                { required: true, message: "Please select the nurse gender" },
              ]}
            >
              <Select>
                <Select.Option value="male">Male</Select.Option>
                <Select.Option value="female">Female</Select.Option>
              </Select>
            </Form.Item>
          </Col>
        </Row>
      </Col>
      <Col span={24}>
        <Row
          style={{
            rowGap: "1rem",
            flexWrap: "wrap",
            justifyContent: "space-between",
            alignItems: "flex-start",
          }}
        >
          <Col xs={24} sm={11} md={11} lg={11}>
            <Form.Item
              label="Email"
              name="email"
              rules={[{ required: true, message: "Please enter the email" }]}
              // style={{ height: "fit-content" }}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col xs={24} sm={11} md={11} lg={11}>
            <Form.Item
              label="Mobile No."
              name="mobileNumber"
              rules={[
                { required: true, message: "Please enter the mobile number" },
              ]}
              // style={{ height: "fit-content" }}
            >
              <Input.Group
                compact={true}
                className="phone-number-input-group"
                style={{ display: "flex" }}
              >
                <Form.Item
                  style={{ flex: "2" }}
                  name={["mobileNumber", "countryCode"]}
                  rules={[
                    {
                      required: true,
                      message: "Please select phone country code",
                    },
                  ]}
                >
                  <Select
                    style={{ minWidth: "70px" }}
                    options={[{ value: "+971", label: "+971" }]}
                  />
                </Form.Item>
                <Form.Item
                  style={{ flex: "7" }}
                  name={["mobileNumber", "number"]}
                  rules={[
                    {
                      required: true,
                      message: "Please enter the mobile number",
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
              </Input.Group>
            </Form.Item>
          </Col>
        </Row>
      </Col>
      <Col span={24}>
        <Row
          style={{
            rowGap: "1rem",
            flexWrap: "wrap",
            justifyContent: "space-between",
            alignItems: "flex-start",
          }}
        >
          <Col xs={24} sm={11} md={11} lg={11}>
            <Form.Item
              label="Country"
              name="nationality"
              rules={[
                {
                  required: true,
                  message: "Please select the country",
                },
              ]}
            >
              <Select
                showSearch={true}
                filterOption={(input, option) => {
                  return (
                    option?.label.toLowerCase().includes(input.toLowerCase()) ??
                    false
                  );
                }}
                style={{ width: "100%" }}
                options={nationalities.map((country) => {
                  return {
                    value: country.toLowerCase(),
                    label: country,
                  };
                })}
              />
            </Form.Item>
          </Col>
          <Col xs={24} sm={11} md={11} lg={11}>
            <Form.Item
              label="Languages"
              name="languages"
              rules={[
                {
                  required: true,
                  message: "Please select the languages",
                },
              ]}
            >
              <Select
                mode="multiple"
                showSearch={true}
                filterOption={(input, option) => {
                  return (
                    option?.label.toLowerCase().includes(input.toLowerCase()) ??
                    false
                  );
                }}
                style={{ width: "100%" }}
                options={listOfLanguages.map((language) => {
                  return {
                    value: language.toLowerCase(),
                    label: language,
                  };
                })}
              />
            </Form.Item>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export default PersonalDetailsTab;
