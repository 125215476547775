import { Divider } from "antd";
import { FC, useMemo } from "react";
import { BookingCustomizationModel } from "../../../models/BookingModel";

const TableCustomizationsList: FC<{ customizations: BookingCustomizationModel[] }> = ({ customizations }) => {
  const customizationsToShow = useMemo(() => {
    return customizations.filter((customization) => {
      return customization.engName !== "Lab Results Consultation";
    });
  }, [customizations]);

  if (!customizationsToShow?.length) return null;

  return (
    <div
      style={{
        border: "1px solid #ccc",
        borderRadius: "6px",
        padding: "8px",
        display: "flex",
        flexDirection: "column",
        width: "fit-content",
        minWidth: "200px",
      }}
    >
      <div>With Addons</div>
      <Divider style={{ margin: "8px 0px" }} />
      <ul style={{ paddingLeft: "16px" }}>
        {customizationsToShow?.map((item, index) => {
          return (
            <li key={index} style={{ color: "rgb(0, 134, 144)" }}>
              <strong>{item.engName}</strong>
            </li>
          );
        })}
      </ul>
    </div>
  );
};

export default TableCustomizationsList;
