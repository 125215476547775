import { Button, Modal } from "@mui/material";
import { FC, useMemo } from "react";
import { StaffMemberModel } from "../../models/StaffMemberModel";
import { NurseOccupiedDatesData } from "../../models/NurseOccupiedDatesResponseModel";
import { Col, Row } from "antd";

const AssignNurseConfirmationModal: FC<AssignNurseConfirmationModalProps> = ({
  nurse,
  open,
  assignNurseOccupiedDates = {},
  onAssignNurseClicked,
  onClose,
}) => {
  const bookingIDs = useMemo(() => {
    const addedIDs = new Map();
    const res = [];
    for (const key of Object.keys(assignNurseOccupiedDates ?? {})) {
      for (const id of assignNurseOccupiedDates[key] ?? []) {
        if (!addedIDs.get(id)) {
          addedIDs.set(id, true);
          res.push(id);
        }
      }
    }
    return res;
  }, [assignNurseOccupiedDates]);

  return (
    <Modal
      className="assign-nurse-confirmation-modal"
      open={open}
      onClose={onClose}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
      style={{
        display: "flex",
        justifyContent: "center",
        top: "5rem",
      }}
    >
      <Row
        style={{
          margin: 0,
          gap: "1rem",
          backgroundColor: "white",
          border: "none",
          borderRadius: "1rem",
          padding: "2rem",
          width: `100%`,
          maxWidth: "500px",
          marginLeft: "1rem",
          marginRight: "1rem",
          height: "max-content",
          maxHeight: "70vh",
          overflowY: "scroll",
        }}
      >
        <Col span={24}>
          <h5
            style={{
              textAlign: "left",
              marginBottom: "0px",
            }}
          >
            Nurse {nurse?.firstName} {nurse?.lastName ?? ""} is already occupied
            with the following bookings
          </h5>
        </Col>
        <div
          style={{
            color: "#008690",
            fontWeight: "bold",
            textAlign: "center",
          }}
        >
          {bookingIDs.join(" - ")}
        </div>
        <h5
          style={{
            textAlign: "center",
            marginBottom: "0px",
          }}
        >
          in the following dates
        </h5>
        <div
          style={{
            color: "#008690",
            fontWeight: "bold",
            textAlign: "center",
          }}
        >
          {Object.keys(assignNurseOccupiedDates ?? {})
            .filter(
              (key) =>
                assignNurseOccupiedDates[key] &&
                assignNurseOccupiedDates[key]?.length
            )
            .join(" - ")}
        </div>
        <h5
          style={{
            textAlign: "center",
            marginBottom: "0px",
          }}
        >
          Are you sure you want to assign ?
        </h5>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            width: "100%",
          }}
        >
          <Button
            style={{ width: "120px", borderRadius: "2rem" }}
            onClick={onClose}
            color="secondary"
            variant="outlined"
          >
            Cancel
          </Button>
          <Button
            style={{
              width: "160px",
              marginRight: "1rem",
              borderRadius: "2rem",
              color: "#fff",
            }}
            variant="contained"
            color="primary"
            onClick={() => {
              if (onAssignNurseClicked) {
                onAssignNurseClicked();
              }
              if (onClose) {
                onClose();
              }
            }}
          >
            Yes, Assign
          </Button>
        </div>
      </Row>
    </Modal>
  );
};

interface AssignNurseConfirmationModalProps {
  open: boolean;
  nurse?: StaffMemberModel;
  assignNurseOccupiedDates?: NurseOccupiedDatesData;
  onClose: () => void;
  onAssignNurseClicked: () => void;
}

export default AssignNurseConfirmationModal;
