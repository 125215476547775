import axios from "axios";
import decrypt from "./decrypt";
import encrypt from "./encrypt";
import Mixpanel from "../utils/Mixpanel";

export const assignedServices = {
  getOrders,
  nurseToOrder,
};

async function getOrders(partnerUUID, pageNumber) {
  const data = await axios.get(
    `/api/v1/orders/ordersforpartner/${partnerUUID}/${pageNumber}/PROCESSING` //
  );
  const decryptedData = decrypt(data?.data);
  return { ...data, data: decryptedData };
}

export function makeSureNoTwoNursesHaveSameShiftInAssignNurseArray(nurse) {
  if (!nurse?.assignedNurses?.length) return;
  const assignendNurseArray = (nurse?.assignedNurses ?? []).reverse();
  const seenShifts = new Map();
  for (const nurse of assignendNurseArray) {
    if (!nurse?.dateArray?.length) continue;
    nurse.dateArray = nurse.dateArray.filter((shift) => {
      const patients = [...(nurse.patients ?? [])].sort().join(",");
      const shiftKey = `${shift.date}-${shift.time}-${patients}`;
      if (seenShifts.get(shiftKey)) return false;
      seenShifts.set(shiftKey, true);
      return true;
    });
  }
  nurse.assignedNurses = assignendNurseArray.reverse();
}

function validateAssignNurseRequestData(data) {
  if (typeof data !== "object") {
    return false;
  }
  if (!data.orderUUID) {
    return false;
  }
  if (!data?.assignedNurses?.length) {
    return false;
  }
  for (const nurse of data?.assignedNurses ?? []) {
    if (!nurse) {
      return false;
    }
    if (!nurse.nurseUUID) {
      return false;
    } else if (!nurse.patients?.length) {
      return false;
    } else if (!nurse.dateArray?.length) {
      return false;
    }
    for (const dateObj of nurse.dateArray) {
      if (!dateObj) {
        return false;
      }
      if (!dateObj.duration) {
        return false;
      }
      if (!dateObj.date) {
        return false;
      }
      if (!dateObj.time) {
        return false;
      }
      if (typeof dateObj.duration !== "number") {
        return false;
      }
      const splittedDate = dateObj.date.split("/");
      if (splittedDate.length !== 3) {
        return false;
      }
      if (splittedDate.some((value) => isNaN(+value))) {
        return false;
      }
      const splittedTime = dateObj.time.split(" - ");
      if (splittedTime.length !== 2) {
        return false;
      }
      for (const value of splittedTime) {
        if (value.split(":").length !== 2) {
          return false;
        }
      }
    }
  }
  return true;
}

async function nurseToOrder(nurse) {
  try {
    const validData = validateAssignNurseRequestData(nurse);
    if (!validData) {
      Mixpanel.track("Assign nurse error", {
        ...(nurse ?? {}),
        settings: localStorage.getItem("setting"),
      });
      return { data: { success: 0 } };
    }
    const data = await axios.post("/api/v1/orders/assignnurses", {
      data: encrypt(nurse),
    });
    const decryptedData = decrypt(data?.data);
    return { ...data, data: decryptedData };
  } catch (error) {
    console.log("--- nurse to order error ---", error);
    return { data: { success: 0 } };
  }
}
