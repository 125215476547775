import { searchType } from "../types";

export const initialState = {
  filter: "MOBILE_NUMBER",
  text: "",
  loader: false,
  searchActive: false,
  searchData: [],
  page: "",
  pageNumber: 1,
};

export function searchReducer(state = initialState, action) {
  switch (action.type) {
    case searchType.SET_SEARCH_BODY:
      return {
        ...state,
        filter: action.filter,
        text: action.text,
        page: action.page,
        searchActive: action.searchActive,
        pageNumber: action.pageNumber,
      };
    case searchType.REQUEST_SEARCH:
      return {
        ...state,
        loader: true,
      };
    case searchType.REQUEST_SEARCH_DONE:
      return {
        ...state,
        loader: false,
      };
    case searchType.SET_SEARCH:
      localStorage.setItem(
        "searchPartner",
        JSON.stringify({
          filter: action.filter,
          text: action.text,
          page: action.page,
          searchActive: true,
          pageNumber: 1,
        })
      );
      return {
        ...state,
        filter: action.filter,
        text: action.text,
        loader: true,
        page: action.page,
        searchActive: true,
        searchData: [],
      };
    case searchType.SEARCH_DATA:
      return {
        ...state,
        searchData: action.data,
        loader: false,
      };
    case searchType.CLEAR_SEARCH:
      localStorage.setItem(
        "searchPartner",
        JSON.stringify({
          searchActive: false,
        })
      );
      return {
        filter: "MOBILE_NUMBER",
        text: "",
        loader: false,
        searchActive: false,
        searchData: [],
        page: "",
        pageNumber: 1,
      };
    case searchType.SEARCH_PAGE_NUMBER_INCREMENT:
      localStorage.setItem(
        "searchPartner",
        JSON.stringify({
          filter: state.filter,
          text: state.text,
          searchActive: state.searchActive,
          page: state.page,
          pageNumber: action.data,
        })
      );
      return {
        ...state,
        pageNumber: action.data,
      };
    case searchType.SEARCH_PAGE_NUMBER_DECREMENT:
      localStorage.setItem(
        "searchPartner",
        JSON.stringify({
          filter: state.filter,
          text: state.text,
          searchActive: state.searchActive,
          page: state.page,
          pageNumber: action.data,
        })
      );
      return {
        ...state,
        pageNumber: action.data,
      };
    default: {
      return {
        ...state,
      };
    }
  }
}
