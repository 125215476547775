import React from "react";
import { BookingPatientModel } from "../../../models/BookingModel";

const AssignNurseModalPatientCard: React.FC<
  AssignNurseModalPatientCardProps
> = ({ patient }) => {
  return (
    <div
      style={{
        boxShadow:
          "rgb(0 0 0 / 10%) 0px 2px 5px 0px, rgb(0 0 0 / 12%) 0px 4px 6px 0px",
        borderRadius: "10px",
        backgroundColor: "white",
        padding: "0.6rem 0.6rem",
        display: "flex",
        flexDirection: "column",
        gap: "0.5rem",
        width: "100%",
        minWidth: "100%",
      }}
    >
      <div
        style={{
          color: "gray",
          fontWeight: "600",
        }}
      >
        {patient.name}
      </div>
    </div>
  );
};

interface AssignNurseModalPatientCardProps {
  patient: BookingPatientModel;
}

export default AssignNurseModalPatientCard;
