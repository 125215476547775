import { FC, useMemo } from "react";
import MultipleSelectDropdown, { MultipleSelectDropdownItem } from "./MultipleSelectDropdown";
import { useSelector } from "react-redux";
import ReduxStateModel from "../models/ReduxStateModel";
import ProductModel from "../models/ProductModel";

const ServiceMultipleSelectDropdown: FC<ServiceMultipleSelectDropdownProps> = ({
  onSelectedProductsChange,
  selectedProducts,
}) => {
  const partnerSettings = useSelector((state: ReduxStateModel) => state.settingReducer?.manageDateTime);

  const value = useMemo<MultipleSelectDropdownItem[]>(() => {
    return selectedProducts.map<MultipleSelectDropdownItem>((prod) => {
      return {
        id: prod.productUUID ?? "",
        label: prod.engProductName ?? "",
      };
    });
  }, [selectedProducts]);

  return (
    <MultipleSelectDropdown
      value={value}
      options={
        partnerSettings?.services?.map<MultipleSelectDropdownItem>((prod) => {
          return {
            id: prod.productUUID ?? "",
            label: prod.engProductName ?? "",
          };
        }) ?? []
      }
      label={"Filter By Service"}
      onChange={function (values: MultipleSelectDropdownItem[]): void {
        const selectedIDs = values.map<string>((value) => value.id);
        onSelectedProductsChange(
          partnerSettings?.services?.filter((prod) => {
            return selectedIDs.includes(prod.productUUID ?? "");
          }) ?? []
        );
      }}
    />
  );
};

interface ServiceMultipleSelectDropdownProps {
  selectedProducts: ProductModel[];
  onSelectedProductsChange: (value: ProductModel[]) => void;
}

export default ServiceMultipleSelectDropdown;
