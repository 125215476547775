import axios from "axios";
import decrypt from "./decrypt";
import encrypt from "./encrypt";
import Mixpanel from "../utils/Mixpanel";
import { GetOrderDetailsResponseModel } from "./orderServices";

export const orderServices = {
  ordersCount,
  orderResult,
  getIndividualOrder,
};

async function ordersCount(partnerUUID: string) {
  const data = await axios.get(`/api/v1/orders/count/${partnerUUID}`);
  // console.log("--- decrypt(data?.data)", decrypt(data?.data));
  return { ...data, data: decrypt(data?.data) };
}

async function orderResult(updateObject: object) {
  Mixpanel.track("Mark booking as reported", {
    partner: { userName: localStorage.getItem("userName"), partnerUserUUID: localStorage.getItem("partnerUserUUID") },
    updateObject: updateObject,
  });
  const data = await axios.post("/api/v1/orders/report", {
    data: encrypt(updateObject),
  });
  const decryptedData = decrypt(data?.data);
  return { ...data, data: decryptedData };
}

async function getIndividualOrder(orderUUID: string) {
  const data = await axios.get(`/api/v1/orders/getparticularpartnerorder/${orderUUID}`);
  const res: GetOrderDetailsResponseModel = decrypt(data?.data);
  return { ...data, data: res };
}
