import { useCallback, useMemo, useState } from "react";
import { showMessage } from "../../components/common/notification";
import { useMutation } from "@tanstack/react-query";
import { checkUniquePartnerUserData } from "../../services";
import { ConfirmationResult } from "firebase/auth";
import useSendMobileNumberOTPWithFirebase from "../../hooks/useSendMobileNumberOTPWithFirebase";
import useUserSignUpLink, { FA2LoginFormType } from "../UserSignUpLink/useUserSignUpLink";

function useFA2Login() {
  const [sendFireAuthOTPConfirmationResult, setSendFireAuthOTPConfirmationResult] = useState<ConfirmationResult>();
  const [formValues, setFormValues] = useState<FA2LoginFormType>();

  const fullPhoneNumber = useMemo(() => {
    const phoneNumber = formValues?.mobileNumber?.startsWith("0") ? formValues?.mobileNumber?.slice(1) : formValues?.mobileNumber;
    return `+971${phoneNumber?.replaceAll(" ", "")}`;
  }, [formValues?.mobileNumber]);

  const checkUniqueUserMutation = useMutation({ mutationFn: checkUniquePartnerUserData });

  const { loading: sendOTPLoading, sendPhoneOTP } = useSendMobileNumberOTPWithFirebase({
    recaptchaContainerID: "login-form-container",
    recaptchaElementID: "login-captcha",
  });

  const handlePhoneNumberEntered = useCallback(
    async (form: FA2LoginFormType) => {
      setFormValues(form);
      const phoneNumber = form?.mobileNumber?.startsWith("0") ? form?.mobileNumber?.slice(1) : form?.mobileNumber;
      const fullPhoneNumber = `+971${phoneNumber?.replaceAll(" ", "")}`;
      const res = await checkUniqueUserMutation.mutateAsync({ mobileNumber: fullPhoneNumber });
      if (!res?.user?.partnerUserUUID) {
        return showMessage("error", "Invalid phone number");
      }
      const confirmationResult = await sendPhoneOTP(fullPhoneNumber);
      if (typeof confirmationResult === "string") {
        return showMessage("error", confirmationResult);
      }
      setSendFireAuthOTPConfirmationResult(confirmationResult);
    },
    [checkUniqueUserMutation, sendPhoneOTP]
  );

  const {
    resendOTPLoading,
    confirmFirebaseOTPLoading,
    sendWhatsappOTPMutation,
    verifyWhatsappOTPMutation,
    firebaseCustomTokenMutation,
    loginPartnerUser,
    handleRequestCallClicked,
    handleResendOTPClicked,
    handleLoginOTPEntered,
  } = useUserSignUpLink({
    firebaseConfirmationResult: sendFireAuthOTPConfirmationResult,
    mobileNumber: fullPhoneNumber,
    mode: "LOGIN",
  });

  const onOTPEntered = useCallback(
    ({ otp }: { otp: string }) => {
      handleLoginOTPEntered({ otp: otp, mobileNumber: fullPhoneNumber });
    },
    [fullPhoneNumber, handleLoginOTPEntered]
  );

  return {
    sendOTPLoading,
    sendFireAuthOTPConfirmationResult,
    checkUniqueUserMutation,
    resendOTPLoading,
    formValues,
    confirmFirebaseOTPLoading,
    sendWhatsappOTPMutation,
    verifyWhatsappOTPMutation,
    firebaseCustomTokenMutation,
    loginPartnerUser,
    onOTPEntered,
    handlePhoneNumberEntered,
    handleRequestCallClicked,
    handleResendOTPClicked,
  };
}

export default useFA2Login;
