import { Col, Row } from "antd";
import { FC } from "react";

const PatientInfoItem: FC<PatientInfoItemProps> = ({ name, value, marginBottom, valueBold }) => {
  if (!value) return null;
  return (
    <Row
      style={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "flex-start",
        color: "grey",
        marginBottom: marginBottom ?? "1rem",
        lineBreak: "anywhere",
      }}
    >
      <Col xs={24} sm={9}>
        <p style={{ marginBottom: "16px" }}>{name}:</p>
      </Col>
      <Col
        xs={24}
        sm={15}
        style={{
          color: "black",
          textTransform: ["User's Email", "E-mail"].includes(name) ? undefined : "capitalize",
          fontWeight: valueBold ? "bold" : "normal",
        }}
      >
        <p style={{ margin: 0 }}>{value}</p>
      </Col>
    </Row>
  );
};

interface PatientInfoItemProps {
  name: string;
  valueBold?: boolean;
  marginBottom?: string;
  value?: React.ReactNode;
}

export default PatientInfoItem;
