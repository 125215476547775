import { useTheme } from "@mui/material";
import { grey } from "@mui/material/colors";
import { FC } from "react";
import { UserAddressModel } from "../../services";
import ShowBookingAddress from "../../pages/ShowOrder/ShowBookingAddress";

const DetailViewAddress: FC<DetailViewAddressProps> = ({ address, patientName }) => {
  const theme = useTheme();

  return (
    <div
      style={{
        border: `1px solid ${theme.components?.MuiDivider?.defaultProps?.style?.borderColor}`,
        padding: "16px",
        borderRadius: "6px",
        backgroundColor: grey[50],
        display: "flex",
        flexDirection: "column",
        gap: "8px",
      }}
    >
      <ShowBookingAddress booking={{ address: address }} patientName={patientName} labelWidth="80px" />
    </div>
  );
};

interface DetailViewAddressProps {
  // address: string;
  // lattitude: string;
  // longitude: string;
  patientName: string;
  address: UserAddressModel;
}

export default DetailViewAddress;
