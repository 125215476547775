import { Avatar, Checkbox, ListItem, ListItemAvatar, ListItemButton, ListItemText, Typography } from "@mui/material";
import { FC, useMemo } from "react";
import { ConsultationIvDripModel } from "../../models/GetProductsForConsultationResponseModel";

const SuggestIVDripModalListItem: FC<SuggestIVDripModalListItemProps> = ({ onSelectClicked, selected, ivDrip }) => {
  const labelId = `checkbox-list-secondary-label-${ivDrip.name}`;

  const ingredients = useMemo(() => {
    return ivDrip.ingredients?.split("\n").filter((ing) => !!ing.trim().length);
  }, [ivDrip.ingredients]);

  return (
    <ListItem
      key={ivDrip.productUUID}
      sx={{
        borderBottom: "1px solid #cccccc",
        marginBottom: "1rem",
        "& .MuiListItemSecondaryAction-root .MuiButtonBase-root": {
          minWidth: "50px",
        },
        "& .MuiListItemButton-root": {
          alignItems: "flex-start",
        },
      }}
      secondaryAction={
        <Checkbox
          edge="end"
          onChange={() => {
            onSelectClicked(ivDrip);
          }}
          checked={selected}
          inputProps={{ "aria-labelledby": labelId }}
        />
      }
      disablePadding
    >
      <ListItemButton
        onClick={() => {
          onSelectClicked(ivDrip);
        }}
      >
        <ListItemAvatar>
          <Avatar sx={{ borderRadius: "4px" }} alt={ivDrip.name} src={ivDrip.mainImageUrl} />
        </ListItemAvatar>
        <ListItemText
          id={labelId}
          primary={
            <Typography variant="subtitle1" sx={{}}>
              {ivDrip.name}
            </Typography>
          }
          secondary={
            <div>
              <div style={{ fontWeight: 600 }}>Ingredients:</div>{" "}
              <ul style={{ padding: "4px 14px" }}>
                {ingredients?.map((ing) => {
                  return (
                    <li key={ing} style={{ marginBottom: "0.5rem" }}>
                      {ing}
                    </li>
                  );
                })}
              </ul>
            </div>
          }
        />
      </ListItemButton>
    </ListItem>
  );
};

interface SuggestIVDripModalListItemProps {
  ivDrip: ConsultationIvDripModel;
  selected: boolean;
  onSelectClicked: (drip: ConsultationIvDripModel) => void;
}

export default SuggestIVDripModalListItem;
