import moment from "moment";
import logo from "../../assets/images/logo.jpg";
import useCurrentUserRole from "../../hooks/useCurrentUserRole";
import { Button, Divider, Modal } from "antd";
import { FC, useCallback } from "react";
import { EditOutlined } from "@mui/icons-material";
import { Redirect, useHistory, useParams, useRouteMatch } from "react-router-dom";
import { SlotPatientModel } from "../../models/SchedulerDetailViewResponseModel";
import { NurseBookingType, NursesDatum } from "../../models/SchedulerExploreViewResponseModel";
import { TemporaryAddressModel, getOrderDetails } from "../../services";
import { useMutation } from "@tanstack/react-query";
import { showMessage } from "../../components/common/notification";

export type HistoryBookingType = {
  orderUUID?: string;
  nurseUUID: string;
  startMoment: number;
  endMoment: number;
  patients?: SlotPatientModel[];
  nurseName?: string;
  nurseProfileImg?: string;
  nurseOrderID?: string;
  service?: string;
  caseDescription?: string;
  nurseData?: NursesDatum;
  locationLat?: string;
  locationLng?: string;
  address?: string;
  addressDetails: string;
  nursePickupAddress?: TemporaryAddressModel;
  nurseDropAddress?: TemporaryAddressModel;
  type: NurseBookingType;
};

const BookingDetailsModal: FC = () => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const history = useHistory<any>();
  const booking: HistoryBookingType = history.location.state?.booking;
  const nurseUUID = useParams<{ nurseUUID?: string }>().nurseUUID;
  const { canUpdate } = useCurrentUserRole();
  const { url } = useRouteMatch();

  const getOrderMutation = useMutation({ mutationFn: getOrderDetails });

  const handleBookingIDClicked = useCallback(async () => {
    const res = await getOrderMutation.mutateAsync({ orderUUID: booking.orderUUID ?? "" });
    if (!res?.order?.orderUUID) {
      return showMessage("error", "Something went wrong");
    }
    history.push(`/order/${res.order?.orderUUID}`, { booking });
  }, [booking, getOrderMutation, history]);

  if (!booking) {
    return <Redirect to="/scheduler" />;
  }

  const handleDeleteClicked = () => {
    const urlBase = url.split("/")[1];
    if (urlBase === "scheduler-details") {
      history.replace(`/${urlBase}/${nurseUUID}/delete-booking`, { booking: { ...booking, type: "BOOKING" } });
    } else {
      history.replace(`/${urlBase}/delete-booking`, { booking: { ...booking, type: "BOOKING" } });
    }
  };

  const onCancel = () => {
    const baseURL = url.split("/")[1];
    if (baseURL === "scheduler-details") {
      history.replace(`/${baseURL}/${nurseUUID}`);
    } else {
      history.replace(`/${baseURL}`);
    }
  };

  const handleEditClicked = () => {
    if (booking.nurseOrderID) {
      history.push("/scheduler/edit-order", {
        nurseOrderID: booking.nurseOrderID,
        caseDescription: booking.caseDescription,
        patients: booking.patients?.map((patinet) => patinet.name).join(", "),
        nurseUUID: booking.nurseUUID,
        nurseData: booking?.nurseData,
        initialSelectedDate: booking.startMoment,
        startTime: booking.startMoment,
        endTime: booking.endMoment,
        service: booking.service,
        locationLat: booking.locationLat,
        locationLng: booking.locationLng,
        address: booking.address,
        edit: true,
        addressDetails: booking.addressDetails,
        nursePickupAddress: booking.nursePickupAddress,
        nurseDropAddress: booking.nurseDropAddress,
      });
    } else if (booking.orderUUID) {
      history.push("/");
    }
  };

  return (
    <Modal
      centered
      className="scheduler-modal scheduler-booking-details-modal"
      title={
        <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
          <div>Booking Details</div>
          {(booking.orderUUID || booking.nurseOrderID) && canUpdate ? (
            <Button
              type="text"
              style={{ color: "#008690", width: "35px", height: "35px", minWidth: "35px", padding: "0px" }}
              onClick={(e) => {
                e.stopPropagation();
                handleEditClicked();
              }}
              shape="circle"
            >
              <EditOutlined />
            </Button>
          ) : (
            <div />
          )}
        </div>
      }
      open={true}
      maskClosable={false}
      footer={null}
      onCancel={onCancel}
    >
      <div className="modal-container">
        <div className="detail-row">
          <div className="label">Professional:</div>
          <div className="value">
            <strong>{booking.nurseName}</strong>
          </div>
        </div>
        {booking.patients && booking.patients.length > 0 && booking.patients[0]?.name && (
          <div className="detail-row">
            <div className="label">Patient:</div>
            <div className="value">
              <strong>{booking.patients[0].name}</strong>
            </div>
          </div>
        )}
        {booking.patients && booking.address && booking.locationLat && booking.locationLng && (
          <div className="detail-row">
            <div className="label">Address:</div>
            <div className="value">
              <a
                href={`https://www.google.com/maps/search/?api=1&query=${booking.locationLat}%2C${booking.locationLng}`}
                target="_blank"
                rel="noreferrer"
              >
                {`${booking.addressDetails} ${booking.address}`}
              </a>
            </div>
          </div>
        )}
        {booking?.nursePickupAddress?.address && (
          <div className="detail-row">
            <div className="label">Temporary pickup location:</div>
            <div className="value">
              <a href={booking?.nursePickupAddress.addressLink ?? ""} target="_blank" rel="noreferrer">
                {booking?.nursePickupAddress.address ?? ""}
              </a>
            </div>
          </div>
        )}
        {booking?.nurseDropAddress?.address && (
          <div className="detail-row">
            <div className="label">Temporary drop location:</div>
            <div className="value">
              <a href={booking?.nurseDropAddress.addressLink} target="_blank" rel="noreferrer">
                {booking?.nurseDropAddress.address}
              </a>
            </div>
          </div>
        )}
        {booking.service && (
          <div className="detail-row">
            <div className="label">Service:</div>
            <div className="value">
              <strong>{booking.service}</strong>
            </div>
          </div>
        )}
        {booking.patients && booking.patients.length > 0 && booking.patients[0].caseDescription && (
          <div className="detail-row">
            <div className="label">Case Description:</div>
            <div className="value">
              <strong>{booking.patients[0].caseDescription}</strong>
            </div>
          </div>
        )}
        {/* <div className="detail-row">
          <div className="label">Date:</div>
          <div className="value">
            <strong>{moment(booking.startMoment).format("DD MMMM YYYY")}</strong>
          </div>
        </div> */}
        <div className="detail-row">
          <div className="label">From:</div>
          <div className="value">
            <strong>{moment(booking.startMoment).format("DD/MM/YYYY hh:mm A")}</strong>
          </div>
        </div>
        <div className="detail-row">
          <div className="label">To:</div>
          <div className="value">
            <strong>{moment(booking.endMoment).format("DD/MM/YYYY hh:mm A")}</strong>
          </div>
        </div>
        {booking.patients && booking.patients.length > 0 && booking.patients[0].source && (
          <div className="detail-row">
            <div className="label">Source:</div>
            <div className="value">
              <strong>{booking.patients[0].source}</strong>
              {booking.patients[0].source === "DARDOC" && (
                <img
                  src={logo}
                  alt="DarDoc"
                  style={{
                    width: "35px",
                    height: "35px",
                  }}
                />
              )}
            </div>
          </div>
        )}
        {booking.orderUUID && (
          <div className="detail-row">
            <div className="label">Order ID:</div>
            <div className="value">
              <Button onClick={handleBookingIDClicked} loading={getOrderMutation.isLoading}>
                <strong>{booking.orderUUID}</strong>
              </Button>
            </div>
          </div>
        )}
      </div>
      <Divider
        style={{
          margin: "0px",
        }}
      />
      <div
        style={{
          paddingLeft: "24px",
          paddingRight: "24px",
          paddingBottom: "12px",
          paddingTop: "12px",
          display: "flex",
          justifyContent: "space-between",
          alignContent: "center",
          gap: "1rem",
        }}
      >
        {booking.nurseOrderID && canUpdate ? (
          <Button onClick={handleDeleteClicked} danger>
            Delete
          </Button>
        ) : (
          <div />
        )}
        <Button type="default" onClick={onCancel} className="ant-btn-defaul-primary">
          Close
        </Button>
      </div>
    </Modal>
  );
};

export default BookingDetailsModal;
