import axios from "../utils/axiosHelpers";
import decrypt from "./decrypt";
import encrypt from "./encrypt";
import OurBookingsResponseModel, {
  BookingFromGoogleSheetModel,
  DriverScheduleTableData,
} from "../models/OurBookingsResponseModel";
import BookingModel from "../models/BookingModel";
import { driversListColors } from "../pages/DriverSchedule/SelectDriverModal";
import { fetchDriverScheduleForDate } from "./driver.schedule.service";
import { getDriverScheduleTableDataItemKey, PickupTimesMap } from "../pages/DriverSchedule";
import { BookingDateModel } from "../models/BookingModel";
import { PartnerUserModel } from "../models/PartnerUserModel";
import moment from "moment";
import BaseResponseModel from "../models/BaseResponseModel";
import UploadFilePayloadModel from "../models/UploadFilePayloadModel";

export interface GetBookingDetailsResponseModel extends BaseResponseModel {
  medications?: string[];
}

export async function getTRTMedicationsList({ location, orderUUID }: { location: string; orderUUID: string }) {
  const res = await axios.get<string>(`/api/v1/apps/get-trt-medication-list/${location}/${orderUUID}`);
  const data: GetBookingDetailsResponseModel = decrypt(res.data);
  return data;
}

export async function updateCallStatus(params: { orderUUID: string; type: "CALL_STARTED" | "CALL_ENDED" }) {
  const res = await axios.post<string>("/api/v1/orders/update-call-status", { data: encrypt(params) });
  const data: BaseResponseModel = decrypt(res.data);
  return data;
}

export interface CreatePartnerUserResponseModel extends BaseResponseModel {
  user?: PartnerUserModel;
}

export async function createPartnerUser(params: {
  partnerUUID: string;
  name: string;
  email: string;
  role: string;
  phoneNumber: string;
  firebaseUID: string;
}): Promise<CreatePartnerUserResponseModel | null> {
  try {
    const res = await axios.post<string>("/api/v1/partneraccounts/create-partner-user", { data: encrypt(params) });
    const data: CreatePartnerUserResponseModel = decrypt(res.data);
    return data;
  } catch (error) {
    console.log("--- createPartnerUser error", error);
    return null;
  }
}

export interface CheckUniquePartnerUserResponseModel extends BaseResponseModel {
  user?: PartnerUserModel;
}

export async function checkUniquePartnerUserData(params: {
  mobileNumber?: string;
  email?: string;
}): Promise<CheckUniquePartnerUserResponseModel | null> {
  try {
    const res = await axios.post<string>("/api/v1/partners/check-unique-partner-user-data", { data: encrypt(params) });
    const data: CheckUniquePartnerUserResponseModel = decrypt(res.data);
    return data;
  } catch (error) {
    console.log("--- checkUniquePartnerUserData error", error);
    return null;
  }
}

export interface UploadReferenceDocResponseModel extends BaseResponseModel {
  order?: BookingModel;
}

export async function deletePatientBookingReport(params: { orderUUID: string; patientID: string; reportURL: string }) {
  try {
    const res = await axios.post<string>("/api/v1/orders/delete-patient-booking-report", { data: encrypt(params) });
    const data: UploadReferenceDocResponseModel = decrypt(res.data);
    return data;
  } catch (error) {
    console.log("--- deletePatientBookingReport error", error);
    return null;
  }
}

export async function updateTRTMedicationForOrder(params: { orderUUID: string; selectedMedication: string }) {
  try {
    const res = await axios.post<string>(`/api/v1/new-connected-care/update-trt-medications`, { data: encrypt(params) });
    const data: BaseResponseModel = decrypt(res.data);
    return data;
  } catch (e) {
    console.log("--- updateTRTMedicationForOrder error", e);
    return null;
  }
}

export async function uploadReferenceDoc(params: {
  orderUUID: string;
  document: UploadFilePayloadModel;
  type?: "CONTROLLED" | "UNCONTROLLED";
}): Promise<UploadReferenceDocResponseModel | null> {
  try {
    const res = await axios.post("/api/v1/orders/uploadreferencedocs", {
      data: encrypt(params),
    });
    const data: UploadReferenceDocResponseModel = decrypt(res.data);
    return data;
  } catch (error) {
    console.log("--- uploadReferenceDoc error", error);
    return null;
  }
}

export async function acknowledgeDeliveryBooking(params: { orderUUID: string }): Promise<BaseResponseModel | null> {
  try {
    const res = await axios.post("/api/v1/orders/deliveryacknowledgement", {
      data: encrypt(params),
    });
    const data: BaseResponseModel = decrypt(res.data);
    return data;
  } catch (error) {
    console.log("--- acknowledgeDeliveryBooking error", error);
    return null;
  }
}

export async function updateDoctorConsultationBooking(params: {
  orderUUID: string;
  otp: string;
}): Promise<BaseResponseModel | null> {
  try {
    const res = await axios.post<string>("/api/v1/orders/updatedoctorconsultationbooking", {
      data: encrypt(params),
    });
    const data: BaseResponseModel = decrypt(res.data);
    return data;
  } catch (error) {
    console.log("--- updateDoctorConsultationBooking error", error);
    return null;
  }
}

export async function acknowledgePremiumOrder(params: { orderUUID: string }): Promise<{ success: number | boolean } | null> {
  try {
    const res = await axios.post<string>("/api/v1/orders/premiumacknowledgement", {
      data: encrypt({ orderUUID: params.orderUUID }),
    });
    const data: { success: number | boolean } = decrypt(res.data);
    return data;
  } catch (e) {
    console.log("--- acknowledgePremiumOrder error", e);
    return null;
  }
}

export async function checkIfNurseCanServeBooking(params: {
  nurseUUID: string;
  onlyCheckForBookings: boolean;
  orderDates: BookingDateModel[];
}): Promise<{
  success: number | boolean;
  assignNurseErrors?: string[];
} | null> {
  try {
    const res = await axios.post<string>("/api/v1/orders/check-if-nurse-is-available-for-booking-dates", {
      data: encrypt(params),
    });
    const data: { success: number | boolean; assignNurseErrors?: string[] } = decrypt(res.data);
    // console.log("--- check-if-nurse-is-available-for-booking-dates", params);
    // console.log(data);
    return data;
  } catch (e) {
    console.log("--- checkIfNurseCanServeBooking error");
    console.log(e);
    return null;
  }
}

export interface GetOrderDetailsResponseModel extends BaseResponseModel {
  order?: BookingModel;
}

export async function getOrderDetails(params: { orderUUID: string }): Promise<GetOrderDetailsResponseModel | null> {
  try {
    const res = await axios.get<string>(`/api/v1/orders/getparticularpartnerorder/${params.orderUUID}`);
    const decryptedBody: GetOrderDetailsResponseModel = decrypt(res.data);
    if (!decryptedBody.order?.orderUUID) {
      console.log("error fetching order details", decryptedBody);
    }
    return decryptedBody;
  } catch (e) {
    console.log("--- getOrderDetails error");
    console.log(e);
    return null;
  }
}

export async function automateDriverSchedule(params: {
  dateToCheck: string;
  previousDate: string;
  partnerUUID: string;
}): Promise<{ [driverName: string]: GeneratedDriverScheduleItem[] } | null> {
  try {
    // DriverScheduleTableData
    const body = { data: encrypt(params) };
    const res = await axios.post("/api/v1/driverschedules/automatedriverschedulenew", body);
    const data = decrypt(res.data);
    const driverScheduleData: {
      [driverName: string]: GeneratedDriverScheduleItem[];
    } = {};
    const driverNames = Array.from(Object.keys(data.driverSchedules));
    for (let i = 0; i < driverNames.length; i += 1) {
      const driverName = driverNames[i];
      const driverTasks = data.driverSchedules[driverName] ?? [];
      for (const driverTask of driverTasks) {
        const driverScheduleItem: GeneratedDriverScheduleItem = {
          bookingUUID: driverTask[0],
          locationLink: driverTask[2],
          nurseName: driverTask[1],
          time: driverTask[3],
          type: driverTask[4],
          driverRouteColor: driversListColors[i % driversListColors.length],
          driverName,
          address: driverTask[5],
        };
        if (driverScheduleData[driverName]?.length) {
          driverScheduleData[driverName].push(driverScheduleItem);
        } else {
          driverScheduleData[driverName] = [driverScheduleItem];
        }
      }
    }
    // console.log("--- driverScheduleData", driverScheduleData);
    return driverScheduleData;
    // const firstDriverKey = Object.keys(driverScheduleData ?? [])[0];
    // return { [firstDriverKey]: driverScheduleData[firstDriverKey].slice(0, 2) };
  } catch (e) {
    console.log("--- automateDriverSchedule error");
    console.log(e);
    return null;
  }
}

export async function fetchOurBookings(params: { dateToCheck: string; previousDate: string; partnerUUID: string }): Promise<{
  tableData: DriverScheduleTableData[];
  pickupTimes: PickupTimesMap;
} | null> {
  try {
    // console.log("-- encrypt(params)", encrypt(params));
    const body = { data: encrypt(params) };
    const responses = await Promise.all([
      axios.post<string>("/api/v1/orders/fetchourbooking", body),
      fetchDriverScheduleForDate({ date: params.dateToCheck }),
    ]);
    const fetchOurBookingsRes = responses[0];
    const savedScheduleResponse = responses[1];
    const decryptedBody = decrypt(fetchOurBookingsRes.data);
    // console.log("--- fetchourbooking", decryptedBody);
    const orders: BookingFromGoogleSheetModel[] | undefined = decryptedBody.orders;
    const ourBookingsMappedResponse: OurBookingsResponseModel = {
      success: decryptedBody.success,
      orders: orders?.map<DriverScheduleTableData>((row) => {
        return {
          date: row[0],
          distance: row[10],
          fromAddress: row[4],
          fromAddressLink: row[3],
          fromArea: row[5],
          key: `${row[1]}-${row[0]}-${row[6]}-${row[11]}-${row[2]}`,
          nurse: row[2],
          orderUUID: row[1],
          time: row[6],
          toAddress: row[8],
          toAddressLink: row[7],
          toArea: row[9],
          type: row[11],
          minutes: row[12],
          driver: undefined,
          pickupTime: undefined,
        };
      }),
    };
    const pickupTimes: PickupTimesMap = {};
    const seenBookings = new Map<string, boolean>();
    const scheduleItemsMap = new Map<string, DriverScheduleTableData>();
    savedScheduleResponse?.orders?.forEach((row) => {
      const key = getDriverScheduleTableDataItemKey(row);
      scheduleItemsMap.set(key, row);
    });
    const rows = (
      ourBookingsMappedResponse?.orders?.filter((row) => {
        const key = getDriverScheduleTableDataItemKey(row);
        if (seenBookings.get(key)) return false;
        seenBookings.set(key, true);
        return true;
      }) ?? []
    ).map<DriverScheduleTableData>((row) => {
      const key = getDriverScheduleTableDataItemKey(row);
      const savedBookingSchedule = scheduleItemsMap.get(key);
      const pickupTimeStr = (savedBookingSchedule ?? row).pickupTime;
      if (pickupTimeStr) {
        pickupTimes[key] = moment(pickupTimeStr, "hh:mmA");
      }
      if (savedBookingSchedule) {
        row.driver = savedBookingSchedule.driver;
        row.pickupTime = pickupTimeStr;
      }
      return row;
    });
    return { tableData: rows ?? [], pickupTimes: pickupTimes };
  } catch (e) {
    console.log("--- fetchOurBookings error");
    console.log(e);
    return null;
  }
}

export async function fetchSystemRecommendedNurses(params: {
  orderUUID: string;
  dates?: string;
}): Promise<RecommendedNursesResponseModel | null> {
  try {
    const res = await axios.post<string>(`/api/v1/orders/fetch-recommended-nurses/${params.orderUUID}`, {
      data: encrypt({ dates: params.dates }),
    });
    const data: RecommendedNursesResponseModel = decrypt(res.data);
    // console.log("--- fetch-recommended-nurses", data);
    return data;
  } catch (e) {
    console.log("--- fetchSystemRecommendedNurses error");
    console.log(e);
    return null;
  }
}

export interface GeneratedDriverScheduleItem {
  bookingUUID: string;
  nurseName: string;
  locationLink: string;
  address?: string;
  time: string;
  type: "pickup" | "delivery";
  driverName: string;
  driverRouteColor: string;
}

export interface RecommendedNursesResponseModel {
  success: number | boolean;
  nurses?: RecommendedNurseModel[];
}

export interface RecommendedNurseModel {
  nurseUUID: string;
  rating?: number;
  distanceToBooking?: number;
}
