import { FC } from "react";
import { Button } from "@mui/material";
import BookingModel from "../../../models/BookingModel";
import { Col, Row } from "antd";

export const bookingDatesPaginationSize = 6;

const AssignNurseModalNextPrevButtons: FC<AssignNurseModalNextPrevButtonsProps> = ({ order, datesPage, setDatesPage }) => {
  return (
    <Row
      style={{
        justifyContent: "space-between",
        alignItems: "center",
      }}
    >
      <Col>
        <Button
          style={{
            width: "160px",
            borderRadius: "2rem",
            color: "#fff",
          }}
          disabled={datesPage <= 1}
          variant="contained"
          color="primary"
          onClick={(e) => {
            e.stopPropagation();
            if (datesPage <= 1) return;
            setDatesPage(datesPage - 1);
          }}
        >
          {"<"} Previous
        </Button>
      </Col>
      <Col>
        <Button
          style={{
            width: "160px",
            borderRadius: "2rem",
            color: "#fff",
          }}
          disabled={datesPage * bookingDatesPaginationSize >= (order?.date?.length ?? 0)}
          variant="contained"
          color="primary"
          onClick={(e) => {
            e.stopPropagation();
            if (datesPage * bookingDatesPaginationSize >= (order?.date?.length ?? 0)) return;
            setDatesPage(datesPage + 1);
          }}
        >
          Next {">"}
        </Button>
      </Col>
    </Row>
  );
};

interface AssignNurseModalNextPrevButtonsProps {
  order: BookingModel;
  datesPage: number;
  setDatesPage: React.Dispatch<React.SetStateAction<number>>;
}

export default AssignNurseModalNextPrevButtons;
