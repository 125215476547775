import { FC, useState } from "react";
import { FilterPeriodKeysType } from "../Traction";
import PharmacyRevenueTrackerHeader from "./PharmacyRevenueTrackerHeader";
import moment from "moment";
import PharmacyTractionTable from "./PharmacyTractionTable";

const PharmacyRevenueTracker: FC = () => {
  const [selectedDateRange, setSelectedDateRange] = useState<[moment.Moment, moment.Moment]>();
  const [selectedPeriod, setSelectedPeriod] = useState<FilterPeriodKeysType>("current-month");

  return (
    <div
      style={{ padding: "16px", flexDirection: "column", gap: "16px", height: "84%", maxHeight: "100%" }}
      className="main_page traction web pharmacy-revenue-tracker"
    >
      <PharmacyRevenueTrackerHeader
        selectedPeriod={selectedPeriod}
        setSelectedDateRange={setSelectedDateRange}
        setSelectedPeriod={setSelectedPeriod}
        selectedDateRange={selectedDateRange}
      />
      <PharmacyTractionTable
        selectedPeriod={selectedPeriod}
        selectedDateRange={selectedDateRange}
        setSelectedDateRange={setSelectedDateRange}
      />
    </div>
  );
};

export default PharmacyRevenueTracker;
