import moment from "moment";
import { Form, FormInstance, Select } from "antd";
import { FC, useMemo } from "react";

const minimumNurseShiftHours = 6;

const NurseShiftEndFormItem: FC<NurseShiftEndFormItemProps> = ({ form }) => {
  const startTime: string | undefined = Form.useWatch("startTime", form);

  const options = useMemo<moment.Moment[]>(() => {
    if (!startTime) return [];
    const arr: moment.Moment[] = [];
    const currentMoment = moment(startTime).add(30, "minute");
    const endOfDay = moment().endOf("day");
    while (currentMoment.isBefore(endOfDay)) {
      arr.push(currentMoment.clone());
      currentMoment.add(30, "minute");
    }
    arr.push(moment().endOf("day").set("second", 0).set("millisecond", 0));
    return arr;
  }, [startTime]);

  return (
    <Form.Item
      className="form-item-edit"
      style={{ flex: "auto" }}
      label="Shift ends at"
      name="endTime"
      rules={[
        () => ({
          validator(_, value) {
            const endMoment = moment(value);
            if (!value) {
              return Promise.reject("Please select the shift end time");
            } else if (endMoment.isSameOrBefore(moment(startTime))) {
              return Promise.reject("Shift end time has to be after shift start time");
            }
            const startMoment = moment(startTime);
            const differenceDuration = moment.duration(endMoment.diff(startMoment));
            if (differenceDuration.asHours() < minimumNurseShiftHours) {
              return Promise.reject(`Minimum shift hours is ${minimumNurseShiftHours} hours`);
            }
            return Promise.resolve();
          },
        }),
      ]}
    >
      <Select>
        {options.map((optionMoment) => {
          return (
            <Select.Option key={optionMoment.format()} value={optionMoment.format()}>
              {optionMoment.format("hh:mm A")}
            </Select.Option>
          );
        })}
      </Select>
    </Form.Item>
  );
};

interface NurseShiftEndFormItemProps {
  // editMode: boolean;
  form: FormInstance;
}

export default NurseShiftEndFormItem;
