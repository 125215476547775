import OneSignal from "react-onesignal";
import { useCallback, useEffect } from "react";
import { useSelector } from "react-redux";
import { setOneSignalUserID } from "../services/onesignal.service";
import ReduxStateModel from "../models/ReduxStateModel";
import useNotificationsPermission from "../hooks/useNotificationsPermission";
import useAppSettings from "../hooks/useAppSettings";

const useOneSignal = function () {
  const notificationsPermission = useNotificationsPermission();
  const partnerUUID = useSelector((state: ReduxStateModel) => state.authReducer?.setting?.partnerUUID);

  const setPlayerAndUserExternalID = useCallback(async () => {
    if (!partnerUUID) return;
    OneSignal.setExternalUserId(partnerUUID);
    const oneSignalUserID = await OneSignal.getUserId();
    setOneSignalUserID({ partnerUUID, playerID: oneSignalUserID });
    // console.log("--- showing slide prompt");
    OneSignal.showSlidedownPrompt();
    // console.log("--- show prompt");
  }, [partnerUUID]);

  useEffect(() => {
    if (notificationsPermission === "granted") {
      setPlayerAndUserExternalID();
    }
  }, [notificationsPermission, setPlayerAndUserExternalID]);

  useEffect(() => {
    if (!partnerUUID) return;
    setPlayerAndUserExternalID();
  }, [partnerUUID, setPlayerAndUserExternalID]);

  const { query } = useAppSettings();

  useEffect(() => {
    if (!partnerUUID) return;
    if (!query.data?.key?.oneSignalProdAppId) return;
    if (window.location.host === "localhost:3000") return;
    (async () => {
      try {
        await OneSignal.init({
          appId: query.data?.key?.oneSignalProdAppId ?? "",
          allowLocalhostAsSecureOrigin: import.meta.env.DEV,
        });
        await OneSignal.getNotificationPermission();
        OneSignal.showSlidedownPrompt();
        if (partnerUUID) {
          setPlayerAndUserExternalID();
        }
      } catch (e) {
        console.log("--- OneSignal.init error");
        console.log(e);
      }
    })();
  }, [partnerUUID, query.data?.key?.oneSignalProdAppId, setPlayerAndUserExternalID]);
};

export default useOneSignal;
