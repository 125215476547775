import { useEffect, FC, SetStateAction, Dispatch } from "react";
import { StaffMemberModel } from "../../models/StaffMemberModel";
import useCurrentUserRole from "../../hooks/useCurrentUserRole";

const StaffProfileAddressSearchInput: FC<AddressSearchInputProps> = ({
  profile,
  showRequiredMark,
  label,
  onAddressSelected,
  setProfile,
}) => {
  const { canUpdate } = useCurrentUserRole();
  //   const [value, setValue] = useState<string>("");

  useEffect(() => {
    const searchInput = document.getElementById("address-search") as HTMLInputElement;
    if (!searchInput) return;
    const autocomplete = new window.google.maps.places.Autocomplete(searchInput, {
      componentRestrictions: { country: "ae" },
    });
    autocomplete.setFields(["formatted_address", "name", "place_id", "geometry"]);
    const listener = autocomplete.addListener("place_changed", () => {
      const placeData = autocomplete.getPlace();
      if (!placeData.geometry?.location) return;
      const address = `${placeData.name} ${placeData.formatted_address ?? ""}`;
      setProfile((prevValue) => {
        const newValue: StaffMemberModel = { ...prevValue };
        newValue.address = address;
        newValue.addressLink = `https://www.google.com/maps?q=${placeData.geometry!.location!.lat()},${placeData.geometry!.location!.lng()}`;
        return newValue;
      });
      // autocomplete.set("value", address);
      searchInput.value = address;
      if (onAddressSelected) {
        onAddressSelected({ lat: placeData.geometry!.location!.lat(), lng: placeData.geometry!.location!.lng() });
      }
    });
    return () => {
      autocomplete.unbindAll();
      listener.remove();
    };
  }, [onAddressSelected, setProfile]);

  useEffect(() => {
    if (!profile?.address) return;
    const searchInput = document.getElementById("address-search") as HTMLInputElement;
    if (!searchInput) return;
    searchInput.value = profile.address ?? "";
  }, [profile?.address]);

  return (
    <div className="ant-form-item">
      <div className="ant-row ant-form-item-row">
        <div className="ant-col ant-form-item-label">
          <label htmlFor="address" className={showRequiredMark ? "ant-form-item-required" : ""} title="Address">
            {label ?? "Address"}
          </label>
        </div>
        <div className="ant-col ant-form-item-control">
          <div className="ant-form-item-control-input">
            <div className="ant-form-item-control-input-content">
              <input
                disabled={!canUpdate}
                name="address"
                id="address-search"
                className="ant-input address-auto-complete-input ant-input-lg"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

interface AddressSearchInputProps {
  profile?: StaffMemberModel;
  //   mapCenter: { lat: number; lng: number } | undefined;
  //   defaultValue?: string;
  label?: string;
  showRequiredMark?: boolean;
  setProfile: Dispatch<SetStateAction<StaffMemberModel>>;
  onAddressSelected?: (params: { lat: number; lng: number }) => void;
}

export default StaffProfileAddressSearchInput;
