import React, { useCallback, useMemo } from "react";
import moment from "moment";
import BookingModel from "../../../models/BookingModel";
import { Col, Row, Select, Spin } from "antd";
import { BookingDateModel } from "../../../models/BookingModel";
import { StaffMemberModel } from "../../../models/StaffMemberModel";
import { AssignNurseParamsType } from "./NewAssignNurseModal";
import { showMessage } from "../notification";
import useCurrentUserRole from "../../../hooks/useCurrentUserRole";
import getStartMomentFromDateObj from "../../../utils/getStartMomentFromDateObj";
import useAllPartnerNurses from "../../../hooks/useAllPartnerNurses";
import useValidOrderNurses from "../../../hooks/useValidOrderNurses";

const AssignNurseModalDateCard: React.FC<AssignNurseModalDateCardProps> = ({ date: bookingDate, booking, onNurseSelected }) => {
  const { canUpdate } = useCurrentUserRole();
  const { query } = useAllPartnerNurses();
  const allNurses = useMemo(() => query.data?.data.nurses ?? [], [query.data?.data.nurses]);
  const { query: validNurseQuery } = useValidOrderNurses({ orderUUID: booking.orderUUID });
  const validnurses = useMemo(() => validNurseQuery.data?.data.nurses ?? [], [validNurseQuery.data?.data.nurses]);
  // const [loading, setLoading] = useState<boolean>(false);

  const bookingDateMoment = useMemo<moment.Moment>(() => {
    return getStartMomentFromDateObj({ date: bookingDate.date, time: bookingDate.time });
  }, [bookingDate]);

  const isFirstDate = useMemo<boolean>(() => {
    return bookingDate.date === booking.date?.[0].date;
  }, [booking.date, bookingDate.date]);

  const secondShiftStartTime = useMemo<moment.Moment>(() => {
    return bookingDateMoment.clone().add(12, "hour");
  }, [bookingDateMoment]);

  const is24HoursBooking = useMemo(() => bookingDate.numberOfDurationOrSession === 24, [bookingDate.numberOfDurationOrSession]);

  const showSecondShiftSelect = useMemo<boolean>(() => {
    if (!is24HoursBooking) return false;
    if (isFirstDate && !secondShiftStartTime.isSame(bookingDateMoment, "day")) {
      return false;
    }
    return true;
  }, [bookingDateMoment, is24HoursBooking, isFirstDate, secondShiftStartTime]);

  const assignableNursesMap = useMemo<Map<string, boolean>>(() => {
    const map = new Map<string, boolean>();
    for (const nurseID of validnurses ?? []) {
      map.set(nurseID, true);
    }
    for (const assignedNurse of booking.assignedNurses ?? []) {
      map.set(assignedNurse.nurseUUID ?? "", true);
    }
    return map;
  }, [booking.assignedNurses, validnurses]);

  const handleNurseChanged = async (nurseUUID: string, isSecondShift: boolean = false) => {
    // setLoading(true);
    // const res = await nurseService.checkDateCanBeDisabled({
    //   nurseUUID: nurseUUID,
    //   newHoliday: booking?.date?.map((item) => item?.date),
    // });
    // setLoading(false);
    const nurse = allNurses?.find((nurse) => nurse.nurseUUID === nurseUUID);
    const patient = booking.patient?.[0];
    if (!patient) {
      showMessage("error", "Something went wrong");
      return console.log("--- assign nurse date card error no patient", booking.patient);
    }
    // console.log("--- handleNurseChanged", res?.data);
    onNurseSelected({
      nurse: nurse!,
      patient: patient,
      // nurseOccupiedDates: res?.data,
      isSecondShift,
    });
  };

  const onFirstShiftNurseChange = (value: string) => {
    handleNurseChanged(value);
  };

  const onSecondShiftNurseChange = (value: string) => {
    handleNurseChanged(value, true);
  };

  const firstShiftSelectedNurseID = useMemo<string | undefined>(() => {
    return booking.assignedNurses?.find((assignedNurse) => {
      return assignedNurse.dateArray?.some(
        (nurseAssignedDate) => nurseAssignedDate.date === bookingDate.date && nurseAssignedDate.time === bookingDate.time
      );
    })?.nurseUUID;
  }, [booking.assignedNurses, bookingDate.date, bookingDate.time]);

  const secondShiftSelectedNurseID = useMemo<string | undefined>(() => {
    return booking.assignedNurses?.find((assignedNurse) => {
      return assignedNurse.dateArray?.some(
        (nurseAssignedDate) => nurseAssignedDate.date === bookingDate.date && nurseAssignedDate.time !== bookingDate.time
      );
    })?.nurseUUID;
  }, [booking.assignedNurses, bookingDate.date, bookingDate.time]);

  const nurseSelectOptions = useMemo<StaffMemberModel[] | undefined>(() => {
    return allNurses?.filter((nurse) => {
      return (
        assignableNursesMap.get(nurse.nurseUUID ?? "") ||
        nurse.nurseUUID === firstShiftSelectedNurseID ||
        nurse.nurseUUID === secondShiftSelectedNurseID
      );
    });
  }, [allNurses, assignableNursesMap, firstShiftSelectedNurseID, secondShiftSelectedNurseID]);

  const getNurseName = useCallback((fname?: string, lname?: string) => {
    const name = `${fname} ${lname}`;
    if (name?.length > 20) {
      const splicedName = name.slice(0, 40);
      return `${splicedName}...`;
    }
    return name;
  }, []);

  const switchShiftNames = useMemo<boolean>(() => {
    const firstShiftTime = moment(bookingDateMoment.format("hh:mmA"), "hh:mmA");
    const secondShfitTime = moment(secondShiftStartTime.format("hh:mmA"), "hh:mmA");
    if (secondShfitTime.isBefore(firstShiftTime)) return true;
    return false;
  }, [bookingDateMoment, secondShiftStartTime]);

  const timeEmoji = useMemo(() => {
    const dayHour = Number(bookingDateMoment.format("H"));
    if (dayHour >= 0 && dayHour < 5) {
      return "🌑";
    } else if (dayHour >= 5 && dayHour < 7) {
      return "🌓";
    } else if (dayHour >= 7 && dayHour < 15) {
      return "🌕";
    } else if (dayHour >= 15 && dayHour < 18) {
      return "🌗";
    } else if (dayHour >= 18 && dayHour <= 24) {
      return "🌑";
    }
  }, [bookingDateMoment]);

  return (
    <Spin spinning={query.isLoading || validNurseQuery.isLoading}>
      <Row
        style={{
          backgroundColor: "white",
          boxShadow: "rgb(0 0 0 / 10%) 0px 2px 5px 0px, rgb(0 0 0 / 12%) 0px 4px 6px 0px",
          padding: "1rem 1rem",
          borderRadius: "7px",
          gap: "0.5rem",
          width: "100%",
          margin: 0,
        }}
      >
        <Col span={24}>
          <DateInfoItem name="Date" value={bookingDate.date} />
        </Col>
        <Col span={24}>
          <DateInfoItem name={`Start Time ${timeEmoji}`} value={bookingDateMoment.format("hh:mm A")} />
        </Col>
        <Col span={24}>
          <DateInfoItem
            name="Duration"
            value={
              <p style={{ marginBottom: "0px" }}>
                {bookingDate.numberOfDurationOrSession === 24 && !showSecondShiftSelect
                  ? 12
                  : bookingDate.numberOfDurationOrSession}
                {bookingDate.numberOfDurationOrSession === 1 ? " HOUR" : " HOURS"}
              </p>
            }
          />
        </Col>
        <div
          style={{ display: "flex", flexDirection: switchShiftNames ? "column-reverse" : "column", width: "100%", gap: "16px" }}
        >
          <Col span={24} style={{ flex: "none" }}>
            <DateInfoItem
              name={
                is24HoursBooking ? (
                  <div>{`${!isFirstDate && switchShiftNames ? "Second Shift" : "First Shift"} ${bookingDateMoment.format(
                    "hh:mm A"
                  )}`}</div>
                ) : (
                  <div>Nurse</div>
                )
              }
              value={
                <div style={{ color: "#25bcbd" }}>
                  <Select
                    showSearch
                    disabled={!canUpdate}
                    placeholder="Select a nurse"
                    optionFilterProp="children"
                    style={{
                      width: "100%",
                    }}
                    onChange={onFirstShiftNurseChange}
                    value={firstShiftSelectedNurseID}
                    filterOption={(input, option) => {
                      return (option?.textValue ?? "").toLowerCase().includes(input.toLowerCase());
                    }}
                  >
                    {nurseSelectOptions?.map((nurse) => {
                      const name = `${nurse.firstName} ${nurse.lastName}`;
                      return (
                        <Select.Option key={nurse.nurseUUID} value={nurse.nurseUUID} textValue={name}>
                          <div
                            style={{
                              display: "flex",
                              gap: "4px",
                              alignItems: "center",
                            }}
                          >
                            <img
                              src={nurse.profileImage}
                              alt={name}
                              style={{
                                width: "25px",
                                height: "25px",
                                borderRadius: "50%",
                                objectFit: "cover",
                              }}
                            />

                            <p style={{ margin: 0 }}>{getNurseName(nurse.firstName, nurse.lastName)}</p>
                          </div>
                        </Select.Option>
                      );
                    })}
                  </Select>
                </div>
              }
            />
          </Col>
          {showSecondShiftSelect && (
            <DateInfoItem
              name={<div>{`${switchShiftNames ? "First Shift" : "Second Shift"} ${secondShiftStartTime.format("hh:mm A")}`}</div>}
              value={
                <Select
                  showSearch
                  disabled={!canUpdate}
                  placeholder="Select a nurse"
                  optionFilterProp="children"
                  style={{
                    width: "100%",
                  }}
                  onChange={onSecondShiftNurseChange}
                  value={secondShiftSelectedNurseID}
                  filterOption={(input, option) => {
                    return (option?.textValue ?? "").toLowerCase().includes(input.toLowerCase());
                  }}
                >
                  {nurseSelectOptions?.map((nurse) => {
                    const name = `${nurse.firstName} ${nurse.lastName}`;
                    return (
                      <Select.Option key={nurse.nurseUUID} value={nurse.nurseUUID} textValue={name}>
                        <div style={{ display: "flex", gap: "4px", alignItems: "center" }}>
                          <img
                            src={nurse.profileImage}
                            alt={name}
                            style={{
                              width: "25px",
                              height: "25px",
                              borderRadius: "50%",
                              objectFit: "cover",
                            }}
                          />
                          <p style={{ margin: 0 }}>{name}</p>
                        </div>
                      </Select.Option>
                    );
                  })}
                </Select>
              }
            />
          )}
        </div>
      </Row>
    </Spin>
  );
};

const DateInfoItem = ({ name, value, valueBold }: { name: React.ReactNode; value: React.ReactNode; valueBold?: boolean }) => {
  if (!value) return null;
  return (
    <Row
      style={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "flex-start",
        color: "#b8b8b8",
        lineBreak: "anywhere",
        whiteSpace: "pre-wrap",
        width: "100%",
      }}
    >
      <Col xs={24} sm={9} style={{ display: "flex", alignItems: "center" }}>
        <p style={{ margin: 0 }}>{name}</p>
      </Col>
      <Col
        xs={24}
        sm={15}
        style={{
          color: "black",
          textTransform: "capitalize",
          fontWeight: valueBold ? "bold" : "normal",
        }}
      >
        <p style={{ margin: 0 }}>{value}</p>
      </Col>
    </Row>
  );
};

interface AssignNurseModalDateCardProps {
  date: BookingDateModel;
  booking: BookingModel;
  onNurseSelected: (params: AssignNurseParamsType) => void;
}

export default AssignNurseModalDateCard;
