import axios from "axios";
import decrypt from "./decrypt";
import encrypt from "./encrypt";
import BaseResponseModel from "../models/BaseResponseModel";
import { RecommendationsRequestProductsModel } from "../models/BookingModel";

export async function handleMultiProductAddon(params: {
  orderUUID: string;
  programBookingId: string;
  blockUUID: string;
  products: RecommendationsRequestProductsModel[];
}): Promise<BaseResponseModel> {
  const res = await axios.post<string>("/api/v1/new-connected-care/handle-multi-product-addon-block", {
    data: encrypt(params),
  });
  const data: BaseResponseModel = decrypt(res.data);
  return data;
}
