import { useEffect, useMemo, useState } from "react";
import { Button, Divider, Form, Modal, Select } from "antd";
import { Redirect, useHistory, useParams, useRouteMatch } from "react-router-dom";
import moment from "moment";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { useSelector } from "react-redux";
import TextField from "@mui/material/TextField";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import SchedulerDetailViewResponseModel from "../../models/SchedulerDetailViewResponseModel";
import { generateRangeArray } from "../../utils/utilFunctions";
import { getNurseTimeSlots } from "../../services/nurseTimeSlotService";
// import { NurseBookingModel } from "../../models/SchedulerExploreViewResponseModel";

const AssignStartEndTimeModal: () => JSX.Element = () => {
  const history = useHistory<{
    caseDescription?: string;
    patients?: string;
    selectedDate?: number[];
    nurseUUID?: string;
    startTime?: number;
    durationHours?: number;
  }>();
  const nurseUUID = useParams<{ nurseUUID: string | undefined }>().nurseUUID ?? history.location.state.nurseUUID;
  const [selectedStartTime, setSelectedStartTime] = useState<moment.Moment | undefined | null>(null);
  const [selectedHoursDuration, setSelectedHoursDuration] = useState<number | undefined>();
  const [form] = Form.useForm<{
    startTime: moment.Moment | undefined;
    // endTime: moment.Moment | undefined;
    durationHours: number | undefined;
  }>();
  // console.log("--- location state", history.location.state);
  const selectedWeek: moment.Moment = useSelector((state) => (state as any)?.schedulerDetailViewReducer?.selectedWeek);
  const queryClient = useQueryClient();
  const queryKey = ["nursetimeslots/getparticularnursedatawithtimeslot", nurseUUID, selectedWeek.valueOf()];
  const queryData = queryClient.getQueryData<SchedulerDetailViewResponseModel | null | undefined>(queryKey);

  const { data: getNuseDetailsWithSlotsResponse } = useQuery(
    queryKey,
    () => getNurseTimeSlots({ nurseUUID: nurseUUID ?? "", startDate: selectedWeek, endDate: selectedWeek.clone().add(6, "day") }),
    {
      enabled: !queryData?.nurseData && !queryData?.nurseData?.length,
    }
  );
  const nurseData = useMemo(() => {
    return (queryData?.nurseData ?? getNuseDetailsWithSlotsResponse?.nurseData)?.find((nurse) => nurse.nurseUUID === nurseUUID);
  }, [getNuseDetailsWithSlotsResponse?.nurseData, nurseUUID, queryData?.nurseData]);
  const { url } = useRouteMatch();

  const nurseStartMoment = useMemo<moment.Moment | undefined>(() => {
    const now = moment();
    if (!nurseData) return undefined;
    return moment(`${now.format("DD/MM/YYYY")} ${nurseData?.startTime}`, "DD/MM/YYYY hh:mmA");
  }, [nurseData]);

  const nurseEndMoment = useMemo<moment.Moment | undefined>(() => {
    const now = moment();
    if (!nurseData) return undefined;
    const endMoment = moment(`${now.format("DD/MM/YYYY")} ${nurseData?.endTime}`, "DD/MM/YYYY hh:mmA")
      .set("second", 0)
      .set("millisecond", 0);
    if (endMoment.isSame(moment().startOf("day"))) {
      return endMoment.add(1, "day");
    }
    return endMoment;
  }, [nurseData]);

  // const nurseEndHour = (nurseEndMoment?.get("hour") ?? 0) === 0 ? 24 : nurseEndMoment?.get("hour") ?? 0;
  // const maxStartTimeHour = nurseEndHour - 3;

  // const bookingsNurseDatesMap: { [prop: string]: NurseBookingModel[] } = useSelector(
  //   (state) => (state as any)?.schedulerExploreViewReducer?.nurseDayBookings
  // );

  // const disabledHours = useMemo<number[]>(() => {
  //   let hours: number[] = [
  //     ...generateRangeArray({ start: 0, end: Math.abs((nurseStartMoment?.get("hour") ?? 1) - 1) }),
  //     ...generateRangeArray({ start: maxStartTimeHour, end: 23 }),
  //   ];
  //   // console.log("--- disabledHours 1", hours);
  //   for (const date of history.location?.state?.selectedDate ?? []) {
  //     const dateBookings = bookingsNurseDatesMap[`${nurseUUID}_${moment(date).format()}`] ?? [];
  //     for (const booking of dateBookings) {
  //       const hoursRange = generateRangeArray({ start: booking.startMoment.get("hour"), end: booking.endMoment.get("hour") - 1 });
  //       hours = [...hours, ...hoursRange];
  //     }
  //   }
  //   return hours;
  // }, [maxStartTimeHour, nurseStartMoment, history.location?.state?.selectedDate, bookingsNurseDatesMap, nurseUUID]);

  useEffect(() => {
    if (!history.location.state) return;
    if (history.location.state?.startTime) {
      setSelectedStartTime(moment(history.location.state.startTime));
    }
    if (history.location.state?.durationHours) {
      setSelectedHoursDuration(history.location.state.durationHours);
    }
  }, [history.location?.state]);

  const durationOptions = useMemo<{ label: string; value: number }[]>(() => {
    const options = generateRangeArray({ start: 4, end: 24 }).map<{ label: string; value: number }>((item) => {
      return {
        label: `${item} ${item === 1 ? "hour" : "hours"}`,
        value: item,
      };
    });
    // if (selectedStartTime) {
    //   return options.filter((option) => {
    //     return selectedStartTime
    //       .clone()
    //       .add(option.value, "hour")
    //       .set("second", 0)
    //       .set("millisecond", 0)
    //       .isSameOrBefore(nurseEndMoment);
    //   });
    // }
    return options;
  }, []);
  // [selectedStartTime, nurseEndMoment]

  const handleFormFinished: (values: { [prop: string]: any }) => void = (values) => {
    // console.log("--- handleFormFinish", values);
    const basePath = url.split("/")[1];
    let nextURL;
    if (basePath === "scheduler-details") {
      nextURL = `/${basePath}/${nurseUUID}/assign-patinets-confirmation`;
    } else {
      nextURL = `/${basePath}/assign-patinets-confirmation`;
    }
    history.replace(nextURL, getHistoryState({ durationHours: values.durationHours, startTime: selectedStartTime }));
  };

  const getHistoryState = (params: { startTime?: moment.Moment | null; durationHours?: number }) => {
    return {
      ...((history.location.state as any) ?? {}),
      startTime: params.startTime?.valueOf(),
      durationHours: params.durationHours,
    };
  };

  if (!history.location.state?.selectedDate) {
    const baseURL = url.split("/")[1];
    if (baseURL === "scheduler-details") {
      return <Redirect to={`/${baseURL}/${nurseUUID}/assign-patients`} />;
    } else {
      return <Redirect to={`/${baseURL}/assign-patients`} />;
    }
  }

  const validateStartTime: (value: moment.Moment | undefined) => string | undefined = (value) => {
    if (!value) {
      return "Please select start time";
    } else if (!nurseStartMoment || !nurseEndMoment) {
      return undefined;
    }
    // else if (value.isBefore(nurseStartMoment)) {
    //   return `Start time can not be before the nurse's start time (${nurseStartMoment.format("hh:mm A")})`;
    // } else if (value.clone().set("second", 0).set("millisecond", 0).isAfter(nurseEndMoment)) {
    //   return `Start time can not be after the nurse's end time (${nurseEndMoment.format("hh:mm A")})`;
    // }
    return undefined;
  };

  const onCloseClicked = () => {
    const baseURL = url.split("/")[1];
    const values = form.getFieldsValue();
    if (baseURL === "scheduler-details") {
      history.replace(
        `/${baseURL}/${nurseUUID}`,
        getHistoryState({ durationHours: values.durationHours, startTime: values.startTime })
      );
    } else {
      history.replace(`/${baseURL}`, getHistoryState({ durationHours: values.durationHours, startTime: values.startTime }));
    }
  };

  const onBackClicked = () => {
    const values = form.getFieldsValue();
    const baseURL = url.split("/")[1];
    if (baseURL === "scheduler-details") {
      history.replace(
        `/${baseURL}/${nurseUUID}/assign-date`,
        getHistoryState({ durationHours: values.durationHours, startTime: values.startTime })
      );
    } else {
      history.replace(
        `/${baseURL}/assign-date`,
        getHistoryState({ durationHours: values.durationHours, startTime: values.startTime })
      );
    }
  };

  return (
    <Modal
      className="scheduler-modal assign-start-end-time-modal"
      centered
      title="Select start time"
      open={true}
      maskClosable={false}
      footer={null}
      onCancel={onCloseClicked}
    >
      <div
        style={{
          display: "flex",
          gap: "3px",
          alignItems: "center",
          paddingLeft: "24px",
          paddingRight: "24px",
          paddingTop: "24px",
        }}
      >
        <p
          style={{
            margin: "0px",
            width: "41%",
            maxWidth: "41%",
          }}
        >
          <span style={{ color: "#ff4d4f", fontSize: "14px" }}> * </span>Please select start time
        </p>
        <div
          style={{
            width: "58%",
            maxWidth: "58%",
          }}
        >
          <TimePicker
            className="start-time-picker"
            label=""
            value={selectedStartTime}
            minutesStep={30}
            onChange={(value) => {
              console.log("--- TimePicker", value?.valueOf());
              setSelectedStartTime(value ?? undefined);
              form.setFieldValue("durationHours", undefined);
              setSelectedHoursDuration(undefined);
            }}
            renderInput={(params) => <TextField {...params} />}
          />
        </div>
      </div>
      <Form
        form={form}
        size="large"
        colon={false}
        onFinish={handleFormFinished}
        wrapperCol={{ span: 14 }}
        labelCol={{ span: 10 }}
        initialValues={{
          startTime: history.location.state?.startTime ? moment(history.location.state?.startTime) : undefined,
          durationHours: history.location.state?.durationHours,
        }}
      >
        <div
          style={{
            padding: "24px",
          }}
        >
          {/* <Form.Item
            name="startTime"
            label="Start Time"
            required={true}
            rules={[
              // {
              //   required: true,
              //   message: "Please select start time",
              // },
              () => ({
                validator: (_, value: moment.Moment) => {
                  // const formValues = form.getFieldsValue();
                  const error = validateStartTime(value);
                  if (error) {
                    return Promise.reject(error);
                  }
                  return Promise.resolve();
                },
              }),
            ]}
          >
            <TimePicker
              format="hh:mm A"
              placeholder=""
              minuteStep={30}
              hideDisabledOptions={true}
              showSecond={false}
              // disabledTime={(time) => {
              //   return {
              //     disabledHours: () => disabledHours,
              //     disabledMinutes: (hour: number) => {
              //       if (hour > maxStartTimeHour - 1) {
              //         return [0, 30];
              //       } else if (hour === maxStartTimeHour - 1) {
              //         return [30];
              //       }
              //       return [];
              //     },
              //   };
              // }}
              onChange={(value: moment.Moment | null) => {
                setSelectedStartTime(value ?? undefined);
                form.setFieldValue("durationHours", undefined);
                setSelectedHoursDuration(undefined);
              }}
            />
          </Form.Item> */}
          <Form.Item
            name="durationHours"
            label="For how many hours ?"
            rules={[
              {
                required: true,
                message: "Please select booking duration",
              },
            ]}
          >
            <Select
              disabled={!selectedStartTime}
              showSearch
              placeholder=""
              options={durationOptions}
              onChange={(value: number | undefined) => {
                setSelectedHoursDuration(value);
              }}
            />
          </Form.Item>
          {/* <Form.Item
            name="endTime"
            label="End Time"
            required={true}
            rules={[
              // {
              //   required: true,
              //   message: "Please select end time",
              // },
              () => ({
                validator: (_, value: moment.Moment) => {
                  // console.log("--- form values", form.getFieldsValue());
                  const formValues = form.getFieldsValue();
                  if (!value) {
                    return Promise.reject("Please select start time");
                  } else if (!nurseEndMoment || !nurseStartMoment) {
                    return Promise.resolve();
                  } else if (value.isBefore(nurseStartMoment)) {
                    return Promise.reject(
                      `End time can not be before the nurse's start time (${nurseStartMoment.format("hh:mm A")})`
                    );
                  } else if (value.clone().set("second", 0).set("millisecond", 0).isAfter(nurseEndMoment)) {
                    // console.log("---- ", value.valueOf());
                    // console.log("---- ", nurseEndMoment.valueOf());
                    // console.log("---- ", value.isAfter(nurseEndMoment));
                    return Promise.reject(`End time can not be after the nurse's end time (${nurseEndMoment.format("hh:mm A")})`);
                  } else if (formValues.startTime) {
                    const fourHoursInMilliseconds = 4 * 60 * 60 * 1000;
                    const difference = Math.abs(value.diff(formValues.startTime));
                    if (difference < fourHoursInMilliseconds) {
                      return Promise.reject("Minimum time for a booking is 4 hours");
                    }
                  }
                  return Promise.resolve();
                },
              }),
            ]}
          >
            <TimePicker
              format="hh:mm a"
              placeholder=""
              minuteStep={30}
              showSecond={false}
              onChange={(value: moment.Moment | null) => {
                // console.log("--- end time", form.getFieldsValue());
                if (!value) return;
                const formValues = form.getFieldsValue();
                if (!formValues?.startTime) return;
                if (value.isSameOrBefore(formValues?.startTime)) {
                  form.setFieldsValue({ ...formValues, startTime: undefined });
                }
                form.validateFields();
              }}
            />
          </Form.Item> */}
          {selectedHoursDuration && selectedStartTime && !validateStartTime(selectedStartTime) && (
            <Form.Item>
              <p>End Time: {selectedStartTime.clone().add(selectedHoursDuration, "hour").format("hh:mm A")}</p>
            </Form.Item>
          )}
        </div>
        <Divider
          style={{
            margin: "0px",
          }}
        />
        <div
          style={{
            paddingLeft: "24px",
            paddingRight: "24px",
            paddingBottom: "12px",
            paddingTop: "12px",
            display: "flex",
            justifyContent: "flex-end",
            alignContent: "center",
            gap: "1rem",
          }}
        >
          <Button onClick={onBackClicked} type="default">
            Back
          </Button>
          <Button type="primary" htmlType="submit">
            Continue
          </Button>
        </div>
      </Form>
    </Modal>
  );
};

export default AssignStartEndTimeModal;
