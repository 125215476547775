import { Button, Dialog, DialogActions, DialogContent, IconButton } from "@mui/material";
import { FC, useCallback, useState } from "react";
import { Document, Page } from "react-pdf";
import ArrowCircleDownRoundedIcon from "@mui/icons-material/ArrowCircleDownRounded";
import ArrowBackIosNewRoundedIcon from "@mui/icons-material/ArrowBackIosNewRounded";
import ArrowForwardIosRoundedIcon from "@mui/icons-material/ArrowForwardIosRounded";
import "react-pdf/dist/Page/AnnotationLayer.css";
import "react-pdf/dist/Page/TextLayer.css";

// pdfjs.GlobalWorkerOptions.workerSrc = new URL("pdfjs-dist/build/pdf.worker.min.js", import.meta.url).toString();

const ViewPrescriptionButton: FC<ViewPrescriptionButtonProps> = ({ index, prescriptionURL }) => {
  const [open, setOpen] = useState(false);
  const [numberOfPages, setNumberOfPages] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);

  const handleNextPageClicked = useCallback(() => {
    if (currentPage >= numberOfPages) return;
    setCurrentPage((value) => (value += 1));
  }, [currentPage, numberOfPages]);

  const handlePreviousPageClicked = useCallback(() => {
    if (currentPage <= 1) return;
    setCurrentPage((value) => (value -= 1));
  }, [currentPage]);

  return (
    <>
      <Button variant="outlined" onClick={() => setOpen(true)}>
        View prescription {index + 1}
      </Button>
      <Dialog
        open={open}
        onClose={() => setOpen(false)}
        maxWidth="sm"
        sx={{
          "& .MuiPaper-root": {
            width: "100%",
            height: "100%",
          },
        }}
        aria-labelledby="view-prescription-button-dialog"
        aria-describedby="view-prescription-button-dialog-description"
      >
        <DialogContent sx={{ padding: "0px", height: "100%", display: "flex", justifyContent: "center", alignItems: "center" }}>
          <Document
            className={"view-prescription-document"}
            file={prescriptionURL}
            onLoadSuccess={(doc) => {
              setNumberOfPages(doc.numPages);
            }}
          >
            <Page pageNumber={currentPage} />
          </Document>
        </DialogContent>
        <DialogActions sx={{ gap: "0px", justifyContent: "space-between" }}>
          <div style={{ display: "flex", alignItems: "center", gap: "8px" }}>
            <IconButton disabled={numberOfPages <= 1} onClick={handlePreviousPageClicked}>
              <ArrowBackIosNewRoundedIcon sx={{ fontSize: "16px" }} />
            </IconButton>
            <IconButton disabled={currentPage >= numberOfPages} onClick={handleNextPageClicked}>
              <ArrowForwardIosRoundedIcon sx={{ fontSize: "16px" }} />
            </IconButton>
          </div>
          <div style={{ display: "flex", gap: "16px" }}>
            <Button variant="outlined" onClick={() => setOpen(false)}>
              Close
            </Button>
            <a
              href={prescriptionURL}
              target="_blank"
              onClick={(e) => {
                e.preventDefault();
                window.open(prescriptionURL, "_blank");
              }}
            >
              <Button startIcon={<ArrowCircleDownRoundedIcon />} className="primary" variant="outlined">
                Download
              </Button>
            </a>
          </div>
        </DialogActions>
      </Dialog>
    </>
  );
};

interface ViewPrescriptionButtonProps {
  prescriptionURL: string;
  index: number;
}

export default ViewPrescriptionButton;
