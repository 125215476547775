import { FC } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Col, Row } from "antd";
import { LeftOutlined, RightOutlined } from "@ant-design/icons";
import { setSelectedDayAction } from "../../actions/schedulerExploreViewActions";
import ReduxStateModel from "../../models/ReduxStateModel";
import moment from "moment";

const DaySelector: FC<DaySelectorProps> = () => {
  const dispatch = useDispatch();
  const selectedDay: moment.Moment = useSelector(
    (state: ReduxStateModel) => state?.schedulerExploreViewReducer?.selectedDay ?? moment()
  );

  const onPrevDayClicked = () => {
    const prevDay = selectedDay.clone().subtract(1, "day");
    dispatch(setSelectedDayAction(prevDay));
  };

  const onNextDayClicked = () => {
    const nextDay = selectedDay.clone().add(1, "day");
    dispatch(setSelectedDayAction(nextDay));
  };

  return (
    <Row className="day-selector">
      <Col>
        <Button className="prev-day-btn" onClick={onPrevDayClicked}>
          <LeftOutlined />
        </Button>
      </Col>
      <div className="day-date">{selectedDay.format("dddd, Do MMMM YYYY")}</div>
      <Col>
        <Button className="next-day-btn" onClick={onNextDayClicked}>
          <RightOutlined />
        </Button>
      </Col>
    </Row>
  );
};

interface DaySelectorProps {}

export default DaySelector;
