import { FC } from "react";
import { Spin } from "antd";
import BookingsTableProps from "../../components/BookingsTable/BookingsTableProps";
import useBookingsList from "../../hooks/useBookingsList";
import MobileBookingListItem from "./MobileBookingListItem";

const MobileBookingsList: FC<MobileBookingsListProps> = ({
  bookings,
  inViewRes,
  loadingNextPage,
  loading,
  handleInvalidateQueries,
  onAssignNurseSuccess,
}) => {
  const { filteredBookings } = useBookingsList({ bookings: bookings });

  if (!filteredBookings?.length && !loading && !loadingNextPage) {
    return <div style={{ textAlign: "center" }}>No bookings</div>;
  }

  return (
    <>
      {filteredBookings?.map((booking) => {
        return (
          <MobileBookingListItem
            key={booking.orderUUID}
            booking={booking}
            handleInvalidateQueries={handleInvalidateQueries}
            onAssignNurseSuccess={onAssignNurseSuccess}
          />
        );
      })}
      <div
        ref={inViewRes.ref}
        style={{
          width: "100%",
          height: "100%",
          top: "0",
          left: "0",
          display: "flex",
          justifyContent: "center",
          padding: "2rem",
        }}
      >
        <Spin spinning={loadingNextPage || loading} />
      </div>
    </>
  );
};

interface MobileBookingsListProps extends BookingsTableProps {}

export default MobileBookingsList;
