import { Button, Dialog, DialogActions, DialogTitle, ListItemButton, ListItemIcon, ListItemText } from "@mui/material";
import { FC, useState } from "react";
import LogoutIcon from "@mui/icons-material/Logout";
import useLogout from "../../hooks/useLogout";

const LogoutListItemButton: FC<LogoutListItemButtonProps> = () => {
  const [open, setOpen] = useState(false);
  const { logout } = useLogout({});

  return (
    <>
      <ListItemButton onClick={() => setOpen(true)}>
        <ListItemIcon>
          <LogoutIcon />
        </ListItemIcon>
        <ListItemText primary={"Logout"} />
      </ListItemButton>
      <Dialog
        open={open}
        onClose={() => setOpen(false)}
        sx={{
          "& .MuiPaper-root": {
            borderRadius: "8px",
          },
        }}
      >
        <DialogTitle>Are you sure you would like to logout ?</DialogTitle>
        <DialogActions>
          <Button variant="outlined" onClick={() => setOpen(false)}>
            Cancel
          </Button>
          <Button variant="outlined" onClick={logout}>
            Yes, logout
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

interface LogoutListItemButtonProps {}

export default LogoutListItemButton;
