import { useQuery } from "@tanstack/react-query";
import { userServices } from "../services";
import { useMemo } from "react";

function useGetUserIDImage({ patientID }: { patientID?: string }) {
  const queryKey = useMemo(() => {
    return ["patients/getimagesforaparticularpatient", patientID];
  }, [patientID]);

  const query = useQuery(
    queryKey,
    () => userServices.getUserIDImage(patientID!),
    {
      enabled: !!patientID,
    }
  );

  return { query, queryKey };
}

export default useGetUserIDImage;
