import { Col, DatePicker, Dropdown, Row, Space } from "antd";
import { FC, useMemo, useState } from "react";
import { DownOutlined } from "@ant-design/icons";
import { FilterPeriodKeysType } from ".";
import TractionHeaderItem from "./TractionHeaderItem";
import useTractionHeadersQuery from "../../hooks/useTractionHeadersQuery";
import moment from "moment";
import DownloadExcelButton from "./DownloadTractionExcelButton";
import { getStartEndDatesFromSelectedPeriod } from "../OperationAnalytics/HomeNursingPeriodDropdown";
import { tractionTableFilterOptions } from "../../constants";

const TractionHeader: FC<TractionHeaderProps> = ({
  selectedPeriod,
  selectedDateRange,
  setSelectedDateRange,
  setSelectedPeriod,
}) => {
  const now = moment();
  const [openDropdown, setOpenDropdown] = useState<boolean>(false);

  const startAndEndDatesOfSelectedPeriod = useMemo(() => {
    return getStartEndDatesFromSelectedPeriod(selectedPeriod, selectedDateRange);
  }, [selectedDateRange, selectedPeriod]);

  const { query: headerQuery } = useTractionHeadersQuery(selectedPeriod, selectedDateRange);
  const { data } = headerQuery;

  let dropdownContent: JSX.Element;
  if (selectedDateRange) {
    dropdownContent = (
      <div>
        {startAndEndDatesOfSelectedPeriod[0].format("DD MMMM")} - {startAndEndDatesOfSelectedPeriod[1].format("DD MMMM")}
      </div>
    );
  } else if (selectedPeriod === "current-month") {
    dropdownContent = <div>Current month ({startAndEndDatesOfSelectedPeriod[0].format("MMMM")})</div>;
  } else if (selectedPeriod === "previous-month") {
    dropdownContent = <div>Previous month ({startAndEndDatesOfSelectedPeriod[0].format("MMMM")})</div>;
  } else if (selectedPeriod === "last-3-month" || selectedDateRange) {
    dropdownContent = (
      <div>
        Last 3 month ({startAndEndDatesOfSelectedPeriod[0].format("DD MMMM")} -{" "}
        {startAndEndDatesOfSelectedPeriod[1].format("DD MMMM")})
      </div>
    );
  } else {
    dropdownContent = (
      <div>
        {startAndEndDatesOfSelectedPeriod[0].format("DD MMMM")} - {startAndEndDatesOfSelectedPeriod[1].format("DD MMMM")}
      </div>
    );
  }

  return (
    <Row className="traction-header-container">
      <Col span={24}>
        <Row
          style={{
            borderBottom: "1px solid #edf1f4",
            width: "100%",
            padding: "16px 16px",
          }}
        >
          <Col span={24}>
            <Row className="traction-header-container-row">
              <Col xs={24} sm={10} md={8} lg={6}>
                <h1 className="page-title">Revenue Tracker</h1>
              </Col>
              <Col xs={24} sm={13} md={12} lg={12}>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                    alignItems: "center",
                    gap: "16px",
                  }}
                >
                  <DownloadExcelButton selectedPeriod={selectedPeriod} selectedDateRange={selectedDateRange} />
                  <Dropdown
                    open={openDropdown}
                    onOpenChange={(value) => {
                      setOpenDropdown(value);
                    }}
                    menu={{
                      items: tractionTableFilterOptions.map((item) => {
                        if (item.key === "select-range") {
                          return {
                            key: item.key,
                            label: (
                              <DatePicker.RangePicker
                                disabledDate={(date) => {
                                  return date.isAfter(now);
                                }}
                                value={selectedDateRange}
                                onChange={(e) => {
                                  if (!e) {
                                    setSelectedDateRange(undefined);
                                  } else if (e[0] && e[1]) {
                                    setOpenDropdown(false);
                                    setSelectedDateRange([e[0]?.clone(), e[1]?.clone()]);
                                  } else {
                                    setSelectedDateRange(undefined);
                                  }
                                }}
                              />
                            ),
                          };
                        }
                        return {
                          key: item.key,
                          label: <div>{item.label}</div>,
                          onClick: (e) => {
                            setSelectedDateRange(undefined);
                            setOpenDropdown(false);
                            setSelectedPeriod(e.key as FilterPeriodKeysType);
                          },
                        };
                      }),
                    }}
                    trigger={["click"]}
                  >
                    <div>
                      <Space>
                        <div style={{ textTransform: "capitalize" }}>{dropdownContent}</div>
                        <DownOutlined />
                      </Space>
                    </div>
                  </Dropdown>
                </div>
              </Col>
            </Row>
          </Col>
          <Col span={24}>
            <p className="page-subtitle">Get insights on all transactions</p>
          </Col>
        </Row>
      </Col>
      <Col span={24}>
        <div className="traction-header">
          {data?.data &&
            data.data.map((item) => {
              return (
                <TractionHeaderItem
                  key={item.name}
                  title={item.name ?? ""}
                  subtitle={`AED ${
                    item.price?.toLocaleString(undefined, {
                      maximumFractionDigits: 1,
                    }) ?? "0"
                  }`}
                  difference={item.differencePercentage}
                  bottom={`${item.amount ?? ""} ${item.unit ?? ""}`}
                />
              );
            })}
        </div>
      </Col>
    </Row>
  );
};

interface TractionHeaderProps {
  selectedPeriod: FilterPeriodKeysType;
  selectedDateRange?: [moment.Moment, moment.Moment];
  setSelectedDateRange: React.Dispatch<React.SetStateAction<[moment.Moment, moment.Moment] | undefined>>;
  setSelectedPeriod: React.Dispatch<React.SetStateAction<FilterPeriodKeysType>>;
}

export default TractionHeader;
