import { useMutation } from "@tanstack/react-query";
import { useState, useEffect, useMemo, useCallback } from "react";
import { UploadBookingReportFormProps, UploadBookingReportResponseModel } from "../components/common/UploadBookingReportForm";
import { showMessage } from "../components/common/notification";
import { orderServices } from "../services";
import convertFileToBase64 from "../utils/convertFileToBase64";
import UploadFilePayloadModel from "../models/UploadFilePayloadModel";
import useProductDetails from "./useProductDetails";
import useBiomarkersListQuery from "./useBiomarkersListQuery";

function useUploadBookingReportForm({ booking, onCancelClicked, onReportUploadedSuccess }: UploadBookingReportFormProps) {
  const [pdfFile, setPDFFile] = useState<File>();
  const [selectedTests, setSelectedTests] = useState<string[]>([]);
  const [selectedPatiet, setSelectedPatient] = useState<string | undefined>(() => {
    if (booking.patient?.length === 1) return booking.patient[0]._id;
  });

  const submitReportMutation = useMutation({ mutationFn: orderServices.orderResult });

  useEffect(() => {
    if (booking.patient?.length === 1) {
      setSelectedPatient(booking.patient[0]._id);
    }
  }, [booking.patient]);

  const { query: productDetailsQuery } = useProductDetails({
    area: booking.emirate,
    productUUID: booking.patientId?.[0]?.productUUID,
  });

  const disableUploadButton = useMemo<boolean>(() => {
    if (!pdfFile) return true;
    if (!selectedTests.length) return true;
    if (!selectedPatiet) return true;
    return false;
  }, [pdfFile, selectedPatiet, selectedTests.length]);

  const handleUploadClicked = useCallback(async () => {
    const patientFromBooking = booking.patientId?.find((patient) => patient.id === selectedPatiet);
    const reportBase64 = await convertFileToBase64(pdfFile);
    const reportData: UploadFilePayloadModel = {
      data: reportBase64?.split("base64,")[1] ?? "",
      fileName: `${new Date().toISOString()}__${pdfFile?.name ?? ""}`,
      type: pdfFile?.type ?? "",
    };
    const res: { data: UploadBookingReportResponseModel } = await submitReportMutation.mutateAsync({
      reportingOrder: booking.orderUUID,
      reportingPatient: patientFromBooking?.id,
      reportingPriceId: patientFromBooking?.priceId,
      isDarLabProduct: productDetailsQuery.data?.product?.isDarLabProduct ?? false,
      reports: [reportData],
      selectedTests: selectedTests,
    });
    if (res.data.order?.orderUUID) {
      onReportUploadedSuccess(res.data.order);
      onCancelClicked();
      showMessage("success", "Report uploaded successfully");
    } else {
      showMessage("error", "Something went wrong");
    }
  }, [
    booking.orderUUID,
    booking.patientId,
    onCancelClicked,
    onReportUploadedSuccess,
    pdfFile,
    productDetailsQuery.data?.product?.isDarLabProduct,
    selectedPatiet,
    selectedTests,
    submitReportMutation,
  ]);

  // const { query: buildYourLabTestProductQuery } = useProductDetails({
  //   productUUID: buildYourLabTestProductUUID,
  //   area: booking.emirate,
  // });

  const { query: biomarkersListQuery } = useBiomarkersListQuery();

  const testsOptionsList = useMemo<string[]>(() => {
    const value = productDetailsQuery.data?.product?.testsIncluded ?? [];
    if (booking.customizations?.length) {
      const addedCustomizations = booking.customizations.map<string>((c) => c.engName ?? "");
      const buildYourLabTestAddons = biomarkersListQuery.data?.addOns ?? [];
      const addonsList = buildYourLabTestAddons.filter((addon) => addedCustomizations.includes(addon.engName ?? ""));
      value.push(
        ...addonsList.map<string>((addon) => {
          return addon.engName ?? "";
        })
      );
      const productCustomizations =
        productDetailsQuery.data?.product?.customizations
          ?.map<string>((customization) => customization.engName ?? "")
          .filter((customization) => {
            return addedCustomizations.includes(customization) && customization !== "Lab Results Consultation";
          }) ?? [];
      value.push(...productCustomizations);
    }
    return Array.from<string>(new Set<string>(value));
  }, [
    biomarkersListQuery.data?.addOns,
    booking.customizations,
    productDetailsQuery.data?.product?.customizations,
    productDetailsQuery.data?.product?.testsIncluded,
  ]);

  const onPatientChanged = useCallback((patientID: string) => {
    setSelectedPatient(patientID);
  }, []);

  const didSelectAllTests = useMemo(() => {
    const selectedTestsMap = new Map<string, boolean>();
    for (const test of selectedTests) {
      selectedTestsMap.set(test, true);
    }
    return testsOptionsList?.every((test) => selectedTestsMap.get(test)) ?? false;
  }, [selectedTests, testsOptionsList]);

  const handleSelectAllTestsClicked = useCallback(() => {
    if (didSelectAllTests) {
      setSelectedTests([]);
    } else {
      setSelectedTests(testsOptionsList);
    }
  }, [didSelectAllTests, testsOptionsList]);

  return {
    disableUploadButton,
    selectedPatiet,
    didSelectAllTests,
    submitReportMutation,
    selectedTests,
    testsOptionsList,
    pdfFile,
    setSelectedTests,
    setPDFFile,
    handleSelectAllTestsClicked,
    handleUploadClicked,
    onPatientChanged,
  };
}

export default useUploadBookingReportForm;
