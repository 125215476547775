import moment from "moment";
import useAutomatedDriverScheduleQuery from "../../hooks/useAutomatedDriverScheduleQuery";
import { FC, useEffect, useMemo, useState } from "react";
import { generateRangeArray } from "../../utils/utilFunctions";
import { Button, DatePicker, Modal, Popover, Spin } from "antd";
import { colors } from "../../constants";

const dateFormat = "DD/MM/YYYY";
const timeFormat = "hh:mmA";
const dayViewBookingHeight = 40;
const minTableRowHeight = 45;

const TimelineView: FC<TableViewProps> = ({
  driversList,
  handleCopyGeneratedScheduleClicked,
  selectedDate,
  setSelectedDate,
  showCopyScheduleButton,
  showDatePicker,
}) => {
  const [tableElements, setTableElements] = useState<JSX.Element[] | undefined>();
  const [detailsModalText, setDetailsModalText] = useState<string | undefined>();

  const day = useMemo(() => selectedDate.clone().startOf("day"), [selectedDate]);

  const {
    query: { data: generatedScheduleData, isLoading: generatedScheduleIsLoading },
  } = useAutomatedDriverScheduleQuery({
    selectedDate,
  });

  const firstBookingOfDayMoment = useMemo<moment.Moment>(() => {
    let m = day.clone().endOf("day");
    for (const key of Object.keys(generatedScheduleData ?? {})) {
      // const bookings = generatedScheduleData![key].filter((booking) => {
      //   const timeMoment = moment(booking.time, "hh:mmA");
      //   return timeMoment.format("DD/MM/YYYY") === day.format("DD/MM/YYYY");
      // });
      for (const booking of generatedScheduleData![key]) {
        const timeMoment = moment(`${day.format("DD/MM/YYYY")} ${booking.time}`, "DD/MM/YYYY hh:mmA");
        if (timeMoment.isBefore(m)) {
          m = timeMoment.clone();
        }
      }
    }
    return m;
  }, [day, generatedScheduleData]);

  const dayHoursArray = useMemo<number[]>(() => {
    const differenceToEODInHours = moment.duration(day.clone().endOf("day").diff(firstBookingOfDayMoment)).asHours();
    if (differenceToEODInHours < 4) {
      return generateRangeArray({ start: 0, end: 23 });
    }
    return generateRangeArray({ start: Math.max(firstBookingOfDayMoment.get("hour"), 0), end: 23 });
  }, [day, firstBookingOfDayMoment]);

  // const dayHoursArray = useMemo<number[]>(() => {
  //   return generateRangeArray({ start: 0, end: 23 });
  // }, []);

  useEffect(() => {
    const tableViewContainerElement = document.getElementById("driver-schedule-day-view");
    if (!tableViewContainerElement) return;
    const elements: JSX.Element[] = [];
    for (const driverName of Object.keys(generatedScheduleData ?? {})) {
      const addedTasksPickAndDropTimes: [moment.Moment, moment.Moment, number][] = [];
      const driverTableRow = document.getElementById(`driver-row-${driverName.toLowerCase()}-${day.format()}`);
      const driverTasks = generatedScheduleData![driverName] ?? [];
      let maximumNumberOfIntersectingTasksForDriver = 0;
      for (let i = 0; i < driverTasks.length; i += 1) {
        const driverTask = driverTasks[i];
        if (driverTask.type === "delivery") continue;
        const taskPickupMoment = moment(`${day.format(dateFormat)} ${driverTask.time}`, `${dateFormat} ${timeFormat}`);
        const startCellID = `driver-day-table-cell-${driverName.toLowerCase()}_${taskPickupMoment
          .clone()
          .set("minute", 0)
          .set("second", 0)
          .set("millisecond", 0)
          .format()}`;
        const startCellElement = document.getElementById(startCellID);
        const dropTask = driverTasks.slice(i + 1).find((t) => {
          return (
            t.driverName === driverName &&
            t.bookingUUID === driverTask.bookingUUID &&
            t.nurseName === driverTask.nurseName &&
            t.type === "delivery"
          );
        }); // driverTasksMap.get(`${driverName}-${driverTask.bookingUUID}-${driverTask.nurseName}-delivery`);
        const taskDropMoment = moment(`${day.format(dateFormat)} ${dropTask?.time}`, `${dateFormat} ${timeFormat}`);
        const endCellID = `driver-day-table-cell-${driverName.toLowerCase()}_${taskDropMoment
          .clone()
          .set("minute", 0)
          .set("second", 0)
          .set("millisecond", 0)
          .format()}`;
        const endCellElement = document.getElementById(endCellID);
        if (!startCellElement || !endCellElement) {
          console.log("--- no element for ");
          console.log(startCellID);
          console.log(endCellID);
          continue;
        }
        const containerRect = tableViewContainerElement.getBoundingClientRect();
        const startCellRect = startCellElement.getBoundingClientRect();
        const startCellLeftOffset = startCellRect.width * (taskPickupMoment.minute() / 60);
        const topOffset = 10;
        const initialTopOffset = startCellRect.top + topOffset - containerRect.top + tableViewContainerElement.scrollTop;
        const startLeft =
          startCellRect.left + startCellLeftOffset - containerRect.left + tableViewContainerElement.scrollLeft - 2;
        const endCellRect = endCellElement?.getBoundingClientRect();
        const endCellLeftOffset = (endCellRect?.width ?? 0) * (taskDropMoment.minute() / 60);
        const endLeft = (endCellRect?.left ?? 0) + endCellLeftOffset - containerRect.left + tableViewContainerElement.scrollLeft;
        const width = endLeft - startLeft - 2;
        const tasksIntersectingWithThisTask = addedTasksPickAndDropTimes.filter(([pickupMoment, dropMoment]) => {
          const intersectingAtStart = taskPickupMoment.isAfter(pickupMoment) && taskPickupMoment.isBefore(dropMoment);
          const intersectingAtEnd = taskDropMoment.isAfter(pickupMoment) && taskDropMoment.isBefore(dropMoment);
          return intersectingAtStart || intersectingAtEnd;
        });
        const numberOfTasksIntersectingWithThisTask = tasksIntersectingWithThisTask.length;
        if (numberOfTasksIntersectingWithThisTask > maximumNumberOfIntersectingTasksForDriver) {
          maximumNumberOfIntersectingTasksForDriver = numberOfTasksIntersectingWithThisTask;
        }
        let top: number;
        if (numberOfTasksIntersectingWithThisTask) {
          top = tasksIntersectingWithThisTask[numberOfTasksIntersectingWithThisTask - 1][2] + dayViewBookingHeight + 5;
        } else {
          top = initialTopOffset + 5;
        }
        addedTasksPickAndDropTimes.push([taskPickupMoment, taskDropMoment, top]);
        elements.push(
          <Popover
            key={`${driverName}-${day.format(dateFormat)}-${driverTask.time}-${driverTask.bookingUUID}-${driverTask.type}`}
            title={`${driverName.toUpperCase()} pickup ${driverTask.nurseName} at ${driverTask.time} and drop them at ${
              dropTask?.time
            }`}
            placement="bottom"
          >
            <div
              key={`${driverName}-${day.format(dateFormat)}-${driverTask.time}-${driverTask.bookingUUID}-${driverTask.type}`}
              style={{
                zIndex: "2",
                position: "absolute",
                width: `${width}px`,
                height: `${dayViewBookingHeight}px`,
                minHeight: `${dayViewBookingHeight}px`,
                top: `${top}px`,
                left: `${startLeft}px`,
                backgroundColor: `${colors[i % colors.length]}80`,
                borderLeft: `2px solid ${colors[i % colors.length]}`,
                padding: "4px 4px",
                fontSize: "0.7rem",
                textAlign: "start",
                display: "flex",
                flexDirection: "column",
                gap: "0.3rem",
                color: "white",
                overflow: "hidden",
              }}
            />
          </Popover>
        );
      }
      if (driverTableRow) {
        const height = `${Math.max(
          minTableRowHeight,
          (maximumNumberOfIntersectingTasksForDriver + 1) * dayViewBookingHeight + 40
        )}px`;
        driverTableRow.style.height = height;
        driverTableRow.style.minHeight = height;
      }
    }
    setTableElements(elements);
  }, [day, generatedScheduleData]);

  return (
    <>
      <div
        style={{
          marginBottom: "16px",
          display: "flex",
          gap: "16px",
          justifyContent: "space-between",
          flexWrap: "wrap",
        }}
      >
        {(showCopyScheduleButton ?? true) && (
          <div
            style={{
              display: "flex",
              gap: "16px",
            }}
          >
            <Button
              style={{ borderRadius: "8px" }}
              type="primary"
              loading={generatedScheduleIsLoading}
              onClick={handleCopyGeneratedScheduleClicked}
            >
              Copy Auto Generated Schedule
            </Button>
          </div>
        )}
        {(showDatePicker ?? true) && (
          <div
            style={{
              display: "flex",
              gap: "16px",
            }}
          >
            <DatePicker
              style={{ width: "200px", borderRadius: "8px" }}
              allowClear={false}
              format="DD-MM-YYYY"
              value={selectedDate}
              onChange={(value) => {
                if (!value) return;
                setSelectedDate(value);
              }}
            />
          </div>
        )}
      </div>
      <div className="driver-schedule-day-view dd-table explore-view-table" id="driver-schedule-day-view">
        {tableElements}
        <Spin spinning={generatedScheduleIsLoading}>
          <div className="table-body" id="explore-view-table-body day-view-">
            <div className="table-row header">
              <div className="cell table-col-1">Drivers List</div>
              {dayHoursArray.map<JSX.Element>((i) => {
                const moment = day.clone().add(i, "hour");
                return (
                  <div key={moment.valueOf()} className="cell">
                    {moment.format("hh A")}
                  </div>
                );
              })}
            </div>
            {driversList.map<JSX.Element>((driverName) => {
              return (
                <div
                  key={`${driverName}_${day.format()}`}
                  className="table-row"
                  id={`driver-row-${driverName.toLowerCase()}-${day.format()}`}
                  style={{ minHeight: `${minTableRowHeight}px` }}
                >
                  <div
                    className="cell table-col-1"
                    style={{ display: "flex", justifyContent: "center", alignItems: "center", minHeight: "70px" }}
                  >
                    <div className="driver-table-leading-cell">
                      <div className="details">
                        <div className="name">{driverName}</div>
                      </div>
                    </div>
                  </div>
                  {dayHoursArray.map<JSX.Element>((hour) => {
                    const cellMoment = day.clone().add(hour, "hour");
                    const key = `driver-day-table-cell-${driverName.toLowerCase()}_${cellMoment.format()}`;
                    return (
                      <div
                        key={key}
                        className="cell empty-cell"
                        id={key}
                        style={{ justifyContent: "space-between", minHeight: "70px" }}
                      >
                        <div style={{ height: "100%", width: "1px" }} />
                        <div style={{ height: "100%", width: "1px", backgroundColor: "#ddd" }} />
                        <div style={{ height: "100%", width: "1px", backgroundColor: "#ddd" }} />
                        <div style={{ height: "100%", width: "1px", backgroundColor: "#ddd" }} />
                        <div style={{ height: "100%", width: "1px" }} />
                      </div>
                    );
                  })}
                </div>
              );
            })}
          </div>
        </Spin>
        <Modal
          open={!!detailsModalText}
          centered={true}
          bodyStyle={{ display: "none" }}
          cancelButtonProps={{ style: { display: "none" } }}
          onCancel={() => setDetailsModalText(undefined)}
          onOk={() => setDetailsModalText(undefined)}
          title={detailsModalText}
        />
      </div>
    </>
  );
};

interface TableViewProps {
  driversList: string[];
  selectedDate: moment.Moment;
  showDatePicker?: boolean;
  showCopyScheduleButton?: boolean;
  setSelectedDate: React.Dispatch<React.SetStateAction<moment.Moment>>;
  handleCopyGeneratedScheduleClicked: () => void;
}

export default TimelineView;
