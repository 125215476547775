import { PartnerUserModel, PartnerUserRoleType } from "../models/PartnerUserModel";
import BaseResponseModel from "../models/BaseResponseModel";
import PartnerSettingsModel from "../models/PartnerSettingsModel";
import axios from "../utils/axiosHelpers";
import decrypt from "./decrypt";
import encrypt from "./encrypt";
import PartnerUserInviteModel from "../models/PartnerUserInviteModel";
import Mixpanel from "../utils/Mixpanel";
import { PartnerModel } from "../models/PartnerModel";
// import encrypt from "./encrypt";

export interface GetAllPartnersResponseModel extends BaseResponseModel {
  partners?: PartnerModel[];
}

export async function getEveryPartners() {
  try {
    const res = await axios.get(`/api/v1/partners`);
    const data: GetAllPartnersResponseModel = decrypt(res?.data);
    return data;
  } catch (e) {
    console.log("--- getEveryPartners error", e);
    return null;
  }
}

export interface GetPartnerUsersResponseModel extends BaseResponseModel {
  users?: PartnerUserModel[];
}

export interface EditPartnerUserResponseModel extends BaseResponseModel {
  user?: PartnerUserModel;
}

export interface GetPartnerUsersInvitesResponseModel extends BaseResponseModel {
  invites?: PartnerUserInviteModel[];
}

export interface GetPartnerUserInvite extends BaseResponseModel {
  invite?: PartnerUserInviteModel;
}

export interface DeletePartnerUserInviteResponseModel extends BaseResponseModel {
  id?: string;
}

export async function getPartnerUserInvite(params: { email: string }) {
  try {
    const res = await axios.get<string>(`/api/v1/partner-users-invite/get-invite/${params.email}`);
    const data: GetPartnerUserInvite = decrypt(res.data);
    return data;
  } catch (error) {
    console.log("--- getPartnerUserInvite error", error);
    return null;
  }
}

export async function deletePartnerUserInvite(params: {
  id: string;
  currentUserID: string;
}): Promise<DeletePartnerUserInviteResponseModel | null> {
  try {
    const res = await axios.post<string>(`/api/v1/partner-users-invite/delete-partner-user-invite`, { data: encrypt(params) });
    const data: DeletePartnerUserInviteResponseModel = decrypt(res.data);
    return data;
  } catch (error) {
    console.log("--- deletePartnerUserInvite error", error);
    return null;
  }
}

export async function getPartnerUsersInvites(params: {
  partnerUUID: string;
}): Promise<GetPartnerUsersInvitesResponseModel | null> {
  try {
    const res = await axios.get<string>(`/api/v1/partner-users-invite/get-user-invites/${params.partnerUUID}`);
    const data: GetPartnerUsersInvitesResponseModel = decrypt(res.data);
    return data;
  } catch (error) {
    console.log("--- getPartnerUsersInvites error", error);
    return null;
  }
}

export async function editPartnerUser(params: {
  name: string;
  email: string;
  role: PartnerUserRoleType;
  partnerUserUUID: string;
  currentUserID: string;
}): Promise<EditPartnerUserResponseModel | null> {
  try {
    const res = await axios.post<string>(`/api/v1/partneraccounts/edit-partner-user`, { data: encrypt(params) });
    const data: EditPartnerUserResponseModel = decrypt(res.data);
    Mixpanel.track("Edit partner user", {
      params: params,
      userName: localStorage.getItem("userName"),
      partnerUserUUID: localStorage.getItem("partnerUserUUID"),
    });
    return data;
  } catch (error) {
    console.log("--- editPartnerUser error", error);
    Mixpanel.track("Edit partner user error", {
      params: params,
      userName: localStorage.getItem("userName"),
      partnerUserUUID: localStorage.getItem("partnerUserUUID"),
    });
    return null;
  }
}

export async function deletePartnerUser(params: {
  partnerUserUUID: string;
  currentUserID: string;
}): Promise<BaseResponseModel | null> {
  try {
    const res = await axios.post<string>(`/api/v1/partneraccounts/delete-partner-user`, { data: encrypt(params) });
    const data: BaseResponseModel = decrypt(res.data);
    Mixpanel.track("Delete partner user", {
      params: params,
      userName: localStorage.getItem("userName"),
      partnerUserUUID: localStorage.getItem("partnerUserUUID"),
    });
    return data;
  } catch (error) {
    console.log("--- deletePartnerUser error", error);
    Mixpanel.track("Delete partner user error", {
      params: params,
      userName: localStorage.getItem("userName"),
      partnerUserUUID: localStorage.getItem("partnerUserUUID"),
    });
    return null;
  }
}

export async function getPartnerUsers(params: { partnerUUID: string }): Promise<GetPartnerUsersResponseModel | null> {
  try {
    const res = await axios.get<string>(`/api/v1/partneraccounts/get-partner-users/${params.partnerUUID}`);
    const data: GetPartnerUsersResponseModel = decrypt(res.data);
    return data;
  } catch (error) {
    console.log("--- getPartnerUsers error", error);
    return null;
  }
}

export async function invitePartnerUsers(params: {
  emails: string[];
  partnerUUID: string;
  role: PartnerUserRoleType;
  currentUserID: string;
}): Promise<BaseResponseModel | null> {
  try {
    const res = await axios.post<string>("/api/v1/partneraccounts/send-invite-email-to-partner-user", { data: encrypt(params) });
    const data: BaseResponseModel = decrypt(res.data);
    Mixpanel.track("Send invite to user", {
      params: params,
      userName: localStorage.getItem("userName"),
      partnerUserUUID: localStorage.getItem("partnerUserUUID"),
    });
    return data;
  } catch (error) {
    console.log("--- invitePartnerUsers error", error);
    console.log();
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const e: any = error;
    if (e?.response?.data) {
      const res: BaseResponseModel = decrypt(e?.response?.data);
      return res;
    }
    Mixpanel.track("Send invite to user error", {
      params: params,
      userName: localStorage.getItem("userName"),
      partnerUserUUID: localStorage.getItem("partnerUserUUID"),
    });
    if (typeof e?.response?.data === "string") {
      const res: BaseResponseModel = decrypt(e.response.data);
      return res;
    }
    return null;
  }
}

export interface GetPartnerDetailsResponseModel extends BaseResponseModel {
  partner?: PartnerSettingsModel;
}

export async function getPartnerDetails({
  partnerUUID,
}: {
  partnerUUID: string;
}): Promise<GetPartnerDetailsResponseModel | null> {
  try {
    const res = await axios.get<GetPartnerDetailsResponseModel | string>(`/api/v1/partners/getparticularpartner/${partnerUUID}`);
    const data: GetPartnerDetailsResponseModel = typeof res.data === "string" ? decrypt(res.data) : res.data;
    // console.log("--- partner details", data.partner.isDeliveryPartner);
    return data;
  } catch (error) {
    console.log("--- getPartnerDetails error", error);
    return null;
  }
}
