import { Col, Row } from "antd";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";
import { StaffMemberModel } from "../../../models/StaffMemberModel";
import React, { useCallback, useMemo, useState } from "react";
import BookingModel, { BookingPatientModel } from "../../../models/BookingModel";
import moment from "moment";
import MonthCalendar from "./MonthCalendar";
import getStartMomentFromDateObj from "../../../utils/getStartMomentFromDateObj";

const SelectNurseAssignedDatesModal: React.FC<SelectNurseAssignedDatesModalProps> = ({
  open,
  bookingModel,
  selectedNurse,
  isSelectingSecondShift,
  patient,
  onCancel,
  onDatesSelected,
}) => {
  const [selectedDates, setSelectedDates] = useState<moment.Moment[]>([]);

  const firstBookingDate = useMemo(() => {
    return getStartMomentFromDateObj({ date: bookingModel?.date?.[0].date ?? "", time: bookingModel?.date?.[0].time ?? "" });
  }, [bookingModel?.date]);

  const enabledDates = useMemo<string[]>(() => {
    return bookingModel?.date?.map<string>((date) => date.date ?? "") ?? [];
  }, [bookingModel?.date]);

  const firstCalendarSelectableMoment = useMemo(() => firstBookingDate.clone().startOf("day"), [firstBookingDate]);

  const lastCalendarSelectableMoment = useMemo<moment.Moment | undefined>(() => {
    if (!bookingModel?.date || !bookingModel?.date.length) return;
    const lastBookingDate = bookingModel?.date![bookingModel?.date.length - 1];
    return getStartMomentFromDateObj({ date: lastBookingDate.date, time: lastBookingDate.time });
  }, [bookingModel?.date]);

  const handleAssignAllDates = () => {
    if (!selectedNurse) return;
    const allAvailableDates = bookingModel?.date?.map<string>((date) => date.date) ?? [];
    const prevSelectedDates = selectedDates.map<string>((date) => date.format("DD/MM/YYYY"));
    onDatesSelected({
      dates: Array.from(new Set([...prevSelectedDates, ...allAvailableDates])),
      selectedNurse,
    });
  };

  const handleAssignSelectedDates = () => {
    if (!selectedNurse) return;
    onDatesSelected({
      selectedNurse,
      dates: selectedDates.map<string>((date) => date.format("DD/MM/YYYY")),
    });
  };

  const handleCalendarSelectedDateChanged = useCallback((dates: moment.Moment[]) => {
    setSelectedDates(dates);
  }, []);

  const initialSelectedDates = useMemo<string[]>(() => {
    const selectedNurseDates = bookingModel?.assignedNurses?.find((nurse) => {
      return nurse.nurseUUID === selectedNurse?.nurseUUID;
    });
    if (isSelectingSecondShift) {
      const firstBookingDate = bookingModel?.date?.[0];
      return (
        selectedNurseDates?.dateArray?.filter((date) => date.time !== firstBookingDate?.time)?.map<string>((date) => date.date) ??
        []
      );
    }
    return selectedNurseDates?.dateArray?.map<string>((date) => date.date) ?? [];
  }, [bookingModel?.assignedNurses, selectedNurse?.nurseUUID, bookingModel?.date, isSelectingSecondShift]);

  return (
    <Dialog open={open} onClose={onCancel} fullWidth={true} maxWidth="sm">
      <DialogTitle>
        <p
          style={{
            marginBottom: "0px",
          }}
        >
          Please select the dates that will be assigned to{" "}
          <strong>
            {selectedNurse?.firstName} {selectedNurse?.lastName}
          </strong>
        </p>
        <p
          style={{
            marginBottom: "0px",
          }}
        >
          For patient: <strong>{patient?.name}</strong>
        </p>
      </DialogTitle>
      <DialogContent sx={{ paddingX: 8 }}>
        <Row gutter={8}>
          <Col span={24}>
            <MonthCalendar
              title={<span />}
              firstSelectableMoment={firstCalendarSelectableMoment}
              lastCalendarSelectableMoment={lastCalendarSelectableMoment}
              initialSelectedDates={initialSelectedDates}
              onSelectedDatesChanged={handleCalendarSelectedDateChanged}
              mutipleSelect={true}
              enabledDates={enabledDates}
              width={"100%"}
            />
          </Col>
        </Row>
      </DialogContent>
      <DialogActions>
        <Row
          gutter={8}
          style={{
            display: "flex",
            alignItems: "center",
          }}
        >
          <Col>
            <Button
              style={{
                width: "160px",
                borderRadius: "2rem",
              }}
              variant="outlined"
              color="primary"
              onClick={onCancel}
            >
              Cancel
            </Button>
          </Col>
          <Col>
            <Button
              style={{
                width: "200px",
                borderRadius: "2rem",
                color: "#fff",
              }}
              variant="contained"
              color="primary"
              disabled={!selectedDates || !selectedDates.length}
              onClick={handleAssignSelectedDates}
            >
              Assign Selected Dates
            </Button>
          </Col>
          <Col>
            <Button
              style={{
                width: "200px",
                borderRadius: "2rem",
                color: "#fff",
              }}
              variant="contained"
              color="primary"
              onClick={handleAssignAllDates}
            >
              Assign All Dates
            </Button>
          </Col>
        </Row>
      </DialogActions>
    </Dialog>
  );
};

interface SelectNurseAssignedDatesModalProps {
  open: boolean;
  bookingModel: BookingModel | undefined;
  selectedNurse: StaffMemberModel | undefined;
  isSelectingSecondShift: boolean;
  patient?: BookingPatientModel;
  onCancel: () => void;
  onDatesSelected: (params: { dates: string[]; selectedNurse: StaffMemberModel }) => void;
}

export default SelectNurseAssignedDatesModal;
