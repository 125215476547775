import moment from "moment";
import schedulerDetailViewTypes from "../types/schedulerDetailViewTypes";
import { NurseBookingModel } from "../models/SchedulerDetailViewResponseModel";

export interface DetailViewSelectedSlotModel {
  [prop: string]: NurseBookingModel;
}

interface SchedulerDetailViewState {
  selectedWeek: moment.Moment;
  selectedSlots: DetailViewSelectedSlotModel;
}

export const initState: SchedulerDetailViewState = {
  selectedWeek: moment().startOf("day"),
  selectedSlots: {},
};

const reducer = (
  state: SchedulerDetailViewState = initState,
  action: { payload: any; type: string }
): SchedulerDetailViewState => {
  if (action.type === schedulerDetailViewTypes.DETAIL_VIEW_SET_SELECTED_WEEK) {
    return {
      ...state,
      selectedWeek: action.payload,
    };
  } else if (action.type === schedulerDetailViewTypes.SET_DETAIL_VIEW_SELECTED_SLOTS) {
    return {
      ...state,
      selectedSlots: action.payload,
    };
  }
  return state;
};

export default reducer;
