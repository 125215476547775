import { Col, Tooltip } from "antd";
import { FC } from "react";

const SubscriptionBookingRibbon: FC = () => {
  return (
    <Col>
      <Tooltip title="This booking will automatically renew every 4 weeks until cancelled by customer or the subscription is expired">
        <p
          style={{
            marginBottom: "0px",
          }}
        >
          <span
            style={{
              backgroundColor: "#008690",
              fontWeight: "bold",
              color: "white",
              borderRadius: "0.2rem 0.2rem 0.2rem 0.2rem",
              padding: "0.1rem 1rem 0.1rem 1rem",
            }}
          >
            Subscription
          </span>
        </p>
      </Tooltip>
    </Col>
  );
};

export default SubscriptionBookingRibbon;
