import { FC, useCallback } from "react";
import { NursesDatum } from "../../models/SchedulerExploreViewResponseModel";
import { Box } from "@mui/material";
import { useHistory } from "react-router-dom";
import useNurseDetails from "../../hooks/useNurseDetails";

const NurseTableLeadingCell: FC<{
  img: string;
  name: string;
  workingHours?: string;
  nurseData: NursesDatum;
  showWorkingHoursText?: boolean;
}> = ({ img, name, workingHours, showWorkingHoursText, nurseData }) => {
  // const dispatch = useDispatch();
  const history = useHistory();
  // const selectedExploreViewWeek: moment.Moment = useSelector(
  //   (state) => (state as any)?.schedulerExploreViewReducer?.selectedWeek
  // );

  // const handleClick = () => {
  //   dispatch(setSelectedWeekDetailViewAction(selectedExploreViewWeek));
  //   history.push(`/scheduler-details/${nurseData.nurseUUID}`);
  // };

  const { query } = useNurseDetails({ nurseUUID: nurseData.nurseUUID });

  const handleClick = useCallback(() => {
    if (!query.data?.nurse?.nurseUUID) return;
    history.push(`/profile/professional/${nurseData.nurseUUID}`, {
      edit: true,
      nurse: query.data?.nurse,
    });
  }, [history, nurseData.nurseUUID, query.data?.nurse]);

  return (
    <div className="nurse-table-leading-cell" onClick={handleClick} style={{ cursor: "pointer" }}>
      <img src={img} alt={name} />
      <div className="details">
        <Box
          sx={{
            "@media (max-width: 700px)": {
              textAlign: "center",
            },
          }}
          className="name"
        >
          {name}
        </Box>
        {workingHours && (
          <div className="working-hours">
            <Box
              sx={{
                "@media (max-width: 700px)": {
                  display: "none",
                },
              }}
              className="text"
            >
              {(showWorkingHoursText ?? true) && <div className="label">Working Hours:</div>}
              {workingHours}
            </Box>
          </div>
        )}
      </div>
    </div>
  );
};

export default NurseTableLeadingCell;
