import moment from "moment";
import { GeneratedDriverScheduleItem } from "../services";
import { driverSchedulerTypes } from "../types";

export interface DriverSchedulerInitState {
  selectedCellID?: string;
  selectedDate: moment.Moment;
  tasksCardGroups: GeneratedDriverScheduleItem[][];
}

export const driverSchedulerInitState: DriverSchedulerInitState = {
  selectedDate: moment().add(1, "day"),
  tasksCardGroups: [],
};

export function driverSchedulerReducer(
  state: DriverSchedulerInitState = driverSchedulerInitState,
  action: { payload: any; type: string }
): DriverSchedulerInitState {
  if (action.type === driverSchedulerTypes.SET_SELECTED_CELL_ID) {
    return { ...state, selectedCellID: action.payload };
  }
  if (action.type === driverSchedulerTypes.SET_CARDS_TASKS_GROUPS) {
    return { ...state, tasksCardGroups: action.payload };
  }
  if (action.type === driverSchedulerTypes.SET_SELECTED_DATE) {
    return { ...state, selectedDate: action.payload };
  }
  return state;
}
