import { Chip } from "@mui/material";
import { FC, useMemo } from "react";
import { PharmacyBookingStatus } from "../../models/PharmacyBookingModel";
import { mapPharmacyOrderStatus } from "../../constants";
import { blue, green, yellow } from "@mui/material/colors";

const StatusChip: FC<StatusChipProps> = ({ status }) => {
  const statusText = useMemo(() => {
    return status;
  }, [status]);

  return (
    <Chip
      label={mapPharmacyOrderStatus[statusText]}
      // variant="outlined"
      // color={status === "DELIVERED" ? "primary" : status === "DISPATCHED" ? "success" : "info"}
      sx={{
        // border: "none",
        // borderRadius: "6px",
        backgroundColor:
          status === "DELIVERED"
            ? blue[400]
            : status === "PRESCRIPTION_UPLOADED"
            ? green[400]
            : status === "DISPATCHED"
            ? yellow[600]
            : "red",
        color: "white",
        height: "22px",
        "& .MuiChip-label": {
          textTransform: "capitalize",
          fontWeight: "500",
        },
      }}
    />
  );
};

interface StatusChipProps {
  status: PharmacyBookingStatus;
}

export default StatusChip;
