import { FC } from "react";

const TeamListProfileImage: FC<TeamListProfileImageProps> = ({ src, alt }) => {
  const imageSize = "40px";
  const borderRadius = "50%";
  const border = "0.5px solid gray";
  //   const [showError, setShowError] = useState<boolean>(false);

  if (!src) {
    const splittedAlt = alt.trim().split(" ");
    return (
      <div
        style={{
          width: imageSize,
          height: imageSize,
          minWidth: imageSize,
          minHeight: imageSize,
          borderRadius: borderRadius,
          //   border: border,
          backgroundColor: "#e8f9ee",
          color: "#16c25a",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          fontWeight: "600",
        }}
      >{`${splittedAlt[0].slice(0, 1).toUpperCase()}${splittedAlt[splittedAlt.length - 1].slice(0, 1).toUpperCase()}`}</div>
    );
  }

  return (
    <img
      width={imageSize}
      height={imageSize}
      style={{
        borderRadius: borderRadius,
        overflow: "hidden",
        objectFit: "cover",
        border: border,
        width: imageSize,
        height: imageSize,
        minHeight: imageSize,
        minWidth: imageSize,
        maxWidth: imageSize,
        maxHeight: imageSize,
      }}
      src={src}
      alt={alt}
      //   onError={(e) => {
      //     console.log("--- load image error", e);
      //     setShowError(true);
      //   }}
    />
  );
};

interface TeamListProfileImageProps {
  src?: string;
  alt: string;
}

export default TeamListProfileImage;
