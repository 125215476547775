import { FC, useCallback, useEffect, useMemo } from "react";
import { styled } from "@mui/material/styles";
import { useDispatch, useSelector } from "react-redux";
import { sidebarType } from "../types";
import { LayoutSideMenuListItem } from "../models/LayoutSideMenuListItem";
import { grey } from "@mui/material/colors";
import { drawerWidth } from "../constants";
import ReduxStateModel from "../models/ReduxStateModel";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import BasePageLayoutSideMenuItems from "./BasePageLayoutSideMenuItems";
import BaseLayoutAppBar from "./BaseLayoutAppBar";
import BasePageLayoutDrawer from "./BasePageLayoutDrawer";

const BasePageLayout: FC<BasePageLayoutProps> = ({
  mainContent,
  appBarContent,
  className,
  notificationsIconColor,
  sideMenuItems = BasePageLayoutSideMenuItems,
  backgroundColor = "white",
  textColor = grey[800],
}) => {
  const dispatch = useDispatch();
  const isMobile = window.innerWidth < 700;
  const sidebarCollapsed = useSelector((state: ReduxStateModel) => {
    const value = state.sidebarReducer?.collapsed;
    if (isMobile && value === undefined) {
      return true;
    }
    return value ?? false;
  });
  const open = !sidebarCollapsed;

  const setOpen = useCallback(
    (value: boolean) => {
      dispatch({ type: sidebarType.SET_COLLAPSED, payload: !value });
    },
    [dispatch]
  );

  useEffect(() => {
    const mobile = window.innerWidth < 700;
    if (mobile) {
      setOpen(false);
    }
  }, [setOpen]);

  const mainWidth = useMemo(() => {
    return `calc(100vw - ${open ? drawerWidth : 0}px)`;
  }, [open]);

  const zIndex = 800;

  return (
    <Box sx={{ display: "flex", width: "100%", height: "100%" }} className={`base-page-layout ${open ? "side-menu-open" : ""}`}>
      <CssBaseline />
      <BaseLayoutAppBar
        notificationsIconColor={notificationsIconColor}
        open={open}
        backgroundColor={backgroundColor}
        zIndex={zIndex}
        textColor={textColor}
        setOpen={setOpen}
        appBarContent={appBarContent}
      />
      <BasePageLayoutDrawer
        open={open}
        zIndex={zIndex}
        backgroundColor={backgroundColor}
        textColor={textColor}
        sideNavListItems={sideMenuItems}
        setOpen={setOpen}
      />
      <Main
        open={open}
        className={`${className ?? ""}`}
        sx={{
          paddingTop: "50px",
          display: "flex",
          flexDirection: "column",
          height: "100vh",
          maxHeight: "100vh",
          overflowY: "scroll",
          width: mainWidth,
          maxWidth: mainWidth,
          "@media (max-width: 700px)": {
            width: "100vw",
            minWidth: "100vw",
          },
        }}
      >
        {mainContent}
      </Main>
    </Box>
  );
};

const Main = styled("main", { shouldForwardProp: (prop) => prop !== "open" })<{
  open?: boolean;
}>(({ theme, open }) => ({
  flexGrow: 1,
  // padding: theme.spacing(3),
  transition: theme.transitions.create("margin", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  marginLeft: `-${drawerWidth}px`,
  ...(open && {
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  }),
  "@media (max-width: 700px)": {
    marginLeft: "0px",
  },
}));

export interface BasePageLayoutProps {
  className?: string;
  mainContent: React.ReactNode;
  appBarContent?: React.ReactNode;
  backgroundColor?: string;
  textColor?: string;
  notificationsIconColor?: string;
  sideMenuItems?: LayoutSideMenuListItem[];
}

export default BasePageLayout;
