import { Input } from "antd";
import { FC } from "react";
import { useDispatch, useSelector } from "react-redux";
import schedulerExploreViewTypes from "../../types/schedulerExploreViewType";
import ReduxStateModel from "../../models/ReduxStateModel";

const StaffSchedulerSearchInput: FC = () => {
  const dispatch = useDispatch();
  const searchInputValue: string = useSelector(
    (state: ReduxStateModel) =>
      state?.schedulerExploreViewReducer?.searchInputValue ?? ""
  );

  return (
    <Input
      onChange={(e) => {
        dispatch({
          type: schedulerExploreViewTypes.SET_SEARCH_INPUT_VALUE,
          payload: e.target.value,
        });
      }}
      value={searchInputValue}
      style={{ borderRadius: "20px", borderColor: "#eee", width: "100%" }}
      placeholder="Search staff by name..."
      allowClear={true}
    />
  );
};

export default StaffSchedulerSearchInput;
