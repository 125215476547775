import { Box, Button, Tooltip } from "@mui/material";
import { useSelector } from "react-redux";
import { allowIVDripInventoryPartnerUUIDs } from "../constants";
import { FC, useState } from "react";
import InventoryRoundedIcon from "@mui/icons-material/InventoryRounded";
import InventorySideDrawer from "./InventorySideDrawer";
import useCurrentPartnerDetails from "../hooks/useCurrentPartnerDetails";
import ReduxStateModel from "../models/ReduxStateModel";

const InventoryRequestButton: FC = () => {
  const authReducer = useSelector((state: ReduxStateModel) => state.authReducer?.setting);
  const [showDrawer, setShowDrawer] = useState(false);

  const { isDeliveryPartner } = useCurrentPartnerDetails();

  if (isDeliveryPartner) return null;

  if (!allowIVDripInventoryPartnerUUIDs.includes(authReducer?.partnerUUID ?? "")) {
    return null;
  }

  return (
    <>
      <Tooltip title="Manage Inventory">
        <Button
          variant="contained"
          disableElevation={true}
          // sx={{ borderRadius: "30px" }}
          startIcon={<InventoryRoundedIcon sx={{ color: "white" }} />}
          onClick={() => setShowDrawer(true)}
          sx={{
            "@media (max-width: 900px)": {
              "& .MuiButton-icon": {
                margin: "0px",
              },
            },
          }}
        >
          <Box
            color={"white"}
            sx={{
              "@media (max-width: 900px)": {
                display: "none",
              },
            }}
          >
            Manage Inventory
          </Box>
        </Button>
      </Tooltip>
      <InventorySideDrawer
        open={showDrawer}
        openDrawer={() => setShowDrawer(true)}
        onClose={function (): void {
          setShowDrawer(false);
        }}
      />
    </>
  );
};

export default InventoryRequestButton;
