import { FC } from "react";
import { Redirect } from "react-router-dom";

const NoAuthOnlyRoute: FC<{ child: JSX.Element }> = ({ child }) => {
  const settings = localStorage.getItem("setting");

  if (!settings) {
    return child;
  }

  return <Redirect to="/" />;
};

export default NoAuthOnlyRoute;
