import { Box, Button, Drawer, IconButton, Typography } from "@mui/material";
import { grey } from "@mui/material/colors";
import { FC, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { PartnerAddressModel, submitInventoryRequest } from "../services/inventory";
import { LoadingButton } from "@mui/lab";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { showMessage } from "./common/notification";
import { useSelector } from "react-redux";
import ArticleRoundedIcon from "@mui/icons-material/ArticleRounded";
import CancelRoundedIcon from "@mui/icons-material/CancelRounded";
import AddInventorySelectContact from "./AddInventorySelectContact";
import PartnerContactModel from "../models/PartnerContactModel";
import AddInventorySelectAddress from "./AddInventorySelectAddress";
import PartnerInventoryListItemModel from "../models/PartnerInventoryListItemModel";
import AddInventoryRequestItems from "./AddInventoryRequestItems";
import CheckCircleRoundedIcon from "@mui/icons-material/CheckCircleRounded";
import ReduxStateModel from "../models/ReduxStateModel";
import useAllPartnerRequestsQuery from "../hooks/useAllPartnerRequestsQuery";
import VisuallyHiddenInput from "./VisuallyHiddenInput";
import convertFileToBase64 from "../utils/convertFileToBase64";

const AddInventoryRequest: FC<AddInventoryRequestProps> = ({ onClose, open }) => {
  const prescriptionInputRef = useRef<HTMLInputElement>(null);
  const [contact, setContact] = useState<PartnerContactModel | null>(null);
  const [address, setAddress] = useState<PartnerAddressModel | null>(null);
  const [requestItems, setRequestItems] = useState<PartnerInventoryListItemModel[]>([{ name: "", quantity: 1 }]);
  const [selectedPrescription, setSelectedPrescription] = useState<File>();
  const authReducer = useSelector((state: ReduxStateModel) => state.authReducer?.setting);
  const queryClient = useQueryClient();

  useEffect(() => {
    if (!open) {
      setContact(null);
      setAddress(null);
      setRequestItems([{ name: "", quantity: 1 }]);
      setSelectedPrescription(undefined);
    }
  }, [open]);

  const disableSaveButton = useMemo(() => {
    return (
      !contact ||
      !address ||
      !requestItems.length ||
      requestItems.some((item) => !item.name || !item.quantity) ||
      !selectedPrescription?.name
    );
  }, [address, contact, requestItems, selectedPrescription?.name]);

  const submitMutation = useMutation({ mutationFn: submitInventoryRequest });

  const { queryKey } = useAllPartnerRequestsQuery({ partnerUUID: authReducer?.partnerUUID });

  const handleSubmitClicked = useCallback(async () => {
    const prescriptionBase64 = await convertFileToBase64(selectedPrescription);
    if (!prescriptionBase64) {
      console.log("--- AddInventoryRequest convertFileToBase64 error");
      return showMessage("error", "Something went wrong");
    }
    const controlledFileType = selectedPrescription?.type.toLocaleLowerCase().includes("pdf") ? "pdf" : "image";
    const res = await submitMutation.mutateAsync({
      addressUUID: address?._id ?? "",
      contactUUID: contact?._id ?? "",
      inventoryList: requestItems,
      partnerUUID: authReducer?.partnerUUID ?? "",
      prescriptionData: {
        data: controlledFileType === "pdf" ? prescriptionBase64.slice(28) : prescriptionBase64,
        fileName: selectedPrescription?.name ?? "",
        type: selectedPrescription?.type ?? "",
        fileType: controlledFileType,
        documentType: "PRESCRIPTION",
      },
    });
    if (res?.success) {
      queryClient.resetQueries(queryKey);
      // onContactCreated(contact);
      showMessage("success", "Contact was added successfully");
      onClose();
      setContact(null);
      setAddress(null);
      setRequestItems([{ name: "", quantity: 1 }]);
      setSelectedPrescription(undefined);
    } else {
      showMessage("error", "Something went wrong");
    }
  }, [
    address?._id,
    authReducer?.partnerUUID,
    contact?._id,
    onClose,
    queryClient,
    queryKey,
    requestItems,
    selectedPrescription,
    submitMutation,
  ]);

  const handlePrescriptionChanged = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    if (!e.target.files?.length) {
      return console.log("--- pick handlePrescriptionChanged no files error");
    }
    setSelectedPrescription(e.target.files[0]);
  }, []);

  return (
    <Drawer anchor={"right"} open={open} onClose={onClose}>
      <Box width={"600px"} maxWidth={"100vw"} height={"100vh"}>
        <Box
          height={"50px"}
          width={"100%"}
          borderBottom={`1px solid ${grey[200]}`}
          display={"flex"}
          alignItems={"center"}
          justifyContent={"space-between"}
          paddingX={2}
        >
          <Typography fontWeight={500}>Submit Request</Typography>
          <Box>
            <IconButton onClick={onClose}>
              <CancelRoundedIcon />
            </IconButton>
          </Box>
        </Box>
        <Box
          paddingX={2}
          paddingY={2}
          height={"calc(100% - 50px)"}
          display={"flex"}
          flexDirection={"column"}
          justifyContent={"space-between"}
          overflow={"scroll"}
        >
          <Box>
            <AddInventorySelectContact value={contact} onChange={setContact} onContactCreated={setContact} />
            <AddInventorySelectAddress value={address} onChange={setAddress} onAddressCreated={setAddress} />
            <AddInventoryRequestItems items={requestItems} setItems={setRequestItems} />
            <Button
              component="label"
              variant="outlined"
              color="success"
              sx={{ marginTop: "24px", display: "flex", flexDirection: "column", paddingX: 2, paddingY: 2 }}
              fullWidth={true}
            >
              <Typography marginBottom={2} textAlign={"center"}>
                Upload prescription
              </Typography>
              <Typography
                textAlign={"center"}
                display={"flex"}
                alignItems={"center"}
                justifyContent={"center"}
                gap={1}
                fontWeight={600}
                fontSize={14}
              >
                {selectedPrescription ? selectedPrescription.name : `Click here to select prescription`}
                <ArticleRoundedIcon />
              </Typography>
              <VisuallyHiddenInput
                type="file"
                accept=".jpg, .jpeg, .png, .pdf"
                ref={prescriptionInputRef}
                onChange={handlePrescriptionChanged}
              />
            </Button>
          </Box>
          <LoadingButton
            variant="contained"
            onClick={handleSubmitClicked}
            loading={submitMutation.isLoading}
            disabled={disableSaveButton}
            fullWidth={true}
            disableElevation={true}
            sx={{ marginTop: "auto" }}
            startIcon={<CheckCircleRoundedIcon sx={{ color: "white" }} />}
          >
            <Box color={"white"}>Submit</Box>
          </LoadingButton>
        </Box>
      </Box>
    </Drawer>
  );
};

interface AddInventoryRequestProps {
  open: boolean;
  onClose: () => void;
}

export default AddInventoryRequest;
