export const getBase64 = (file) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result.split("base64,")[1]);
    reader.onerror = (error) => reject(error);
  });
};

export function generateRangeArray({ start = 0, end }) {
  // console.log("--- generateRangeArray", start, end);
  if (start > end) return [];
  const range = Array.from(Array(end - start + 1).keys()).map((x) => x + start);
  return range;
}


