import { FC, useMemo } from "react";
import { StaffMemberModel } from "../models/StaffMemberModel";
import { MultipleSelectDropdownItem } from "./MultipleSelectDropdown";
import { Autocomplete, Avatar, Box, TextField, Typography } from "@mui/material";
import BookingModel, { AssignStaffDateModel } from "../models/BookingModel";
import useAllPartnerNurses from "../hooks/useAllPartnerNurses";
import useValidOrderNurses from "../hooks/useValidOrderNurses";

const AssignStaffSelectFirstNurse: FC<AssignStaffSelectFirstNurseProps> = ({
  id,
  isSecondShift,
  booking,
  dateObj,
  value,
  label,
  onChange,
}) => {
  const { query } = useAllPartnerNurses();

  const allNurses = useMemo(() => query.data?.data.nurses ?? [], [query.data?.data.nurses]);

  const { query: validNurseQuery } = useValidOrderNurses({ orderUUID: booking.orderUUID });
  const validNurses = useMemo(() => validNurseQuery.data?.data.nurses ?? [], [validNurseQuery.data?.data.nurses]);

  const assignableNursesMap = useMemo<Map<string, boolean>>(() => {
    const map = new Map<string, boolean>();
    for (const nurseID of validNurses ?? []) {
      map.set(nurseID, true);
    }
    for (const assignedNurse of booking.assignedNurses ?? []) {
      map.set(assignedNurse.nurseUUID ?? "", true);
    }
    if (isSecondShift) {
      map.delete(dateObj.firstShiftNurseUUID ?? "");
    } else {
      map.delete(dateObj.secondShiftNurseUUID ?? "");
    }
    return map;
  }, [booking.assignedNurses, dateObj.firstShiftNurseUUID, dateObj.secondShiftNurseUUID, isSecondShift, validNurses]);

  const nurseSelectOptions = useMemo<StaffMemberModel[]>(() => {
    return (
      allNurses?.filter((nurse) => {
        return assignableNursesMap.get(nurse.nurseUUID ?? "");
      }) ?? []
    );
  }, [allNurses, assignableNursesMap]);

  const selectedNurse = useMemo(() => {
    return nurseSelectOptions.find((nurse) => nurse.nurseUUID === value);
  }, [nurseSelectOptions, value]);

  return (
    <Autocomplete
      disablePortal
      autoComplete={false}
      autoCorrect="off"
      loading={query.isLoading || validNurseQuery.isLoading}
      popupIcon={
        <Avatar
          sx={{ width: "28px", height: "28px", minWidth: "28px", minHeight: "28px" }}
          src={selectedNurse?.profileImage ?? selectedNurse?.profileImageUrl ?? selectedNurse?.profilePhoto}
          alt={selectedNurse?.firstName ?? selectedNurse?.lastName ?? "Nurse"}
        />
      }
      sx={{
        marginTop: "20px",
        "& .MuiAutocomplete-popupIndicator": {
          transform: "rotate(0deg)",
        },
      }}
      id={id ?? "assign-staff-select-first-nurse"}
      isOptionEqualToValue={(option, value) => option.id === value.id}
      value={
        selectedNurse
          ? {
              id: selectedNurse?.nurseUUID ?? "",
              label: `${selectedNurse?.firstName ?? ""} ${selectedNurse?.lastName ?? ""}`.trim(),
              img: selectedNurse?.profileImageUrl ?? selectedNurse?.profileImage,
            }
          : null
      }
      onChange={(_, value) => {
        if (value?.id) {
          onChange(value.id);
        }
      }}
      renderOption={(props, option) => {
        return (
          <Box
            // key={option.id}
            {...props}
            component="li"
            sx={{ "& > img": { mr: 2, flexShrink: 0 }, display: "flex", alignItems: "center", gap: "8px" }}
          >
            <Avatar srcSet={option.img} src={option.img} alt={option.label?.toString()} sx={{ width: 24, height: 24 }} />
            <Typography>{option.label}</Typography>
          </Box>
        );
      }}
      options={nurseSelectOptions.map<MultipleSelectDropdownItem>((nurse) => {
        return {
          id: nurse.nurseUUID ?? "",
          label: `${nurse.firstName ?? ""} ${nurse.lastName ?? ""}`.trim(),
          img: nurse.profileImageUrl ?? nurse.profileImage,
        };
      })}
      renderInput={(params) => (
        <TextField
          {...params}
          size="small"
          autoComplete="off"
          autoCorrect="off"
          label={label ?? "First shift staff"}
          inputProps={{
            ...params.inputProps,
            autoComplete: "new-password",
          }}
        />
      )}
    />
  );
};

interface AssignStaffSelectFirstNurseProps {
  booking: BookingModel;
  dateObj: AssignStaffDateModel;
  value?: string;
  label?: string;
  id?: string;
  isSecondShift: boolean;
  onChange: (nurseUUID: string) => void;
}

export default AssignStaffSelectFirstNurse;
