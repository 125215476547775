import { useQuery } from "@tanstack/react-query";
import { getPharmacyOrder } from "../services/pharmacy.orders.service";
import { useMemo } from "react";

function usePharmacyOrder(params: { orderUUID?: string }) {
  const queryKey = useMemo(() => ["pharmacy-orders/fetch-particular-order", params.orderUUID], [params.orderUUID]);

  const query = useQuery(queryKey, () => getPharmacyOrder({ orderUUID: params.orderUUID ?? "" }), {
    enabled: !!params.orderUUID,
  });

  return { query, queryKey };
}

export default usePharmacyOrder;
