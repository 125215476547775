import SettingsRoundedIcon from "@mui/icons-material/SettingsRounded";
import TrendingUpRoundedIcon from "@mui/icons-material/TrendingUpRounded";
import PeopleAltRoundedIcon from "@mui/icons-material/PeopleAltRounded";
import DriveEtaRoundedIcon from "@mui/icons-material/DriveEtaRounded";
import AllInboxRoundedIcon from "@mui/icons-material/AllInboxRounded";
import CalendarMonthRoundedIcon from "@mui/icons-material/CalendarMonthRounded";

export const deliveryPartnerMenuItems = [
  {
    href: "/",
    icon: <AllInboxRoundedIcon />,
    label: "Orders",
  },
  {
    icon: <TrendingUpRoundedIcon />,
    href: "/delivery-partner-revenue",
    label: "Revenue",
  },
  {
    icon: <SettingsRoundedIcon />,
    href: "/settings",
    label: "Settings",
  },
];

export default [
  {
    href: "/",
    icon: <AllInboxRoundedIcon />,
    label: "Bookings",
  },
  {
    href: "/scheduler",
    icon: <CalendarMonthRoundedIcon />,
    label: "Staff Scheduler",
  },
  {
    href: "/new-driver-schedule",
    icon: <DriveEtaRoundedIcon />,
    label: "Mobility Tool",
  },
  {
    href: "/team",
    label: "Staffing",
    icon: <PeopleAltRoundedIcon />,
  },
  {
    href: "/traction",
    label: "Revenue Tracker",
    icon: <TrendingUpRoundedIcon />,
  },
  {
    icon: <SettingsRoundedIcon />,
    href: "/settings",
    label: "Settings",
  },
];
