import moment from "moment";
import useGetStaffSchedulerWeekViewQueryKey from "../../hooks/useGetStaffSchedulerWeekViewQueryKey";
import useGetStaffSchedulerMonthViewQueryKey from "../../hooks/useGetStaffSchedulerMonthViewQueryKey";
import useGetStaffSchedulerDayViewQueryKey from "../../hooks/useGetStaffSchedulerDayViewQueryKey";
import SchedulerExploreViewResponseModel from "../../models/SchedulerExploreViewResponseModel";
import useGetStaffSchedulerQueryData from "../../hooks/useGetStaffSchedulerQueryData";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { Button, Form, Modal, Spin, DatePicker } from "antd";
import { FC } from "react";
import { useHistory } from "react-router-dom";
import { nurseService } from "../../services";
import { StaffMemberModel } from "../../models/StaffMemberModel";
import { NursesDatum, SlotPatientModel } from "../../models/SchedulerExploreViewResponseModel";
import { showMessage } from "../../components/common/notification";
import { CalendarViewType } from ".";

const RemoveNurseOnLeaveModal: FC<{ viewType: CalendarViewType }> = ({ viewType }) => {
  const now = moment();
  const history = useHistory<
    { nurseData: NursesDatum; patientsList: SlotPatientModel[]; initialSelectedDate: number } | undefined
  >();
  const queryClient = useQueryClient();
  const locationState = history.location?.state;
  const [form] = Form.useForm<RemoveNurseOnLeaveModalForm>();
  const timeRange: moment.Moment[] | undefined = Form.useWatch("timeRange", form);

  const addNurseHolidaysMutation = useMutation({ mutationFn: nurseService.addNurseHolidays });

  const { data: nurseDetailsResponse, isFetching: loadingNurseDetails } = useQuery(
    ["nurses/getparticularnurse", locationState?.nurseData.nurseUUID],
    () => nurseService.getNurseDetails(locationState?.nurseData.nurseUUID ?? ""),
    { refetchOnWindowFocus: false, enabled: !!locationState?.nurseData.nurseUUID }
  );

  const nurseDetails: StaffMemberModel | undefined = nurseDetailsResponse?.nurse;

  const exploreViewData = useGetStaffSchedulerQueryData(viewType);

  const handleValidationFinished = async (values: RemoveNurseOnLeaveModalForm) => {
    // const enumratedDates: string[] = [];
    const [startDate, endDate] = values.timeRange ?? [];
    if (!startDate || !endDate) {
      return showMessage("error", "Something went wrong");
    }
    const currentDate = startDate.clone();
    const selectedDatesMap = new Map<string, boolean>();
    while (currentDate.isSameOrBefore(endDate)) {
      //   enumratedDates.push(currentDate.format("DD/MM/YYYY"));
      selectedDatesMap.set(currentDate.format("DD/MM/YYYY"), true);
      currentDate.add(1, "day");
    }
    // for (const date of )
    const updatedHolidays = nurseDetails?.holidays?.filter((date) => {
      return !selectedDatesMap.get(date);
    });
    const res = await addNurseHolidaysMutation.mutateAsync({
      holidaysToUpdate: updatedHolidays ?? [],
      nurseUUID: nurseDetails?.nurseUUID ?? "",
    });
    if (!res?.success) {
      showMessage("error", "Something went wrong");
    } else {
      handleNurseHolidayRemovedSuccessfully(updatedHolidays ?? []);
    }
  };

  const weekViewQueryKey = useGetStaffSchedulerWeekViewQueryKey();
  const monthViewQueryKey = useGetStaffSchedulerMonthViewQueryKey();
  const dayViewQueryKey = useGetStaffSchedulerDayViewQueryKey();

  const handleRemoveAllDaysClicked = async () => {
    const res = await addNurseHolidaysMutation.mutateAsync({
      holidaysToUpdate: [],
      nurseUUID: nurseDetails?.nurseUUID ?? "",
    });
    if (!res?.success) {
      showMessage("error", "Something went wrong");
    } else {
      handleNurseHolidayRemovedSuccessfully([]);
    }
  };

  const handleNurseHolidayRemovedSuccessfully = (selectedDates: string[]) => {
    const queryUpdater = (oldValue: SchedulerExploreViewResponseModel | null | undefined) => {
      if (!oldValue) return oldValue;
      const newValue: SchedulerExploreViewResponseModel = JSON.parse(JSON.stringify(oldValue));
      const nurse = newValue.nursesData?.find((nurse) => nurse.nurseUUID === nurseDetails?.nurseUUID);
      if (!nurse?.data) return oldValue;
      nurse.holidays = selectedDates;
      return newValue;
    };
    const newQueryValue = queryUpdater(exploreViewData);
    queryClient.setQueryData(weekViewQueryKey, newQueryValue);
    queryClient.setQueryData(monthViewQueryKey, newQueryValue);
    queryClient.setQueryData(dayViewQueryKey, newQueryValue);
    showMessage("success", "Nurse time off was removed successfully");
    history.replace("/scheduler");
  };

  return (
    <Modal
      className="remove-nurse-on-leave-modal"
      title={
        <>
          <div style={{ fontWeight: "normal" }}>Remove off days for nurse</div>
          <div>
            {nurseDetails?.firstName ?? ""} {nurseDetails?.lastName ?? ""}
          </div>
        </>
      }
      footer={null}
      maskClosable={false}
      centered={true}
      open={true}
      onCancel={() => {
        history.replace("/scheduler");
      }}
    >
      <div className="modal-container">
        <Spin spinning={addNurseHolidaysMutation.isLoading || loadingNurseDetails}>
          <Form form={form} onFinish={handleValidationFinished}>
            <Form.Item
              label=""
              name="timeRange"
              rules={[
                {
                  required: true,
                  message: "Please select the dates where you want to remove nurse off days",
                },
              ]}
            >
              <DatePicker.RangePicker
                style={{ width: "100%" }}
                disabledDate={(date) => {
                  return date.isBefore(now.clone().startOf("day"));
                }}
              />
            </Form.Item>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <Button onClick={handleRemoveAllDaysClicked} htmlType="button">
                Remove all off days
              </Button>
              <Button htmlType="submit" type="primary" disabled={!timeRange?.length || timeRange.some((value) => !value)}>
                Remove selected days
              </Button>
            </div>
          </Form>
        </Spin>
      </div>
    </Modal>
  );
};

interface RemoveNurseOnLeaveModalForm {
  timeRange?: moment.Moment[];
}

export default RemoveNurseOnLeaveModal;
