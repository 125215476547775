import { useQuery } from "@tanstack/react-query";
import { GetOrderDetailsResponseModel, getOrderDetails } from "../services";
import { useMemo } from "react";
import { showMessage } from "../components/common/notification";

function useOrderDetails({
  orderUUID,
  refetchOnWindowFocus,
  cacheTime,
  onSuccess,
}: {
  orderUUID?: string;
  cacheTime?: number;
  refetchOnWindowFocus?: boolean;
  onSuccess?: (res: GetOrderDetailsResponseModel | null) => void;
}) {
  const queryKey = useMemo(() => ["getparticularpartnerorder", orderUUID], [orderUUID]);

  const query = useQuery(queryKey, () => getOrderDetails({ orderUUID: orderUUID ?? "" }), {
    enabled: !!orderUUID,
    onSuccess: onSuccess,
    refetchOnWindowFocus: refetchOnWindowFocus,
    cacheTime: cacheTime,
    staleTime: cacheTime,
    onError: () => {
      showMessage("error", "Something went wrong");
    },
  });

  return { query, queryKey };
}

export default useOrderDetails;
