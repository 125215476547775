import React from "react";

const Slide1 = ({ url, title, desc }) => {
  return (
    <div>
      {/* <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignSelf: "center",
          textAlign: "center",
        }}
      >
        <img src={require(`./${url}.svg`).default} style={{ marginBottom: "0px" }} alt="driver" />
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignSelf: "center",
            textAlign: "center",
          }}
        >
          <h3
            style={{
              fontWeight: "bold",
              fontSize: "24px",
              marginBottom: "0.5rem",
            }}
          >
            {title}
          </h3>
          <p
            style={{
              fontSize: "15px",
              width: "70vh",
              color: "#7d7d7d",
            }}
          >
            {desc}
          </p>
        </div>
      </div> */}
    </div>
  );
};

export default Slide1;
