async function convertFileToBase64(file?: File) {
  return new Promise<string | undefined>((resolve, reject) => {
    if (!file) {
      reject(new Error("No file provided."));
      return;
    }
    const reader = new FileReader();
    reader.onload = function (event) {
      const base64String = event.target?.result;
      resolve(base64String?.toString());
    };
    reader.onerror = function () {
      reject(new Error("Error reading the file."));
    };
    reader.readAsDataURL(file);
  });
}

export default convertFileToBase64;
