import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Avatar,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Typography,
} from "@mui/material";
import { FC, useMemo } from "react";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import BookingModel from "../../models/BookingModel";

const OrderRecommendations: FC<OrderRecommendationsProps> = ({ order }) => {
  const ivDrips = useMemo(() => {
    return order.recommendations?.find((recommendation) => recommendation.type === "DRIPS");
  }, [order.recommendations]);

  const labTests = useMemo(() => {
    return order.recommendations?.find((recommendation) => recommendation.type === "LAB_TESTS");
  }, [order.recommendations]);

  if (!order.recommendations?.length) return null;

  return (
    <Accordion style={{ marginTop: "1rem" }}>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <div style={{ fontWeight: "bold" }}>Recommendations List</div>
      </AccordionSummary>
      <AccordionDetails>
        <div style={{ display: "flex", flexWrap: "wrap", gap: "1rem" }}>
          <List
            dense
            sx={{
              width: "100%",
              bgcolor: "background.paper",
              overflow: "scroll",
              "& .iv-drip-list-item": {
                alignItems: "flex-start",
              },
            }}
          >
            {(ivDrips?.multipleProducts?.length ?? 0) > 0 && (
              <ListItem
                className="iv-drip-list-item"
                sx={{
                  borderBottom: "1px solid #cccccc",
                  paddingBottom: (ivDrips?.customizations?.length ?? 0) > 0 ? "0px" : "1rem",
                  marginBottom: "0.2rem",
                  "& .MuiListItemSecondaryAction-root .MuiButtonBase-root": {
                    minWidth: "50px",
                  },
                  "& .MuiListItemButton-root": {
                    alignItems: "flex-start",
                  },
                }}
                disablePadding
              >
                <ListItemAvatar>
                  <Avatar
                    sx={{ borderRadius: "4px" }}
                    alt={ivDrips?.multipleProducts?.[0]?.name}
                    src={ivDrips?.multipleProducts?.[0]?.imageUrl}
                  />
                </ListItemAvatar>
                <ListItemText
                  primary={
                    <Typography variant="subtitle1" sx={{}} fontWeight={600}>
                      {ivDrips?.multipleProducts?.[0]?.name}
                    </Typography>
                  }
                  secondary={
                    (ivDrips?.customizations?.length ?? 0) > 0 ? (
                      <div>
                        <div style={{ fontWeight: 600 }}>Add-ons:</div>{" "}
                        <ul style={{ padding: "4px 14px" }}>
                          {ivDrips?.customizations?.map((addon) => {
                            return (
                              <li key={addon.customizationUUID} style={{ marginBottom: "0.5rem" }}>
                                {addon.engName}
                              </li>
                            );
                          })}
                        </ul>
                      </div>
                    ) : null
                  }
                />
              </ListItem>
            )}
            {labTests?.multipleProducts?.map((test) => {
              return (
                <ListItem
                  key={test.productUUID}
                  sx={{
                    borderBottom: "1px solid #cccccc",
                    paddingBottom: "1rem",
                    // marginBottom: "1rem",
                    marginTop: "1rem",
                    "& .MuiListItemSecondaryAction-root .MuiButtonBase-root": {
                      minWidth: "50px",
                    },
                    "& .MuiListItemButton-root": {
                      alignItems: "flex-start",
                    },
                  }}
                  disablePadding
                >
                  <ListItemAvatar>
                    <Avatar sx={{ borderRadius: "4px" }} alt={test.name} src={test.imageUrl} />
                  </ListItemAvatar>
                  <ListItemText
                    primary={
                      <Typography variant="subtitle1" sx={{}} fontWeight={600}>
                        {test.name}
                      </Typography>
                    }
                  />
                </ListItem>
              );
            })}
            {labTests?.customizations?.map((test) => {
              return (
                <ListItem
                  key={test.customizationUUID}
                  sx={{
                    borderBottom: "1px solid #cccccc",
                    marginBottom: "1rem",
                    "& .MuiListItemSecondaryAction-root .MuiButtonBase-root": {
                      minWidth: "50px",
                    },
                    "& .MuiListItemButton-root": {
                      alignItems: "flex-start",
                    },
                  }}
                  disablePadding
                >
                  <ListItemText
                    primary={
                      <Typography variant="subtitle1" sx={{}} fontWeight={600}>
                        {test.engName}
                      </Typography>
                    }
                    secondary={<Typography variant="subtitle2">{test.engDescription}</Typography>}
                  />
                </ListItem>
              );
            })}
          </List>
        </div>
      </AccordionDetails>
    </Accordion>
  );
};

interface OrderRecommendationsProps {
  order: BookingModel;
}

export default OrderRecommendations;
