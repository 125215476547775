import moment from "moment";
import NurseTableLeadingCell from "./NurseTableLeadingCell";
import TableCell from "./TableCell";
import ReduxStateModel from "../../models/ReduxStateModel";
import SchedulerExploreViewResponseModel, { NursesDatum } from "../../models/SchedulerExploreViewResponseModel";
import { FC, useMemo } from "react";
import { useSelector } from "react-redux";
import { generateRangeArray } from "../../utils/utilFunctions";

const CalendarWeekView: FC<CalendarWeekViewProps> = ({ data }) => {
  const selectedWeek = useSelector((state: ReduxStateModel) => state?.schedulerExploreViewReducer?.selectedWeek ?? moment());
  const searchInputValue = useSelector((state: ReduxStateModel) => state?.schedulerExploreViewReducer?.searchInputValue ?? "");

  const weekDays = useMemo<moment.Moment[]>(() => {
    const days: moment.Moment[] = [];
    for (let i = 0; i < 7; i += 1) {
      days.push(selectedWeek.clone().add(i, "day"));
    }
    return days;
  }, [selectedWeek]);

  const bookingsNurseDatesMap = useSelector(
    (state: ReduxStateModel) => state?.schedulerExploreViewReducer?.nurseDayBookings ?? {}
  );

  const filteredNursesData = useMemo<NursesDatum[] | undefined>(() => {
    if (searchInputValue.trim().length) {
      return data.nursesData?.filter((nurseData) => {
        return nurseData.name?.trim().toLocaleLowerCase().includes(searchInputValue.trim().toLocaleLowerCase());
      });
    }
    return data.nursesData;
  }, [data.nursesData, searchInputValue]);

  return (
    <div className="dd-table explore-view-table">
      <div className="table-body" id="explore-view-table-body">
        <div className="table-row header">
          <div className="cell table-col-1">Professionals ({data.nursesData?.length})</div>
          {generateRangeArray({ end: 6 }).map<JSX.Element>((i) => {
            const moment = selectedWeek.clone().add(i, "day");
            return (
              <div key={moment.valueOf()} className="cell">
                {moment.format("dddd D")}
              </div>
            );
          })}
        </div>
        {filteredNursesData?.map<JSX.Element>((nurseData) => {
          // const totalHoursAssigned = nurseData.totalHoursAssigned ?? 0;
          // const totalHoursServed = nurseData.totalHoursServed ?? 0;
          const key = `${nurseData.nurseUUID}_${selectedWeek.format()}`;
          // const cellContent = (
          // );
          // if (totalHoursAssigned || totalHoursServed) {
          // }
          return (
            <div key={key} className="table-row" id={`nurse-row-${nurseData.nurseUUID}`}>
              <div className="cell table-col-1" key={key}>
                <NurseTableLeadingCell
                  nurseData={nurseData}
                  name={nurseData.name ?? ""}
                  img={nurseData.profileImage ?? ""}
                  workingHours={`${nurseData.startTime ?? ""} - ${nurseData.endTime ?? ""}`}
                />
              </div>
              {weekDays.map<JSX.Element>((day) => {
                return (
                  <TableCell
                    key={day.valueOf()}
                    nurseData={nurseData}
                    cellMoment={day.clone()}
                    bookings={bookingsNurseDatesMap[`${nurseData.nurseUUID}_${day.format()}`]}
                  />
                );
              })}
            </div>
          );
          // return cellContent;
        })}
      </div>
    </div>
  );
};

interface CalendarWeekViewProps {
  // selectedWeek: moment.Moment;
  data: SchedulerExploreViewResponseModel;
}

export default CalendarWeekView;
