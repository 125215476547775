import { useQuery } from "@tanstack/react-query";
import { nurseService } from "../services";
import { useMemo } from "react";

export default function useValidOrderNurses(params: { orderUUID?: string }) {
  const queryKey = useMemo(() => ["orders/fetchvalidnurses", params.orderUUID], [params.orderUUID]);

  const query = useQuery(queryKey, () => nurseService.fetchValidNursesForAnOrder(params.orderUUID ?? ""), {
    enabled: !!params.orderUUID,
  });

  return { query, queryKey };
}
