import { useQuery } from "@tanstack/react-query";
import { useMemo } from "react";
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirebaseAppConfig } from "../services";

export default function useFireAuth() {
  const queryKey = useMemo(() => {
    return ["/api/v1/apps/get-partner-dashboard-firebase-config"];
  }, []);

  const query = useQuery(queryKey, getFirebaseAppConfig);

  const app = useMemo(() => {
    return query.data?.config ? initializeApp(query.data?.config) : null;
  }, [query.data?.config]);

  const fireAuth = useMemo(() => (app ? getAuth(app) : null), [app]);

  return { query, fireAuth, app };
}
