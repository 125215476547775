import { FC, useMemo } from "react";
import { BiCalendar } from "react-icons/bi";
import { FaRegClock } from "react-icons/fa";
import HourglassEmptyRoundedIcon from "@mui/icons-material/HourglassEmptyRounded";
import BookingModel from "../../../models/BookingModel";
import getStartMomentFromDateObj from "../../../utils/getStartMomentFromDateObj";
import moment from "moment";
import useCurrentBookingDateObj from "../../../hooks/useCurrentBookingDateObj";

const BookingTableDateTime: FC<BookingTableDateTimeProps> = ({ order }) => {
  const { dateObj } = useCurrentBookingDateObj({ order: order });

  const dateMomentToShow = useMemo<moment.Moment | null>(() => {
    if (!dateObj) return null;
    return getStartMomentFromDateObj({ date: dateObj?.date ?? "", time: dateObj?.time ?? "" });
  }, [dateObj]);

  return (
    <div style={{ fontWeight: "bold" }}>
      <div style={{ display: "flex", gap: "8px", marginBottom: "8px" }}>
        <BiCalendar size="19" color="#008690" />
        {dateMomentToShow?.format("DD/MM/YYYY")}
      </div>
      <div style={{ display: "flex", gap: "8px", marginBottom: "8px" }}>
        <FaRegClock size="18" color="#008690" /> {dateMomentToShow?.format("hh:mm A")}
      </div>
      {order.productType === "S-D" && (
        <div style={{ display: "flex", gap: "4px", alignItems: "center", marginLeft: "-3px" }}>
          <HourglassEmptyRoundedIcon sx={{ color: "#008690" }} />
          {`${dateObj?.numberOfDurationOrSession} ${dateObj?.numberOfDurationOrSession === 1 ? "Hour" : "Hours"}`}
        </div>
      )}
    </div>
  );
};

interface BookingTableDateTimeProps {
  order: BookingModel;
}

export default BookingTableDateTime;
