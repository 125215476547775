import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { Dropdown, Button, Switch, Divider } from "antd";
import { FC, useEffect, useMemo, useRef, useState } from "react";
import { BiCog } from "react-icons/bi";
import { useSelector } from "react-redux";
import { showMessage } from "../../components/common/notification";
import { StaffMemberModel } from "../../models/StaffMemberModel";
import { editDriver, nurseService } from "../../services";
import useAutomatedDriverScheduleQueryKey from "../../hooks/useAutomatedDriverScheduleQueryKey";
import ReduxStateModel from "../../models/ReduxStateModel";
import moment from "moment";
import useCurrentUserRole from "../../hooks/useCurrentUserRole";

const settingsButtonWith = "160px";

const DriverSettingsDropdownButton: FC = () => {
  const queryClient = useQueryClient();
  const partnerUUID = useSelector((state: ReduxStateModel) => state.authReducer?.setting?.partnerUUID ?? "");
  const partnerUserUUID: string | undefined = useSelector(
    (state: ReduxStateModel) => state?.authReducer?.setting?.partnerUserUUID
  );
  const selectedDate: moment.Moment = useSelector(
    (state: ReduxStateModel) => state?.driverSchedulerReducer?.selectedDate ?? moment()
  );
  const [openDropdown, setOpenDropdown] = useState<boolean>(false);
  const [activeSwitchValues, setActiveSwitchValues] = useState<boolean[]>([]);
  const originalActiveStatsRef = useRef<boolean[]>();
  const { canUpdate } = useCurrentUserRole();

  const automatedDriverScheduleQueryKey = useAutomatedDriverScheduleQueryKey({ selectedDate });

  const staffDataQueryKey: [string, string | undefined] = ["nurses", partnerUserUUID];

  const { data: staffDataResponse, isLoading } = useQuery(
    staffDataQueryKey,
    () => nurseService.getAllNurses(partnerUserUUID ?? ""),
    {
      enabled: !!partnerUserUUID,
      onSuccess: (res) => {
        if (!res?.data?.success) {
          showMessage("error", "Somethint went wrong");
        } else {
          const value = res?.data?.drivers?.map((driver: StaffMemberModel) => driver.isActive ?? false) ?? [];
          originalActiveStatsRef.current = structuredClone(value);
          setActiveSwitchValues(value);
        }
      },
      onError: () => {
        showMessage("error", "Somethint went wrong");
      },
    }
  );

  useEffect(() => {
    if (!openDropdown) {
      const value = staffDataResponse?.data?.drivers?.map((driver: StaffMemberModel) => driver.isActive ?? false) ?? [];
      originalActiveStatsRef.current = structuredClone(value);
      setActiveSwitchValues(value);
    }
  }, [openDropdown, staffDataResponse?.data?.drivers]);

  const editDriverMutation = useMutation({
    mutationFn: editDriver,
    onError: () => {
      showMessage("error", "Something went wrong");
    },
    onSuccess: (res) => {
      if (res?.success) {
        queryClient.resetQueries(automatedDriverScheduleQueryKey);
        queryClient.invalidateQueries(staffDataQueryKey);
      } else {
        showMessage("error", "Something went wrong");
      }
    },
  });

  const handleApplyClicked = () => {
    staffDataResponse?.data?.drivers?.forEach((driver: StaffMemberModel, index: number) => {
      editDriverMutation.mutate({
        activeDays: driver.activeDays ?? [],
        endTime: driver.endTime ?? "",
        startTime: driver.startTime ?? "",
        breakEndTime: driver.breakEndTime,
        breakStartTime: driver.breakStartTime,
        holidays: driver.holidays ?? [],
        isActive: activeSwitchValues[index],
        isDeleted: false,
        partnerUUID: partnerUUID,
        address: driver.address ?? "",
        addressLink: driver.addressLink ?? "",
        capacity: driver.capacity ?? 0,
        dob: driver.dob ?? "",
        driverUUID: driver.driverUUID!,
        email: driver.email ?? "",
        emiratesIdBackImageUrl: driver.emiratesIdBackImageUrl ?? "",
        emiratesIdFrontImageUrl: driver.emiratesIdFrontImageUrl ?? "",
        licenseImageUrl: driver.licenseImageUrl ?? "",
        mobileNumber: driver.mobileNumber ?? "",
        name: driver.name ?? "",
        nationality: driver.nationality ?? "",
        passportImageUrl: driver.passportImageUrl ?? "",
        profileImageUrl: typeof driver.profileImage === "string" ? driver.profileImage : "",
        profileImage: typeof driver.profileImage !== "string" ? driver.profileImage : undefined,
        sex: driver.sex ?? "",
      });
    });
    queryClient.setQueryData<typeof staffDataResponse>(staffDataQueryKey, (oldValue) => {
      if (!oldValue?.data) return oldValue;
      const newValue: typeof staffDataResponse = JSON.parse(JSON.stringify(oldValue));
      newValue!.data.drivers = newValue?.data.drivers?.map((member: StaffMemberModel, index: number) => {
        member.isActive = activeSwitchValues[index];
        return member;
      });
      return newValue;
    });
    setOpenDropdown(false);
  };

  const disabledDriversCount = useMemo<number>(() => {
    return staffDataResponse?.data?.drivers?.filter((driver: StaffMemberModel) => !driver.isActive).length ?? 0;
  }, [staffDataResponse?.data?.drivers]);

  const showApplyButton = useMemo<boolean>(() => {
    return activeSwitchValues.join("-") !== originalActiveStatsRef.current?.join("-");
  }, [activeSwitchValues]);

  return (
    <div style={{ position: "relative" }}>
      {disabledDriversCount > 0 && (
        <div style={{ position: "absolute", top: "-20px", left: "10px", fontSize: "12px" }}>
          {disabledDriversCount} disabled drivers
        </div>
      )}
      <Dropdown
        disabled={isLoading || !canUpdate}
        open={openDropdown}
        onOpenChange={(value) => {
          setOpenDropdown(value);
        }}
        trigger={["click"]}
        dropdownRender={(oldNode) => (
          <div
            style={{
              width: settingsButtonWith,
              backgroundColor: "white",
              borderRadius: "10px",
              overflow: "hidden",
              boxShadow: "0 3px 6px -4px rgb(0 0 0 / 12%), 0 6px 16px 0 rgb(0 0 0 / 8%), 0 9px 28px 8px rgb(0 0 0 / 5%)",
            }}
          >
            {oldNode}
          </div>
        )}
        className="auto-nav-driver-settings-dropdown"
        menu={{
          items: [
            ...(staffDataResponse?.data?.drivers ?? []).map((driver: StaffMemberModel, index: number) => {
              return {
                key: driver.driverUUID ?? "",
                label: (
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      cursor: "default",
                    }}
                  >
                    <div
                      style={{
                        color: "black",
                        fontWeight: "500",
                        fontSize: "14px",
                        maxWidth: "100px",
                        textOverflow: "ellipsis",
                        overflow: "hidden",
                      }}
                    >
                      {driver.name?.trim()?.split(" ")[0]}
                    </div>
                    <Switch
                      size="small"
                      checked={activeSwitchValues[index]}
                      className="driver-settings-dropdown-switch"
                      onChange={(value) => {
                        setActiveSwitchValues((prevValue) => {
                          const newValue = [...prevValue];
                          newValue[index] = value;
                          return newValue;
                        });
                      }}
                    />
                  </div>
                ),
                disabled: true,
              };
            }),
            {
              key: "divider",
              label: showApplyButton && <Divider style={{ marginTop: "4px", marginBottom: "4px" }} />,
              style: { display: showApplyButton ? "flex" : "none" },
            },
            {
              key: "bottom-buttons",
              label: (
                <div style={{ display: "flex", justifyContent: "flex-end" }}>
                  {showApplyButton && (
                    <Button
                      size="small"
                      onClick={handleApplyClicked}
                      style={{ borderRadius: "5px", fontSize: "12px", backgroundColor: "black", color: "white" }}
                    >
                      Apply
                    </Button>
                  )}
                </div>
              ),
            },
          ],
        }}
      >
        <Button
          size="large"
          loading={isLoading}
          style={{
            width: settingsButtonWith,
            minWidth: settingsButtonWith,
            border: "none",
            borderRadius: "10px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            gap: "4px",
            color: "black",
          }}
        >
          <BiCog />
          <div style={{ fontWeight: "500", fontSize: "14px" }}>Driver Settings</div>
        </Button>
      </Dropdown>
    </div>
  );
};

export default DriverSettingsDropdownButton;
