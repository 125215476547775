import { Box, Button, Dialog, DialogContent, IconButton } from "@mui/material";
import { FC, useState } from "react";
import { CiImageOn } from "react-icons/ci";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import LoadingSpinner from "../../components/LoadingSpinner";
import useHEICImageURL from "../../hooks/useHEICImageURL";

const NurseVerificationDocImage: FC<NurseVerificationDocImageProps> = ({
  imageURL,
  buttonColor,
  viewButtonText,
  alwaysHoverMode,
  alt,
}) => {
  const [selectedImage, setSelectedImage] = useState<string>();
  const isHEICImage = imageURL.toLocaleLowerCase().endsWith(".heic");
  const { imageUrl: heicImgURL, loading } = useHEICImageURL({ heicUrl: imageURL });

  return (
    <LoadingSpinner
      styles={{
        minHeight: "200px",
      }}
      children={
        <Box
          sx={{
            width: "100%",
            height: "100%",
            maxWidth: "100%",
            maxHeight: "100%",
            position: "relative",
            "& .overlay": {
              display: alwaysHoverMode ? "flex" : "none",
            },
            "&:hover .overlay": {
              display: "flex",
            },
          }}
        >
          <div
            style={{
              backgroundColor: "#00000085",
              position: "absolute",
              width: "100%",
              height: "100%",
              top: "0",
              left: "0",
              borderRadius: "8px",
              justifyContent: "center",
              alignItems: "center",
            }}
            className="overlay"
          >
            <Button
              startIcon={<CiImageOn />}
              variant="contained"
              color={buttonColor}
              onClick={() => {
                setSelectedImage(imageURL);
              }}
            >
              {viewButtonText ?? `View Image`}
            </Button>
          </div>
          {isHEICImage && loading ? null : (
            <img
              src={isHEICImage ? heicImgURL : imageURL}
              alt={alt}
              style={{
                width: "100%",
                height: "100%",
                maxWidth: "100%",
                maxHeight: "100%",
                borderRadius: "8px",
                objectFit: "contain",
              }}
            />
          )}
          <Dialog
            open={!!selectedImage}
            onClose={() => setSelectedImage(undefined)}
            fullWidth={true}
            maxWidth="lg"
            sx={{ "& .MuiPaper-root": { height: "100%" } }}
          >
            <DialogContent sx={{ height: "100%", maxHeight: "100%", position: "relative" }}>
              <IconButton onClick={() => setSelectedImage(undefined)} sx={{ position: "absolute", top: "10px", right: "10px" }}>
                <CloseRoundedIcon />
              </IconButton>
              <img
                src={isHEICImage ? heicImgURL : imageURL}
                alt={alt}
                style={{
                  width: "100%",
                  height: "100%",
                  borderRadius: "8px",
                  objectFit: "contain",
                }}
              />
            </DialogContent>
          </Dialog>
        </Box>
      }
      spinning={isHEICImage && loading}
    />
  );
};

interface NurseVerificationDocImageProps {
  imageURL: string;
  alt: string;
  viewButtonText?: string;
  buttonColor?: "primary" | "secondary" | "success" | "error" | "info" | "warning";
  alwaysHoverMode?: boolean;
}

export default NurseVerificationDocImage;
