import { useQuery } from "@tanstack/react-query";
import { useMemo } from "react";
import { getTokenForGetStream } from "../services";

function useGetTokenForGetStream(params: { userUUID: string }) {
  const queryKey = useMemo(() => {
    return ["apps/get-token-for-get-stream", params.userUUID];
  }, [params.userUUID]);

  const query = useQuery(queryKey, () => getTokenForGetStream(params), {
    enabled: !!params.userUUID,
    refetchOnWindowFocus: false,
    cacheTime: Infinity,
    staleTime: Infinity,
  });

  return { query, queryKey };
}

export default useGetTokenForGetStream;
