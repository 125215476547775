import { FC } from "react";
import { ConsultationLabTestModel } from "../../models/GetProductsForConsultationResponseModel";
import { Avatar, Checkbox, ListItem, ListItemAvatar, ListItemButton, ListItemText, Typography } from "@mui/material";

const SuggestLabTestsListItem: FC<SuggestLabTestsListItemProps> = ({ test, selected, onSelectClicked }) => {
  return (
    <ListItem
      sx={{
        borderBottom: "1px solid #cccccc",
        marginBottom: "1rem",
        "& .MuiListItemSecondaryAction-root .MuiButtonBase-root": {
          minWidth: "50px",
        },
        "& .MuiListItemButton-root": {
          alignItems: "flex-start",
        },
      }}
      secondaryAction={
        <Checkbox
          edge="end"
          onChange={() => {
            onSelectClicked(test);
          }}
          checked={selected}
        />
      }
      disablePadding
    >
      <ListItemButton
        onClick={() => {
          onSelectClicked(test);
        }}
      >
        <ListItemAvatar>
          <Avatar sx={{ borderRadius: "4px" }} alt={test.name} src={test.mainImageUrl} />
        </ListItemAvatar>
        <ListItemText
          primary={
            <Typography variant="subtitle1" sx={{}}>
              {test.name}
            </Typography>
          }
          secondary={<div>{test.engDescription}</div>}
        />
      </ListItemButton>
    </ListItem>
  );
};

interface SuggestLabTestsListItemProps {
  test: ConsultationLabTestModel;
  selected: boolean;
  onSelectClicked: (test: ConsultationLabTestModel) => void;
}

export default SuggestLabTestsListItem;
