import ReactDOM from "react-dom/client";
import history from "./utils/browserHistory";
import * as Sentry from "@sentry/react";
import { setAxiosConfig } from "./utils/axiosHelpers";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { Provider } from "react-redux";
import { ThemeProvider, StyledEngineProvider, createTheme } from "@mui/material/styles";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import store from "./store";
import App from "./App";
import "antd/dist/antd.css";
import "./index.css";

Sentry.init({
  dsn: "https://b7e6a8702f7aeab7c96dbad862dd336a@o4505578151411712.ingest.us.sentry.io/4507934124802048",
  integrations: [Sentry.browserTracingIntegration(), Sentry.replayIntegration()],
  tracesSampleRate: 1.0,
  tracePropagationTargets: ["localhost", /^https:\/\/dardoc\.com\/api/],
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
});

const queryClient = new QueryClient();

const theme = createTheme({
  typography: {
    fontFamily: `"Inter", "sans-serif"`,
  },
  palette: {
    primary: {
      main: "#25bcbd",
      dark: "#008690",
    },
    secondary: {
      main: "#BD2626",
    },
  },
});

setAxiosConfig();

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  // <React.StrictMode>
  // </React.StrictMode>
  <QueryClientProvider client={queryClient}>
    <Provider store={store}>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <StyledEngineProvider injectFirst>
          <ThemeProvider theme={theme}>
            <App history={history} />
          </ThemeProvider>
        </StyledEngineProvider>
      </LocalizationProvider>
    </Provider>
  </QueryClientProvider>
);
