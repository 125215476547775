import { Form, FormInstance, TimePicker } from "antd";
import { FC } from "react";
import { DriverForm } from "./OldDriverProfile";

const ShiftEndTimeFormItem: FC<ShiftEndTimeFormItemProps> = ({ form }) => {
  const startTime = Form.useWatch("startTime", form);

  return (
    <Form.Item
      name="endTime"
      label="End time"
      required={true}
      style={{ width: "100%" }}
      rules={[
        () => ({
          validator(_, value) {
            if (!value) {
              return Promise.reject("Please select driver's shift end time");
            } else if (startTime && value?.isSameOrBefore(startTime)) {
              return Promise.reject("Driver's shfit end time must be after shift start time");
            }
            return Promise.resolve();
          },
        }),
      ]}
    >
      <TimePicker
        minuteStep={5}
        style={{ width: "100%" }}
        format="hh:mmA"
        onSelect={(value) => {
          form.setFieldValue("endTime", value);
        }}
      />
    </Form.Item>
  );
};

interface ShiftEndTimeFormItemProps {
  form: FormInstance<DriverForm>;
}

export default ShiftEndTimeFormItem;
