import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import { FC } from "react";
import { BookingPatientModel } from "../../models/BookingModel";

const UploadReportSelectPatientField: FC<UploadReportSelectPatientFieldProps> = ({ onChange, value, patients, disabled }) => {
  return (
    <FormControl fullWidth disabled={disabled}>
      <InputLabel id="upload-report-select-patient-label">Select Patient</InputLabel>
      <Select
        labelId="upload-report-select-patient-label"
        id="upload-report-select-patient"
        label="Select Patient"
        value={value}
        onChange={(e) => onChange(e.target.value)}
        className="upload-report-select-patient-field"
      >
        {patients.map((p) => {
          return (
            <MenuItem key={p._id} value={p._id}>
              {p.name}
            </MenuItem>
          );
        })}
      </Select>
    </FormControl>
  );
};

interface UploadReportSelectPatientFieldProps {
  value?: string;
  patients: BookingPatientModel[];
  disabled?: boolean;
  onChange: (value: string) => void;
}

export default UploadReportSelectPatientField;
