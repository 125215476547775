import { TextField } from "@mui/material";
import { grey } from "@mui/material/colors";
import { FC } from "react";

const PharmacyTractionTableSearchInput: FC<PharmacyTractionTableSearchInputProps> = ({ onChange, value }) => {
  return (
    <TextField
      size="small"
      id="outlined-basic"
      placeholder="Search bookingID or patient name"
      variant="outlined"
      value={value}
      onChange={(e) => {
        onChange(e.target.value);
      }}
      sx={{
        width: "400px",
        height: "32px",
        "& .MuiInputBase-input::placeholder": {
          color: grey[600],
          fontSize: "14px",
        },
        "& .MuiInputBase-root": {
          height: "32px",
        },
        "& .MuiOutlinedInput-notchedOutline": {
          borderRadius: "10px",
          borderColor: "#d9d9d9",
        },
      }}
    />
  );
};

interface PharmacyTractionTableSearchInputProps {
  value: string;
  onChange: (v: string) => void;
}

export default PharmacyTractionTableSearchInput;
