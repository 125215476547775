import moment from "moment";
import MonthViewCellBookingTile from "./MonthViewCellBookingTile";
import { FC, useMemo } from "react";
import { useHistory, useRouteMatch } from "react-router-dom";
import { NursesDatum, NurseBookingModel, getNursePatientsList } from "../../models/SchedulerExploreViewResponseModel";
import { AiOutlinePlus } from "react-icons/ai";
import useCurrentUserRole from "../../hooks/useCurrentUserRole";
import useNurseDetails from "../../hooks/useNurseDetails";

const MonthViewTableCell: FC<MonthViewTableCellProps> = ({ cellBookings, cellDayMoment, nurseData }) => {
  const history = useHistory();
  const { url } = useRouteMatch();
  const { canUpdate } = useCurrentUserRole();

  const handleAssignNurseClicked = () => {
    history.push(`${url}/assign-patients`, {
      initialSelectedDate: cellDayMoment.valueOf(),
      nurseUUID: nurseData.nurseUUID,
      patientsList: getNursePatientsList(nurseData),
      nurseData: nurseData,
    });
  };

  const { query } = useNurseDetails({ nurseUUID: nurseData.nurseUUID });

  const isOnLeave = useMemo(() => {
    const cellWeekDayName = cellDayMoment.format("dddd").toLocaleLowerCase();
    if (!query.data?.nurse?.activeDays?.includes(cellWeekDayName)) {
      return true;
    }
    return nurseData.holidays?.some((offDate) => offDate === cellDayMoment.format("DD/MM/YYYY"));
  }, [cellDayMoment, nurseData.holidays, query.data?.nurse?.activeDays]);

  const startOfToday = useMemo(() => moment().startOf("day"), []);

  const canAddBooking = useMemo(() => {
    return !isOnLeave && cellDayMoment.isSameOrAfter(startOfToday) && canUpdate;
  }, [canUpdate, cellDayMoment, isOnLeave, startOfToday]);

  const key = `${nurseData.nurseUUID}-${cellDayMoment.valueOf()}`;

  const handleRemoveLeaveClicked = () => {
    history.push("/scheduler/remove-nurse-on-leave", {
      nurseData,
      patientsList: getNursePatientsList(nurseData),
      initialSelectedDate: cellDayMoment.valueOf(),
    });
  };

  const buildOnLeaveContent: () => JSX.Element = () => {
    return (
      <div
        onClick={handleRemoveLeaveClicked}
        style={{
          flex: "auto",
          width: "100%",
          // height: "97%",
          backgroundColor: "#ffe4e1",
          cursor: "pointer",
        }}
      />
    );
  };

  const content = (
    <>
      {cellBookings?.map((booking) => {
        const key =
          booking.nurseOrderID ??
          `${booking.nurseUUID}-${cellDayMoment.format()}-${booking.startMoment.format()}-${booking.endMoment.format()}`;
        return <MonthViewCellBookingTile key={key} id={key} booking={booking} nurseData={nurseData} />;
      })}
      {canAddBooking && (
        <div
          style={{
            height: "10px",
            width: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            cursor: canAddBooking ? "pointer" : "default",
          }}
        >
          <AiOutlinePlus />
        </div>
      )}
      {isOnLeave && buildOnLeaveContent()}
    </>
  );

  return (
    <div
      key={key}
      id={key}
      className="cell empty-cell"
      onClick={canAddBooking ? handleAssignNurseClicked : undefined}
      style={{ padding: "2px 0px" }}
    >
      {content}
    </div>
  );
};

interface MonthViewTableCellProps {
  nurseData: NursesDatum;
  cellDayMoment: moment.Moment;
  cellBookings?: NurseBookingModel[];
}

export default MonthViewTableCell;
