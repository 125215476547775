import { FC, useCallback, useMemo, useState } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  InputAdornment,
  OutlinedInput,
} from "@mui/material";
import { IoCallOutline } from "react-icons/io5";
import { LoadingButton } from "@mui/lab";
import BookingModel from "../models/BookingModel";
import { useMutation } from "@tanstack/react-query";
import { createKnolarityCall } from "../services";
import { showMessage } from "./common/notification";

const CallUserButton: FC<CallUserButtonProps> = ({ order }) => {
  const [open, setOpen] = useState(false);
  const [agentPhoneNumber, setAgentPhoneNumber] = useState("");

  const customerPhoneNumber = useMemo(() => {
    if (order.alternatePhoneNumber) {
      return order.alternatePhoneNumber;
    } else if (order.guardianData?.phoneNumber) {
      return order.guardianData?.phoneNumber;
    } else if (order.patient?.[0]?.phoneNumber) {
      return order.patient?.[0]?.phoneNumber;
    }
  }, [order.alternatePhoneNumber, order.guardianData?.phoneNumber, order.patient]);

  const handleClose = useCallback(() => {
    setOpen(false);
  }, []);

  const callUserMutation = useMutation({ mutationFn: createKnolarityCall });

  const handleFormSubmit = useCallback(async () => {
    const res = await callUserMutation.mutateAsync({
      agentNumber: agentPhoneNumber.startsWith("0") ? `+971${agentPhoneNumber.slice(1)}` : `+971${agentPhoneNumber}`,
      customerNumber: customerPhoneNumber ?? "",
    });
    if (res.success) {
      showMessage("success", "You will receive a phone call shortly");
      handleClose();
    } else {
      showMessage("error", "Something went wrong");
    }
  }, [agentPhoneNumber, callUserMutation, customerPhoneNumber, handleClose]);

  return (
    <>
      <Button startIcon={<IoCallOutline />} variant="outlined" size="small" onClick={() => setOpen(true)}>
        Call Customer
      </Button>
      <Dialog
        fullWidth={true}
        maxWidth="sm"
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <form
          onSubmit={(e) => {
            e.preventDefault();
            handleFormSubmit();
          }}
        >
          <DialogTitle id="alert-dialog-title">
            <div>Please enter your phone number</div>
            <div style={{ fontSize: "12px", color: "grey" }}>
              Once you place a request for a call, we will call you first and then connect you with the customer.
            </div>
          </DialogTitle>
          <DialogContent>
            {/* <DialogContentText id="alert-dialog-description">Description</DialogContentText> */}
            <FormControl variant="outlined" size="small" fullWidth={true} required={true}>
              <OutlinedInput
                startAdornment={<InputAdornment position="start">+971</InputAdornment>}
                value={agentPhoneNumber}
                onChange={(e) => {
                  setAgentPhoneNumber(e.target.value);
                }}
                inputProps={{
                  "aria-label": "Phone number",
                }}
              />
            </FormControl>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} variant="outlined">
              Cancel
            </Button>
            <LoadingButton
              loading={callUserMutation.isLoading}
              disabled={callUserMutation.isLoading}
              autoFocus
              type="submit"
              variant="outlined"
              startIcon={<IoCallOutline />}
            >
              Call
            </LoadingButton>
          </DialogActions>
        </form>
      </Dialog>
    </>
  );
};

interface CallUserButtonProps {
  order: BookingModel;
}

export default CallUserButton;
