import { InfiniteData, useInfiniteQuery } from "@tanstack/react-query";
import { GetPartnerOrdersResponseModel, getPartnerOrders } from "../services";
import { BookingStatusType } from "../models/BookingModel";
import { useEffect, useMemo } from "react";
import { useSelector } from "react-redux";
import ReduxStateModel from "../models/ReduxStateModel";

function usePartnerOrders({
  status,
  loadMoreElementInView,
  enabled = true,
  onSuccess,
}: {
  status?: BookingStatusType;
  loadMoreElementInView: boolean;
  enabled?: boolean;
  onSuccess?: (value: InfiniteData<GetPartnerOrdersResponseModel | null>) => void;
}) {
  const authReducer = useSelector((state: ReduxStateModel) => state.authReducer?.setting);

  const queryKey = useMemo(() => {
    return ["/api/v1/orders/ordersforpartner", authReducer?.partnerUserUUID, status];
  }, [authReducer?.partnerUserUUID, status]);

  const query = useInfiniteQuery(
    queryKey,
    (queryParams) =>
      getPartnerOrders({
        pageNumber: queryParams.pageParam || 1,
        partnerUserUUID: authReducer?.partnerUserUUID ?? "",
        status: status ?? "ACCEPTED",
      }),
    {
      enabled: enabled,
      refetchOnWindowFocus: true,
      onSuccess: onSuccess,
      getNextPageParam: (res, allPages) => {
        if (res?.hasNextPage) {
          return allPages.length + 1;
        }
        return null;
      },
      getPreviousPageParam: (_, pages) => {
        if (pages.length <= 1) return 1;
        return pages.length - 1;
      },
    }
  );

  const { hasNextPage, fetchNextPage } = query;

  useEffect(() => {
    if (hasNextPage && loadMoreElementInView) {
      fetchNextPage();
    }
  }, [fetchNextPage, hasNextPage, loadMoreElementInView]);

  return { queryKey, query };
}

export default usePartnerOrders;
