import momentTimezone from "moment-timezone";
import MangePartnerUsers from "./MangePartnerUsers";
import ManagePartnerTime from "../../components/ManagePartnerTime";
import ManageServices from "../../components/ManageServices";
import useCurrentPartnerDetails from "../../hooks/useCurrentPartnerDetails";
import BasePageLayoutSideMenuItems, { deliveryPartnerMenuItems } from "../../components/BasePageLayoutSideMenuItems";
import { useState, useEffect, useMemo, useCallback } from "react";
import { useHistory } from "react-router-dom";
import { CircularProgress } from "@mui/material";
import { useSelector, useDispatch } from "react-redux";
import { settingAction, authAction, nurseAction } from "../../actions/index";
import { Row, Col } from "antd";
import BasePageLayout from "../../components/BasePageLayout";
import "./style.css";

function Settings({ showPartnerTime = true, showServices = true, showBaseLayout = true }) {
  const history = useHistory();
  const dispatch = useDispatch();
  const { partnerUUID } = useMemo(() => {
    try {
      return localStorage.setting ? JSON?.parse(localStorage.setting) : "";
    } catch (error) {
      console.log("--- Settings error JSON?.parse(localStorage.setting)");
    }
  }, []);

  const { isDeliveryPartner } = useCurrentPartnerDetails();

  const manageDateTime = useSelector((state) => state.settingReducer.manageDateTime);
  const settingLoader = useSelector((state) => state.settingReducer.settingLoader);

  const [dateTime, setdateTime] = useState({});

  const getSettingsPage = useCallback(() => {
    dispatch(settingAction.getManageDateTime(JSON?.parse(localStorage.partnerUserUUID)));
  }, [dispatch]);

  const handleTimeUpdateSuccess = useCallback(
    (name) => {
      getSettingsPage();

      if (name === "standardWorkingHr") {
        let body = {
          nurseUUID: "",
          isShiftTimeChange: false,
          isNewNurse: false,
          newDays: [],
          newService: [],
          newHoliday: [],
          newLocation: [],
          deletedService: [],
          deletedHoliday: [],
          deletedDays: [],
          deletedLocation: [],
          partnerId: partnerUUID,
          isNewStandardWorkingHr: true,
        };

        dispatch(nurseAction.updateNurseTimeslot(body));
      }
    },
    [dispatch, getSettingsPage, partnerUUID]
  );

  const createTimeSchedule = useCallback(
    (data, name) => {
      dispatch(settingAction.createManageDateTime(data, () => handleTimeUpdateSuccess(name)));
    },
    [dispatch, handleTimeUpdateSuccess]
  );

  const editTimeSchedule = useCallback(
    (data, name) => {
      dispatch(settingAction.updateManageDateTime(data, () => handleTimeUpdateSuccess(name)));
    },
    [dispatch, handleTimeUpdateSuccess]
  );

  const handleSubmitChange = useCallback(
    (mydata, name) => {
      let data = mydata;

      let myarray = manageDateTime;

      myarray.partnerSettings.partnerUUID = partnerUUID;
      myarray.partnerSettings.partnerUserUUID = JSON?.parse(localStorage.partnerUserUUID);

      let dayName = [];

      if (name === "standardWorkingHr") {
        if (data?.standardWorkingHr?.isStartEdited) {
          delete data?.standardWorkingHr?.isStartEdited;
          data.standardWorkingHr.startTime = momentTimezone
            .tz(data?.standardWorkingHr?.startTime, "HH:mm", "Asia/Dubai")
            .toISOString();
        }

        if (data?.standardWorkingHr?.isEndEdited) {
          delete data?.standardWorkingHr?.isEndEdited;
          data.standardWorkingHr.endTime = momentTimezone
            .tz(data?.standardWorkingHr?.endTime, "HH:mm", "Asia/Dubai")
            .toISOString();
        }
      }

      if (name === "week") {
        for (let w = 0; w < data?.workingHourPerDay?.length; w++) {
          for (let t = 0; t < data?.workingHourPerDay[w]?.timeSlot?.length; t++) {
            if (data?.workingHourPerDay[w]?.timeSlot[t]?.isStartEdited) {
              if (!dayName?.includes(data?.workingHourPerDay[w]?.dayName)) {
                dayName.push(data?.workingHourPerDay[w]?.dayName);
              }

              delete data?.workingHourPerDay[w]?.timeSlot[t]?.isStartEdited;
              data.workingHourPerDay[w].timeSlot[t].timeStart = momentTimezone
                .tz(data.workingHourPerDay[w].timeSlot[t].timeStart, "HH:mm", "Asia/Dubai")
                .toISOString();
            }

            if (data?.workingHourPerDay[w]?.timeSlot[t]?.isEndEdited) {
              if (!dayName?.includes(data?.workingHourPerDay[w]?.dayName)) {
                dayName.push(data?.workingHourPerDay[w]?.dayName);
              }

              delete data?.workingHourPerDay[w]?.timeSlot[t]?.isEndEdited;
              if (data.workingHourPerDay[w].timeSlot[t].timeEnd === "24:00") {
                data.workingHourPerDay[w].timeSlot[t].timeEnd = momentTimezone.tz("00:00", "HH:mm", "Asia/Dubai").toISOString();
              } else {
                data.workingHourPerDay[w].timeSlot[t].timeEnd = momentTimezone
                  .tz(data.workingHourPerDay[w].timeSlot[t].timeEnd, "HH:mm", "Asia/Dubai")
                  .toISOString();
              }
            }
          }
        }
      }

      if (name === "group") {
        for (let g = 0; g < data?.group?.length; g++) {
          for (let s = 0; s < data?.group[g]?.specialHour?.length; s++) {
            delete data?.group[g]?.specialHour[s]?.isEdited;

            if (data?.group[g]?.specialHour[s]?.isStartEdited) {
              delete data?.group[g]?.specialHour[s]?.isStartEdited;

              data.group[g].specialHour[s].timeStart = momentTimezone
                .tz(data?.group[g]?.specialHour[s].timeStart, "HH:mm", "Asia/Dubai")
                .toISOString();
            }

            if (data?.group[g]?.specialHour[s]?.isEndEdited) {
              delete data?.group[g]?.specialHour[s]?.isEndEdited;
              if (data.group[g].specialHour[s].timeEnd === "24:00") {
                data.group[g].specialHour[s].timeEnd = momentTimezone.tz("00:00", "HH:mm", "Asia/Dubai").toISOString();
              } else {
                data.group[g].specialHour[s].timeEnd = momentTimezone
                  .tz(data?.group[g]?.specialHour[s].timeEnd, "HH:mm", "Asia/Dubai")
                  .toISOString();
              }
            }
          }

          for (let w = 0; w < data?.group[g]?.bookingAcceptanceHourPerDay?.length; w++) {
            for (let t = 0; t < data?.group[g]?.bookingAcceptanceHourPerDay[w]?.timeSlot?.length; t++) {
              if (data?.group[g]?.bookingAcceptanceHourPerDay[w]?.timeSlot[t]?.isStartEdited) {
                delete data?.group[g]?.bookingAcceptanceHourPerDay[w]?.timeSlot[t]?.isStartEdited;

                data.group[g].bookingAcceptanceHourPerDay[w].timeSlot[t].timeStart = momentTimezone
                  .tz(data.group[g].bookingAcceptanceHourPerDay[w].timeSlot[t].timeStart, "HH:mm", "Asia/Dubai")
                  .toISOString();
              }

              if (data?.group[g]?.bookingAcceptanceHourPerDay[w]?.timeSlot[t]?.isEndEdited) {
                delete data?.group[g]?.bookingAcceptanceHourPerDay[w]?.timeSlot[t]?.isEndEdited;

                if (data.group[g].bookingAcceptanceHourPerDay[w].timeSlot[t].timeEnd === "24:00") {
                  data.group[g].bookingAcceptanceHourPerDay[w].timeSlot[t].timeEnd = momentTimezone
                    .tz("00:00", "HH:mm", "Asia/Dubai")
                    .toISOString();
                } else {
                  data.group[g].bookingAcceptanceHourPerDay[w].timeSlot[t].timeEnd = momentTimezone
                    .tz(data.group[g].bookingAcceptanceHourPerDay[w].timeSlot[t].timeEnd, "HH:mm", "Asia/Dubai")
                    .toISOString();
                }
              }
            }
          }
        }
      }

      if (name === "special") {
        for (let s = 0; s < data?.specialHour?.length; s++) {
          if (data?.specialHour[s]?.isStartEdited) {
            delete data?.specialHour[s]?.isStartEdited;

            data.specialHour[s].timeStart = momentTimezone
              .tz(data?.specialHour[s]?.timeStart, "HH:mm", "Asia/Dubai")
              .toISOString();
          }
          if (data?.specialHour[s]?.isEndEdited) {
            delete data?.specialHour[s]?.isEndEdited;

            if (data.specialHour[s].timeEnd === "24:00") {
              data.specialHour[s].timeEnd = momentTimezone.tz("00:00", "HH:mm", "Asia/Dubai").toISOString();
            } else {
              data.specialHour[s].timeEnd = momentTimezone.tz(data?.specialHour[s]?.timeEnd, "HH:mm", "Asia/Dubai").toISOString();
            }
          }
        }
      }

      if (name === "partnerUser") {
        for (let i = 0; i < data?.partnerUser?.length; i++) {
          if (!data?.partnerUser[i]?.partnerUserUUID && data?.partnerUser[i]?.password === "") {
            delete data.partnerUser[i].password;
          }

          if (data?.partnerUser[i]?.isEdit) {
            console.log();
          } else {
            data.partnerUser[i].isEdit = false;
          }
        }
      }

      if (name === "week") {
        myarray.partnerSettings.workingHourPerDay = data.workingHourPerDay;
      } else if (name === "holiday") {
        myarray.partnerSettings.holiday = data.holiday;
      } else if (name === "service") {
        myarray.partnerSettings.service = [...data.service];
      } else if (name === "group") {
        myarray.partnerSettings.group = [...data.group];
      } else if (name === "partnerUser") {
        myarray.partnerSettings.partnerUser = [...data.partnerUser];
      } else if (name === "standardWorkingHr") {
        myarray.partnerSettings.standardWorkingHr = data.standardWorkingHr;
      } else {
        myarray.partnerSettings.specialHour = data.specialHour;
      }
      if (dateTime?.partnerSettings?._id) {
        editTimeSchedule(myarray?.partnerSettings, name);
      } else {
        myarray.partnerUUID = partnerUUID;
        createTimeSchedule(myarray?.partnerSettings, name);
      }
    },
    [createTimeSchedule, dateTime?.partnerSettings?._id, editTimeSchedule, manageDateTime, partnerUUID]
  );

  useEffect(() => {
    setdateTime({});

    setTimeout(() => setdateTime(manageDateTime), 0);
  }, [manageDateTime]);

  const { userName, phoneNumber } = useMemo(() => {
    try {
      return localStorage.setting ? JSON.parse(localStorage.setting) : {};
    } catch (e) {
      return {};
    }
  }, []);

  useEffect(() => {
    const role = localStorage?.role;
    if (JSON?.parse(role) === "ANALYST") {
      history.push("/");
    }
    getSettingsPage();
    document.title = `DarDoc: Settings`;
    dispatch(authAction.getUserData({ userName: userName, phoneNumber: phoneNumber }));
  }, [dispatch, getSettingsPage, history, phoneNumber, userName]);

  const content = (
    <div className="">
      {/* {renderSideMenu && <Sidebar active="settings" />} */}
      <div
        className="main_page"
        style={{
          height: "100%",
          padding: "1rem",
          overflowY: "scroll",
          width: "100%",
        }}
      >
        <Row
          style={{
            rowGap: "2rem",
            alignItems: "center",
            justifyContent: "center",
            width: "100%",
          }}
        >
          {/* <Col span={24}>
<PageHeader pagename="Settings" content={settingContent} />
</Col> */}
          {!settingLoader ? (
            <>
              <Col span={24}>
                <MangePartnerUsers />
              </Col>
              {showPartnerTime && !isDeliveryPartner && (
                <Col span={24}>
                  <ManagePartnerTime
                    data={dateTime}
                    loader={settingLoader}
                    saveChange={handleSubmitChange}
                    getSettingsPage={getSettingsPage}
                  />
                </Col>
              )}
              {showServices && !isDeliveryPartner && (
                <Col span={24}>
                  <ManageServices
                    data={dateTime}
                    loader={settingLoader}
                    saveChange={handleSubmitChange}
                    getSettingsPage={getSettingsPage}
                  />
                </Col>
              )}
            </>
          ) : (
            <Col span={24}>
              <Row style={{ justifyContent: "center", width: "100%" }}>
                <CircularProgress />
              </Row>
            </Col>
          )}
        </Row>
      </div>
    </div>
  );

  if (!showBaseLayout) {
    return content;
  }

  return (
    <BasePageLayout
      mainContent={content}
      sideMenuItems={isDeliveryPartner ? deliveryPartnerMenuItems : BasePageLayoutSideMenuItems}
    />
  );
}

export default Settings;
