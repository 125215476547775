export const timevalues = [
  "00:00",
  "00:30",
  "01:00",
  "01:30",
  "02:00",
  "02:30",
  "03:00",
  "03:30",
  "04:00",
  "04:30",
  "05:00",
  "05:30",
  "06:00",
  "06:30",
  "07:00",
  "07:30",
  "08:00",
  "08:30",
  "09:00",
  "09:30",
  "10:00",
  "10:30",
  "11:00",
  "11:30",
  "12:00",
  "12:30",
  "13:00",
  "13:30",
  "14:00",
  "14:30",
  "15:00",
  "15:30",
  "16:00",
  "16:30",
  "17:00",
  "17:30",
  "18:00",
  "18:30",
  "19:00",
  "19:30",
  "20:00",
  "20:30",
  "21:00",
  "21:30",
  "22:00",
  "22:30",
  "23:00",
  "23:30",
  "23:59",
];

// const timeslot = [
//   "12:00AM - 12:30AM",
//   "12:30AM - 01:00AM",
//   "01:00AM - 01:30AM",
//   "01:30AM - 02:00AM",
//   "02:00AM - 02:30AM",
//   "02:30AM - 03:00AM",
//   "03:00AM - 03:30AM",
//   "03:30AM - 04:00AM",
//   "04:00AM - 04:30AM",
//   "04:30AM - 05:00AM",
//   "05:00AM - 05:30AM",
//   "05:30AM - 06:00AM",
//   "06:00AM - 06:30AM",
//   "06:30AM - 07:00AM",
//   "07:00AM - 07:30AM",
//   "07:30AM - 08:00AM",
//   "08:00AM - 08:30AM",
//   "08:30AM - 09:00AM",
//   "09:00AM - 09:30AM",
//   "09:30AM - 10:00AM",
//   "10:00AM - 10:30AM",
//   "10:30AM - 11:00AM",
//   "11:00AM - 11:30AM",
//   "11:30AM - 12:00PM",
//   "12:00PM - 12:30PM",
//   "12:30PM - 01:00PM",
//   "01:00PM - 01:30PM",
//   "01:30PM - 02:00PM",
//   "02:00PM - 02:30PM",
//   "02:30PM - 03:00PM",
//   "03:00PM - 03:30PM",
//   "03:30PM - 04:00PM",
//   "04:00PM - 04:30PM",
//   "04:30PM - 05:00PM",
//   "05:00PM - 05:30PM",
//   "05:30PM - 06:00PM",
//   "06:00PM - 06:30PM",
//   "06:30PM - 07:00PM",
//   "07:00PM - 07:30PM",
//   "07:30PM - 08:00PM",
//   "08:00PM - 08:30PM",
//   "08:30PM - 09:00PM",
//   "09:00PM - 09:30PM",
//   "09:30PM - 10:00PM",
//   "10:00PM - 10:30PM",
//   "10:30PM - 11:00PM",
//   "11:00PM - 11:30PM",
//   "11:30PM - 12:00AM",
// ];
