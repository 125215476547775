import moment from "moment";
import NurseOrderModel from "../models/NurseOrderModel";
import axios from "../utils/axiosHelpers";
import decrypt from "./decrypt";
import encrypt from "./encrypt";
import Mixpanel from "../utils/Mixpanel";
import BookingModel, { BookingStatusType } from "../models/BookingModel";
import BaseResponseModel from "../models/BaseResponseModel";

export const nurseOrdersService = {
  createNurseOrder,
  editNurseOrder,
  createMultipleDaysNurseOrder,
};

export interface TemporaryAddressModel {
  address: string;
  addressLink: string;
}

export interface GetPartnerOrdersResponseModel extends BaseResponseModel {
  orders?: BookingModel[];
  hasNextPage?: boolean;
}

export async function getPartnerOrders(params: {
  partnerUserUUID: string;
  pageNumber: number;
  status: BookingStatusType;
}): Promise<GetPartnerOrdersResponseModel | null> {
  try {
    const res = await axios.get<string>(
      `/api/v1/orders/ordersforpartner/${params.partnerUserUUID}/${params.pageNumber}/${params.status}`
    );
    const data: GetPartnerOrdersResponseModel = decrypt(res.data);
    data.hasNextPage = (data.orders?.length ?? 0) > 0;
    // console.log("getPartnerOrders", params.pageNumber);
    // console.log(params);
    return data;
  } catch (error) {
    console.log("--- getPartnerOrders error", error);
    return null;
  }
}

async function createMultipleDaysNurseOrder(params: {
  nurseUUID: string;
  service?: string;
  caseDescription?: string;
  patientName: string;
  dates: { startTime: string; endTime: string }[];
  locationLat?: string;
  locationLng?: string;
  address: string;
  addressDetails: string;
  temporaryPickupAddress?: TemporaryAddressModel;
  temporaryDropOffAddress?: TemporaryAddressModel;
  type: "BOOKING" | "BREAK";
}): Promise<CreateMultipleDaysNurseOrderResponseModel | null> {
  try {
    const res = await axios.post<string>("/api/v1/nurse-orders/create-multiple-days-nurse-order", { data: encrypt(params) });
    const data: CreateMultipleDaysNurseOrderResponseModel = decrypt(res.data);
    Mixpanel.track("Add nurse multiple days order", {
      ...params,
      user: localStorage.getItem("partnerUserUUID"),
    });
    return data;
  } catch (e) {
    console.log("--- createMultipleDaysNurseOrder", e);
    return null;
  }
}

async function editNurseOrder(params: {
  nurseUUID: string;
  newStartTime: moment.Moment;
  oldStartTime: moment.Moment;
  newEndTime: moment.Moment;
  patientName: string;
  service?: string;
  caseDescription?: string;
  nurseOrderID: string;
  locationLat: string;
  locationLng: string;
  address: string;
  addressDetails: string;
  temporaryPickupAddress?: TemporaryAddressModel;
  temporaryDropOffAddress?: TemporaryAddressModel;
}): Promise<CreateNurseOrderResponseModel | null> {
  try {
    const body = {
      nurseUUID: params.nurseUUID,
      newStartTime: params.newStartTime.format(),
      oldStartTime: params.oldStartTime.format(),
      newEndTime: params.newEndTime.format(),
      patientName: params.patientName,
      service: params.service,
      caseDescription: params.caseDescription,
      nurseOrderID: params.nurseOrderID,
      locationLat: params.locationLat,
      locationLng: params.locationLng,
      address: params.address,
      addressDetails: params.addressDetails,
      temporaryPickupAddress: params.temporaryPickupAddress,
      temporaryDropOffAddress: params.temporaryDropOffAddress,
    };
    const res = await axios.post<string>("/api/v1/nurse-orders/edit-nurse-orders", {
      data: encrypt(body),
    });
    const decryptedBody: CreateNurseOrderResponseModel = decrypt(res.data);
    Mixpanel.track("Edit nurse order", {
      ...params,
      user: localStorage.getItem("partnerUserUUID"),
    });
    return decryptedBody;
  } catch (e) {
    console.log("--- editNurseOrder error");
    console.log(e);
    return null;
  }
}

async function createNurseOrder(params: {
  nurseUUID: string;
  startTime: moment.Moment;
  endTime: moment.Moment;
  patientName: string;
  service?: string;
  caseDescription?: string;
}): Promise<CreateNurseOrderResponseModel | null> {
  try {
    const body = {
      nurseUUID: params.nurseUUID,
      startTime: params.startTime.format(),
      endTime: params.endTime.format(),
      patientName: params.patientName,
      service: params.service,
      caseDescription: params.caseDescription,
    };
    const res = await axios.post<string>("/api/v1/nurse-orders/create-nurse-orders", {
      data: encrypt(body),
    });
    const decryptedBody: CreateNurseOrderResponseModel = decrypt(res.data);
    Mixpanel.track("Edit nurse order", {
      ...params,
      user: localStorage.getItem("partnerUserUUID"),
    });
    return decryptedBody;
  } catch (e) {
    console.log("--- createNurseOrder", e);
    console.log(e);
    return null;
  }
}

interface CreateMultipleDaysNurseOrderResponseModel {
  nurseOrder?: NurseOrderModel[];
  success: number | boolean;
}

interface CreateNurseOrderResponseModel {
  nurseOrder?: NurseOrderModel;
  success: number | boolean;
}
