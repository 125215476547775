import { Button, FormControl, FormControlLabel, FormLabel, Radio, RadioGroup } from "@mui/material";
import { FC, useCallback, useState } from "react";
import { FilterBookingsType, SortBookingsType } from "./SearchAndFilterBookingButton";
import { StaffMemberModel } from "../models/StaffMemberModel";
import NurseMultipleSelectDropdown from "./NurseMultipleSelectDropdown";
import ServiceMultipleSelectDropdown from "./ServiceMultipleSelectDropdown";
import ProductModel from "../models/ProductModel";

const SearchAndFilterBookingsDrawer: FC<SearchAndFilterBookingsDrawerProps> = ({
  onApplyFilterClicked,
  onClearFilterClicked,
  initialValues,
}) => {
  const [sortValue, setSortValue] = useState<SortBookingsType>(initialValues?.sort ?? "booking-date-decsending");
  const [selectedFilterNurses, setSelectedFilterNurses] = useState<StaffMemberModel[]>(initialValues?.selectedFilterNurses ?? []);
  const [selectedFilterProducts, setSelectedFilterProducts] = useState<ProductModel[]>(
    initialValues?.selectedFilterProducts ?? []
  );

  const handleClearAll = useCallback(() => {
    onClearFilterClicked();
    setSortValue("booking-date-decsending");
    setSelectedFilterNurses([]);
    setSelectedFilterProducts([]);
  }, [onClearFilterClicked]);

  const handleApplyClicked = useCallback(() => {
    const value: FilterBookingsType = {};
    if (selectedFilterNurses.length) {
      value.selectedFilterNurses = selectedFilterNurses;
    }
    if (selectedFilterProducts.length) {
      value.selectedFilterProducts = selectedFilterProducts;
    }
    if (sortValue) {
      value.sort = sortValue;
    }
    if (Object.keys(value).length) {
      onApplyFilterClicked(value);
    } else {
      handleClearAll();
    }
  }, [handleClearAll, onApplyFilterClicked, selectedFilterNurses, selectedFilterProducts, sortValue]);

  return (
    <>
      <div style={{ display: "flex", justifyContent: "space-between", marginBottom: "32px" }}>
        <Button variant="outlined" onClick={handleClearAll}>
          {Object.keys(initialValues ?? {}).length > 0 ? "Clear All" : "Close"}
        </Button>
        <Button variant="contained" onClick={handleApplyClicked} disableElevation={true}>
          <span style={{ color: "white" }}>Apply</span>
        </Button>
      </div>
      <FormControl>
        <FormLabel id="sort-by-radio-label">Sort By</FormLabel>
        <RadioGroup
          value={sortValue}
          onChange={(e) => {
            setSortValue(e.target.value as SortBookingsType);
          }}
          aria-labelledby="sort-by-radio-label"
          defaultValue="female"
          name="sort-by-radio-group"
        >
          <FormControlLabel value="booking-date-ascending" control={<Radio />} label="Booking Date (Acending)" />
          <FormControlLabel value="booking-date-decsending" control={<Radio />} label="Booking Date (Decending)" />
        </RadioGroup>
      </FormControl>
      <NurseMultipleSelectDropdown selectedNurses={selectedFilterNurses} onSelectedNursesChanged={setSelectedFilterNurses} />
      <ServiceMultipleSelectDropdown
        selectedProducts={selectedFilterProducts}
        onSelectedProductsChange={setSelectedFilterProducts}
      />
      {/* <div>
<DatePicker />
<DatePicker />
</div> */}
    </>
  );
};

interface SearchAndFilterBookingsDrawerProps {
  onApplyFilterClicked: (params: FilterBookingsType) => void;
  onClearFilterClicked: () => void;
  initialValues?: FilterBookingsType;
}

export default SearchAndFilterBookingsDrawer;
