import { Input } from "antd";
import { FC, useEffect } from "react";

const LocationAutoCompleteSearch: FC<LocationAutoCompleteSearchProps> = ({ value, id, onValueChange, onPlaceChanged }) => {
  useEffect(() => {
    const searchInput = document.getElementById(id ?? "address-search") as HTMLInputElement;
    if (!searchInput) return;
    const autocomplete = new window.google.maps.places.Autocomplete(searchInput, {
      componentRestrictions: { country: "ae" },
    });
    autocomplete.setFields(["formatted_address", "name", "place_id", "geometry"]);
    const listener = autocomplete.addListener("place_changed", () => {
      const placeData = autocomplete.getPlace();
      if (!placeData.geometry?.location) return;
      const address = `${placeData.name} ${placeData.formatted_address ?? ""}`;
      //   searchInput.value = address;
      if (onValueChange) {
        onValueChange(address);
      }
      onPlaceChanged(placeData);
    });
    return () => {
      autocomplete.unbindAll();
      listener.remove();
    };
  }, [id, onPlaceChanged, onValueChange]);

  return (
    <Input
      id={id ?? "address-search"}
      value={value}
      onChange={(e) => {
        if (onValueChange) {
          onValueChange(e.target.value);
        }
      }}
    />
  );
};

export interface LocationAutoCompleteSearchProps {
  value?: string;
  id?: string;
  onValueChange?: (value: string) => void;
  onPlaceChanged: (place: google.maps.places.PlaceResult) => void;
}

export default LocationAutoCompleteSearch;
