import { buildYourLabTestProductUUID } from "../constants";
import BaseResponseModel from "../models/BaseResponseModel";
import ProductModel from "../models/ProductModel";
import axios from "../utils/axiosHelpers";
import decrypt from "./decrypt";
// import encrypt from "./encrypt";

export async function getBiomarkersMasterList() {
  const res = await axios.get<string>("/api/v1/schemas/get-addons-new");
  const data: GetBuildYourLabTestCustomizationsResponseModel = decrypt(res.data);
  return data;
}

interface GetBuildYourLabTestCustomizationsResponseModel extends BaseResponseModel {
  addOns?: BuildYourLabTestCustomizationItem[];
}

export interface BuildYourLabTestCustomizationItem {
  "App Price"?: number;
  "TAT Days"?: number;
  "Test Name"?: string;
  FASTING?: boolean;
  arbName?: string;
  engName?: string;
  engDescription?: string;
  arbDescription?: string;
  price?: number;
  isFastingRequired?: boolean;
  sample?: string;
  SAMPLE?: string;
  customizationUUID?: string;
  sampleType?: string;
  _id?: string;
}

export async function getBuildYourLabTestAddons(): Promise<GetBuildYourLabTestCustomizationsResponseModel | null> {
  try {
    const res = await axios.get<string>(`/api/v1/schemas/getaddons/${buildYourLabTestProductUUID}`);
    const decryptedData: GetBuildYourLabTestCustomizationsResponseModel = decrypt(res.data);
    return decryptedData;
  } catch (error) {
    console.log("--- getBuildYourLabTestAddons error", error);
    return null;
  }
}

export interface GetProductDetailsResponseModel extends BaseResponseModel {
  product?: ProductModel;
}

export async function getProductDetails(params: {
  productUUID: string;
  area: string;
}): Promise<GetProductDetailsResponseModel | null> {
  try {
    const res = await axios.get<string>(`/api/v1/schemas/getparticularproductwithlocation/${params.productUUID}/${params.area}`);
    const data: GetProductDetailsResponseModel = decrypt(res.data);
    return data;
  } catch (error) {
    console.log("--- getProductDetails error", error);
    return null;
  }
}

export async function getAllProducts() {
  try {
    // const res = await axios.get('')
  } catch (e) {
    console.log("--- getAllProducts error", e);
    return null;
  }
}
