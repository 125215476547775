import { Typography } from "@mui/material";
import { FC } from "react";

const CardListHeader: FC<CardListHeaderProps> = ({ title }) => {
  return (
    <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }} className="board-view-list-header">
      <Typography variant="h5">{title}</Typography>
    </div>
  );
};

interface CardListHeaderProps {
  title: string;
}

export default CardListHeader;
