import moment from "moment";
import RenderGoogleMapsWrapper from "../../components/common/RenderGoogleMapsWrapper";
import AddPatientLocationAutoComplete from "./AddPatientLocationAutoComplete";
import useGetStaffSchedulerWeekViewQueryKey from "../../hooks/useGetStaffSchedulerWeekViewQueryKey";
import useGetStaffSchedulerMonthViewQueryKey from "../../hooks/useGetStaffSchedulerMonthViewQueryKey";
import useGetStaffSchedulerDayViewQueryKey from "../../hooks/useGetStaffSchedulerDayViewQueryKey";
import useGetStaffSchedulerQueryData from "../../hooks/useGetStaffSchedulerQueryData";
import { FC, useCallback, useEffect, useMemo, useState } from "react";
import {
  Alert,
  Button,
  Col,
  DatePicker,
  Divider,
  Form,
  Input,
  Modal,
  Popover,
  Row,
  Select,
  Spin,
  Switch,
  TimePicker,
  Tooltip,
  Typography,
} from "antd";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useHistory, useRouteMatch } from "react-router-dom";
import { NursesDatum, SlotPatientModel } from "../../models/SchedulerExploreViewResponseModel";
import { nurseOrdersService, TemporaryAddressModel } from "../../services";
import { showMessage } from "../../components/common/notification";
import { CalendarViewType } from "../SchedulerExploreView";
import { DefaultOptionType } from "antd/lib/select";
import { Wrapper } from "@googlemaps/react-wrapper";
import { getMomentDaysBetweenDays } from "./getMomentDaysBetweenDays";
// import { BookingDateModel } from "../../models/BookingModel";
import useCheckIfNurseIsAvailableForBookingDates from "../../customHooks/useCheckIfNurseIsAvailableForBookingDates";
import MarkNurseOnLeaveModal from "../SchedulerExploreView/MarkNurseOnLeaveModal";
import ModalHeaderProfessionalAvatar from "./ModalHeaderProfessionalAvatar";
import AssignPatientsModalForm from "./AssignPatientsModalForm";
import staffSchedulerQueryUpdater from "./staffSchedulerQueryUpdater";
import useAppSettings from "../../hooks/useAppSettings";
import "./styles.css";

export type AssignPatientsModalHistoryType = {
  caseDescription?: string;
  patients?: string;
  nurseUUID?: string;
  patientsList?: SlotPatientModel[];
  nurseData?: NursesDatum;
  initialSelectedDate?: number;
  edit: boolean;
  startTime?: number;
  endTime?: number;
  service?: string;
  nurseOrderID?: string;
  orderUUID?: string;
  locationLat?: string;
  locationLng?: string;
  address?: string;
  addressDetails?: string;
  nursePickupAddress?: TemporaryAddressModel;
  nurseDropAddress?: TemporaryAddressModel;
};

const AssignPatientsModal: FC<{ viewType: CalendarViewType }> = ({ viewType }) => {
  const { query } = useAppSettings();
  const googleAPIKey = query.data?.key?.googleKey;
  const history = useHistory<AssignPatientsModalHistoryType>();
  const queryClient = useQueryClient();
  const editMode = history.location?.state?.edit ?? false;
  // const [showAssignNurseConfirmationModal, setShowAssignNurseConfirmationModal] = useState<boolean>(false);
  // const [assignNurseOccupiedDates, setAssignNurseOccupiedDates] = useState<{ [prop: string]: string[] } | undefined>();
  // const [openSelectDatesModal, setOpenSelectDatesModal] = useState<boolean>(false);
  const [loading, setLoading] = useState(false);
  const [selectedDates, setSelectedDates] = useState<moment.Moment[]>([]);
  const [selectedPatientPlace, setSelectedPatientPlace] = useState<google.maps.places.PlaceResult | undefined>();
  const [selectedNursePickupPlace, setselectedNursePickupPlace] = useState<google.maps.places.PlaceResult | undefined>();
  const [selectedNurseDropPlace, setselectedNurseDropPlace] = useState<google.maps.places.PlaceResult | undefined>();
  const [formViewType, setFormViewType] = useState<"assign" | "mark-leave">("assign");
  const [showAdditionalDetailsFields, setShowAdditionalDetailsFields] = useState<boolean>(false);
  const [form] = Form.useForm<AssignPatientsModalForm>();
  const startTime: moment.Moment | undefined = Form.useWatch("startTime", form);
  const endTime: moment.Moment | undefined = Form.useWatch("endTime", form);
  const startDate: moment.Moment | undefined = Form.useWatch("startDate", form);
  const endDate: moment.Moment | undefined = Form.useWatch("endDate", form);
  const selectedNurseUUID = Form.useWatch("nurse", form);
  const isSingleDay = Form.useWatch("isSingleDay", form);
  const startOfToday = useMemo(() => moment().startOf("day"), []);
  // const formValuesRef = useRef<AssignPatientsModalForm>();
  const nurseUUID = history.location.state?.nurseUUID;

  useEffect(() => {
    if (!startDate || !endDate) return;
    setSelectedDates(getMomentDaysBetweenDays({ start: startDate, end: endDate }));
  }, [startDate, endDate]);

  // useEffect(() => {
  //   if (history.location?.state?.initialSelectedDate) {
  //     // const map = new Map<number, boolean>();
  //     // map.set(, true);
  //     // setSelectedDates(map);
  //   }
  // }, [history.location?.state?.initialSelectedDate]);

  const selectedStartMoment = useMemo(() => {
    if (!startTime) return;
    return startTime.format("HH:mm");
  }, [startTime]);

  // const selectedDatesArray = useMemo<number[]>(() => {
  //   return Array.from(selectedDates.keys()).sort();
  // }, [selectedDates]);

  const momentUserClickOnFromCalendar = useMemo<moment.Moment>(
    () => moment(history.location?.state?.initialSelectedDate),
    [history.location?.state?.initialSelectedDate]
  );

  const { data: checkNurseAvailableResponse } = useCheckIfNurseIsAvailableForBookingDates({
    enableQuery: true,
    onlyCheckForBookings: false,
    selectedNurseUUID,
    startTime: startTime,
    endTime: endTime,
    editMode,
    momentUserClickOnFromCalendar,
    selectedDatesArray: selectedDates,
    isSingleDay: isSingleDay ?? false,
  });

  const exploreViewData = useGetStaffSchedulerQueryData(viewType);

  const oldStartMoment = useMemo<moment.Moment>(
    () => moment(history.location.state?.startTime),
    [history.location?.state?.startTime]
  );
  const oldEndTime = useMemo<moment.Moment>(() => moment(history.location.state?.endTime), [history.location.state?.endTime]);

  const weekViewQueryKey = useGetStaffSchedulerWeekViewQueryKey();
  const monthViewQueryKey = useGetStaffSchedulerMonthViewQueryKey();
  const dayViewQueryKey = useGetStaffSchedulerDayViewQueryKey();

  const { url } = useRouteMatch();

  const createNurseOrderMutation = useMutation({
    mutationFn: nurseOrdersService.createMultipleDaysNurseOrder,
  });
  const editNurseOrderMutation = useMutation({
    mutationFn: nurseOrdersService.editNurseOrder,
  });

  const handleEditFormSubmit = async (values: AssignPatientsModalForm) => {
    if (!history.location.state.nurseOrderID) {
      console.log("--- no nurseOrderID");
      return showMessage("error", "Something went wrong");
    }
    const dateStr = editMode ? values.date?.format("DD/MM/YYYY") : momentUserClickOnFromCalendar.format("DD/MM/YYYY");
    const addressLocation = selectedPatientPlace?.geometry?.location;
    const newStartTime = moment(`${dateStr} ${values.startTime?.format("HH:mm")}`, "DD/MM/YYYY HH:mm");
    const newEndTime = moment(`${dateStr} ${values.endTime?.format("HH:mm")}`, "DD/MM/YYYY HH:mm");
    if (newEndTime.isSameOrBefore(newStartTime)) {
      newEndTime.add(1, "day");
    }
    const nursePickupAddress: TemporaryAddressModel = {
      address: `${selectedNursePickupPlace?.name} ${selectedNursePickupPlace?.formatted_address}`,
      addressLink: `https://www.google.com/maps?q=${selectedNursePickupPlace?.geometry?.location?.lat() ?? ""},${
        selectedNursePickupPlace?.geometry?.location?.lng() ?? ""
      }`,
    };
    const nurseDropAddress: TemporaryAddressModel = {
      address: `${selectedNurseDropPlace?.name} ${selectedNurseDropPlace?.formatted_address}`,
      addressLink: `https://www.google.com/maps?q=${selectedNurseDropPlace?.geometry?.location?.lat() ?? ""},${
        selectedNurseDropPlace?.geometry?.location?.lng() ?? ""
      }`,
    };
    const res = await editNurseOrderMutation.mutateAsync({
      temporaryPickupAddress: selectedNursePickupPlace ? nursePickupAddress : history.location.state.nursePickupAddress,
      temporaryDropOffAddress: selectedNurseDropPlace ? nurseDropAddress : history.location.state.nurseDropAddress,
      addressDetails: values.addressDetails ?? "",
      address: selectedPatientPlace
        ? `${selectedPatientPlace?.name ?? ""} ${selectedPatientPlace?.formatted_address ?? ""}`.trim()
        : history.location.state.address ?? "",
      locationLat: addressLocation ? addressLocation!.lat().toString() : history.location.state.locationLat ?? "",
      locationLng: addressLocation ? addressLocation!.lng().toString() : history.location.state.locationLng ?? "",
      nurseOrderID: history.location.state.nurseOrderID,
      oldStartTime: oldStartMoment,
      newStartTime: newStartTime,
      newEndTime: newEndTime,
      nurseUUID: values.nurse ?? "",
      patientName: values.patients ?? "Patient 1",
      caseDescription: values.caseDescription,
      service: values.service,
    });
    if (!res?.nurseOrder?._id) {
      return showMessage("error", "Something went wrong");
    }
    showMessage("success", "Professional order was updated successfully");
    queryClient.resetQueries(weekViewQueryKey);
    queryClient.resetQueries(monthViewQueryKey);
    queryClient.resetQueries(dayViewQueryKey);
    history.replace("/scheduler");
  };

  const createNurseOrder = async (values: AssignPatientsModalForm, selectedDaysList: moment.Moment[]) => {
    if (editMode) {
      return handleEditFormSubmit(values);
    }
    if (!values.isSingleDay && !selectedDates.length) {
      return showMessage("error", "Please select the dates to assign");
    }
    const dateFormat = "DD/MM/YYYY";
    const timeFormat = "HH:mm";
    const startTimeFormatted = values.startTime;
    const endTimeFormatted = values.endTime;
    const addressLocation = selectedPatientPlace?.geometry?.location;
    const nursePickupAddress: TemporaryAddressModel = {
      address: `${selectedNursePickupPlace?.name ?? ""} ${selectedNursePickupPlace?.formatted_address ?? ""}`.trim(),
      addressLink: `https://www.google.com/maps?q=${selectedNursePickupPlace?.geometry?.location?.lat() ?? ""},${
        selectedNursePickupPlace?.geometry?.location?.lng() ?? ""
      }`,
    };
    const nurseDropAddress: TemporaryAddressModel = {
      address: `${selectedNurseDropPlace?.name ?? ""} ${selectedNurseDropPlace?.formatted_address ?? ""}`.trim(),
      addressLink: `https://www.google.com/maps?q=${selectedNurseDropPlace?.geometry?.location?.lat() ?? ""},${
        selectedNurseDropPlace?.geometry?.location?.lng() ?? ""
      }`,
    };
    const res = await createNurseOrderMutation.mutateAsync({
      type: "BOOKING",
      temporaryPickupAddress: selectedNursePickupPlace ? nursePickupAddress : undefined,
      temporaryDropOffAddress: selectedNurseDropPlace ? nurseDropAddress : undefined,
      addressDetails: values.addressDetails ?? "",
      address: `${selectedPatientPlace?.name ?? ""} ${selectedPatientPlace?.formatted_address ?? ""}`.trim(),
      locationLat: addressLocation?.lat().toString(),
      locationLng: addressLocation?.lng().toString(),
      dates: selectedDaysList.map<{ startTime: string; endTime: string }>((dayMoment) => {
        const startTime = moment(
          `${dayMoment.format(dateFormat)} ${startTimeFormatted?.format(timeFormat)}`,
          `${dateFormat} ${timeFormat}`
        );
        const endTime = moment(
          `${dayMoment.format(dateFormat)} ${endTimeFormatted?.format(timeFormat)}`,
          `${dateFormat} ${timeFormat}`
        );
        if (endTime.isSameOrBefore(startTime)) {
          endTime.add(1, "day");
        }
        return {
          startTime: startTime.format(),
          endTime: endTime.format(),
        };
      }),
      nurseUUID: values.nurse ?? "",
      patientName: values.patients ?? "-",
      caseDescription: values.caseDescription,
      service: values.service,
    });
    if (!res?.nurseOrder) {
      return showMessage("error", "Something went wrong");
    }
    showMessage("success", "Professional was assigned successfully");
    const newQueryValue = staffSchedulerQueryUpdater({
      type: "BOOKING",
      formValues: values,
      googleMapsPlace: selectedPatientPlace,
      nurseUUID: nurseUUID ?? "",
      schedulerExploreViewData: exploreViewData,
      location: addressLocation,
      nurseOrders: res.nurseOrder,
      tempDropAddress: nurseDropAddress,
      tempPickupAddress: nursePickupAddress,
    });
    queryClient.setQueryData(weekViewQueryKey, newQueryValue);
    queryClient.setQueryData(monthViewQueryKey, newQueryValue);
    queryClient.setQueryData(dayViewQueryKey, newQueryValue);
    history.replace("/scheduler");
  };

  const handleFormFinishValidation: (value: AssignPatientsModalForm) => Promise<void> = async (values) => {
    setLoading(true);
    const selectedDaysList: moment.Moment[] = [];
    if (values.isSingleDay) {
      selectedDaysList.push(momentUserClickOnFromCalendar);
    } else {
      selectedDaysList.push(...selectedDates.map<moment.Moment>((date) => moment(date)));
    }
    createNurseOrder(values, selectedDaysList);
    setLoading(false);
  };

  const daysOffMap = useMemo<Map<string, boolean>>(() => {
    const map = new Map<string, boolean>();
    for (const offDate of history.location.state?.nurseData?.holidays ?? []) {
      map.set(offDate, true);
    }
    return map;
  }, [history.location.state?.nurseData?.holidays]);

  const onCancel = () => {
    const path = url.split("/")[1];
    // if (nurseUUID) {
    //   history.replace(`/${path}/${nurseUUID}`);
    // } else {
    // }
    history.replace(`/${path}`);
  };

  const handleRemoveDay = (removeDate: moment.Moment) => {
    setSelectedDates((prevValue) => {
      return prevValue.filter((date) => {
        return date.valueOf() !== removeDate.valueOf();
      });
    });
  };

  const handlePlaceChanged = (place: google.maps.places.PlaceResult) => {
    setSelectedPatientPlace(place);
  };

  const handleAddBreakClicked = useCallback(() => {
    history.replace(`/scheduler/add-break-time`, {
      ...(history.location?.state ?? {}),
    });
  }, [history]);

  const initialStartTimeValue = useMemo<moment.Moment | undefined>(() => {
    if (editMode) {
      return oldStartMoment;
    } else if (viewType === "day") {
      return moment(momentUserClickOnFromCalendar);
    }
  }, [editMode, momentUserClickOnFromCalendar, oldStartMoment, viewType]);

  let content: JSX.Element;
  if (formViewType === "assign") {
    content = (
      <>
        {(checkNurseAvailableResponse?.assignNurseErrors?.length ?? 0) > 0 && (
          <Alert
            message={
              <div style={{ display: "flex", flexDirection: "column", gap: "8px" }}>
                {checkNurseAvailableResponse?.assignNurseErrors?.map((errorMsg, index) => {
                  return (
                    <div key={index} style={{ textAlign: "center" }}>
                      <Typography.Text type="danger" strong={true} style={{ textAlign: "center" }}>
                        {errorMsg}
                      </Typography.Text>
                    </div>
                  );
                })}
              </div>
            }
            type="error"
          />
        )}
        <Spin spinning={createNurseOrderMutation.isLoading || loading}>
          {googleAPIKey && (
            <Wrapper render={RenderGoogleMapsWrapper} libraries={["places"]} apiKey={googleAPIKey}>
              <Form
                form={form}
                size="large"
                onFinish={handleFormFinishValidation}
                onValuesChange={(changedValues) => {
                  if (changedValues.startTime) {
                    form.setFieldValue("endTime", undefined);
                  }
                }}
                layout="vertical"
                initialValues={{
                  patients: editMode ? history.location.state.patients : undefined,
                  startDate: moment(history.location?.state?.initialSelectedDate),
                  addressDetails: editMode ? history.location.state.addressDetails : undefined,
                  startTime: initialStartTimeValue,
                  endTime: editMode ? oldEndTime : undefined,
                  date: editMode ? oldStartMoment : undefined,
                  service: editMode ? history.location.state?.service : "",
                  caseDescription: editMode ? history.location.state.caseDescription : "",
                  nurse: nurseUUID,
                  isSingleDay: false,
                  endDate: moment(history.location?.state?.initialSelectedDate),
                }}
              >
                <div
                  style={{
                    paddingLeft: "24px",
                    paddingRight: "24px",
                    // paddingTop: "24px",
                  }}
                >
                  <Form.Item
                    style={{ display: editMode ? "block" : "none" }}
                    label="Nurse"
                    name="nurse"
                    rules={[
                      {
                        required: true,
                        message: "Plesae enter patient name",
                      },
                    ]}
                  >
                    <Select
                      className="select-nurse"
                      showSearch={true}
                      filterOption={(input, option) => {
                        return ((option?.label ?? "") as string).toLowerCase().includes(input.toLowerCase());
                      }}
                      options={
                        exploreViewData?.nursesData?.map<DefaultOptionType>((nurse) => {
                          return {
                            label: nurse.name,
                            value: nurse.nurseUUID,
                          };
                        }) ?? []
                      }
                    />
                  </Form.Item>
                  <div style={{ margin: "16px 0px 24px 0px" }}>
                    <div style={{ marginBottom: "8px" }}>
                      {/* <span style={{ color: "red" }}>* </span> */}
                      Patient Address
                    </div>
                    <AddPatientLocationAutoComplete
                      id="patient-address-auto-complete-field"
                      onPlaceChanged={handlePlaceChanged}
                      value={history.location.state?.address}
                    />
                  </div>
                  <Form.Item
                    style={{ flex: "auto" }}
                    name="addressDetails"
                    label="Address details"
                    // rules={[
                    //   {
                    //     required: true,
                    //     message: "Please enter the address details",
                    //   },
                    // ]}
                  >
                    <Input placeholder="Ex. Building name, floor number, villa number" />
                  </Form.Item>
                  {!editMode && (
                    <div style={{ display: "flex", gap: "16px" }}>
                      <Form.Item
                        style={{ flex: "auto" }}
                        name="startDate"
                        label="Start date"
                        rules={[
                          {
                            required: true,
                            message: "Please select the start date",
                          },
                        ]}
                      >
                        <DatePicker
                          style={{ width: "100%" }}
                          disabledDate={(date) => {
                            return date.isBefore(moment().startOf("day"));
                          }}
                        />
                      </Form.Item>
                      <Form.Item
                        style={{ flex: "auto" }}
                        name="endDate"
                        label="End date"
                        rules={[
                          {
                            required: true,
                            message: "Please select the end date",
                          },
                        ]}
                      >
                        <DatePicker
                          style={{ width: "100%" }}
                          disabledDate={(date) => {
                            const startOfToday = moment().startOf("day");
                            return date.isBefore(startOfToday) || date.isBefore(startDate ?? startOfToday);
                          }}
                        />
                      </Form.Item>
                    </div>
                  )}
                  {/* <p style={{ fontSize: "14px" }}>Selected dates </p> */}
                  {!isSingleDay && selectedDates.length !== 0 && (
                    <div
                      style={{
                        display: "flex",
                        flexWrap: "wrap",
                        gap: "8px",
                        marginBottom: "24px",
                      }}
                    >
                      {selectedDates.map((date) => {
                        const dateElement = (
                          <div
                            key={date.valueOf()}
                            onClick={selectedDates.length <= 1 ? undefined : () => handleRemoveDay(date)}
                            style={{
                              cursor: selectedDates.length > 1 ? "pointer" : "default",
                              backgroundColor: "#BF9001",
                              color: "white",
                              padding: "8px 8px",
                              borderRadius: "5px",
                              fontWeight: "500",
                            }}
                          >
                            {moment(date).format("DD MMM")}
                          </div>
                        );
                        if (selectedDates.length <= 1) return dateElement;
                        return (
                          <Popover key={date.valueOf()} title="Click to remove">
                            {dateElement}
                          </Popover>
                        );
                      })}
                    </div>
                  )}
                  <Row
                    style={{
                      rowGap: "16px",
                      justifyContent: "space-between",
                      alignItems: "flex-start",
                    }}
                  >
                    {editMode && (
                      <Col xs={24} sm={24} md={24} lg={7}>
                        <Form.Item
                          style={{ flex: "auto" }}
                          label="Date"
                          name="date"
                          rules={[
                            {
                              required: true,
                              message: "Plesae enter booking date",
                            },
                          ]}
                        >
                          <DatePicker
                            format="DD/MM/YYYY"
                            style={{ width: "100%" }}
                            disabledDate={(date) => {
                              return date.isBefore(startOfToday) || (daysOffMap.get(date.format("DD/MM/YYYY")) ?? false);
                            }}
                          />
                        </Form.Item>
                      </Col>
                    )}
                    <Col xs={24} sm={24} md={11} lg={editMode ? 7 : 11}>
                      <Form.Item
                        style={{ flex: "auto" }}
                        label="Start Time"
                        name="startTime"
                        rules={[
                          {
                            required: true,
                            message: "Please select the start time",
                          },
                        ]}
                      >
                        <TimePicker
                          format="hh:mm A"
                          showSecond={false}
                          minuteStep={30}
                          style={{ width: "100%" }}
                          showNow={false}
                          onSelect={(value) => {
                            form.setFieldValue("startTime", value);
                          }}
                        />
                      </Form.Item>
                    </Col>
                    <Col xs={24} sm={24} md={11} lg={editMode ? 7 : 11}>
                      <Form.Item
                        style={{ flex: "auto" }}
                        label="End Time"
                        name="endTime"
                        required={true}
                        rules={[
                          () => ({
                            validator(_, value: moment.Moment) {
                              if (!value) {
                                return Promise.reject("Please select the end time");
                              }
                              if (value.isSameOrBefore(selectedStartMoment)) {
                                value.add(1, "day");
                              }
                              if (moment.duration(value.diff(selectedStartMoment)).asHours() < 1) {
                                return Promise.reject("Minimum booking duration is 1 hour");
                              }
                              return Promise.resolve();
                            },
                          }),
                        ]}
                      >
                        <TimePicker
                          format="hh:mm A"
                          showSecond={false}
                          minuteStep={30}
                          style={{ width: "100%" }}
                          showNow={false}
                          onSelect={(value) => {
                            form.setFieldValue("endTime", value);
                          }}
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                  <div
                    className="optional-fields"
                    style={{
                      height: showAdditionalDetailsFields ? "520px" : "0px",
                      overflow: "hidden",
                      transition: "height ease-in-out 0.2s",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        gap: "16px",
                        margin: "16px 0px 24px 0px",
                      }}
                    >
                      <div style={{ flex: 1 }}>
                        <div style={{ marginBottom: "8px" }}>Temporary pickup location</div>
                        <AddPatientLocationAutoComplete
                          id="nurse-pickup-address-auto-complete-field"
                          onPlaceChanged={(place) => {
                            setselectedNursePickupPlace(place);
                          }}
                          value={history.location.state?.nursePickupAddress?.address}
                        />
                      </div>
                      <div style={{ flex: 1 }}>
                        <div style={{ marginBottom: "8px" }}>Temporary drop location</div>
                        <AddPatientLocationAutoComplete
                          id="nurse-drop-address-auto-complete-field"
                          onPlaceChanged={(place) => {
                            setselectedNurseDropPlace(place);
                          }}
                          value={history.location.state?.nurseDropAddress?.address}
                        />
                      </div>
                    </div>
                    <Form.Item label="Patient name" name="patients">
                      <Input />
                    </Form.Item>
                    <Form.Item label="Service" name="service">
                      <Input />
                    </Form.Item>
                    <Form.Item name="caseDescription" label="Case Description">
                      <Input.TextArea className="case-description" />
                    </Form.Item>
                  </div>
                </div>
                <div className="modal-footer">
                  <Divider
                    style={{
                      margin: "0px",
                    }}
                  />
                  <Row
                    style={{
                      paddingLeft: "24px",
                      paddingRight: "24px",
                      paddingBottom: "12px",
                      paddingTop: "12px",
                      justifyContent: "space-between",
                      alignContent: "center",
                      width: "100%",
                      rowGap: "16px",
                    }}
                  >
                    <Col>
                      <Button
                        onClick={() =>
                          setShowAdditionalDetailsFields((prevValue) => {
                            return !prevValue;
                          })
                        }
                        type="default"
                      >
                        {showAdditionalDetailsFields ? "Hide additional fields" : "Add more details"}
                      </Button>
                    </Col>
                    <div style={{ display: "flex", alignItems: "center", gap: "16px" }}>
                      <Col>
                        <Button onClick={onCancel} type="default">
                          Cancel
                        </Button>
                      </Col>
                      <Col>
                        <Tooltip
                          title={
                            (checkNurseAvailableResponse?.assignNurseErrors?.length ?? 0) > 0
                              ? checkNurseAvailableResponse?.assignNurseErrors?.[0]
                              : undefined
                          }
                          color="red"
                        >
                          <Button
                            disabled={(checkNurseAvailableResponse?.assignNurseErrors?.length ?? 0) > 0}
                            htmlType="submit"
                            style={{
                              backgroundColor: "#12AFB8",
                              borderColor: "#12AFB8",
                              color: "white",
                            }}
                          >
                            Save
                          </Button>
                        </Tooltip>
                      </Col>
                    </div>
                  </Row>
                </div>
              </Form>
            </Wrapper>
          )}
        </Spin>
      </>
    );
  } else {
    content = <MarkNurseOnLeaveModal renderModals={false} viewType={viewType} />;
  }

  return (
    <>
      <Modal
        centered
        destroyOnClose={true}
        width={700}
        className={`scheduler-modal scheduler-assign-patients-modal ${
          formViewType === "assign" ? "assing-nurse-modal" : "mark-leave-modal"
        }`}
        title={
          <Row style={{ justifyContent: "space-between", rowGap: "16px" }}>
            <Col xs={24} sm={24} md={15}>
              {history.location.state?.nurseData && (
                <ModalHeaderProfessionalAvatar
                  professionalData={history.location.state?.nurseData}
                  date={momentUserClickOnFromCalendar}
                />
              )}
            </Col>
            <Col xs={24} sm={24} md={8}>
              <Row
                style={{
                  gap: "16px",
                  alignItems: "center",
                  justifyContent: "flex-end",
                }}
              >
                <Col>
                  <Tooltip title="Add break time for the professional">
                    <Button onClick={handleAddBreakClicked}>Add Break</Button>
                  </Tooltip>
                </Col>
                <Col>
                  <Switch
                    size="default"
                    checkedChildren={<div style={{ color: "white" }}>Booking</div>}
                    unCheckedChildren={<div style={{ color: "white" }}>Leave</div>}
                    className="mark-leave-or-assign-switch"
                    checked={formViewType === "assign"}
                    onChange={(e) => {
                      setFormViewType(e ? "assign" : "mark-leave");
                    }}
                  />
                </Col>
              </Row>
            </Col>
          </Row>
        }
        open={true}
        maskClosable={false}
        footer={null}
        onCancel={onCancel}
      >
        {content}
      </Modal>
    </>
  );
};

export default AssignPatientsModal;
