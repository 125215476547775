import { useQuery } from "@tanstack/react-query";
import moment from "moment";
import { getAllNurseDataWithTimeSlots } from "../services/nurseTimeSlotService";
import useGetStaffSchedulerDayViewQueryKey from "./useGetStaffSchedulerDayViewQueryKey";
import useGetStaffSchedulerMonthViewQueryKey from "./useGetStaffSchedulerMonthViewQueryKey";
import useGetStaffSchedulerWeekViewQueryKey from "./useGetStaffSchedulerWeekViewQueryKey";
import { CalendarViewType } from "../pages/SchedulerExploreView";

function useStaffSchedulerQuery({ viewType }: { viewType: CalendarViewType }) {
  const weekViewQueryKey = useGetStaffSchedulerWeekViewQueryKey();
  const monthViewQueryKey = useGetStaffSchedulerMonthViewQueryKey();
  const dayViewQueryKey = useGetStaffSchedulerDayViewQueryKey();
  let queryKey: typeof weekViewQueryKey;
  if (viewType === "week") {
    queryKey = weekViewQueryKey;
  } else if (viewType === "day") {
    queryKey = dayViewQueryKey;
  } else {
    queryKey = monthViewQueryKey;
  }

  const query = useQuery(
    queryKey,
    () =>
      getAllNurseDataWithTimeSlots({
        partnerUUID: (queryKey[1] as string) ?? "",
        startDate: moment(queryKey[2] as number),
        endDate: moment(queryKey[3] as number).endOf("day"),
      }),
    { refetchOnWindowFocus: false }
  );

  return { query, queryKey };
}

export default useStaffSchedulerQuery;
