import axios from "../utils/axiosHelpers";
import decrypt from "./decrypt";
import encrypt from "./encrypt";

export const setOneSignalUserID = async function ({ partnerUUID, playerID }) {
  try {
    const body = { partnerUUID, playerId: playerID };
    const encryptedBody = encrypt(body);
    const data = await axios.post(`/api/v1/partners/addonesignalplayerid`, { data: encryptedBody });
    const res = { ...data, data: decrypt(data?.data) };
    return res;
  } catch (e) {
    console.log("--- setOneSignalUserID error");
    console.log(e);
    return null;
  }
};
