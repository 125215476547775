import { Input, Select } from "antd";
import { FC, useMemo, useState } from "react";
import { FilterPeriodKeysType } from ".";
import { TractionTableRowModel } from "../../models/TractionTableResponseModel";
import useEligibleProductsForPartner, { PartnerEligibleProductModel } from "../../customHooks/useEligibleProductsForPartner";
import useTractionTableQuery from "../../hooks/useTractionTableQuery";
import TracktionTableRowItem from "./TracktionTableRowItem";
import {
  tractionTableBookingIDColumnWidth,
  tractionTableHomeNursingProductNames,
  tractionTablePhysioProductNames,
  tractionTableReceivedColumnWidth,
  tractionTableunitColumnWidth,
} from "../../constants";

const TractionTable: FC<TractionTableProps> = ({ selectedPeriod, selectedDateRange }) => {
  const { data, isLoading: loadingTableData } = useTractionTableQuery(selectedPeriod, selectedDateRange);
  const [loadingProducts, products] = useEligibleProductsForPartner();
  const [selectedFilterService, setSelectedFilterService] = useState<PartnerEligibleProductModel | undefined>();
  const [searchInput, setSearchInput] = useState<string>("");

  const filteredTableData = useMemo<TractionTableRowModel[]>(() => {
    let tableData = data?.bookings ?? [];
    if (selectedFilterService) {
      tableData = tableData.filter((row) => {
        if (selectedFilterService.key === "NON_CLINICAL_HOME_NURSING") {
          return row.type === "NON_CLINICAL" && tractionTableHomeNursingProductNames.includes(row.service ?? "");
        } else if (selectedFilterService.key === "CLINICAL_HOME_NURSING") {
          return row.type === "CLINICAL" && tractionTableHomeNursingProductNames.includes(row.service ?? "");
        } else if (selectedFilterService.key === "PHYSIOTHERAPY") {
          return tractionTablePhysioProductNames.includes(row.service ?? "");
        }
        const filterNameTrimed = selectedFilterService.value.trim();
        const filterServiceName = filterNameTrimed.endsWith(".")
          ? filterNameTrimed.slice(0, filterNameTrimed.length - 1)
          : filterNameTrimed;
        const rowServiceNameTrim = row.service?.trim() ?? "";
        const rowServiceName = rowServiceNameTrim?.endsWith(".")
          ? rowServiceNameTrim.slice(0, rowServiceNameTrim.length - 1)
          : rowServiceNameTrim;
        return rowServiceName === filterServiceName;
      });
    }
    if (searchInput) {
      const normalizadSearchInput = searchInput.trim().toLocaleLowerCase();
      tableData = tableData.filter((row) => {
        if (row.orderUUID?.toLocaleLowerCase().includes(normalizadSearchInput)) {
          return true;
        } else if (row.PatientName?.join("").toLocaleLowerCase().includes(normalizadSearchInput)) {
          return true;
        } else if (row.assignedProfessionals?.join("").toLocaleLowerCase().includes(normalizadSearchInput)) {
          return true;
        } else if (row.service?.toLocaleLowerCase().includes(normalizadSearchInput)) {
          return true;
        } else if (row.customizations?.join("").toLocaleLowerCase().includes(normalizadSearchInput)) {
          return true;
        }
        return false;
      });
    }
    return tableData;
  }, [data?.bookings, searchInput, selectedFilterService]);

  const isFiltingData = useMemo(() => {
    return !!searchInput.trim().length || !!selectedFilterService;
  }, [searchInput, selectedFilterService]);

  return (
    <div className="traction-table-container">
      <div
        style={{
          display: "flex",
          alignItems: "center",
          gap: "16px",
          marginBottom: "16px",
          padding: "16px 16px 0px 16px",
        }}
      >
        <Input
          allowClear={true}
          value={searchInput}
          onChange={(e) => setSearchInput(e.target.value)}
          placeholder="Search booking ID, patient name, nurse or service type"
          style={{ width: "400px" }}
        />
        <Select
          value={selectedFilterService?.key}
          onChange={(e) => {
            setSelectedFilterService(products?.find((prod) => prod.key === e));
          }}
          loading={loadingProducts}
          allowClear={true}
          placeholder="Filter by service type"
          style={{ width: "400px", maxWidth: "400px" }}
        >
          {products?.map((item) => {
            return (
              <Select.Option key={item.key} value={item.key}>
                {item.value}
              </Select.Option>
            );
          })}
        </Select>
      </div>
      <div className="traction-table">
        <div className="table-header">
          <div
            className="table-cell"
            style={{ width: tractionTableBookingIDColumnWidth, minWidth: tractionTableBookingIDColumnWidth }}
          >
            Booking ID
          </div>
          <div
            className="table-cell"
            style={{ width: tractionTableBookingIDColumnWidth, minWidth: tractionTableBookingIDColumnWidth }}
          >
            Date
          </div>
          <div className="table-cell">Service Type</div>
          <div className="table-cell">Patient</div>
          <div className="table-cell" style={{ width: tractionTableunitColumnWidth, minWidth: tractionTableunitColumnWidth }}>
            Unit
          </div>
          <div
            className="table-cell"
            style={{ width: tractionTableReceivedColumnWidth, minWidth: tractionTableReceivedColumnWidth }}
          >
            Total
          </div>
          <div
            className="table-cell"
            style={{ width: tractionTableReceivedColumnWidth, minWidth: tractionTableReceivedColumnWidth }}
          >
            Served
          </div>
          <div className="table-cell">Professional</div>
          <div className="table-cell">Unit Price</div>
          <div className="table-cell">Total Served</div>
          <div className="table-cell">Ad-On</div>
          <div className="table-cell">Collection Fees</div>
          <div className="table-cell">Convenience Fees</div>
          <div className="table-cell">Penalty Charges</div>
          <div className="table-cell">Credits</div>
          <div className="table-cell">Total AED</div>
        </div>
        {!loadingTableData && filteredTableData.length === 0 && (
          <div
            style={{
              width: "100%",
              height: "300px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              color: "gray",
            }}
          >
            {isFiltingData ? "No data match your filter" : "No data found"}
          </div>
        )}
        {filteredTableData?.map((row) => {
          return <TracktionTableRowItem key={row.orderUUID} row={row} />;
        })}
      </div>
    </div>
  );
};

interface TractionTableProps {
  selectedPeriod: FilterPeriodKeysType;
  selectedDateRange?: [moment.Moment, moment.Moment];
  setSelectedDateRange: React.Dispatch<React.SetStateAction<[moment.Moment, moment.Moment] | undefined>>;
}

export default TractionTable;
