import { FC } from "react";
import Settings from "../Settings";
import PharmacyBasePageLayout from "../../components/PharmacyBasePageLayout";

const PharmacySettings: FC = () => {
  return (
    <PharmacyBasePageLayout
      className="pharmacy-settings"
      mainContent={<Settings showPartnerTime={false} showServices={false} showBaseLayout={false} />}
    />
  );
};

export default PharmacySettings;
