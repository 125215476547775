import { useQuery, useQueryClient } from "@tanstack/react-query";
import { useCallback, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { showMessage } from "../components/common/notification";
import { mapGeneratedScheduleResponseToCardGroupsArray } from "../pages/NewDriverSchedule/mapGeneratedScheduleResponseToCardGroupsArray";
import { automateDriverSchedule, GeneratedDriverScheduleItem } from "../services";
import { driverSchedulerTypes } from "../types";
import useAutomatedDriverScheduleQueryKey from "./useAutomatedDriverScheduleQueryKey";
import ReduxStateModel from "../models/ReduxStateModel";

const useAutomatedDriverScheduleQuery = ({ selectedDate }: { selectedDate: moment.Moment }) => {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();
  const partnerUUID: string | undefined = useSelector((state: ReduxStateModel) => state?.authReducer?.setting?.partnerUUID);
  const dateToCheck = useMemo(() => selectedDate.format("DD/MM/YYYY"), [selectedDate]);
  const previousDate = useMemo(() => selectedDate.clone().subtract(1, "day").format("DD/MM/YYYY"), [selectedDate]);

  const setCardGroupsData = useCallback(
    (res: { [driverName: string]: GeneratedDriverScheduleItem[] }) => {
      const cardGroups = mapGeneratedScheduleResponseToCardGroupsArray(res ?? {});
      dispatch({
        type: driverSchedulerTypes.SET_CARDS_TASKS_GROUPS,
        payload: cardGroups,
      });
      if (!cardGroups.length || !cardGroups[0].length) return;
      dispatch({
        type: driverSchedulerTypes.SET_SELECTED_CELL_ID,
        payload: `${cardGroups[0][0].driverName}-${cardGroups[0][0].time}-${cardGroups[0][0].nurseName}`,
      });
    },
    [dispatch]
  );

  const queryKey = useAutomatedDriverScheduleQueryKey({ selectedDate });

  useEffect(() => {
    const queryData = queryClient.getQueryData<{ [driverName: string]: GeneratedDriverScheduleItem[] } | null | undefined>(
      queryKey
    );
    setCardGroupsData(queryData ?? {});
  }, [queryClient, queryKey, setCardGroupsData]);

  const query = useQuery(queryKey, () => automateDriverSchedule({ dateToCheck, previousDate, partnerUUID: partnerUUID! }), {
    staleTime: Infinity,
    cacheTime: Infinity,
    refetchOnWindowFocus: false,
    enabled: !!partnerUUID,
    onSuccess: (res) => {
      setCardGroupsData(res ?? {});
    },
    onError: () => {
      showMessage("error", "Something went wrong");
    },
  });

  return { query, queryKey };
};

export default useAutomatedDriverScheduleQuery;
