import { FC, useEffect, useState } from "react";

const LoadingText: FC = () => {
  const [currentTextIndex, setCurrentTextIndex] = useState<number>(0);

  useEffect(() => {
    const intervalID = setInterval(() => {
      setCurrentTextIndex((prevValue) => {
        if (prevValue === textArray.length - 1) {
          return prevValue;
        }
        return prevValue + 1;
      });
    }, 5000);
    return () => {
      clearInterval(intervalID);
    };
  }, []);

  return <div style={{ textAlign: "center", marginTop: "-80px", fontSize: "1rem" }}>{textArray[currentTextIndex]}...</div>;
};

const textArray = [
  "Estimating travel time",
  "Calculating live traffic data",
  "Finding optimal routes",
  "Optimizing fuel efficiency",
  "Finalizing",
  "Almost done",
];

export default LoadingText;
