import { useQuery } from "@tanstack/react-query";
import { useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import axios from "../utils/axiosHelpers";
import decrypt from "../services/decrypt";
import BaseResponseModel from "../models/BaseResponseModel";
import ReduxStateModel from "../models/ReduxStateModel";

interface GetGoogleAPIKeyResponseModel extends BaseResponseModel {
  key?: {
    googleKey?: string;
    voucherifyAppId?: string;
    voucherifySecretKey?: string;
    oneSignalProdAppId?: string;
    vonageApiKey?: string;
    vonageApiSecret?: string;
    vonageHeader?: string;
    stripePublicKey?: string;
    adminDashboardOneSignalAppId?: string;
  };
}

export async function getAppSettings() {
  const res = await axios.get<string>("/api/v1/apps/fetch-google-api-key");
  const data: GetGoogleAPIKeyResponseModel = decrypt(res.data);
  return data;
}

export default function useAppSettings() {
  const dispatch = useDispatch();
  const queryKey = useMemo(() => ["google-api-key"], []);
  const partnerName = useSelector((state: ReduxStateModel) => state.authReducer?.setting?.partnerUUID);

  const query = useQuery(queryKey, getAppSettings, {
    enabled: !!partnerName,
    refetchOnWindowFocus: false,
    cacheTime: Infinity,
    staleTime: Infinity,
  });

  useEffect(() => {
    if (query.data?.key?.googleKey) {
      dispatch({ type: "SET_GOOGLE_API_KEY", payload: query.data.key.googleKey });
    }
  }, [dispatch, query.data?.key?.googleKey]);

  return { query, queryKey, isLoading: query.isLoading };
}
