import { Box, Button, Tooltip } from "@mui/material";
import { FC, useState } from "react";
import LocalShippingRoundedIcon from "@mui/icons-material/LocalShippingRounded";
import OutSourceInventoryDrawer from "./OutSourceInventoryDrawer";
import useCurrentPartnerDetails from "../hooks/useCurrentPartnerDetails";
import { useSelector } from "react-redux";
import ReduxStateModel from "../models/ReduxStateModel";
import { allowIVDripInventoryPartnerUUIDs } from "../constants";

const OutSourceInventoryButton: FC<OutSourceInventoryButtonProps> = () => {
  const authReducer = useSelector((state: ReduxStateModel) => state.authReducer?.setting);
  const [open, setOpen] = useState(false);

  const { isDeliveryPartner } = useCurrentPartnerDetails();

  if (isDeliveryPartner) return null;

  if (!allowIVDripInventoryPartnerUUIDs.includes(authReducer?.partnerUUID ?? "")) {
    return null;
  }

  return (
    <>
      <Tooltip title="Outsource Inventory">
        <Button
          variant="contained"
          disableElevation={true}
          startIcon={<LocalShippingRoundedIcon sx={{ color: "white" }} />}
          onClick={() => setOpen(true)}
          sx={{
            "@media (max-width: 900px)": {
              "& .MuiButton-icon": {
                margin: "0px",
              },
            },
          }}
        >
          <Box
            color={"white"}
            display={"block"}
            sx={{
              "@media (max-width: 900px)": {
                display: "none",
              },
            }}
          >
            Outsource Inventory
          </Box>
        </Button>
      </Tooltip>
      <OutSourceInventoryDrawer open={open} onClose={() => setOpen(false)} />
    </>
  );
};

interface OutSourceInventoryButtonProps {}

export default OutSourceInventoryButton;
