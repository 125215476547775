import { useDispatch } from "react-redux";
import { authType } from "../types";
import { showMessage } from "../components/common/notification";
import { PartnerSettingModel } from "../models/ReduxStateModel";

function useHandleUserAuthSuccess() {
  const dispatch = useDispatch();

  function handleUserAuthSuccess(authSettings: PartnerSettingModel) {
    if (authSettings && authSettings?.role && authSettings?.partnerUserUUID) {
      dispatch({
        type: authType.SAVE_SETTINGS,
        data: authSettings,
      });
      if (authSettings?.passwordDigest) {
        delete authSettings.passwordDigest;
      }
      localStorage.setItem("role", JSON.stringify(authSettings.role));
      localStorage.setItem("partnerUserUUID", JSON.stringify(authSettings.partnerUserUUID));
      localStorage.setItem(
        "setting",
        JSON.stringify({
          userName: authSettings.userName,
          role: authSettings.role,
          partnerUserUUID: authSettings.partnerUserUUID,
          xeroContactId: authSettings.xeroContactId,
          email: authSettings.email,
          city: authSettings.city,
          didForceLogout: authSettings.didForceLogout,
          isActive: authSettings.isActive,
          name: authSettings.name,
          partnerUUID: authSettings.partnerUUID,
          phoneNumber: authSettings.phoneNumber,
        })
      );
      localStorage.setItem("userName", authSettings.userName ?? "");
      // getToken();
      // authService.upadteDeviceId({
      //   newDeviceId: currentToken,
      //   partnerUUID: partnerUUID,
      // });
    } else {
      showMessage("error", "Something went wrong!");
    }
  }

  return { handleUserAuthSuccess };
}

export default useHandleUserAuthSuccess;
