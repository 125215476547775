import { useEffect } from "react";
import { useSelector } from "react-redux";
import { createVisitorIdentification } from "../services/hubspot.service";
import ReduxStateModel from "../models/ReduxStateModel";

function useHubspotTracking() {
  const authReducer = useSelector((state: ReduxStateModel) => state.authReducer);

  useEffect(() => {
    (async () => {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const currentWindow: any = window;
      currentWindow.hsConversationsSettings = currentWindow.hsConversationsSettings || {};
      currentWindow.hsConversationsSettings.loadImmediately = false;
      async function onConversationsAPIReady() {
        if (!authReducer?.setting?.email) return;
        // var _hsq = (window._hsq = window._hsq || []);
        const email = authReducer?.setting?.email;
        const res = await createVisitorIdentification({
          email: email,
          firstName: authReducer?.setting?.name ?? "",
          lastName: "",
        });
        // console.log("createVisitorIdentification res", res);
        currentWindow.hsConversationsSettings.identificationEmail = email;
        currentWindow.hsConversationsSettings.identificationToken = res?.token;
        currentWindow.HubSpotConversations?.widget.load();
        let _hsq;
        if (currentWindow._hsq) {
          _hsq = currentWindow._hsq;
        } else {
          _hsq = [];
        }
        const identity = {
          email: email,
          // id: authReducer?.setting?.partnerUUID,
          name: authReducer?.setting?.name,
        };
        // console.log("pushing to _hsq", identity);
        _hsq.push(["identify", identity]);
        _hsq.push(["setPath", "/"]);
        _hsq.push(["trackPageView"]);
        console.log(`==== HubSpot Conversations API: ${currentWindow.HubSpotConversations}`);
        currentWindow.HubSpotConversations?.on("contactAssociated", (payload: { message?: string }) => {
          console.log("---- contactAssociated");
          console.log(payload.message);
        });
        currentWindow.HubSpotConversations?.on("widgetLoaded", (payload: { message?: string }) => {
          console.log("---- widgetLoaded");
          console.log(payload.message);
        });
      }
      if (currentWindow.HubSpotConversations) {
        onConversationsAPIReady();
      } else {
        currentWindow.hsConversationsOnReady = [onConversationsAPIReady];
      }
      if (!authReducer?.setting?.email) return;
      // var _hsq = (window._hsq = window._hsq || []);
      const email = authReducer?.setting?.email;
      const res = await createVisitorIdentification({
        email: email,
        firstName: authReducer?.setting?.name ?? "",
        lastName: "",
      });
      // console.log("createVisitorIdentification res", res);
      currentWindow.hsConversationsSettings.identificationEmail = email;
      currentWindow.hsConversationsSettings.identificationToken = res?.token;
      currentWindow.HubSpotConversations?.widget.load();
      let _hsq;
      if (currentWindow._hsq) {
        _hsq = currentWindow._hsq;
      } else {
        _hsq = [];
      }
      const identity = {
        email: email,
        // id: authReducer?.setting?.partnerUUID,
        name: authReducer?.setting?.name,
      };
      // console.log("pushing to _hsq", identity);
      _hsq.push(["identify", identity]);
      _hsq.push(["setPath", "/"]);
      _hsq.push(["trackPageView"]);
    })();
  }, [authReducer?.setting?.email, authReducer?.setting?.name, authReducer?.setting?.partnerUUID]);
}

export default useHubspotTracking;
