import { Button, CircularProgress } from "@mui/material";
import { useMutation } from "@tanstack/react-query";
import { Tooltip } from "antd";
import { FC } from "react";
import { showMessage } from "../../components/common/notification";
import BookingModel from "../../models/BookingModel";
import { acknowledgePremiumOrder } from "../../services";

const AckowledgeBookingButtont: FC<AckowledgeBookingButtontProps> = ({ booking, height, setBooking }) => {
  const acknowledgeBookingMutation = useMutation({
    mutationFn: acknowledgePremiumOrder,
  });

  if (!booking.isPremiumOrder) return null;
  if (booking.isPremiumOrder && booking.isPremiumAcknowledged) return null;

  const handleClicked = async () => {
    const res = await acknowledgeBookingMutation.mutateAsync({
      orderUUID: booking.orderUUID!,
    });
    if (!res?.success) {
      return showMessage("error", "Something went wrong");
    }
    showMessage("success", "Booking was acknowledge successfully");
    setBooking((prevValue) => {
      const newValue: BookingModel = { ...prevValue };
      newValue.isPremiumAcknowledged = true;
      return newValue;
    });
  };

  return (
    <Tooltip title="Acknowledge premium booking">
      <Button
        disabled={acknowledgeBookingMutation.isLoading}
        variant="contained"
        color="success"
        disableElevation={true}
        sx={{
          height: height,
          maxHeight: height,
          "&.MuiButton-containedPrimary": {
            color: "white",
          },
        }}
        onClick={handleClicked}
      >
        {acknowledgeBookingMutation.isLoading ? <CircularProgress size={10} /> : "Acknowledge"}
      </Button>
    </Tooltip>
  );
};

interface AckowledgeBookingButtontProps {
  booking: BookingModel;
  setBooking: React.Dispatch<React.SetStateAction<BookingModel>>;
  height?: string;
}

export default AckowledgeBookingButtont;
