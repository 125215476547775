import { useMemo } from "react";
import { useSelector } from "react-redux";
import ReduxStateModel from "../models/ReduxStateModel";
import moment from "moment";

const useGetStaffSchedulerWeekViewQueryKey: () => [string, string | undefined, number, number] = () => {
  const partnerUUID: string | undefined = useSelector((state: ReduxStateModel) => state?.authReducer?.setting?.partnerUUID);
  const selectedWeek: moment.Moment = useSelector(
    (state: ReduxStateModel) => state?.schedulerExploreViewReducer?.selectedWeek ?? moment()
  );

  const weekViewQueryKey = useMemo<[string, string | undefined, number, number]>(
    () => [
      "nursetimeslots/getallnursedatawithtimeslot",
      partnerUUID,
      selectedWeek.valueOf(),
      selectedWeek.clone().add(6, "day").valueOf(),
    ],
    [partnerUUID, selectedWeek]
  );

  return weekViewQueryKey;
};

export default useGetStaffSchedulerWeekViewQueryKey;
