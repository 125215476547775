import { FC, useCallback, useState } from "react";
import { Divider, List, ListItem, ListItemText, Tooltip } from "@mui/material";
import BookingModel, { BookingPatientModel, PatientReportModel } from "../../models/BookingModel";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import IconButton from "@mui/material/IconButton";
import DeletePatientReportModal from "./DeletePatientReportModal";

const PatientReportsList: FC<PatientReportsListProps> = ({ patient, order, setOrder }) => {
  const [deleteReport, setDeleteReport] = useState<PatientReportModel>();

  const handleReportDeletedSuccess = useCallback(
    (order: BookingModel) => {
      setOrder((value) => {
        for (const patient of value.patient ?? []) {
          const patientId = order.patientId?.find((p) => p.id === patient._id);
          patient.reports = patientId?.reports;
        }
        return { ...value, ...order };
      });
    },
    [setOrder]
  );

  return (
    <div>
      <Divider textAlign="left">Reports</Divider>
      <List sx={{ paddingTop: "0px" }}>
        {patient.reports?.map((report) => {
          return (
            <div key={report.report}>
              <ListItem>
                <ListItemText
                  primary={
                    <div style={{ display: "flex", gap: "1rem", alignItems: "center" }}>
                      <a href={report.report} style={{ textDecoration: "underline" }} target="_blank">{`${report.tests?.join(
                        ", "
                      )} report`}</a>
                      <Tooltip title="Delete Report" placement="top">
                        <IconButton color="error" size="small" onClick={() => setDeleteReport(report)}>
                          <DeleteForeverIcon />
                        </IconButton>
                      </Tooltip>
                    </div>
                  }
                />
              </ListItem>
              <Divider />
            </div>
          );
        })}
      </List>
      {deleteReport && (
        <DeletePatientReportModal
          open={!!deleteReport}
          report={deleteReport}
          onClose={function (): void {
            setDeleteReport(undefined);
          }}
          orderUUID={order.orderUUID ?? ""}
          patientID={patient._id ?? ""}
          onReportDeletedSuccessfully={handleReportDeletedSuccess}
        />
      )}
    </div>
  );
};

interface PatientReportsListProps {
  order: BookingModel;
  patient: BookingPatientModel;
  setOrder: React.Dispatch<React.SetStateAction<BookingModel>>;
}

export default PatientReportsList;
