export const listOfLanguages = [
  "Arabic",
  "Amharic",
  "Assamese",
  "Bavarian",
  "Bengali",
  "Bhojpuri",
  "Burmese",
  "Cebuano",
  "Chhattisgarhi",
  "Chittagonian",
  "Czech",
  "Deccan",
  "Dutch",
  "Eastern Min",
  "English",
  "French",
  "Chinese",
  "German",
  "Greek",
  "Gujarati",
  "Hakka",
  "Hausa",
  "Hindi",
  "Hungarian",
  "Igbo",
  "Indonesian",
  "Iranian Persian",
  "Italian",
  "Japanese",
  "Javanese",
  "Kannada",
  "Kazakh",
  "Khmer",
  "Kinyarwanda",
  "Korean",
  "Magahi",
  "Maithili",
  "Malayalam",
  "Malaysian",
  "Mandarin",
  "Marathi",
  "Nepali",
  "Nigerian Fulfulde",
  "Northern Kurdish",
  "Northern Min",
  "Northern Pashto",
  "Northern Uzbek",
  "Odia",
  "Polish",
  "Portuguese",
  "Punjabi ",
  "Romanian",
  "Rundi",
  "Russian",
  "Saraiki",
  "Sindhi",
  "Sinhalese",
  "Somali",
  "South Azerbaijani",
  "Southern Min",
  "Southern Pashto",
  "Spanish",
  "Sunda",
  "Sylheti",
  "Tagalog",
  "Tamil",
  "Telugu",
  "Thai",
  "Turkish",
  "Ukrainian",
  "Urdu",
  "Uyghur",
  "Vietnamese",
  "Yoruba",
  "Zulu",
];
