import { Button, Dialog, DialogContent, DialogTitle, Divider, List } from "@mui/material";
import { FC, useCallback, useMemo, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { ConsultationIvDripModel } from "../../models/GetProductsForConsultationResponseModel";
import { ConsultationRecommendationsType } from "../../types/consultation.recommendations.type";
import { useDispatch, useSelector } from "react-redux";
import { ConsultationRecommendationsInitialState } from "../../reducer/consultation.recommendations.reducer";
import TextField from "@mui/material/TextField";
import SuggestIVDripModalListItem from "./SuggestIVDripModalListItem";
import VaccinesRoundedIcon from "@mui/icons-material/VaccinesRounded";
import useProductsForConsultationQuery from "../../hooks/useProductsForConsultationQuery";
import LoadingSpinner from "../../components/LoadingSpinner";
import ReduxStateModel from "../../models/ReduxStateModel";
import Mixpanel from "../../utils/Mixpanel";

const SuggestIVDripsModal: FC<SuggestIVDripsModalProps> = ({ loading }) => {
  const { orderUUID } = useParams<{ orderUUID: string }>();
  const dispatch = useDispatch();
  const history = useHistory();
  const [searchText, setSearchText] = useState("");
  const [selectedDrip, setSelectedDrip] = useState<ConsultationIvDripModel>();
  const consultationRecommendationsReducer = useSelector((state: ReduxStateModel) => state.consultationRecommendationsReducer);

  const handleClose = useCallback(() => {
    history.push(`/order/${orderUUID}`);
  }, [history, orderUUID]);

  const { query } = useProductsForConsultationQuery();

  const dripsList = useMemo(() => {
    if (!searchText.trim().length) return query.data?.ivDrips;
    return query.data?.ivDrips?.filter((drip) => {
      return drip.name?.toLocaleLowerCase().includes(searchText.trim().toLocaleLowerCase());
    });
  }, [query.data?.ivDrips, searchText]);

  const handleContinueClicked = useCallback(() => {
    const payload: Partial<ConsultationRecommendationsInitialState> = {
      selectedIVDrip: selectedDrip,
    };
    dispatch({ type: ConsultationRecommendationsType.SET_CONSULTATION_RECOMMENDATIONS, payload: payload });
    Mixpanel.track("SuggestIVDripsModal continue clicked", {
      ...payload,
      partnerUser: localStorage.getItem("userName"),
      partnerUserUUID: localStorage.getItem("partnerUserUUID"),
    });
    if (selectedDrip?.customizations?.length) {
      history.push(`/order/${orderUUID}/suggestions/iv-drips/customizations`);
    } else if (consultationRecommendationsReducer.selectedLabTests === undefined) {
      history.push(`/order/${orderUUID}/suggestions/lab-tests`);
    } else {
      // lab test = SKIP | [object, object]
      // TODO: handle saving selected iv drip & lab tests
      history.push(`/order/${orderUUID}/suggestions/confirm`);
    }
  }, [consultationRecommendationsReducer.selectedLabTests, dispatch, history, orderUUID, selectedDrip]);

  const handleSkipClicked = useCallback(() => {
    const payload: Partial<ConsultationRecommendationsInitialState> = {
      selectedIVDrip: "SKIP",
    };
    Mixpanel.track("SuggestIVDripsModal skip clicked", {
      ...payload,
      partnerUser: localStorage.getItem("userName"),
      partnerUserUUID: localStorage.getItem("partnerUserUUID"),
    });
    dispatch({ type: ConsultationRecommendationsType.SET_CONSULTATION_RECOMMENDATIONS, payload: payload });
    if (consultationRecommendationsReducer.selectedLabTests === undefined) {
      history.push(`/order/${orderUUID}/suggestions/lab-tests`);
    } else if (consultationRecommendationsReducer.selectedBuildYourLabTestItems?.length) {
      history.push(`/order/${orderUUID}/suggestions/confirm`);
    } else if (consultationRecommendationsReducer.selectedLabTests === "SKIP") {
      history.push(`/order/${orderUUID}`);
    } else {
      // consultationRecommendationsReducer.selectedLabTests = [object, object];
      // TODO: handle saving selected lab tests
      history.push(`/order/${orderUUID}/suggestions/confirm`);
    }
  }, [
    consultationRecommendationsReducer.selectedBuildYourLabTestItems?.length,
    consultationRecommendationsReducer.selectedLabTests,
    dispatch,
    history,
    orderUUID,
  ]);

  return (
    <Dialog open={true} onClose={handleClose} fullWidth={true} maxWidth="sm">
      <DialogTitle sx={{ marginBottom: "2rem", lineHeight: "1" }} color="green">
        <VaccinesRoundedIcon /> IV Drips
      </DialogTitle>
      <DialogContent
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          gap: "1rem",
          paddingBottom: "2rem",
          height: "100%",
          "& .MuiButtonBase-root": {
            minWidth: "300px",
          },
          "& .MuiButton-containedPrimary": {
            color: "white",
          },
        }}
      >
        <TextField
          label="Search IV Drips"
          variant="outlined"
          size="small"
          fullWidth={true}
          sx={{ marginTop: "0.3rem" }}
          autoFocus={true}
          value={searchText}
          onChange={(e) => {
            setSearchText(e.target.value);
          }}
        />
        <Divider sx={{ width: "100%", borderColor: "black" }} />
        <LoadingSpinner spinning={query.isLoading || loading} styles={{ minHeight: "100px" }}>
          <List
            dense
            sx={{
              width: "100%",
              bgcolor: "background.paper",
              height: "100%",
              minHeight: "60vh",
              maxHeight: "60vh",
              overflow: "scroll",
            }}
          >
            {dripsList?.map((ivDrip) => {
              return (
                <SuggestIVDripModalListItem
                  key={ivDrip.productUUID}
                  ivDrip={ivDrip}
                  selected={ivDrip.productUUID === selectedDrip?.productUUID}
                  onSelectClicked={function (drip: ConsultationIvDripModel): void {
                    if (selectedDrip?.productUUID === drip.productUUID) {
                      setSelectedDrip(undefined);
                    } else {
                      setSelectedDrip(drip);
                    }
                  }}
                />
              );
            })}
          </List>
        </LoadingSpinner>
        <Button
          variant={selectedDrip ? "contained" : "text"}
          fullWidth={true}
          disabled={query.isLoading || loading}
          disableElevation={true}
          onClick={selectedDrip ? handleContinueClicked : handleSkipClicked}
        >
          {selectedDrip ? "Continue" : "Skip"}
        </Button>
      </DialogContent>
    </Dialog>
  );
};

interface SuggestIVDripsModalProps {
  loading: boolean;
}

export default SuggestIVDripsModal;
