import { FC } from "react";
import { Col, Radio, Row } from "antd";
import { CalendarViewType } from ".";
import WeekSelector from "./WeekSelector";
import MonthSelector from "./MonthSelector";
import DaySelector from "./DaySelector";
import StaffSchedulerSearchInput from "./StaffSchedulerSearchInput";

const ExploreViewControls: FC<ExploreViewControlsProps> = ({
  viewType,
  onViewTypeClicked,
}) => {
  let selector;
  if (viewType === "week") {
    selector = (
      <Col xs={24} sm={24} md={13} lg={9} className="controls-leading">
        <WeekSelector />
      </Col>
    );
  } else if (viewType === "month") {
    selector = (
      <Col xs={24} sm={24} md={24} lg={14} className="controls-leading">
        <MonthSelector />
      </Col>
    );
  } else {
    selector = (
      <Col xs={24} sm={24} md={24} lg={13} xl={9} className="controls-leading">
        <DaySelector />
      </Col>
    );
  }

  return (
    <Row className="controls">
      {selector}

      <Col
        xs={24}
        sm={12}
        md={viewType === "week" ? 10 : 12}
        lg={viewType === "month" ? 10 : 8}
        className="view-type"
      >
        <Radio.Group
          className="view-type-radio-group"
          value={viewType}
          onChange={(e) => onViewTypeClicked(e.target.value)}
        >
          <Radio.Button value="day">Day</Radio.Button>
          <Radio.Button value="week">Week</Radio.Button>
          <Radio.Button value="month">Month</Radio.Button>
        </Radio.Group>
      </Col>
      <Col
        xs={24}
        sm={12}
        md={viewType === "week" ? 24 : 12}
        lg={viewType === "month" ? 24 : 7}
      >
        <StaffSchedulerSearchInput />
      </Col>
    </Row>
  );
};

interface ExploreViewControlsProps {
  isFetching: boolean;
  isLoading: boolean;
  viewType: CalendarViewType;
  onViewTypeClicked: (type: CalendarViewType) => void;
}

export default ExploreViewControls;
