import { Divider } from "antd";
import { FC } from "react";
import { FiChevronRight } from "react-icons/fi";
import { useHistory } from "react-router-dom";
import { StaffMemberModel } from "../../models/StaffMemberModel";
import TeamListProfileImage from "./TeamListProfileImage";

const DriverListItem: FC<DriverListItemProps> = ({ driver, renderDivider }) => {
  // const imageSize = "40px";
  const history = useHistory();

  const openDriverProfile = () => {
    history.push(`/profile/driver/${driver.driverUUID}`, {
      editDriver: true,
      driver,
    });
  };

  return (
    <>
      <li
        className={!driver.isActive ? "disabled-driver-list-item" : ""}
        onClick={openDriverProfile}
        style={{
          cursor: "pointer",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          gap: "16px",
          padding: "10px 0px",
        }}
      >
        <div style={{ display: "flex", gap: "1rem" }}>
          <TeamListProfileImage src={driver.profileImageUrl} alt={driver.name ?? ""} />
          <div style={{ display: "flex", flexDirection: "column", gap: "0.7rem" }}>
            <div style={{ color: "black" }}>{driver.name}</div>
            <div className="tag">{driver.isActive ? "Active" : "Disabled"}</div>
          </div>
        </div>
        <div
          style={{
            padding: "0px 16px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            fontSize: "20px",
          }}
        >
          <FiChevronRight />
        </div>
      </li>
      {renderDivider && <Divider style={{ margin: "0px" }} />}
    </>
  );
};

interface DriverListItemProps {
  driver: StaffMemberModel;
  renderDivider: boolean;
}

export default DriverListItem;
