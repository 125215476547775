import React, { FC, useEffect, useRef, useState } from "react";
import { createCustomEqual } from "fast-equals";
import { isLatLngLiteral } from "@googlemaps/typescript-guards";

const GoogleMap: FC<MapProps> = ({ onClick, onIdle, children, style, onMapCreated, ...options }) => {
  const ref = useRef<HTMLDivElement>(null);
  const [map, setMap] = useState<google.maps.Map>();

  useEffect(() => {
    if (ref.current && !map) {
      setMap(new window.google.maps.Map(ref.current, {}));
    }
  }, [ref, map]);

  useEffect(() => {
    if (onMapCreated && map) {
      onMapCreated(map);
    }
  }, [map, onMapCreated]);

  useDeepCompareEffectForMaps(() => {
    if (map) {
      map.setOptions(options);
    }
  }, [map, options]);

  useEffect(() => {
    if (map) {
      ["click", "idle"].forEach((eventName) => google.maps.event.clearListeners(map, eventName));
      if (onClick) {
        map.addListener("click", onClick);
      }
      if (onIdle) {
        map.addListener("idle", () => onIdle(map));
      }
    }
  }, [map, onClick, onIdle]);

  return (
    <>
      <div ref={ref} style={style} />
      {React.Children.map(children, (child) => {
        if (React.isValidElement(child)) {
          return React.cloneElement(child as React.ReactElement, { map: map });
        }
      })}
    </>
  );
};

interface MapProps extends google.maps.MapOptions {
  style: React.CSSProperties;
  children?: React.ReactNode;
  onClick?: (e: google.maps.MapMouseEvent) => void;
  onIdle?: (map: google.maps.Map) => void;
  onMapCreated?: (map: google.maps.Map) => void;
}

export const Marker: React.FC<DDMarkerProps> = (options) => {
  const [marker, setMarker] = React.useState<google.maps.Marker>();

  React.useEffect(() => {
    if (!marker) {
      const newMarker = new google.maps.Marker();
      setMarker(newMarker);
      if (options.onMarkerCreated) {
        options.onMarkerCreated(newMarker);
      }
      if (options.toolTipContent) {
        const infoWindow = new google.maps.InfoWindow({
          content: options.toolTipContent,
        });
        infoWindow.open(options.map, newMarker);
        // newMarker.addListener("click", function () {
        //   infoWindow.open(options.map, newMarker);
        // });
      }
    }
    return () => {
      if (marker) {
        marker.setMap(null);
      }
    };
  }, [marker, options]);

  React.useEffect(() => {
    if (marker) {
      marker.setOptions(options);
    }
  }, [marker, options]);

  return null;
};

export interface DDMarkerProps extends google.maps.MarkerOptions {
  map: google.maps.Map;
  toolTipContent?: string | null | Element | Text;
  onMarkerCreated?: (marker: google.maps.Marker) => void;
}

const deepCompareEqualsForMaps = createCustomEqual((deepEqual) => (a: any, b: any) => {
  if (isLatLngLiteral(a) || a instanceof google.maps.LatLng || isLatLngLiteral(b) || b instanceof google.maps.LatLng) {
    return new google.maps.LatLng(a).equals(new google.maps.LatLng(b));
  }
  return deepEqual(a, b);
});

function useDeepCompareMemoize(value: any) {
  const ref = React.useRef();
  if (!deepCompareEqualsForMaps(value, ref.current)) {
    ref.current = value;
  }
  return ref.current;
}

function useDeepCompareEffectForMaps(callback: React.EffectCallback, dependencies: any[]) {
  React.useEffect(callback, [...dependencies.map(useDeepCompareMemoize), callback]);
}

export default GoogleMap;
