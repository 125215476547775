import { useQuery } from "@tanstack/react-query";
import { getAllPartnerRequests } from "../services/inventory";
import { useMemo } from "react";

export default function useAllPartnerRequestsQuery({ partnerUUID }: { partnerUUID?: string }) {
  const queryKey = useMemo(() => {
    return ["inventory-requests/get-all-partner-requests", partnerUUID];
  }, [partnerUUID]);

  const query = useQuery(queryKey, () => getAllPartnerRequests({ partnerUUID: partnerUUID ?? "" }), { enabled: !!partnerUUID });

  return { query, queryKey };
}
