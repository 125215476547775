import moment from "moment";
import { Button, Divider, Modal } from "antd";
import { FC, useCallback, useMemo } from "react";
import { useHistory, useRouteMatch } from "react-router-dom";
import { NurseBookingModel, NursesDatum } from "../../models/SchedulerExploreViewResponseModel";
import logo from "../../assets/images/logo.jpg";
import { HistoryBookingType } from "../SchdulerDetailView/BookingDetailsModal";

const BreakTimeDetailsModal: FC = () => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const history = useHistory<any>();
  const bookingStr: string = history.location.state.booking;
  const nurseData: NursesDatum = history.location.state.nurseData;
  const { url } = useRouteMatch();

  const booking = useMemo<NurseBookingModel>(() => {
    return JSON.parse(bookingStr);
  }, [bookingStr]);

  const handleDeleteClicked = useCallback(() => {
    const urlBase = url.split("/")[1];
    const deleteRouteBooking: HistoryBookingType = {
      addressDetails: booking.addressDetails,
      endMoment: moment(booking.endMoment).valueOf(),
      nurseUUID: booking.nurseUUID,
      startMoment: moment(booking.startMoment).valueOf(),
      address: booking.address,
      caseDescription: booking.caseDescription,
      locationLat: booking.locationLat,
      locationLng: booking.locationLng,
      nurseData: nurseData,
      patients: [],
      nurseName: nurseData.name,
      nurseProfileImg: nurseData.profileImage,
      nurseOrderID: booking.nurseOrderID,
      type: booking.type,
    };
    history.replace(`/${urlBase}/delete-booking`, { booking: deleteRouteBooking });
  }, [
    booking.address,
    booking.addressDetails,
    booking.caseDescription,
    booking.endMoment,
    booking.locationLat,
    booking.locationLng,
    booking.nurseOrderID,
    booking.nurseUUID,
    booking.startMoment,
    booking.type,
    history,
    nurseData,
    url,
  ]);

  const onCancel = useCallback(() => {
    history.replace("/scheduler");
  }, [history]);

  return (
    <Modal
      centered
      className="scheduler-modal scheduler-booking-details-modal"
      title={
        <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
          <div>Break Time</div>
        </div>
      }
      open={true}
      maskClosable={false}
      footer={null}
      onCancel={onCancel}
    >
      <div className="modal-container">
        <div className="detail-row">
          <div className="label">Professional:</div>
          <div className="value">
            <strong>{booking.nurseName}</strong>
          </div>
        </div>
        {booking.patients && booking.address && booking.locationLat && booking.locationLng && (
          <div className="detail-row">
            <div className="label">Address:</div>
            <div className="value">
              <a
                href={`https://www.google.com/maps/search/?api=1&query=${booking.locationLat}%2C${booking.locationLng}`}
                target="_blank"
                rel="noreferrer"
              >
                {`${booking.addressDetails} ${booking.address}`}
              </a>
            </div>
          </div>
        )}
        {booking?.temporaryPickupAddress?.address && (
          <div className="detail-row">
            <div className="label">Temporary pickup location:</div>
            <div className="value">
              <a href={booking?.temporaryPickupAddress.addressLink ?? ""} target="_blank" rel="noreferrer">
                {booking?.temporaryPickupAddress.address ?? ""}
              </a>
            </div>
          </div>
        )}
        {booking?.temporaryDropOffAddress?.address && (
          <div className="detail-row">
            <div className="label">Temporary drop location:</div>
            <div className="value">
              <a href={booking?.temporaryDropOffAddress.addressLink} target="_blank" rel="noreferrer">
                {booking?.temporaryDropOffAddress.address}
              </a>
            </div>
          </div>
        )}
        {booking.service && (
          <div className="detail-row">
            <div className="label">Service:</div>
            <div className="value">
              <strong>{booking.service}</strong>
            </div>
          </div>
        )}
        {booking.patients && booking.patients.length > 0 && booking.patients[0].caseDescription && (
          <div className="detail-row">
            <div className="label">Case Description:</div>
            <div className="value">
              <strong>{booking.patients[0].caseDescription}</strong>
            </div>
          </div>
        )}
        <div className="detail-row">
          <div className="label">From:</div>
          <div className="value">
            <strong>{moment(booking.startMoment).format("DD/MM/YYYY hh:mm A")}</strong>
          </div>
        </div>
        <div className="detail-row">
          <div className="label">To:</div>
          <div className="value">
            <strong>{moment(booking.endMoment).format("DD/MM/YYYY hh:mm A")}</strong>
          </div>
        </div>
        {booking.patients && booking.patients.length > 0 && booking.patients[0].source && (
          <div className="detail-row">
            <div className="label">Source:</div>
            <div className="value">
              <strong>{booking.patients[0].source}</strong>
              {booking.patients[0].source === "DARDOC" && (
                <img
                  src={logo}
                  alt="DarDoc"
                  style={{
                    width: "35px",
                    height: "35px",
                  }}
                />
              )}
            </div>
          </div>
        )}
      </div>
      <Divider
        style={{
          margin: "0px",
        }}
      />
      <div
        style={{
          paddingLeft: "24px",
          paddingRight: "24px",
          paddingBottom: "12px",
          paddingTop: "12px",
          display: "flex",
          justifyContent: "space-between",
          alignContent: "center",
          gap: "1rem",
        }}
      >
        {booking.nurseOrderID ? (
          <Button onClick={handleDeleteClicked} danger>
            Delete
          </Button>
        ) : (
          <div />
        )}
        <Button type="default" onClick={onCancel} className="ant-btn-defaul-primary">
          Close
        </Button>
      </div>
    </Modal>
  );
};

export default BreakTimeDetailsModal;
