import { Button, Dialog, DialogContent, DialogTitle } from "@mui/material";
import { Form, Select } from "antd";
import { FC, useCallback, useState } from "react";
import { useSelector } from "react-redux";
import { PartnerUserRoleType } from "../../models/PartnerUserModel";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { invitePartnerUsers } from "../../services/partner.service";
import { LoadingButton } from "@mui/lab";
import { showMessage } from "../../components/common/notification";
import usePartnerUsersInvites from "../../hooks/usePartnerUsersInvites";
import ReduxStateModel from "../../models/ReduxStateModel";

const AddPartnerUserButton: FC<AddPartnerUserButtonProps> = () => {
  const queryClient = useQueryClient();
  const authReducer = useSelector((state: ReduxStateModel) => state.authReducer?.setting);
  const [open, setOpen] = useState(false);

  const { queryKey: invitesQueryKey } = usePartnerUsersInvites({ partnerUUID: authReducer?.partnerUUID });

  const invitePartnerMutation = useMutation({ mutationFn: invitePartnerUsers });

  const handleFormSubmit = useCallback(
    async (values: { emails: string[]; role: PartnerUserRoleType }) => {
      const res = await invitePartnerMutation.mutateAsync({
        currentUserID: authReducer?.partnerUserUUID ?? "",
        emails: values.emails,
        partnerUUID: authReducer?.partnerUUID ?? "",
        role: values.role,
      });
      if (res?.success) {
        queryClient.resetQueries(invitesQueryKey);
        setOpen(false);
        showMessage("success", "Invite has been sent");
      } else if (res?.errors?.length) {
        showMessage("error", res?.errors[0].msg ?? "Something went wrong");
      } else {
        showMessage("error", "Something went wrong");
      }
    },
    [authReducer?.partnerUUID, authReducer?.partnerUserUUID, invitePartnerMutation, invitesQueryKey, queryClient]
  );

  if (authReducer?.role !== "SUPER_ADMIN") return null;

  return (
    <>
      <div style={{ display: "flex", justifyContent: "flex-end" }}>
        <Button variant="outlined" onClick={() => setOpen(true)}>
          Add Users
        </Button>
      </div>
      <Dialog
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby="add-users-modal"
        maxWidth="sm"
        fullWidth={true}
        sx={{ zIndex: 1000 }}
      >
        <DialogTitle>Invite Users</DialogTitle>
        <DialogContent>
          <Form layout="vertical" onFinish={handleFormSubmit}>
            <Form.Item
              name={"emails"}
              label="Emails"
              rules={[
                {
                  required: true,
                  message: "Please enter at least one email",
                },
              ]}
            >
              <Select mode="tags" placeholder="Enter email and press enter to add multiple emails" />
            </Form.Item>
            <Form.Item
              name={"role"}
              label="Role"
              rules={[
                {
                  required: true,
                  message: "Please select the role",
                },
              ]}
            >
              <Select
                options={[
                  {
                    value: "SUPER_ADMIN",
                    label: "Super Admin",
                  },
                  {
                    value: "READ_WRITE",
                    label: "Read & Write",
                  },
                  {
                    value: "READ",
                    label: "Read",
                  },
                ]}
              />
            </Form.Item>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <Button variant="outlined" onClick={() => setOpen(false)}>
                Cancel
              </Button>
              <LoadingButton type="submit" variant="contained" loading={invitePartnerMutation.isLoading}>
                <span style={{ color: "white" }}>Send Invite</span>
              </LoadingButton>
            </div>
          </Form>
        </DialogContent>
      </Dialog>
    </>
  );
};

interface AddPartnerUserButtonProps {}

export default AddPartnerUserButton;
