import { combineReducers } from "redux";
import { authReducer, initialState as authState } from "./auth.reducer";
import { analyticsInitState, analyticsReducer } from "./analytics.reducer";
import { orderReducer, initialState as orderState } from "./order.reducer";
import { darViewReducer, initialState as darViewState } from "./darview.reducer";
import { bookingReducer, initialState as bookingState } from "./bookings.reducer";
import { assignedReducer, initialState as assignedState } from "./assigned.reducer";
import { reportingReducer, initialState as reportingState } from "./reporting.reducer";
import { nurseReducer, initialState as nurseState } from "./nurse.reducer";
import { searchReducer, initialState as searchState } from "./search.reducer";
import { sortReducer, initialState as sortState } from "./sort.reducer";
import { settingReducer, initialState as settingState } from "./setting.reducer";
import { completebookingReducer, initialState as completebookingState } from "./completebooking.reducer";
import { locationReducer, initialState as locationState } from "./location.reducer";
import schedulerExploreViewReducer, { initState as schedulerExploreViewInitState } from "./schedulerExploreViewReducer";
import schedulerDetailViewReducer, { initState as schedulerDetailViewInitState } from "./schedulerDetailViewReducer";
import { sidebarReducer, sidebarInitState } from "./sidebar.reducer";
import { driverSchedulerReducer, driverSchedulerInitState } from "./driver.scheduler.reducer";
import {
  consultationRecommendationsReducer,
  initialState as consultationRecommendations,
} from "./consultation.recommendations.reducer";
import { appSettingsReducer, initialState as appSettingsInitialState } from "./app.settings.reducer";

export const rootInitialState = {
  consultationRecommendations,
  appSettings: appSettingsInitialState,
  schedulerDetailView: schedulerDetailViewInitState,
  schedulerExploreView: schedulerExploreViewInitState,
  authReducer: authState,
  orderReducer: orderState,
  darViewReducer: darViewState,
  bookingReducer: bookingState,
  reportingReducer: reportingState,
  sortReducer: sortState,
  nurseReducer: nurseState,
  searchReducer: searchState,
  settingReducer: settingState,
  assignedReducer: assignedState,
  completebookingReducer: completebookingState,
  locationReducer: locationState,
  analytics: analyticsInitState,
  sidebar: sidebarInitState,
  driverScheduler: driverSchedulerInitState,
};

const rootReducer = combineReducers({
  consultationRecommendationsReducer,
  appSettingsReducer,
  schedulerDetailViewReducer,
  schedulerExploreViewReducer,
  authReducer,
  orderReducer,
  darViewReducer,
  bookingReducer,
  reportingReducer,
  nurseReducer,
  searchReducer,
  sortReducer,
  settingReducer,
  assignedReducer,
  completebookingReducer,
  locationReducer,
  analyticsReducer,
  sidebarReducer,
  driverSchedulerReducer,
});

//export default rootReducer;

const rootReducerFunc = (state, action) => rootReducer(action.type === "LOGOUT" ? undefined : state, action);

export default rootReducerFunc;
