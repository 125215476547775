import { FC, useMemo } from "react";
import { StaffMemberModel } from "../../models/StaffMemberModel";
import { GeneratedDriverScheduleItem } from "../../services";

const AutoNavCellStepItem: FC<AutoNavCellStepItemProps> = ({
  index,
  type,
  nurseName,
  time,
  isUnAssigned,
  staffMembersNameMap,
  task,
}) => {
  const driver = useMemo(() => {
    return staffMembersNameMap.get(task.driverName);
  }, [staffMembersNameMap, task.driverName]);

  const nurse = useMemo(() => {
    return staffMembersNameMap.get(task.nurseName);
  }, [staffMembersNameMap, task.nurseName]);

  const isAssignedToDriver = useMemo(() => {
    if (!nurse?.skillNumber) return false;
    return nurse?.skillNumber === driver?.skillNumber;
  }, [driver?.skillNumber, nurse?.skillNumber]);

  return (
    <div style={{ display: "flex", gap: "8px", alignItems: "center" }}>
      <div
        style={{
          fontWeight: "600",
          fontSize: "10px",
          minWidth: "48px",
          textAlign: isUnAssigned && type === "delivery" ? "center" : undefined,
        }}
      >
        {isUnAssigned && type === "delivery" ? "---" : time}
      </div>
      <div
        style={{
          width: dotSize,
          maxWidth: dotSize,
          minWidth: dotSize,
          height: dotSize,
          minHeight: dotSize,
          maxHeight: dotSize,
          borderRadius: "50%",
          backgroundColor: "black",
          position: "relative",
          overflow: "visible",
        }}
      >
        {index > 0 && (
          <div
            style={{
              position: "absolute",
              width: "2px",
              height: "38px",
              bottom: "0px",
              left: "1.5px",
              borderRadius: "20px",
              backgroundColor: "black",
            }}
          />
        )}
      </div>
      <div style={{ fontWeight: "500", fontSize: "12px", color: "black" }}>{`${
        type === "pickup" ? "Pickup" : "Drop"
      } ${nurseName}`}</div>
      {isAssignedToDriver && <span style={{ color: "gray", fontSize: "10px" }}>(assigned to {driver?.name})</span>}
    </div>
  );
};

interface AutoNavCellStepItemProps {
  index: number;
  type: "pickup" | "delivery";
  time: string;
  nurseName: string;
  isUnAssigned: boolean;
  staffMembersNameMap: Map<string, StaffMemberModel>;
  task: GeneratedDriverScheduleItem;
}

const dotSize = "5px";

export default AutoNavCellStepItem;
