import axios from "axios";
import decrypt from "./decrypt";
import { SearchBookingsType } from "../components/SearchBookingsInput";
import BaseResponseModel from "../models/BaseResponseModel";
import BookingModel from "../models/BookingModel";

export const searchServices = {
  searchOrderData,
};

export interface SearchOrdersResponseModel extends BaseResponseModel {
  orders?: BookingModel[];
}

async function searchOrderData(type: SearchBookingsType, value: string, partnerUUID: string, pageNumber: number) {
  const searchValue = type === "BOOKING_ID" ? value.toUpperCase() : value;
  const promiseResults = await Promise.all([
    axios.get<string>(`/api/v1/orders/search/${type}/${searchValue}/ACCEPT/${partnerUUID}/${pageNumber}`),
    axios.get<string>(`/api/v1/orders/search/${type}/${searchValue}/ASSIGN/${partnerUUID}/${pageNumber}`),
    axios.get<string>(`/api/v1/orders/search/${type}/${searchValue}/REPORT/${partnerUUID}/${pageNumber}`),
  ]);
  const decryptedResponses = promiseResults.map<SearchOrdersResponseModel>((res) => decrypt(res.data));
  const allOrdersList: BookingModel[] = [];
  const seenOrderIDs = new Map<string, boolean>();
  for (const res of decryptedResponses) {
    for (const order of res.orders ?? []) {
      if (seenOrderIDs.get(order.orderUUID ?? "")) continue;
      seenOrderIDs.set(order.orderUUID ?? "", true);
      allOrdersList.push(order);
    }
  }
  const res: SearchOrdersResponseModel = {
    success: 1,
    orders: allOrdersList,
  };
  return res;
}
