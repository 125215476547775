import { Form, FormInstance, TimePicker } from "antd";
import { FC } from "react";
import { DriverForm } from "./OldDriverProfile";

const BreakStartTime: FC<BreakStartTimeProps> = ({ form }) => {
  const startTime = Form.useWatch("startTime", form);
  const endTime = Form.useWatch("endTime", form);

  return (
    <Form.Item
      name="breakStartTime"
      label="Break start time"
      style={{ width: "100%" }}
      rules={[
        () => ({
          validator(_, value) {
            if (!value) return Promise.resolve();
            if (value.isSameOrBefore(startTime) || value.isSameOrAfter(endTime)) {
              return Promise.reject("Break start time must be between shift start and end times");
            }
            return Promise.resolve();
          },
        }),
      ]}
    >
      <TimePicker
        style={{ width: "100%" }}
        minuteStep={5}
        format="hh:mmA"
        onSelect={(value) => {
          form.setFieldValue("breakStartTime", value);
        }}
      />
    </Form.Item>
  );
};

interface BreakStartTimeProps {
  form: FormInstance<DriverForm>;
}

export default BreakStartTime;
