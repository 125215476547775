import { FC, useEffect, useState } from "react";
import LocationAutoCompleteSearch, { LocationAutoCompleteSearchProps } from "./LocationAutoCompleteSearch";

const AddPatientLocationAutoComplete: FC<LocationAutoCompleteSearchProps> = ({ id, value: initialValue, onPlaceChanged }) => {
  const [value, setValue] = useState<string>("");

  useEffect(() => {
    if (initialValue) {
      setValue(initialValue);
    }
  }, [initialValue]);

  return <LocationAutoCompleteSearch id={id} onPlaceChanged={onPlaceChanged} value={value} onValueChange={setValue} />;
};

export default AddPatientLocationAutoComplete;
