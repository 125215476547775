import { FC } from "react";
import { TextField, Button, Checkbox, FormControlLabel } from "@mui/material";
import { Modal, Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import response from "./response.png";
import useNewLoginPage from "./useNewLoginPage";
import moment from "moment";
import logo from "./logo.svg";
import "./style.css";

const NewLoginPage: FC = () => {
  const {
    email,
    password,
    showPassword,
    loginUserMutation,
    showDraftStatusModal,
    setEmail,
    setPassword,
    setShowPassword,
    handleFormSubmitted,
    setShowDraftStatusModal,
  } = useNewLoginPage();

  return (
    <div className="login-page">
      <div className="container gradient">
        <nav>
          <img className="logo" src={logo} alt="DarDoc" />
        </nav>
        <div className="center-content">
          <div className="left-section">
            <Spin
              style={{ width: "100%" }}
              spinning={loginUserMutation.isLoading}
              indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />}
            >
              <form
                onSubmit={(e) => {
                  e.preventDefault();
                  handleFormSubmitted();
                }}
              >
                <div className="login-footer">
                  <h1
                    style={{
                      fontWeight: "bold",
                      fontSize: "30px",
                    }}
                  >
                    Log in
                  </h1>
                  <br />
                  <TextField
                    className="login-input"
                    // error={error}
                    // type="email"
                    required={true}
                    label="User name"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    variant="outlined"
                    placeholder="Enter your user name"
                    style={{
                      marginBottom: "1.4rem",
                      borderRadius: "4px",
                    }}
                  />
                  {/* <p
                    style={{
                      cursor: "pointer",
                      color: "#25bcbd",
                      fontWeight: 500,
                      alignItems: "end",
                      alignSelf: "end",
                    }}
                    onClick={handleForgetPassword}
                  >
                    Forgot Password?
                  </p> */}
                  <TextField
                    // error={error}
                    required={true}
                    label="Password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    variant="outlined"
                    type={showPassword ? "text" : "password"}
                    placeholder="Enter your password"
                    style={{
                      marginBottom: "1.4rem",
                      borderRadius: "4px",
                    }}
                  />
                  <div>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={showPassword}
                          color="primary"
                          onClick={() => setShowPassword((prevValue) => !prevValue)}
                        />
                      }
                      label="Show password"
                    />
                  </div>
                  <br />
                  <Button
                    // disabled={disabledbtn}
                    variant="contained"
                    color="primary"
                    className="login-btn custom-button-hover"
                    type="submit"
                    style={{
                      borderRadius: "4px",
                      color: "#ffffff",
                      padding: "0.8rem",
                      marginBottom: "1rem",
                      fontSize: "15px",
                    }}
                  >
                    &nbsp; Log in
                  </Button>
                  <p
                    style={{
                      position: "fixed",
                      bottom: 0,
                      fontSize: "12px",
                      alignSelf: "center",
                    }}
                  >
                    © DarDoc Health Technologies Limited ADGM {moment().format("YYYY")}
                  </p>
                </div>
              </form>
            </Spin>
          </div>
        </div>
      </div>
      <Modal
        open={showDraftStatusModal}
        onOk={() => setShowDraftStatusModal(false)}
        onCancel={() => setShowDraftStatusModal(false)}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            alignContent: "center",
          }}
        >
          <h1
            style={{
              color: "#1e1e1e",
              marginBottom: "2rem",
              marginTop: "0.5rem",
              fontWeight: "500",
            }}
          >
            Your response has been submitted!
          </h1>
          <img style={{ width: "120px" }} src={response} alt="email" />
          <p
            style={{
              marginBottom: "0px",
              marginTop: "1.5rem",
              fontWeight: "100",
              color: "#7d7d7d",
              textAlign: "center",
            }}
          >
            Your documents are currently under verification. Please check back later for updates on submission status. We
            appreciate your patience.
          </p>
        </div>
      </Modal>
    </div>
  );
};

export default NewLoginPage;
