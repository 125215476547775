import { bookingsType } from "../types";

export const initialState = {
  bookingsOrders: [],
  loader: false,
};

export function bookingReducer(state = initialState, action) {
  switch (action.type) {
    case bookingsType.REQUEST_BOOKING:
      return {
        ...state,
        loader: true,
      };
    case bookingsType.REQUEST_BOOKING_DONE:
      return {
        ...state,
        loader: false,
      };
    case bookingsType.GET_ORDERS:
      return {
        ...state,
        bookingsOrders: action.data,
        loader: false,
      };

    default: {
      return {
        ...state,
      };
    }
  }
}
