import { useQuery } from "@tanstack/react-query";
import { SortOrdersParamsType, sortServices } from "../services";
import { useMemo } from "react";
import ReduxStateModel from "../models/ReduxStateModel";
import { useSelector } from "react-redux";

export default function useSortOrdersQuery(params: Partial<SortOrdersParamsType>) {
  const authReducer = useSelector((state: ReduxStateModel) => state.authReducer?.setting);

  const queryKey = useMemo(() => {
    return [
      "orders/sort",
      params.pageNumber,
      authReducer?.partnerUUID,
      params.sortUnreportedOrdersOnly,
      params.type,
      params.value,
      params.view,
      params.filter?.nurse,
      params.filter?.service,
    ];
  }, [
    params.filter,
    params.pageNumber,
    authReducer?.partnerUUID,
    params.sortUnreportedOrdersOnly,
    params.type,
    params.value,
    params.view,
  ]);

  const enableQuery = useMemo<boolean>(() => {
    return !!params.filter?.nurse?.length || !!params.filter?.service?.length || !!params.type;
  }, [params.filter?.nurse?.length, params.filter?.service?.length, params.type]);

  const query = useQuery(
    queryKey,
    () =>
      sortServices.sortOrders({
        filter: params.filter!,
        pageNumber: params.pageNumber ?? 1,
        partnerUUID: authReducer?.partnerUUID ?? "",
        type: params.type ?? "ASCENDING",
        value: params.value!,
        view: params.view ?? "ACCEPT",
        sortUnreportedOrdersOnly: params.sortUnreportedOrdersOnly,
      }),
    {
      enabled: enableQuery,
      refetchOnWindowFocus: true,
      staleTime: Infinity,
      cacheTime: Infinity,
    }
  );

  return { query, queryKey };
}
