import { FC, useMemo } from "react";
import { StaffMemberModel } from "../models/StaffMemberModel";
import useAllPartnerNurses from "../hooks/useAllPartnerNurses";
import MultipleSelectDropdown, { MultipleSelectDropdownItem } from "./MultipleSelectDropdown";

const NurseMultipleSelectDropdown: FC<NurseMultipleSelectDropdownProps> = ({ onSelectedNursesChanged, selectedNurses }) => {
  const { query } = useAllPartnerNurses();

  const value = useMemo<MultipleSelectDropdownItem[]>(() => {
    return selectedNurses.map((nurse) => {
      return {
        id: nurse.nurseUUID ?? "",
        label: `${nurse.firstName} ${nurse.lastName}`.trim(),
      };
    });
  }, [selectedNurses]);

  const selectOptions = useMemo<MultipleSelectDropdownItem[]>(() => {
    return (
      query.data?.data.nurses?.map<MultipleSelectDropdownItem>((nurse) => {
        return { label: `${nurse.firstName} ${nurse.lastName}`.trim(), id: nurse.nurseUUID ?? "" };
      }) ?? []
    );
  }, [query.data?.data.nurses]);

  return (
    <MultipleSelectDropdown
      value={value}
      options={selectOptions}
      loading={query.isLoading}
      label={"Filter By Professional"}
      onChange={function (values: MultipleSelectDropdownItem[]): void {
        const selectedIDs = values.map<string>((value) => value.id ?? "");
        onSelectedNursesChanged(
          query.data?.data.nurses?.filter((nurse) => {
            return selectedIDs.includes(nurse.nurseUUID ?? "");
          }) ?? []
        );
      }}
    />
  );
};

interface NurseMultipleSelectDropdownProps {
  selectedNurses: StaffMemberModel[];
  onSelectedNursesChanged: (values: StaffMemberModel[]) => void;
}

export default NurseMultipleSelectDropdown;
