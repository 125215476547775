import { useHistory } from "react-router-dom";
import { AssignPatientsModalHistoryType } from "../SchdulerDetailView/AssignPatientsModal";
import { Form } from "antd";
import { nurseOrdersService } from "../../services";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { showMessage } from "../../components/common/notification";
import { CalendarViewType } from ".";
import moment from "moment";
import useCheckIfNurseIsAvailableForBookingDates from "../../customHooks/useCheckIfNurseIsAvailableForBookingDates";
import { useCallback, useMemo } from "react";
import staffSchedulerQueryUpdater from "../SchdulerDetailView/staffSchedulerQueryUpdater";
import useGetStaffSchedulerQueryData from "../../hooks/useGetStaffSchedulerQueryData";
import useGetStaffSchedulerWeekViewQueryKey from "../../hooks/useGetStaffSchedulerWeekViewQueryKey";
import useGetStaffSchedulerMonthViewQueryKey from "../../hooks/useGetStaffSchedulerMonthViewQueryKey";
import useGetStaffSchedulerDayViewQueryKey from "../../hooks/useGetStaffSchedulerDayViewQueryKey";

function useAddBreakTimeModal({ viewType }: { viewType: CalendarViewType }) {
  const [form] = Form.useForm<AddProfessionalBreakForm>();
  const queryClient = useQueryClient();
  const history = useHistory<AssignPatientsModalHistoryType | undefined>();
  const startTime = Form.useWatch("startTime", form);
  const duration = Form.useWatch("duration", form);

  const endTime = useMemo<moment.Moment | undefined>(() => {
    if (!duration) return;
    return startTime?.clone().add(duration, "hour");
  }, [duration, startTime]);

  const momentUserClickOnFromCalendar = useMemo<moment.Moment>(
    () => moment(history.location?.state?.initialSelectedDate),
    [history.location?.state?.initialSelectedDate]
  );

  const { data: checkNurseAvailableResponse } = useCheckIfNurseIsAvailableForBookingDates({
    enableQuery: true,
    onlyCheckForBookings: false,
    selectedNurseUUID: history.location.state?.nurseUUID,
    startTime: startTime,
    endTime: endTime,
    editMode: false,
    momentUserClickOnFromCalendar: momentUserClickOnFromCalendar,
    selectedDatesArray: [momentUserClickOnFromCalendar],
    isSingleDay: true,
  });

  const createNurseOrderMutation = useMutation({ mutationFn: nurseOrdersService.createMultipleDaysNurseOrder });

  const exploreViewData = useGetStaffSchedulerQueryData(viewType);

  const weekViewQueryKey = useGetStaffSchedulerWeekViewQueryKey();
  const monthViewQueryKey = useGetStaffSchedulerMonthViewQueryKey();
  const dayViewQueryKey = useGetStaffSchedulerDayViewQueryKey();

  const handleFormFinishedValidation = useCallback(
    async (values: AddProfessionalBreakForm) => {
      const timeFormat = "HH:mm";
      const dateFormat = "DD/MM/YYYY";
      const endMoment = values.startTime?.clone().add(values.duration, "hour");
      const res = await createNurseOrderMutation.mutateAsync({
        type: "BREAK",
        address: "",
        patientName: "-",
        addressDetails: "",
        nurseUUID: history.location.state?.nurseUUID ?? "",
        dates: [
          {
            startTime: moment(
              `${momentUserClickOnFromCalendar.format(dateFormat)} ${values.startTime?.format(timeFormat) ?? ""}`,
              `${dateFormat} ${timeFormat}`
            ).format(),
            endTime: moment(
              `${momentUserClickOnFromCalendar.format(dateFormat)} ${endMoment?.format(timeFormat) ?? ""}`,
              `${dateFormat} ${timeFormat}`
            ).format(),
          },
        ],
      });
      if (!res?.nurseOrder) {
        return showMessage("error", "Something went wrong");
      }
      showMessage("success", "Break time was added successfully");
      const newQueryValue = staffSchedulerQueryUpdater({
        type: "BREAK",
        formValues: values,
        nurseUUID: history.location.state?.nurseUUID ?? "",
        schedulerExploreViewData: exploreViewData,
        nurseOrders: res.nurseOrder,
      });
      queryClient.setQueryData(weekViewQueryKey, newQueryValue);
      queryClient.setQueryData(monthViewQueryKey, newQueryValue);
      queryClient.setQueryData(dayViewQueryKey, newQueryValue);
      history.replace("/scheduler");
    },
    [
      createNurseOrderMutation,
      dayViewQueryKey,
      exploreViewData,
      history,
      momentUserClickOnFromCalendar,
      monthViewQueryKey,
      queryClient,
      weekViewQueryKey,
    ]
  );

  return { momentUserClickOnFromCalendar, history, form, checkNurseAvailableResponse, handleFormFinishedValidation };
}

export interface AddProfessionalBreakForm {
  startTime?: moment.Moment;
  duration?: number;
}

export default useAddBreakTimeModal;
