import { Dialog, DialogContent, DialogTitle } from "@mui/material";
import { FC } from "react";
import BookingModel from "../../models/BookingModel";
import UploadBookingReportForm from "./UploadBookingReportForm";

const UploadBookingReportModal: FC<UploadBookingReportModalProps> = ({ onReportUploadedSuccess, onClose, open, booking }) => {
  return (
    <Dialog open={open} onClose={onClose} aria-labelledby="Upload Report" maxWidth="md" fullWidth={true}>
      <DialogTitle sx={{ textAlign: "center", paddingTop: "2rem" }}>
        <h3>Submit Reports</h3>
      </DialogTitle>
      <DialogContent>
        {open && (
          <UploadBookingReportForm
            onCancelClicked={onClose}
            onReportUploadedSuccess={onReportUploadedSuccess}
            booking={booking}
          />
        )}
      </DialogContent>
    </Dialog>
  );
};

interface UploadBookingReportModalProps {
  open: boolean;
  booking: BookingModel;
  onReportUploadedSuccess: (order: BookingModel) => void;
  onClose: () => void;
}

export default UploadBookingReportModal;
