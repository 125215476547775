import { useMemo } from "react";
import { GetNurseDetailsResponseModel, nurseService } from "../services";
import { useQuery } from "@tanstack/react-query";

function useNurseDetails({
  nurseUUID,
  onError,
  onSuccess,
}: {
  nurseUUID?: string;
  onSuccess?: (res: GetNurseDetailsResponseModel | null) => void;
  onError?: () => void;
}) {
  const queryKey = useMemo(() => ["nurses/getparticularnurse", nurseUUID], [nurseUUID]);

  const query = useQuery(queryKey, () => nurseService.getNurseDetails(nurseUUID ?? ""), {
    enabled: !!nurseUUID,
    refetchOnWindowFocus: false,
    cacheTime: Infinity,
    staleTime: Infinity,
    onSuccess: onSuccess,
    onError: onError,
  });

  return { queryKey, query };
}

export default useNurseDetails;
