import { nurseType } from "../types";

export const initialState = {
  nurses: [],
  drivers: [],
  loader: false,
  singleNurse: {},
  nurseUpdateLoader: false,
  nurseReview: {},
  validNursesWithOrder: [],
  validNursesLoader: false,
};

const sortNurses = (data) => {
  let sortedData = data.sort(function (a, b) {
    let nameA = `${a.firstName} ${a.lastName}`;
    let nameB = `${b.firstName} ${b.lastName}`;
    nameA = nameA.toLowerCase().replace(/\s+/g, " ").trim();
    nameB = nameB.toLowerCase().replace(/\s+/g, " ").trim();
    if (nameA < nameB) {
      return -1;
    }
    if (nameA > nameB) {
      return 1;
    }
    return 0;
  });

  return sortedData;
};

export function nurseReducer(state = initialState, action) {
  switch (action.type) {
    case nurseType.GET_VALID_NURSES_LOADER:
      return {
        ...state,
        validNursesLoader: action.data,
      };
    case nurseType.GET_VALID_NURSES_FOR_ORDER:
      return {
        ...state,
        validNursesWithOrder: action.data,
      };
    case nurseType.GET_NURSE_REVIEW:
      return {
        ...state,
        nurseReview: action.data,
      };
    case nurseType.LOG_OUT:
      return {
        nurses: [],
        loader: false,
        singleNurse: {},
      };
    case nurseType.GET_LOADER:
      return {
        ...state,
        loader: true,
      };

    case nurseType.GET_NURSE_UPDATE_LOADER:
      return {
        ...state,
        nurseUpdateLoader: action.data,
      };

    case nurseType.SINGLE_NURSE:
      return {
        ...state,
        singleNurse: action.data,
      };
    case nurseType.GET_NURSES:
      return {
        ...state,
        nurses: sortNurses(action.data),
        drivers: action.drivers,
        loader: false,
      };
    default: {
      return {
        ...state,
      };
    }
  }
}
