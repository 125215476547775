export function googleMapsDirectionsForMultipleTrips(
  requests: GoogleMapsDirectionsAPIRequestModel[]
): Promise<google.maps.DirectionsResult[]> | null {
  try {
    const directionsService = new google.maps.DirectionsService();
    // return Promise.all(requests.map<Promise<GoogleMapsDirectionsAPIResponseModel | null>>((req) => googleMapsDirections(req)));
    return Promise.all(
      requests.map((req) => {
        return directionsService.route({
          origin: req.origin,
          destination: req.destination,
          travelMode: google.maps.TravelMode.DRIVING,
          region: "ae",
        });
      })
    );
  } catch (e) {
    console.log("--- googleMapsDirectionsForMultipleTrips error");
    console.log(e);
    return null;
  }
}

export async function googleMapsDirections({
  origin,
  destination,
  wayPoints,
}: GoogleMapsDirectionsAPIRequestModel): Promise<google.maps.DirectionsResult | null> {
  try {
    const directionsService = new google.maps.DirectionsService();
    // const res = await axios.get<GoogleMapsDirectionsAPIResponseModel>("https://maps.googleapis.com/maps/api/directions/json", {
    //   params: { origin, destination, arrival_time: arrivalTime, waypoints: wayPoints, key: googleAPIKey },
    // });
    const res = await directionsService.route({
      origin: origin,
      destination: destination,
      travelMode: google.maps.TravelMode.DRIVING,
      region: "ae",
      // drivingOptions: departureTime ? { departureTime } : undefined,
      waypoints: wayPoints.map((point) => {
        return {
          location: point,
          stopover: true,
        };
      }),
    });
    // console.log("--- params", { origin, destination, wayPoints });
    // console.log(
    //   "--- DirectionsService",
    //   res.routes[0].legs.map((leg) => {
    //     return {
    //       start: { address: leg.start_address, lat: leg.start_location.lat(), lng: leg.start_location.lng() },
    //       end: { address: leg.end_address, lat: leg.end_location.lat(), lng: leg.end_location.lng() },
    //     };
    //   })
    // );
    return res;
  } catch (e) {
    console.log("--- googleMapsDirections error");
    console.log(e);
    return null;
  }
}

export interface GoogleMapsDirectionsAPIRequestModel {
  origin: string;
  destination: string;
  arrivalTime?: number;
  departureTime?: Date;
  wayPoints: string[];
}
