import { FC } from "react";
import { Box, Button, Divider } from "@mui/material";
import PartnerInventoryListItemModel from "../models/PartnerInventoryListItemModel";
import AddInventoryRequestListItem from "./AddInventoryRequestListItem";
import AddCircleOutlineRoundedIcon from "@mui/icons-material/AddCircleOutlineRounded";

const AddInventoryRequestItems: FC<AddInventoryRequestItemsProps> = ({ items, isOutsource, setItems }) => {
  return (
    <Box marginTop={3}>
      <Divider>Order Items</Divider>
      {items.map((item, index) => {
        return (
          <AddInventoryRequestListItem key={index} item={item} index={index} isOutsource={isOutsource} setItems={setItems} />
        );
      })}
      <Button
        variant="outlined"
        fullWidth={true}
        sx={{ marginTop: 4 }}
        onClick={() => {
          setItems((value) => [...value, { name: "", quantity: 1 }]);
        }}
        startIcon={<AddCircleOutlineRoundedIcon />}
      >
        Add an item
      </Button>
    </Box>
  );
};

interface AddInventoryRequestItemsProps {
  items: PartnerInventoryListItemModel[];
  isOutsource?: boolean;
  setItems: React.Dispatch<React.SetStateAction<PartnerInventoryListItemModel[]>>;
}

export default AddInventoryRequestItems;
