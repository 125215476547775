import { Wrapper } from "@googlemaps/react-wrapper";
import { Col, Form, Input, InputNumber, Row, Select } from "antd";
import { FormInstance } from "antd/lib/form";
import { FC, useEffect } from "react";
import { qualificationSkillsMap } from "../../constants";
import { ProfileImageCardProps } from "./ProfileImageCard";
import RenderGoogleMapsWrapper from "../../components/common/RenderGoogleMapsWrapper";
import StaffProfileAddressSearchInput from "../NurseProfile/StaffProfileAddressSearchInput";
import NurseLocationsFormItem from "./NurseLocationsFormItem";
import NurseAssignDriverFormItem from "./NurseAssignDriverFormItem";
import useAppSettings from "../../hooks/useAppSettings";

const ProfessionalDetailsTab: FC<ProfessionalDetailsTabProps> = ({ form, staffMember, setMember }) => {
  const { query } = useAppSettings();
  const googleAPIKey = query.data?.key?.googleKey;
  const qualification: string | undefined = Form.useWatch("qualification", form);
  const showLicenseNumberField = qualification === "REGISTERED" || qualification === "PHYSIOTHERAPIST";
  const skillsList = qualificationSkillsMap.get(qualification ?? "");

  useEffect(() => {
    if (!qualification) return;
    const selectedSkills: string[] | undefined = form.getFieldValue("skills");
    const availableSkills = qualificationSkillsMap.get(qualification);
    if (!availableSkills) return;
    const availableSkillsMap = new Map<string, boolean>();
    for (const skill of availableSkills ?? []) {
      availableSkillsMap.set(skill, true);
    }
    if (selectedSkills?.some((skill) => !availableSkillsMap.get(skill))) {
      form.setFieldValue("skills", []);
    }
  }, [form, qualification]);

  return (
    <Row style={{ width: "100%" }}>
      <Col span={24}>
        <Row
          style={{
            rowGap: "1rem",
            flexWrap: "wrap",
            justifyContent: "space-between",
            alignItems: "flex-start",
          }}
        >
          <Col xs={24} sm={11} md={11} lg={11}>
            {googleAPIKey && (
              <Wrapper render={RenderGoogleMapsWrapper} libraries={["places"]} apiKey={googleAPIKey}>
                <StaffProfileAddressSearchInput profile={staffMember} setProfile={setMember} />
              </Wrapper>
            )}
          </Col>
          <Col xs={24} sm={11} md={11} lg={11}>
            <NurseLocationsFormItem />
          </Col>
        </Row>
      </Col>
      <Col span={24}>
        <Row
          style={{
            rowGap: "1rem",
            flexWrap: "wrap",
            justifyContent: "space-between",
            alignItems: "flex-start",
          }}
        >
          <Col
            xs={24}
            sm={showLicenseNumberField ? 11 : 24}
            md={showLicenseNumberField ? 11 : 24}
            lg={showLicenseNumberField ? 11 : 24}
          >
            <Form.Item
              style={{ flex: "auto" }}
              label="Qualification"
              name="qualification"
              rules={[
                {
                  required: true,
                  message: "Please select the qualification",
                },
              ]}
            >
              <Select showSearch={true}>
                <Select.Option value="REGISTERED">Registered Nurse</Select.Option>
                <Select.Option value="CERTIFIED">Certified Caregiver</Select.Option>
                <Select.Option value="PHYSIOTHERAPIST">Physiotherapist</Select.Option>
                <Select.Option value="TECHNICIAN">Technician</Select.Option>
                <Select.Option value="PHYSICIAN">Physician</Select.Option>
                <Select.Option value="MIDWIFE">Midwife</Select.Option>
                <Select.Option value="SLEEP COACH">Sleep Coach</Select.Option>
                <Select.Option value="SLEEP CONSULTANT">Sleep Consultant</Select.Option>
                <Select.Option value="LACTATION SPECIALIST">Lactation Specialist</Select.Option>
                <Select.Option value="NURSING_ASSISTANT">Nursing Assistant</Select.Option>
                <Select.Option value="CLINICAL_DIETITIAN">Clinical Dietitian</Select.Option>
              </Select>
            </Form.Item>
          </Col>
          {showLicenseNumberField && (
            <Col xs={24} sm={11} md={11} lg={11}>
              <Form.Item
                style={{ flex: "auto" }}
                name="licenseNumber"
                label="License No."
                rules={[
                  {
                    required: true,
                    message: "Please enter the license number",
                  },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>
          )}
        </Row>
      </Col>
      <Col span={24}>
        <Row
          style={{
            rowGap: "1rem",
            flexWrap: "wrap",
            justifyContent: "space-between",
            alignItems: "flex-start",
          }}
        >
          <Col xs={24} sm={11} md={11} lg={11}>
            <Form.Item
              className="professional-years-of-experience-form-item"
              name="totalYearsOfExperience"
              label="Years of experience"
              rules={[
                {
                  required: true,
                  message: "Please select the locations",
                },
              ]}
            >
              <InputNumber className="professional-years-of-experience-input" style={{ width: "100%" }} />
            </Form.Item>
          </Col>
          <Col xs={24} sm={11} md={11} lg={11}>
            <NurseAssignDriverFormItem />
          </Col>
        </Row>
      </Col>
      <Col span={24}>
        <Row
          style={{
            rowGap: "1rem",
            flexWrap: "wrap",
            justifyContent: "space-between",
            alignItems: "flex-start",
          }}
        >
          <Col xs={24} sm={11} md={11} lg={11}>
            <Form.Item style={{ flex: "auto" }} label="Certificates" name="certificationsList">
              <Select mode="tags" />
            </Form.Item>
          </Col>
          <Col xs={24} sm={11} md={11} lg={11}>
            <Form.Item style={{ flex: "auto" }} label="Skills" name="skills">
              <Select
                mode={(skillsList?.length ?? 0) > 0 ? "multiple" : "tags"}
                filterOption={
                  (skillsList?.length ?? 0) > 0
                    ? (input, option) => {
                        return (option?.label ?? "").toLowerCase().includes(input.toLowerCase());
                      }
                    : undefined
                }
                options={skillsList?.map((value) => {
                  return {
                    label: value,
                    key: value,
                    value: value,
                  };
                })}
              />
            </Form.Item>
          </Col>
        </Row>
      </Col>
      <Col span={24}>
        <Form.Item
          className="professional-bio-form-item"
          name="bio"
          label="Bio"
          rules={[
            {
              required: true,
              message: "Please enter the bio",
            },
          ]}
        >
          <Input.TextArea />
        </Form.Item>
      </Col>
    </Row>
  );
};

interface ProfessionalDetailsTabProps extends ProfileImageCardProps {
  form: FormInstance;
}

export default ProfessionalDetailsTab;
