import { Box, Divider, SxProps, Tab, Tabs, Theme } from "@mui/material";
import { FC, useCallback } from "react";
// import SwipeableViews from "react-swipeable-views";

const TabsView: FC<TabsViewProps> = ({ tabs, style, value, setValue }) => {
  const handleChange = useCallback(
    (_: React.SyntheticEvent, newValue: number) => {
      setValue(newValue);
    },
    [setValue]
  );

  // const handleChangeIndex = useCallback(
  //   (index: number) => {
  //     setValue(index);
  //   },
  //   [setValue]
  // );

  return (
    <Box
      sx={{
        ...style,
        "& .react-swipeable-view-container": {
          height: "100%",
        },
      }}
    >
      <Tabs
        value={value}
        onChange={handleChange}
        textColor="inherit"
        aria-label="bookings tabs"
        sx={{
          "@media (max-width: 700px)": {
            "& .MuiTabs-flexContainer": {
              justifyContent: "center",
            },
          },
        }}
      >
        {tabs.map((tab) => {
          return <Tab key={tab.key} label={tab.label} sx={{ height: "100%" }} {...a11yProps(0)} />;
        })}
      </Tabs>
      <Divider sx={{ backgroundColor: "gray", marginBottom: "24px" }} />
      {tabs[value].tab}
      {/* <SwipeableViews index={value} onChangeIndex={handleChangeIndex}>
        {tabs.map((tab, i) => {
          return (
            <TabPanel key={tab.key} index={i} value={i}>
              {tab.tab}
            </TabPanel>
          );
        })}
      </SwipeableViews> */}
    </Box>
  );
};

function a11yProps(index: number) {
  return {
    id: `tab-${index}`,
    "aria-controls": `tabpanel-${index}`,
  };
}

// function TabPanel(props: TabPanelProps) {
//   const { children, value, index, ...other } = props;

//   return (
//     <div
//       style={{ height: "100%" }}
//       role="tabpanel"
//       hidden={value !== index}
//       id={`tabpanel-${index}`}
//       aria-labelledby={`tab-${index}`}
//       {...other}
//     >
//       {value === index && children}
//     </div>
//   );
// }

interface TabsViewProps {
  tabs: TabViewItem[];
  style?: SxProps<Theme>;
  value: number;
  setValue: React.Dispatch<React.SetStateAction<number>>;
  // onTabChange?: (index: number) => void;
}

interface TabViewItem {
  key: string;
  label: React.ReactNode;
  tab: React.ReactNode;
}

// interface TabPanelProps {
//   children?: React.ReactNode;
//   dir?: string;
//   index: number;
//   value: number;
// }

export default TabsView;
