import { Form, Select } from "antd";
import { FC, useMemo } from "react";
import { useSelector } from "react-redux";
import ReduxStateModel from "../../models/ReduxStateModel";

const NurseLocationsFormItem: FC<NurseLocationsFormItemProps> = () => {
  const authReducerSettings: { city?: string[]; status?: string } =
    useSelector((state: ReduxStateModel) => state?.authReducer?.setting) ?? {};
  const { city } = authReducerSettings;

  const locationOptions = useMemo<string[]>(() => {
    if (!city?.length) return [];
    const tempLoc: string[] = [];
    for (let i = 0; i < city?.length; i++) {
      if (
        city[i] === "Abu-Dhabi-A" ||
        city[i] === "Abu-Dhabi-B" ||
        city[i] === "Abu-Dhabi-C" ||
        city[i] === "Abu-Dhabi-D" ||
        city[i] === "Abu-Dhabi-E" ||
        city[i] === "Abu-Dhabi-F" ||
        city[i] === "Abu-Dhabi-H" ||
        city[i] === "Abu-Dhabi-G"
      ) {
        if (!tempLoc?.includes("Abu-Dhabi")) {
          tempLoc.push("Abu-Dhabi");
        }
      }
      if (city[i] === "Al-Ain") {
        if (!tempLoc?.includes("Al-Ain")) {
          tempLoc.push("Al-Ain");
        }
      }
      if (
        city[i] === "Dubai" ||
        city[i] === "Dubai-A" ||
        city[i] === "Dubai-B" ||
        city[i] === "Dubai-C" ||
        city[i] === "Dubai-D" ||
        city[i] === "Dubai-E"
      ) {
        if (!tempLoc?.includes("Dubai")) {
          tempLoc.push("Dubai");
        }
      }
      if (city[i] === "Sharjah-A" || city[i] === "Sharjah-B-And-NE") {
        if (!tempLoc?.includes("Sharjah")) {
          tempLoc.push("Sharjah");
        }
      }
      if (city[i] === "RAK") {
        if (!tempLoc?.includes("RAK")) {
          tempLoc.push("RAK");
        }
      }
    }
    return tempLoc;
  }, [city]);

  return (
    <Form.Item
      style={{ flex: "auto" }}
      name="locations"
      label="Locations"
      rules={[
        {
          required: true,
          message: "Please select the locations",
        },
      ]}
    >
      <Select
        mode="multiple"
        showSearch={true}
        filterOption={(input, option) => {
          return option?.label.toLowerCase().includes(input.toLowerCase()) ?? false;
        }}
        style={{ width: "100%" }}
        options={locationOptions.map((location) => {
          return {
            value: location,
            label: location,
          };
        })}
      />
    </Form.Item>
  );
};

interface NurseLocationsFormItemProps {
  // editMode: boolean;
}

export default NurseLocationsFormItem;
