import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import { FC } from "react";

const PartnerUserRoleSelect: FC<PartnerUserRoleSelectProps> = ({ onChange, value, disabled = true }) => {
  return (
    <FormControl fullWidth={true} required={true}>
      <InputLabel id="role-lable">Role</InputLabel>
      <Select
        value={value}
        onChange={(e) => {
          onChange(e.target.value);
        }}
        SelectDisplayProps={{ style: { WebkitTextFillColor: "black" } }}
        disabled={disabled}
        labelId="role-lable"
        id="role"
        label="Role"
      >
        <MenuItem value={"SUPER_ADMIN"}>Super Admin</MenuItem>
        <MenuItem value={"READ_WRITE"}>Read & Write</MenuItem>
        <MenuItem value={"READ"}>Read Only</MenuItem>
      </Select>
    </FormControl>
  );
};

interface PartnerUserRoleSelectProps {
  value: string;
  disabled?: boolean;
  onChange: (value: string) => void;
}

export default PartnerUserRoleSelect;
