import { Box, Button, CircularProgress, Dialog, DialogContent, DialogTitle, Tooltip, Typography } from "@mui/material";
import { FC, useCallback, useRef, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { LoadingButton } from "@mui/lab";
import { showMessage } from "../../components/common/notification";
import { updateTRTMedicationForOrder, uploadReferenceDoc } from "../../services";
import { useMutation } from "@tanstack/react-query";
import CheckCircleRoundedIcon from "@mui/icons-material/CheckCircleRounded";
import ArrowForwardIosRoundedIcon from "@mui/icons-material/ArrowForwardIosRounded";
import useOrderDetails from "../../hooks/useOrderDetails";
import PrescribeTRTSelectMedication from "./PrescribeTRTSelectMedication";
import VisuallyHiddenInput from "../../components/VisuallyHiddenInput";
import ArticleRoundedIcon from "@mui/icons-material/ArticleRounded";
import convertFileToBase64 from "../../utils/convertFileToBase64";

const PrescribeTRTMedicationModal: FC<PrescribeTRTMedicationModalProps> = () => {
  const history = useHistory();
  const controlledMedicationsInputRef = useRef<HTMLInputElement>(null);
  // const uncontrolledMedicationsInputRef = useRef<HTMLInputElement>(null);
  const { orderUUID } = useParams<{ orderUUID: string }>();
  const [selectedMedication, setSelectedMedication] = useState<string | null>(null);
  const [selectedControlledMedication, setSelectedControlledMedication] = useState<File>();
  // const [selectedUncontrolledMedication, setSelectedUncontrolledMedication] = useState<File>();

  const { query } = useOrderDetails({ cacheTime: Infinity, orderUUID: orderUUID });
  const order = query.data?.order;

  const handleClose = useCallback(() => {
    history.push(`/order/${orderUUID}`);
  }, [history, orderUUID]);

  const handleControlledMedicationInputChanged = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    if (!e.target.files?.length) {
      return console.log("--- pick controlled prescription no files error");
    }
    setSelectedControlledMedication(e.target.files[0]);
  }, []);

  // const handleUncontrolledMedicationInputChanged = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
  //   if (!e.target.files?.length) {
  //     return console.log("--- pick un-controlled prescription no files error");
  //   }
  //   setSelectedUncontrolledMedication(e.target.files[0]);
  // }, []);

  const uploadReferenceDocMutation = useMutation({ mutationFn: uploadReferenceDoc });

  const updateTRTForOrderMutation = useMutation({ mutationFn: updateTRTMedicationForOrder });

  const handleSaveClicked = useCallback(async () => {
    if (!selectedMedication || !selectedControlledMedication) {
      return showMessage("error", "Please select the medication and upload a prescription file");
    }
    const controlledMedicationBase64 = await convertFileToBase64(selectedControlledMedication);
    if (!controlledMedicationBase64) {
      return showMessage("error", "Something went wrong");
    }
    // if (selectedUncontrolledMedication && !uncontrolledMedicationBase64) {
    //   return showMessage("error", "Something went wrong");
    // }
    const controlledFileType = selectedControlledMedication?.type.toLocaleLowerCase().includes("pdf") ? "pdf" : "image";
    // const uncontrolledFileType = selectedUncontrolledMedication?.type.toLocaleLowerCase().includes("pdf") ? "pdf" : "image";
    const [uploadControlledMedicationPrescriptionRes, updateMedicationRes] = await Promise.all([
      uploadReferenceDocMutation.mutateAsync({
        orderUUID: orderUUID,
        type: "CONTROLLED",
        document: {
          data: controlledFileType === "pdf" ? controlledMedicationBase64.slice(28) : controlledMedicationBase64,
          fileName: selectedControlledMedication?.name ?? "",
          type: selectedControlledMedication?.type ?? "",
          documentType: "CONTROLLED",
          fileType: controlledFileType,
        },
      }),
      updateTRTForOrderMutation.mutateAsync({ orderUUID: orderUUID, selectedMedication: selectedMedication }),
    ]);
    if (!uploadControlledMedicationPrescriptionRes?.success || !updateMedicationRes?.success) {
      return showMessage("error", "Something went wrong");
    }
    showMessage("success", "Medication was prescribed successfully");
    history.replace(`/order/${orderUUID}/suggestions`);
  }, [
    history,
    orderUUID,
    selectedControlledMedication,
    selectedMedication,
    updateTRTForOrderMutation,
    uploadReferenceDocMutation,
  ]);

  const disableSaveButton = !selectedMedication || !selectedControlledMedication;

  return (
    <Dialog open={true} onClose={handleClose} fullWidth={true} maxWidth="sm">
      <DialogTitle>Prescribe Testosterone Medication</DialogTitle>
      <DialogContent
        sx={{
          minHeight: "400px",
        }}
      >
        {query.isLoading ? (
          <Box width={"100%"} display={"flex"} justifyContent={"center"}>
            <CircularProgress sx={{ zIndex: 3 }} />
          </Box>
        ) : (
          <Box display={"flex"} flexDirection={"column"} justifyContent={"space-between"} minHeight={"400px"} height={"100%"}>
            <Box>
              <PrescribeTRTSelectMedication
                location={order?.emirate ?? ""}
                orderUUID={order?.orderUUID ?? ""}
                value={selectedMedication}
                onChange={setSelectedMedication}
              />
              <Button
                component="label"
                variant="contained"
                disableElevation={true}
                endIcon={
                  selectedControlledMedication ? (
                    <CheckCircleRoundedIcon sx={{ color: "white" }} />
                  ) : (
                    <ArrowForwardIosRoundedIcon sx={{ color: "white" }} />
                  )
                }
                sx={{
                  marginTop: "24px",
                  display: "flex",
                  justifyContent: "space-between",
                }}
                fullWidth={true}
              >
                <Box display={"flex"} alignItems={"center"} gap={1}>
                  <ArticleRoundedIcon sx={{ color: "white" }} />
                  <Typography
                    color={"white"}
                    textAlign={"center"}
                    display={"flex"}
                    alignItems={"center"}
                    justifyContent={"center"}
                    gap={1}
                  >
                    {selectedControlledMedication ? selectedControlledMedication.name : `Click here to select prescription`}
                  </Typography>
                </Box>
                <VisuallyHiddenInput
                  type="file"
                  accept=".jpg, .jpeg, .png, .pdf"
                  ref={controlledMedicationsInputRef}
                  onChange={handleControlledMedicationInputChanged}
                />
              </Button>
              {/* <Button
                component="label"
                variant="outlined"
                color="info"
                sx={{ marginTop: "24px", display: "flex", flexDirection: "column", paddingX: 2, paddingY: 2 }}
                fullWidth={true}
              >
                <Typography marginBottom={2} textAlign={"center"}>
                  General Medication
                </Typography>
                <Typography
                  textAlign={"center"}
                  display={"flex"}
                  alignItems={"center"}
                  justifyContent={"center"}
                  gap={1}
                  fontWeight={600}
                  fontSize={14}
                >
                  {selectedUncontrolledMedication ? selectedUncontrolledMedication.name : `Click here to select prescription`}
                  <ArticleRoundedIcon />
                </Typography>
                <VisuallyHiddenInput
                  type="file"
                  accept=".jpg, .jpeg, .png, .pdf"
                  ref={uncontrolledMedicationsInputRef}
                  onChange={handleUncontrolledMedicationInputChanged}
                />
              </Button> */}
            </Box>
            <Tooltip
              title={
                disableSaveButton ? `Please select the medication and the controlled medication prescription file` : undefined
              }
              placement="top"
            >
              <Box width={"100%"}>
                <LoadingButton
                  loading={uploadReferenceDocMutation.isLoading || updateTRTForOrderMutation.isLoading}
                  onClick={handleSaveClicked}
                  disabled={disableSaveButton}
                  variant="contained"
                  disableElevation={true}
                  fullWidth={true}
                  sx={{
                    "&.MuiButton-containedPrimary": {
                      color: "white",
                    },
                  }}
                >
                  Save
                </LoadingButton>
              </Box>
            </Tooltip>
          </Box>
        )}
      </DialogContent>
    </Dialog>
  );
};

interface PrescribeTRTMedicationModalProps {}

export default PrescribeTRTMedicationModal;
