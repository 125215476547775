import { Autocomplete, TextField } from "@mui/material";
import { FC } from "react";
import useTRTMedicationsListQuery from "../../hooks/useTRTMedicationsListQuery";

const PrescribeTRTSelectMedication: FC<PrescribeTRTSelectMedicationProps> = ({ location, orderUUID, value, onChange }) => {
  const { query } = useTRTMedicationsListQuery({ location, orderUUID });

  return (
    <Autocomplete
      disablePortal
      loading={query.isLoading}
      sx={{
        marginTop: "20px",
        "& .MuiAutocomplete-popupIndicator": {
          transform: "rotate(0deg)",
        },
      }}
      value={value}
      id={"prescribe-trt-select-medication"}
      //   isOptionEqualToValue={(option, value) => option.id === value.id}
      //   value={
      //     selectedNurse
      //       ? {
      //           id: selectedNurse?.nurseUUID ?? "",
      //           label: `${selectedNurse?.firstName ?? ""} ${selectedNurse?.lastName ?? ""}`.trim(),
      //           img: selectedNurse?.profileImageUrl ?? selectedNurse?.profileImage,
      //         }
      //       : null
      //   }
      onChange={(_, value) => {
        onChange(value);
      }}
      options={query.data?.medications ?? []}
      renderInput={(params) => (
        <TextField
          {...params}
          size="small"
          label={"Select Medication"}
          inputProps={{
            ...params.inputProps,
            autoComplete: "new-password",
          }}
        />
      )}
    />
  );
};

interface PrescribeTRTSelectMedicationProps {
  location: string;
  orderUUID: string;
  value: string | null;
  onChange: (medication: string | null) => void;
}

export default PrescribeTRTSelectMedication;
