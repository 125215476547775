import { FormControl, IconButton, InputAdornment, InputLabel, MenuItem, OutlinedInput, Select } from "@mui/material";
import { FC, useCallback, useMemo, useState } from "react";
import SearchIcon from "@mui/icons-material/Search";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";

const SearchBookingsInput: FC<SearchBookingsInputProps> = ({ onSearchClick, onClearSearchClicked, selectedSearchValues }) => {
  const [searchBookingsType, setSearchBookingsType] = useState<SearchBookingsType>("BOOKING_ID");
  const [inputValue, setInputValue] = useState("");

  const handleSearchClicked = useCallback(() => {
    onSearchClick({ type: searchBookingsType, value: inputValue });
  }, [inputValue, onSearchClick, searchBookingsType]);

  const handleClearSearchClicked = useCallback(() => {
    setInputValue("");
    onClearSearchClicked();
  }, [onClearSearchClicked]);

  const inputLabel = useMemo(() => {
    if (searchBookingsType === "BOOKING_ID") {
      return "Booking ID";
    } else if (searchBookingsType === "EMIRATES_ID") {
      return "Emirates ID";
    } else if (searchBookingsType === "MOBILE_NUMBER") {
      return "Mobile Number";
    } else {
      return "Patient Name";
    }
  }, [searchBookingsType]);

  return (
    <div style={{ paddingTop: "5px", display: "flex" }}>
      <FormControl sx={{ m: 1, minWidth: 120, margin: "0px" }} size="small">
        <InputLabel id="booking-search-type-select-label">Search By</InputLabel>
        <Select
          labelId="booking-search-type-select-label"
          id="booking-search-type-select"
          label="Search By"
          sx={{ border: "none", borderTopRightRadius: "0px", borderBottomRightRadius: "0px", borderRight: "none" }}
          value={searchBookingsType}
          onChange={(value) => {
            setSearchBookingsType(value.target.value as SearchBookingsType);
          }}
          componentsProps={{ root: { style: { border: "none" } } }}
        >
          <MenuItem value={"EMIRATES_ID"}>Emirates ID</MenuItem>
          <MenuItem value={"BOOKING_ID"}>Booking ID</MenuItem>
          <MenuItem value={"MOBILE_NUMBER"}>Mobile Number</MenuItem>
          <MenuItem value={"PATIENT_NAME"}>Patient Name</MenuItem>
        </Select>
      </FormControl>
      <FormControl
        sx={{
          m: 1,
          width: "300px",
          "@media (max-width: 600px)": {
            width: "100%",
          },
          margin: "0px",
        }}
        size="small"
        variant="outlined"
      >
        <InputLabel htmlFor="search-bookings-input">{inputLabel}</InputLabel>
        <OutlinedInput
          value={inputValue}
          onChange={(e) => {
            setInputValue(e.target.value);
          }}
          label={inputLabel}
          id="search-bookings-input"
          size="small"
          onKeyUp={(e) => {
            if (e.code === "Enter") {
              handleSearchClicked();
            }
          }}
          sx={{ m: 1, width: "100%", margin: "0px", borderTopLeftRadius: "0px", borderBottomLeftRadius: "0px" }}
          inputProps={{ style: { borderTopLeftRadius: "0px" } }}
          endAdornment={
            <>
              {selectedSearchValues && (
                <InputAdornment position="end">
                  <IconButton aria-label="Clear" onClick={handleClearSearchClicked} edge="end">
                    <CancelOutlinedIcon />
                  </IconButton>
                </InputAdornment>
              )}
              <InputAdornment position="end">
                <IconButton aria-label={inputLabel} onClick={handleSearchClicked} edge="end">
                  <SearchIcon />
                </IconButton>
              </InputAdornment>
            </>
          }
        />
      </FormControl>
    </div>
  );
};

export type SearchBookingsType = "EMIRATES_ID" | "BOOKING_ID" | "MOBILE_NUMBER" | "PATIENT_NAME";

export type SearchBookingsInputValue = { type: SearchBookingsType; value: string };

interface SearchBookingsInputProps {
  selectedSearchValues?: SearchBookingsInputValue;
  onClearSearchClicked: () => void;
  onSearchClick: (params: SearchBookingsInputValue) => void;
}

export default SearchBookingsInput;
