import { Accordion, AccordionDetails, AccordionSummary } from "@mui/material";
import { Col, Row } from "antd";
import { FC, useMemo } from "react";
import useGetUserIDImage from "../../hooks/useGetUserIDImage";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

const PatientImages: FC<{ patientID?: string }> = ({ patientID }) => {
  const { query: getUserIDImageQuery } = useGetUserIDImage({
    patientID: patientID,
  });

  const patientImagesList = useMemo(() => {
    if (getUserIDImageQuery.data?.data.success == 1) {
      return getUserIDImageQuery.data.data.patientImages ?? undefined;
    }
    return undefined;
  }, [getUserIDImageQuery]);

  return patientImagesList?.images && patientImagesList.images.length > 0 ? (
    <Row
      style={{
        width: "100%",
        rowGap: "1rem",
        justifyContent: "space-between",
        alignItems: "center",
      }}
    >
      <Accordion sx={{ marginTop: "1rem", width: "100%" }} defaultExpanded={true}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <div style={{ fontWeight: "bold" }}>Emirates ID/Passport Images</div>
        </AccordionSummary>
        <AccordionDetails sx={{ display: "flex", gap: "1rem", flexWrap: "wrap" }}>
          {patientImagesList.images.map((imgsrc: string, i: number) => (
            <Col xl={11} lg={11} md={11} sm={24} xs={24} key={i}>
              <img
                width="100%"
                height="auto"
                src={imgsrc.startsWith("https://") ? imgsrc : `data:application/image;base64,${imgsrc}`}
              />
            </Col>
          ))}
        </AccordionDetails>
      </Accordion>
    </Row>
  ) : (
    <></>
  );
};

export default PatientImages;
