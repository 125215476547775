import { FC, useEffect } from "react";
import { useSelector } from "react-redux";
import { driverSchedulePageBackgroundColor } from "../../constants";
import AutoNavCellsList from "./AutoNavCellsList";
import AutoNavMapView from "./AutoNavMapView";
import LottieLoadingAnimation from "../../assets/lottie-loading.json";
import Lottie from "lottie-react";
import useAutomatedDriverScheduleQuery from "../../hooks/useAutomatedDriverScheduleQuery";
import LoadingText from "./LoadingText";
import ReduxStateModel from "../../models/ReduxStateModel";
import moment from "moment";
import BasePageLayout from "../../components/BasePageLayout";
import Mixpanel from "../../utils/Mixpanel";
import "./styles.css";

const NewDriverSchedule: FC = () => {
  const selectedDate = useSelector((state: ReduxStateModel) => state?.driverSchedulerReducer?.selectedDate ?? moment());

  useEffect(() => {
    Mixpanel.track("Driver Schedule Page Viewed", {
      partner: { userName: localStorage.getItem("userName"), partnerUserUUID: localStorage.getItem("partnerUserUUID") },
    });
  }, []);

  const {
    query: { isLoading },
  } = useAutomatedDriverScheduleQuery({ selectedDate });

  let content: JSX.Element;
  if (isLoading) {
    content = (
      <div
        style={{
          width: "100%",
          height: "100vh",
          overflow: "hidden",
          display: "flex",
          // justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
        }}
      >
        <Lottie style={{ width: "600px", height: "600px" }} autoPlay={true} loop={true} animationData={LottieLoadingAnimation} />
        <LoadingText />
      </div>
    );
  } else {
    // const width = `calc(100% - ${sidebarCollapsed ? sideMenuCollapsedWidth : sideMenuExpandedWidth}px)`;
    content = (
      <div
        style={{
          // padding: "2rem",
          flex: "1 1",
          width: "100%",
          maxWidth: "100%",
          // height: "100vh",
          // maxHeight: "100vh",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <div
          style={{
            flex: "auto",
            // maxHeight: "100vh",
            display: "flex",
            gap: "16px",
            flexWrap: "wrap",
            alignItems: "stretch",
          }}
        >
          <AutoNavCellsList />
          <AutoNavMapView />
        </div>
      </div>
    );
  }

  return (
    <BasePageLayout
      mainContent={
        <div
          className="new-driver-schedule"
          style={{
            display: "flex",
            height: "100%",
            // minHeight: "100vh",
            backgroundColor: driverSchedulePageBackgroundColor,
          }}
        >
          {content}
        </div>
      }
    />
  );
};

export default NewDriverSchedule;
