import { Button, Form, Select } from "antd";
import Modal from "antd/lib/modal/Modal";
import { FC } from "react";

export const driversList = ["Edward", "Bivek"];
export const driversListColors = ["green", "blue", "red", "#008690", "#ff9184"];

const SelectDriverModal: FC<{ open: boolean; onCancel: () => void; onDriverSelected: (driver: string) => void }> = ({
  open,
  onCancel,
  onDriverSelected,
}) => {
  const handleFormFinished: (values: { driver?: string }) => void = ({ driver }) => {
    if (!driver) return;
    onDriverSelected(driver);
  };

  return (
    <Modal open={open} footer={null} onCancel={onCancel} title="Select Driver">
      <Form onFinish={handleFormFinished}>
        <Form.Item
          name="driver"
          label=""
          rules={[
            {
              required: true,
              message: "Please select the driver name",
            },
          ]}
        >
          <Select
            options={driversList.map((driver) => {
              return {
                label: driver,
                value: driver,
              };
            })}
          />
        </Form.Item>
        <div
          style={{
            display: "flex",
            gap: "16px",
            justifyContent: "flex-end",
          }}
        >
          <Button type="default" onClick={onCancel}>
            Cancel
          </Button>
          <Button type="primary" htmlType="submit">
            Select
          </Button>
        </div>
      </Form>
    </Modal>
  );
};

export default SelectDriverModal;
