import axios from "axios";
import decrypt from "./decrypt";
import encrypt from "./encrypt";

export const settingService = {
  getManageDateTime,
  createManageDateTime,
  updateManageDateTime,
  updateService,
  updateDriverScheduleSettings,
};

async function updateDriverScheduleSettings({
  partnerUUID,
  nurseLateLeniency,
  nurseTravelTime,
  bookingEndPickupDelay,
  avoidTolls,
  useSkills,
}) {
  try {
    const res = await axios.post("/api/v1/partnersettings/updateschedulersettings", {
      data: encrypt({ partnerUUID, useSkills, nurseLateLeniency, nurseTravelTime, bookingEndPickupDelay, avoidTolls }),
    });
    const decryptedData = decrypt(res.data);
    // console.log("--- updateschedulersettings", decryptedData);
    // console.log({ partnerUUID, nurseLateLeniency, nurseTravelTime, bookingEndPickupDelay, avoidTolls, useSkills });
    return decryptedData;
  } catch (e) {
    console.log("--- updateDriverScheduleSettings error");
    console.log(e);
    return null;
  }
}

async function getManageDateTime(partnerUUID) {
  const data = await axios.get(`/api/v1/partnerSettings/${partnerUUID}`);
  const resultdata = { ...data, data: decrypt(data?.data) };
  return resultdata;
}

async function createManageDateTime(dateTimeObject) {
  const data = await axios.post("/api/v1/partnerSettings/set", {
    data: encrypt(dateTimeObject),
  });
  return { ...data, data: decrypt(data?.data) };
}

async function updateManageDateTime(dateTimeObject) {
  const data = await axios.post("/api/v1/partnerSettings/edit", {
    data: encrypt(dateTimeObject),
  });
  return { ...data, data: decrypt(data?.data) };
}

async function updateService(body) {
  const data = await axios.post("/api/v1/centralisedtimeslots/update", {
    data: encrypt(body),
  });

  return { ...data, data: decrypt(data?.data) };
}
