import moment from "moment";
import schedulerExploreViewTypes from "../types/schedulerExploreViewType";
import { NurseBookingModel } from "../models/SchedulerExploreViewResponseModel";

type NurseDayBookingsType = { [prop: string]: NurseBookingModel[] };

export interface SchedulerExploreViewState {
  searchInputValue: string;
  selectedDay: moment.Moment;
  selectedWeek: moment.Moment;
  selectedMonth: moment.Moment;
  selectedEndDateMonthView: moment.Moment;
  nurseDayBookings: NurseDayBookingsType;
}

const monthViewStartMoment = moment().startOf("day");

export const initState: SchedulerExploreViewState = {
  searchInputValue: "",
  selectedDay: moment().startOf("day"),
  selectedWeek: moment().startOf("day"),
  selectedMonth: monthViewStartMoment,
  selectedEndDateMonthView: monthViewStartMoment.clone().add(1, "month").endOf("day"),
  nurseDayBookings: {},
};

const reducer = (
  state: SchedulerExploreViewState = initState,
  action: { payload: unknown; type: string }
): SchedulerExploreViewState => {
  if (action.type === schedulerExploreViewTypes.EXPLORE_VIEW_SET_SELECTED_WEEK) {
    return {
      ...state,
      selectedWeek: action.payload as moment.Moment,
    };
  } else if (action.type === schedulerExploreViewTypes.SET_NURSE_DAY_BOOKINGS) {
    return {
      ...state,
      nurseDayBookings: action.payload as NurseDayBookingsType,
    };
  } else if (action.type === schedulerExploreViewTypes.SET_SELECTED_MONTH) {
    const payload = action.payload as { startMoment: moment.Moment; endMoment: moment.Moment };
    return {
      ...state,
      selectedMonth: payload.startMoment,
      selectedEndDateMonthView: payload.endMoment,
    };
  } else if (action.type === schedulerExploreViewTypes.SET_SELECTED_DAY) {
    return {
      ...state,
      selectedDay: action.payload as moment.Moment,
    };
  } else if (action.type === schedulerExploreViewTypes.SET_SEARCH_INPUT_VALUE) {
    return {
      ...state,
      searchInputValue: action.payload as string,
    };
  }
  return state;
};

export default reducer;
