import { useQuery } from "@tanstack/react-query";
import { getDeliveryPartnerRevenueOrders } from "../services/inventory";
import { useMemo } from "react";

export default function useDeliveryPartnerRevenueOrdersQuery({ partnerUUID }: { partnerUUID?: string }) {
  const queryKey = useMemo(() => ["inventory-requests/get-delivery-partner-revenue-orders", partnerUUID], [partnerUUID]);

  const query = useQuery(queryKey, () => getDeliveryPartnerRevenueOrders({ partnerUUID: partnerUUID ?? "" }), {
    enabled: !!partnerUUID,
  });

  return { query, queryKey };
}
