import { FC, useState } from "react";
import { Box, Button, Dialog, DialogContent, IconButton, Tooltip } from "@mui/material";
import { grey } from "@mui/material/colors";
import BookingModel, { BookingReferenceDocModel } from "../../models/BookingModel";
import PDFView from "../../components/PDFView";
import DownloadRoundedIcon from "@mui/icons-material/DownloadRounded";

const ReferenceDocListItem: FC<{ doc: BookingReferenceDocModel; booking?: BookingModel }> = ({ doc }) => {
  const [showModal, setShowModal] = useState(false);

  const isPDF = doc.url?.endsWith(".pdf") ?? false;

  return (
    <>
      <Box
        border={`1px solid ${grey[300]}`}
        borderRadius={"10px"}
        overflow={"hidden"}
        position={"relative"}
        // sx={{
        //   "& .MuiButtonBase-root": {
        //     opacity: 0,
        //   },
        //   "&:hover .MuiButtonBase-root": {
        //     opacity: 1,
        //   },
        // }}
      >
        <Box
          display={"flex"}
          justifyContent={"flex-end"}
          gap={2}
          alignItems={"center"}
          position={"absolute"}
          width={"100%"}
          left={"0"}
          right={"0"}
          top={"0"}
          zIndex={"10"}
          paddingX={2}
          paddingY={2}
        >
          {/* <Tooltip title="View">
            <IconButton
              size="small"
              onClick={() => {
                setShowModal(true);
              }}
              sx={{
                borderRadius: "4px",
                border: "1px solid #25bcbd",
                width: "30px",
                height: "30px",
                transition: "opacity 0.3s ease-out",
                backgroundColor: "white",
                "&:hover": {
                  backgroundColor: "white",
                },
              }}
            >
              <InsertDriveFileRoundedIcon sx={{ color: "#25bcbd" }} />
            </IconButton>
          </Tooltip> */}
          <a href={doc.url} target="_blank" onClick={(e) => e.stopPropagation()}>
            <Tooltip title="Download">
              <IconButton
                size="small"
                sx={{
                  borderRadius: "4px",
                  width: "30px",
                  height: "30px",
                  color: "white",
                  backgroundColor: "#25bcbd",
                  "&:hover": {
                    backgroundColor: "#25bcbd",
                  },
                }}
              >
                <DownloadRoundedIcon sx={{ color: "white" }} />
              </IconButton>
            </Tooltip>
          </a>
        </Box>
        {isPDF ? (
          <Button
            disableRipple={true}
            disableFocusRipple={true}
            onClick={() => {
              setShowModal(true);
            }}
          >
            <PDFView numberOfPagesToShow={1} url={doc.url ?? ""} />
          </Button>
        ) : (
          <img src={doc.url} alt={doc.type} style={{ maxWidth: "500px", objectFit: "cover" }} />
        )}
      </Box>
      <Dialog open={showModal} onClose={() => setShowModal(false)} maxWidth="lg" fullWidth={true}>
        <DialogContent sx={{ display: "flex", flexDirection: "column", alignItems: "center", position: "relative" }}>
          <Box display={"flex"} justifyContent={"flex-end"} width={"100%"}>
            <a href={doc.url} target="_blank">
              <Tooltip title="Download">
                <IconButton
                  size="small"
                  sx={{
                    borderRadius: "4px",
                    border: "1px solid #25bcbd",
                    width: "30px",
                    height: "30px",
                    transition: "opacity 0.3s ease-out",
                    backgroundColor: "white",
                    "&:hover": {
                      backgroundColor: "white",
                    },
                  }}
                >
                  <DownloadRoundedIcon sx={{ color: "#25bcbd" }} />
                </IconButton>
              </Tooltip>
            </a>
          </Box>
          <PDFView url={doc.url ?? ""} maxHeight={1400} />
        </DialogContent>
      </Dialog>
    </>
  );
};

export default ReferenceDocListItem;
