import { useQuery } from "@tanstack/react-query";
import { getEveryPartners } from "../services/partner.service";
import { useMemo } from "react";
import { PartnerModel } from "../models/PartnerModel";

export default function useAllPartnersQuery() {
  const queryKey = useMemo(() => ["/api/v1/partners"], []);

  const query = useQuery(queryKey, getEveryPartners);

  const partnersIDMap = useMemo(() => {
    const value = new Map<string, PartnerModel>();
    for (const partner of query.data?.partners ?? []) {
      value.set(partner.partnerUUID ?? "", partner);
    }
    return value;
  }, [query.data?.partners]);

  return { query, partnersIDMap, queryKey };
}
