import { reportingType } from "../types";

export const initialState = {
  unreportedorders: [],
  reportedOrders: [],
  loader: false,
};

export function reportingReducer(state = initialState, action) {
  switch (action.type) {
    case reportingType.REQUEST_REPORTING:
      return {
        ...state,
        loader: true,
      };
    case reportingType.REQUEST_REPORTING_DONE:
      return {
        ...state,
        loader: false,
      };
    case reportingType.GET_UNREPORTED_ORDERS:
      return {
        ...state,
        unreportedorders: action.data,
        loader: false,
      };

    case reportingType.GET_REPORTED_ORDERS:
      return {
        ...state,
        reportedOrders: action.data,
        loader: false,
      };

    default: {
      return {
        ...state,
      };
    }
  }
}
