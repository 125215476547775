import { FC, useCallback, useEffect, useMemo, useState } from "react";
import { Box, Tooltip } from "@mui/material";
import BookingModel from "../../models/BookingModel";
import LoadingButton from "@mui/lab/LoadingButton";
import UploadWLPPrescriptionModal from "./UploadWLPPrescriptionModal";
import ArticleIcon from "@mui/icons-material/Article";
import getStartMomentFromDateObj from "../../utils/getStartMomentFromDateObj";
import moment from "moment";
import { trtVideoConsultationProductUUID } from "../../constants";
import { grey } from "@mui/material/colors";

const UploadWLPPrescriptionsButton: FC<UploadWLPPrescriptionsButtonProps> = ({ booking, onPrescriptionUploaded }) => {
  const [showModal, setShowModal] = useState<boolean>(false);

  const [now, setNow] = useState(moment());

  const bookingStartMoment = useMemo(() => {
    return getStartMomentFromDateObj({ date: booking?.date?.[0].date ?? "", time: booking?.date?.[0].time ?? "" }).subtract(
      15,
      "minutes"
    );
  }, [booking?.date]);

  const handleDocumentUploaded = useCallback(() => {
    if (onPrescriptionUploaded) {
      onPrescriptionUploaded();
    }
  }, [onPrescriptionUploaded]);

  const disabledButton = useMemo(() => {
    return now.isBefore(bookingStartMoment);
  }, [bookingStartMoment, now]);

  useEffect(() => {
    if (!disabledButton) return;
    const intervalID = setInterval(() => {
      setNow(moment());
    }, 2000);
    return () => {
      clearInterval(intervalID);
    };
  }, [disabledButton]);

  const buttonText = useMemo(() => {
    if (booking?.patientId?.[0]?.productUUID === trtVideoConsultationProductUUID) {
      return "Prescribe Other Medication";
    }
    return "Upload Prescription";
  }, [booking?.patientId]);

  if (!booking?.isNormalVideoCall) return null;
  if (booking?.status !== "PROCESSING") return null;

  return (
    <>
      <Box
        sx={{
          "@media (max-width: 700px)": {
            width: "100%",
          },
        }}
      >
        <Tooltip
          title={
            disabledButton
              ? `Upload button will be activated on ${bookingStartMoment.format("DD/MM/YYYY hh:mm A")}`
              : "Upload prescription"
          }
        >
          <div style={{ width: "100%" }}>
            <LoadingButton
              startIcon={<ArticleIcon />}
              size="medium"
              variant="contained"
              color="success"
              disableElevation={true}
              sx={{
                "&.MuiButtonBase-root": {
                  color: disabledButton ? grey[500] : "white",
                },
                "& .MuiButton-startIcon": {
                  color: disabledButton ? grey[500] : "white",
                },
                "& .MuiTouchRipple-root": {
                  color: disabledButton ? grey[500] : "white",
                },
                "@media (max-width: 700px)": {
                  width: "100%",
                },
              }}
              disabled={disabledButton}
              onClick={(e) => {
                e.stopPropagation();
                setShowModal(true);
              }}
            >
              {buttonText}
            </LoadingButton>
          </div>
        </Tooltip>
      </Box>
      <UploadWLPPrescriptionModal
        open={showModal}
        orderUUID={booking.orderUUID ?? ""}
        onDocumentUploaded={handleDocumentUploaded}
        onClose={() => setShowModal(false)}
      />
    </>
  );
};

interface UploadWLPPrescriptionsButtonProps {
  booking?: BookingModel;
  onPrescriptionUploaded?: () => void;
  setBooking: React.Dispatch<React.SetStateAction<BookingModel>>;
}

export default UploadWLPPrescriptionsButton;
