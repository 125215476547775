import { useMemo } from "react";
import { useSelector } from "react-redux";

const useAutomatedDriverScheduleQueryKey = ({ selectedDate }: { selectedDate: moment.Moment }) => {
  const partnerUUID: string | undefined = useSelector((state) => (state as any)?.authReducer?.setting?.partnerUUID);
  const dateToCheck = useMemo(() => selectedDate.format("DD/MM/YYYY"), [selectedDate]);
  const previousDate = useMemo(() => selectedDate.clone().subtract(1, "day").format("DD/MM/YYYY"), [selectedDate]);

  const queryKey = useMemo(
    () => ["driverschedules/automatedriverschedule", dateToCheck, previousDate, partnerUUID],
    [dateToCheck, partnerUUID, previousDate]
  );

  return queryKey;
};

export default useAutomatedDriverScheduleQueryKey;
