import { FC } from "react";
import { Row } from "antd";
import AddPartnerUserButton from "./AddPartnerUserButton";
import PartnerUsersList from "./PartnerUsersList";

const MangePartnerUsers: FC = () => {
  return (
    <Row className="setting__container" style={{ height: "100%", width: "100%", rowGap: "1rem" }}>
      <div style={{ width: "100%" }}>
        <p className="container__title">Manage Users</p>
        <AddPartnerUserButton />
        <PartnerUsersList />
      </div>
    </Row>
  );
};

export default MangePartnerUsers;
