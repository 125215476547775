import { TableCell, TableRow } from "@mui/material";
import { FC, useMemo } from "react";
import PharmacyTractionTableItemModel from "../../models/PharmacyTractionTableItemModel";
import moment from "moment";

const PharmacyTractionTableRow: FC<PharmacyTractionTableRowProps> = ({ row }) => {
  const createdAtMoment = useMemo(() => {
    return moment(row.createdAt);
  }, [row.createdAt]);

  return (
    <TableRow hover role="checkbox" tabIndex={-1} key={row.orderUUID}>
      <TableCell className="booking-id-cell">{row.orderUUID}</TableCell>
      <TableCell className="date-time-cell">{createdAtMoment.format("DD/MM/YYYY hh:mm A")}</TableCell>
      <TableCell className="patient-cell">{row.patient?.join(", ")}</TableCell>
      <TableCell className="transaction-type-cell">
        {row.bookingType === "CASH" ? "Cash Prescription" : "Insurance Prescription"}
      </TableCell>
      <TableCell className="insurance-provider-cell">-</TableCell>
      <TableCell className="invoice-amount-cell">
        AED {row.costPrice?.toLocaleString(undefined, { maximumFractionDigits: 2 })}
      </TableCell>
      <TableCell className="dardoc-commision-cell">% {row.ourPercent}</TableCell>
      <TableCell className="receivable-from-dardoc-cell">
        AED {row.partnerPrice?.toLocaleString(undefined, { maximumFractionDigits: 2 })}
      </TableCell>
      <TableCell className="payable-to-dardoc">
        AED {row.ourPrice?.toLocaleString(undefined, { maximumFractionDigits: 2 })}
      </TableCell>
    </TableRow>
  );
};

interface PharmacyTractionTableRowProps {
  row: PharmacyTractionTableItemModel;
}

export default PharmacyTractionTableRow;
