import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import "./style.css";
import { CircularProgress } from "@mui/material";
import { Table } from "antd";
function ManageServices({ data, loader, saveChange, getSettingsPage }) {
  const manageDateTime = useSelector(
    (state) => state.settingReducer.manageDateTime
  );
  const columns = [
    {
      title: "Service",
      key: "service",
      fixed: "left",
      render: (_, service) => service?.engProductName,
    },
    {
      title: "Service Type",
      key: "type",

      render: (_, service) =>
        service?.engProductName.includes("Covid-19 PCR Test")
          ? service?.productSpeciality?.engName
          : "",
    },
    {
      title: "Status",
      key: "status",

      render: (_, service) => (service?.isActive ? "Active" : "Inactive"),
    },
  ];

  const [allservice, setallservice] = useState([]);

  useEffect(() => {
    let sortedservices = manageDateTime?.services?.sort((a, b) => {
      if (a.engProductName < b.engProductName) return -1;
      if (a.engProductName > b.engProductName) return 1;
      return 0;
    });

    setallservice(sortedservices);
  }, [manageDateTime]);

  return (
    <>
      <div
        className="setting__container"
        style={{ width: "100%", height: "max-content" }}
      >
        <p className="container__title">List of Services</p>
        <Table
          locale={{ emptyText: <span>No existing services found!</span> }}
          dataSource={allservice}
          columns={columns}
          scroll={{ x: "400px" }}
          loading={{
            spinning: loader,
            indicator: <CircularProgress />,
          }}
          pagination={false}
        />
        {/* <table className="table" style={{ marginTop: "1rem" }}>
          <thead
            className="thead"
            style={{ position: "sticky", top: "-1.9rem", zIndex: "100" }}
          >
            <tr>
              {labels.map((label, index) => (
                <th
                  key={index}
                  className="th"
                  align={label !== "Manage day & time" ? "left" : "center"}
                  style={{ paddingLeft: index === 0 ? "1rem" : "0rem" }}
                >
                  {label}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {allservice?.length > 0 &&
              loader === false &&
              allservice?.map((service, index) => (
                <tr
                  key={index}
                  align="center"
                  className="hoverServiceTr"
                  style={{ marginBottom: "1rem" }}
                >
                  <td className="td" align="left">
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        alignContent: "center",
                        marginLeft: "1.3rem",
                        color: "rgb(19, 19, 19)",
                      }}
                    >
                      <div>{service?.engProductName}</div>
                    </div>
                  </td>
                  <td className="td" align="left">
                    <p style={{ marginBottom: "0" }}>
                      {service?.engProductName.includes("Covid-19 PCR Test")
                        ? service?.productSpeciality?.engName
                        : ""}
                    </p>
                  </td>
                  <td className="td" align="left">
                    {service?.isActive ? "Active" : "Inactive"}
                  </td>
                </tr>
              ))}

            {loader === true && (
              <tr>
                <td
                  colSpan="4"
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignContent: "center",
                  }}
                >
                  <CircularProgress />
                </td>
              </tr>
            )}

            {loader === false && allservice?.length === 0 && (
              <tr>
                <td colSpan="4">
                  <p style={{ textAlign: "center", padding: "1rem" }}>
                    No existing services found!
                  </p>
                </td>
              </tr>
            )}
          </tbody>
        </table> */}
      </div>
    </>
  );
}

export default ManageServices;
