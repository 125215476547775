import { useState, FC, useEffect, useMemo } from "react";
import { Button, Tooltip } from "@mui/material";
import { trtVideoConsultationProductUUID } from "../../constants";
import { Link, Route, Switch, useRouteMatch } from "react-router-dom";
import MedicationLiquidRoundedIcon from "@mui/icons-material/MedicationLiquidRounded";
import BookingModel from "../../models/BookingModel";
import PrescribeTRTMedicationModal from "./PrescribeTRTMedicationModal";
import moment from "moment";
import getStartMomentFromDateObj from "../../utils/getStartMomentFromDateObj";

const PrescribeTRTMedicationButton: FC<PrescribeTRTMedicationButtonProps> = ({ order }) => {
  const { path, url } = useRouteMatch();

  const orderStartMoment = useMemo(() => {
    return getStartMomentFromDateObj({ date: order.date?.[0]?.date ?? "", time: order.date?.[0]?.time ?? "" }).subtract(
      15,
      "minute"
    );
  }, [order.date]);

  const [now, setNow] = useState(moment());

  const enableButton = useMemo(() => {
    return now.isAfter(orderStartMoment);
  }, [now, orderStartMoment]);

  useEffect(() => {
    if (enableButton) return;
    const intervalID = setInterval(() => {
      setNow(moment());
    }, 2000);
    return () => {
      clearInterval(intervalID);
    };
  }, [enableButton]);

  if (order.status === "REPORTED") return null;
  if (order.status === "ACCEPTED") return null;
  if (order.status === "CANCELLED") return null;
  if (order.patientId?.[0]?.productUUID !== trtVideoConsultationProductUUID) return null;

  return (
    <>
      <Tooltip
        title={enableButton ? "" : `Prescribe Medication button will be enabled at ${orderStartMoment.format("DD/MM hh:mm A")}`}
      >
        <Link to={`${url}/prescribe-medication`}>
          <Button
            disabled={!enableButton}
            startIcon={<MedicationLiquidRoundedIcon />}
            variant="contained"
            color="success"
            disableElevation={true}
            sx={{ height: "100%" }}
          >
            Prescribe Testosterone Medication
          </Button>
        </Link>
      </Tooltip>
      <Switch>
        <Route
          exact={true}
          path={`${path}/prescribe-medication`}
          render={() => {
            return <PrescribeTRTMedicationModal />;
          }}
        />
      </Switch>
    </>
  );
};

interface PrescribeTRTMedicationButtonProps {
  order: BookingModel;
}

export default PrescribeTRTMedicationButton;
