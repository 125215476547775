import axios from "axios";
import decrypt from "./decrypt";
import encrypt from "./encrypt";
import { allAbuDhabiAreas, allDubaiAreas, allSharjahAreas } from "../constants";
import Mixpanel from "../utils/Mixpanel";
import { StaffMemberModel } from "../models/StaffMemberModel";
import BaseResponseModel from "../models/BaseResponseModel";

export const nurseService = {
  addNurse,
  getAllNurses,
  deleteNurse,
  editNurse,
  activeNurse,
  addnurseinbulk,
  updateNurseTimeslot,
  getNurseReview,
  checkDateCanBeDisabled,
  fetchValidNursesForAnOrder,
  getNurseDetails,
  addNurseHolidays,
  getServedHoursForNurse,
  getNurseDetailedRatingReviews,
  changeNurseActiveStatus,
  deleteNurseForAdmin,
};

async function deleteNurseForAdmin({ nurseUUID, partnerUserUUID }: { nurseUUID: string; partnerUserUUID: string }) {
  try {
    // console.log(nurseUUID, partnerUUID);
    const res = await axios.post("/api/v1/nurses/delete", { data: encrypt({ partnerUserUUID, nurseUUID }) });
    const decryptedResponse = decrypt(res.data);
    return decryptedResponse;
  } catch (e) {
    console.log("--- deleteNurseForAdmin error");
    console.log(e);
    return null;
  }
}

async function getNurseDetailedRatingReviews(nurseUUID: string) {
  try {
    const res = await axios.get(`/api/v1/nurses/getdetailedratingsandreview/${nurseUUID}`);
    const data = decrypt(res.data);
    // console.log("--- getdetailedratingsandreview", data);
    return data;
  } catch (error) {
    console.log("--- getdetailedratingsandreview error", error);
    return null;
  }
}

async function getServedHoursForNurse(nurseUUID: string) {
  try {
    const res = await axios.get(`/api/v1/nurses/getservedhours/${nurseUUID}`);
    const data = decrypt(res.data);
    return data;
  } catch (error) {
    console.log("--- getServedHoursForNurse error", error);
    return null;
  }
}

async function addNurseHolidays({ nurseUUID, holidaysToUpdate }: { nurseUUID: string; holidaysToUpdate: unknown }) {
  try {
    const res = await axios.post(`/api/v1/nurses/addholidayfromdarlink`, { data: encrypt({ nurseUUID, holidaysToUpdate }) });
    Mixpanel.track("Mark nurse on leave", { nurseUUID, holidaysToUpdate, user: localStorage.getItem("partnerUserUUID") });
    return decrypt(res.data);
  } catch (e) {
    console.log("--- addNurseHolidays error");
    console.log(e);
    return null;
  }
}

export interface GetNurseDetailsResponseModel extends BaseResponseModel {
  nurse?: StaffMemberModel;
}

async function getNurseDetails(nurseUUID: string): Promise<GetNurseDetailsResponseModel | null> {
  try {
    const res = await axios.get(`/api/v1/nurses/getparticularnurse/${nurseUUID}`);
    const data: GetNurseDetailsResponseModel = decrypt(res.data);
    return data;
  } catch (e) {
    console.log("--- getNurseDetails error");
    console.log(e);
    return null;
  }
}

export interface FetchValidOrderNurseResponseModel extends BaseResponseModel {
  nurses?: string[];
}

async function fetchValidNursesForAnOrder(body: string) {
  // console.log("--- ", `/orders/fetchvalidnurses/${body}`);
  const data = await axios.get(`/api/v1/orders/fetchvalidnurses/${body}`);
  const decryptedData: FetchValidOrderNurseResponseModel = decrypt(data?.data);
  const returnData = { ...data, data: decryptedData };
  return returnData;
}

async function checkDateCanBeDisabled(body: { nurseUUID: string; newHoliday: unknown }) {
  // console.log("--- checkDateCanBeDisabled", body);
  if (!body) return;
  const editedbody = {
    nurseUUID: body?.nurseUUID,
    dateTobeInactive: body?.newHoliday,
  };
  const data = await axios.post(`/api/v1/nurses/checkifnursecanbeinactivated`, {
    data: encrypt(editedbody),
  });
  // return {
  //   data: {
  //     success: 1,
  //     dateData: { "16/02/2023": ["ASDASD", "ZXCZXC", "LKJLKJ"], "17/02/2023": ["ASDASD", "ZXCZXC", "LKJLKJ"] },
  //   },
  // };
  const returnData = { ...data, data: decrypt(data?.data) };
  return returnData;
}

async function getNurseReview(body: unknown) {
  const data = await axios.get(`/api/v1/darflowusers/nursereview/${body}`);
  return { ...data, data: decrypt(data?.data) };
}

async function changeNurseActiveStatus({ nurseUUID, isActive }: { nurseUUID: string; isActive: boolean }) {
  try {
    const res = await axios.post("/api/v1/nurses/deactivatenurse", { data: encrypt({ nurseUUID, isActive }) });
    const data = decrypt(res.data);
    return data;
  } catch (e) {
    console.log("--- changeNurseActiveStatus error", e);
    return null;
  }
}

async function addNurse(addObject: object) {
  const data = await axios.post("/api/v1/nurses/add", {
    data: encrypt(addObject),
  });
  const decryptedData = decrypt(data?.data);
  return { ...data, data: decryptedData };
}

export interface GetAllPartnerNursesResponseModel extends BaseResponseModel {
  nurses?: StaffMemberModel[];
  drivers?: StaffMemberModel[];
}

async function getAllNurses(partnerUUID: string) {
  const data = await axios.get(`/api/v1/nurses/${partnerUUID}`);
  const decryptedData: GetAllPartnerNursesResponseModel = decrypt(data?.data);
  // console.log("--- getAllNurses", partnerUUID, decryptedData);
  return { ...data, data: decryptedData };
}

async function deleteNurse(deleteObject: object) {
  const data = await axios.post("/api/v1/nurses/delete", {
    data: encrypt(deleteObject),
  });
  const decryptedData = decrypt(data?.data);
  return { ...data, data: decryptedData };
}

async function editNurse(editObject: StaffMemberModel) {
  if (editObject?.locations?.includes("Abu-Dhabi")) {
    editObject.locations = editObject?.locations.filter((area) => area !== "Abu-Dhabi");
    editObject.locations.push(...allAbuDhabiAreas);
  }
  if (editObject?.locations?.includes("Dubai")) {
    editObject.locations = editObject?.locations.filter((area) => area !== "Dubai");
    editObject.locations.push(...allDubaiAreas);
  }
  if (editObject?.locations?.includes("Sharjah")) {
    editObject.locations = editObject?.locations.filter((area) => area !== "Sharjah");
    editObject.locations.push(...allSharjahAreas);
  }
  const data = await axios.post("/api/v1/nurses/edit", {
    data: encrypt(editObject),
  });
  const decryptedData = decrypt(data?.data);
  return { ...data, data: decryptedData };
}

async function activeNurse(editObject: object) {
  const data = await axios.post("api/v1/nurses/changenursestatus", {
    data: encrypt(editObject),
  });
  return { ...data, data: decrypt(data?.data) };
}

async function addnurseinbulk(body: object) {
  const data = await axios.post("api/v1/nurses/addinbulk", {
    data: encrypt(body),
  });
  return { ...data, data: decrypt(data?.data) };
}

async function updateNurseTimeslot(body: object) {
  const data = await axios.post("api/v1/nursetimeslots/edit", {
    data: encrypt(body),
  });
  const decryptedData = decrypt(data?.data);
  return { ...data, data: decryptedData };
}
