import { Col, Row, Spin } from "antd";
import { FC, useMemo } from "react";
import { useParams } from "react-router-dom";
import { generateRangeArray } from "../../utils/utilFunctions";
import { AiFillStar } from "react-icons/ai";
import useNurseReviews from "../../hooks/useNurseReviews";
import useNurseDetailedReviews from "../../hooks/useNurseDetailedReviews";
import useNurseServedHoursQuery from "../../hooks/useNurseServedHoursQuery";
import { Box } from "@mui/material";

const ReviewsAndRatingsTab: FC = () => {
  const { id: nurseUUID } = useParams<{ id?: string }>();
  const { query: reviewDetailsQuery } = useNurseDetailedReviews(nurseUUID);
  const { query } = useNurseReviews(nurseUUID);
  const { data: nurseReviewsResponse, isLoading } = query;

  const { query: professionalServedHoursQuery } = useNurseServedHoursQuery(nurseUUID);

  const averageNurseRating = useMemo<number>(() => {
    let value = 0;
    if (nurseReviewsResponse?.data?.reviewsAndRatings?.ratings?.length) {
      for (const rating of nurseReviewsResponse?.data?.reviewsAndRatings?.ratings ?? []) {
        value += +(rating.value ?? "0");
      }
    }
    return value / nurseReviewsResponse?.data?.reviewsAndRatings?.ratings?.length;
  }, [nurseReviewsResponse?.data?.reviewsAndRatings?.ratings]);

  const ratingStars = useMemo<JSX.Element>(() => {
    const numberOfYellowStars = Math.round(averageNurseRating || 0);
    const stars = generateRangeArray({ start: 1, end: 5 }).map<JSX.Element>((i) => {
      return (
        <AiFillStar
          key={i}
          style={{
            color: i <= numberOfYellowStars ? "#FDD835" : "gray",
            fontSize: "20px",
          }}
        />
      );
    });
    return <div style={{ display: "flex", gap: "4px" }}>{stars}</div>;
  }, [averageNurseRating]);

  const totalHours = professionalServedHoursQuery.data?.servedHoursData?.totalHours ?? 0;
  const monthlyHour = professionalServedHoursQuery.data?.servedHoursData?.monthlyHour ?? 0;
  const monthlyTest = professionalServedHoursQuery.data?.servedHoursData?.monthlyTest ?? 0;
  const totalTest = professionalServedHoursQuery.data?.servedHoursData?.totalTest ?? 0;
  const totalSession = professionalServedHoursQuery.data?.servedHoursData?.totalSession ?? 0;
  const highestAvgRatingsValue = reviewDetailsQuery.data?.ratingsAndReviewData?.highestAvgRatingsValue ?? 0;
  const lowestAvgRatingsValue = reviewDetailsQuery.data?.ratingsAndReviewData?.lowestAvgRatingsValue ?? 0;

  return (
    <Spin spinning={isLoading}>
      <Row style={{ rowGap: "1rem" }}>
        {(totalHours > 0 || monthlyHour > 0) && (
          <Col span={24}>
            <Row
              style={{
                width: "100%",
                gap: "16px",
                justifyContent: "flex-start",
                alignItems: "flex-start",
              }}
            >
              {totalHours > 0 && (
                <>
                  <Col>
                    <p style={{ margin: 0 }}>Total served hours:</p>
                  </Col>

                  <Col>
                    <p style={{ margin: 0 }}>
                      <strong>{totalHours} hours</strong>
                    </p>
                  </Col>
                </>
              )}
              {monthlyHour > 0 && (
                <>
                  <Col>
                    <p style={{ margin: 0 }}>Total served hours this month:</p>
                  </Col>
                  <Col>
                    <p style={{ margin: 0 }}>
                      <strong>{monthlyHour} hours</strong>
                    </p>
                  </Col>
                </>
              )}
            </Row>
          </Col>
        )}
        {(monthlyTest > 0 || totalTest > 0) && (
          <Col span={24}>
            <Row
              style={{
                width: "100%",
                gap: "16px",
                justifyContent: "flex-start",
                alignItems: "flex-start",
              }}
            >
              {totalTest > 0 && (
                <>
                  <Col>
                    <p style={{ margin: 0 }}>Total tests:</p>
                  </Col>
                  <Col>
                    <p style={{ margin: 0 }}>
                      <strong>{totalTest} test</strong>
                    </p>
                  </Col>
                </>
              )}
              {monthlyTest > 0 && (
                <>
                  <Col>
                    <p style={{ margin: 0 }}>Total tests this month:</p>
                  </Col>
                  <Col>
                    <p style={{ margin: 0 }}>
                      <strong>{monthlyTest} test</strong>
                    </p>
                  </Col>
                </>
              )}
            </Row>
          </Col>
        )}
        {totalSession > 0 && (
          <Col span={24}>
            <Row
              style={{
                width: "100%",
                gap: "16px",
                justifyContent: "flex-start",
                alignItems: "flex-start",
              }}
            >
              {totalSession > 0 && (
                <>
                  <Col>
                    <p style={{ margin: 0 }}>Total sessions:</p>
                  </Col>
                  <Col>
                    <p style={{ margin: 0 }}>
                      <strong>{totalSession} sessions</strong>
                    </p>
                  </Col>
                </>
              )}
            </Row>
          </Col>
        )}
        <Col span={24}>
          <Row style={{ alignItems: "center", gap: "8px" }}>
            Average rating: (
            {(averageNurseRating || 0).toLocaleString(undefined, {
              maximumFractionDigits: 1,
            })}
            ) {/* <Rate defaultValue={Math.round(averageNurseRating)} allowHalf={false} disabled={true} /> */}
            {ratingStars}
          </Row>
          <Row style={{ gap: "16px" }}>
            {highestAvgRatingsValue > 0 && <div>Max rating: {highestAvgRatingsValue}</div>}
            {lowestAvgRatingsValue > 0 && <div>Min rating: {lowestAvgRatingsValue}</div>}
          </Row>
        </Col>
        <div style={{ display: "flex", flexDirection: "column", gap: "1rem" }}>
          <Box
            sx={{
              display: "flex",
              gap: "1rem",
              "@media (max-width: 700px)": {
                flexDirection: "column",
              },
            }}
          >
            <div>
              Total number of ratings received: {nurseReviewsResponse?.data?.reviewsAndRatings?.numberOfUsersReviewed ?? 0}
            </div>
            <div style={{ display: "flex", gap: "8px" }}>
              {nurseReviewsResponse?.data?.reviewsAndRatings?.ratings?.map((rate: RateItemModel, index: number) => {
                return (
                  <div
                    key={index}
                    style={{
                      border: "1px solid #ffc107",
                      borderRadius: "5px",
                      padding: "0px 8px",
                      color: "#ffc107",
                      textAlign: "center",
                    }}
                  >
                    {rate.engAttribute}: <span style={{ fontWeight: "500" }}>{rate.value}</span>
                  </div>
                );
              })}
            </div>
          </Box>
          <div>Reviews: ({nurseReviewsResponse?.data?.reviewsAndRatings?.reviews?.length ?? 0})</div>
          {nurseReviewsResponse?.data?.reviewsAndRatings?.reviews?.map((review: UserReviewItemModel, index: number) => {
            return (
              <div
                key={index}
                style={{
                  border: "1px solid #ffc107",
                  padding: "8px 8px",
                  borderRadius: "5px",
                }}
              >
                <div style={{ fontWeight: "500" }}>{review.userName}</div>
                <div
                  style={{
                    width: "100%",
                    height: "1px",
                    backgroundColor: "#eee",
                    margin: "4px 0px",
                  }}
                />
                <div>{review.review}</div>
              </div>
            );
          })}
        </div>
      </Row>
    </Spin>
  );
};

interface RateItemModel {
  arbAttribute?: string;
  engAttribute?: string;
  value?: string;
}

interface UserReviewItemModel {
  userName?: string;
  review?: string;
}

export default ReviewsAndRatingsTab;
