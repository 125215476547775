import { Col, FormInstance, Row } from "antd";
import { FC } from "react";
import { AddEditNurseForm } from "./OldNurseProfile";
import NurseServicesFormItem from "./NurseServicesFormItem";
import SPServiceAvailabilityFormItems from "./SPServiceAvailabilityFormItems";

const ServicesAndAvailabilityTab: FC<ServicesAndAvailabilityTabProps> = ({ form }) => {
  return (
    <Row style={{ width: "100%" }}>
      <Col span={24}>
        <NurseServicesFormItem />
      </Col>
      <Col span={24}>
        <SPServiceAvailabilityFormItems form={form} />
      </Col>
    </Row>
  );
};

interface ServicesAndAvailabilityTabProps {
  form: FormInstance<AddEditNurseForm>;
}

export default ServicesAndAvailabilityTab;
