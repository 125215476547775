import { FC } from "react";
import BGCover from "../../assets/images/bg-cover.jpg";

const CoverImage: FC<{ alt: string }> = ({ alt }) => {
  return (
    <div
      style={{
        width: "100%",
        height: "230px",
        // background: "linear-gradient(45deg, #8ae8d0, #8be2da, #8ae8d0)",
        borderBottom: "1px solid #f1f1f1",
        position: "absolute",
        top: "0px",
        left: "0px",
        right: "0px",
      }}
    >
      <img src={BGCover} alt={alt} style={{ width: "100%", height: "100%", objectFit: "cover" }} />
    </div>
  );
};

export default CoverImage;
