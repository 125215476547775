import { Upload } from "antd";
import { FC } from "react";
import { FaFilePdf } from "react-icons/fa";
import { IoAttach } from "react-icons/io5";

const UploadReportDraggerField: FC<UploadReportDraggerFieldProps> = ({ file, onChange }) => {
  return (
    <Upload.Dragger
      accept=".pdf"
      name="files"
      listType="text"
      multiple={false}
      onDrop={(e) => {
        console.log("--- file onDrop", e);
        onChange(e.dataTransfer.files[0]);
      }}
      onChange={(info) => {
        onChange(info.file as unknown as File);
      }}
      iconRender={() => <FaFilePdf size="28" color="#008690" />}
      beforeUpload={() => false}
      style={{
        borderColor: "#008690",
      }}
    >
      <div
        style={{
          height: "140px",
        }}
      >
        <p
          style={{
            textAlign: "center",
            marginBottom: "1rem",
          }}
        >
          Click or drag a file here to upload
        </p>
        {file?.name ? (
          <p
            style={{
              textAlign: "center",
              fontSize: "20px",
              color: "#008690",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              fontWeight: "bold",
            }}
          >
            <IoAttach size={28} />
            {file?.name}
          </p>
        ) : (
          <p
            style={{
              textAlign: "center",
              fontSize: "20px",
              color: "#b8b8b8",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <IoAttach size={28} />
            Upload file here
          </p>
        )}
      </div>
    </Upload.Dragger>
  );
};

interface UploadReportDraggerFieldProps {
  file?: File;
  onChange: (value: File) => void;
}

export default UploadReportDraggerField;
