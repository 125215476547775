import { Paper, Typography } from "@mui/material";
import { FC, useMemo } from "react";
import PharmacyDownloadRevenueExcel from "./PharmacyDownloadRevenueExcel";
import moment from "moment";
import { getStartEndDatesFromSelectedPeriod } from "../OperationAnalytics/HomeNursingPeriodDropdown";
import { FilterPeriodKeysType } from "../Traction";
import TractionHeaderItem from "../Traction/TractionHeaderItem";
import PharmacyTractionDateSelectDropdown from "../Traction/PharmacyTractionDateSelectDropdown";
import { Spin } from "antd";
import usePharmacyTractionTable from "../../hooks/usePharmacyTractionTable";

const PharmacyRevenueTrackerHeader: FC<PharmacyRevenueTrackerHeaderProps> = ({
  selectedPeriod,
  selectedDateRange,
  setSelectedDateRange,
  setSelectedPeriod,
}) => {
  const { query: tractionTableQuery } = usePharmacyTractionTable(selectedPeriod, selectedDateRange);
  // const now = moment();
  // const [openDropdown, setOpenDropdown] = useState<boolean>(false);

  const startAndEndDatesOfSelectedPeriod = useMemo(() => {
    return getStartEndDatesFromSelectedPeriod(selectedPeriod, selectedDateRange);
  }, [selectedDateRange, selectedPeriod]);

  // const { query: headerQuery } = usePharmacyTractionHeader(selectedPeriod, selectedDateRange);
  // const { data } = headerQuery;

  const totalReceivableFromDarDoc = useMemo(() => {
    return tractionTableQuery.data?.table?.map((item) => item.partnerPrice ?? 0).reduce((x, y) => x + y, 0) ?? 0;
  }, [tractionTableQuery.data?.table]);

  const totalInsuranceTransactions = useMemo(() => {
    return (
      tractionTableQuery.data?.table
        ?.filter((item) => item.bookingType !== "CASH")
        .map((item) => item.costPrice ?? 0)
        .reduce((x, y) => x + y, 0) ?? 0
    );
  }, [tractionTableQuery.data?.table]);

  const totalCashTransactions = useMemo(() => {
    return (
      tractionTableQuery.data?.table
        ?.filter((item) => item.bookingType === "CASH")
        .map((item) => item.costPrice ?? 0)
        .reduce((x, y) => x + y, 0) ?? 0
    );
  }, [tractionTableQuery.data?.table]);

  const totalInsurancePayableCommision = useMemo(() => {
    return (
      tractionTableQuery.data?.table
        ?.filter((item) => item.bookingType !== "CASH")
        .map((item) => item.ourPrice ?? 0)
        .reduce((x, y) => x + y, 0) ?? 0
    );
  }, [tractionTableQuery.data?.table]);

  const totalCashPayableCommision = useMemo(() => {
    return (
      tractionTableQuery.data?.table
        ?.filter((item) => item.bookingType === "CASH")
        .map((item) => item.ourPrice ?? 0)
        .reduce((x, y) => x + y, 0) ?? 0
    );
  }, [tractionTableQuery.data?.table]);

  return (
    <Paper
      sx={{ borderRadius: "15px", boxShadow: "#0000001a 0 1px 3px, #0000000f 0 1px 2px", width: "100%" }}
      elevation={0}
      className="traction-header-container"
    >
      <Spin spinning={tractionTableQuery.isLoading}>
        <div
          style={{
            padding: "16px",
            display: "flex",
            justifyContent: "space-between",
            borderBottom: "1px solid rgb(237, 241, 244)",
          }}
        >
          <Typography variant="h4">Revenue Tracker</Typography>
          <div style={{ display: "flex", gap: "16px", alignItems: "centere" }}>
            <PharmacyDownloadRevenueExcel selectedPeriod={selectedPeriod} selectedDateRange={selectedDateRange} />
            <PharmacyTractionDateSelectDropdown
              selectedDateRange={selectedDateRange}
              selectedPeriod={selectedPeriod}
              startAndEndDatesOfSelectedPeriod={startAndEndDatesOfSelectedPeriod}
              setSelectedDateRange={setSelectedDateRange}
              setSelectedPeriod={setSelectedPeriod}
            />
          </div>
        </div>
        <div className="traction-header">
          <TractionHeaderItem
            title={"Total Cash Transactions"}
            subtitle={`AED ${totalCashTransactions.toLocaleString(undefined, { maximumFractionDigits: 2 })}`}
          />
          <TractionHeaderItem
            title={"Total Insurance Transactions"}
            subtitle={`AED ${totalInsuranceTransactions.toLocaleString(undefined, { maximumFractionDigits: 2 })}`}
          />
          <TractionHeaderItem
            title={"Total Receivable From DarDoc"}
            subtitle={`AED ${totalReceivableFromDarDoc.toLocaleString(undefined, { maximumFractionDigits: 2 })}`}
          />
          <TractionHeaderItem
            title={"Total Insurance Payable Commission To DarDoc"}
            subtitle={`AED ${totalInsurancePayableCommision.toLocaleString(undefined, { maximumFractionDigits: 2 })}`}
          />
          <TractionHeaderItem
            title={"Total Cash Payable Commission To DarDoc"}
            subtitle={`AED ${totalCashPayableCommision.toLocaleString(undefined, { maximumFractionDigits: 2 })}`}
          />
          <TractionHeaderItem
            title={"Total Payable Commission To DarDoc"}
            subtitle={`AED ${(totalInsurancePayableCommision + totalCashPayableCommision).toLocaleString(undefined, {
              maximumFractionDigits: 2,
            })}`}
          />
        </div>
      </Spin>
    </Paper>
  );
};

interface PharmacyRevenueTrackerHeaderProps {
  selectedPeriod: FilterPeriodKeysType;
  selectedDateRange?: [moment.Moment, moment.Moment];
  setSelectedDateRange: React.Dispatch<React.SetStateAction<[moment.Moment, moment.Moment] | undefined>>;
  setSelectedPeriod: React.Dispatch<React.SetStateAction<FilterPeriodKeysType>>;
}

export default PharmacyRevenueTrackerHeader;
