import { List, ListItem, ListItemButton, ListItemIcon, ListItemText } from "@mui/material";
import { FC } from "react";
import { NavLink } from "react-router-dom";
import { LayoutSideMenuListItem } from "../../models/LayoutSideMenuListItem";
import LogoutListItemButton from "./LogoutListItemButton";

const SideDrawerList: FC<SideDrawerListProps> = ({
  sideNavListItems,
  backgroundColor = "rgba(255, 255, 255, 0.11)",
  textColor = "white",
}) => {
  return (
    <List
      sx={{
        color: textColor,
        "& .MuiListItemIcon-root": {
          color: textColor,
        },
        "& .MuiTypography-root": {
          color: textColor,
        },
        "& .MuiListItem-root": {
          width: "100%",
        },
        "& .MuiListItem-root a": {
          width: "100%",
          backgroundColor: backgroundColor,
        },
        "& .MuiListItem-root a.active": {
          backgroundColor: "rgba(0, 0, 0, 0.1)",
        },
      }}
    >
      {sideNavListItems.map((item) => {
        return (
          <ListItem key={item.href} disablePadding>
            <NavLink to={item.href} exact={true}>
              <ListItemButton>
                <ListItemIcon>{item.icon}</ListItemIcon>
                <ListItemText primary={item.label} />
              </ListItemButton>
            </NavLink>
          </ListItem>
        );
      })}
      <ListItem disablePadding>
        <LogoutListItemButton />
      </ListItem>
    </List>
  );
};

interface SideDrawerListProps {
  textColor?: string;
  backgroundColor?: string;
  sideNavListItems: LayoutSideMenuListItem[];
}

export default SideDrawerList;
