import React, { useMemo } from "react";
import moment from "moment";
import MultiplePatientsDateCardSelect from "./MultiplePatientsDateCardSelect";
import BookingModel, { BookingPatientModel } from "../../../models/BookingModel";
import { Col, Row } from "antd";
import { BookingDateModel } from "../../../models/BookingModel";
import { StaffMemberModel } from "../../../models/StaffMemberModel";
import { AssignNurseParamsType } from "./NewAssignNurseModal";
import getStartMomentFromDateObj from "../../../utils/getStartMomentFromDateObj";
import useAllPartnerNurses from "../../../hooks/useAllPartnerNurses";
import useValidOrderNurses from "../../../hooks/useValidOrderNurses";

const MultiplePatientsDateCard: React.FC<MultiplePatientsDateCardProps> = ({ date: bookingDate, booking, onNurseSelected }) => {
  const { query } = useAllPartnerNurses();
  const allNurses = useMemo(() => query.data?.data.nurses ?? [], [query.data?.data.nurses]);
  const { query: validNurseQuery } = useValidOrderNurses({ orderUUID: booking.orderUUID });
  const validnurses = useMemo(() => validNurseQuery.data?.data.nurses ?? [], [validNurseQuery.data?.data.nurses]);

  // const [loading, setLoading] = useState<boolean>(false);

  const currentMoment = useMemo(() => moment(), []);

  const bookingDateMoment = useMemo<moment.Moment>(() => {
    return getStartMomentFromDateObj({ date: bookingDate.date, time: bookingDate.time });
  }, [bookingDate]);

  const assignableNursesMap = useMemo<Map<string, boolean>>(() => {
    const map = new Map<string, boolean>();
    for (const nurseID of validnurses ?? []) {
      map.set(nurseID, true);
    }
    return map;
  }, [validnurses]);

  const handleNurseChanged = async (nurseUUID: string, patient: BookingPatientModel) => {
    // setLoading(true);
    // const res = await nurseService.checkDateCanBeDisabled({
    //   nurseUUID: nurseUUID,
    //   newHoliday: booking?.date?.map((item) => item?.date),
    // });
    // setLoading(false);
    const nurse = allNurses?.find((nurse) => nurse.nurseUUID === nurseUUID);
    onNurseSelected({ nurse: nurse!, patient });
  };

  const onPatientNurseSelected = ({ nurseUUID, patient }: { nurseUUID: string; patient: BookingPatientModel }) => {
    handleNurseChanged(nurseUUID, patient);
  };

  const nurseSelectOptions = useMemo<StaffMemberModel[] | undefined>(() => {
    return allNurses?.filter((nurse) => {
      return assignableNursesMap.get(nurse.nurseUUID ?? "");
    });
  }, [allNurses, assignableNursesMap]);

  return (
    <Row
      style={{
        backgroundColor: "white",
        boxShadow: "rgb(0 0 0 / 10%) 0px 2px 5px 0px, rgb(0 0 0 / 12%) 0px 4px 6px 0px",
        padding: "1rem 1rem",
        borderRadius: "7px",

        gap: "0.5rem",
      }}
    >
      <Col span={24}>
        <DateInfoItem name="Date" value={bookingDate.date} />
      </Col>
      <Col span={24}>
        <DateInfoItem name="Start Time" value={bookingDateMoment.format("hh:mm A")} />
      </Col>
      <Col span={24}>
        <DateInfoItem
          name="Duration"
          value={
            <p>
              {bookingDate.numberOfDurationOrSession}
              {bookingDate.numberOfDurationOrSession === 1 ? " HOUR" : " HOURS"}
            </p>
          }
        />
      </Col>
      {booking.patient?.map((patient) => {
        return (
          <Col span={24}>
            <MultiplePatientsDateCardSelect
              bookingDate={bookingDate}
              key={patient._id}
              nurseSelectOptions={nurseSelectOptions ?? []}
              order={booking}
              patient={patient}
              disabled={bookingDateMoment.isBefore(currentMoment)}
              onPatientNurseSelected={onPatientNurseSelected}
            />
          </Col>
        );
      })}
    </Row>
  );
};

const DateInfoItem = ({ name, value, valueBold }: { name: string; value: React.ReactNode; valueBold?: boolean }) => {
  if (!value) return null;
  return (
    <Row
      style={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "flex-start",
        color: "#b8b8b8",
        lineBreak: "anywhere",
        whiteSpace: "pre-wrap",
      }}
    >
      <Col xs={24} sm={9}>
        <p style={{ margin: 0 }}>{name}:</p>
      </Col>
      <Col
        xs={24}
        sm={15}
        style={{
          color: "black",
          textTransform: ["User's Email", "E-mail"].includes(name) ? "none" : "capitalize",
          fontWeight: valueBold ? "bold" : "normal",
        }}
      >
        <p style={{ margin: 0 }}>{value}</p>
      </Col>
    </Row>
  );
};
interface MultiplePatientsDateCardProps {
  date: BookingDateModel;
  booking: BookingModel;
  onNurseSelected: (params: AssignNurseParamsType) => void;
}

export default MultiplePatientsDateCard;
