import { Box, Button } from "@mui/material";
import { FC, useState } from "react";
import { PartnerInventoryRequestModel } from "../../services/inventory";
import UploadDeliveryNoteModal from "./UploadDeliveryNoteModal";
import FileUploadRoundedIcon from "@mui/icons-material/FileUploadRounded";

const UploadDeliveryNoteButton: FC<UploadDeliveryNoteButtonProps> = ({ request }) => {
  const [showModal, setShowModal] = useState(false);

  if (request.status !== "ACCEPTED") return null;

  return (
    <>
      <Button
        variant="contained"
        startIcon={<FileUploadRoundedIcon sx={{ color: "white" }} />}
        sx={{ marginTop: 3, color: "white" }}
        disableElevation={true}
        onClick={(e) => {
          e.stopPropagation();
          setShowModal(true);
        }}
      >
        <Box color={"white"}>Upload Delivery Note & Invoice</Box>
      </Button>
      <UploadDeliveryNoteModal onClose={() => setShowModal(false)} open={showModal} request={request} />
    </>
  );
};

interface UploadDeliveryNoteButtonProps {
  request: PartnerInventoryRequestModel;
}

export default UploadDeliveryNoteButton;
