import { Autocomplete, Box, Button, TextField, Tooltip } from "@mui/material";
import { FC, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import useAllPartnerContactsQuery from "../hooks/useAllPartnerContactsQuery";
import ReduxStateModel from "../models/ReduxStateModel";
import ControlPointOutlinedIcon from "@mui/icons-material/ControlPointOutlined";
import AddPartnerContactModal from "./AddPartnerContactModal";
import PartnerContactModel from "../models/PartnerContactModel";

const AddInventorySelectContact: FC<AddInventorySelectContactProps> = ({ onChange, onContactCreated, value }) => {
  const authReducer = useSelector((state: ReduxStateModel) => state.authReducer?.setting);
  const { query } = useAllPartnerContactsQuery({ partnerUUID: authReducer?.partnerUUID });
  const [showAddContactModal, setShowAddContactModal] = useState(false);

  const strValue = useMemo(() => {
    if (!value) return null;
    return `${value?.name} - ${value?.phoneNumber}`;
  }, [value]);

  return (
    <>
      <Box display={"flex"} gap={2}>
        <Autocomplete
          fullWidth={true}
          size="small"
          //   disablePortal={true}
          multiple={false}
          value={strValue}
          loading={query.isLoading}
          // isOptionEqualToValue={(option, value) => {
          //   return option.name === value.name && value.phoneNumber === value.phoneNumber;
          // }}
          onChange={(_, values) => {
            const value = values;
            if (!value) {
              return onChange(null);
            }
            const [name, phoneNumber] = value.split(" - ");
            const contactFromQuery =
              query.data?.contacts?.find((contact) => {
                return contact.name === name && contact.phoneNumber === phoneNumber;
              }) ?? null;
            onChange(contactFromQuery);
          }}
          // options={[]}
          renderOption={(props, option) => {
            const [name, phoneNumber] = option.split(" - ");
            return (
              <Box {...props} component="li">
                {`${name} (${phoneNumber})`}
              </Box>
            );
          }}
          options={(query.data?.contacts ?? []).map((contact) => {
            return `${contact?.name} - ${contact?.phoneNumber}`;
          })}
          renderInput={(params) => <TextField {...params} label={"Select contact"} size="small" />}
        />
        <Tooltip title="Add contact">
          <Button
            variant="outlined"
            size="small"
            onClick={() => {
              setShowAddContactModal(true);
            }}
          >
            <ControlPointOutlinedIcon />
          </Button>
        </Tooltip>
      </Box>
      <AddPartnerContactModal
        open={showAddContactModal}
        onContactCreated={onContactCreated}
        onClose={function (): void {
          setShowAddContactModal(false);
        }}
      />
    </>
  );
};

interface AddInventorySelectContactProps {
  value: PartnerContactModel | null;
  onContactCreated: (contact: PartnerContactModel) => void;
  onChange: (value: PartnerContactModel | null) => void;
}

export default AddInventorySelectContact;
