import { Box, Button, Dialog, DialogContent, IconButton } from "@mui/material";
import { FC, useCallback, useState } from "react";
import { FaChevronRight, FaChevronLeft } from "react-icons/fa6";
import { Document, Page } from "react-pdf";
import { FaFileDownload } from "react-icons/fa";
import { FaFilePdf } from "react-icons/fa6";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import "react-pdf/dist/Page/AnnotationLayer.css";
import "react-pdf/dist/Page/TextLayer.css";

// pdfjs.GlobalWorkerOptions.workerSrc = new URL("pdfjs-dist/build/pdf.worker.min.js", import.meta.url).toString();

const NurseVerificationDocPDF: FC<NurseVerificationDocPDFProps> = ({
  docURL,
  viewButtonColor,
  downloadButtonText,
  viewButtonText,
  downloadButtonColor,
  showViewButton = true,
}) => {
  const [showModal, setShowModal] = useState(false);
  const [numberOfPages, setNumberOfPages] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);

  const handleNextPageClicked = useCallback(() => {
    if (currentPage >= numberOfPages) return;
    setCurrentPage((value) => (value += 1));
  }, [currentPage, numberOfPages]);

  const handlePreviousPageClicked = useCallback(() => {
    if (currentPage <= 1) return;
    setCurrentPage((value) => (value -= 1));
  }, [currentPage]);

  return (
    <div style={{ display: "flex", width: "100%", height: "100%", justifyContent: "center", alignItems: "center", gap: "8px" }}>
      {showViewButton && (
        <Button
          startIcon={<FaFilePdf color="white" />}
          variant="contained"
          color={viewButtonColor ?? "primary"}
          disableElevation={true}
          onClick={() => {
            setShowModal(true);
          }}
        >
          <Box color={"white"}>{viewButtonText ?? `View PDF`}</Box>
        </Button>
      )}
      <a href={docURL} target="_blank">
        <Button
          startIcon={<FaFileDownload color="white" />}
          variant="contained"
          disableElevation={true}
          color={downloadButtonColor ?? "primary"}
        >
          <Box color={"white"}>{downloadButtonText ?? `Download PDF`}</Box>
        </Button>
      </a>
      <Dialog
        open={showModal}
        onClose={() => setShowModal(false)}
        fullWidth={true}
        maxWidth="lg"
        sx={{ "& .MuiPaper-root": { height: "100%" } }}
      >
        <DialogContent
          sx={{ height: "100%", maxHeight: "100%", display: "flex", justifyContent: "center", position: "relative" }}
        >
          <IconButton onClick={() => setShowModal(false)} sx={{ position: "absolute", top: "10px", right: "10px" }}>
            <CloseRoundedIcon />
          </IconButton>
          <IconButton
            sx={{ position: "absolute", top: "100px", left: "10px" }}
            onClick={handlePreviousPageClicked}
            disabled={currentPage <= 1}
          >
            <FaChevronLeft />
          </IconButton>
          <IconButton
            sx={{ position: "absolute", top: "100px", right: "10px" }}
            onClick={handleNextPageClicked}
            disabled={currentPage >= numberOfPages}
          >
            <FaChevronRight />
          </IconButton>
          <Document
            className={"view-prescription-document"}
            file={docURL}
            onLoadSuccess={(doc) => {
              setNumberOfPages(doc.numPages);
            }}
          >
            <Page pageNumber={currentPage} />
          </Document>
        </DialogContent>
      </Dialog>
    </div>
  );
};

interface NurseVerificationDocPDFProps {
  docURL: string;
  viewButtonText?: string;
  downloadButtonText?: string;
  showViewButton?: boolean;
  viewButtonColor?: "primary" | "secondary" | "success" | "error" | "info" | "warning";
  downloadButtonColor?: "primary" | "secondary" | "success" | "error" | "info" | "warning";
}

export default NurseVerificationDocPDF;
