import { sidebarType } from "../types";

export const sidebarInitState = {
  collapsed: false,
};

export function sidebarReducer(state = sidebarInitState, { type, payload }) {
  if (type === sidebarType.SET_COLLAPSED) {
    return {
      ...state,
      collapsed: payload,
    };
  }
  return state;
}
