import { sortType } from "../types";

export const initialState = {
  sortData: [],
  sortActive: false,
  sortBody: {},
  loader: false,
  pageNumber: 1,
};

export function sortReducer(state = initialState, action) {
  switch (action.type) {
    case sortType.SET_SORT_BODY:
      localStorage.setItem(
        "sortPartner",
        JSON.stringify({
          sortActive: action.sortActive,
          sortBody: action.body,
          pageNumber: action.pageNumber,
        })
      );
      return {
        ...state,
        sortActive: action.sortActive,
        sortBody: action.body,
        pageNumber: action.pageNumber,
      };

    case sortType.REQUEST_SORT:
      return {
        ...state,
        loader: true,
      };
    case sortType.REQUEST_SORT_DONE:
      return {
        ...state,
        loader: false,
      };
    case sortType.ACTIVATE_SORT:
      localStorage.setItem(
        "sortPartner",
        JSON.stringify({
          sortActive: true,
          sortBody: action.data,
          pageNumber: 1,
        })
      );
      return {
        ...state,
        sortActive: true,
        sortBody: action.data,
        sortData: [],
      };

    case sortType.GET_SORTED_ORDERS:
      return {
        ...state,
        sortData: action.data,
        loader: false,
      };

    case sortType.CLEAR_SORT:
      localStorage.setItem(
        "sortPartner",
        JSON.stringify({
          sortBody: {},
          sortActive: false,
          pageNumber: 1,
        })
      );
      return {
        sortData: [],
        sortActive: false,
        sortBody: {},
        loader: false,
        pageNumber: 1,
      };

    case sortType.SORT_PAGE_NUMBER_INCREMENT:
      localStorage.setItem(
        "sortPartner",
        JSON.stringify({
          sortBody: state.sortBody,
          sortActive: state.sortActive,
          pageNumber: action.data,
        })
      );
      return {
        ...state,
        pageNumber: action.data,
      };
    case sortType.SORT_PAGE_NUMBER_DECREMENT:
      localStorage.setItem(
        "sortPartner",
        JSON.stringify({
          sortBody: state.sortBody,
          sortActive: state.sortActive,
          pageNumber: action.data,
        })
      );
      return {
        ...state,
        pageNumber: action.data,
      };
    default: {
      return {
        ...state,
      };
    }
  }
}
