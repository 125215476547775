import { Alert, Col, Modal, Row, Spin } from "antd";
import { FC, useCallback, useMemo, useState } from "react";
import { BsCalendar3 } from "react-icons/bs";
import { StaffMemberModel } from "../../../models/StaffMemberModel";
import { useSelector } from "react-redux";
import BookingModel, {
  AssignedBookingNurseModel,
  BookingDateModel,
  BookingPatientModel,
  NurseBookingDateModel,
} from "../../../models/BookingModel";
import AssignNurseModalTitleRow from "./AssignNurseModalTitleRow";
import AssignNurseModalPatientsList from "./AssignNurseModalPatientsList";
import AssignNurseModalPatientAddress from "./AssignNurseModalPatientAddress";
import AssignNurseModalNextPrevButtons, { bookingDatesPaginationSize } from "./AssignNurseModalNextPrevButtons";
import moment from "moment";
import MultiplePatientsDateCard from "./MultiplePatientsDateCard";
import AssignNurseModalDateCard from "./AssignNurseModalDateCard";
import AssignNurseConfirmationModal from "../../../pages/ShowOrder/AssignNurseConfirmationModal";
import SelectNurseAssignedDatesModal from "./SelectNurseAssignedDatesModal";
import NurseOccupiedDatesResponseModel from "../../../models/NurseOccupiedDatesResponseModel";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { acknowledgePremiumOrder, assignedServices } from "../../../services";
import { showMessage } from "../notification";
import Mixpanel from "../../../utils/Mixpanel";
import ReduxStateModel from "../../../models/ReduxStateModel";
import BaseResponseModel from "../../../models/BaseResponseModel";
import "./style.css";
import getStartMomentFromDateObj from "../../../utils/getStartMomentFromDateObj";

export type AssignNurseParamsType = {
  nurse: StaffMemberModel;
  patient: BookingPatientModel;
  nurseOccupiedDates?: NurseOccupiedDatesResponseModel;
  isSecondShift?: boolean;
};

const NewAssignNurseModal: FC<NewAssignNurseModelProps> = ({ open, order, setOrder, onClose, onAssignNurseSuccess }) => {
  const [datesPage, setDatesPage] = useState<number>(1);
  const [assignNurseForMultiplePatientsBookingParams, setAssignNurseForMultiplePatientsBookingParams] =
    useState<AssignNurseParamsType>();

  const allNurses: StaffMemberModel[] | undefined = useSelector((state: ReduxStateModel) => state.nurseReducer?.nurses);
  const allNursesMap = useMemo<Map<string, StaffMemberModel>>(() => {
    const map = new Map<string, StaffMemberModel>();
    for (const nurse of allNurses ?? []) {
      map.set(nurse.nurseUUID ?? "", nurse);
    }
    return map;
  }, [allNurses]);

  const acknowledgeBookingMutation = useMutation({
    mutationFn: acknowledgePremiumOrder,
  });

  const getOrderDetailsQueryKey = useMemo(() => ["orders/getparticularnurseorder", order?.orderUUID], [order?.orderUUID]);

  const queryClient = useQueryClient();

  const assignNurseMutaiton = useMutation({
    mutationFn: assignedServices.nurseToOrder,
    onSuccess: (res: { data?: BaseResponseModel }) => {
      if (!res?.data?.success) {
        return showMessage("error", "Something went wrong");
      }

      showMessage("success", "Nurse was assigned successfully");
      onClose();
      onAssignNurseSuccess(order);
      queryClient.resetQueries(getOrderDetailsQueryKey);
      Mixpanel.track("Nurse Assigned", order);
    },
    onError: () => {
      showMessage("error", "Something went wrong");
    },
  });

  const bookingFirstDateMoment = useMemo(() => {
    const dateObj = order.date?.[0];
    return getStartMomentFromDateObj({ date: dateObj?.date ?? "", time: dateObj?.time ?? "" });
  }, [order.date]);

  const handleAssignNurseClicked = useCallback(() => {
    if (order.isSubscriptionOrder) {
      acknowledgeBookingMutation.mutate({ orderUUID: order.orderUUID ?? "" });
    }
    const assignedNurses = order?.assignedNurses ?? [];
    for (const nurse of assignedNurses) {
      nurse.dateArray = nurse.dateArray?.filter((nurseDateObj) => {
        let dateMoment: moment.Moment;
        if (nurseDateObj.time === "11:30 - 12:00AM") {
          dateMoment = moment(`${nurseDateObj.date} 11:30PM`, "DD/MM/YYYY hh:mmA");
        } else {
          dateMoment = getStartMomentFromDateObj({ date: nurseDateObj.date, time: nurseDateObj.time ?? "" });
        }
        return dateMoment.isSameOrAfter(bookingFirstDateMoment);
      });
    }
    assignNurseMutaiton.mutate({
      orderUUID: order?.orderUUID,
      assignedNurses: assignedNurses.filter((nurse) => !!nurse.dateArray?.length),
    });
  }, [
    acknowledgeBookingMutation,
    assignNurseMutaiton,
    bookingFirstDateMoment,
    order?.assignedNurses,
    order.isSubscriptionOrder,
    order.orderUUID,
  ]);

  const totalBookingHours = useMemo(() => {
    return order?.date
      ?.map((date) => date.numberOfDurationOrSession)
      .reduce((prevValue, currentValue) => (prevValue ?? 0) + (currentValue ?? 0), 0);
  }, [order]);

  const groupAssignedNursesByDate = useMemo<Map<string, AssignedBookingNurseModel[]>>(() => {
    const assignedNurseDateMap = new Map<string, AssignedBookingNurseModel[]>();
    for (const assignedNurse of order?.assignedNurses ?? []) {
      for (const nurseDate of assignedNurse.dateArray ?? []) {
        if (assignedNurseDateMap.get(nurseDate.date)) {
          assignedNurseDateMap.get(nurseDate.date)?.push(assignedNurse);
        } else {
          assignedNurseDateMap.set(nurseDate.date, [assignedNurse]);
        }
      }
    }
    return assignedNurseDateMap;
  }, [order?.assignedNurses]);

  const bookingDates = useMemo<BookingDateModel[]>(() => {
    const dates = (order?.date ?? []).map<BookingDateModel>((bookingDate) => {
      const assignedNurses = groupAssignedNursesByDate.get(bookingDate.date) ?? [];
      const assignedNursesDetails = assignedNurses.map<StaffMemberModel | undefined>((nurse) =>
        allNursesMap.get(nurse.nurseUUID ?? "")
      );
      let firstShiftNurseName;
      let firstShiftNurseUUID;
      if (assignedNursesDetails && assignedNursesDetails.length) {
        firstShiftNurseName = `${assignedNursesDetails[0]?.firstName ?? ""} ${assignedNursesDetails[0]?.lastName ?? ""}`;
        firstShiftNurseUUID = assignedNursesDetails[0]?.nurseUUID;
      }
      return {
        date: bookingDate.date,
        numberOfDurationOrSession: bookingDate.numberOfDurationOrSession,
        time: bookingDate.time,
        nurseUUID: firstShiftNurseUUID,
        nurseName: firstShiftNurseName,
      };
    });
    dates.sort((value1, value2) => {
      const date1 = moment(value1.date, "DD/MM/YYYY").valueOf();
      const date2 = moment(value2.date, "DD/MM/YYYY").valueOf();
      if (date1 > date2) {
        return 1;
      } else if (date1 < date2) {
        return -1;
      }
      return 0;
    });
    return dates;
  }, [allNursesMap, groupAssignedNursesByDate, order?.date]);

  const handleNurseSelectedBooking = useCallback((params: AssignNurseParamsType) => {
    setAssignNurseForMultiplePatientsBookingParams(params);
  }, []);

  const showSelectDatesModal = useMemo<boolean>(() => {
    const nurseIsFreeOnSelectedDate =
      !assignNurseForMultiplePatientsBookingParams?.nurseOccupiedDates?.dateData ||
      Object.values(assignNurseForMultiplePatientsBookingParams?.nurseOccupiedDates?.dateData ?? {}).every(
        (arr) => !arr || !arr.length
      );
    return !!assignNurseForMultiplePatientsBookingParams && nurseIsFreeOnSelectedDate;
  }, [assignNurseForMultiplePatientsBookingParams]);

  const showNurseOccupiedForSelectedDateWorningModal = useMemo(() => {
    const nurseIsOccupaiedOnSelectedDate =
      !!assignNurseForMultiplePatientsBookingParams?.nurseOccupiedDates?.dateData &&
      Object.values(assignNurseForMultiplePatientsBookingParams?.nurseOccupiedDates?.dateData ?? {}).some((arr) => !!arr?.length);
    return !!assignNurseForMultiplePatientsBookingParams && nurseIsOccupaiedOnSelectedDate;
  }, [assignNurseForMultiplePatientsBookingParams]);

  const bookingDatesMap = useMemo<Map<string, BookingDateModel>>(() => {
    const map = new Map<string, BookingDateModel>();
    for (const date of order?.date ?? []) {
      map.set(date.date, date);
    }
    return map;
  }, [order?.date]);

  const handleDatesSelectedFromDatesModalForSecondShift = useCallback(
    ({ dates }: { dates: string[] }) => {
      const selectedNurse = assignNurseForMultiplePatientsBookingParams?.nurse;
      setOrder((value) => {
        if (!value) return value;
        if (!selectedNurse?.nurseUUID) return value;
        const selectedDatesMap = new Map<string, boolean>();
        for (const date of dates) {
          selectedDatesMap.set(date, true);
        }
        const selectedBookingDates = order?.date?.filter((date) => selectedDatesMap.get(date.date ?? "")) ?? [];
        if (!selectedBookingDates.length) return value;
        const selectedBookingDatesMap = new Map<string, BookingDateModel>();
        for (const bookingDate of selectedBookingDates) {
          selectedBookingDatesMap.set(bookingDate.date ?? "", bookingDate);
        }
        const newValue: BookingModel = { ...value };
        const fullPrevNurseSchedule = newValue.assignedNurses?.find((nurse) => nurse.nurseUUID === selectedNurse?.nurseUUID);
        const firstShiftOldAssignedNurseObjDates: NurseBookingDateModel[] =
          fullPrevNurseSchedule?.dateArray?.filter((date) => {
            const firstShiftPeriod = bookingDatesMap.get(date.date)?.time.slice(13) ?? "AM";
            return date.time?.endsWith(firstShiftPeriod);
          }) ?? [];
        newValue.assignedNurses = newValue.assignedNurses?.filter((nurse) => nurse.nurseUUID !== selectedNurse?.nurseUUID) ?? [];
        const nurseDateArray: NurseBookingDateModel[] = [
          ...firstShiftOldAssignedNurseObjDates,
          ...dates.map<NurseBookingDateModel>((dateStr) => {
            const bookingDateObj = selectedBookingDatesMap.get(dateStr)!;
            const duraiton = bookingDateObj?.numberOfDurationOrSession ?? 0;
            const secondShiftStartTime = getStartMomentFromDateObj({
              date: bookingDateObj.date ?? "",
              time: bookingDateObj.time ?? "",
            }).add(12, "hour");
            return {
              date: dateStr,
              duration: duraiton === 24 ? 12 : duraiton,
              time: `${secondShiftStartTime.format("hh:mm")} - ${secondShiftStartTime
                .clone()
                .add(30, "minute")
                .format("hh:mmA")}`,
              startTimestamp:
                typeof bookingDateObj?.startTimestamp === "string"
                  ? bookingDateObj?.startTimestamp
                  : bookingDateObj?.startTimestamp?.toISOString(),
            };
          }),
        ];
        const selectedPatient = assignNurseForMultiplePatientsBookingParams?.patient._id;
        const newAssignedNurseObj: AssignedBookingNurseModel = {
          nurseUUID: selectedNurse?.nurseUUID,
          patients: selectedPatient ? [selectedPatient] : undefined,
          dateArray: nurseDateArray,
        };
        for (const assignedNurse of newValue.assignedNurses) {
          assignedNurse.dateArray = assignedNurse.dateArray?.filter((date) => {
            const bookingDate = selectedBookingDatesMap.get(date.date);
            const hasSameBookingDate = !!bookingDate;
            const hasSameTime = date.time !== bookingDate?.time;
            const is24HoursBooking = bookingDate?.numberOfDurationOrSession === 24;
            const assignedForSamePatient = assignedNurse.patients?.includes(selectedPatient ?? "");
            if (is24HoursBooking && hasSameBookingDate && hasSameTime && assignedForSamePatient) {
              return false;
            } else if (!is24HoursBooking && hasSameBookingDate && hasSameTime && assignedForSamePatient) {
              return false;
            }
            return true;
          });
        }
        newValue.assignedNurses = newValue.assignedNurses.filter(
          (assignedNurse) => assignedNurse.dateArray && assignedNurse.dateArray.length
        );
        newValue.assignedNurses.push(newAssignedNurseObj);
        return newValue;
      });
      setAssignNurseForMultiplePatientsBookingParams(undefined);
    },
    [
      assignNurseForMultiplePatientsBookingParams?.nurse,
      assignNurseForMultiplePatientsBookingParams?.patient._id,
      bookingDatesMap,
      order?.date,
      setOrder,
    ]
  );

  const handleDatesSelectedFromDatesModal = useCallback(
    ({ dates }: { dates: string[]; selectedNurse: StaffMemberModel }) => {
      if (assignNurseForMultiplePatientsBookingParams?.isSecondShift) {
        return handleDatesSelectedFromDatesModalForSecondShift({
          dates: dates,
        });
      }
      const selectedNurse = assignNurseForMultiplePatientsBookingParams?.nurse;
      setOrder((value) => {
        if (!value) return value;
        if (!selectedNurse?.nurseUUID) return value;
        const selectedDatesMap = new Map<string, boolean>();
        for (const date of dates) {
          selectedDatesMap.set(date, true);
        }
        const selectedBookingDates = value?.date?.filter((date) => selectedDatesMap.get(date.date ?? "")) ?? [];
        if (!selectedBookingDates.length) return value;
        const selectedBookingDatesMap = new Map<string, BookingDateModel>();
        for (const bookingDate of selectedBookingDates) {
          selectedBookingDatesMap.set(bookingDate.date ?? "", bookingDate);
        }
        const newValue: BookingModel = { ...value };
        const prevNurseSchedule = newValue.assignedNurses?.find((nurse) => nurse.nurseUUID === selectedNurse?.nurseUUID);
        newValue.assignedNurses = newValue.assignedNurses?.filter((nurse) => nurse.nurseUUID !== selectedNurse?.nurseUUID) ?? [];
        const selectedPatient = assignNurseForMultiplePatientsBookingParams?.patient._id;
        const newAssignedNurseObj: AssignedBookingNurseModel = {
          nurseUUID: selectedNurse?.nurseUUID,
          patients: selectedPatient ? [selectedPatient] : [],
          dateArray: dates.map<NurseBookingDateModel>((dateStr) => {
            const bookingDateObj = selectedBookingDatesMap.get(dateStr);
            const duration = bookingDateObj?.numberOfDurationOrSession ?? 0;
            const dateFromPrevNurseSchedule = prevNurseSchedule?.dateArray?.find((dateObj) => dateObj.date === dateStr);
            return {
              date: dateStr,
              duration: duration === 24 ? 12 : duration,
              time: dateFromPrevNurseSchedule?.time ?? bookingDateObj?.time ?? "",
              startTimestamp:
                typeof bookingDateObj?.startTimestamp === "string"
                  ? bookingDateObj?.startTimestamp
                  : bookingDateObj?.startTimestamp?.toISOString(),
            };
          }),
        };
        for (const assignedNurse of newValue.assignedNurses) {
          assignedNurse.dateArray = assignedNurse.dateArray?.filter((date) => {
            const bookingDate = selectedBookingDatesMap.get(date.date);
            const is24HoursBooking = bookingDate?.numberOfDurationOrSession === 24;
            const hasSameBookingDate = !!bookingDate;
            const dateFromPrevNurseSchedule = newAssignedNurseObj?.dateArray?.find((dateObj) => dateObj.date === date.date);
            const hasSameTime = date.time === dateFromPrevNurseSchedule?.time; // date.time === bookingDate?.time;
            const assignedForSamePatient = assignedNurse.patients?.includes(selectedPatient ?? "");
            if (is24HoursBooking && hasSameBookingDate && hasSameTime && assignedForSamePatient) {
              return false;
            } else if (!is24HoursBooking && hasSameBookingDate && hasSameTime && assignedForSamePatient) {
              return false;
            }
            return true;
          });
        }
        newValue.assignedNurses = newValue.assignedNurses.filter(
          (assignedNurse) => assignedNurse.dateArray && assignedNurse.dateArray.length
        );
        newValue.assignedNurses.push(newAssignedNurseObj);
        return newValue;
      });
      setAssignNurseForMultiplePatientsBookingParams(undefined);
    },
    [
      assignNurseForMultiplePatientsBookingParams?.isSecondShift,
      assignNurseForMultiplePatientsBookingParams?.nurse,
      assignNurseForMultiplePatientsBookingParams?.patient._id,
      handleDatesSelectedFromDatesModalForSecondShift,
      setOrder,
    ]
  );

  const dateCards = useMemo<JSX.Element[]>(() => {
    if (!order.orderUUID) return [];
    const slicedDates =
      bookingDates.length > bookingDatesPaginationSize
        ? bookingDates.slice((datesPage - 1) * bookingDatesPaginationSize, datesPage * bookingDatesPaginationSize)
        : bookingDates;
    return slicedDates.map<JSX.Element>((date) => {
      const key = `${date.date} ${date.time}`;
      const numberOfPatients = order.patient?.length ?? 0;
      if (numberOfPatients > 1 && order.productType === "S-D") {
        return (
          <Col xs={24} sm={11} md={11} lg={7} key={key}>
            <MultiplePatientsDateCard key={key} booking={order} date={date} onNurseSelected={handleNurseSelectedBooking} />
          </Col>
        );
      }
      return (
        <Col xs={24} sm={11} md={11} lg={11} key={key}>
          <AssignNurseModalDateCard key={key} date={date} booking={order} onNurseSelected={handleNurseSelectedBooking} />
        </Col>
      );
    });
  }, [bookingDates, datesPage, handleNurseSelectedBooking, order]);

  return (
    <>
      <Modal
        open={open}
        onCancel={(e) => {
          e.stopPropagation();
          onClose();
        }}
        footer={null}
        // width={1500}
        className="nurseModal"
        zIndex={900}
        centered={true}
        destroyOnClose={true}
      >
        <Row style={{ width: "100%", margin: 0, rowGap: "1rem" }}>
          <Col span={24}>
            <Spin spinning={assignNurseMutaiton.isLoading}>
              <AssignNurseModalTitleRow order={order} onClose={onClose} handleAssignNurseClicked={handleAssignNurseClicked} />
              <AssignNurseModalPatientsList order={order} />
              <Row
                style={{
                  justifyContent: "space-between",
                  rowGap: "1rem",
                  alignItems: "center",
                }}
              >
                {order?.productType === "S-D" && (
                  <Col span={24}>
                    <p
                      style={{
                        color: "gray",
                        marginBottom: "0.5rem",
                      }}
                    >
                      Schedule
                    </p>
                    <Row
                      style={{
                        alignItems: "center",
                        gap: "1rem",
                      }}
                    >
                      <Col>
                        <BsCalendar3 size="18" color="#008690" />
                      </Col>
                      <Col>
                        <p style={{ margin: 0 }}>
                          {order?.date?.length}
                          {order?.date?.length === 1 ? "Day" : "Days"} - {totalBookingHours}
                          {totalBookingHours === 1 ? "Hour" : "Hours"}
                        </p>
                      </Col>
                    </Row>
                  </Col>
                )}
                {order.isSubscriptionOrder && (
                  <Col span={24}>
                    <Alert
                      style={{ borderRadius: "8px" }}
                      showIcon={true}
                      message="Note: This is a subscription booking. Please assign the same nurse. Failure to do so can lead to penalties. "
                      type="warning"
                    />
                  </Col>
                )}
                <Col span={24}>
                  <Row
                    style={{
                      justifyContent: "flex-start",
                      alignItems: "flex-start",
                      gap: "1rem",
                    }}
                  >
                    {dateCards}
                  </Row>
                </Col>
              </Row>
            </Spin>
          </Col>
          <Col span={24}>
            <AssignNurseModalNextPrevButtons order={order} datesPage={datesPage} setDatesPage={setDatesPage} />
          </Col>
          <Col span={24}>
            <AssignNurseModalPatientAddress order={order} />
          </Col>
        </Row>
      </Modal>
      <AssignNurseConfirmationModal
        nurse={assignNurseForMultiplePatientsBookingParams?.nurse}
        open={showNurseOccupiedForSelectedDateWorningModal}
        assignNurseOccupiedDates={assignNurseForMultiplePatientsBookingParams?.nurseOccupiedDates?.dateData}
        onClose={() =>
          setAssignNurseForMultiplePatientsBookingParams((value) => {
            if (!value) return value;
            const newValue = { ...value, nurseOccupiedDates: undefined };
            return newValue;
          })
        }
        onAssignNurseClicked={() => {
          setAssignNurseForMultiplePatientsBookingParams((value) => {
            if (!value) return value;
            const newValue = { ...value, nurseOccupiedDates: undefined };
            return newValue;
          });
        }}
      />
      <SelectNurseAssignedDatesModal
        patient={assignNurseForMultiplePatientsBookingParams?.patient}
        isSelectingSecondShift={assignNurseForMultiplePatientsBookingParams?.isSecondShift ?? false}
        selectedNurse={assignNurseForMultiplePatientsBookingParams?.nurse}
        bookingModel={order}
        open={showSelectDatesModal}
        onDatesSelected={handleDatesSelectedFromDatesModal}
        onCancel={() => setAssignNurseForMultiplePatientsBookingParams(undefined)}
      />
    </>
  );
};

interface NewAssignNurseModelProps {
  open: boolean;
  order: BookingModel;
  onClose: () => void;
  onAssignNurseSuccess: (booking: BookingModel) => void;
  setOrder: React.Dispatch<React.SetStateAction<BookingModel>>;
}

export default NewAssignNurseModal;
