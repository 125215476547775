import { FC, useMemo } from "react";
import { GeneratedDriverScheduleItem } from "../../services";
import { useDispatch, useSelector } from "react-redux";
import { driverSchedulerTypes } from "../../types";
import AutoNavCellStepItem from "./AutoNavCellStepItem";
import logo from "../../assets/images/logo.jpg";
import { StaffMemberModel } from "../../models/StaffMemberModel";

const driverImageSize = "30px";

const AutoNavCell: FC<AutoNavCellProps> = ({ tasks, staffMembersNameMap }) => {
  const dispatch = useDispatch();

  const isUnAssigned = useMemo<boolean>(() => {
    return tasks.some((task) => task.driverName === "unassigned");
  }, [tasks]);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const selectedCellID: string | undefined = useSelector((state: any) => state?.driverSchedulerReducer?.selectedCellID);

  const cellID = `${tasks[0].driverName}-${tasks[0].time}-${tasks[0].nurseName}`;

  const isSelected = selectedCellID === cellID;

  const handleCellClicked = () => {
    dispatch({ type: driverSchedulerTypes.SET_SELECTED_CELL_ID, payload: cellID });
  };

  const content = (
    <div
      onClick={handleCellClicked}
      style={{
        backgroundColor: "white",
        borderRadius: "10px",
        width: "100%",
        cursor: "pointer",
        // padding: "16px",
        display: "flex",
        flexDirection: "column",
        boxShadow: "rgba(0, 0, 0, 0.1) 0px 0px 5px 0px, rgba(0, 0, 0, 0.1) 0px 0px 1px 0px",
        gap: "17px",
      }}
    >
      {isUnAssigned ? (
        <div style={{ padding: "16px 16px 0px 16px" }}>
          <div
            style={{
              backgroundColor: "red",
              padding: "2px 8px",
              color: "white",
              borderRadius: "5px",
              width: "fit-content",
              fontSize: "10px",
              fontWeight: "500",
            }}
          >
            Unallocated
          </div>
        </div>
      ) : (
        <div style={{ display: "flex", gap: "8px", padding: "16px 16px 0px 16px" }}>
          <img
            src={logo}
            alt="DarDoc"
            style={{
              width: driverImageSize,
              maxHeight: driverImageSize,
              maxWidth: driverImageSize,
              height: driverImageSize,
              objectFit: "cover",
              borderRadius: "50%",
            }}
          />
          <div style={{ display: "flex", flexDirection: "column" }}>
            <div style={{ color: "black", fontWeight: "500" }}>{tasks[0].driverName}</div>
            <div style={{ color: "gray", fontSize: "12px", lineHeight: "0.9", fontWeight: "500" }}>Driver</div>
          </div>
        </div>
      )}
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          gap: "16px",
          padding: "0px 16px 0px 16px",
          marginBottom: "16px",
          transition: "border-color ease-out 0.2s",
          borderLeft: `2px solid ${isSelected ? "green" : "white"}`,
        }}
      >
        {tasks.map((task, index) => {
          return (
            <AutoNavCellStepItem
              key={`${task.driverName}-${task.bookingUUID}-${task.nurseName}-${task.type}`}
              index={index}
              type={task.type}
              time={task.time}
              nurseName={task.nurseName}
              isUnAssigned={isUnAssigned}
              staffMembersNameMap={staffMembersNameMap}
              task={task}
            />
          );
        })}
      </div>
    </div>
  );

  if (isUnAssigned) {
    return (
      <div style={{ backgroundColor: "white", borderRadius: "10px", width: "100%" }}>
        {content}
        <div
          style={{ padding: "8px", fontWeight: "500", fontSize: "12px", display: "flex", flexDirection: "column", gap: "6px" }}
        >
          <div style={{ color: "black" }}>Why is this booking is unallocated ?</div>
          <div>You are out of driver capacity. You have no drivers available at {tasks[0].time}.</div>
          <div>Steps to slove this issue:</div>
          <ul style={{ padding: "0px 12px" }}>
            <li>Increase time travel threshold for nurses</li>
            <li>Increase permissible delay</li>
            <li>Add more drivers</li>
          </ul>
        </div>
      </div>
    );
  }
  return content;
};

interface AutoNavCellProps {
  staffMembersNameMap: Map<string, StaffMemberModel>;
  tasks: GeneratedDriverScheduleItem[];
}

export default AutoNavCell;
