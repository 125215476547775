import { Button, Divider, Modal } from "antd";
import { useHistory, useParams, useRouteMatch } from "react-router-dom";
import { useSelector } from "react-redux";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { useMemo } from "react";
import moment from "moment";
import { bookNurseSlot } from "../../services/nurseTimeSlotService";
import { showMessage } from "../../components/common/notification";
import SchedulerDetailViewResponseModel, { NursesDatum } from "../../models/SchedulerDetailViewResponseModel";
import { ScheduleNurseBookingDateModel } from "../../models/ScheduleBookingForNurseModel";
import { getNurseTimeSlots } from "../../services/nurseTimeSlotService";

const AssignPatientsConfirmationModal = () => {
  const history = useHistory<{
    // endTime?: number;
    durationHours?: number;
    startTime?: number;
    patients?: string;
    selectedDate?: number[];
    caseDescription?: string;
    nurseUUID?: string;
  }>();
  const nurseUUID = useParams<{ nurseUUID: string | undefined }>()?.nurseUUID ?? history.location?.state?.nurseUUID;
  const { url } = useRouteMatch();
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const selectedDetailViewWeek: moment.Moment = useSelector((state) => (state as any)?.schedulerDetailViewReducer?.selectedWeek);
  const selectedExploreViewWeek: moment.Moment = useSelector(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (state) => (state as any)?.schedulerExploreViewReducer?.selectedWeek
  );
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const partnerUUID: string = useSelector((state) => (state as any)?.authReducer?.setting?.partnerUUID);
  const queryClient = useQueryClient();
  const sortedListOfDateValues = useMemo<number[]>(() => {
    const dateValues = [...(history.location?.state?.selectedDate ?? [])];
    dateValues.sort();
    return dateValues;
  }, [history.location?.state?.selectedDate]);

  const queryKey = ["nursetimeslots/getparticularnursedatawithtimeslot", nurseUUID, selectedDetailViewWeek.valueOf()];
  const queryData = queryClient.getQueryData<SchedulerDetailViewResponseModel | null>(queryKey);

  const { data: getNuseDetailsWithSlotsResponse } = useQuery(
    queryKey,
    () =>
      getNurseTimeSlots({
        nurseUUID: nurseUUID ?? "",
        startDate: selectedDetailViewWeek,
        endDate: selectedDetailViewWeek.clone().add(6, "day"),
      }),
    {
      enabled: !queryData?.nurseData && !queryData?.nurseData?.length,
    }
  );

  const nurseData = useMemo<NursesDatum | undefined>(() => {
    return (queryData?.nurseData ?? getNuseDetailsWithSlotsResponse?.nurseData)?.find((item) => item.nurseUUID === nurseUUID);
  }, [queryData, nurseUUID, getNuseDetailsWithSlotsResponse?.nurseData]);

  const mutation = useMutation({
    mutationFn: bookNurseSlot,
    onSuccess: (res) => {
      // console.log("--- mutation bookNurseSlot", res);
      queryClient.invalidateQueries({
        queryKey: ["nursetimeslots/getparticularnursedatawithtimeslot", nurseUUID, selectedDetailViewWeek.valueOf()],
      });
      queryClient.invalidateQueries({
        queryKey: ["nursetimeslots/getallnursedatawithtimeslot", partnerUUID, selectedExploreViewWeek.valueOf()],
      });
      if (res?.success) {
        showMessage("success", "Nurse was assigned successfully");
      }
      // console.log("--- split ", `/${url.split("/")[1]}`);
      const basePath = url.split("/")[1];
      if (basePath === "scheduler-details") {
        history.replace(`/${basePath}/${nurseUUID}`, {});
      } else {
        history.replace(`/${basePath}`, {});
      }
    },
    onError: (error) => {
      console.log("--- mutation onError bookNurseSlot", error);
      showMessage("error", "Something went wrong");
    },
  });
  // console.log("--- nurseUUID", nurseUUID);
  // console.log("--- queryData", queryData);
  // console.log("--- location", history.location);

  const handleConfirmClicked = () => {
    const startMoment = moment(history.location?.state?.startTime);
    // const endMoment = startMoment.clone().add(history.location.state.durationHours, "hour");
    // console.log("--- start end difference", endMoment.diff(startMoment) / 1000 / 60 / 60);
    const dates = history.location?.state?.selectedDate?.map<ScheduleNurseBookingDateModel>((value) => {
      return {
        date: moment(value).format("DD/MM/YYYY"),
        duration: history.location.state.durationHours ?? 0,
        time: `${startMoment.format("hh:mm")} - ${startMoment.clone().add(30, "minute").format("hh:mmA")}`,
        orderDetails: {
          name: history.location?.state?.patients ?? "",
          caseDescription: history.location?.state?.caseDescription,
        },
      };
    });
    mutation.mutate({
      nurseUUID: nurseUUID!,
      dateArray: dates ?? [],
    });
  };

  // if (!nurseUUID || !history.location.state.startTime || !history.location.state.durationHours) {
  //   return <Redirect to={`/${url.split("/")[1]}`} />;
  // }

  const onCloseClicked = () => {
    const baseURL = url.split("/")[1];
    if (baseURL === "scheduler-details") {
      history.replace(`/${baseURL}/${nurseUUID}`);
    } else {
      history.replace(`/${baseURL}`);
    }
  };

  const onBackClicked = () => {
    const baseURL = url.split("/")[1];
    if (baseURL === "scheduler-details") {
      history.replace(`/${baseURL}/${nurseUUID}/assign-start-end-time`, { ...(history.location.state ?? {}) });
    } else {
      history.replace(`/${baseURL}/assign-start-end-time`, { ...(history.location.state ?? {}) });
    }
  };

  return (
    <Modal
      className="scheduler-modal assign-patients-confirmation-modal"
      centered
      title="Summary"
      width="830px"
      open={true}
      maskClosable={false}
      footer={null}
      onCancel={onCloseClicked}
    >
      <div className="modal-container">
        <div className="detail-row">
          <div className="label">Assigning nurse:</div>
          <div className="value">
            <strong>{nurseData?.name}</strong>
          </div>
        </div>
        <div className="detail-row">
          <div className="label">To patient:</div>
          <div className="value">
            <strong>{history.location?.state?.patients}</strong>
          </div>
        </div>
        <div className="detail-row">
          <div className="label">Dates:</div>
          <div className="value">
            <div className="dates">
              {sortedListOfDateValues.map((value) => {
                return (
                  <div key={value} className="date">
                    <strong>{moment(value).format("DD MMMM YYYY")}</strong>
                  </div>
                );
              })}
            </div>
            {/* <strong>{moment(history.location?.state?.selectedDate).format("DD MMMM YYYY")}</strong> */}
          </div>
        </div>
        <div className="detail-row">
          <div className="label">From:</div>
          <div className="value">
            <strong>{moment(history.location?.state?.startTime).format("hh:mm A")}</strong>
          </div>
        </div>
        <div className="detail-row">
          <div className="label">To:</div>
          <div className="value">
            <strong>
              {moment(history.location?.state?.startTime).add(history.location?.state?.durationHours, "hour").format("hh:mm A")}
            </strong>
          </div>
        </div>
      </div>
      <Divider
        style={{
          margin: "0px",
        }}
      />
      <div
        style={{
          paddingLeft: "24px",
          paddingRight: "24px",
          paddingBottom: "12px",
          paddingTop: "12px",
          display: "flex",
          justifyContent: "flex-end",
          alignContent: "center",
          gap: "1rem",
        }}
      >
        <Button onClick={onBackClicked} type="default" className="ant-btn-cancel" loading={mutation.isLoading}>
          Back
        </Button>
        <Button type="primary" onClick={handleConfirmClicked} loading={mutation.isLoading}>
          Confirm
        </Button>
      </div>
    </Modal>
  );
};

export default AssignPatientsConfirmationModal;
