import axios from "axios";
import decrypt from "./decrypt";
import BaseResponseModel from "../models/BaseResponseModel";
import PatientModel from "../models/PatientModel";
import encrypt from "./encrypt";
import { City } from "../models/ReduxStateModel";
import { UserModel } from "../models/UserModel";

export const userServices = {
  getUserIDImage,
};

export interface CheckIfAreaIsServicableResponseData extends BaseResponseModel {
  isServiceable?: number;
  area?: City;
  city?: string;
}

export class CheckIfAreaIsServicableResponseModel {
  data: CheckIfAreaIsServicableResponseData;

  constructor(data: CheckIfAreaIsServicableResponseData) {
    this.data = data;
  }
}

export async function checkIfAreaIsServicable(params: { lat: string; lng: string }) {
  const res = await axios.post<CheckIfAreaIsServicableResponseData>("/api/v1/users/checktheareaisserviceable", {
    data: encrypt(params),
  });
  const data = new CheckIfAreaIsServicableResponseModel(res.data);
  return data;
}

export interface GetPatientDetailsResponseModel extends BaseResponseModel {
  patient?: PatientModel;
}

export async function getPatientDetails(params: { patientID: string }) {
  try {
    const res = await axios.get<string>(`/api/v1/patients/getparticularpatient/${params.patientID}`);
    const data: GetPatientDetailsResponseModel = decrypt(res.data);
    return data;
  } catch (error) {
    console.log("--- getPatientDetails error", error);
    return null;
  }
}

export interface UserAddressModel {
  _id: string;
  userUUID: string;
  address?: string;
  lattitude?: string;
  longitude?: string;
  detail?: string;
  savedName: string;
  isActive: boolean;
  createdAt: Date;
  updatedAt: Date;
  flatOrVillaNumber?: string;
}

export interface GetAddressDetailsResponseModel extends BaseResponseModel {
  address?: UserAddressModel;
}

export async function getAddressDetails(params: { addressID: string }): Promise<GetAddressDetailsResponseModel | null> {
  try {
    const res = await axios.get<string>(`/api/v1/addresses/getparticularaddress/${params.addressID}`);
    const data: GetAddressDetailsResponseModel = decrypt(res.data);
    return data;
  } catch (error) {
    console.log("---  getAddressDetails error", error);
    return null;
  }
}

async function getUserIDImage(userID: string) {
  const data = await axios.get(`api/v1/patients/getimagesforaparticularpatient/${userID}`);
  //return data;
  const res = decrypt(data?.data);
  return { ...data, data: res };
}

export interface GetUserByIDResponseModel extends BaseResponseModel {
  user: UserModel;
}

export async function getUserByID(params: { userUUID: string }) {
  try {
    const res = await axios.get<string>(`/api/v1/users/getbyuserid/${params.userUUID}`);
    const data: GetUserByIDResponseModel = decrypt(res.data);
    return data;
  } catch (e) {
    console.log("--- error", e);
    return null;
  }
}
