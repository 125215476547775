import { LoadingButton } from "@mui/lab";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  TextFieldProps,
  Typography,
} from "@mui/material";
import { MobileDatePicker } from "@mui/x-date-pickers/MobileDatePicker";
import { FC, ReactElement, useCallback, useMemo, useRef, useState } from "react";
import { showMessage } from "../common/notification";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useSelector } from "react-redux";
import { PartnerInventoryRequestModel, uploadDocForDeliveryOrder } from "../../services/inventory";
import ArticleRoundedIcon from "@mui/icons-material/ArticleRounded";
import VisuallyHiddenInput from "../VisuallyHiddenInput";
import convertFileToBase64 from "../../utils/convertFileToBase64";
import UploadFilePayloadModel from "../../models/UploadFilePayloadModel";
import ReduxStateModel from "../../models/ReduxStateModel";
import useDeliveryPartnerOrders from "../../hooks/useDeliveryPartnerOrders";

const UploadDeliveryNoteModal: FC<UploadDeliveryNoteModalProps> = ({ open, request, onClose }) => {
  const [invoiceFile, setInvoiceFile] = useState<File>();
  const [deliveryNote, setDeliveryNote] = useState<File>();
  const [invoiceDate, setInvoiceDate] = useState(new Date());
  const invoiceInputRef = useRef<HTMLInputElement>(null);
  const deliveryInputRef = useRef<HTMLInputElement>(null);
  const authReducer = useSelector((state: ReduxStateModel) => state.authReducer?.setting);
  const queryClient = useQueryClient();

  const { queryKey } = useDeliveryPartnerOrders({ partnerUUID: authReducer?.partnerUUID });

  const handleInvoiceInputChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    if (!e.target.files?.length) {
      return console.log("--- pick controlled prescription no files error");
    }
    setInvoiceFile(e.target.files[0]);
  }, []);

  const handleDeliveryNoteChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    if (!e.target.files?.length) {
      return console.log("--- pick un-controlled prescription no files error");
    }
    setDeliveryNote(e.target.files[0]);
  }, []);

  const uploadInvoiceMutation = useMutation({ mutationFn: uploadDocForDeliveryOrder });

  const uploadDeliveryNoteMutation = useMutation({ mutationFn: uploadDocForDeliveryOrder });

  const onSaveClicked = useCallback(async () => {
    if (!deliveryNote) {
      return showMessage("error", "Please select and upload the delivery note and invoice");
    }
    const [invoiceBase64, deliveryNoteBase64] = await Promise.all([
      invoiceFile ? convertFileToBase64(invoiceFile) : Promise.resolve(null),
      convertFileToBase64(deliveryNote),
    ]);
    // if (!invoiceBase64) {
    //   return showMessage("error", "Something went wrong");
    // }
    if (!deliveryNoteBase64) {
      return showMessage("error", "Something went wrong");
    }
    const invoiceFileType = invoiceFile?.type.toLocaleLowerCase().includes("pdf") ? "pdf" : "image";
    const deliveryNoteFileType = deliveryNote?.type.toLocaleLowerCase().includes("pdf") ? "pdf" : "image";
    const invoiceUploadPayload: UploadFilePayloadModel | null = invoiceBase64
      ? {
          data: invoiceFileType === "pdf" ? invoiceBase64.slice(28) : invoiceBase64,
          fileName: invoiceFile?.name ?? "",
          type: invoiceFile?.type ?? "",
          fileType: invoiceFileType,
        }
      : null;
    const deliveryNoteUploadPayload: UploadFilePayloadModel = {
      data: deliveryNoteFileType === "pdf" ? deliveryNoteBase64.slice(28) : deliveryNoteBase64,
      fileName: deliveryNote?.name ?? "",
      type: deliveryNote?.type ?? "",
      fileType: deliveryNoteFileType,
    };
    const [invoiceRes, deliveryNoteRes] = await Promise.all([
      invoiceUploadPayload
        ? uploadInvoiceMutation.mutateAsync({
            orderUUID: request.orderUUID ?? "",
            document: invoiceUploadPayload,
            type: "INVOICE",
            deliveryNoteInvoiceDate: invoiceDate,
          })
        : null,
      uploadDeliveryNoteMutation.mutateAsync({
        orderUUID: request.orderUUID ?? "",
        document: deliveryNoteUploadPayload,
        type: "DELIVERY_NOTE",
        deliveryNoteInvoiceDate: invoiceDate,
      }),
    ]);
    if (!invoiceRes?.success && invoiceFile) {
      return showMessage("error", "Something went wrong");
    }
    if (deliveryNoteRes?.success) {
      queryClient.resetQueries(queryKey);
      showMessage("success", "Documents uploaded successfully");
      onClose();
    } else {
      showMessage("error", "Something went wrong");
    }
  }, [
    deliveryNote,
    invoiceDate,
    invoiceFile,
    onClose,
    queryClient,
    queryKey,
    request.orderUUID,
    uploadDeliveryNoteMutation,
    uploadInvoiceMutation,
  ]);

  const disableSaveButton = useMemo(() => {
    return !deliveryNote || !invoiceDate;
  }, [deliveryNote, invoiceDate]);

  return (
    <Dialog open={open} onClose={onClose} fullWidth={true} maxWidth="sm">
      <DialogTitle>{"Upload Delivery Note & Invoice"}</DialogTitle>
      <DialogContent>
        <Button
          component="label"
          variant="outlined"
          color="success"
          // startIcon={}
          sx={{ marginTop: "24px", display: "flex", flexDirection: "column", paddingX: 2, paddingY: 2 }}
          fullWidth={true}
        >
          <Typography marginBottom={2} textAlign={"center"}>
            Invoice
          </Typography>
          <Typography
            textAlign={"center"}
            display={"flex"}
            alignItems={"center"}
            justifyContent={"center"}
            gap={1}
            fontWeight={600}
            fontSize={14}
          >
            {invoiceFile ? invoiceFile.name : `Click here to select the invoice`}
            <ArticleRoundedIcon />
          </Typography>
          <VisuallyHiddenInput
            type="file"
            accept=".jpg, .jpeg, .png, .pdf"
            ref={invoiceInputRef}
            onChange={handleInvoiceInputChange}
          />
        </Button>
        <Button
          component="label"
          variant="outlined"
          color="info"
          sx={{ marginTop: "24px", display: "flex", flexDirection: "column", paddingX: 2, paddingY: 2 }}
          fullWidth={true}
        >
          <Typography marginBottom={2} textAlign={"center"}>
            Delivery Note
          </Typography>
          <Typography
            textAlign={"center"}
            display={"flex"}
            alignItems={"center"}
            justifyContent={"center"}
            gap={1}
            fontWeight={600}
            fontSize={14}
          >
            {deliveryNote ? deliveryNote.name : `Click here to select the delivery note`}
            <ArticleRoundedIcon />
          </Typography>
          <VisuallyHiddenInput
            type="file"
            accept=".jpg, .jpeg, .png, .pdf"
            ref={deliveryInputRef}
            onChange={handleDeliveryNoteChange}
          />
        </Button>
        <MobileDatePicker
          label="Invoice Date"
          inputFormat="DD/MM/YYYY"
          onChange={function (value: Date | null): void {
            if (value) {
              setInvoiceDate(value);
            }
          }}
          disableFuture={true}
          value={invoiceDate}
          renderInput={function (props: TextFieldProps): ReactElement {
            return <TextField {...props} sx={{ marginTop: 4, width: "100%" }} />;
          }}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <LoadingButton
          loading={uploadInvoiceMutation.isLoading || uploadDeliveryNoteMutation.isLoading}
          disabled={disableSaveButton}
          variant="contained"
          disableElevation={true}
          type="submit"
          onClick={onSaveClicked}
        >
          <Box color={"white"}>Save</Box>
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

interface UploadDeliveryNoteModalProps {
  open: boolean;
  request: PartnerInventoryRequestModel;
  onClose: () => void;
}

export default UploadDeliveryNoteModal;
