import { useMemo } from "react";
import { PartnerInventoryRequestStatus } from "../services/inventory";

export default function useDeliveryOrderStatusText({ status }: { status: PartnerInventoryRequestStatus }) {
  const statusText = useMemo(() => {
    if (status === "DELIVERY_NOTE_APPROVED") {
      return "Pending Payment";
    } else if (status === "ACCEPTED") {
      return "Pending Delivery";
    }
    return status?.split("_").join(" ");
  }, [status]);

  return { statusText };
}
