import { SlotPatientModel } from "../../models/SchedulerExploreViewResponseModel";
import { FC } from "react";

const BookingPopover: FC<BookingPopoverProps> = ({ patients, time }) => {
  return (
    <div className="booking-popover">
      <div className="content">
        {time && <span>{time}</span>}
        {patients.map((patient) => {
          return (
            <div key={patient.patientUUID ?? patient.name}>
              <div>{patient.name}</div>
              {patient.caseDescription && <div>{patient.caseDescription}</div>}
            </div>
          );
        })}
      </div>
      <div
        className="divider divider-bottom"
        style={{
          marginBottom: "0.7rem",
        }}
      />
    </div>
  );
};

interface BookingPopoverProps {
  patients: SlotPatientModel[];
  time?: string;
}

export default BookingPopover;
