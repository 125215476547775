import { useQuery } from "@tanstack/react-query";
import { getUserByID } from "../services";
import { useMemo } from "react";

export default function useUserByID({ userUUID }: { userUUID?: string }) {
  const queryKey = useMemo(() => ["users/getbyuserid", userUUID], [userUUID]);

  const query = useQuery(queryKey, () => getUserByID({ userUUID: userUUID ?? "" }), {
    enabled: !!userUUID,
  });

  return { query };
}
