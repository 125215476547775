import { Button, Menu, MenuItem } from "@mui/material";
import { FC, useCallback, useState } from "react";
import { FilterPeriodKeysType } from ".";
import moment from "moment";

const PharmacyTractionDateSelectDropdown: FC<PharmacyTractionDateSelectDropdownProps> = ({
  selectedPeriod,
  selectedDateRange,
  startAndEndDatesOfSelectedPeriod,
  setSelectedDateRange,
  setSelectedPeriod,
}) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = useCallback((event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  }, []);

  const handleClose = useCallback(() => {
    setAnchorEl(null);
  }, []);

  const handleSelectPeriodClicked = useCallback(
    (period: FilterPeriodKeysType) => {
      setSelectedDateRange(undefined);
      handleClose();
      setSelectedPeriod(period);
    },
    [handleClose, setSelectedDateRange, setSelectedPeriod]
  );

  // const now = useMemo(() => moment(), []);

  let dropdownContent: JSX.Element;
  if (selectedDateRange) {
    dropdownContent = (
      <div>
        {startAndEndDatesOfSelectedPeriod[0].format("DD MMMM")} - {startAndEndDatesOfSelectedPeriod[1].format("DD MMMM")}
      </div>
    );
  } else if (selectedPeriod === "current-month") {
    dropdownContent = <div>Current month ({startAndEndDatesOfSelectedPeriod[0].format("MMMM")})</div>;
  } else if (selectedPeriod === "previous-month") {
    dropdownContent = <div>Previous month ({startAndEndDatesOfSelectedPeriod[0].format("MMMM")})</div>;
  }
  // else if (selectedPeriod === "last-3-month" || selectedDateRange) {
  //   dropdownContent = (
  //     <div>
  //       Last 3 month ({startAndEndDatesOfSelectedPeriod[0].format("DD MMMM")} -{" "}
  //       {startAndEndDatesOfSelectedPeriod[1].format("DD MMMM")})
  //     </div>
  //   );
  // }
  else {
    dropdownContent = (
      <div>
        {startAndEndDatesOfSelectedPeriod[0].format("DD MMMM")} - {startAndEndDatesOfSelectedPeriod[1].format("DD MMMM")}
      </div>
    );
  }

  return (
    <div>
      <Button
        id="basic-button"
        aria-controls={open ? "basic-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
      >
        {dropdownContent}
      </Button>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        <MenuItem onClick={() => handleSelectPeriodClicked("current-month")}>Current Month</MenuItem>
        <MenuItem onClick={() => handleSelectPeriodClicked("previous-month")}>Previous Month</MenuItem>
        {/* <MenuItem onClick={() => handleSelectPeriodClicked("last-3-month")}>Last 3 Months</MenuItem> */}
      </Menu>
    </div>
  );
};

interface PharmacyTractionDateSelectDropdownProps {
  selectedPeriod: FilterPeriodKeysType;
  selectedDateRange?: [moment.Moment, moment.Moment];
  startAndEndDatesOfSelectedPeriod: [moment.Moment, moment.Moment];
  setSelectedDateRange: React.Dispatch<React.SetStateAction<[moment.Moment, moment.Moment] | undefined>>;
  setSelectedPeriod: React.Dispatch<React.SetStateAction<FilterPeriodKeysType>>;
}

export default PharmacyTractionDateSelectDropdown;
