import { Accordion, AccordionDetails, AccordionSummary, Box, IconButton, useTheme } from "@mui/material";
import { FC } from "react";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import DownloadForOfflineRoundedIcon from "@mui/icons-material/DownloadForOfflineRounded";

const DetailsViewPatientIDImage: FC<DetailsViewPatientIDImageProps> = ({ images, patientName }) => {
  const theme = useTheme();

  return (
    <Accordion
      sx={{
        boxShadow: "none",
        border: `1px solid ${theme.components?.MuiDivider?.defaultProps?.style?.borderColor}`,
        borderLeft: `1px solid ${theme.components?.MuiDivider?.defaultProps?.style?.borderColor}`,
        marginTop: "16px",
        "&.MuiPaper-root": {
          borderRadius: "6px",
          backgroundColor: "rgb(250, 250, 250)",
          borderLeft: `1px solid ${theme.components?.MuiDivider?.defaultProps?.style?.borderColor}`,
        },
        "&.MuiPaper-root::before": {
          display: "none",
        },
        "& .MuiAccordionSummary-root.Mui-expanded": {
          borderBottom: `1px solid ${theme.components?.MuiDivider?.defaultProps?.style?.borderColor}`,
          height: "48px",
          minHeight: "48px",
        },
        "& .MuiAccordionDetails-root": {
          padding: "16px",
        },
      }}
    >
      <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1-content" id="panel1-header">
        Emirates ID / Passport
      </AccordionSummary>
      <AccordionDetails sx={{ display: "flex", flexDirection: "column", gap: "16px" }}>
        {images.map((img) => {
          return (
            <Box
              key={img}
              sx={{
                position: "relative",
                "& .MuiButtonBase-root": {
                  display: "none",
                },
                "&:hover .MuiButtonBase-root": {
                  display: "flex",
                },
              }}
            >
              <img src={img} alt={patientName} key={img} style={{ width: "100%" }} />
              <a href={img} target="_blank">
                <IconButton sx={{ position: "absolute", bottom: "10px", right: "10px", color: "black" }}>
                  <DownloadForOfflineRoundedIcon />
                </IconButton>
              </a>
            </Box>
          );
        })}
      </AccordionDetails>
    </Accordion>
  );
};

interface DetailsViewPatientIDImageProps {
  images: string[];
  patientName: string;
}

export default DetailsViewPatientIDImage;
