import { Form, FormInstance, TimePicker } from "antd";
import { FC } from "react";
import { DriverForm } from "./OldDriverProfile";

const ShiftStartTimeFormItem: FC<ShiftStartTimeFormItemProps> = ({ form }) => {
  const endTime = Form.useWatch("endTime", form);

  return (
    <Form.Item
      name="startTime"
      label="Start time"
      style={{ width: "100%" }}
      rules={[
        {
          required: true,
          message: "Please select driver's shift start time",
        },
      ]}
    >
      <TimePicker
        onChange={() => {
          if (endTime) {
            form.setFieldValue("endTime", undefined);
          }
        }}
        onSelect={(value) => {
          form.setFieldValue("startTime", value);
        }}
        minuteStep={5}
        style={{ width: "100%" }}
        format="hh:mmA"
      />
    </Form.Item>
  );
};

interface ShiftStartTimeFormItemProps {
  form: FormInstance<DriverForm>;
}

export default ShiftStartTimeFormItem;
