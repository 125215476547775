import BookingModel from "../../models/BookingModel";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { FC } from "react";
import { Accordion, AccordionDetails, AccordionSummary, Divider } from "@mui/material";

const OrderMedicationsList: FC<OrderMedicationsListProps> = ({ booking }) => {
  if (!booking?.medications?.length) return null;

  return (
    <Accordion style={{ marginTop: "1rem" }}>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <div style={{ fontWeight: "bold" }}>Selected Medications</div>
      </AccordionSummary>
      <AccordionDetails>
        {booking.medications.map((medicationObj, i) => {
          return (
            <div key={i} style={{ display: "flex", flexDirection: "column", gap: "1rem", paddingBottom: "1rem" }}>
              <Divider style={{ marginTop: "1rem", marginBottom: "1rem" }} textAlign="left">
                {medicationObj.date}
              </Divider>
              {medicationObj.medications?.map((medication) => {
                return <div style={{ fontWeight: "bold" }}>{medication}</div>;
              })}
            </div>
          );
        })}
      </AccordionDetails>
    </Accordion>
  );
};

interface OrderMedicationsListProps {
  booking?: BookingModel;
}

export default OrderMedicationsList;
