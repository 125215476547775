import { Chip } from "@mui/material";
import { FC } from "react";
import BookingModel from "../../models/BookingModel";
import TableCustomizationsList from "../common/BookingsTable/TableCustomizationsList";
import useProductDetails from "../../hooks/useProductDetails";

const BookingTableServiceCellContent: FC<BookingTableServiceCellContentProps> = ({ booking }) => {
  const { query } = useProductDetails({ area: booking.emirate, productUUID: booking.patientId?.[0]?.productUUID });

  return (
    <>
      {booking.isPremiumOrder && (
        <Chip label="Premium" sx={{ color: "white", marginBottom: "8px" }} size="small" color="primary" />
      )}
      {booking.isSubscriptionOrder && (
        <Chip label="Subscription" sx={{ color: "white", marginBottom: "8px" }} size="small" color="primary" />
      )}
      <div
        style={{
          marginBottom: "8px",
          fontWeight: "600",
        }}
      >
        {booking.orderUUID}
      </div>
      <div
        style={{
          fontWeight: "600",
          marginBottom: "8px",
          color: query.data?.product?.isDarLabProduct ? "rgb(0, 134, 144)" : undefined,
        }}
      >
        {booking.patientId?.[0].type}
      </div>
      <TableCustomizationsList customizations={booking.customizations ?? []} />
    </>
  );
};

interface BookingTableServiceCellContentProps {
  booking: BookingModel;
}

export default BookingTableServiceCellContent;
