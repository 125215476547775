import { useEffect, useState } from "react";

function useNotificationsPermission() {
  if (typeof Notification !== "undefined") {
    const [permission, setPermission] =
      useState<typeof Notification.permission>("default");

    useEffect(() => {
      setPermission(Notification.permission);
      const intervalID = setInterval(() => {
        setPermission(Notification.permission);
      }, 5000);
      return () => {
        clearInterval(intervalID);
      };
    }, []);

    return permission;
  }
}

export default useNotificationsPermission;
