import PhoneLockedIcon from "@mui/icons-material/PhoneLocked";
import VideoCallIcon from "@mui/icons-material/VideoCall";
import LoadingButton from "@mui/lab/LoadingButton";
import moment from "moment";
import BookingModel from "../../models/BookingModel";
import { Tooltip } from "@mui/material";
import { FC, useMemo } from "react";
import { useHistory } from "react-router-dom";
import { updateCallStatus } from "../../services";

const StartCallButton: FC<StartCallButtonProps> = ({ booking, startTime, userUUID }) => {
  const history = useHistory();

  const canCall = useMemo(() => {
    if (window.location.origin !== "https://partner.dardoc.com") {
      return true;
    }
    if (!startTime) return false;
    return !(moment().isBefore(startTime?.clone().add(-30, "minutes")) || moment().isAfter(startTime?.clone().add(1, "days")));
  }, [startTime]);

  if (!booking?.isNormalVideoCall) return null;
  if (booking.status === "REPORTED") return null;
  if (booking.status === "ACCEPTED") return null;
  if (booking.status === "CANCELLED") return null;

  return (
    <Tooltip title={canCall ? "Call Patient" : "Call will be active before 30 minutes form session starting time"}>
      <LoadingButton
        fullWidth={true}
        startIcon={
          !canCall ? (
            <PhoneLockedIcon style={{ fontSize: "25px", color: "#a7a7a7" }} />
          ) : (
            <VideoCallIcon style={{ fontSize: "25px", color: "#25bcbd" }} />
          )
        }
        size="large"
        variant="outlined"
        // color="primary"
        sx={{
          color: canCall ? "#25bcbd" : "#a7a7a7",
          borderColor: canCall ? "#25bcbd" : "#a7a7a7",
        }}
        onClick={() => {
          if (canCall) {
            updateCallStatus({ orderUUID: booking.orderUUID ?? "", type: "CALL_STARTED" });
            history.push(`/video-call/${booking.orderUUID}/${userUUID}`);
          }
        }}
      >
        Start Call
      </LoadingButton>
    </Tooltip>
  );
};

interface StartCallButtonProps {
  userUUID?: string;
  booking?: BookingModel;
  startTime?: moment.Moment;
}

export default StartCallButton;
