import moment from "moment";
import SchedulerExploreViewResponseModel, {
  getNursePatientsList,
  NursesDatum,
} from "../../models/SchedulerExploreViewResponseModel";
import NurseTableLeadingCell from "./NurseTableLeadingCell";
import DayViewTableCell from "./DayViewTableCell";
import BookingPopover from "./BookingPopover";
import ReduxStateModel from "../../models/ReduxStateModel";
import { FC, useCallback, useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { Divider, Popover } from "antd";
import { generateRangeArray } from "../../utils/utilFunctions";
import { NurseBookingModel } from "../../models/SchedulerExploreViewResponseModel";
import { useHistory, useRouteMatch } from "react-router-dom";
import { dayViewBookingHeight } from "../../constants";
import useAllPartnerNurses from "../../hooks/useAllPartnerNurses";

const CalendarDayView: FC<CalendarDayViewProps> = ({ data }) => {
  const history = useHistory();
  const { url } = useRouteMatch();
  const day: moment.Moment = useSelector((state: ReduxStateModel) => state?.schedulerExploreViewReducer?.selectedDay ?? moment());
  const searchInputValue: string = useSelector(
    (state: ReduxStateModel) => state?.schedulerExploreViewReducer?.searchInputValue ?? ""
  );
  const [bookingsElements, setBookingsElements] = useState<JSX.Element[]>();

  const bookingsNurseDatesMap = useSelector((state: ReduxStateModel) => state?.schedulerExploreViewReducer?.nurseDayBookings);

  const firstBookingOfDayMoment = useMemo<moment.Moment>(() => {
    let m = day.clone().endOf("day");
    for (const key of Object.keys(bookingsNurseDatesMap ?? {})) {
      const bookings = bookingsNurseDatesMap?.[key]?.filter((booking) => {
        return booking.startMoment.format("DD/MM/YYYY") === day.format("DD/MM/YYYY");
      });
      for (const booking of bookings ?? []) {
        if (booking.startMoment.isBefore(m)) {
          m = booking.startMoment.clone();
        }
      }
    }
    return m;
  }, [bookingsNurseDatesMap, day]);

  const dayHoursArray = useMemo<number[]>(() => {
    const differenceToEODInHours = moment.duration(day.clone().endOf("day").diff(firstBookingOfDayMoment)).asHours();
    if (differenceToEODInHours < 4) {
      return generateRangeArray({ start: 0, end: 23 });
    }
    return generateRangeArray({ start: Math.max(firstBookingOfDayMoment.get("hour") - 1, 0), end: 23 });
  }, [day, firstBookingOfDayMoment]);

  const handleBookingClicked = useCallback(
    (booking: NurseBookingModel) => {
      if (booking.type === "BREAK") {
        return history.replace(`${url}/view-break-time`, {
          booking: JSON.stringify(booking),
          nurseData: data.nursesData?.find((data) => data.nurseUUID === booking.nurseUUID),
        });
      }
      const nurseData = data.nursesData?.find((nurse) => nurse.nurseUUID === booking.nurseUUID);
      history.replace(`/scheduler/booking-details`, {
        booking: {
          orderUUID: booking.orderUUID,
          nurseUUID: booking.nurseUUID,
          startMoment: booking.startMoment.valueOf(),
          endMoment: booking.endMoment.valueOf(),
          patients: booking.patients,
          nurseName: booking.nurseName,
          nurseProfileImg: booking.nurseProfileImg,
          nurseOrderID: booking.nurseOrderID,
          service: booking.service,
          caseDescription: booking.caseDescription,
          nurseData: nurseData,
          locationLat: booking.locationLat,
          locationLng: booking.locationLng,
          address: booking.address,
        },
      });
    },
    [data.nursesData, history, url]
  );

  const handleOffDayClicked = useCallback(
    (booking: NurseBookingModel) => {
      const nurseData = data.nursesData?.find((nurse) => nurse.nurseUUID === booking.nurseUUID);
      if (!nurseData) {
        console.log("--- handleOffDayClicked error");
        return;
      }
      history.push("/scheduler/remove-nurse-on-leave", {
        nurseData,
        patientsList: getNursePatientsList(nurseData),
        initialSelectedDate: day.valueOf(),
      });
    },
    [data.nursesData, day, history]
  );

  const buildBookingElement: (params: {
    container: HTMLElement;
    startCellElement: HTMLElement;
    booking: NurseBookingModel;
    isOffDay: boolean;
  }) => JSX.Element = useCallback(
    ({ startCellElement, booking, container, isOffDay }) => {
      const containerRect = container.getBoundingClientRect();
      const startCellRect = startCellElement.getBoundingClientRect();
      const startCellLeftOffset = startCellRect.width * (booking.startMoment.minute() / 60);
      const topOffset = 10;
      const top = startCellRect.top + topOffset - containerRect.top + container.scrollTop;
      const startLeft = startCellRect.left + startCellLeftOffset - containerRect.left + container.scrollLeft;
      let endElementID;
      const spanTwoDaysBooking = booking.endMoment.isAfter(booking.startMoment.clone().endOf("day"));
      if (spanTwoDaysBooking) {
        endElementID = `day-table-cell-${booking.nurseUUID}_${booking.startMoment
          .clone()
          .set("hour", 23)
          .set("minute", 0)
          .set("second", 0)
          .set("millisecond", 0)
          .format()}`;
      } else {
        endElementID = `day-table-cell-${booking.nurseUUID}_${booking.endMoment
          .clone()
          .set("minute", 0)
          .set("second", 0)
          .set("millisecond", 0)
          .format()}`;
      }
      const endCellElement = document.getElementById(endElementID);
      const endCellRect = endCellElement?.getBoundingClientRect();
      const endRectWidth = endCellRect?.width ?? 0;
      const endCellLeftOffset = spanTwoDaysBooking ? endRectWidth : endRectWidth * (booking.endMoment.minute() / 60);
      const endLeft = (endCellRect?.left ?? 0) + endCellLeftOffset - containerRect.left + container.scrollLeft;
      const width = endLeft - startLeft - 3;
      const popoverTitleText = (booking.patients?.length ?? 0) > 1 ? "PATIENTS:" : "PATIENT:";
      const isBreak = booking.type === "BREAK";
      const timeStr = `${booking.startMoment.format("hh:mm A")} - ${booking.endMoment.format("hh:mm A")}`;
      return (
        <Popover
          key={booking.orderUUID ?? booking.nurseOrderID}
          title={isBreak ? "Break:" : popoverTitleText}
          content={isBreak ? <div>{timeStr}</div> : <BookingPopover patients={booking.patients ?? []} time={timeStr} />}
          placement="bottomLeft"
          trigger={isOffDay ? [] : ["hover"]}
        >
          <div
            key={booking.orderUUID ?? booking.nurseOrderID}
            className={isOffDay || isBreak ? "on-leave-cell-content" : undefined}
            onClick={isOffDay ? () => handleOffDayClicked(booking) : () => handleBookingClicked(booking)}
            style={{
              zIndex: "2",
              position: "absolute",
              width: `${width}px`,
              height: `${dayViewBookingHeight}px`,
              top: `${top}px`,
              left: `${startLeft}px`,
              backgroundColor: isOffDay || isBreak ? undefined : "#25bbbb1a",
              borderLeft: isOffDay || isBreak ? undefined : "2px solid #25bcbd",
              minHeight: "55px",
              // flex: "1",
              padding: "4px 4px",
              fontSize: "0.7rem",
              textAlign: "start",
              display: "flex",
              flexDirection: "column",
              gap: "0.3rem",
              color: isOffDay || isBreak ? undefined : "#515151",
              cursor: "pointer",
              overflow: "hidden",
            }}
          >
            {isOffDay || isBreak ? (
              <div style={{ fontWeight: "600" }}>{isBreak ? "Break" : `On Leave`}</div>
            ) : (
              <>
                <div style={{ fontWeight: "600" }}>{`${booking.startMoment.format(
                  spanTwoDaysBooking ? "DD/MM hh:mm a" : "hh:mm a"
                )} - ${booking.endMoment.format(spanTwoDaysBooking ? "DD/MM hh:mm a" : "hh:mm a")}`}</div>
                <div>
                  {booking.patients && booking.patients.length ? (
                    <div>
                      {booking.patients.length === 1
                        ? booking.patients[0].name
                        : `${booking.patients[0].name} & ${booking.patients.length - 1} ${
                            booking.patients.length - 1 === 1 ? "other" : "others"
                          }`}
                    </div>
                  ) : undefined}
                </div>
              </>
            )}
          </div>
        </Popover>
      );
    },
    [handleBookingClicked, handleOffDayClicked]
  );

  const { query } = useAllPartnerNurses();

  const allNurses = useMemo(() => {
    return query.data?.data.nurses ?? [];
  }, [query.data?.data.nurses]);

  useEffect(() => {
    const dayViewContainerElement = document.getElementById("calendar-day-view");
    if (!dayViewContainerElement) return;
    // const dayViewContainerRect = dayViewContainerElement!.getBoundingClientRect();
    const elements: JSX.Element[] = [];
    const selectedDayStr = firstBookingOfDayMoment.format("DD/MM/YYYY");
    for (const nurse of data.nursesData ?? []) {
      const nurseDetails = allNurses.find((n) => n.nurseUUID === nurse.nurseUUID);
      if (
        nurse.holidays?.includes(selectedDayStr) ||
        !nurseDetails?.activeDays?.includes(firstBookingOfDayMoment.format("dddd").toLocaleLowerCase())
      ) {
        const nurseBookingsForTheDay = bookingsNurseDatesMap?.[`${nurse.nurseUUID}_${day.format()}`] ?? [];
        let offStartMoment: moment.Moment;
        if (nurseBookingsForTheDay.length) {
          offStartMoment = nurseBookingsForTheDay[nurseBookingsForTheDay.length - 1].endMoment;
        } else {
          offStartMoment = firstBookingOfDayMoment
            .clone()
            .set("hour", dayHoursArray[0])
            .set("minute", 0)
            .set("second", 0)
            .set("millisecond", 0);
        }
        const startElementID = `day-table-cell-${nurse.nurseUUID}_${offStartMoment.format()}`;
        const startCellElement = document.getElementById(startElementID);
        if (!startCellElement) continue;
        elements.push(
          buildBookingElement({
            isOffDay: true,
            booking: {
              addressDetails: "",
              startMoment: firstBookingOfDayMoment.clone(),
              endMoment: firstBookingOfDayMoment.clone().endOf("day"),
              nurseUUID: nurse.nurseUUID,
              nurseName: nurse.name,
              nurseProfileImg: nurse.profileImage,
              service: nurse.services?.join(", "),
              type: "BOOKING",
            },
            container: dayViewContainerElement,
            startCellElement: startCellElement,
          })
        );
      }
    }
    for (const nurseIDDayKey of Object.keys(bookingsNurseDatesMap ?? {})) {
      const nurseDayBookings = bookingsNurseDatesMap?.[nurseIDDayKey] ?? [];
      for (const booking of nurseDayBookings) {
        const startElementID = `day-table-cell-${booking.nurseUUID}_${booking.startMoment
          .clone()
          .set("minute", 0)
          .set("second", 0)
          .set("millisecond", 0)
          .format()}`;
        const startCellElement = document.getElementById(startElementID);
        if (!startCellElement) continue;
        elements.push(
          buildBookingElement({
            booking,
            container: dayViewContainerElement,
            startCellElement: startCellElement,
            isOffDay: false,
          })
        );
      }
    }
    setBookingsElements(elements);
  }, [allNurses, bookingsNurseDatesMap, buildBookingElement, data.nursesData, day, dayHoursArray, firstBookingOfDayMoment]);

  const filteredNursesData = useMemo<NursesDatum[] | undefined>(() => {
    if (searchInputValue.trim().length) {
      return data.nursesData?.filter((nurseData) => {
        return nurseData.name?.trim().toLocaleLowerCase().includes(searchInputValue.trim().toLocaleLowerCase());
      });
    }
    return data.nursesData;
  }, [data.nursesData, searchInputValue]);

  return (
    <div className="calendar-day-view dd-table explore-view-table" id="calendar-day-view">
      {bookingsElements}
      <div className="table-body" id="explore-view-table-body day-view-">
        <div className="table-row header">
          <div className="cell table-col-1">Professionals ({data.nursesData?.length})</div>
          {dayHoursArray.map<JSX.Element>((i) => {
            const dayHourMoment = day.clone().add(i, "hour");
            return (
              <div key={dayHourMoment.valueOf()} className="cell">
                {dayHourMoment.format("hh A")}
              </div>
            );
          })}
        </div>
        {filteredNursesData?.map<JSX.Element>((nurseData) => {
          // const nurseBookings = bookingsNurseDatesMap[`${nurseData.nurseUUID}_${day.format()}`] ?? [];
          const minHeight = 85; // Math.max(85, nurseBookings.length * 90);
          const totalHoursAssigned = nurseData.totalHoursAssigned ?? 0;
          const totalHoursServed = nurseData.totalHoursServed ?? 0;
          const key = `${nurseData.nurseUUID}_${day.format()}`;
          return (
            <div
              key={key}
              className="table-row"
              id={`day-nurse-row-${nurseData.nurseUUID}-${day.format()}`}
              style={{ minHeight: `${minHeight}px` }}
            >
              <Popover
                placement="rightTop"
                title={nurseData.name}
                content={
                  <div style={{ maxWidth: "250px", fontSize: "0.7rem" }}>
                    {totalHoursAssigned > 0 && (
                      <div>
                        Total Hours Assigned: <span style={{ fontSize: "0.9rem" }}>{nurseData.totalHoursAssigned}</span>
                      </div>
                    )}
                    {totalHoursServed > 0 && (
                      <div>
                        Total Hours Served: <span style={{ fontSize: "0.9rem" }}>{nurseData.totalHoursServed}</span>
                      </div>
                    )}
                    {(nurseData.services?.length ?? 0) > 0 && totalHoursAssigned > 0 && totalHoursServed > 0 && (
                      <Divider style={{ margin: "8px 0px" }} />
                    )}
                    {nurseData.services && nurseData.services.length && (
                      <>
                        <div>Services</div>
                        <div>{nurseData.services.join(", ")}</div>
                      </>
                    )}
                  </div>
                }
              >
                <div className="cell table-col-1" key={key}>
                  <NurseTableLeadingCell
                    nurseData={nurseData}
                    showWorkingHoursText={false}
                    name={nurseData.name ?? ""}
                    img={nurseData.profileImage ?? ""}
                    workingHours={`${nurseData.startTime ?? ""} - ${nurseData.endTime ?? ""}`}
                  />
                </div>
              </Popover>
              {dayHoursArray.map<JSX.Element>((hour) => {
                const cellMoment = day.clone().add(hour, "hour");
                const key = `day-table-cell-${nurseData.nurseUUID}_${cellMoment.format()}`;
                return <DayViewTableCell key={key} cellMoment={cellMoment} nurseData={nurseData} />;
              })}
            </div>
          );
        })}
      </div>
    </div>
  );
};

interface CalendarDayViewProps {
  // day: moment.Moment;
  data: SchedulerExploreViewResponseModel;
}

export default CalendarDayView;
