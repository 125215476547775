import { FC } from "react";
import { Chip } from "@mui/material";
import { blue, green, grey, orange, purple, teal } from "@mui/material/colors";
import DescriptionOutlinedIcon from "@mui/icons-material/DescriptionOutlined";
import ScienceOutlinedIcon from "@mui/icons-material/ScienceOutlined";
import GradeRoundedIcon from "@mui/icons-material/GradeRounded";
import AssignmentTurnedInRoundedIcon from "@mui/icons-material/AssignmentTurnedInRounded";
import BookingModel from "../../models/BookingModel";
import useProductDetails from "../../hooks/useProductDetails";
import TextSnippetOutlinedIcon from "@mui/icons-material/TextSnippetOutlined";
import PersonAddOutlinedIcon from "@mui/icons-material/PersonAddOutlined";
import CheckCircleOutlineRoundedIcon from "@mui/icons-material/CheckCircleOutlineRounded";
import useCurrentBookingDateObj from "../../hooks/useCurrentBookingDateObj";

const ShowBookingStatus: FC<ShowBookingStatusProps> = ({ booking }) => {
  const { query } = useProductDetails({ area: booking.emirate, productUUID: booking.patientId?.[0].productUUID });
  const { currentAssignedNurse } = useCurrentBookingDateObj({ order: booking });

  const completedChip = (
    <Chip
      label={
        <div style={{ display: "flex", alignItems: "center", gap: "4px" }}>
          <CheckCircleOutlineRoundedIcon fontSize="small" />
          <div>Completed</div>
        </div>
      }
      size="small"
      sx={{
        borderColor: green[400],
        color: green[400],
        fontSize: "12px",
        padding: "0px 0px",
      }}
      variant="outlined"
    />
  );

  if (currentAssignedNurse?.dateObj?.startTimestamp) {
    return (
      <Chip
        label={
          <div style={{ display: "flex", alignItems: "center", gap: "4px" }}>
            <AssignmentTurnedInRoundedIcon fontSize="small" />
            <div>Ongoing</div>
          </div>
        }
        size="small"
        sx={{
          borderColor: green[500],
          color: green[500],
          fontSize: "12px",
          padding: "0px 0px",
        }}
        variant="outlined"
      />
    );
  } else if (booking.status === "DRAFT") {
    return (
      <Chip
        label={
          <div style={{ display: "flex", alignItems: "center", gap: "4px" }}>
            <TextSnippetOutlinedIcon fontSize="small" />
            <div>New</div>
          </div>
        }
        size="small"
        sx={{
          borderColor: grey[400],
          color: grey[400],
          fontSize: "12px",
          padding: "0px 0px",
        }}
        variant="outlined"
      />
    );
  } else if (booking.status === "ACCEPTED") {
    return (
      <Chip
        label={
          <div style={{ display: "flex", alignItems: "center", gap: "4px" }}>
            <GradeRoundedIcon fontSize="small" />
            <div>New</div>
          </div>
        }
        size="small"
        sx={{
          borderColor: orange[400],
          color: orange[400],
          fontSize: "12px",
          padding: "0px 0px",
        }}
        variant="outlined"
      />
    );
  } else if (query.data?.product?.isDarLabProduct && booking.status === "REPORTED") {
    return completedChip;
  } else if (booking.status === "PARTIALLY_REPORTED") {
    return (
      <Chip
        label={
          <div style={{ display: "flex", alignItems: "center", gap: "4px" }}>
            <DescriptionOutlinedIcon fontSize={"small"} />
            <div>Partially Reported</div>
          </div>
        }
        size="small"
        sx={{
          borderColor: blue[400],
          color: blue[400],
          fontSize: "12px",
          padding: "0px 0px",
        }}
        variant="outlined"
      />
    );
  } else if (query.data?.product?.isDarLabProduct && booking.isSampleCollected) {
    return (
      <Chip
        label={
          <div style={{ display: "flex", alignItems: "center", gap: "4px" }}>
            <ScienceOutlinedIcon fontSize="small" />
            <div>Sample Collected</div>
          </div>
        }
        size="small"
        sx={{
          borderColor: purple[400],
          color: purple[400],
          fontSize: "12px",
          padding: "0px 0px",
        }}
        variant="outlined"
      />
    );
  } else if (booking.status === "PROCESSING") {
    return (
      <Chip
        label={
          <div style={{ display: "flex", alignItems: "center", gap: "4px" }}>
            <PersonAddOutlinedIcon fontSize="small" />
            <div>Assigned</div>
          </div>
        }
        size="small"
        sx={{
          borderColor: teal[400],
          color: teal[400],
          fontSize: "12px",
          padding: "0px 0px",
        }}
        variant="outlined"
      />
    );
  } else {
    return completedChip;
  }
};

interface ShowBookingStatusProps {
  booking: BookingModel;
}

export default ShowBookingStatus;
