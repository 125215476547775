import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";
import React, { useCallback, useMemo, useState } from "react";
import { StaffMemberModel } from "../models/StaffMemberModel";
import BookingModel, { AssignStaffDateModel } from "../models/BookingModel";
import MonthCalendar from "./common/BookingsTable/MonthCalendar";
import getStartMomentFromDateObj from "../utils/getStartMomentFromDateObj";
import moment from "moment";

const AssignStaffSelectDatesModal: React.FC<AssignStaffSelectDatesModalProps> = ({
  onClose,
  setDateObjs,
  dateObjs,
  booking,
  isSelectingSecondShift,
  open,
  nurse,
}) => {
  // getStartMomentFromDateObj({ date: dateObjs[selectedDateObjIndex].date, time: dateObjs[selectedDateObjIndex].time })
  //   const selectedDatesRef = useRef<moment.Moment[]>([]);
  const [selectedDates, setSelectedDates] = useState<moment.Moment[]>([]);
  const firstBookingDateMoment = useMemo(() => {
    const firstDateObj = booking.date?.[0];
    return getStartMomentFromDateObj({ date: firstDateObj?.date ?? "", time: firstDateObj?.time ?? "" });
  }, [booking.date]);

  const lastBookingDateMoment = useMemo(() => {
    const lastDateObj = booking.date?.[(booking.date?.length ?? 1) - 1];
    return getStartMomentFromDateObj({ date: lastDateObj?.date ?? "", time: lastDateObj?.time ?? "" });
  }, [booking.date]);

  const enabledDates = useMemo<string[]>(() => {
    return booking?.date?.map<string>((date) => date.date ?? "") ?? [];
  }, [booking?.date]);

  const initialSelectedDates = useMemo(() => {
    return dateObjs
      .filter((dateObj) => {
        if (isSelectingSecondShift) {
          return dateObj.secondShiftNurseUUID === nurse.nurseUUID;
        } else {
          return dateObj.firstShiftNurseUUID === nurse.nurseUUID;
        }
      })
      .map((dateObj) => dateObj.date);
  }, [dateObjs, isSelectingSecondShift, nurse.nurseUUID]);

  const handleAssignSelectedDatesClicked = useCallback(() => {
    const dateStrs = selectedDates.map((m) => m.format("DD/MM/YYYY"));
    if (isSelectingSecondShift) {
      setDateObjs((value) => {
        return value.map((obj) => {
          if (dateStrs.includes(obj.date)) {
            return { ...obj, secondShiftNurseUUID: nurse.nurseUUID } as AssignStaffDateModel;
          }
          return obj;
        });
      });
    } else {
      setDateObjs((value) => {
        return value.map((obj) => {
          if (dateStrs.includes(obj.date)) {
            return { ...obj, firstShiftNurseUUID: nurse.nurseUUID } as AssignStaffDateModel;
          }
          return obj;
        });
      });
    }
    onClose();
  }, [isSelectingSecondShift, nurse.nurseUUID, onClose, selectedDates, setDateObjs]);

  const handleAssignAllDates = useCallback(() => {
    if (isSelectingSecondShift) {
      setDateObjs((value) => {
        return value.map((obj) => {
          return { ...obj, secondShiftNurseUUID: nurse.nurseUUID } as AssignStaffDateModel;
        });
      });
    } else {
      setDateObjs((value) => {
        return value.map((obj) => {
          return { ...obj, firstShiftNurseUUID: nurse.nurseUUID } as AssignStaffDateModel;
        });
      });
    }
    onClose();
  }, [isSelectingSecondShift, nurse.nurseUUID, onClose, setDateObjs]);

  return (
    <Dialog open={open} onClose={onClose} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
      <DialogTitle id="alert-dialog-title">{`Select dates to assign to ${nurse.firstName ?? ""} ${
        nurse.lastName ?? ""
      }`}</DialogTitle>
      <DialogContent>
        <MonthCalendar
          title={<span />}
          mutipleSelect={true}
          firstSelectableMoment={firstBookingDateMoment}
          lastCalendarSelectableMoment={lastBookingDateMoment}
          initialSelectedDates={initialSelectedDates}
          enabledDates={enabledDates}
          width={"100%"}
          onSelectedDatesChanged={(dates) => {
            setSelectedDates(dates);
          }}
        />
      </DialogContent>
      <DialogActions sx={{ display: "flex", justifyContent: "space-between" }}>
        <Button onClick={onClose}>Cancel</Button>
        <Box display={"flex"} gap={2}>
          <Button variant="outlined" color="success" disableElevation={true} onClick={handleAssignAllDates}>
            Assign to all dates
          </Button>
          <Button
            disabled={selectedDates.length < 2}
            variant="outlined"
            disableElevation={true}
            onClick={handleAssignSelectedDatesClicked}
          >
            Assign to selected dates
          </Button>
        </Box>
      </DialogActions>
    </Dialog>
  );
};

interface AssignStaffSelectDatesModalProps {
  open: boolean;
  // selectedDateObjIndex: number;
  dateObjs: AssignStaffDateModel[];
  // selectedTime: string;
  booking: BookingModel;
  nurse: StaffMemberModel;
  // patient: BookingPatientModel;
  isSelectingSecondShift: boolean;
  setDateObjs: React.Dispatch<React.SetStateAction<AssignStaffDateModel[]>>;
  onClose: () => void;
}

export default AssignStaffSelectDatesModal;
