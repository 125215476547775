import moment from "moment";
import TractionHeaderResponseModel from "../models/TractionHeaderResponseModel";
import TractionTableResponseModel from "../models/TractionTableResponseModel";
import axios from "../utils/axiosHelpers";
import decrypt from "./decrypt";
import encrypt from "./encrypt";
import ProfessionalStrenghthResponseModel from "../models/ProfessionalStrenghthResponseModel";
import PartnerCapacityResponseModel from "../models/PartnerCapacityResponseModel";
import PartnerServiceVolumeResponseModel, { ServiceVolumeModel } from "../models/PartnerServiceVolumeResponseModel";
import StaffUtilizationExcelReportModel from "../models/StaffUtilizationExcelReportModel";
import HomeNursingVolumeModel from "../models/HomeNursingVolumeModel";
import HomeNursingExcelReportModel from "../models/HomeNursingExcelReportModel";
import ProfessionalsSPVolumeResponseModel from "../models/ProfessionalsSPVolumeResponseModel";
import BaseResponseModel from "../models/BaseResponseModel";
import PharmacyTractionTableItemModel from "../models/PharmacyTractionTableItemModel";

export interface GetPharmacyTractionHeaderResponseModel extends BaseResponseModel {
  header?: { name?: string; cost?: number }[];
}

export async function getPharmacyTractionHeader(params: {
  partnerUUID: string;
  startDate: string;
  endDate: string;
}): Promise<GetPharmacyTractionHeaderResponseModel | null> {
  try {
    const res = await axios.post<string>("/api/v1/analytics/traction-header-for-pharmacy", { data: encrypt(params) });
    const data: GetPharmacyTractionHeaderResponseModel = decrypt(res.data);
    console.log("--- traction-header-for-pharmacy", data);
    // console.log(params);
    return data;
  } catch (e) {
    console.log("---- getPharmacyTractionHeader error", e);
    return null;
  }
}

export interface GetPharmacyTractionTableResponseModel extends BaseResponseModel {
  table?: PharmacyTractionTableItemModel[];
}

export async function getPharmacyTractionTable(params: {
  partnerUUID: string;
  startDate: string;
  endDate: string;
}): Promise<GetPharmacyTractionTableResponseModel | null> {
  try {
    const res = await axios.post<string>("/api/v1/analytics/traction-table-for-pharmacy", { data: encrypt(params) });
    const data: GetPharmacyTractionTableResponseModel = decrypt(res.data);
    // console.log("--- traction-table-for-pharmacy", data);
    return data;
  } catch (error) {
    console.log("--- getPharmacyTractionTable error", error);
    return null;
  }
}

export async function getSPVolume(params: {
  partnerUUID: string;
  startDate: string;
  endDate: string;
}): Promise<ProfessionalsSPVolumeResponseModel | null> {
  try {
    const res = await axios.post<string>("/api/v1/analytics/spvolumebyprofessionals", { data: encrypt(params) });
    const data: ProfessionalsSPVolumeResponseModel = decrypt(res.data);
    // console.log("--- spvolumebyprofessionals", data);
    // console.log(params);
    return data;
  } catch (error) {
    console.log("--- getSPVolume error", error);
    return null;
  }
}

export async function getHomeNursingExcelReport(params: {
  partnerUUID: string;
  startDate: string;
  endDate: string;
}): Promise<HomeNursingExcelReportModel | null> {
  try {
    const res = await axios.post<string>("/api/v1/analytics/excelforhomenursingutilization", { data: encrypt(params) });
    const data: HomeNursingExcelReportModel = decrypt(res.data);
    // console.log("--- getHomeNursingExcelReport", data);
    return data;
  } catch (error) {
    console.log("--- getHomeNursingExcelReport error", error);
    return null;
  }
}

export async function getHomeNursingVolume(params: {
  partnerUUID: string;
  startDate: string;
  endDate: string;
  nurseUUID: string;
}): Promise<HomeNursingVolumeModel | null> {
  try {
    const res = await axios.post<string>("/api/v1/analytics/homenursingvolume", { data: encrypt(params) });
    const data: HomeNursingVolumeModel = decrypt(res.data);
    // console.log("--- homenursingvolume", data);
    // console.log("--- ", params);
    return data;
  } catch (e) {
    console.log("--- getHomeNursingVolume error", e);
    return null;
  }
}

export interface ServiceVolumeGroupedByTimePeriod {
  [periodName: string]: ServiceVolumeModel;
}

export interface ServiceVolumeResponseModel {
  [service: string]: ServiceVolumeGroupedByTimePeriod;
}

export async function getPartnerServiceVolume(params: {
  partnerUUID: string;
  startDate: string;
  endDate: string;
}): Promise<ServiceVolumeResponseModel | null> {
  try {
    const res = await axios.post<string>(`/api/v1/analytics/servicevolume`, { data: encrypt(params) });
    const data: PartnerServiceVolumeResponseModel = decrypt(res.data);
    // console.log("--- servicevolume", data);
    // console.log(params);
    if (!data?.volumeData) return null;
    const servicesGroupedByMonths: ServiceVolumeResponseModel = {};
    for (const month of Object.keys(data?.volumeData ?? {})) {
      for (const [service, value] of Object.entries(data!.volumeData![month])) {
        const serviceVolumeModel: ServiceVolumeModel = {
          totalHours: value.totalHours ?? 0,
          totalHoursToBeDone: value.totalHoursToBeDone,
          unit: value.unit,
        };
        if (servicesGroupedByMonths[service] && servicesGroupedByMonths[service][month]) {
          if (servicesGroupedByMonths[service][month].totalHours || servicesGroupedByMonths[service][month].totalHours === 0) {
            servicesGroupedByMonths[service][month]!.totalHours! += value.totalHours ?? 0;
            servicesGroupedByMonths[service][month]!.totalHoursToBeDone! += value.totalHoursToBeDone ?? 0;
          } else if (
            servicesGroupedByMonths[service][month].totalHoursToBeDone ||
            servicesGroupedByMonths[service][month].totalHoursToBeDone === 0
          ) {
            servicesGroupedByMonths[service][month]!.totalHours! += value.totalHours ?? 0;
            servicesGroupedByMonths[service][month]!.totalHoursToBeDone! += value.totalHoursToBeDone ?? 0;
          } else {
            servicesGroupedByMonths[service][month] = serviceVolumeModel;
          }
        } else if (servicesGroupedByMonths[service]) {
          servicesGroupedByMonths[service][month] = serviceVolumeModel;
        } else {
          servicesGroupedByMonths[service] = { [month]: serviceVolumeModel };
        }
      }
    }
    const now = moment();
    const startOfCurrentYear = now.clone().startOf("year").format("YYYY-MM-DD");
    const endOfCurrentYear = now.clone().endOf("year").format("YYYY-MM-DD");
    const servicesUnitsMap = new Map<string, string>();
    if (params.startDate === startOfCurrentYear && params.endDate === endOfCurrentYear) {
      const servicesGroupedByYearQuarters: ServiceVolumeResponseModel = {};
      for (const service of Object.keys(servicesGroupedByMonths)) {
        servicesGroupedByYearQuarters[service] = {
          q1: { totalHours: 0, totalHoursToBeDone: 0, unit: undefined },
          q2: { totalHours: 0, totalHoursToBeDone: 0, unit: undefined },
          q3: { totalHours: 0, totalHoursToBeDone: 0, unit: undefined },
          q4: { totalHours: 0, totalHoursToBeDone: 0, unit: undefined },
        };
        for (const [month, value] of Object.entries(servicesGroupedByMonths[service])) {
          if (value.unit) {
            servicesUnitsMap.set(service, value.unit);
          }
          servicesGroupedByYearQuarters[service]["q1"].unit = value.unit ?? servicesUnitsMap.get(service);
          servicesGroupedByYearQuarters[service]["q2"].unit = value.unit ?? servicesUnitsMap.get(service);
          servicesGroupedByYearQuarters[service]["q3"].unit = value.unit ?? servicesUnitsMap.get(service);
          servicesGroupedByYearQuarters[service]["q4"].unit = value.unit ?? servicesUnitsMap.get(service);
          if (month === "January" || month === "February" || month === "March") {
            servicesGroupedByYearQuarters[service]["q1"].totalHours! += value.totalHours ?? 0;
            servicesGroupedByYearQuarters[service]["q1"].totalHoursToBeDone! += value.totalHoursToBeDone ?? 0;
          } else if (month === "April" || month === "May" || month === "June") {
            servicesGroupedByYearQuarters[service]["q2"].totalHours! += value.totalHours ?? 0;
            servicesGroupedByYearQuarters[service]["q2"].totalHoursToBeDone! += value.totalHoursToBeDone ?? 0;
          } else if (month === "July" || month === "August" || month === "September") {
            servicesGroupedByYearQuarters[service]["q3"].totalHours! += value.totalHours ?? 0;
            servicesGroupedByYearQuarters[service]["q3"].totalHoursToBeDone! += value.totalHoursToBeDone ?? 0;
          } else {
            servicesGroupedByYearQuarters[service]["q4"].totalHours! += value.totalHours ?? 0;
            servicesGroupedByYearQuarters[service]["q4"].totalHoursToBeDone! += value.totalHoursToBeDone ?? 0;
          }
        }
      }
      // const q1: MonthServiceVolumes = {};
      // const q2: MonthServiceVolumes = {};
      // const q3: MonthServiceVolumes = {};
      // const q4: MonthServiceVolumes = {};
      // for (const serviceName of Object.keys(dataGroupedByService)) {
      //   for (const timePeriodName of Object.keys(dataGroupedByService[serviceName])) {
      //     if (serviceName === "January" || serviceName === "February" || serviceName === "March") {
      //       if (q1[timePeriodName]?.totalHours || q1[timePeriodName]?.totalHours === 0) {
      //         q1![timePeriodName].totalHours! += data.volumeData[serviceName][timePeriodName].totalHours ?? 0;
      //       } else {
      //         q1![timePeriodName] = { ...data.volumeData[serviceName][timePeriodName] };
      //       }
      //     } else if (serviceName === "April" || serviceName === "May" || serviceName === "June") {
      //       if (q2[timePeriodName]?.totalHours || q2[timePeriodName]?.totalHours === 0) {
      //         q2![timePeriodName].totalHours! += data.volumeData[serviceName][timePeriodName].totalHours ?? 0;
      //       } else {
      //         q2![timePeriodName] = { ...data.volumeData[serviceName][timePeriodName] };
      //       }
      //     } else if (serviceName === "July" || serviceName === "August" || serviceName === "September") {
      //       if (q3[timePeriodName]?.totalHours || q3[timePeriodName]?.totalHours === 0) {
      //         q3![timePeriodName].totalHours! += data.volumeData[serviceName][timePeriodName].totalHours ?? 0;
      //       } else {
      //         q3![timePeriodName] = { ...data.volumeData[serviceName][timePeriodName] };
      //       }
      //     } else {
      //       if (q4[timePeriodName]?.totalHours || q4[timePeriodName]?.totalHours === 0) {
      //         q4![timePeriodName].totalHours! += data.volumeData[serviceName][timePeriodName].totalHours ?? 0;
      //       } else {
      //         q4![timePeriodName] = { ...data.volumeData[serviceName][timePeriodName] };
      //       }
      //     }
      //   }
      // }
      console.log("--- service volume", servicesGroupedByYearQuarters);
      console.log(params);
      return servicesGroupedByYearQuarters;
    }
    console.log("--- service volume", servicesGroupedByMonths);
    console.log(params);
    return servicesGroupedByMonths;
    // return data;
  } catch (e) {
    console.log("--- servicevolume error", e);
    return null;
  }
}

export async function getExcelReportCapacityUtilizationForPartner(params: {
  partnerUUID: string;
  startDate: string;
  endDate: string;
}): Promise<StaffUtilizationExcelReportModel | null> {
  try {
    const res = await axios.post<string>(`/api/v1/analytics/excelforcapacityutilization`, { data: encrypt(params) });
    const data: StaffUtilizationExcelReportModel = decrypt(res.data);
    // console.log("--- getExcelReportCapacityUtilizationForPartner", data);
    return data;
  } catch (e) {
    console.log("--- excelforcapacityutilization error", e);
    return null;
  }
}

export async function getCapacityUtilizationForPartner(params: {
  partnerUUID: string;
  startDate: string;
  endDate: string;
}): Promise<PartnerCapacityResponseModel | null> {
  try {
    const res = await axios.post<string>(`/api/v1/analytics/capacityutilization`, { data: encrypt(params) });
    const data: PartnerCapacityResponseModel = decrypt(res.data);
    // console.log("--- capacityutilization", data);
    // console.log(params);
    return data;
  } catch (e) {
    console.log("--- getCapacityUtilizationForPartner error", e);
    return null;
  }
}

export async function getProfessionalStrenghth(params: {
  partnerUUID: string;
}): Promise<ProfessionalStrenghthResponseModel | null> {
  try {
    const res = await axios.get<string>(`/api/v1/analytics/professionalstrength/${params.partnerUUID}`);
    const data: ProfessionalStrenghthResponseModel = decrypt(res.data);
    // console.log("--- professionalstrength", data);
    // console.log(params);
    return data;
  } catch (e) {
    console.log("--- getTracktionTable error", e);
    return null;
  }
}

export async function getTracktionTable({
  endDate,
  partnerUUID,
  startDate,
}: {
  partnerUUID: string;
  startDate: string;
  endDate: string;
}): Promise<TractionTableResponseModel | null> {
  try {
    const res = await axios.post<string>("/api/v1/analytics/traction-table", {
      data: encrypt({ partnerUUID, startDate, endDate }),
    });
    const decryptedData: TractionTableResponseModel = decrypt(res.data);
    // console.log("--- tractiontable", decryptedData);
    return decryptedData;
  } catch (e) {
    console.log("--- getTracktionTable error", e);
    return null;
  }
}

export async function getTracktionHeader({
  endDate,
  partnerUUID,
  startDate,
}: {
  partnerUUID: string;
  startDate: string;
  endDate: string;
}): Promise<TractionHeaderResponseModel | null> {
  try {
    const res = await axios.post<string>("/api/v1/analytics/traction-header", {
      data: encrypt({ partnerUUID, startDate, endDate }),
    });
    const decryptedData: TractionHeaderResponseModel = decrypt(res.data);
    // console.log({
    //   endDate,
    //   partnerUUID,
    //   startDate,
    // });
    return decryptedData;
  } catch (e) {
    console.log("--- getTracktionHeader error", e);
    return null;
  }
}

export const getPartnerEligibleProducts = async function (partnerUserUUID: string) {
  try {
    const data = await axios.get(`/api/v1/partners/geteligibleproductslist/${partnerUserUUID}`);
    const decryptedData = decrypt(data?.data);
    const res = { ...data, data: decryptedData };
    return res;
  } catch (e) {
    console.log("--- getPartnerEligibleProducts error");
    console.log(e);
    return null;
  }
};

export const getAnalyticsDashboardData = async function ({
  endDate,
  partnerUserUUID,
  productID,
  startDate,
}: {
  partnerUserUUID: string;
  productID: string;
  startDate: moment.Moment;
  endDate: moment.Moment;
}) {
  try {
    const dateFormat = "YYYY-MM-DD";
    const body = {
      partnerUserUUID,
      product: productID,
      startDate: startDate.format(dateFormat),
      endDate: endDate.format(dateFormat),
    };
    const encryptedBody = encrypt(body);
    const data = await axios.post(`/api/v1/analytics/partnerdash`, { data: encryptedBody });
    const res = { ...data, data: decrypt(data?.data) };
    // console.log("--- dash payload", decrypt(data?.data));
    return res;
  } catch (e) {
    console.log("--- getAnalyticsDashboardData error");
    console.log(e);
    return null;
  }
};
