import { createStore, applyMiddleware } from "redux";
import thunkMiddleware from "redux-thunk";
import rootReducer from "./reducer/index";

const reduxMiddleware = [thunkMiddleware];
// if (process.env.NODE_ENV === "development") {
//   reduxMiddleware.push(loggerMiddleware);
// }

const store = createStore(rootReducer, {}, applyMiddleware(...reduxMiddleware));
export default store;
