import axios from "../utils/axiosHelpers";
import decrypt from "./decrypt";
import encrypt from "./encrypt";
import UploadFilePayloadModel from "../models/UploadFilePayloadModel";

interface BaseAPIResponse {
  success?: number | boolean;
  error?: string;
}

export async function sendDriverScheduleNotification(params: {
  partnerUUID: string;
  nurseUUIDs: string[];
}): Promise<BaseAPIResponse | null> {
  try {
    // return { success: 1 };
    const res = await axios.post<string>("/api/v1/driverSchedules/send-driver-schedule-notification-for-drivers-and-nurses", {
      data: encrypt(params),
    });
    const data: BaseAPIResponse = decrypt(res.data);
    return data;
  } catch (e) {
    console.log("--- sendDriverScheduleNotification error", e);
    return null;
  }
}

export async function deleteDriver(params: { driverUUID: string }): Promise<BaseAPIResponse | null> {
  try {
    const res = await axios.post<string>("/api/v1/drivers/deletedriver", { data: encrypt(params) });
    const data: BaseAPIResponse = decrypt(res.data);
    return data;
  } catch (e) {
    console.log("--- deleteDriver error");
    console.log(e);
    return null;
  }
}

export async function createDriver(params: {
  name: string;
  dob: string;
  sex: string;
  email: string;
  startTime: string;
  endTime: string;
  breakStartTime?: string;
  priority?: number;
  breakEndTime?: string;
  mobileNumber: string;
  nationality: string;
  isActive: boolean;
  isDeleted: boolean;
  capacity: number;
  licenseImageUrl: string;
  license?: UploadFilePayloadModel;
  passportImageUrl: string;
  passport?: UploadFilePayloadModel;
  emiratesIdBackImageUrl: string;
  emiratesIdBack?: UploadFilePayloadModel;
  emiratesIdFrontImageUrl: string;
  emiratesIdFront?: UploadFilePayloadModel;
  profileImage?: UploadFilePayloadModel;
  profileImageUrl: string;
  address: string;
  addressLink: string;
  partnerUUID: string;
  activeDays: string[];
}): Promise<BaseAPIResponse | null> {
  try {
    const res = await axios.post("/api/v1/drivers/add", { data: encrypt(params) });
    const decryptedBody: BaseAPIResponse = decrypt(res.data);
    return decryptedBody;
  } catch (e) {
    console.log("--- createDriver error");
    console.log(e);
    return null;
  }
}

export async function editDriver(params: {
  name: string;
  dob: string;
  sex: string;
  email: string;
  startTime: string;
  endTime: string;
  breakStartTime?: string;
  breakEndTime?: string;
  mobileNumber: string;
  nationality: string;
  isActive: boolean;
  isDeleted: boolean;
  priority?: number;
  driverUUID: string;
  capacity: number;
  holidays?: string[];
  licenseImageUrl: string;
  license?: UploadFilePayloadModel;
  passportImageUrl: string;
  passport?: UploadFilePayloadModel;
  emiratesIdBackImageUrl: string;
  emiratesIdBack?: UploadFilePayloadModel;
  emiratesIdFrontImageUrl: string;
  emiratesIdFront?: UploadFilePayloadModel;
  profileImage?: UploadFilePayloadModel;
  profileImageUrl: string;
  address: string;
  addressLink: string;
  partnerUUID: string;
  activeDays: string[];
}): Promise<BaseAPIResponse | null> {
  try {
    // console.log("--- params", params);
    // return null;
    const res = await axios.post("/api/v1/drivers/edit", { data: encrypt({ ...params, type: "DRIVER" }) });
    const decryptedBody: BaseAPIResponse = decrypt(res.data);
    return decryptedBody;
  } catch (e) {
    console.log("--- editDriver error");
    console.log(e);
    return null;
  }
}
