import { FC } from "react";
import { FaFileExcel } from "react-icons/fa";
import { saveAs as fileServerSaveAs } from "file-saver";
import * as XLSX from "xlsx";
import { Button, Tooltip } from "antd";
import useTractionTableQuery from "../../hooks/useTractionTableQuery";
import { FilterPeriodKeysType } from ".";
import { excelFileType } from "../../constants";
import useTractionHeadersQuery from "../../hooks/useTractionHeadersQuery";
import moment from "moment";

const DownloadTractionExcelButton: FC<DownloadTractionExcelButtonProps> = ({ selectedPeriod, selectedDateRange }) => {
  const { data: tractionTableData } = useTractionTableQuery(selectedPeriod, selectedDateRange);
  const { queryKey: headerQueryKey, query: headerQuery } = useTractionHeadersQuery(selectedPeriod, selectedDateRange);
  const { data: tractionHeaderData } = headerQuery;

  const handleDownloadExcel = () => {
    const excelFileData =
      tractionTableData?.bookings?.map<(string | number | string[])[]>((row) => {
        const totalCustomizationsPrice =
          row.customizations?.map((customization) => customization.price ?? 0).reduce((x, y) => x + y, 0) ?? 0;
        const penaltiesAmount = row.penalties?.map((penalty) => penalty.amount ?? 0).reduce((x, y) => x + y, 0) ?? 0;
        const customizationsNames =
          row.customizations?.map<string>((customization) => {
            return `${customization.engName} - AED ${customization.price}`;
          }) ?? [];
        const values = [
          row.orderUUID ?? "",
          row.date ?? "",
          row.service ?? "",
          row.PatientName?.join(", ") ?? "",
          row.unitType ?? "HOUR",
          row.recievedQuantity ?? 0,
          row.servedQuantity ?? 0,
          row.assignedProfessionals?.join(", ") ?? "",
          row.basePrice ?? 0,
          (row.finalPrice ?? 0) - totalCustomizationsPrice + penaltiesAmount,
          customizationsNames.join(", "),
          penaltiesAmount ? `AED ${penaltiesAmount}` : "-",
          row.finalPrice ?? 0,
        ];
        if (row.price || row.price === 0) {
          values.push(row.price ?? 0);
        }
        return values;
      }) ?? [];
    const columnNames = [
      "Booking ID",
      "Date",
      "Service Type",
      "Patient",
      "Unit",
      "Total",
      "Served",
      "Professional",
      "Unit Price",
      "Total Served",
      "Ad-On",
      "Penalty Charges",
      "Total AED",
    ];
    // if (tractionTableData?.bookings?.[0].price) {
    //   columnNames.push("Total Price (AED)");
    // }
    excelFileData.unshift(columnNames);
    const totalServedAmountHeaderItem = tractionHeaderData?.data?.find((item) => item.name === "Total Amount Served");
    excelFileData.unshift(
      ["Total Amount Served"],
      [
        `AED ${
          totalServedAmountHeaderItem?.price?.toLocaleString(undefined, {
            maximumFractionDigits: 2,
          }) ?? ""
        }`,
      ],
      []
    );
    const ws = XLSX.utils.json_to_sheet(excelFileData);
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], { type: excelFileType });
    fileServerSaveAs(
      data,
      `dardoc-revenue-tracker-${moment(headerQueryKey[2]).format("DD/MM/YYYY")}-${moment(headerQueryKey[3]).format(
        "DD/MM/YYYY"
      )}.xlsx`
    );
  };

  return (
    <Tooltip title="Download as Excel">
      <Button
        onClick={handleDownloadExcel}
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          border: "none",
          boxShadow: "rgba(187, 187, 187, 0.48) 6px 2px 16px 0px, rgba(255, 255, 255, 0.8) -6px -2px 16px 0px",
        }}
        icon={<FaFileExcel style={{ color: "#04723a" }} />}
        shape="circle"
        type="ghost"
      />
    </Tooltip>
  );
};

interface DownloadTractionExcelButtonProps {
  selectedPeriod: FilterPeriodKeysType;
  selectedDateRange?: [moment.Moment, moment.Moment];
}

export default DownloadTractionExcelButton;
