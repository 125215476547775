import { Form, Select } from "antd";
import { FC } from "react";

const DriverActiveDaysFormItem: FC<DriverActiveDaysFormItemProps> = () => {
  return (
    <Form.Item
      className="driver-active-days-form-item"
      name="activeDays"
      label="Active days"
      rules={[
        {
          required: true,
          message: "Please select the driver active days",
        },
      ]}
    >
      <Select
        mode="multiple"
        allowClear={true}
        style={{ width: "100%" }}
        placeholder="Select the driver's active days"
        options={[
          {
            label: "Monday",
            value: "Monday",
          },
          {
            label: "Tuesday",
            value: "Tuesday",
          },
          {
            label: "Wednesday",
            value: "Wednesday",
          },
          {
            label: "Thursday",
            value: "Thursday",
          },
          {
            label: "Friday",
            value: "Friday",
          },
          {
            label: "Saturday",
            value: "Saturday",
          },
          {
            label: "Sunday",
            value: "Sunday",
          },
        ]}
      />
    </Form.Item>
  );
};

interface DriverActiveDaysFormItemProps {}

export default DriverActiveDaysFormItem;
