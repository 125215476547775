import { FC, useState } from "react";
import { driverSchedulePageBackgroundColor } from "../../constants";
import { Alert, Box, Typography } from "@mui/material";
import { grey } from "@mui/material/colors";
import { Link } from "react-router-dom";
import TabsView from "../../components/TabsView";
import AcceptedBookings from "../AcceptedBookings";
import usePartnerOrders from "../../hooks/usePartnerOrders";
import BasePageLayout from "../../components/BasePageLayout";
import BasePageLayoutSideMenuItems, { deliveryPartnerMenuItems } from "../../components/BasePageLayoutSideMenuItems";
import NewBookingsTabBadge from "./NewBookingsTabBadge";
import useAllPartnerNurses from "../../hooks/useAllPartnerNurses";
import useCurrentPartnerDetails from "../../hooks/useCurrentPartnerDetails";
import DeliveryPartnerOrdersList from "../../components/DeliveryPartnerOrdersList";

const AllBookings: FC = () => {
  const [currentTabIndex, setCurrentTabIndex] = useState(1);

  const { query: partnerOrdersQuery } = usePartnerOrders({
    status: "ACCEPTED",
    loadMoreElementInView: false,
    enabled: true,
    // onSuccess: (res) => {
    //   if (res.pages.length && res.pages[0]?.orders?.length === 0) {
    //     setCurrentTabIndex(1);
    //   }
    // },
  });

  const { someNurseHasMessingData } = useAllPartnerNurses();

  const acceptedOrdersCount = partnerOrdersQuery.data?.pages?.[0]?.orders?.length ?? 0;

  const { isDeliveryPartner } = useCurrentPartnerDetails();

  return (
    <BasePageLayout
      backgroundColor="white"
      textColor={grey[900]}
      mainContent={
        isDeliveryPartner ? (
          <DeliveryPartnerOrdersList />
        ) : (
          <div
            className="all-bookings"
            style={{
              display: "flex",
              height: "100%",
              maxHeight: "100%",
              backgroundColor: driverSchedulePageBackgroundColor,
            }}
          >
            <Box
              sx={{
                width: "100%",
                maxWidth: "100%",
                padding: "16px",
                maxHeight: "100%",
                display: "flex",
                flexDirection: "column",
                "@media (max-width: 700px)": {
                  padding: "0px",
                },
              }}
            >
              {someNurseHasMessingData ? (
                <Alert
                  color="error"
                  sx={{
                    "& a": {
                      color: "black",
                    },
                  }}
                >
                  <Link to={"/team"}>
                    Please update your staff's mandatory profile details if you wish to receive further bookings!
                  </Link>
                </Alert>
              ) : (
                <Typography sx={{ marginBottom: "12px", "@media (max-width: 700px)": { display: "none" } }} variant="h5">
                  All Bookings
                </Typography>
              )}
              <TabsView
                value={currentTabIndex}
                setValue={setCurrentTabIndex}
                style={{
                  backgroundColor: "white",
                  padding: "8px",
                  borderRadius: "4px",
                  // height: "calc(100% - 50px)",
                  display: "flex",
                  flexDirection: "column",
                  // "@media (max-width: 700px)": {
                  //   height: "calc(100% - 10px)",
                  // },
                }}
                tabs={[
                  {
                    key: "item-one",
                    label: <NewBookingsTabBadge count={acceptedOrdersCount} />,
                    tab: <AcceptedBookings status={"ACCEPTED"} isCurrentTab={true} />,
                  },
                  {
                    key: "item-two",
                    label: <span style={{ color: "black" }}>Assigned</span>,
                    tab: <AcceptedBookings status={"PROCESSING"} isCurrentTab={true} />,
                  },
                  {
                    key: "item-three",
                    label: <span style={{ color: "black" }}>Completed</span>,
                    tab: <AcceptedBookings status={"REPORTED"} isCurrentTab={currentTabIndex === 2} />,
                  },
                ]}
              />
            </Box>
          </div>
        )
      }
      sideMenuItems={isDeliveryPartner ? deliveryPartnerMenuItems : BasePageLayoutSideMenuItems}
    />
  );
};

export default AllBookings;
