import {
  Dialog,
  DialogTitle,
  DialogContent,
  List,
  ListItemText,
  ListItemButton,
  ListItem,
  Checkbox,
  Button,
  Typography,
} from "@mui/material";
import { FC, useCallback, useState } from "react";
import { Redirect, useHistory, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { ConsultationCustomizationModel } from "../../models/GetProductsForConsultationResponseModel";
import { ConsultationRecommendationsType } from "../../types";
import { ConsultationRecommendationsInitialState } from "../../reducer/consultation.recommendations.reducer";
import VaccinesRoundedIcon from "@mui/icons-material/VaccinesRounded";
import ReduxStateModel from "../../models/ReduxStateModel";
import Mixpanel from "../../utils/Mixpanel";
import LoadingSpinner from "../../components/LoadingSpinner";

const SelectIVDripCustomizationsModal: FC<SelectIVDripCustomizationsModalProps> = ({ loading }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { orderUUID } = useParams<{ orderUUID: string }>();
  const [selectedAddons, setSelectedAddons] = useState<ConsultationCustomizationModel[]>([]);
  const consultationRecommendationsReducer = useSelector((state: ReduxStateModel) => state.consultationRecommendationsReducer);

  const handleClose = useCallback(() => {
    history.push(`/order/${orderUUID}`);
  }, [history, orderUUID]);

  const handleNextStep = useCallback(() => {
    if (consultationRecommendationsReducer.selectedLabTests === "SKIP") {
      // TODO: handle recommending selected iv drip
      history.push(`/order/${orderUUID}/suggestions/confirm`);
    } else if (consultationRecommendationsReducer.selectedLabTests === undefined) {
      // go to recommend lab tests
      history.push(`/order/${orderUUID}/suggestions/lab-tests`);
    } else {
      // consultationRecommendationsReducer.selectedLabTests = [object, object]
      // consultationRecommendationsReducer.selectedBuildYourLabTestItems = [object, object]
      // TODO: handle recommending selected iv drip with customizations and lab tests
      history.push(`/order/${orderUUID}/suggestions/confirm`);
    }
  }, [consultationRecommendationsReducer.selectedLabTests, history, orderUUID]);

  const handleContinueClicked = useCallback(() => {
    const payload: Partial<ConsultationRecommendationsInitialState> = {
      selectedCustomizations: selectedAddons,
    };
    dispatch({ type: ConsultationRecommendationsType.SET_CONSULTATION_RECOMMENDATIONS, payload: payload });
    Mixpanel.track("SelectIVDripCustomizationsModal skip clicked", {
      ...payload,
      partnerUser: localStorage.getItem("userName"),
      partnerUserUUID: localStorage.getItem("partnerUserUUID"),
    });
    handleNextStep();
  }, [dispatch, handleNextStep, selectedAddons]);

  if (consultationRecommendationsReducer.selectedIVDrip === "SKIP") {
    console.log("--- selected iv drip is SKIP in customizations modal");
    return <Redirect to={`/order/${orderUUID}`} />;
  }

  return (
    <Dialog open={true} onClose={handleClose} fullWidth={true} maxWidth="sm">
      <DialogTitle sx={{ marginBottom: "2rem", lineHeight: "1" }} color="teal">
        <VaccinesRoundedIcon /> Add-ons for {consultationRecommendationsReducer.selectedIVDrip?.name}
      </DialogTitle>
      <DialogContent
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          gap: "1rem",
          paddingBottom: "2rem",
          height: "100%",
          "& .MuiButtonBase-root": {
            minWidth: "300px",
          },
          "& .MuiButton-containedPrimary": {
            color: "white",
          },
        }}
      >
        <LoadingSpinner spinning={loading} styles={{ minHeight: "100px" }}>
          <List
            dense
            sx={{
              width: "100%",
              bgcolor: "background.paper",
              height: "100%",
              minHeight: "60vh",
              maxHeight: "60vh",
              overflow: "scroll",
            }}
          >
            {consultationRecommendationsReducer.selectedIVDrip?.customizations?.map((addon) => {
              return (
                <ListItem
                  key={addon.customizationUUID}
                  sx={{
                    borderBottom: "1px solid #cccccc",
                    marginBottom: "1rem",
                    "& .MuiListItemSecondaryAction-root .MuiButtonBase-root": {
                      minWidth: "50px",
                    },
                    "& .MuiListItemButton-root": {
                      alignItems: "flex-start",
                    },
                  }}
                  secondaryAction={
                    <Checkbox
                      edge="end"
                      onChange={(e) => {
                        if (e.target.checked) {
                          setSelectedAddons((value) => [...value, addon]);
                        } else {
                          setSelectedAddons((value) => value.filter((ad) => ad.customizationUUID !== addon.customizationUUID));
                        }
                      }}
                      checked={selectedAddons.some((ad) => ad.customizationUUID === addon.customizationUUID)}
                    />
                  }
                  disablePadding
                >
                  <ListItemButton
                    onClick={() => {
                      if (selectedAddons.some((ad) => ad.customizationUUID === addon.customizationUUID)) {
                        setSelectedAddons((value) => value.filter((ad) => ad.customizationUUID !== addon.customizationUUID));
                      } else {
                        setSelectedAddons((value) => [...value, addon]);
                      }
                    }}
                  >
                    <ListItemText
                      id={addon.customizationUUID}
                      primary={<Typography variant="subtitle1">{addon.engName}</Typography>}
                      secondary={<div>{addon.engDescription}</div>}
                    />
                  </ListItemButton>
                </ListItem>
              );
            })}
          </List>
        </LoadingSpinner>
        <Button
          variant={selectedAddons.length ? "contained" : "text"}
          fullWidth={true}
          disableElevation={true}
          disabled={loading}
          onClick={selectedAddons.length ? handleContinueClicked : handleNextStep}
        >
          {selectedAddons.length ? "Continue" : "Skip"}
        </Button>
      </DialogContent>
    </Dialog>
  );
};

interface SelectIVDripCustomizationsModalProps {
  loading: boolean;
}

export default SelectIVDripCustomizationsModal;
