import { settingService } from "../services";
import { settingType } from "../types";
import { showMessage } from "../components/common/notification";

export const settingAction = {
  getManageDateTime,
  createManageDateTime,
  updateManageDateTime,
  updateService,
};

function getManageDateTime(partnerUUID, onSuccess) {
  return (dispatch) => {
    dispatch(request());
    settingService.getManageDateTime(partnerUUID).then(
      (response) => {
        dispatch(success(response.data.partnerSetting));
        dispatch(requestDone());

        if (onSuccess) {
          onSuccess();
        }
      },
      () => {}
    );
  };
  function request() {
    return { type: settingType.GET_SETTING_LOADER_TRUE };
  }
  function requestDone() {
    return { type: settingType.GET_SETTING_LOADER_FALSE };
  }

  function success(data) {
    return { type: settingType.GET_MANAGE_DATE_TIME, data };
  }
}

function createManageDateTime(body, onSuccess) {
  return (dispatch) => {
    dispatch(request());
    settingService.createManageDateTime(body).then(
      (response) => {
        dispatch(success(response.data));
        dispatch(requestDone());

        if (onSuccess) {
          onSuccess();
        }

        if (response.data.success === 1) {
          showMessage("success", "Settings updated!");
        } else {
          showMessage("error", "Failed to update settings, try again!");
        }
      },
      () => {}
    );
  };
  function request() {
    return { type: settingType.GET_SETTING_LOADER_TRUE };
  }
  function requestDone() {
    return { type: settingType.GET_SETTING_LOADER_TRUE };
  }

  function success(data) {
    return { type: settingType.GET_MANAGE_DATE_TIME, data };
  }
}

function updateManageDateTime(body, onSuccess) {
  return (dispatch) => {
    dispatch(request());
    settingService.updateManageDateTime(body).then(
      (response) => {
        dispatch(success(response.data));
        dispatch(requestDone());
        if (onSuccess) {
          onSuccess();
        }

        if (response.data.success === 1) {
          showMessage("success", "Settings updated!");
        } else {
          showMessage("error", "Failed to update settings, try again!");
        }
      },
      () => {}
    );
  };
  function request() {
    return { type: settingType.GET_SETTING_LOADER_TRUE };
  }
  function requestDone() {
    return { type: settingType.GET_SETTING_LOADER_TRUE };
  }

  function success(data) {
    return { type: settingType.GET_MANAGE_DATE_TIME, data };
  }
}

function updateService(body, onSuccess) {
  return () => {
    settingService.updateService(body).then(
      () => {
        if (onSuccess) {
          onSuccess();
        }
      },
      () => {}
    );
  };
}
