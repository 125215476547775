import { FC } from "react";
import useNotificationsPermission from "../hooks/useNotificationsPermission";
import { useSelector } from "react-redux";
import ReduxStateModel from "../models/ReduxStateModel";

const NotificationsPermissionAlert: FC = () => {
  const authReducer = useSelector((state: ReduxStateModel) => state.authReducer?.setting);
  const notificationsPermission = useNotificationsPermission();

  if (!authReducer?.partnerUUID) return null;
  if (notificationsPermission === "granted") return null;

  return (
    <>
      <div
        style={{
          backgroundColor: "red",
          color: "white",
          padding: "4px 8px",
          fontSize: "10pt",
          position: "fixed",
          top: "0px",
          left: "0px",
          right: "0px",
          zIndex: "110",
        }}
      >
        Please enable notifications permission on your browser to get important booking alerts.
      </div>
      <div style={{ height: "30px" }} />
    </>
  );
};

export default NotificationsPermissionAlert;
