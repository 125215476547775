import moment from "moment";

function getStartMomentFromDateObj({ date, time }: { date: string; time: string }): moment.Moment {
  if (time === "11:30 - 12:00AM") {
    return moment(`${date} 11:30PM`, "DD/MM/YYYY hh:mmA");
  }
  const splittedTime = time.split(" - ")[1];
  const dateStartMoment = moment(`${date} ${splittedTime}`, "DD/MM/YYYY hh:mmA").subtract(30, "minute");
  return dateStartMoment;
}

export default getStartMomentFromDateObj;
