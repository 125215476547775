import { FC } from "react";
import { NursesDatum } from "../../models/SchedulerExploreViewResponseModel";
import moment from "moment";

const ModalHeaderProfessionalAvatar: FC<ModalHeaderProfessionalAvatarProps> = ({ date, professionalData }) => {
  return (
    <div style={{ display: "flex", alignItems: "center", gap: "8px" }}>
      <img
        src={professionalData.profileImage}
        alt={professionalData.name}
        style={{ width: "35px", height: "35px", objectFit: "cover", borderRadius: "50%" }}
      />
      <div>
        <h4 style={{ marginBottom: "0px", lineHeight: "1.2" }}>{professionalData.name}</h4>
        {date && (
          <p style={{ marginBottom: "0px", color: "gray", fontSize: "14px", fontWeight: "500" }}>{date.format("DD MMM")}</p>
        )}
      </div>
    </div>
  );
};

interface ModalHeaderProfessionalAvatarProps {
  professionalData: NursesDatum;
  date?: moment.Moment;
}

export default ModalHeaderProfessionalAvatar;
