import { Box, Button, Drawer, IconButton, TextField, Typography } from "@mui/material";
import { FC, useCallback, useMemo, useRef, useState } from "react";
import { LoadingButton } from "@mui/lab";
import { submitOutsourceRequest } from "../services/inventory";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { showMessage } from "./common/notification";
import { useSelector } from "react-redux";
import CancelRoundedIcon from "@mui/icons-material/CancelRounded";
import CheckCircleRoundedIcon from "@mui/icons-material/CheckCircleRounded";
import AddInventoryRequestItems from "./AddInventoryRequestItems";
import PartnerInventoryListItemModel from "../models/PartnerInventoryListItemModel";
import VisuallyHiddenInput from "./VisuallyHiddenInput";
import ArticleRoundedIcon from "@mui/icons-material/ArticleRounded";
import convertFileToBase64 from "../utils/convertFileToBase64";
import ReduxStateModel from "../models/ReduxStateModel";
import useAllPartnerRequestsQuery from "../hooks/useAllPartnerRequestsQuery";

const OutSourceInventoryDrawer: FC<OutSourceInventoryDrawerProps> = ({ onClose, open }) => {
  const invoiceInputRef = useRef<HTMLInputElement>(null);
  const authReducer = useSelector((state: ReduxStateModel) => state.authReducer?.setting);
  const [invoice, setInvoice] = useState<File>();
  const [invoiceAmount, setInvoiceAmount] = useState<string>();
  const queryClient = useQueryClient();

  const handleInvoiceChanged = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    if (!e.target.files?.length) {
      return console.log("--- pick handlePrescriptionChanged no files error");
    }
    setInvoice(e.target.files[0]);
  }, []);

  const [items, setItems] = useState<PartnerInventoryListItemModel[]>([
    {
      name: "",
      quantity: 1,
    },
  ]);

  const submitMutation = useMutation({ mutationFn: submitOutsourceRequest });

  const { queryKey } = useAllPartnerRequestsQuery({ partnerUUID: authReducer?.partnerUUID });

  const handleSubmitClicked = useCallback(async () => {
    const invoiceBase64 = await convertFileToBase64(invoice);
    if (!invoiceBase64) {
      console.log("--- OutSourceInventoryDrawer convertFileToBase64 error");
      return showMessage("error", "Something went wrong");
    }
    const invoiceFileType = invoice?.type.toLocaleLowerCase().includes("pdf") ? "pdf" : "image";
    const res = await submitMutation.mutateAsync({
      invoiceTotalAmount: Number(invoiceAmount),
      items: items,
      partnerUUID: authReducer?.partnerUUID ?? "",
      invoiceData: {
        data: invoiceFileType === "pdf" ? invoiceBase64.slice(28) : invoiceBase64,
        fileName: invoice?.name ?? "",
        type: invoice?.type ?? "",
        fileType: invoiceFileType,
        documentType: "PRESCRIPTION",
      },
    });
    if (res?.success) {
      queryClient.resetQueries(queryKey);
      showMessage("success", "Request was submitted successfully");
      onClose();
      setItems([
        {
          name: "",
          quantity: 1,
        },
      ]);
      setInvoice(undefined);
      setInvoiceAmount(undefined);
    } else {
      showMessage("error", "Something went wrong");
    }
  }, [authReducer?.partnerUUID, invoice, invoiceAmount, items, onClose, queryClient, queryKey, submitMutation]);

  const disableSubmitButton = useMemo(() => {
    return !invoice || !invoiceAmount || items.some((item) => !item.name || !item.quantity) || items.length === 0;
  }, [invoice, invoiceAmount, items]);

  return (
    <Drawer anchor={"right"} open={open} onClose={onClose}>
      <Box width={"600px"} maxWidth={"100vw"} height={"100vh"} padding={2}>
        <Box display={"flex"} flexDirection={"column"} justifyContent={"space-between"} height={"100%"}>
          <Box>
            <Box display={"flex"} justifyContent={"space-between"} alignItems={"center"}>
              <Typography fontWeight={500}>Outsource Items</Typography>
              <IconButton size="small" onClick={onClose}>
                <CancelRoundedIcon />
              </IconButton>
            </Box>
            <AddInventoryRequestItems items={items} isOutsource={true} setItems={setItems} />
            <Button
              component="label"
              variant="outlined"
              color="success"
              sx={{ marginTop: "24px", display: "flex", flexDirection: "column", paddingX: 2, paddingY: 2 }}
              fullWidth={true}
            >
              <Typography marginBottom={2} textAlign={"center"}>
                Upload invoice
              </Typography>
              <Typography
                textAlign={"center"}
                display={"flex"}
                alignItems={"center"}
                justifyContent={"center"}
                gap={1}
                fontWeight={600}
                fontSize={14}
              >
                {invoice ? invoice.name : `Click here to select invoice`}
                <ArticleRoundedIcon />
              </Typography>
              <VisuallyHiddenInput
                type="file"
                accept=".jpg, .jpeg, .png, .pdf"
                ref={invoiceInputRef}
                onChange={handleInvoiceChanged}
              />
            </Button>
            <TextField
              label="Total invoice amount"
              size="small"
              sx={{ marginTop: 4 }}
              fullWidth={true}
              value={invoiceAmount}
              InputProps={{
                startAdornment: <Box marginRight={2}>AED</Box>,
              }}
              onChange={(e) => {
                const numberValue = Number(e.target.value);
                if (isNaN(numberValue)) {
                  return;
                }
                setInvoiceAmount(numberValue.toString());
              }}
            />
          </Box>
          <LoadingButton
            variant="contained"
            disabled={disableSubmitButton}
            disableElevation={true}
            loading={submitMutation.isLoading}
            onClick={handleSubmitClicked}
            startIcon={<CheckCircleRoundedIcon sx={{ color: "white" }} />}
          >
            <Box color={"white"}>Submit</Box>
          </LoadingButton>
        </Box>
      </Box>
    </Drawer>
  );
};

interface OutSourceInventoryDrawerProps {
  open: boolean;
  onClose: () => void;
}

export default OutSourceInventoryDrawer;
