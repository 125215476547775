import decrypt from "../services/decrypt";
import BaseResponseModel from "../models/BaseResponseModel";
import axios from "../utils/axiosHelpers";
import encrypt from "./encrypt";
import { BookingMedicationObj } from "../models/BookingModel";
import { TokenResponseModel } from "../models/TokenResponseModel";
import GetProductsForConsultationResponseModel from "../models/GetProductsForConsultationResponseModel";

export interface FirebaseAppConfigModel {
  apiKey?: string;
  authDomain?: string;
  projectId?: string;
  storageBucket?: string;
  messagingSenderId?: string;
  appId?: string;
  measurementId?: string;
}

export interface GetFirebaseAppConfigResponseModel extends BaseResponseModel {
  config: FirebaseAppConfigModel;
}

export async function getFirebaseAppConfig() {
  if (window.location.host === "localhost:3000") return null;
  const res = await axios.get<string>("/api/v1/apps/get-partner-dashboard-firebase-config");
  const data: GetFirebaseAppConfigResponseModel = decrypt(res.data);
  return data;
}

export async function createKnolarityCall(params: { agentNumber: string; customerNumber: string }) {
  const payload = { data: encrypt(params) };
  const res = await axios.post<string>(`/api/v1/apps/create-knolarity-call`, payload);
  const data: BaseResponseModel = decrypt(res.data);
  return data;
}

export interface SendWhatsappOTPResponseModel extends BaseResponseModel {
  orderID?: string;
}

export interface VerifyWhatsappOTPResponseModel extends BaseResponseModel {
  isOTPVerified?: boolean;
  reason?: string;
}

export async function verifyWhatsappOTP(params: { phoneNumber: string; orderID: string; otp: string }) {
  if (!isProd) {
    params.phoneNumber = "+201121137720";
  }
  const res = await axios.post<string>("/api/v1/apps/verify-whatsapp-otp", { data: encrypt(params) });
  const data: VerifyWhatsappOTPResponseModel = decrypt(res.data);
  return data;
}

const isProd = window.location.host === "partner.dardoc.com";

export async function sendWhatsappOTP(params: { phoneNumber: string }) {
  const res = await axios.post<string>("/api/v1/apps/send-whatsapp-otp", {
    data: encrypt(isProd ? params : { phoneNumber: "+201121137720" }),
  });
  const data: SendWhatsappOTPResponseModel = decrypt(res.data);
  return data;
}

export async function getProductsForConsultation() {
  const res = await axios.get<string>("/api/v1/apps/get-products-for-consultation");
  const data: GetProductsForConsultationResponseModel = decrypt(res.data);
  return data;
}

export interface GetVonageSessionAndTokenIDs extends BaseResponseModel {
  sessionId?: string;
  tokenId?: string;
}

export interface GetWLPMedicationsListResponseModel extends BaseResponseModel {
  list: string[];
}

export async function setOrderMedications(params: {
  orderUUID: string;
  medications: BookingMedicationObj;
}): Promise<BaseResponseModel | null> {
  try {
    const res = await axios.post<string>("/api/v1/orders/uploadmedications", {
      data: encrypt(params),
    });
    const data: BaseResponseModel = decrypt(res.data);
    return data;
  } catch (error) {
    console.log("--- setOrderMedications error", error);
    return null;
  }
}

export async function getWLPMedicationsList() {
  try {
    const res = await axios.get<string>("/api/v1/apps/get-medications-list");
    const data: GetWLPMedicationsListResponseModel = decrypt(res.data);
    return data;
  } catch (error) {
    console.log("--- getWLPMedicationsList error", error);
    return null;
  }
}

export async function getVonageSessionAndTokenIDs(params: { orderUUID: string }): Promise<GetVonageSessionAndTokenIDs | null> {
  try {
    const res = await axios.get<string>(`/api/v1/apps/getvonagesessionandtoken/${params.orderUUID}`);
    const data: GetVonageSessionAndTokenIDs = decrypt(res.data);
    return data;
  } catch (error) {
    console.log("--- getVonageSessionAndTokenIDs error", error);
    return null;
  }
}

export async function getTokenForGetStream(params: { userUUID: string }): Promise<TokenResponseModel | null> {
  try {
    // const body = {
    //   userUUID: params.userUUID,
    // };
    // console.log(params);
    const res = await axios.post<TokenResponseModel>("/api/v1/apps/get-token-for-get-stream", params);
    const data = res.data;
    // console.log("--- token", res.data);
    return data;
  } catch (error) {
    console.log("--- getVonageSessionAndTokenIDs error", error);
    return null;
  }
}
