import { FC, useCallback, useMemo } from "react";
import { showMessage } from "../../components/common/notification";
import { Box, Button, useTheme } from "@mui/material";
import { IoIosCopy } from "react-icons/io";
import { GoArrowUpRight } from "react-icons/go";
import BookingModel from "../../models/BookingModel";
import useAreaServicableQuery from "../../hooks/useAreaServicableQuery";

const textMaxLength = 100;

const ShowBookingAddress: FC<ShowBookingAddressProps> = ({ booking, labelWidth, patientName }) => {
  const theme = useTheme();

  const handleCopyLocation = useCallback(
    (lattitude: string, longitude: string) => {
      let addressStr = "";
      if (patientName) {
        addressStr += `\n Patient Name: ${patientName}`;
      }
      if (booking?.address?.villaName) {
        addressStr += `\n Villa Name: ${booking?.address?.villaName}`;
      }
      if (booking?.address?.villaNumber) {
        addressStr += `\n Villa Number ${booking?.address?.villaNumber}`;
      }
      if (booking?.address?.apartmentNumber) {
        addressStr += `\n Apartment Number: ${booking?.address?.apartmentNumber}`;
      }
      if (booking?.address?.floorNumber) {
        addressStr += `\n Floor Number: ${booking?.address?.floorNumber}`;
      }
      if (booking?.address?.buildingName) {
        addressStr += `\n Building Name: ${booking?.address?.buildingName}`;
      }
      if (booking?.address?.streetName) {
        addressStr += `\n Street Name: ${booking?.address?.streetName}`;
      }
      addressStr += `\n ${booking?.address?.address ?? booking?.address?.detail ?? ""}`;
      addressStr += `\n https://maps.google.com/?q=${lattitude},${longitude}`;
      // const link = `${booking?.address?.villaName ? `Villa Name: ${booking?.address?.villaName}` : ""}\n${
      //   booking?.address?.villaNumber ? `Villa Number: ${booking?.address?.villaNumber}` : ""
      // }\n${booking?.address?.apartmentNumber ? `Apartment Number: ${booking?.address?.apartmentNumber}` : ""}\n${
      //   booking?.address?.address ?? booking?.address?.detail ?? ""
      // }\nhttps://maps.google.com/?q=${lattitude},${longitude}`;
      navigator.clipboard.writeText(addressStr);
      showMessage("info", "Location copied successfully!");
    },
    [
      booking?.address?.address,
      booking?.address?.apartmentNumber,
      booking?.address?.buildingName,
      booking?.address?.detail,
      booking?.address?.floorNumber,
      booking?.address?.streetName,
      booking?.address?.villaName,
      booking?.address?.villaNumber,
      patientName,
    ]
  );

  const lWidth = labelWidth ?? "150px";

  const { query } = useAreaServicableQuery({ lat: booking?.address?.lattitude ?? "", lng: booking?.address?.longitude ?? "" });

  const addressDetailsText = useMemo(() => {
    const textLength = 100;
    const value = `${booking?.address?.address ?? booking?.address?.detail ?? ""}`;
    return `${value.slice(0, textLength)}${value.length > textLength ? "..." : ""}`;
  }, [booking?.address?.address, booking?.address?.detail]);

  if (!booking?.address) return null;

  return (
    <Box
      sx={{
        maxWidth: "300px",
        width: "300px",
        "@media (max-width: 700px)": {
          width: "auto",
        },
      }}
    >
      {booking?.address?.villaName && (
        <div style={{ display: "flex", alignItems: "center", gap: "8px", marginBottom: "8px" }}>
          <div style={{ width: lWidth, color: "black", fontSize: "14px" }}>Villa:</div>
          <div style={{ fontWeight: "500", color: "black" }}>{`${booking?.address?.villaName ?? ""} ${
            booking?.address?.villaNumber ?? ""
          }`}</div>
        </div>
      )}
      {/* {booking?.address?.villaNumber && (
        <div style={{ display: "flex", alignItems: "center", gap: "8px" }}>
          <div style={{ width: lWidth, color: "black", fontSize: "14px" }}>Villa:</div>
          <div style={{ fontWeight: "500", color: "black" }}>{}</div>
        </div>
      )} */}
      {booking?.address?.apartmentNumber && (
        <div style={{ display: "flex", alignItems: "center", gap: "8px", marginBottom: "8px" }}>
          <div style={{ width: lWidth, color: "black", fontSize: "14px" }}>Apartment:</div>
          <div style={{ fontWeight: "500", color: "black" }}>{booking?.address?.apartmentNumber}</div>
        </div>
      )}
      {booking?.address?.floorNumber && (
        <div style={{ display: "flex", alignItems: "center", gap: "8px", marginBottom: "8px" }}>
          <div style={{ width: lWidth, color: "black", fontSize: "14px" }}>Floor:</div>
          <div style={{ fontWeight: "500", color: "black" }}>{booking?.address?.floorNumber}</div>
        </div>
      )}
      {booking?.address?.buildingName && (
        <div style={{ display: "flex", alignItems: "center", gap: "8px", marginBottom: "8px" }}>
          <div style={{ width: lWidth, color: "black", fontSize: "14px" }}>Building:</div>
          <div style={{ fontWeight: "500", color: "black" }}>{`${booking?.address?.buildingName.slice(0, textMaxLength)}${
            booking?.address?.buildingName.length > textMaxLength ? "..." : ""
          }`}</div>
        </div>
      )}
      {booking?.address?.streetName && (
        <div style={{ display: "flex", alignItems: "center", gap: "8px", marginBottom: "8px" }}>
          <div style={{ width: lWidth, color: "black", fontSize: "14px" }}>Street:</div>
          <div style={{ fontWeight: "500", color: "black" }}>{`${booking?.address?.streetName.slice(0, textMaxLength)}${
            booking?.address?.streetName.length > textMaxLength ? "..." : ""
          }`}</div>
        </div>
      )}
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          gap: "8px",
          marginBottom: "8px",
          // "@media(max-width: 700px)": {
          //   flexDirection: "column",
          //   alignItems: "flex-start",
          // },
        }}
      >
        <div style={{ width: lWidth, color: "black", fontSize: "14px" }}>City:</div>
        <div style={{ fontWeight: "500", color: "black" }}>{query.data?.data.city}</div>
      </Box>
      {(booking?.address?.address || booking?.address?.detail) && (
        <div style={{ display: "flex", alignItems: "center", gap: "8px" }}>
          <div style={{ fontWeight: "500", color: "black", width: "100%", overflow: "hidden" }}>{addressDetailsText}</div>
        </div>
      )}
      <Box
        sx={{
          display: "flex",
          gap: "16px",
          marginTop: "16px",
          "@media(max-width: 700px)": {
            flexDirection: "column",
          },
        }}
      >
        <Button
          startIcon={<IoIosCopy />}
          variant="outlined"
          size="small"
          // className="success-hover"
          sx={{
            "&:hover": {
              borderColor: "rgb(80, 201, 202)",
            },
            borderColor: theme.palette.primary.light,
            color: theme.palette.primary.light,
            width: "100%",
          }}
          onClick={(e) => {
            e.stopPropagation();
            handleCopyLocation(booking?.address?.lattitude ?? "", booking?.address?.longitude ?? "");
          }}
        >
          Copy Location
        </Button>
        <a
          target="_blank"
          href={`https://maps.google.com/?q=${booking?.address?.lattitude},${booking?.address?.longitude}`}
          onClick={(e) => {
            e.stopPropagation();
          }}
          style={{ width: "100%" }}
        >
          <Button
            size="small"
            fullWidth={true}
            variant="outlined"
            endIcon={<GoArrowUpRight />}
            sx={{
              "&:hover": {
                borderColor: "rgb(80, 201, 202)",
              },
              borderColor: theme.palette.primary.light,
              color: theme.palette.primary.light,
            }}
          >
            Open in maps
          </Button>
        </a>
      </Box>
    </Box>
  );
};

interface ShowBookingAddressProps {
  booking?: BookingModel;
  labelWidth?: string;
  patientName?: string;
}

export default ShowBookingAddress;
