import { useSelector } from "react-redux";
import ReduxStateModel from "../models/ReduxStateModel";
import { useMemo } from "react";

function useCurrentUserRole() {
  const authReducer = useSelector((state: ReduxStateModel) => state.authReducer?.setting);

  const canUpdate = useMemo(() => {
    return authReducer?.role === "SUPER_ADMIN" || authReducer?.role === "READ_WRITE";
  }, [authReducer?.role]);

  return { role: authReducer?.role, canUpdate };
}

export default useCurrentUserRole;
