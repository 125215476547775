import axios from "../utils/axiosHelpers";
import decrypt from "./decrypt";
// import encrypt from "./encrypt";
import BaseResponseModel from "../models/BaseResponseModel";

export interface CreateHubspotVisitroIdentityResponseModel extends BaseResponseModel {
  token?: string;
}

export async function createVisitorIdentification(params: { email: string; firstName: string; lastName: string }) {
  try {
    const res = await axios.post<string>("/api/v1/apps/create-hubspot-visitor-identification", params);
    const data: CreateHubspotVisitroIdentityResponseModel = decrypt(res.data);
    return data;
  } catch (error) {
    console.log("--- createVisitorIdentification", error);
    return null;
  }
}
