import moment from "moment";
import { Form, FormInstance, Select } from "antd";
import { FC, useMemo } from "react";

const NurseShiftStartFormItem: FC<NurseShiftStartFormItemProps> = () => {
  const options = useMemo(() => {
    const arr: moment.Moment[] = [];
    const currentMoment = moment().startOf("day"); // .add(1, "hour");
    const endOfDay = moment().endOf("day");
    while (currentMoment.isBefore(endOfDay)) {
      arr.push(currentMoment.clone());
      currentMoment.add(30, "minute");
    }
    return arr;
  }, []);

  return (
    <Form.Item
      className="form-item-edit"
      style={{ flex: "auto" }}
      label="Shift starts at"
      name="startTime"
      rules={[
        {
          required: true,
          message: "Please select the shift start time",
        },
      ]}
    >
      <Select>
        {options.map((optionMoment) => {
          return (
            <Select.Option key={optionMoment.format()} value={optionMoment.format()}>
              {optionMoment.format("hh:mm A")}
            </Select.Option>
          );
        })}
      </Select>
    </Form.Item>
  );
};

interface NurseShiftStartFormItemProps {
  // editMode: boolean;
  form: FormInstance;
}

export default NurseShiftStartFormItem;
