import heic2any from "heic2any";
import { useState, useCallback, useEffect } from "react";

export default function useHEICImageURL({ heicUrl }: { heicUrl?: string }) {
  const [imageUrl, setImageUrl] = useState<string>();
  const [loading, setLoading] = useState(true);

  const convertAndDisplayImage = useCallback(async () => {
    if (!heicUrl || !heicUrl.endsWith(".heic")) {
      setLoading(false);
      return;
    }
    try {
      const response = await fetch(heicUrl);
      const heicBlob = await response.blob();

      const imageBlob = await heic2any({
        blob: heicBlob,
        toType: "image/jpeg",
      });
      if (Array.isArray(imageBlob)) {
        const url = URL.createObjectURL(imageBlob[0]);
        setImageUrl(url);
      } else {
        const url = URL.createObjectURL(imageBlob);
        setImageUrl(url);
      }
    } catch (error) {
      console.error("Error converting image:", error);
    } finally {
      setLoading(false);
    }
  }, [heicUrl]);

  useEffect(() => {
    convertAndDisplayImage();
  }, [convertAndDisplayImage]);

  return { imageUrl, loading };
}
