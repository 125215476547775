export const getColor = (type) => {
  let variatName = type?.toLowerCase();

  if (variatName?.includes("gold")) {
    return "#926F34";
  } else if (variatName?.includes("platinum")) {
    return "#41464D";
  } else if (variatName?.includes("elite")) {
    return "#6b328c";
  } else {
    return "#108E95";
  }
};
