export const getMomentDaysBetweenDays: (params: { start: moment.Moment; end: moment.Moment }) => moment.Moment[] = ({
  end,
  start,
}) => {
  const dates: moment.Moment[] = [];
  const currentDay = start.clone().startOf("day");
  const endDate = end.clone().startOf("day");
  while (currentDay.isSameOrBefore(endDate)) {
    dates.push(currentDay.clone());
    currentDay.add(1, "day");
  }
  return dates;
};
