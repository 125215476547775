import { useQuery } from "@tanstack/react-query";
import { useMemo } from "react";
import { useSelector } from "react-redux";
import { FilterPeriodKeysType } from "../pages/Traction";
import { getTracktionTable } from "../services";
import { getStartEndDatesFromSelectedPeriod } from "../pages/OperationAnalytics/HomeNursingPeriodDropdown";
import ReduxStateModel from "../models/ReduxStateModel";

function useTractionTableQuery(selectedPeriod: FilterPeriodKeysType, selectedDateRange?: [moment.Moment, moment.Moment]) {
  const partnerUUID: string | undefined = useSelector((state: ReduxStateModel) => state?.authReducer?.setting?.partnerUUID);

  const startAndEndDatesOfSelectedPeriod = useMemo(() => {
    return getStartEndDatesFromSelectedPeriod(selectedPeriod, selectedDateRange);
  }, [selectedDateRange, selectedPeriod]);

  const query = useQuery(
    [
      "analytics/tractiontable",
      partnerUUID,
      startAndEndDatesOfSelectedPeriod[0].valueOf(),
      startAndEndDatesOfSelectedPeriod[1].valueOf(),
    ],
    () =>
      getTracktionTable({
        startDate: startAndEndDatesOfSelectedPeriod[0].format("YYYY-MM-DD"),
        endDate: startAndEndDatesOfSelectedPeriod[1].format("YYYY-MM-DD"),
        partnerUUID: partnerUUID!,
      }),
    { enabled: !!partnerUUID }
  );

  return query;
}

export default useTractionTableQuery;
