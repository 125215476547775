import { useQuery } from "@tanstack/react-query";
import { showMessage } from "../components/common/notification";
import { nurseService } from "../services";
import { useSelector } from "react-redux";
import { useMemo } from "react";
import { StaffMemberModel } from "../models/StaffMemberModel";
import ReduxStateModel from "../models/ReduxStateModel";
import checkIfNurseHasMissingData from "../components/Sidebar/checkIfNurseHasMissingData";

function useAllPartnerNurses() {
  const partnerUserUUID = useSelector((state: ReduxStateModel) => state?.authReducer?.setting?.partnerUserUUID);

  const queryKey = useMemo<[string, string | undefined]>(() => ["nurses", partnerUserUUID], [partnerUserUUID]);

  const query = useQuery(queryKey, () => nurseService.getAllNurses(partnerUserUUID ?? ""), {
    enabled: !!partnerUserUUID,
    refetchOnWindowFocus: false,
    staleTime: Infinity,
    cacheTime: Infinity,
    onSuccess: (res) => {
      if (!res?.data?.success) {
        showMessage("error", "Somethint went wrong");
      }
    },
    onError: () => {
      showMessage("error", "Somethint went wrong");
    },
  });

  const someNurseHasMessingData = useMemo(() => {
    return query.data?.data.nurses?.some((nurse) => checkIfNurseHasMissingData(nurse)) ?? false;
  }, [query.data?.data.nurses]);

  const nursesMap = useMemo<Map<string, StaffMemberModel>>(() => {
    const map = new Map<string, StaffMemberModel>();
    for (const nurse of query.data?.data.nurses ?? []) {
      map.set(nurse.nurseUUID ?? "", nurse);
    }
    return map;
  }, [query.data?.data.nurses]);

  return { query, queryKey, nursesMap, someNurseHasMessingData };
}

export default useAllPartnerNurses;
