import { Drawer, Typography, styled } from "@mui/material";
import { FC, useMemo } from "react";
import { LayoutSideMenuListItem } from "../models/LayoutSideMenuListItem";
import { useSelector } from "react-redux";
import { drawerWidth } from "../constants";
import SideDrawerList from "./SideDrawerList";
import ReduxStateModel from "../models/ReduxStateModel";
import useAllPartnerNurses from "../hooks/useAllPartnerNurses";
import useWindowDimensions from "../hooks/useWindowDimensions";

// const excludeListItemsFromDeliveryPartner = ["/scheduler", "/new-driver-schedule", "/team", "/traction"];

const BasePageLayoutDrawer: FC<BasePageLayoutDrawerProps> = ({
  open,
  zIndex,
  backgroundColor,
  textColor,
  sideNavListItems,
  setOpen,
}) => {
  const partnerName = useSelector((state: ReduxStateModel) => state.authReducer?.setting?.name);
  const { isMobile } = useWindowDimensions();

  const { query } = useAllPartnerNurses();

  // const { queryData } = useCurrentPartnerDetails();
  // const isDeliveryPartner = queryData.query.data?.partner.isDeliveryPartner ?? false;

  const listItems = useMemo(() => {
    // if (isDeliveryPartner) {
    //   const homeListItem = sideNavListItems.find((item) => item.href === "/");
    //   if (homeListItem) {
    //     homeListItem.label = "Orders";
    //   }
    //   return sideNavListItems.filter((listItem) => !excludeListItemsFromDeliveryPartner.includes(listItem.href));
    // }
    if (query.data?.data.drivers?.filter((driver) => driver.isActive && !driver.isDeleted).length) {
      return sideNavListItems;
    }
    return sideNavListItems.filter((listItem) => listItem.href !== "/new-driver-schedule");
  }, [query.data?.data.drivers, sideNavListItems]);

  return (
    <Drawer
      className="main-side-menu"
      onClose={() => setOpen(!open)}
      sx={{
        width: drawerWidth,
        flexShrink: 0,
        "& .MuiPaper-root": {
          zIndex: zIndex - 10,
        },
        "& .MuiDrawer-paper": {
          width: drawerWidth,
          boxSizing: "border-box",
          backgroundColor: backgroundColor,
        },
      }}
      variant={isMobile ? undefined : "persistent"}
      anchor="left"
      open={open}
    >
      <DrawerHeader className="drawer-header" sx={{ justifyContent: "flex-start" }}>
        <Typography
          fontWeight={600}
          sx={{
            color: textColor,
            display: "none",
            "@media (max-width: 700px)": {
              display: "block",
            },
          }}
        >
          {partnerName}
        </Typography>
      </DrawerHeader>
      <SideDrawerList sideNavListItems={listItems} backgroundColor={backgroundColor} textColor={textColor} />
    </Drawer>
  );
};

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(0, 1),
  ...theme.mixins.toolbar,
  justifyContent: "flex-end",
}));

interface BasePageLayoutDrawerProps {
  open: boolean;
  zIndex: number;
  backgroundColor: string;
  textColor: string;
  sideNavListItems: LayoutSideMenuListItem[];
  setOpen: (value: boolean) => void;
}

export default BasePageLayoutDrawer;
