import { Button, Divider, Drawer, Typography, useTheme } from "@mui/material";
import { FC } from "react";
import ArrowBackIosNewRoundedIcon from "@mui/icons-material/ArrowBackIosNewRounded";
import PharmacyBookingModel from "../../models/PharmacyBookingModel";

const ItemsPaidForDrawer: FC<ItemsPaidForDrawerProps> = ({ open, order, onClose }) => {
  const theme = useTheme();

  return (
    <Drawer
      open={open}
      onClose={onClose}
      anchor={"right"}
      elevation={0}
      sx={{
        "& .MuiBackdrop-root": { backgroundColor: "unset" },
        "& .MuiPaper-root": {
          borderLeft: `1px solid ${theme.components?.MuiDivider?.defaultProps?.style?.borderColor}`,
        },
      }}
    >
      <div style={{ width: "700px" }}>
        <div
          style={{ padding: "16px 24px", display: "flex", alignItems: "center", justifyContent: "space-between", height: "64px" }}
        >
          <Button
            startIcon={<ArrowBackIosNewRoundedIcon />}
            onClick={onClose}
            sx={{
              color: theme.palette.primary.light,
            }}
          >
            Back
          </Button>
        </div>
        <Divider />
        <div style={{ padding: "16px 24px" }}>
          {order.itemsPaidFor?.map((item) => {
            return (
              <div key={item.productUUID}>
                <Typography variant="h4">{item.productName}</Typography>
                <Typography variant="body2">{item.dosage}</Typography>
                <Divider sx={{ marginTop: "16px" }} />
              </div>
            );
          })}
        </div>
      </div>
    </Drawer>
  );
};

interface ItemsPaidForDrawerProps {
  open: boolean;
  order: PharmacyBookingModel;
  onClose: () => void;
}

export default ItemsPaidForDrawer;
