import {
  Avatar,
  Dialog,
  DialogContent,
  DialogTitle,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Typography,
} from "@mui/material";
import { FC, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import ReduxStateModel from "../../models/ReduxStateModel";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { handleMultiProductAddon } from "../../services/connected.care.service";
import { showMessage } from "../../components/common/notification";
import { ConsultationRecommendationsType } from "../../types";
import { LoadingButton } from "@mui/lab";
import BookingModel, {
  RecommendationsRequestProductItemModel,
  RecommendationsRequestProductsModel,
} from "../../models/BookingModel";
import useOrderDetails from "../../hooks/useOrderDetails";
import usePartnerOrders from "../../hooks/usePartnerOrders";
import useSearchOrderData from "../../hooks/useSearchOrderData";
import useSortOrdersQuery from "../../hooks/useSortOrdersQuery";

const ConfirmRecommendationsModal: FC<ConfirmRecommendationsModalProps> = ({ setBooking }) => {
  const { orderUUID } = useParams<{ orderUUID: string }>();
  const history = useHistory();
  const queryClient = useQueryClient();
  const dispatch = useDispatch();
  const consultationRecommendationsReducer = useSelector((state: ReduxStateModel) => state.consultationRecommendationsReducer);

  const { query, queryKey: orderDetailsQueryKey } = useOrderDetails({ cacheTime: Infinity, orderUUID: orderUUID });

  const handleClose = useCallback(() => {
    history.push(`/order/${orderUUID}`);
  }, [history, orderUUID]);

  const ivDrip = consultationRecommendationsReducer.selectedIVDrip;
  const ivDripAddons = consultationRecommendationsReducer.selectedCustomizations;
  const labTests = consultationRecommendationsReducer.selectedLabTests;
  const buildYourLabTestAddons = consultationRecommendationsReducer.selectedBuildYourLabTestItems;

  const saveMutation = useMutation({ mutationFn: handleMultiProductAddon });

  const status = query.data?.order?.status;

  const { queryKey: partnerOrdersQueryKey } = usePartnerOrders({
    status: query.data?.order?.status,
    loadMoreElementInView: false,
    enabled: true,
  });

  const { queryKey: searchOrdersQueryKey } = useSearchOrderData("BOOKING_ID", orderUUID, 1);

  const { queryKey: sortQueryOrdersKey } = useSortOrdersQuery({
    pageNumber: 1,
    type: "ASCENDING",
    value: { endDate: "", startDate: "" },
    sortUnreportedOrdersOnly: true,
    filter: {
      nurse: query.data?.order?.assignedNurses?.map((nurse) => nurse.nurseUUID ?? ""),
      service: query.data?.order?.patientId?.map((patient) => patient.productUUID ?? ""),
    },
    view: status === "ACCEPTED" ? "ACCEPT" : status === "PROCESSING" ? "ASSIGN" : "REPORT",
  });

  const handleConfirmClicked = useCallback(async () => {
    const reqProducts: RecommendationsRequestProductsModel[] = [];
    if (ivDrip && ivDrip !== "SKIP") {
      reqProducts.push({
        type: "DRIPS",
        customizations: ivDripAddons ?? [],
        multipleProducts: [
          { imageUrl: ivDrip.mainImageUrl ?? "", name: ivDrip.name ?? "", productUUID: ivDrip.productUUID ?? "" },
        ],
        productUUIDs: [ivDrip.productUUID ?? ""],
      });
    }
    if (labTests && labTests !== "SKIP") {
      reqProducts.push({
        type: "LAB_TESTS",
        customizations: buildYourLabTestAddons && buildYourLabTestAddons !== "SKIP" ? buildYourLabTestAddons : [],
        multipleProducts: labTests.map<RecommendationsRequestProductItemModel>((test) => {
          return {
            imageUrl: test.mainImageUrl ?? "",
            name: test.name ?? "",
            productUUID: test.productUUID ?? "",
          };
        }),
        productUUIDs: labTests.map((test) => test.productUUID ?? ""),
      });
    }
    const res = await saveMutation.mutateAsync({
      orderUUID: orderUUID,
      blockUUID: query.data?.order?.blockUUID ?? "",
      programBookingId: query.data?.order?.programBookingId ?? "",
      products: reqProducts,
    });
    if (!res.success) {
      return showMessage("error", "Something went wrong");
    }
    history.replace(`/order/${orderUUID}`);
    setBooking((value) => {
      const newValue = { ...value };
      newValue.recommendations = reqProducts;
      return newValue;
    });
    queryClient.invalidateQueries(orderDetailsQueryKey);
    queryClient.invalidateQueries(partnerOrdersQueryKey);
    queryClient.invalidateQueries(searchOrdersQueryKey);
    queryClient.invalidateQueries(sortQueryOrdersKey);
    dispatch({ type: ConsultationRecommendationsType.RESET_CONSULTATION_RECOMMENDATIONS });
    showMessage("success", "Recommendations were saved successfully");
  }, [
    buildYourLabTestAddons,
    dispatch,
    history,
    ivDrip,
    ivDripAddons,
    labTests,
    orderDetailsQueryKey,
    orderUUID,
    partnerOrdersQueryKey,
    query.data?.order?.blockUUID,
    query.data?.order?.programBookingId,
    queryClient,
    saveMutation,
    searchOrdersQueryKey,
    setBooking,
    sortQueryOrdersKey,
  ]);

  const showLabTests = !!labTests && labTests !== "SKIP";
  const showLabTestAddon = !!buildYourLabTestAddons && buildYourLabTestAddons !== "SKIP";

  return (
    <Dialog open={true} onClose={handleClose} fullWidth={true} maxWidth="sm">
      <DialogTitle sx={{ marginBottom: "2rem", textAlign: "center" }}>Confirm Recommendations</DialogTitle>
      <DialogContent
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          gap: "1rem",
          paddingBottom: "2rem",
          "& .MuiButtonBase-root": {
            minWidth: "300px",
          },
        }}
      >
        <List
          dense
          sx={{
            width: "100%",
            bgcolor: "background.paper",
            height: "100%",
            minHeight: "60vh",
            maxHeight: "60vh",
            overflow: "scroll",
            "& .iv-drip-list-item": {
              alignItems: "flex-start",
            },
          }}
        >
          {ivDrip && ivDrip !== "SKIP" && (
            <ListItem
              sx={{
                borderBottom: "1px solid #cccccc",
                marginBottom: "1rem",
                "& .MuiListItemSecondaryAction-root .MuiButtonBase-root": {
                  minWidth: "50px",
                },
                "& .MuiListItemButton-root": {
                  alignItems: "flex-start",
                },
              }}
              disablePadding
            >
              <ListItemText
                primary={
                  <Typography variant="subtitle1" fontWeight={600}>
                    IV Drip
                  </Typography>
                }
              />
            </ListItem>
          )}
          {ivDrip && ivDrip !== "SKIP" && (
            <ListItem
              className="iv-drip-list-item"
              sx={{
                borderBottom: "1px solid #cccccc",
                paddingBottom: (ivDripAddons?.length ?? 0) > 0 ? "0px" : "1rem",
                marginBottom: "0.2rem",
                "& .MuiListItemSecondaryAction-root .MuiButtonBase-root": {
                  minWidth: "50px",
                },
                "& .MuiListItemButton-root": {
                  alignItems: "flex-start",
                },
              }}
              disablePadding
            >
              <ListItemAvatar>
                <Avatar sx={{ borderRadius: "4px" }} alt={ivDrip.name} src={ivDrip.mainImageUrl} />
              </ListItemAvatar>
              <ListItemText
                primary={
                  <Typography variant="subtitle1" sx={{}}>
                    {ivDrip.name}
                  </Typography>
                }
                secondary={
                  (ivDripAddons?.length ?? 0) > 0 ? (
                    <div>
                      <div style={{ fontWeight: 600 }}>Add-ons:</div>{" "}
                      <ul style={{ padding: "4px 14px" }}>
                        {ivDripAddons?.map((addon) => {
                          return (
                            <li key={addon.customizationUUID} style={{ marginBottom: "0.5rem" }}>
                              {addon.engName}
                            </li>
                          );
                        })}
                      </ul>
                    </div>
                  ) : null
                }
              />
            </ListItem>
          )}
          {(showLabTests || showLabTestAddon) && (
            <ListItem
              sx={{
                borderBottom: "1px solid #cccccc",
                marginBottom: "1rem",
                "& .MuiListItemSecondaryAction-root .MuiButtonBase-root": {
                  minWidth: "50px",
                },
                "& .MuiListItemButton-root": {
                  alignItems: "flex-start",
                },
              }}
              disablePadding
            >
              <ListItemText
                primary={
                  <Typography variant="subtitle1" fontWeight={600}>
                    Lab Tests
                  </Typography>
                }
              />
            </ListItem>
          )}
          {showLabTests &&
            labTests.map((test) => {
              return (
                <ListItem
                  key={test.productUUID}
                  sx={{
                    borderBottom: "1px solid #cccccc",
                    paddingBottom: "1rem",
                    marginBottom: "1rem",
                    "& .MuiListItemSecondaryAction-root .MuiButtonBase-root": {
                      minWidth: "50px",
                    },
                    "& .MuiListItemButton-root": {
                      alignItems: "flex-start",
                    },
                  }}
                  disablePadding
                >
                  <ListItemAvatar>
                    <Avatar sx={{ borderRadius: "4px" }} alt={test.name} src={test.mainImageUrl} />
                  </ListItemAvatar>
                  <ListItemText
                    primary={
                      <Typography variant="subtitle1" sx={{}}>
                        {test.name}
                      </Typography>
                    }
                  />
                </ListItem>
              );
            })}
          {showLabTestAddon &&
            buildYourLabTestAddons.map((test) => {
              return (
                <ListItem
                  key={test.customizationUUID}
                  sx={{
                    borderBottom: "1px solid #cccccc",
                    marginBottom: "1rem",
                    "& .MuiListItemSecondaryAction-root .MuiButtonBase-root": {
                      minWidth: "50px",
                    },
                    "& .MuiListItemButton-root": {
                      alignItems: "flex-start",
                    },
                  }}
                  disablePadding
                >
                  <ListItemText
                    primary={
                      <Typography variant="subtitle1" sx={{}}>
                        {test.engName}
                      </Typography>
                    }
                    secondary={<Typography variant="subtitle2">{test.engDescription}</Typography>}
                  />
                </ListItem>
              );
            })}
        </List>
        <LoadingButton
          loading={saveMutation.isLoading}
          variant="contained"
          disableElevation={true}
          fullWidth={true}
          onClick={handleConfirmClicked}
        >
          <span style={{ color: "white" }}>Confirm</span>
        </LoadingButton>
      </DialogContent>
    </Dialog>
  );
};

interface ConfirmRecommendationsModalProps {
  setBooking: React.Dispatch<React.SetStateAction<BookingModel>>;
}

export default ConfirmRecommendationsModal;
