import { Badge } from "@mui/material";
import { FC } from "react";

const NewBookingsTabBadge: FC<NewBookingsTabBadgeProps> = ({ count }) => {
  return (
    <Badge
      color="primary"
      badgeContent={count > 0 ? count : undefined}
      sx={{
        "& .MuiBadge-badge": {
          right: "-10px",
          color: "white",
          borderRadius: "50%",
          minWidth: "25px",
          minHeight: "25px",
        },
      }}
    >
      <span style={{ color: "black" }}>New</span>
    </Badge>
  );
};

interface NewBookingsTabBadgeProps {
  count: number;
}

export default NewBookingsTabBadge;
