import { FC } from "react";
import { TractionTableRowModel } from "../../models/TractionTableResponseModel";
import {
  tractionTableBookingIDColumnWidth,
  tractionTableunitColumnWidth,
  tractionTableReceivedColumnWidth,
} from "../../constants";
import { Paper } from "@mui/material";

const TracktionTableRowItem: FC<TracktionTableRowItemProps> = ({ row }) => {
  return (
    <div className="table-row" key={row.orderUUID}>
      <div
        className="table-cell"
        style={{ color: "gray", width: tractionTableBookingIDColumnWidth, minWidth: tractionTableBookingIDColumnWidth }}
      >
        <div>{row.orderUUID}</div>
        <div>{row.status === "CANCELLED" && <div style={{ fontWeight: "bold", color: "black" }}>Cancelled</div>}</div>
      </div>
      <div
        className="table-cell"
        style={{ width: tractionTableBookingIDColumnWidth, minWidth: tractionTableBookingIDColumnWidth }}
      >
        {row.date}
      </div>
      <div className="table-cell service-cell">
        <div className="tag" key={row.service}>
          {row.service}
        </div>
      </div>
      <div className="table-cell">{row.PatientName?.join(", ")}</div>
      <div
        className="table-cell"
        style={{ width: tractionTableunitColumnWidth, minWidth: tractionTableunitColumnWidth, fontSize: "12px" }}
      >
        <span style={{ textTransform: "capitalize" }}>{row.unitType}</span>
      </div>
      <div className="table-cell" style={{ width: tractionTableReceivedColumnWidth, minWidth: tractionTableReceivedColumnWidth }}>
        {row.recievedQuantity}
      </div>
      <div className="table-cell" style={{ width: tractionTableReceivedColumnWidth, minWidth: tractionTableReceivedColumnWidth }}>
        {row.servedQuantity}
      </div>
      <div className="table-cell">{row.assignedProfessionals?.join(", ")}</div>
      <div className="table-cell">{`AED ${row.basePrice}`}</div>
      <div className="table-cell">{`AED ${row.totalPrice?.toLocaleString()}`}</div>
      <div
        className="table-cell"
        style={{ display: "flex", flexWrap: "wrap", gap: "8px", justifyContent: "center", alignItems: "center" }}
      >
        {row.customizations?.map((customization, index) => {
          return (
            <div className="tag" key={index}>
              <span>{customization.engName}</span>
              <span style={{ fontWeight: "500" }}>{` - AED ${customization.price}`}</span>
            </div>
          );
        })}
        {row.customizations?.length ? "" : "-"}
      </div>
      <div className="table-cell">{row.collectionFee ? `${row.collectionFee ?? 0} AED` : "-"}</div>
      <div className="table-cell">{row.convenienceFee ? `${row.convenienceFee ?? 0} AED` : "-"}</div>
      <div className="table-cell" style={{ display: "flex", flexDirection: "column", gap: "8px" }}>
        {!row.penalties?.length && !row.adminNotes?.length ? "-" : ""}
        {row.penalties?.map((penalty, i) => {
          return (
            <Paper variant="outlined" key={penalty._id ?? i} sx={{ padding: "8px", borderRadius: "10px" }}>
              <div style={{ textAlign: "center" }}>
                <div>
                  AED <span style={{ color: "red" }}>-{penalty.amount.toLocaleString()}</span>
                </div>
                <div>{penalty.comment}</div>
              </div>
            </Paper>
          );
        })}
        {!row.penalties?.length &&
          row.adminNotes?.map<JSX.Element | null>((note) => {
            if (!note.showToPartner) return null;
            return (
              <Paper variant="outlined" key={note.timeStamp} sx={{ padding: "8px", borderRadius: "10px" }}>
                <div style={{ textAlign: "center" }}>
                  <div>{note.note}</div>
                </div>
              </Paper>
            );
          })}
      </div>
      <div className="table-cell" style={{ display: "flex", flexDirection: "column", gap: "8px" }}>
        {!row.partnerCredits?.length ? "-" : ""}
        {row.partnerCredits?.map((credit, i) => {
          return (
            <Paper variant="outlined" key={i} sx={{ padding: "8px", borderRadius: "10px" }}>
              <div style={{ textAlign: "center" }}>
                <div>
                  AED <span style={{ fontWeight: 500, color: "green" }}>{Number(credit.amount ?? "0")?.toLocaleString()}</span>
                </div>
                <div>{credit.reason}</div>
              </div>
            </Paper>
          );
        })}
      </div>
      <div className="table-cell">{`AED ${row.finalPrice?.toLocaleString() ?? "0"}`}</div>
    </div>
  );
};

interface TracktionTableRowItemProps {
  row: TractionTableRowModel;
}

export default TracktionTableRowItem;
