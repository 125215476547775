import { useQuery } from "@tanstack/react-query";
import { getPartnerContact } from "../services/inventory";
import { useMemo } from "react";

export default function usePartnerContactQuery({ contactUUID }: { contactUUID?: string }) {
  const queryKey = useMemo(() => ["inventory-requests/get-partner-contact", contactUUID], [contactUUID]);

  const query = useQuery(queryKey, () => getPartnerContact({ contactUUID: contactUUID ?? "" }), { enabled: !!contactUUID });

  return { query, queryKey };
}
