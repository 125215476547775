import { useQuery } from "@tanstack/react-query";
import { useMemo } from "react";
import { useSelector } from "react-redux";
import { FilterPeriodKeysType } from "../pages/Traction";
import { getTracktionHeader } from "../services";
import { getStartEndDatesFromSelectedPeriod } from "../pages/OperationAnalytics/HomeNursingPeriodDropdown";
import ReduxStateModel from "../models/ReduxStateModel";

function useTractionHeadersQuery(selectedPeriod: FilterPeriodKeysType, selectedDateRange?: [moment.Moment, moment.Moment]) {
  const partnerUUID: string | undefined = useSelector((state: ReduxStateModel) => state?.authReducer?.setting?.partnerUUID);

  const startAndEndDatesOfSelectedPeriod = useMemo(() => {
    return getStartEndDatesFromSelectedPeriod(selectedPeriod, selectedDateRange);
  }, [selectedDateRange, selectedPeriod]);

  const queryKey = useMemo<[string, string | undefined, number, number]>(() => {
    return [
      "analytics/tractionheader",
      partnerUUID,
      startAndEndDatesOfSelectedPeriod[0].valueOf(),
      startAndEndDatesOfSelectedPeriod[1].valueOf(),
    ];
  }, [partnerUUID, startAndEndDatesOfSelectedPeriod]);

  const query = useQuery(
    queryKey,
    () =>
      getTracktionHeader({
        startDate: startAndEndDatesOfSelectedPeriod[0].format("YYYY-MM-DD"),
        endDate: startAndEndDatesOfSelectedPeriod[1].format("YYYY-MM-DD"),
        partnerUUID: partnerUUID!,
      }),
    { enabled: !!partnerUUID }
  );

  return { query, queryKey };
}

export default useTractionHeadersQuery;
