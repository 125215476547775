import { FC } from "react";
import { authService } from "../services";
import { useSelector } from "react-redux";
import useUserData from "../hooks/useUserData";
import ReduxStateModel from "../models/ReduxStateModel";
import useLogout from "../hooks/useLogout";

const ForceUserLogout: FC = () => {
  const partnerUserUUIDFromRedux = useSelector((state: ReduxStateModel) => state.authReducer?.setting?.partnerUserUUID);
  const { logout } = useLogout({});

  useUserData({
    onSuccess: async (res) => {
      if (!partnerUserUUIDFromRedux) return;
      if (res?.data?.settings?.didForceLogout) return;
      const setForceLogoutRes = await authService.setUserForceLogout({
        didForceLogout: true,
        partnerUserUUID: partnerUserUUIDFromRedux,
      });
      if (setForceLogoutRes?.user?.partnerUserUUID) {
        logout();
      }
    },
  });

  return null;
};

export default ForceUserLogout;
