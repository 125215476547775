import {
  Button,
  Checkbox,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  TextField,
  Typography,
} from "@mui/material";
import { FC, useCallback, useMemo, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import BiotechRoundedIcon from "@mui/icons-material/BiotechRounded";
import LoadingSpinner from "../../components/LoadingSpinner";
import ReduxStateModel from "../../models/ReduxStateModel";
import { ConsultationRecommendationsInitialState } from "../../reducer/consultation.recommendations.reducer";
import { ConsultationRecommendationsType } from "../../types";
import useBiomarkersListQuery from "../../hooks/useBiomarkersListQuery";

const SuggestBuildYourLabTestAddonsModal: FC<SuggestBuildYourLabTestAddonsModalProps> = ({ loading }) => {
  const { orderUUID } = useParams<{ orderUUID: string }>();
  const history = useHistory();
  const dispatch = useDispatch();
  const [searchText, setSearchText] = useState("");
  const [selectedAddons, setSelectedAddons] = useState<Map<string, boolean>>(new Map());
  const consultationRecommendationsReducer = useSelector((state: ReduxStateModel) => state.consultationRecommendationsReducer);

  const handleClose = useCallback(() => {
    history.push(`/order/${orderUUID}`);
  }, [history, orderUUID]);

  // const { query } = useBuildYourLabTestAddons();
  const { query: biomarkersListQuery } = useBiomarkersListQuery();

  const fullAddonsList = useMemo(() => {
    return biomarkersListQuery.data?.addOns ?? [];
  }, [biomarkersListQuery.data?.addOns]);

  const addonsList = useMemo(() => {
    const searchQ = searchText.trim().toLocaleLowerCase();
    if (searchQ.length) {
      return fullAddonsList?.filter((item) => {
        return item.engName?.toLowerCase().includes(searchQ);
      });
    }
    return fullAddonsList;
  }, [fullAddonsList, searchText]);

  const handleContinueClicked = useCallback(() => {
    const selectedTestsList = fullAddonsList?.filter((addon) => {
      return selectedAddons.get(addon.customizationUUID ?? "");
    });
    const payload: Partial<ConsultationRecommendationsInitialState> = {
      selectedBuildYourLabTestItems: selectedTestsList ?? [],
    };
    dispatch({ type: ConsultationRecommendationsType.SET_CONSULTATION_RECOMMENDATIONS, payload: payload });
    if (consultationRecommendationsReducer.selectedIVDrip === "SKIP") {
      history.push(`/order/${orderUUID}/suggestions/confirm`);
    } else if (consultationRecommendationsReducer.selectedIVDrip === undefined) {
      history.push(`/order/${orderUUID}/suggestions/iv-drips`);
    } else {
      history.push(`/order/${orderUUID}/suggestions/confirm`);
    }
  }, [consultationRecommendationsReducer.selectedIVDrip, dispatch, fullAddonsList, history, orderUUID, selectedAddons]);

  const handleSkipClicked = useCallback(() => {
    if (consultationRecommendationsReducer.selectedIVDrip === undefined) {
      history.push(`/order/${orderUUID}/suggestions/iv-drips`);
    } else {
      history.push(`/order/${orderUUID}/suggestions/confirm`);
    }
  }, [consultationRecommendationsReducer.selectedIVDrip, history, orderUUID]);

  const didSelectAtLeastOneTest = useMemo(() => {
    return Array.from(selectedAddons.values()).some((value) => value);
  }, [selectedAddons]);

  return (
    <Dialog open={true} onClose={handleClose} fullWidth={true} maxWidth="sm">
      <DialogTitle sx={{ marginBottom: "2rem", lineHeight: "1" }} color="teal">
        <BiotechRoundedIcon /> Lab Tests Add-ons
      </DialogTitle>
      <DialogContent
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          gap: "1rem",
          paddingBottom: "2rem",
          height: "100%",
          "& .MuiButtonBase-root": {
            minWidth: "300px",
          },
          "& .MuiButton-containedPrimary": {
            color: "white",
          },
        }}
      >
        <TextField
          label="Search Lab Tests Add-ons"
          variant="outlined"
          size="small"
          fullWidth={true}
          sx={{ marginTop: "0.3rem" }}
          autoFocus={true}
          value={searchText}
          onChange={(e) => {
            setSearchText(e.target.value);
          }}
        />
        <Divider sx={{ width: "100%", borderColor: "black" }} />
        <LoadingSpinner spinning={biomarkersListQuery.isLoading || loading} styles={{ minHeight: "100px" }}>
          <List
            dense
            sx={{
              width: "100%",
              bgcolor: "background.paper",
              height: "100%",
              minHeight: "60vh",
              maxHeight: "60vh",
              overflow: "scroll",
            }}
          >
            {addonsList?.map((addon, i) => {
              return (
                <ListItem
                  key={addon._id ?? i}
                  sx={{
                    borderBottom: "1px solid #cccccc",
                    marginBottom: "1rem",
                    "& .MuiListItemSecondaryAction-root .MuiButtonBase-root": {
                      minWidth: "50px",
                    },
                    "& .MuiListItemButton-root": {
                      alignItems: "flex-start",
                    },
                  }}
                  secondaryAction={
                    <Checkbox
                      edge="end"
                      onChange={(checkBox) => {
                        setSelectedAddons((value) => {
                          const newValue = new Map(value);
                          if (checkBox.target.checked) {
                            newValue.set(addon.customizationUUID ?? "", true);
                          } else {
                            newValue.set(addon.customizationUUID ?? "", false);
                          }
                          return newValue;
                        });
                      }}
                      checked={selectedAddons.get(addon.customizationUUID ?? "") ?? false}
                    />
                  }
                  disablePadding
                >
                  <ListItemButton
                    onClick={() => {
                      setSelectedAddons((value) => {
                        const newValue = new Map(value);
                        if (newValue.get(addon.customizationUUID ?? "")) {
                          newValue.set(addon.customizationUUID ?? "", false);
                        } else {
                          newValue.set(addon.customizationUUID ?? "", true);
                        }
                        return newValue;
                      });
                    }}
                  >
                    <ListItemText
                      primary={
                        <Typography variant="subtitle1" sx={{}}>
                          {addon.engName}
                        </Typography>
                      }
                      secondary={<div>{addon.engDescription}</div>}
                    />
                  </ListItemButton>
                </ListItem>
              );
            })}
          </List>
        </LoadingSpinner>
        <Button
          variant={didSelectAtLeastOneTest ? "contained" : "text"}
          fullWidth={true}
          disabled={biomarkersListQuery.isLoading || loading}
          disableElevation={true}
          onClick={didSelectAtLeastOneTest ? handleContinueClicked : handleSkipClicked}
        >
          {didSelectAtLeastOneTest ? "Continue" : "Skip"}
        </Button>
      </DialogContent>
    </Dialog>
  );
};

interface SuggestBuildYourLabTestAddonsModalProps {
  loading: boolean;
}

export default SuggestBuildYourLabTestAddonsModal;
