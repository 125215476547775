import { FC, useMemo } from "react";
import { Typography } from "@mui/material";
import { FaChevronRight } from "react-icons/fa6";
import BookingsTableRowProps from "../../components/BookingsTable/BookingsTableRowProps";
import useBookingsTableRow from "../../components/BookingsTable/useBookingsTableRow";
import useAreaServicableQuery from "../../hooks/useAreaServicableQuery";
import useCurrentBookingDateObj from "../../hooks/useCurrentBookingDateObj";
import getStartMomentFromDateObj from "../../utils/getStartMomentFromDateObj";
import UnassignedNurseTableRowAlert from "../../components/BookingsTable/UnassignedNurseTableRowAlert";
import DelayedNurseStartServiceTableRowAlert from "../../components/BookingsTable/DelayedNurseStartServiceTableRowAlert";

const MobileBookingListItem: FC<MobileBookingListItemProps> = ({ booking: bookingProp }) => {
  const { border, history, booking, delayedNurseData, showDelayedServiceAlert, hasUnassignedDates, borderColor, setBooking } =
    useBookingsTableRow({ booking: bookingProp });

  const { query } = useAreaServicableQuery({ lat: booking?.address?.lattitude ?? "", lng: booking?.address?.longitude ?? "" });

  const cityName = useMemo(() => {
    return query.data?.data.city?.split("-").join(" ");
  }, [query.data?.data.city]);

  const { dateObj } = useCurrentBookingDateObj({ order: booking });

  const dateMomentToShow = useMemo<moment.Moment | null>(() => {
    if (!dateObj) return null;
    return getStartMomentFromDateObj({ date: dateObj?.date ?? "", time: dateObj?.time ?? "" });
  }, [dateObj]);

  const trimService = useMemo(() => {
    const limit = 17;
    const value = booking.patientId?.[0]?.type ?? "";
    if (value?.length < limit) return value;
    return `${value?.slice(0, limit)}...`;
  }, [booking.patientId]);

  return (
    <div
      style={{
        padding: "1rem 1rem",
        position: "relative",
        paddingTop: hasUnassignedDates ? "80px" : showDelayedServiceAlert ? "100px" : "16px",
        border: border,
        borderBottom: `1px solid ${borderColor}`,
      }}
      onClick={(e) => {
        e.stopPropagation();
        history.push(`/order/${booking.orderUUID}`);
      }}
    >
      {hasUnassignedDates && (
        <UnassignedNurseTableRowAlert booking={booking} setBooking={setBooking} onAssignNurseSuccess={setBooking} />
      )}
      {showDelayedServiceAlert && delayedNurseData && <DelayedNurseStartServiceTableRowAlert data={delayedNurseData} />}
      <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", marginBottom: "4px" }}>
        <Typography color={"black"} fontWeight={500}>
          {trimService}
        </Typography>
        <div style={{ display: "flex", gap: "8px", alignItems: "center", color: "black" }}>
          <Typography fontSize={14} textAlign={"end"}>
            {dateMomentToShow?.format("DD/MM/YY hh:mm A")}
          </Typography>
          <FaChevronRight />
        </div>
      </div>
      <Typography fontSize={14} sx={{ marginBottom: "4px" }}>
        {booking.patient?.[0]?.name} {(booking.patient?.length ?? 0) > 1 ? `+ ${(booking.patient?.length ?? 0) - 1} more` : ""}
      </Typography>
      <Typography color={"black"} sx={{ height: "24px" }}>
        {cityName}
      </Typography>
    </div>
  );
};

interface MobileBookingListItemProps extends BookingsTableRowProps {}

export default MobileBookingListItem;
