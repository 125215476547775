import { Box, Typography } from "@mui/material";
import * as Sentry from "@sentry/react";
import React from "react";

class ErrorBoundary extends React.Component<ErrorBoundaryProps> {
  state = { hasError: false };

  constructor(props: ErrorBoundaryProps) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError() {
    // error: Error
    return { hasError: true };
  }

  componentDidCatch(error: Error) {
    // , info: React.ErrorInfo
    Sentry.captureException(error);
  }

  render() {
    if (this.state.hasError) {
      return (
        <Box
          display={"flex"}
          flexDirection={"column"}
          justifyContent={"center"}
          alignItems={"center"}
          width={"100%"}
          height={"100%"}
        >
          <Typography color={"black"} variant="h5" marginBottom={2}>
            Something went wrong
          </Typography>
          <Typography color={"black"}>We are investigating the issue. Please refresh the page and try again.</Typography>
        </Box>
      );
    }
    return this.props.children;
  }
}

interface ErrorBoundaryProps {
  children: React.ReactNode;
  // fallback: React.ReactNode;
}

export default ErrorBoundary;
