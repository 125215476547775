import { useQuery } from "@tanstack/react-query";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useLocation, useParams } from "react-router-dom";
import { showMessage } from "../../components/common/notification";
import { StaffMemberModel } from "../../models/StaffMemberModel";
import { nurseService } from "../../services";
import ReduxStateModel from "../../models/ReduxStateModel";

const useDriverForDriverProfile: UseDriverForDriverProfileType = (onDriverFetched) => {
  const partnerUserUUID: string | undefined = useSelector(
    (state: ReduxStateModel) => state?.authReducer?.setting?.partnerUserUUID
  );
  const location = useLocation<{ driver?: StaffMemberModel }>();
  const { id: driverUUID } = useParams<{ id?: string }>();

  const queryKey: [string, string | undefined] = ["nurses", partnerUserUUID];
  const { data, isFetching } = useQuery(queryKey, () => nurseService.getAllNurses(partnerUserUUID ?? ""), {
    enabled: !!partnerUserUUID && !location.state?.driver,
    staleTime: Infinity,
    cacheTime: Infinity,
    refetchOnWindowFocus: false,
    onSuccess: (res) => {
      if (!res?.data?.success) {
        showMessage("error", "Something went wrong");
        const driver: StaffMemberModel | undefined = res?.data?.drivers?.find(
          (driver: StaffMemberModel) => driver.driverUUID === driverUUID
        );
        if (driver) {
          onDriverFetched(driver);
        }
      }
    },
    onError: () => {
      showMessage("error", "Something went wrong");
    },
  });

  let driver: StaffMemberModel | undefined;
  if (location.state?.driver?.driverUUID) {
    driver = location.state?.driver;
  } else {
    driver = data?.data?.drivers?.find((driver: StaffMemberModel) => driver.driverUUID === driverUUID);
  }

  useEffect(() => {
    const driver: StaffMemberModel | undefined = data?.data?.drivers?.find(
      (driver: StaffMemberModel) => driver.driverUUID === driverUUID
    );
    if (!location.state?.driver && !isFetching && !driver?.driverUUID && location.pathname !== "/create-driver") {
      showMessage("error", "Something went wrong");
    }
  }, [data?.data?.drivers, driverUUID, isFetching, location.pathname, location.state?.driver]);

  useEffect(() => {
    if (location.state?.driver) {
      onDriverFetched(location.state?.driver);
    }
  }, [location.state?.driver, onDriverFetched]);

  return { driver: driver, isFetching, teamPageQueryKey: queryKey };
};

type UseDriverForDriverProfileType = (onDriverFetched: (driver: StaffMemberModel) => void) => {
  driver: StaffMemberModel | undefined;
  isFetching: boolean;
  teamPageQueryKey: [string, string | undefined];
};

export default useDriverForDriverProfile;
