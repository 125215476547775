import { StaffMemberModel } from "../../models/StaffMemberModel";

function checkIfNurseHasMissingData(nurse: StaffMemberModel): boolean {
  if (!nurse.isActive) return false;
  if (!nurse?.profileImage && !nurse?.profilePhoto) {
    return true;
  }
  if (!nurse?.firstName || nurse?.firstName === "") {
    return true;
  }
  if (!nurse?.lastName || nurse?.lastName === "") {
    return true;
  }
  if (!nurse?.dob || nurse?.dob === "") {
    return true;
  }
  if (!nurse?.sex) {
    return true;
  }
  if (!nurse?.email) {
    return true;
  }

  if (!nurse?.mobileNumber) {
    return true;
  }

  if (!nurse?.nationality) {
    return true;
  }
  if (!nurse?.languages?.length) {
    return true;
  }

  if (!nurse?.locations?.length) {
    return true;
  }

  if (!nurse?.qualification) {
    return true;
  }

  if (nurse.qualification === "REGISTERED" || nurse?.qualification === "PHYSIOTHERAPIST") {
    if (nurse?.licenseNumber === "" || !nurse?.licenseNumber) {
      return true;
    }
  }
  if (!nurse?.bio || nurse?.bio === "") {
    return true;
  }

  if (!nurse?.services?.length) {
    return true;
  }

  // if (!nurse?.activeDays?.length) {
  //   return true;
  // }

  if (!nurse?.startTime || !nurse?.endTime) {
    return true;
  }
  return false;
}

export default checkIfNurseHasMissingData;
