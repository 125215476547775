import { useQuery } from "@tanstack/react-query";
import { getPartnerUsersInvites } from "../services/partner.service";
import { useMemo } from "react";

function usePartnerUsersInvites({ partnerUUID }: { partnerUUID?: string }) {
  const queryKey = useMemo(() => {
    return ["api/v1/partner-users-invite/get-user-invites", partnerUUID];
  }, [partnerUUID]);

  const query = useQuery(queryKey, () => getPartnerUsersInvites({ partnerUUID: partnerUUID ?? "" }), { enabled: !!partnerUUID });

  return { query, queryKey };
}

export default usePartnerUsersInvites;
