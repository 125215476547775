import { FC, useState } from "react";
import NewAssignNurseModal from "../common/BookingsTable/NewAssignNurseModal";
import ShowBookingAssignedNurse from "../common/BookingsTable/ShowBookingAssignedNurse";
import BookingModel from "../../models/BookingModel";
import ShowBookingStatus from "./ShowBookingStatus";
import AssignStaffModal from "../AssignStaffModal";

const BookingStatusCellContent: FC<BookingStatusCellContentProps> = ({ booking, onAssignNurseSuccess, setBooking }) => {
  const [showAssignNurseModal, setShowAssignNurseModal] = useState(false);
  const [showSplitBookingModal, setShowSplitBookingModal] = useState(false);

  return (
    <>
      <div style={{ marginBottom: "0.5rem" }}>
        <ShowBookingStatus booking={booking} />
      </div>
      <ShowBookingAssignedNurse
        booking={booking}
        onAssignNurseClicked={() => {
          if ((booking.patientId?.length ?? 0) > 1) {
            setShowAssignNurseModal(true);
          } else {
            setShowSplitBookingModal(true);
          }
        }}
      />
      {booking?.orderUUID && (
        <NewAssignNurseModal
          open={showAssignNurseModal}
          order={booking}
          setOrder={setBooking}
          onClose={function (): void {
            setShowAssignNurseModal(false);
          }}
          onAssignNurseSuccess={onAssignNurseSuccess}
        />
      )}
      {(booking.date?.length ?? 0) > 0 && (
        <AssignStaffModal
          key={booking.orderUUID}
          open={showSplitBookingModal}
          booking={booking}
          onAssignStaffSuccess={onAssignNurseSuccess}
          onClose={function (): void {
            setShowSplitBookingModal(false);
          }}
        />
      )}
    </>
  );
};

interface BookingStatusCellContentProps {
  booking: BookingModel;
  setBooking: React.Dispatch<React.SetStateAction<BookingModel>>;
  onAssignNurseSuccess: (booking: BookingModel) => void;
}

export default BookingStatusCellContent;
