import { Divider, Paper, TextField } from "@mui/material";
import { FC, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import LoadingSpinner from "../../components/LoadingSpinner";
import useDeliveryPartnerRevenueOrdersQuery from "../../hooks/useDeliveryPartnerRevenueOrdersQuery";
import ReduxStateModel from "../../models/ReduxStateModel";
import RevenueTableListItem from "./RevenueTableListItem";
import { grey } from "@mui/material/colors";

const RevenueTable: FC<RevenueTableProps> = () => {
  const authReducer = useSelector((state: ReduxStateModel) => state.authReducer?.setting);
  const { query } = useDeliveryPartnerRevenueOrdersQuery({ partnerUUID: authReducer?.partnerUUID });
  const [searchQ, setSearchQ] = useState("");

  const ordersList = useMemo(() => {
    if (searchQ.trim().length) {
      return (
        query.data?.orders?.filter((order) => order.orderUUID?.toLocaleLowerCase().includes(searchQ.toLocaleLowerCase())) ?? []
      );
    }
    return query.data?.orders ?? [];
  }, [query.data?.orders, searchQ]);

  return (
    <LoadingSpinner
      spinning={query.isLoading}
      styles={{ minHeight: "auto", borderRadius: "12px" }}
      showBackdrop={false}
      borderRadius={12}
    >
      <Paper sx={{ marginTop: 2, borderRadius: "12px", padding: 2 }}>
        <TextField label="Search orders by ID" size="small" value={searchQ} onChange={(e) => setSearchQ(e.target.value)} />
        <Divider sx={{ marginTop: 2, borderColor: grey[400], borderWidth: "1px" }} />
        {ordersList.map((order, i) => {
          return <RevenueTableListItem key={order.orderUUID} order={order} isLast={i === ordersList.length - 1} />;
        })}
      </Paper>
    </LoadingSpinner>
  );
};

interface RevenueTableProps {}

export default RevenueTable;
