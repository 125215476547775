import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import { FC, useMemo, useState } from "react";
import { FilterPeriodKeysType } from "../Traction";
import PharmacyTractionTableSearchInput from "./PharmacyTractionTableSearchInput";
import usePharmacyTractionTable from "../../hooks/usePharmacyTractionTable";
import PharmacyTractionTableItemModel from "../../models/PharmacyTractionTableItemModel";
import { Spin } from "antd";
import moment from "moment";
import PharmacyTractionTableRow from "./PharmacyTractionTableRow";

const PharmacyTractionTable: FC<PharmacyTractionTableProps> = ({ selectedPeriod, selectedDateRange }) => {
  const [searchInput, setSearchInput] = useState<string>("");
  // const [selectedFilterService, setSelectedFilterService] = useState<PartnerEligibleProductModel | undefined>();
  const { query } = usePharmacyTractionTable(selectedPeriod, selectedDateRange);

  const filteredTableData = useMemo<PharmacyTractionTableItemModel[]>(() => {
    let tableData = query.data?.table ?? [];
    if (searchInput) {
      const normalizadSearchInput = searchInput.trim().toLocaleLowerCase();
      tableData = tableData.filter((row) => {
        if (row.orderUUID?.toLocaleLowerCase().includes(normalizadSearchInput)) {
          return true;
        } else if (row.patient?.join("").toLocaleLowerCase().includes(normalizadSearchInput)) {
          return true;
        } else if (row.product?.toLocaleLowerCase().includes(normalizadSearchInput)) {
          return true;
        }
        return false;
      });
    }
    return tableData;
  }, [query.data?.table, searchInput]);

  return (
    <Paper
      sx={{
        borderRadius: "15px",
        boxShadow: "#0000001a 0 1px 3px, #0000000f 0 1px 2px",
        width: "100%",
        height: "calc(100% - 200px)",
        maxHeight: "calc(100% - 200px)",
        display: "flex",
        flexDirection: "column",
        "& .ant-spin-nested-loading": {
          overflow: "scroll",
          height: "100%",
          maxHeight: "100%",
        },
        "& .ant-spin-container": {
          height: "100%",
          maxHeight: "100%",
        },
      }}
      elevation={0}
      className="traction-table-container"
    >
      <div style={{ padding: "16px", display: "flex", gap: "16px" }}>
        <PharmacyTractionTableSearchInput value={searchInput} onChange={setSearchInput} />
      </div>
      <Spin spinning={query.isLoading} className="">
        <TableContainer sx={{ maxHeight: "100%", height: "100%", width: "100%", overflow: "scroll" }}>
          <Table
            stickyHeader
            aria-label="sticky table"
            sx={{
              "& .booking-id-cell": {
                width: "120px",
                maxWidth: "120px",
                minWidth: "120px",
              },
              "& .date-time-cell": {
                width: "120px",
                maxWidth: "120px",
                minWidth: "120px",
              },
              "& .patient-cell": {
                width: "120px",
                maxWidth: "120px",
                minWidth: "120px",
              },
              "& .transaction-type-cell": {
                width: "160px",
                maxWidth: "160px",
                minWidth: "160px",
              },
              "& .insurance-provider-cell": {
                width: "160px",
                maxWidth: "160px",
                minWidth: "160px",
              },
              "& .invoice-amount-cell": {
                width: "140px",
                maxWidth: "140px",
                minWidth: "140px",
              },
              "& .dardoc-commision-cell": {
                width: "170px",
                maxWidth: "170px",
                minWidth: "170px",
              },
              "& .receivable-from-dardoc-cell": {
                width: "200px",
                maxWidth: "200px",
                minWidth: "200px",
              },
              "& .payable-to-dardoc": {
                width: "200px",
                maxWidth: "200px",
                minWidth: "200px",
              },
            }}
          >
            <TableHead>
              <TableRow>
                <TableCell className="booking-id-cell">Booking ID</TableCell>
                <TableCell className="date-time-cell">Date & Time</TableCell>
                <TableCell className="patient-cell">Patient</TableCell>
                <TableCell className="transaction-type-cell">Transaction Type</TableCell>
                <TableCell className="insurance-provider-cell">Insurance Provider</TableCell>
                <TableCell className="invoice-amount-cell">Invoice Amount</TableCell>
                <TableCell className="dardoc-commision-cell">DarDoc Commision</TableCell>
                <TableCell className="receivable-from-dardoc-cell">Receivable From DarDoc</TableCell>
                <TableCell className="payable-to-dardoc">Payable To DarDoc</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {filteredTableData.map((row) => {
                return <PharmacyTractionTableRow row={row} key={row.orderUUID} />;
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </Spin>
    </Paper>
  );
};

export interface PharmacyTractionTableProps {
  selectedPeriod: FilterPeriodKeysType;
  selectedDateRange?: [moment.Moment, moment.Moment];
  setSelectedDateRange: React.Dispatch<React.SetStateAction<[moment.Moment, moment.Moment] | undefined>>;
}

export default PharmacyTractionTable;
