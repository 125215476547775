import { useQueryClient } from "@tanstack/react-query";
import SchedulerExploreViewResponseModel from "../models/SchedulerExploreViewResponseModel";
import { CalendarViewType } from "../pages/SchedulerExploreView";
import useGetStaffSchedulerDayViewQueryKey from "./useGetStaffSchedulerDayViewQueryKey";
import useGetStaffSchedulerMonthViewQueryKey from "./useGetStaffSchedulerMonthViewQueryKey";
import useGetStaffSchedulerWeekViewQueryKey from "./useGetStaffSchedulerWeekViewQueryKey";

const useGetStaffSchedulerQueryData = (viewType: CalendarViewType) => {
  const queryClient = useQueryClient();

  const weekViewQueryKey = useGetStaffSchedulerWeekViewQueryKey();
  const monthViewQueryKey = useGetStaffSchedulerMonthViewQueryKey();
  const dayViewQueryKey = useGetStaffSchedulerDayViewQueryKey();
  let queryKey: (string | number | undefined)[];
  if (viewType === "week") {
    queryKey = weekViewQueryKey;
  } else if (viewType === "day") {
    queryKey = dayViewQueryKey;
  } else {
    queryKey = monthViewQueryKey;
  }

  const exploreViewData: SchedulerExploreViewResponseModel | null | undefined = queryClient.getQueryData(queryKey);

  return exploreViewData;
};

export default useGetStaffSchedulerQueryData;
