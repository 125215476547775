import { useQuery } from "@tanstack/react-query";
import { getPartnerUsers } from "../services/partner.service";
import { useMemo } from "react";

function useGetPartnerUsers({ partnerUUID }: { partnerUUID?: string }) {
  const queryKey = useMemo(() => {
    return ["/api/v1/partneraccounts/get-partner-users", partnerUUID];
  }, [partnerUUID]);

  const query = useQuery(queryKey, () => getPartnerUsers({ partnerUUID: partnerUUID ?? "" }), { enabled: !!partnerUUID });

  return { query, queryKey };
}

export default useGetPartnerUsers;
