import "@stream-io/video-react-sdk/dist/css/styles.css";
import "./styles.css";
import { PiPhoneCall } from "react-icons/pi";
import {
  Call,
  ScreenShareButton,
  SpeakerLayout,
  SpeakingWhileMutedNotification,
  StreamCall,
  StreamTheme,
  StreamVideo,
  StreamVideoClient,
  ToggleAudioPublishingButton,
  ToggleVideoPublishingButton,
} from "@stream-io/video-react-sdk";
import { FC, useEffect, useMemo, useState } from "react";
import { apiKey } from "../../utils/axiosHelpers";
import { Spin } from "antd";
import { showMessage } from "../../components/common/notification";
import useLeavePageAlert from "../../hooks/useLeavePageAlert";
import EndCallButton from "./EndCallButton";
import BookingModel from "../../models/BookingModel";

interface StreamVideoCallerProps {
  calleeIds: string[];
  user_id: string;
  callId: string;
  token: string;
  booking?: BookingModel;
}

const StreamVideoCaller: FC<StreamVideoCallerProps> = ({ calleeIds, user_id, callId, token, booking }) => {
  const [callLoading, setCallLoading] = useState(false);
  const [callRinging, setCallRinging] = useState(false);

  const userCaller = useMemo(() => ({ id: user_id }), [user_id]);

  const [client, setClient] = useState<StreamVideoClient>();
  const [call, setCall] = useState<Call>();

  useEffect(() => {
    if (!token) return;
    if (callId) {
      console.log("--- effect init call");
      setCallLoading(true);
      const myClient = new StreamVideoClient({
        apiKey,
        user: userCaller,
        token: token,
      });
      setClient(myClient);
      const myCall = myClient.call("default", callId);
      myClient.on("connection.error", (e) => {
        console.log("client connection.error", e);
      });
      myClient.on("health.check", (e) => {
        console.log("client health.check", e);
      });
      myCall
        .join({
          create: true,
          ring: true,
          data: {
            members: [
              { user_id: user_id },
              ...calleeIds.map((calleeId) => {
                return { user_id: calleeId };
              }),
            ],
          },
        })
        .catch((err) => {
          showMessage("error", "Something went wrong");
          console.error(`Failed to join the call`, err);
        })
        .finally(() => {
          setCallLoading(false);
        });
      setCall(myCall);
      const callRingEvent = () => {
        // e: StreamCallEvent
        // console.log("call.ring", e);
        setCallRinging(true);
      };
      const callMemberAdded = () => {
        // e: StreamCallEvent
        // console.log("--- call.member_added", e);
        setCallRinging(false);
      };
      const callRejected = () => {
        // e: StreamCallEvent
        // console.log("--- callRejected", e);
        setCallRinging(false);
        // showMessage("info", "Call was rejected");
      };
      myCall.on("call.rejected", callRejected);
      myCall.on("call.member_added", callMemberAdded);
      // const callEnded = (e: StreamCallEvent) => {
      //   console.log("call.ended", e);
      // };
      // myCall.on("call.ring", callRingEvent);
      // myCall.on("call.blocked_user", (e) => {
      //   console.log("call.blocked_user", e);
      // });
      // myCall.on("call.hls_broadcasting_failed", (e) => {
      //   console.log("call.hls_broadcasting_failed", e);
      // });
      // myCall.on("call.session_ended", (e) => {
      //   console.log("call.session_ended", e);
      // });
      // myCall.on("connection.error", (e) => {
      //   console.log("connection.error", e);
      // });
      // myCall.on("error", (e) => {
      //   console.log("call error", e);
      // });
      // myCall.on("health.check", (e) => {
      //   console.log("call health.check", e);
      // });
      // myCall.on("call.ended", callEnded);

      return () => {
        myClient.disconnectUser();
        myCall.stopPublish(2, true);
        myCall.stopPublish(1, true);
        const loom = document.getElementById("loom-widget");
        if (loom) loom.style.display = "";
        setClient(undefined);
        setCall(undefined);
        myCall.off("call.ring", callRingEvent);
        myCall.off("call.member_added", callMemberAdded);
        myCall.off("call.rejected", callRejected);
        // myCall.off("call.ended", callEnded);
        myCall.leave().catch((err) => {
          console.error(`Failed to leave the call`, err);
        });
        myCall.endCall();
      };
    }
  }, []);

  useLeavePageAlert({ alertMessage: "Are you sure you would like to end this call ?" });

  if (!client) return null;

  return (
    <div style={{ width: "100vw", height: "100vh", position: "relative" }}>
      {callLoading && (
        <div
          style={{
            position: "absolute",
            top: "0px",
            left: "0px",
            right: "0px",
            width: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            paddingTop: "100px",
            color: "white",
            gap: "16px",
          }}
        >
          <div>Loading</div>
          <Spin spinning={true} />
        </div>
      )}
      {callRinging && (
        <div
          style={{
            position: "absolute",
            top: "0px",
            left: "0px",
            right: "0px",
            width: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            paddingTop: "200px",
            color: "white",
            gap: "16px",
          }}
        >
          Ringing...
          <span style={{ fontSize: "20px" }}>
            <PiPhoneCall />
          </span>
        </div>
      )}
      <StreamVideo client={client}>
        <StreamTheme className="my-theme-overrides">
          {call && (
            <StreamCall call={call}>
              <SpeakerLayout />
              <div className="str-video__call-controls">
                <ScreenShareButton />
                <SpeakingWhileMutedNotification>
                  <ToggleAudioPublishingButton />
                </SpeakingWhileMutedNotification>
                <ToggleVideoPublishingButton />
                <EndCallButton
                  call={call}
                  callLoading={callLoading}
                  onLeaveCall={function (): void {
                    setCall(undefined);
                  }}
                  orderUUID={booking?.orderUUID ?? ""}
                />
              </div>
            </StreamCall>
          )}
        </StreamTheme>
      </StreamVideo>
    </div>
  );
};

export default StreamVideoCaller;
