import { Button, Dialog, DialogContent, DialogTitle } from "@mui/material";
import { FC, useCallback, useEffect, useRef, useState } from "react";
import { useMutation } from "@tanstack/react-query";
import { uploadReferenceDoc } from "../../services";
import { showMessage } from "../../components/common/notification";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import LoadingButton from "@mui/lab/LoadingButton";
import convertFileToBase64 from "../../utils/convertFileToBase64";
import VisuallyHiddenInput from "../../components/VisuallyHiddenInput";

const UploadWLPPrescriptionModal: FC<UploadWLPPrescriptionModalProps> = ({ onDocumentUploaded, onClose, open, orderUUID }) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const [selectedFile, setSelectedFile] = useState<File>();

  useEffect(() => {
    if (!open) {
      setSelectedFile(undefined);
    }
  }, [open]);

  const uploadReferenceDocMutation = useMutation({ mutationFn: uploadReferenceDoc });

  const handleInputChanged = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    if (!e.target.files?.length) {
      return console.log("--- pick file no files error");
    }
    setSelectedFile(e.target.files[0]);
  }, []);

  const handleUploadClicked = useCallback(async () => {
    const base64 = await convertFileToBase64(selectedFile);
    if (!base64) {
      return showMessage("error", "Something went wrong");
    }
    const fileType = selectedFile?.type.toLocaleLowerCase().includes("pdf") ? "pdf" : "image";
    const res = await uploadReferenceDocMutation.mutateAsync({
      orderUUID: orderUUID,
      document: {
        data: fileType === "pdf" ? base64.slice(28) : base64,
        fileName: selectedFile?.name ?? "",
        type: selectedFile?.type ?? "",
        documentType: "PRESCRIPTION",
        fileType: fileType,
      },
    });
    if (res?.success) {
      onDocumentUploaded();
      showMessage("success", "Document was uploaded successfully");
      onClose();
    } else {
      showMessage("error", "Something went wrong");
    }
  }, [onClose, onDocumentUploaded, orderUUID, selectedFile, uploadReferenceDocMutation]);

  return (
    <Dialog
      open={open}
      onClose={(e: React.MouseEvent) => {
        e.stopPropagation();
        onClose();
      }}
      aria-labelledby="upload-wlp-prescription-modal"
      maxWidth="sm"
      fullWidth={true}
    >
      <DialogTitle sx={{ textAlign: "center" }}>Upload Prescription</DialogTitle>
      <DialogContent>
        <div style={{ display: "flex", justifyContent: "center", flexDirection: "column", gap: "2rem" }}>
          <div style={{ display: "flex", justifyContent: "center" }}>
            <Button component="label" variant="outlined">
              Select a File
              <VisuallyHiddenInput type="file" accept=".jpg, .jpeg, .png, .pdf" ref={inputRef} onChange={handleInputChanged} />
            </Button>
          </div>
          {selectedFile && (
            <div style={{ textAlign: "center", fontWeight: "bold", marginBottom: "2rem" }}>{selectedFile.name}</div>
          )}
          <LoadingButton
            loading={uploadReferenceDocMutation.isLoading}
            onClick={handleUploadClicked}
            disabled={!selectedFile}
            startIcon={<FileUploadIcon />}
            variant="contained"
            sx={{ width: "100%" }}
          >
            Upload
          </LoadingButton>
        </div>
      </DialogContent>
    </Dialog>
  );
};

interface UploadWLPPrescriptionModalProps {
  open: boolean;
  orderUUID: string;
  onDocumentUploaded: () => void;
  onClose: () => void;
}

export default UploadWLPPrescriptionModal;
