import { FC, useCallback, useMemo, useState } from "react";
import { Route, Switch, useHistory, useRouteMatch } from "react-router-dom";
import PharmacyBasePageLayout from "../../components/PharmacyBasePageLayout";
import PharmacyTopBar from "./PharmacyTopBar";
import BoardView from "./BoardView";
import PharmacyRevenueTracker from "../PharmacyRevenueTracker";
import PharmacyDetailsDrawer from "../../components/PharmacyDetailsDrawer";

const PharmacyPartnerHome: FC = () => {
  const history = useHistory();
  const { path } = useRouteMatch();
  const [tabIndex, setTabIndex] = useState(0);
  const value = tabIndex; // mapTopBarTabToIndex[location.pathname] ?? 0;

  const handleTabChanged = useCallback((index: number) => {
    setTabIndex(index);
  }, []);

  const content = useMemo(() => {
    if (tabIndex === 0) {
      return <BoardView />;
    } else if (tabIndex === 1) {
      return <PharmacyRevenueTracker />;
    }
  }, [tabIndex]);

  return (
    <PharmacyBasePageLayout
      mainContent={
        <>
          <PharmacyTopBar value={value} onChange={handleTabChanged} />
          {content}
          <Switch>
            <Route
              path={`${path}/order-details/:orderUUID`}
              render={() => {
                return (
                  <PharmacyDetailsDrawer
                    onClose={function (): void {
                      history.goBack();
                    }}
                  />
                );
              }}
            />
          </Switch>
        </>
      }
    />
  );
};

export default PharmacyPartnerHome;
