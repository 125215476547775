import { useQuery } from "@tanstack/react-query";
import { nurseService } from "../services";
import { useMemo } from "react";
import { ProfessionalServedHoursResponseModel } from "../models/ProfessionalServedHoursResponseModel";

function useNurseServedHoursQuery(nurseUUID?: string) {
  const queryKey = useMemo(() => ["nurses/getservedhours", nurseUUID], [nurseUUID]);

  const query = useQuery<ProfessionalServedHoursResponseModel>(
    queryKey,
    () => nurseService.getServedHoursForNurse(nurseUUID ?? ""),
    {
      enabled: !!nurseUUID,
      staleTime: Infinity,
      cacheTime: Infinity,
      refetchOnWindowFocus: false,
    }
  );

  return { query, queryKey };
}

export default useNurseServedHoursQuery;
