import { FC, useCallback, useMemo, useState } from "react";
import { useQueryClient } from "@tanstack/react-query";
import { GetPartnerOrdersResponseModel } from "../../services";
import { useInView } from "react-intersection-observer";
import SearchBookingsInput, { SearchBookingsInputValue } from "../../components/SearchBookingsInput";
import SearchAndFilterBookingButton, { FilterBookingsType } from "../../components/SearchAndFilterBookingButton";
import usePartnerOrders from "../../hooks/usePartnerOrders";
import ShowSelectedFilters from "./ShowSelectedFilters";
import BookingsTable from "../../components/BookingsTable";
import BookingModel, { BookingStatusType } from "../../models/BookingModel";
import useSearchOrderData from "../../hooks/useSearchOrderData";
import useSortOrdersQuery from "../../hooks/useSortOrdersQuery";
import useWindowDimensions from "../../hooks/useWindowDimensions";
import MobileBookingsList from "./MobileBookingsList";

const AcceptedBookings: FC<AcceptedBookingsProps> = ({ status, isCurrentTab }) => {
  const queryClient = useQueryClient();
  const [searchBookingInput, setSearchBookingInput] = useState<SearchBookingsInputValue>();
  const [filterValue, setFilterValue] = useState<FilterBookingsType>();
  const inViewRes = useInView({});
  const pageNumber = 1;

  const { query: partnerOrdersQuery, queryKey: partnerOrdersQueryKey } = usePartnerOrders({
    status: status,
    loadMoreElementInView: inViewRes.inView,
    enabled: isCurrentTab,
  });

  const { query: searchOrdersQuery, queryKey: searchOrdersQueryKey } = useSearchOrderData(
    searchBookingInput?.type,
    searchBookingInput?.value,
    pageNumber
  );

  const { query: sortQuery, queryKey: sortQueryOrdersKey } = useSortOrdersQuery({
    pageNumber: pageNumber,
    type:
      filterValue?.sort === "booking-date-ascending"
        ? "ASCENDING"
        : filterValue?.sort === "booking-date-decsending"
        ? "DESCENDING"
        : undefined,
    value: { endDate: "", startDate: "" },
    sortUnreportedOrdersOnly: true,
    filter: {
      nurse: filterValue?.selectedFilterNurses?.map((nurse) => nurse.nurseUUID ?? "") ?? [],
      service: filterValue?.selectedFilterProducts?.map((prod) => prod.productUUID ?? "") ?? [],
    },
    view: status === "ACCEPTED" ? "ACCEPT" : status === "PROCESSING" ? "ASSIGN" : "REPORT",
  });

  const handleApplyFilter = useCallback((params: FilterBookingsType) => {
    setFilterValue(params);
  }, []);

  const handleClearFilter = useCallback(() => {
    setFilterValue(undefined);
  }, []);

  const handleSearchInputClicked = useCallback((params: SearchBookingsInputValue) => {
    setSearchBookingInput(params);
    setFilterValue(undefined);
  }, []);

  const handleClearSearchInputs = useCallback(() => {
    setSearchBookingInput(undefined);
  }, []);

  const handleNurseAssignedSuccess = useCallback(
    (booking: BookingModel) => {
      queryClient.setQueryData(partnerOrdersQueryKey, (data: GetPartnerOrdersResponseModel | null | undefined) => {
        if (!data) return data;
        const newValue: GetPartnerOrdersResponseModel = JSON.parse(JSON.stringify(data));
        newValue.orders = newValue.orders?.map<BookingModel>((order) => {
          if (order.orderUUID === booking.orderUUID) {
            return booking;
          }
          return order;
        });
        return newValue;
      });
    },
    [queryClient, partnerOrdersQueryKey]
  );

  const handleInvalidateQueries = useCallback(() => {
    queryClient.invalidateQueries(partnerOrdersQueryKey);
    queryClient.invalidateQueries(searchOrdersQueryKey);
    queryClient.invalidateQueries(sortQueryOrdersKey);
  }, [partnerOrdersQueryKey, queryClient, searchOrdersQueryKey, sortQueryOrdersKey]);

  const ordersList = useMemo(() => {
    if (searchBookingInput?.value) {
      return searchOrdersQuery.data?.orders ?? [];
    } else if (Object.keys(filterValue ?? {}).length) {
      return sortQuery.data?.data.orders ?? [];
    }
    const orders: BookingModel[] = [];
    for (const page of partnerOrdersQuery.data?.pages ?? []) {
      orders.push(...(page?.orders ?? []));
    }
    return orders;
  }, [
    filterValue,
    partnerOrdersQuery.data?.pages,
    searchBookingInput?.value,
    searchOrdersQuery.data?.orders,
    sortQuery.data?.data.orders,
  ]);

  const { isMobile } = useWindowDimensions();

  return (
    <div style={{ paddingBottom: "8px", maxHeight: "100%", display: "flex", flexDirection: "column" }}>
      <div style={{ display: "flex", justifyContent: "space-between", marginBottom: "16px", flexWrap: "wrap" }}>
        <SearchBookingsInput
          selectedSearchValues={searchBookingInput}
          onSearchClick={handleSearchInputClicked}
          onClearSearchClicked={handleClearSearchInputs}
        />
        <SearchAndFilterBookingButton
          onApplyFilterClicked={handleApplyFilter}
          onClearFilterClicked={handleClearFilter}
          initialValues={filterValue}
        />
      </div>
      <ShowSelectedFilters setFilterValue={setFilterValue} filterValue={filterValue} />
      {isMobile ? (
        <MobileBookingsList
          inViewRes={inViewRes}
          bookings={ordersList}
          onAssignNurseSuccess={handleNurseAssignedSuccess}
          handleInvalidateQueries={handleInvalidateQueries}
          loadingNextPage={partnerOrdersQuery.isFetchingNextPage}
          loading={partnerOrdersQuery.isLoading || searchOrdersQuery.isFetching || sortQuery.isFetching}
        />
      ) : (
        <BookingsTable
          inViewRes={inViewRes}
          bookings={ordersList}
          onAssignNurseSuccess={handleNurseAssignedSuccess}
          handleInvalidateQueries={handleInvalidateQueries}
          loadingNextPage={partnerOrdersQuery.isFetchingNextPage}
          loading={partnerOrdersQuery.isLoading || searchOrdersQuery.isFetching || sortQuery.isFetching}
        />
      )}
    </div>
  );
};

interface AcceptedBookingsProps {
  status: BookingStatusType;
  isCurrentTab: boolean;
}

export default AcceptedBookings;
