import { FC, useState, useCallback } from "react";
import { Button } from "@mui/material";
import BookingModel from "../../models/BookingModel";
import useProductDetails from "../../hooks/useProductDetails";
import UploadBookingReportModal from "./UploadBookingReportModal";
import useCurrentUserRole from "../../hooks/useCurrentUserRole";

const UploadBookingReportButton: FC<UploadBookingReportButtonProps> = ({ booking, onReportedUploadedSuccess, setBooking }) => {
  const { canUpdate } = useCurrentUserRole();
  const [showModal, setShowModal] = useState(false);

  const { query: productDetailsQuery } = useProductDetails({
    area: booking.emirate,
    productUUID: booking.patientId?.[0]?.productUUID,
  });

  const handleCloseModal = useCallback(() => {
    setShowModal(false);
  }, []);

  const handleReportUploadedSuccessfully = useCallback(
    (order: BookingModel) => {
      if (setBooking) {
        setBooking((value) => {
          for (const patient of value.patient ?? []) {
            const patientId = order.patientId?.find((p) => p.id === patient._id);
            patient.reports = patientId?.reports;
          }
          return { ...value, ...order };
        });
      }
      if (onReportedUploadedSuccess) {
        onReportedUploadedSuccess();
      }
    },
    [onReportedUploadedSuccess, setBooking]
  );

  if (!canUpdate) return null;
  if (!productDetailsQuery.data?.product?.isDarLabProduct) return null;
  if (!booking.isSampleCollected) return null;
  if (booking.status === "REPORTED") return null;

  return (
    <>
      <Button
        variant="contained"
        onClick={(e) => {
          e.stopPropagation();
          setShowModal(true);
        }}
        sx={{
          "@media (max-width: 700px)": {
            width: "100%",
          },
        }}
        disableElevation={true}
      >
        <span style={{ color: "white" }}>Submit Reports</span>
      </Button>
      <UploadBookingReportModal
        booking={booking}
        open={showModal}
        onClose={handleCloseModal}
        onReportUploadedSuccess={handleReportUploadedSuccessfully}
      />
    </>
  );
};

interface UploadBookingReportButtonProps {
  booking: BookingModel;
  onReportedUploadedSuccess: (() => void) | null;
  setBooking?: React.Dispatch<React.SetStateAction<BookingModel>>;
}

export default UploadBookingReportButton;
