import { useQuery } from "@tanstack/react-query";
import { getTRTMedicationsList } from "../services";
import { useMemo } from "react";

export default function useTRTMedicationsListQuery({ location, orderUUID }: { location?: string; orderUUID?: string }) {
  const queryKey = useMemo(() => {
    return ["api/v1/apps/get-trt-medication-list", location, orderUUID];
  }, [location, orderUUID]);

  const query = useQuery(queryKey, () => getTRTMedicationsList({ location: location ?? "", orderUUID: orderUUID ?? "" }), {
    enabled: !!location && !!orderUUID,
  });

  return { query, queryKey };
}
