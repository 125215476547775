import moment from "moment";
import axios from "../utils/axiosHelpers";
import decrypt from "./decrypt";
import encrypt from "./encrypt";
import SchedulerExploreViewResponseModel from "../models/SchedulerExploreViewResponseModel";
import ScheduleBookingForNurseModel from "../models/ScheduleBookingForNurseModel";
import SchedulerDetailViewResponseModel from "../models/SchedulerDetailViewResponseModel";

export async function removeNurseBookingFromSchedule(body: {
  nurseUUID: string;
  date: string;
  time: string;
  nurseOrderID?: string;
}): Promise<{
  success: number | boolean;
}> {
  try {
    const res = await axios.post<string>("/api/v1/nursetimeslots/removenursetimeslotsfromscheduler", { data: encrypt(body) });
    const data: { success: number | boolean } = decrypt(res.data);
    // console.log("--- removenursetimeslotsfromscheduler", body, data);
    return data;
  } catch (e) {
    console.log("--- removeNurseBookingFromSchedule error");
    console.log(e);
    return { success: false };
  }
}

export async function getNurseTimeSlots(params: {
  nurseUUID: string;
  startDate: moment.Moment;
  endDate: moment.Moment;
}): Promise<SchedulerDetailViewResponseModel | undefined> {
  try {
    const body = {
      nurseUUID: params.nurseUUID,
      startDate: params.startDate.format("DD/MM/YYYY"),
      endDate: params.endDate.format("DD/MM/YYYY"),
    };
    const res = await axios.post<string>("/api/v1/nursetimeslots/getparticularnursedatawithtimeslot", { data: encrypt(body) });
    const data: SchedulerDetailViewResponseModel = decrypt(res.data);
    // console.log("--- getparticularnursedatawithtimeslot", data);
    return data;
  } catch (e) {
    console.log("--- getNurseTimeSlots error");
    console.log(e);
    return undefined;
  }
}

export async function bookNurseSlot(body: ScheduleBookingForNurseModel): Promise<{ success: number | boolean } | undefined> {
  try {
    const res = await axios.post<string>("/api/v1/nursetimeslots/updatenursetimeslotsfromscheduler", { data: encrypt(body) });
    const data: { success: number | boolean } = decrypt(res.data);
    // console.log("--- bookNurseSlot", data);
    return data;
  } catch (e) {
    console.log("--- bookNurseSlot error");
    console.log(e);
    return undefined;
  }
}

export async function getAllNurseDataWithTimeSlots(params: {
  partnerUUID: string;
  startDate: moment.Moment;
  endDate: moment.Moment;
}): Promise<SchedulerExploreViewResponseModel | null> {
  try {
    if (!params.partnerUUID) return null;
    const body = {
      partnerUUID: params.partnerUUID,
      startDate: params.startDate.format("DD/MM/YYYY"),
      endDate: params.endDate.format("DD/MM/YYYY"),
    };
    const res = await axios.post<string>(`/api/v1/nursetimeslots/getallnursedatawithtimeslot`, { data: encrypt(body) });
    const data: SchedulerExploreViewResponseModel = decrypt(res.data);
    // console.log({ partnerUUID: params.partnerUUID, startDate: params.startDate.format(), endDate: params.endDate.format() });
    // console.log("--- getallnursedatawithtimeslot", data);
    // console.log(body);
    ////////////////////////////////////////////////////////////////////////////////
    // for (const nurse of data.nursesData ?? []) {
    //   nurse.data?.sort((date1, date2) => {
    //     const dateFormat = "DD/MM/YYYY";
    //     const moment1 = moment(date1.date, dateFormat);
    //     const moment2 = moment(date2.date, dateFormat);
    //     if (moment1.valueOf() > moment2.valueOf()) {
    //       return 1;
    //     } else if (moment1.valueOf() < moment2.valueOf()) {
    //       return -1;
    //     }
    //     return 0;
    //   });
    // }
    ////////////////////////////////////////////////////////////////////////////////
    // console.log("--- getallnursedatawithtimeslot", data);
    return data;
  } catch (e) {
    console.log("--- getAllNurseDataWithTimeSlots");
    console.log(e);
    return null;
  }
}
