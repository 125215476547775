export const saveUserToken = (token) => {
  localStorage.setItem("token", token);
};

export const saveUserName = (userName) => {
  localStorage.setItem("userName", userName);
};

export const saveSetting = (setting) => {
  if (setting?.passwordDigest) {
    delete setting.passwordDigest;
  }
  localStorage.setItem(
    "setting",
    JSON.stringify({
      userName: setting.userName,
      role: setting.role,
      partnerUserUUID: setting.partnerUserUUID,
      xeroContactId: setting.xeroContactId,
      email: setting.email,
      city: setting.city,
      didForceLogout: setting.didForceLogout,
      isActive: setting.isActive,
      name: setting.name,
      partnerUUID: setting.partnerUUID,
      phoneNumber: setting.phoneNumber,
    })
  );
};

export const saveNurse = (nurse) => {
  localStorage.setItem("nurse", JSON.stringify(nurse));
};

export const saveUserRole = (role) => {
  localStorage.setItem("role", JSON.stringify(role));
};

export const savePartnerUserUUID = (partnerUserUUID) => {
  localStorage.setItem("partnerUserUUID", JSON.stringify(partnerUserUUID));
};
