import { authType } from "../types";
import { authService, partnerEditService } from "../services";
import { setAuthorizationToken } from "../utils/axiosHelpers";
import { saveUserToken, saveUserName, saveSetting, saveUserRole, savePartnerUserUUID } from "./localStorage.action";
import { showMessage } from "../components/common/notification";

export const authAction = {
  userLogin,
  setPassword,
  getUserData,
  saveUserData,
  userLogout,
  upadteDeviceId,
  readNotification,
  deleteNotification,
  changePartnerStatus,
  getPassowrdResetLink,
};

function upadteDeviceId(body) {
  return () => {
    authService.upadteDeviceId(body);
  };
}

function userLogin(body, onSuccess) {
  return (dispatch) => {
    // console.log(authService.userLogin(body));
    authService.userLogin(body).then(
      (response) => {
        if (response.data.success) {
          dispatch(success(response.data.settings));

          if (
            // response?.data?.token &&
            response?.data?.settings?.role &&
            response?.data?.settings?.partnerUserUUID &&
            // response?.data?.username &&
            response?.data?.settings
          ) {
            // saveUserToken(response.data.token);

            saveUserRole(response.data.settings.role);

            savePartnerUserUUID(response.data.settings.partnerUserUUID);

            //saveUserName(response.data.username);

            saveSetting(response.data.settings);
            // showMessage("success", "You are logged in!");
          } else {
            showMessage("error", "Something went wrong!");
          }
          setAuthorizationToken();
          if (onSuccess) {
            onSuccess(response);
          }
        } else {
          if (response?.data?.userActive === false) {
            showMessage("error", "Inactive user found, please try again!");
            if (onSuccess) {
              onSuccess(response);
            }
          } else {
            showMessage("error", "Invalid credentials, please try again!");
            if (onSuccess) {
              onSuccess(response);
            }
          }
        }
      },
      () => {}
    );
  };

  function success(data) {
    return { type: authType.SAVE_SETTINGS, data };
  }
}

function setPassword(body, onSuccess) {
  return (dispatch) => {
    authService.setPassword(body).then(
      (response) => {
        if (response?.data?.success) {
          showMessage("success", "New password set successfully!");
          if (response?.data?.userActive === false) {
            showMessage("error", "User found inactive, please contact admin!");
            if (onSuccess) {
              onSuccess(response);
            }
            return;
          }
          dispatch(success(response?.data?.settings));

          if (
            response?.data?.token &&
            response?.data?.settings?.role &&
            response?.data?.settings?.partnerUserUUID &&
            response?.data?.username &&
            response?.data?.settings
          ) {
            showMessage("success", "You are logged in!");

            saveUserToken(response?.data?.token);

            saveUserRole(response.data?.settings?.role);

            savePartnerUserUUID(response.data?.settings?.partnerUserUUID);

            saveUserName(response?.data?.username);

            saveSetting(response?.data?.settings);
          } else {
            showMessage("error", "Something went wrong!");
          }
          setAuthorizationToken();
          if (onSuccess) {
            onSuccess(response);
          }
        } else {
          if (onSuccess) {
            onSuccess(response);
          }
          showMessage("error", "Failed to save new password, please try again!");
        }
      },
      () => {}
    );
  };

  function success(data) {
    return { type: authType.SAVE_SETTINGS, data };
  }
}

function getPassowrdResetLink(email, onSuccess) {
  return () => {
    authService.getPassowrdResetLink(email).then((response) => {
      if (response.data.success) {
        showMessage("success", "Reset password link sent!");
      } else {
        showMessage("error", "Failed to send reset password link, please try again!");
      }

      if (onSuccess) {
        onSuccess(response.data.success);
      }
    });
  };
}

function getUserData(body, onSuccess) {
  return (dispatch) => {
    authService.getUserData(body).then(
      (response) => {
        if (response?.data?.success) {
          if (onSuccess) {
            onSuccess(response);
          }
          if (response?.data?.settings?.partnerUUID) {
            dispatch(success(response.data.settings));
            saveSetting(response.data.settings);
          }
        }
      },
      () => {}
    );
  };

  function success(data) {
    return { type: authType.SAVE_SETTINGS, data };
  }
}

function changePartnerStatus(body, onSuccess) {
  return () => {
    partnerEditService.managePartnerStatus(body).then((res) => {
      if (onSuccess) {
        onSuccess(res);
      }

      if (res?.data?.success === 1) {
        showMessage("success", "Partner active status updated!");
      } else {
        showMessage("error", "Failed to update partner active status!");
      }
    });
  };
}

function saveUserData(data) {
  return (dispatch) => {
    dispatch(success(data));
    saveSetting(data);
  };

  function success(data) {
    return { type: authType.SAVE_SETTINGS, data };
  }
}

function userLogout(body, onSuccess) {
  return (dispatch) => {
    authService.deleteDeviceId(body);
    dispatch(logout());
    localStorage.clear();
    onSuccess();
    showMessage("success", "You are logged out!");
  };

  function logout() {
    return { type: "LOGOUT" };
  }
}

function readNotification(body, onSucess) {
  return () => {
    authService.readNotification(body).then(() => {
      if (onSucess) {
        onSucess();
      }
    });
  };
}

function deleteNotification(body, onSucess) {
  return () => {
    authService.deleteNotification(body).then(() => {
      if (onSucess) {
        onSucess();
      }
    });
  };
}
