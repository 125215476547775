import moment from "moment";
import { Button } from "antd";
import { AiOutlinePlus } from "react-icons/ai";
import { useMemo, FC } from "react";
import { useHistory, useRouteMatch } from "react-router-dom";
import { getNursePatientsList, NurseBookingModel } from "../../models/SchedulerExploreViewResponseModel";
import { NursesDatum } from "../../models/SchedulerExploreViewResponseModel";
import WeekViewBookingTile from "./WeekViewBookingTile";
import useCurrentUserRole from "../../hooks/useCurrentUserRole";
import useNurseDetails from "../../hooks/useNurseDetails";

const TableCell: FC<TableCellProps> = ({ nurseData, cellMoment, bookings }) => {
  const history = useHistory();
  const { url } = useRouteMatch();
  const { canUpdate } = useCurrentUserRole();

  const disableClick = useMemo<boolean>(() => {
    return cellMoment.isBefore(moment().startOf("day")) || !canUpdate;
  }, [canUpdate, cellMoment]);

  const { query } = useNurseDetails({ nurseUUID: nurseData.nurseUUID });

  const isOnLeave = useMemo<boolean>(() => {
    const cellWeekDayName = cellMoment.format("dddd").toLocaleLowerCase();
    if (!query.data?.nurse?.activeDays?.includes(cellWeekDayName)) {
      return true;
    }
    const value = nurseData.holidays?.some((offDate) => offDate === cellMoment.format("DD/MM/YYYY")) ?? false;
    return value;
  }, [cellMoment, nurseData.holidays, query.data?.nurse?.activeDays]);

  const handleAssignNurseClicked = () => {
    if (disableClick) return;
    history.push(`${url}/assign-patients`, {
      initialSelectedDate: cellMoment.valueOf(),
      nurseUUID: nurseData.nurseUUID,
      patientsList: getNursePatientsList(nurseData),
      nurseData: nurseData,
    });
  };

  const handleRemoveNurseLeaveClicked = () => {
    // { nurseData: NursesDatum; patientsList: SlotPatientModel[]; initialSelectedDate: number }
    history.push("/scheduler/remove-nurse-on-leave", {
      nurseData,
      patientsList: getNursePatientsList(nurseData),
      initialSelectedDate: cellMoment.valueOf(),
    });
  };

  const buildOnLeaveContent: (text?: string) => JSX.Element = (text) => {
    return (
      <div
        style={{
          width: "100%",
          height: "100%",
          display: "flex",
          flexDirection: "column",
          gap: "0.3rem",
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignContent: "center",
            flexDirection: "column",
            width: "100%",
            gap: "0.3rem",
          }}
        >
          <div className="on-leave-cell-content" onClick={handleRemoveNurseLeaveClicked}>
            <div style={{ fontWeight: "600", marginBottom: "0.3rem" }}>{text ?? "On Leave"}</div>
          </div>
        </div>
      </div>
    );
  };

  let content;
  if (bookings) {
    content = (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignContent: "center",
          flexDirection: "column",
          width: "100%",
          gap: "0.3rem",
        }}
      >
        {bookings.map<JSX.Element>((booking, index) => {
          const renderSmallTile = bookings.length >= 3 || booking.type === "BREAK";
          return (
            <WeekViewBookingTile
              key={booking.nurseOrderID ?? `${booking.nurseUUID}_${booking.startMoment.valueOf()}_${index}`}
              booking={booking}
              index={index}
              renderSmallTile={renderSmallTile}
              nurseData={nurseData}
            />
          );
        })}
        {isOnLeave && buildOnLeaveContent("On leave for rest of the day")}
        {!disableClick && !isOnLeave && (
          <Button className="add-patient-btn" onClick={disableClick ? undefined : handleAssignNurseClicked}>
            <AiOutlinePlus />
          </Button>
        )}
      </div>
    );
  } else if (isOnLeave) {
    content = buildOnLeaveContent();
  } else if (!disableClick) {
    content = (
      <div
        onClick={disableClick ? undefined : handleAssignNurseClicked}
        className={disableClick ? "" : "cursor-pointer"}
        style={{
          width: "100%",
          height: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <AiOutlinePlus />
      </div>
    );
  } else {
    content = null;
  }

  return (
    <div className="cell empty-cell" id={`table-cell-${nurseData.nurseUUID}-${cellMoment.format()}`}>
      {content}
    </div>
  );
};

interface TableCellProps {
  nurseData: NursesDatum;
  cellMoment: moment.Moment;
  bookings: NurseBookingModel[] | undefined;
}

export default TableCell;
