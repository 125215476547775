import BookingModel, { AssignedBookingNurseModel, BookingDateModel, BookingPatientModel } from "../../../models/BookingModel";
import { Col, Row, Select } from "antd";
import { FC, useMemo } from "react";
import { StaffMemberModel } from "../../../models/StaffMemberModel";
import useCurrentUserRole from "../../../hooks/useCurrentUserRole";

const MultiplePatientsDateCardSelect: FC<MultiplePatientsDateCardSelectProps> = ({
  patient,
  disabled,
  nurseSelectOptions,
  order,
  bookingDate,
  onPatientNurseSelected,
}) => {
  const { canUpdate } = useCurrentUserRole();

  const bookingAssignedNursesMap = useMemo<Map<string, AssignedBookingNurseModel>>(() => {
    const map = new Map<string, AssignedBookingNurseModel>();
    for (const nurse of order.assignedNurses ?? []) {
      map.set(nurse.nurseUUID ?? "", nurse);
    }
    return map;
  }, [order.assignedNurses]);

  const value = useMemo<string | undefined>(() => {
    let nurseUUID: string | undefined;
    for (const assignedNurse of order.assignedNurses ?? []) {
      if (
        assignedNurse.patients?.includes(patient._id ?? "") &&
        assignedNurse.dateArray?.some((date) => date.date === bookingDate.date)
      ) {
        nurseUUID = assignedNurse.nurseUUID;
      }
    }
    return nurseUUID;
  }, [bookingDate.date, order.assignedNurses, patient._id]);

  return (
    <Row key={patient._id} style={{ alignItems: "flex-start", gap: "0.3rem" }}>
      <Col span={24}>
        <p style={{ color: "gray", margin: 0 }}>
          Nurse for <span style={{ color: "black", fontWeight: "500" }}>{patient.name}:</span>
        </p>
      </Col>
      <Col span={24}>
        <div style={{ color: "#25bcbd" }}>
          <Select
            showSearch
            disabled={disabled || !canUpdate}
            placeholder="Select a nurse"
            optionFilterProp="children"
            style={{ width: "100%" }}
            onChange={(value) => onPatientNurseSelected({ nurseUUID: value, patient })}
            value={value}
            filterOption={(input, option) => {
              return (option?.textValue ?? "").toLowerCase().includes(input.toLowerCase());
            }}
          >
            {nurseSelectOptions
              ?.filter((nurse) => {
                const bookingAssignedNurse = bookingAssignedNursesMap.get(nurse.nurseUUID ?? "");
                if (!bookingAssignedNurse) return true;
                if (!bookingAssignedNurse.patients?.some((patientID) => patientID === patient._id)) return false;
                return true;
              })
              .map((nurse) => {
                const name = `${nurse.firstName} ${nurse.lastName}`;
                return (
                  <Select.Option key={nurse.nurseUUID} value={nurse.nurseUUID} textValue={name}>
                    <Row
                      style={{
                        alignItems: "center",
                        gap: "0.5rem",
                      }}
                    >
                      <Col>
                        <img
                          src={nurse.profileImage}
                          alt={name}
                          style={{
                            width: "25px",
                            height: "25px",
                            borderRadius: "50%",
                            objectFit: "cover",
                          }}
                        />
                      </Col>
                      <Col>
                        <p style={{ margin: 0 }}>{name}</p>
                      </Col>
                    </Row>
                  </Select.Option>
                );
              })}
          </Select>
        </div>
      </Col>
    </Row>
  );
};

interface MultiplePatientsDateCardSelectProps {
  order: BookingModel;
  bookingDate: BookingDateModel;
  patient: BookingPatientModel;
  disabled: boolean;
  nurseSelectOptions: StaffMemberModel[];
  onPatientNurseSelected: (params: { nurseUUID: string; patient: BookingPatientModel }) => void;
}

export default MultiplePatientsDateCardSelect;
