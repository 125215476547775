import moment from "moment";
import schedulerDetailViewTypes from "../types/schedulerDetailViewTypes";
import { getBookinsList } from "../models/SchedulerExploreViewResponseModel";
import { DetailViewSelectedSlotModel } from "../reducer/schedulerDetailViewReducer";
import SchedulerDetailViewResponseModel from "../models/SchedulerDetailViewResponseModel";

export const setSelectedSlotsAction = (params: { response: SchedulerDetailViewResponseModel; nurseUUID: string }) => {
  const listOfBookings = getBookinsList(params.response.nurseData).filter((item) => item.nurseUUID === params.nurseUUID);
  const selectedBookings: DetailViewSelectedSlotModel = {};
  for (const booking of listOfBookings) {
    const currentDate = booking.startMoment.clone();
    while (currentDate.isBefore(booking.endMoment)) {
      selectedBookings[currentDate.format()] = booking; // true;
      currentDate.add(30, "minute");
    }
  }
  return {
    type: schedulerDetailViewTypes.SET_DETAIL_VIEW_SELECTED_SLOTS,
    payload: selectedBookings,
  };
};

export const setSelectedWeekAction = (weekStart: moment.Moment) => {
  return {
    type: schedulerDetailViewTypes.DETAIL_VIEW_SET_SELECTED_WEEK,
    payload: weekStart,
  };
};
