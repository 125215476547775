import { FC, useMemo, useState } from "react";
import { DatePicker, Input, Spin } from "antd";
import { FiSearch } from "react-icons/fi";
import { GeneratedDriverScheduleItem } from "../../services";
import { useDispatch, useSelector } from "react-redux";
import { driverSchedulerTypes } from "../../types";
import { DriverScheduleTableData } from "../../models/OurBookingsResponseModel";
import { FaChevronDown } from "react-icons/fa";
import { StaffMemberModel } from "../../models/StaffMemberModel";
import { AutoNavCellsListProps } from "./mapGeneratedScheduleResponseToCardGroupsArray";
import { Box } from "@mui/material";
import AutoNavCell from "./AutoNavCell";
import moment from "moment";
import useAutomatedDriverScheduleQuery from "../../hooks/useAutomatedDriverScheduleQuery";
import DriverSettingsDrawer from "./DriverSettingsDrawer";
import DriverSettingsDropdownButton from "./DriverSettingsDropdownButton";
import DownloadDriverScheduleExcelButton from "../AutoGeneratedDriverSchedule/DownloadDriverScheduleExcelButton";
import ShowTimelineButton from "./ShowTimelineButton";
import CopyScheduleButton from "./CopyScheduleButton";
import SendDriverScheduleNotificationButton from "./SendDriverScheduleNotificationButton";
import useAllPartnerNurses from "../../hooks/useAllPartnerNurses";
import ReduxStateModel from "../../models/ReduxStateModel";
import AutoNavMobileControls from "./AutoNavMobileControls";

const AutoNavCellsList: FC<AutoNavCellsListProps> = () => {
  const dispatch = useDispatch();
  const selectedDate = useSelector((state: ReduxStateModel) => state?.driverSchedulerReducer?.selectedDate ?? moment());
  const [searchValue, setSearchValue] = useState<string>("");
  const [openSettingsDrawer, setOpenSettingsDrawer] = useState<boolean>(false);

  const {
    query: { isLoading: generatedScheduleIsLoading, data: generatedScheduleData },
  } = useAutomatedDriverScheduleQuery({
    selectedDate,
  });

  const tableData = useMemo<DriverScheduleTableData[]>(() => {
    const data: DriverScheduleTableData[] = [];
    for (const driverName of Object.keys(generatedScheduleData ?? {})) {
      const driverBookings = generatedScheduleData![driverName];
      driverBookings.forEach((booking) => {
        data.push({
          date: selectedDate.format("DD/MM/YYYY"),
          key: `${booking.bookingUUID}-${booking.driverName}-${selectedDate.format("DD/MM/YYYY")}-${booking.time}-${
            booking.driverName
          }`,
          nurse: booking.nurseName,
          orderUUID: booking.bookingUUID,
          time: booking.time,
          toAddressLink: booking.locationLink,
          toAddress: booking.address,
          type: booking.type,
          driver: driverName,
          pickupTime: booking.time,
          distance: undefined,
          fromAddress: undefined,
          fromAddressLink: undefined,
          fromArea: undefined,
          toArea: undefined,
          minutes: undefined,
        });
      });
    }
    return data;
  }, [generatedScheduleData, selectedDate]);

  const cardGroups = useSelector((state: ReduxStateModel) => state?.driverSchedulerReducer?.tasksCardGroups ?? []);

  const filteredCardGroups = useMemo<GeneratedDriverScheduleItem[][]>(() => {
    if (!searchValue.trim()) return cardGroups;
    const searchQ = searchValue.toLowerCase();
    return cardGroups.filter((arr) => {
      return arr.some((task) => {
        return task.nurseName.toLowerCase().includes(searchQ) || task.driverName.toLowerCase().includes(searchQ);
      });
    });
  }, [cardGroups, searchValue]);

  const { query } = useAllPartnerNurses();

  const allStaffMembersList = useMemo<StaffMemberModel[]>(() => {
    const list: StaffMemberModel[] = [...(query.data?.data?.nurses ?? []), ...(query.data?.data?.drivers ?? [])];
    return list;
  }, [query.data?.data?.drivers, query.data?.data?.nurses]);

  const allStaffMembersNamesMap = useMemo(() => {
    const map = new Map<string, StaffMemberModel>();
    for (const member of allStaffMembersList) {
      map.set(member.name ? member.name.trim() : `${member.firstName?.trim()} ${member.lastName?.trim()}`.trim(), member);
    }
    return map;
  }, [allStaffMembersList]);

  const today = useMemo<moment.Moment>(() => {
    return moment().startOf("day");
  }, []);

  return (
    <Box
      sx={{
        display: "flex",
        gap: "16px",
        flex: "1",
        minWidth: "380px",
        flexDirection: "column",
        height: "100%",
        maxHeight: "calc(100vh - 80px)",
        "@media (max-width: 768px)": {
          maxHeight: "unset",
        },
      }}
    >
      <div style={{ padding: "16px 16px 0px 16px" }}>
        <DatePicker
          style={{ width: `${90 + selectedDate.format("MMMM").length * 20}px` }}
          className="auto-nav-date-picker"
          suffixIcon={<FaChevronDown style={{ color: "black" }} />}
          format="DD MMMM"
          allowClear={false}
          value={selectedDate}
          onChange={(value) => {
            dispatch({ type: driverSchedulerTypes.SET_SELECTED_DATE, payload: value });
          }}
          disabledDate={(date) => {
            return date.isBefore(today);
          }}
        />
      </div>
      <Spin spinning={generatedScheduleIsLoading}>
        <div style={{ display: "flex", flexDirection: "column", gap: "16px" }}>
          <div
            style={{
              padding: "0px 16px",
              display: "flex",
              gap: "16px",
              flex: "1",
              alignItems: "flex-start",
            }}
          >
            <Input
              value={searchValue}
              allowClear={true}
              onChange={(e) => setSearchValue(e.target.value)}
              className="search-input"
              prefix={<FiSearch style={{ color: "#c1c1c1" }} />}
              placeholder="Search"
              size="large"
              style={{ border: "none", borderRadius: "10px" }}
            />
            <DriverSettingsDropdownButton />
          </div>
          <Box
            sx={{
              padding: "0px 16px",
              display: "flex",
              gap: "16px",
              flex: "1",
              alignItems: "flex-start",
              justifyContent: "flex-start",
              "@media (max-width: 768px)": {
                justifyContent: "space-between",
              },
            }}
          >
            <DownloadDriverScheduleExcelButton
              type="default"
              shape="circle"
              styles={{
                fontWeight: "500",
                fontSize: "14px",
                border: "none",
                boxShadow: "rgb(187 187 187 / 48%) 6px 2px 16px 0px, rgb(255 255 255 / 80%) -6px -2px 16px 0px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                gap: "4px",
                color: "black",
              }}
              size="large"
              selectedDate={selectedDate}
              tableData={tableData}
            />
            <ShowTimelineButton />
            <CopyScheduleButton dateToCheck={selectedDate.format("DD/MM/YYYY")} scheduleData={generatedScheduleData} />
            <SendDriverScheduleNotificationButton />
          </Box>
        </div>
      </Spin>
      <AutoNavMobileControls />
      {!generatedScheduleIsLoading && (
        <Box
          className="auto-nav-cards-list"
          key={selectedDate.valueOf()}
          sx={{
            display: "flex",
            flexDirection: "column",
            flex: "auto",
            gap: "16px",
            overflowY: "scroll",
            overflowX: "hidden",
            paddingTop: "4px",
            paddingBottom: "32px",
            paddingRight: "4px",
            width: "100%",
            maxWidth: "100%",
          }}
        >
          {filteredCardGroups.map((cardArray) => {
            const key = `${selectedDate.valueOf()}-${cardArray[0].bookingUUID}-${cardArray[0].driverName}-${
              cardArray[0].locationLink
            }-${cardArray[0].nurseName}-${cardArray[0].time}-${cardArray[0].type}`;
            return (
              <div key={key} style={{ padding: "0px 0px 0px 16px" }}>
                <AutoNavCell key={key} tasks={cardArray} staffMembersNameMap={allStaffMembersNamesMap} />
              </div>
            );
          })}
        </Box>
      )}
      <DriverSettingsDrawer onClose={() => setOpenSettingsDrawer(false)} open={openSettingsDrawer} />
    </Box>
  );
};

export default AutoNavCellsList;
