import { Alert, Box, Button, Chip, Dialog, DialogActions, DialogContent, DialogTitle, Grid, Typography } from "@mui/material";
import { FC, useCallback, useMemo, useState } from "react";
import { FaChevronRight, FaChevronLeft } from "react-icons/fa6";
import BookingModel, {
  AssignStaffDateModel,
  AssignedBookingNurseModel,
  NurseBookingDateModelWithUUID,
} from "../models/BookingModel";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { acknowledgePremiumOrder, assignedServices } from "../services";
import { showMessage } from "./common/notification";
import { LoadingButton } from "@mui/lab";
import ShowBookingStatus from "./BookingsTable/ShowBookingStatus";
import AssignStaffDate from "./AssignStaffDate";
import getStartMomentFromDateObj from "../utils/getStartMomentFromDateObj";
import Mixpanel from "../utils/Mixpanel";
import AssignStaffSelectDatesModal from "./AssignStaffSelectDatesModal";
import useAllPartnerNurses from "../hooks/useAllPartnerNurses";
import DatesModalParams from "../models/DatesModalParams";
import usePartnerOrders from "../hooks/usePartnerOrders";

const pageSize = 6;

const AssignStaffModal: FC<AssignStaffModalProps> = ({ onClose, onAssignStaffSuccess, booking, open }) => {
  const [currentPage, setCurrentPage] = useState(0);
  const [datesModalParams, setDatesModalParams] = useState<DatesModalParams>();

  const { nursesMap } = useAllPartnerNurses();

  const patientID = useMemo<string>(() => {
    return booking.patientId?.[0]?.id ?? "";
  }, [booking.patientId]);

  const totalHours = useMemo<number>(() => {
    return booking.date?.map((dateObj) => dateObj.numberOfDurationOrSession ?? 0).reduce((x, y) => x + y, 0) ?? 0;
  }, [booking.date]);

  const [dateObjs, setDateObjs] = useState<AssignStaffDateModel[]>(() => {
    const value = booking.date?.map<AssignStaffDateModel>((dateObj) => ({ ...dateObj } as AssignStaffDateModel)) ?? [];
    const assignedNurseGroupByDate = new Map<string, NurseBookingDateModelWithUUID[]>();
    for (const nurse of booking.assignedNurses ?? []) {
      for (const nurseDateObj of nurse.dateArray ?? []) {
        const currentList = assignedNurseGroupByDate.get(nurseDateObj.dateObjID ?? "") ?? [];
        currentList.push({ ...nurseDateObj, nurseUUID: nurse.nurseUUID });
        assignedNurseGroupByDate.set(nurseDateObj.dateObjID ?? "", currentList);
      }
    }
    for (const nurseDatesList of assignedNurseGroupByDate.values()) {
      nurseDatesList.sort((dateObj1, dateObj2) => {
        const moment1 = getStartMomentFromDateObj({ date: dateObj1.date, time: dateObj1.time ?? "" });
        const moment2 = getStartMomentFromDateObj({ date: dateObj2.date, time: dateObj2.time ?? "" });
        return moment1.diff(moment2);
      });
    }
    for (const bookingDateObj of value) {
      const assignedNurseList = assignedNurseGroupByDate.get(bookingDateObj.id ?? "") ?? [];
      if (assignedNurseList[0]) {
        bookingDateObj.firstShiftDuration = assignedNurseList[0].duration ?? 0;
        bookingDateObj.firstShiftNurseUUID = assignedNurseList[0].nurseUUID ?? "";
      }
      if (assignedNurseList[1]) {
        bookingDateObj.secondShiftDuration = assignedNurseList[1]?.duration ?? 0;
        bookingDateObj.secondShiftNurseUUID = assignedNurseList[1]?.nurseUUID ?? "";
      }
      bookingDateObj.splitShift = assignedNurseList.length > 1;
    }
    return value;
  });

  const totalNumberOfPages = useMemo(() => {
    return Math.ceil(dateObjs.length / pageSize);
  }, [dateObjs.length]);

  const datesToShow = useMemo(() => {
    return dateObjs.slice(currentPage * pageSize, currentPage * pageSize + pageSize);
  }, [currentPage, dateObjs]);

  const acknowledgeBookingMutation = useMutation({
    mutationFn: acknowledgePremiumOrder,
  });

  const getOrderDetailsQueryKey = useMemo(() => ["orders/getparticularnurseorder", booking?.orderUUID], [booking?.orderUUID]);

  const queryClient = useQueryClient();

  const assignNurseMutation = useMutation({
    mutationFn: assignedServices.nurseToOrder,
    onError: () => {
      showMessage("error", "Something went wrong");
    },
  });

  const { queryKey: acceptedOrdersQueryKey } = usePartnerOrders({
    status: "ACCEPTED",
    loadMoreElementInView: false,
    enabled: true,
  });

  const { query: processingOrdersQuery } = usePartnerOrders({
    status: "PROCESSING",
    loadMoreElementInView: false,
    enabled: true,
  });

  const handleSaveClicked = useCallback(async () => {
    if (booking.isSubscriptionOrder) {
      acknowledgeBookingMutation.mutate({ orderUUID: booking.orderUUID ?? "" });
    }
    const assignedNursesUUIDs = new Set<string>();
    for (const dateObj of dateObjs) {
      if (dateObj.firstShiftNurseUUID) {
        assignedNursesUUIDs.add(dateObj.firstShiftNurseUUID);
      }
      if (dateObj.secondShiftNurseUUID) {
        assignedNursesUUIDs.add(dateObj.secondShiftNurseUUID);
      }
    }
    const assignedNurses: AssignedBookingNurseModel[] = Array.from(assignedNursesUUIDs).map<AssignedBookingNurseModel>(
      (nurseID) => {
        return {
          nurseUUID: nurseID,
          patients: [patientID],
          dateArray: [],
        };
      }
    );
    for (const dateObj of dateObjs) {
      const firstShiftMoment = getStartMomentFromDateObj({ date: dateObj.date, time: dateObj.time });
      const firstShiftDuration = dateObj.splitShift ? dateObj.firstShiftDuration ?? 0 : dateObj.numberOfDurationOrSession ?? 0;
      const secondShiftMoment = firstShiftMoment.clone().add(firstShiftDuration, "hours");
      if (dateObj.firstShiftNurseUUID) {
        assignedNurses
          .find((nurse) => nurse.nurseUUID === dateObj.firstShiftNurseUUID)
          ?.dateArray?.push({
            date: dateObj.date,
            time: dateObj.time,
            duration: firstShiftDuration,
            dateObjID: dateObj.id,
            startTimestamp: dateObj.startTimestamp?.toString(),
          });
      }
      if (dateObj.splitShift && dateObj.secondShiftNurseUUID) {
        assignedNurses
          .find((nurse) => nurse.nurseUUID === dateObj.secondShiftNurseUUID)
          ?.dateArray?.push({
            date: secondShiftMoment.format("DD/MM/YYYY"),
            time: `${secondShiftMoment.format("hh:mm")} - ${secondShiftMoment.clone().add(30, "minute").format("hh:mmA")}`,
            duration: dateObj.secondShiftDuration ?? 0,
            dateObjID: dateObj.id,
            startTimestamp: dateObj.startTimestamp?.toString(),
          });
      }
    }
    const filteredAssignedNurses = assignedNurses.filter((nurse) => !!nurse.dateArray?.length);
    const res = await assignNurseMutation.mutateAsync({
      orderUUID: booking?.orderUUID,
      assignedNurses: filteredAssignedNurses,
    });
    if (!res?.data?.success) {
      return showMessage("error", "Something went wrong");
    }
    showMessage("success", "Nurse was assigned successfully");
    onAssignStaffSuccess({ ...booking, status: "PROCESSING", assignedNurses: filteredAssignedNurses });
    onClose();
    queryClient.resetQueries(getOrderDetailsQueryKey);
    queryClient.invalidateQueries(processingOrdersQuery);
    queryClient.invalidateQueries(acceptedOrdersQueryKey);
    Mixpanel.track("Nurse Assigned", booking);
  }, [
    acceptedOrdersQueryKey,
    acknowledgeBookingMutation,
    assignNurseMutation,
    booking,
    dateObjs,
    getOrderDetailsQueryKey,
    patientID,
    processingOrdersQuery,
    queryClient,
    onAssignStaffSuccess,
    onClose,
  ]);

  const onFirstShiftChanged = useCallback(
    ({ nurseUUID, dateObj }: { nurseUUID: string; dateObj: AssignStaffDateModel }) => {
      // if (dateObjs.every((dateObj) => !dateObj.splitShift)) {
      // }
      if (dateObjs.length > 1) {
        setDatesModalParams({
          dateObjIndex: dateObjs.findIndex((date) => date.id === dateObj.id),
          startTime: dateObj.time,
          nurse: nursesMap.get(nurseUUID)!,
          patient: booking.patient![0],
          isSelectingSecondShift: false,
        });
      }
      setDateObjs((value) => {
        return value.map((obj) => {
          if (obj.date === dateObj.date) {
            return { ...obj, firstShiftNurseUUID: nurseUUID } as AssignStaffDateModel;
          }
          return obj;
        });
      });
    },
    [booking.patient, dateObjs, nursesMap]
  );

  const onSecondShiftChanged = useCallback(
    ({ dateObj, nurseUUID }: { nurseUUID: string; dateObj: AssignStaffDateModel }) => {
      const shiftMoment = getStartMomentFromDateObj({ date: dateObj.date, time: dateObj.time }).add(
        dateObj.firstShiftDuration,
        "hours"
      );
      if (dateObjs.length > 1) {
        setDatesModalParams({
          dateObjIndex: dateObjs.findIndex((date) => date.id === dateObj.id),
          startTime: `${shiftMoment.format("hh:mm")} - ${shiftMoment.clone().add(30, "minute").format("hh:mmA")}`,
          nurse: nursesMap.get(nurseUUID)!,
          patient: booking.patient![0],
          isSelectingSecondShift: true,
        });
      }
      setDateObjs((value) => {
        return value.map((obj) => {
          if (obj.date === dateObj.date) {
            return { ...obj, secondShiftNurseUUID: nurseUUID } as AssignStaffDateModel;
          }
          return obj;
        });
      });
    },
    [booking.patient, dateObjs, nursesMap]
  );

  return (
    <>
      <Dialog open={open} onClose={onClose} fullWidth={true} maxWidth="md">
        <DialogTitle display={"flex"} alignItems={"center"} gap={"16px"} justifyContent={"space-between"}>
          <Typography variant="h6">{`${booking.orderUUID} - ${booking.patientId?.[0]?.type ?? ""}`}</Typography>
          <Box display={"flex"} gap={"8px"}>
            <ShowBookingStatus booking={booking} />
            {booking.isPremiumOrder && <Chip label="Premium" sx={{ color: "white" }} size="small" color="primary" />}
            {booking.isSubscriptionOrder && <Chip label="Subscription" sx={{ color: "white" }} size="small" color="primary" />}
          </Box>
        </DialogTitle>
        <DialogContent>
          <Box display={"flex"} gap={2} marginBottom={2}>
            {booking.patient?.[0]?.name && <Typography fontWeight={500}>{`${booking.patient?.[0]?.name}`}</Typography>}
            {booking.patient?.[0]?.name && <Typography fontWeight={500}>-</Typography>}
            {booking.productType === "S-D" ? (
              <Typography>{`${booking.date?.length} Days ${totalHours} Hours`}</Typography>
            ) : (
              <Typography>{`${booking.date?.length} ${booking.date?.length === 1 ? "Session" : "Sessions"}`}</Typography>
            )}
          </Box>
          {booking.isSubscriptionOrder && (
            <Alert severity="warning" sx={{ marginBottom: "16px" }}>
              Note: This is a subscription booking. Please assign the same nurse. Failure to do so can lead to penalties.{" "}
            </Alert>
          )}
          <Grid container spacing={2} minHeight={"400px"}>
            {datesToShow.map((dateObj) => {
              return (
                <Grid item xs={12} sm={6} md={4} key={dateObj.date}>
                  <AssignStaffDate
                    booking={booking}
                    dateObj={dateObj}
                    onSecondShiftDurationChanged={(duration) => {
                      setDateObjs((value) => {
                        return value.map((obj) => {
                          if (obj.date === dateObj.date) {
                            return { ...obj, secondShiftDuration: duration } as AssignStaffDateModel;
                          }
                          return obj;
                        });
                      });
                    }}
                    onFirstShiftDurationChanged={(duration) => {
                      setDateObjs((value) => {
                        return value.map((obj) => {
                          if (obj.date === dateObj.date) {
                            return { ...obj, firstShiftDuration: duration } as AssignStaffDateModel;
                          }
                          return obj;
                        });
                      });
                    }}
                    onSplitBookingChanged={(isSplit) => {
                      setDateObjs((value) => {
                        return value.map((obj) => {
                          if (obj.date === dateObj.date) {
                            return { ...obj, splitShift: isSplit } as AssignStaffDateModel;
                          }
                          return obj;
                        });
                      });
                    }}
                    onFirstShiftChanged={(nurseUUID) => {
                      onFirstShiftChanged({ nurseUUID, dateObj });
                    }}
                    onSecondShiftChanged={(nurseUUID) => {
                      onSecondShiftChanged({ dateObj, nurseUUID });
                    }}
                  />
                </Grid>
              );
            })}
          </Grid>
          <Box
            display={"flex"}
            overflow={"hidden"}
            maxWidth={"100%"}
            marginTop={2}
            gap={2}
            alignItems={"flex-start"}
            visibility={totalNumberOfPages < 2 ? "hidden" : "visible"}
          >
            <Button
              startIcon={<FaChevronLeft />}
              disabled={currentPage === 0}
              onClick={() =>
                setCurrentPage((value) => {
                  if (value === 0) return value;
                  return value - 1;
                })
              }
            >
              Previous
            </Button>
            <Box
              display={"flex"}
              flex={"1 1 0"}
              width={"100%"}
              overflow={"scroll"}
              gap={2}
              alignItems={"center"}
              paddingBottom={1}
              justifyContent={"center"}
            >
              {Array.from({ length: totalNumberOfPages }).map((_, index) => {
                return (
                  <Button
                    key={index}
                    variant={currentPage === index ? "outlined" : undefined}
                    onClick={() => {
                      setCurrentPage(index);
                    }}
                  >
                    {index + 1}
                  </Button>
                );
              })}
            </Box>
            <Button
              disabled={currentPage >= totalNumberOfPages - 1}
              endIcon={<FaChevronRight />}
              onClick={() => {
                setCurrentPage((value) => {
                  if (value >= totalNumberOfPages - 1) {
                    return value;
                  }
                  return value + 1;
                });
              }}
            >
              Next
            </Button>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose}>Cancel</Button>
          <LoadingButton
            loading={assignNurseMutation.isLoading || acknowledgeBookingMutation.isLoading}
            onClick={handleSaveClicked}
            variant="contained"
            disableElevation={true}
          >
            <span style={{ color: "white" }}>Save</span>
          </LoadingButton>
        </DialogActions>
      </Dialog>
      {datesModalParams && (
        <AssignStaffSelectDatesModal
          open={!!datesModalParams}
          booking={booking}
          dateObjs={dateObjs}
          setDateObjs={setDateObjs}
          // selectedDateObjIndex={datesModalParams.dateObjIndex}
          // selectedTime={datesModalParams.startTime}
          isSelectingSecondShift={datesModalParams?.isSelectingSecondShift}
          nurse={datesModalParams?.nurse}
          // patient={datesModalParams?.patient}
          onClose={function (): void {
            setDatesModalParams(undefined);
          }}
        />
      )}
      {/* <SelectNurseAssignedDatesModal
        open={!!datesModalParams}
        bookingModel={booking}
        selectedNurse={datesModalParams?.nurse}
        patient={datesModalParams?.patient}
        isSelectingSecondShift={false}
        onCancel={function (): void {
          setDatesModalParams(undefined);
        }}
        onDatesSelected={function (params: { dates: string[]; selectedNurse: StaffMemberModel }): void {
          console.log("Function not implemented.", params);
        }}
      /> */}
    </>
  );
};

interface AssignStaffModalProps {
  open: boolean;
  booking: BookingModel;
  onAssignStaffSuccess: (booking: BookingModel) => void;
  onClose: () => void;
}

export default AssignStaffModal;
