import { GeneratedDriverScheduleItem } from "../../services";
import moment from "moment";

export interface AutoNavCellsListProps {}

export function mapGeneratedScheduleResponseToCardGroupsArray(response: {
  [driverName: string]: GeneratedDriverScheduleItem[];
}): GeneratedDriverScheduleItem[][] {
  const arrGroups: GeneratedDriverScheduleItem[][] = [];
  for (const driverName of Object.keys(response ?? {})) {
    const driverTasks = response![driverName] ?? [];
    let cardTasks: GeneratedDriverScheduleItem[] = [];
    for (const task of driverTasks) {
      cardTasks.push(task);
      const pickupTasks = cardTasks.filter((task) => task.type === "pickup");
      const dropTasks = cardTasks.filter((task) => task.type === "delivery");
      const didDropEveryPickup = pickupTasks.every((pickupTask) => {
        return dropTasks.some((dropTask) => {
          return (
            dropTask.bookingUUID === pickupTask.bookingUUID &&
            dropTask.nurseName === pickupTask.nurseName &&
            pickupTask.driverName === dropTask.driverName
          );
        });
      });
      if (didDropEveryPickup) {
        arrGroups.push([...cardTasks]);
        cardTasks = [];
      }
    }
    if (cardTasks.length) {
      arrGroups.push([...cardTasks]);
      cardTasks = [];
    }
  }
  return arrGroups.sort((arr1, arr2) => {
    if (arr1.some((task) => task.driverName === "unassigned")) {
      // const time1 = moment(arr1[0].time, "hh:mmA");
      // const time2 = moment(arr2[0].time, "hh:mmA");
      // return time1.diff(time2);
      return -1;
    } else if (arr2.some((task) => task.driverName === "unassigned")) {
      // const time1 = moment(arr1[0].time, 'hh:mmA');
      // const time2 = moment(arr2[0].time, 'hh:mmA');
      // return time1.diff(time2);
      return 1;
    }
    if (!arr1.length || !arr2.length) return 0;
    const firstMoment = moment(arr1[0].time, "hh:mmA");
    const secondMoment = moment(arr2[0].time, "hh:mmA");
    return firstMoment.diff(secondMoment);
  });
}
