import { FC, useState } from "react";
import { Button, Modal, Typography } from "antd";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useHistory } from "react-router-dom";
import { showMessage } from "../../components/common/notification";
import { StaffMemberModel } from "../../models/StaffMemberModel";
import { nurseService } from "../../services";
import useAllPartnerNurses from "../../hooks/useAllPartnerNurses";
import { useSelector } from "react-redux";
import ReduxStateModel from "../../models/ReduxStateModel";

const buttonsBorderRadius = "7px";

const DeleteNurseButton: FC<DeleteNurseButtonProps> = ({ nurse }) => {
  const history = useHistory();
  const partnerUserUUID: string | undefined = useSelector(
    (state: ReduxStateModel) => state.authReducer?.setting?.partnerUserUUID
  );
  const deleteNurseMutation = useMutation({ mutationFn: nurseService.deleteNurseForAdmin });
  const queryClient = useQueryClient();
  const { queryKey } = useAllPartnerNurses();
  const [showDeleteNurseModal, setShowDeleteNurseModal] = useState<boolean>(false);

  const handleDeleteNurse = async () => {
    const res = await deleteNurseMutation.mutateAsync({
      nurseUUID: nurse.nurseUUID ?? "",
      partnerUserUUID: partnerUserUUID ?? "",
    });
    if (res?.success) {
      showMessage("success", "Nurse was deleted successfully");
      setShowDeleteNurseModal(false);
      queryClient.resetQueries(queryKey);
      history.replace("/team");
    } else {
      showMessage("error", "Something went wrong");
    }
  };

  return (
    <>
      <Button
        loading={deleteNurseMutation.isLoading}
        onClick={() => setShowDeleteNurseModal(true)}
        danger={true}
        style={{ borderRadius: buttonsBorderRadius }}
      >
        Delete Nurse
      </Button>
      <Modal
        title={
          <div style={{ width: "100%", textAlign: "center" }}>
            Are you sure you would like to delete nurse {nurse.firstName ?? ""} {nurse.lastName ?? ""} ?
          </div>
        }
        okText="Yes"
        onOk={handleDeleteNurse}
        open={showDeleteNurseModal}
        onCancel={() => setShowDeleteNurseModal(false)}
        okType="danger"
        okButtonProps={{ loading: deleteNurseMutation.isLoading, danger: true, style: { borderRadius: buttonsBorderRadius } }}
        cancelButtonProps={{ loading: deleteNurseMutation.isLoading, style: { borderRadius: buttonsBorderRadius } }}
      >
        <div style={{ display: "flex", justifyContent: "center" }}>
          <Typography.Text type="danger" style={{ textAlign: "center", width: "100%" }}>
            <strong>This aciton is irreversible</strong>
          </Typography.Text>
        </div>
      </Modal>
    </>
  );
};

interface DeleteNurseButtonProps {
  nurse: StaffMemberModel;
}

export default DeleteNurseButton;
