import { Input, Spin } from "antd";
import { FC, useMemo, useState } from "react";
import { IoSearch } from "react-icons/io5";
import { driverSchedulePageBackgroundColor } from "../../constants";
import { StaffMemberModel } from "../../models/StaffMemberModel";
import useAllPartnerNurses from "../../hooks/useAllPartnerNurses";
import TabsMenu from "./TabsMenu";
import TeamMembersList from "./TeamMembersList";
import BasePageLayout from "../../components/BasePageLayout";
import CreateDriverButton from "./CreateDriverButton";
import CreateProfessionalButton from "./CreateProfessionalButton";
import { Box } from "@mui/material";
import "../../components/common/TeamTable/style.css";
import "./styles.css";

const NewTeamPage: FC = () => {
  const [selectedMenuItem, setSelectedMenuItem] = useState("all");
  const [searchInputValue, setSearchInputValue] = useState<string>("");

  const { query } = useAllPartnerNurses();
  const { data, isLoading } = query;

  const teamMembers = useMemo<StaffMemberModel[]>(() => {
    let list: StaffMemberModel[] = [...(data?.data?.nurses ?? []), ...(data?.data?.drivers ?? [])];
    list.sort((m1, m2) => {
      if (!m1.isActive) return 1;
      if (!m2.isActive) return -1;
      return 0;
    });
    if (searchInputValue.trim()) {
      list = list.filter((member) => {
        if (member.type === "DRIVER") {
          return member.name?.toLocaleLowerCase().includes(searchInputValue.toLocaleLowerCase());
        } else {
          const name = `${member.firstName ?? ""} ${member.lastName ?? ""}`.toLocaleLowerCase();
          return name.includes(searchInputValue.toLocaleLowerCase());
        }
      });
    }
    return list;
  }, [
    data?.data?.drivers,
    data?.data?.nurses,
    searchInputValue,
    // selectedFilterServices,
  ]);

  const staffCounts = useMemo<StaffCountsModel>(() => {
    const counts = { certified: 0, registered: 0, therapists: 0, drivers: 0 };
    counts.drivers = teamMembers.filter((member) => member.type === "DRIVER").length;
    for (const member of teamMembers ?? []) {
      if (member.qualification === "CERTIFIED") {
        counts.certified += 1;
      } else if (member.qualification === "REGISTERED") {
        counts.registered += 1;
      } else if (member.qualification === "PHYSIOTHERAPIST") {
        counts.therapists += 1;
      }
    }
    return counts;
  }, [teamMembers]);

  const teamMembersFilteredByType = useMemo<StaffMemberModel[]>(() => {
    let list = teamMembers;
    if (selectedMenuItem === "caregivers") {
      list = list.filter((member) => {
        if (member.type === "DRIVER") return false;
        return member.qualification === "CERTIFIED";
      });
    } else if (selectedMenuItem === "nurses") {
      list = list.filter((member) => {
        if (member.type === "DRIVER") return false;
        return member.qualification === "REGISTERED";
      });
    } else if (selectedMenuItem === "therapists") {
      list = list.filter((member) => {
        if (member.type === "DRIVER") return false;
        return member.qualification === "PHYSIOTHERAPIST";
      });
    } else if (selectedMenuItem === "drivers") {
      list = list.filter((member) => {
        return member.type === "DRIVER";
      });
    }
    return list;
  }, [selectedMenuItem, teamMembers]);

  return (
    <BasePageLayout
      mainContent={
        <div
          style={{
            backgroundColor: driverSchedulePageBackgroundColor,
          }}
        >
          <div
            className="main_page"
            style={{
              height: "100%",
              padding: "1rem",
              overflowY: "scroll",
              marginBottom: "6rem",
              width: "100%",
              maxWidth: "100%",
            }}
          >
            <Spin spinning={isLoading}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  marginBottom: "1rem",
                  flexWrap: "wrap",
                }}
              >
                <Box
                  sx={{
                    "@media (max-width: 700px)": {
                      marginBottom: "1rem",
                    },
                  }}
                >
                  <h1 className="page-title" style={{ marginBottom: "8px" }}>
                    Staffing
                  </h1>
                  <Box
                    sx={{
                      "@media (max-width: 700px)": {
                        display: "none",
                      },
                    }}
                  >
                    <p className="page-subtitle">Central hub for managing your staff</p>
                  </Box>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    gap: "1rem",
                    "@media (max-width: 700px)": {
                      flexWrap: "wrap",
                    },
                  }}
                >
                  <CreateDriverButton />
                  <CreateProfessionalButton />
                </Box>
              </div>
              <div
                style={{
                  backgroundColor: "white",
                  borderRadius: "20px",
                  padding: "14px 14px",
                  display: "flex",
                  flexDirection: "column",
                  gap: "16px",
                }}
              >
                <Box
                  sx={{
                    width: "400px",
                    "@media (max-width: 700px)": {
                      width: "100%",
                    },
                  }}
                >
                  <Input
                    allowClear={true}
                    style={{ width: "100%", borderRadius: "10px" }}
                    prefix={<IoSearch style={{ color: "#cdcdcd", fontSize: "18px" }} />}
                    placeholder="Searching is easier"
                    value={searchInputValue}
                    onChange={(e) => {
                      setSearchInputValue(e.target.value);
                    }}
                  />
                </Box>
                <TabsMenu
                  staffCounts={staffCounts}
                  teamMembers={teamMembers}
                  selectedItemKey={selectedMenuItem}
                  onItemClicked={(info) => {
                    setSelectedMenuItem(info.key);
                  }}
                />
                <TeamMembersList teamMembers={teamMembersFilteredByType} />
              </div>
            </Spin>
          </div>
        </div>
      }
    />
  );
};

export interface StaffCountsModel {
  certified: number;
  registered: number;
  therapists: number;
  drivers: number;
}

export default NewTeamPage;
