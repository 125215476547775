import cryptLib from "@skavinvarnan/cryptlib";

const decrypt = (image) => {
  try {
    const decryptedString = JSON.parse(cryptLib.decryptCipherTextWithRandomIV(image, "w0HXiDp6baR2uLNRQ3FpVDePii3aeP7R"));
    return decryptedString;
  } catch (e) {
    console.log("--- decrypt error", e);
    return image;
  }
};

export default decrypt;
