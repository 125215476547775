import { useQuery } from "@tanstack/react-query";
import { getPharmacyTractionTable } from "../services";
import { useSelector } from "react-redux";
import { useMemo } from "react";
import { FilterPeriodKeysType } from "../pages/Traction";
import { getStartEndDatesFromSelectedPeriod } from "../pages/OperationAnalytics/HomeNursingPeriodDropdown";
import moment from "moment";
import ReduxStateModel from "../models/ReduxStateModel";

function usePharmacyTractionTable(selectedPeriod: FilterPeriodKeysType, selectedDateRange?: [moment.Moment, moment.Moment]) {
  const partnerUUID = useSelector((state: ReduxStateModel) => state.authReducer?.setting?.partnerUUID);

  const startAndEndDatesOfSelectedPeriod = useMemo(() => {
    return getStartEndDatesFromSelectedPeriod(selectedPeriod, selectedDateRange);
  }, [selectedDateRange, selectedPeriod]);

  const queryKey = useMemo(() => {
    return [
      "/analytics/traction-table-for-pharmacy",
      partnerUUID,
      startAndEndDatesOfSelectedPeriod[0].format(),
      startAndEndDatesOfSelectedPeriod[1].format(),
    ];
  }, [partnerUUID, startAndEndDatesOfSelectedPeriod]);

  const query = useQuery(
    queryKey,
    () =>
      getPharmacyTractionTable({
        partnerUUID: partnerUUID ?? "",
        endDate: startAndEndDatesOfSelectedPeriod[1].format("YYYY-MM-DD"),
        startDate: startAndEndDatesOfSelectedPeriod[0].format("YYYY-MM-DD"),
      }),
    { enabled: !!partnerUUID && !!startAndEndDatesOfSelectedPeriod[0] && !!startAndEndDatesOfSelectedPeriod[1] }
  );

  return { query };
}

export default usePharmacyTractionTable;
