import moment from "moment";
import { DatePicker, Form, Input, Select } from "antd";
import { FC } from "react";
import { nationalities } from "../../components/common/contry.data";

const PersonalDetailsTab: FC = () => {
  const yearsAgo18 = moment().subtract(18, "years");

  return (
    <div style={{ width: "100%" }}>
      <div style={{ display: "flex", gap: "16px", width: "100%" }}>
        <Form.Item
          className="driver-name-form-item"
          name="name"
          label="Full name"
          style={{ width: "100%" }}
          rules={[{ required: true, message: "Please enter the full name" }]}
        >
          <Input />
        </Form.Item>
      </div>
      <div style={{ display: "flex", gap: "16px" }}>
        <Form.Item
          name="dob"
          label="Date of birth"
          rules={[{ required: true, message: "Please enter the date of birth" }]}
          style={{ width: "100%" }}
        >
          <DatePicker
            style={{ width: "100%" }}
            format="DD/MM/YYYY"
            disabledDate={(date) => {
              return date.isAfter(yearsAgo18);
            }}
          />
        </Form.Item>
        <Form.Item
          name="sex"
          label="Gender"
          rules={[{ required: true, message: "Please select the nurse gender" }]}
          style={{ width: "100%" }}
        >
          <Select>
            <Select.Option value="male">Male</Select.Option>
            <Select.Option value="female">Female</Select.Option>
          </Select>
        </Form.Item>
      </div>
      <div style={{ display: "flex", gap: "16px" }}>
        <Form.Item
          label="Email"
          name="email"
          rules={[{ required: true, message: "Please enter the email" }]}
          style={{ width: "100%" }}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Mobile No."
          name="mobileNumber"
          rules={[{ required: true, message: "Please enter the mobile number" }]}
          style={{ width: "100%" }}
        >
          <Input.Group compact={true} className="phone-number-input-group" style={{ display: "flex" }}>
            <Form.Item
              style={{ flex: "2" }}
              name={["mobileNumber", "countryCode"]}
              rules={[{ required: true, message: "Please select phone country code" }]}
            >
              <Select style={{ minWidth: "70px" }} options={[{ value: "+971", label: "+971" }]} />
            </Form.Item>
            <Form.Item
              style={{ flex: "6" }}
              name={["mobileNumber", "number"]}
              rules={[{ required: true, message: "Please enter the mobile number" }]}
            >
              <Input />
            </Form.Item>
          </Input.Group>
        </Form.Item>
      </div>
      <div style={{ display: "flex", gap: "16px" }}>
        <Form.Item
          label="Country"
          className="driver-nationality-form-item"
          name="nationality"
          style={{ width: "100%" }}
          rules={[
            {
              required: true,
              message: "Please select the country",
            },
          ]}
        >
          <Select
            showSearch={true}
            filterOption={(input, option) => {
              return option?.label.toLowerCase().includes(input.toLowerCase()) ?? false;
            }}
            style={{ width: "100%" }}
            options={nationalities.map((country) => {
              return {
                value: country.toLowerCase(),
                label: country,
              };
            })}
          />
        </Form.Item>
      </div>
    </div>
  );
};

export default PersonalDetailsTab;
