import { FC } from "react";
import BookingTableRowPatientCell from "./BookingTableRowPatientCell";
import BookingModel from "../../models/BookingModel";
import UploadBookingReportButton from "../common/UploadBookingReportButton";
import AckowledgeBookingButton from "../../pages/ShowOrder/AckowledgeBookingButton";
import AcknowledgeDeliveryBooking from "../common/BookingsTable/AcknowledgeDeliveryBooking";

const TableRowPatientCellContent: FC<TableRowPatientCellContentProps> = ({ booking, setBooking, onReportedUploadedSuccess }) => {
  return (
    <>
      <div style={{ marginBottom: "8px" }}>
        <BookingTableRowPatientCell patientId={booking.patientId} guardian={booking.guardian} />
      </div>
      <div
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        <UploadBookingReportButton
          booking={booking}
          setBooking={setBooking}
          onReportedUploadedSuccess={onReportedUploadedSuccess}
        />
      </div>
      <div
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        <AckowledgeBookingButton booking={booking} height="35px" setBooking={setBooking} />
      </div>
      <div
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        <AcknowledgeDeliveryBooking height="35px" booking={booking} setBooking={setBooking} />
      </div>
    </>
  );
};

interface TableRowPatientCellContentProps {
  booking: BookingModel;
  setBooking: React.Dispatch<React.SetStateAction<BookingModel>>;
  onReportedUploadedSuccess: (() => void) | null;
}

export default TableRowPatientCellContent;
