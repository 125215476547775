import { FC, useCallback } from "react";
import PharmacyBookingModel from "../../models/PharmacyBookingModel";
import { LoadingButton } from "@mui/lab";
import usePharmacyOrders from "../../hooks/usePharmacyOrders";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { GetPharmacyOrdersResponseModel, markOrderAsDelivered } from "../../services/pharmacy.orders.service";
import { showMessage } from "../common/notification";
import { useHistory } from "react-router-dom";

const MarkDeliveredButton: FC<MarkDeliveredButtonProps> = ({ order }) => {
  const history = useHistory();
  const { queryKey: dispatchedOrdersQueryKey } = usePharmacyOrders({ status: "DISPATCHED" });
  const { queryKey: deliveredOrdersQueryKey } = usePharmacyOrders({ status: "DELIVERED" });
  const queryClient = useQueryClient();
  const markDeliveredMutation = useMutation({ mutationFn: markOrderAsDelivered });

  const handleClick = useCallback(async () => {
    const res = await markDeliveredMutation.mutateAsync({ orderUUID: order.orderUUID ?? "" });
    if (res?.success) {
      showMessage("success", "Order was marked as delivered successfully");
      queryClient.setQueryData(dispatchedOrdersQueryKey, (data: unknown) => {
        if (!data) return data;
        const newValue: GetPharmacyOrdersResponseModel = JSON.parse(JSON.stringify(data));
        newValue.pharmacyOrders = newValue.pharmacyOrders?.filter((o) => o.orderUUID !== order.orderUUID);
        return newValue;
      });
      queryClient.setQueryData(deliveredOrdersQueryKey, (data: unknown) => {
        if (!data) return data;
        const newValue: GetPharmacyOrdersResponseModel = JSON.parse(JSON.stringify(data));
        const updatedOrder: PharmacyBookingModel = { ...order };
        updatedOrder.appStatus = "DELIVERED";
        newValue.pharmacyOrders?.unshift(updatedOrder);
        return newValue;
      });
      queryClient.invalidateQueries(dispatchedOrdersQueryKey);
      queryClient.invalidateQueries(deliveredOrdersQueryKey);
      history.replace("/pharmacy");
    } else {
      showMessage("error", "Something went wrong");
    }
  }, [deliveredOrdersQueryKey, dispatchedOrdersQueryKey, history, markDeliveredMutation, order, queryClient]);

  return (
    <LoadingButton onClick={handleClick} loading={markDeliveredMutation.isLoading} className="primary" variant="outlined">
      Mark Delivered
    </LoadingButton>
  );
};

interface MarkDeliveredButtonProps {
  order: PharmacyBookingModel;
}

export default MarkDeliveredButton;
