import { FC, useMemo } from "react";
import { Redirect } from "react-router-dom";

const MustPassTestRoute: FC<MustPassTestRouteProps> = ({ child, queryLoading, redirectPath, test }) => {
  const passTest = useMemo(() => {
    return !queryLoading && test();
  }, [queryLoading, test]);

  if (!passTest && !queryLoading) {
    return <Redirect to={redirectPath ?? "/"} />;
  }

  return child;
};

interface MustPassTestRouteProps {
  test: () => boolean;
  child: JSX.Element;
  queryLoading: boolean;
  redirectPath?: string;
}

export default MustPassTestRoute;
