import { useQuery } from "@tanstack/react-query";
import { getAllPartnerContacts } from "../services/inventory";
import { useMemo } from "react";

export default function useAllPartnerContactsQuery({ partnerUUID }: { partnerUUID?: string }) {
  const queryKey = useMemo(() => ["inventory-requests/get-all-partner-contacts", partnerUUID], [partnerUUID]);

  const query = useQuery(queryKey, () => getAllPartnerContacts({ partnerUUID: partnerUUID ?? "" }), { enabled: !!partnerUUID });

  return { query, queryKey };
}
