import { Grid } from "@mui/material";
import { FC } from "react";
import DarDocLogo from "../../assets/images/logo.jpg";
import LoginForm from "./LoginForm";
import useFA2Login from "./useFA2Login";
import OTPForm from "../UserSignUpLink/OTPForm";
import moment from "moment";

const FA2Login: FC = () => {
  const {
    sendFireAuthOTPConfirmationResult,
    sendOTPLoading,
    checkUniqueUserMutation,
    confirmFirebaseOTPLoading,
    firebaseCustomTokenMutation,
    formValues,
    sendWhatsappOTPMutation,
    resendOTPLoading,
    verifyWhatsappOTPMutation,
    loginPartnerUser,
    handlePhoneNumberEntered,
    handleRequestCallClicked,
    handleResendOTPClicked,
    onOTPEntered,
  } = useFA2Login();

  return (
    <div
      style={{
        width: "100vw",
        height: "100vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: "white",
        position: "relative",
      }}
    >
      <div style={{ position: "absolute", bottom: "16px", left: "16px", right: "16px", textAlign: "center" }}>
        © DarDoc Health Technologies Limited ADGM {moment().format("YYYY")}
      </div>
      <Grid
        id="login-form-container"
        container={true}
        spacing={2}
        sx={{ alignItems: "center", justifyContent: "center", marginBottom: "200px" }}
      >
        <div id="login-captcha" />
        <Grid xs={12} sm={8} md={6} lg={4} item={true}>
          <div style={{ display: "flex", justifyContent: "center", width: "100%" }}>
            <img src={DarDocLogo} alt="DarDoc" style={{ width: "120px" }} />
          </div>
          <h3 style={{ textAlign: "center", marginBottom: "2rem" }}>Sign In</h3>
          {sendFireAuthOTPConfirmationResult ? (
            <OTPForm
              mobileNumber={formValues?.mobileNumber ?? ""}
              resendOTPLoading={resendOTPLoading}
              submitLoading={
                confirmFirebaseOTPLoading ||
                checkUniqueUserMutation.isLoading ||
                verifyWhatsappOTPMutation.isLoading ||
                firebaseCustomTokenMutation.isLoading ||
                loginPartnerUser.isLoading
              }
              requestCallLoading={sendWhatsappOTPMutation.isLoading}
              handleCallRequestClicked={handleRequestCallClicked}
              handleFormSubmit={onOTPEntered}
              handleResendOTPClicked={handleResendOTPClicked}
            />
          ) : (
            <LoginForm
              handleFormSubmit={handlePhoneNumberEntered}
              loadingLogin={sendOTPLoading || checkUniqueUserMutation.isLoading}
            />
          )}
        </Grid>
      </Grid>
    </div>
  );
};

export default FA2Login;
