import { FC, useEffect } from "react";
import { Route, Switch, useHistory, useRouteMatch } from "react-router-dom";
import { useDispatch } from "react-redux";
import { ConsultationRecommendationsType } from "../../types";
import { allowRecommendationsProductUUIDs } from "../../constants";
import SuggestionsModal from "./SuggestionsModal";
import SuggestIVDripsModal from "./SuggestIVDripsModal";
import SuggestLabTestsModal from "./SuggestLabTestsModal";
import SelectIVDripCustomizationsModal from "./SelectIVDripCustomizationsModal";
import SuggestBuildYourLabTestAddonsModal from "./SuggestBuildYourLabTestAddonsModal";
import ConfirmRecommendationsModal from "./ConfirmRecommendationsModal";
import BookingModel from "../../models/BookingModel";

const RecommendationsRoutes: FC<RecommendationsRoutesProps> = ({ booking, setBooking }) => {
  const { path, url } = useRouteMatch();
  const dispatch = useDispatch();
  const history = useHistory();
  // const consultationRecommendationsReducer = useSelector((state: ReduxStateModel) => state.consultationRecommendationsReducer);

  useEffect(() => {
    const unlisten = history.listen((location) => {
      if (location.pathname === url) {
        dispatch({ type: ConsultationRecommendationsType.RESET_CONSULTATION_RECOMMENDATIONS });
      }
    });
    return () => {
      unlisten();
    };
  }, [dispatch, history, url]);

  if (!allowRecommendationsProductUUIDs.includes(booking.patientId?.[0]?.productUUID ?? "")) return null;

  return (
    <Switch>
      <Route
        exact={true}
        path={`${path}/suggestions`}
        render={() => {
          return <SuggestionsModal />;
        }}
      />
      <Route
        exact={true}
        path={`${path}/suggestions/iv-drips`}
        render={() => {
          return <SuggestIVDripsModal loading={false} />;
        }}
      />
      <Route
        exact={true}
        path={`${path}/suggestions/lab-tests`}
        render={() => {
          return <SuggestLabTestsModal />;
        }}
      />
      <Route
        exact={true}
        path={`${path}/suggestions/iv-drips/customizations`}
        render={() => {
          return <SelectIVDripCustomizationsModal loading={false} />;
        }}
      />
      <Route
        exact={true}
        path={`${path}/suggestions/lab-tests/addons`}
        render={() => {
          return <SuggestBuildYourLabTestAddonsModal loading={false} />;
        }}
      />
      <Route
        exact={true}
        path={`${path}/suggestions/confirm`}
        render={() => {
          return <ConfirmRecommendationsModal setBooking={setBooking} />;
        }}
      />
    </Switch>
  );
};

interface RecommendationsRoutesProps {
  setBooking: React.Dispatch<React.SetStateAction<BookingModel>>;
  booking: BookingModel;
}

export default RecommendationsRoutes;
