import type { ConfirmationResult } from "firebase/auth";
import { useCallback, useState } from "react";

const useVerifyMobileNumberOTPWithFirebase = ({ confirmationResult }: { confirmationResult?: ConfirmationResult }) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | undefined>();
  // const { remainingTime: remainingResendTimeInSeconds } = useTimer(resendTimeInSeconds);

  const verifyOTP = useCallback(
    async (otpCode: string) => {
      if (!confirmationResult) return "Something went wrong";
      setLoading(true);
      try {
        const userCredential = await confirmationResult.confirm(otpCode);
        setLoading(false);
        if (!userCredential?.user?.uid) {
          const errorMsg = "Something went wrong";
          setError(errorMsg);
          return errorMsg;
        }
        return userCredential;
      } catch (e) {
        console.log("--- useVerifyMobileNumberOTPWithFirebase error");
        console.log(e);
        let errorMsg: string;
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        if ((e as any).code === "auth/invalid-verification-code") {
          errorMsg = "Invalid OTP";
        } else {
          errorMsg = "Something went wrong";
        }
        setLoading(false);
        setError(errorMsg);
        return errorMsg;
      }
    },
    [confirmationResult]
  );

  return { verifyOTP, loading, error };
};

// type useVerifyMobileNumberOTPWithFirebaseType = (params: ) => {
//   verifyOTP: (otpCode: string) => Promise<string | UserCredential>;
//   loading: boolean;
//   error: string | undefined;
//   remainingResendTimeInSeconds: number;
// };

export default useVerifyMobileNumberOTPWithFirebase;
