import { FC, useMemo } from "react";
import { useSelector } from "react-redux";
import { Box, Paper, Typography } from "@mui/material";
import { grey } from "@mui/material/colors";
import useDeliveryPartnerRevenueOrdersQuery from "../../hooks/useDeliveryPartnerRevenueOrdersQuery";
import ReduxStateModel from "../../models/ReduxStateModel";
import RevenueHeaderItem from "./RevenueHeaderItem";
import LoadingSpinner from "../../components/LoadingSpinner";

const RevenueHeader: FC = () => {
  const authReducer = useSelector((state: ReduxStateModel) => state.authReducer?.setting);
  const { query } = useDeliveryPartnerRevenueOrdersQuery({ partnerUUID: authReducer?.partnerUUID });

  const totalPayable = useMemo(() => {
    let value = 0;
    for (const order of query.data?.orders ?? []) {
      for (const item of order.inventoryRequirements ?? []) {
        value += (item.quantity ?? 0) * (item.supplierPrice ?? 0);
      }
    }
    return value;
  }, [query.data?.orders]);

  return (
    <LoadingSpinner
      spinning={query.isLoading}
      styles={{ minHeight: "auto", borderRadius: "12px" }}
      showBackdrop={false}
      borderRadius={12}
    >
      <Paper sx={{ borderRadius: "12px" }}>
        <Box padding={2}>
          <Typography marginBottom={1} variant="h5">
            Revenue Tracker
          </Typography>
          <Typography color={grey[700]} fontWeight={500}>
            Get insights on all transactions
          </Typography>
        </Box>
        <Box borderTop={`1px solid`} borderColor={"divider"} display={"flex"} sx={{ overflowX: "scroll" }}>
          <RevenueHeaderItem title="Total Payable" subtitle={`AED ${totalPayable.toLocaleString()}`} />
          <RevenueHeaderItem title="IV Drips" subtitle={`AED ${totalPayable.toLocaleString()}`} />
        </Box>
      </Paper>
    </LoadingSpinner>
  );
};

export default RevenueHeader;
