import { useState, useEffect, useRef } from "react";
import { getBase64 } from "../../../utils/utilFunctions";
import Line from "../Line/index";
import { MdCancel } from "react-icons/md";
import ReactToPrint from "react-to-print";
import { FaFilePdf, FaCopy } from "react-icons/fa";
import { Upload, Empty } from "antd";
import { getColor } from "../getColor";
import { orderAction } from "../../../actions";
import { useDispatch } from "react-redux";
import { IoAttach } from "react-icons/io5";
import { CircularProgress, Tooltip, Modal, Button, Grid } from "@mui/material";
import { showMessage } from "../notification";
import "./style.css";
import "./print.css";

const { Dragger } = Upload;

function ModalPopUp({ data, name, handleClose }) {
  const componentRef = useRef(null);
  const assessmentRef = useRef(null);
  const dispatch = useDispatch();
  const [uploadfileloader, setuploadfileloader] = useState(false);
  const [patients, setpatients] = useState({});
  const [reportData, setreportData] = useState({});
  const [pdf, setPdf] = useState(null);
  const [openModal, setOpenModal] = useState(false);
  const [modalData, setModalData] = useState([]);

  const focusDiv = useRef();
  const doNotFocus = useRef();

  if (focusDiv?.current) {
    focusDiv.current.focus();
  }

  const props = {
    accept: ".pdf",
    name: "files",
    beforeUpload() {
      return false;
    },
    listType: "text",
    onChange(info) {
      if (info.fileList?.length > 1) {
        info.fileList.splice(0, 1);
      }
      setPdf(info.fileList[info.fileList?.length - 1]);
    },
    iconRender() {
      return <FaFilePdf size="28" color="#008690" />;
    },
    onDrop(e) {
      if (e.dataTransfer.files?.length > 1) {
        e.dataTransfer.files.splice(0, 1);
      }
      setPdf(e.dataTransfer.files[e.dataTransfer.files?.length - 1]);
    },
  };

  const handleOpenModal = () => {
    if (patients?.data?.patient?.length > 0) {
      setModalData([...(patients?.data?.patient ?? [])]);
      setOpenModal(true);
    }
  };

  const getViewAssesmmentDisabled = (data) => {
    for (let i = 0; i < data?.length; i++) {
      if (data[i]?.declarationForm?.length > 0) {
        return false;
      }
    }

    return true;
  };
  const handleCloseModal = () => {
    setOpenModal(false);
    setModalData([]);
  };

  const handleSaveReport = async (e, reportData) => {
    setuploadfileloader(true);
    const reports = [];

    const base64Data = await getBase64(pdf.originFileObj);
    const fileType = pdf.type.split("/")[1];
    const report = {
      fileName: pdf.name,
      fileSize: pdf.size,
      fileType,
      image: base64Data,
    };

    reports.push(report);

    let orderUUID = reportData.data.orderUUID;
    let patientID = reportData.data.patient[reportData.index]._id;

    const getpatientIDIndex = () => {
      let { index, data } = reportData;

      let currentpatient = data.patient[index];

      if (currentpatient?.variantName) {
        let myindex = data.patientId.findIndex(
          (patient) =>
            patient?.id === currentpatient._id &&
            patient?.type === currentpatient.type &&
            patient?.variantName === currentpatient.variantName
        );

        return myindex;
      } else {
        let myindex = data.patientId.findIndex(
          (patient) => patient?.id === currentpatient._id && patient?.type === currentpatient.type
        );

        return myindex;
      }
    };

    const handleOrderUpdate = (data) => {
      const handlegetpatientSuccess = async (res) => {
        if (res?.data?.order) {
          setreportData((prev) => ({ ...prev, data: res?.data?.order }));
          dispatch(orderAction.updatedOrderData(res.data.order));
        }
        setuploadfileloader(false);
      };
      if (data?.reportingOrder) {
        dispatch(orderAction.getIndividualOrder(data?.reportingOrder, handlegetpatientSuccess));
      }
      if (!data?.reportingOrder) {
        setuploadfileloader(false);
      }
    };

    dispatch(
      orderAction.orderResult(
        {
          reports,
          reportingOrder: orderUUID,
          reportingPatient: patientID,
          reportingPriceId: reportData.data.patientId[getpatientIDIndex()].priceId,
          isDarLabProduct: reportData.data.patientId[getpatientIDIndex()]?.isDarLabProduct,
          partnerUserUUID: JSON?.parse(localStorage.partnerUserUUID),
        },
        handleOrderUpdate
      )
    );

    setPdf(null);
  };
  const getreport = (report) => {
    let myarray = report.split("/");
    return myarray[myarray?.length - 1];
  };

  const handleCopyLocation = (lattitude, longitude) => {
    let link = `https://maps.google.com/?q=${lattitude},${longitude}`;
    navigator.clipboard.writeText(link);
    showMessage("info", "Location copied successfully!");
  };

  const getpatientIDIndex = () => {
    let { index, data } = reportData;

    let currentpatient = data.patient[index];

    if (currentpatient?.variantName) {
      let myindex = data.patientId.findIndex(
        (patient) =>
          patient?.id === currentpatient._id &&
          patient?.type === currentpatient.type &&
          patient?.variantName === currentpatient.variantName
      );

      return myindex;
    } else {
      let myindex = data.patientId.findIndex(
        (patient) => patient?.id === currentpatient._id && patient?.type === currentpatient.type
      );

      return myindex;
    }
  };

  useEffect(() => {
    setreportData(data);
  }, [data]);

  return (
    <>
      {name === "viewDetails" ? (
        <div
          style={{
            marginTop: "8vh",
            display: "flex",
            justifyContent: "center",
            height: "fit-content",
            maxHeight: "80vh",
          }}
          id="page"
        >
          <div
            style={{
              backgroundColor: "white",
              color: "black",
              padding: "2rem",
              borderRadius: "0.8rem",
              overflowY: "scroll",
            }}
          >
            <div id="viewDetails" ref={componentRef}>
              <p
                style={{
                  cursor: "pointer",
                  float: "right",
                }}
                onClick={handleClose}
                className="noprint"
              >
                <MdCancel style={{ position: "fixed" }} color="#008690" size="25" />{" "}
              </p>

              <p>
                Booking ID: &nbsp;
                <span
                  style={{
                    fontSize: "1.5rem",
                    fontWeight: "bold",
                  }}
                >
                  {patients?.data?.orderUUID}
                </span>
              </p>
              {patients?.data?.patient?.length > 0 ? (
                patients?.data?.patient?.map((patient, index) => (
                  <div key={index} ref={patients.index === index ? focusDiv : doNotFocus}>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                        alignItems: "center",
                        marginBottom: "0.8rem",
                      }}
                    >
                      <div style={{ width: "380px" }}>
                        <p style={{ marginRight: "2rem" }}>
                          <span style={{ fontWeight: "bold" }}>
                            Patient {patients?.data?.patient?.length > 1 ? index + 1 : ""}
                          </span>
                          : {patients?.data?.orderUUID}
                          {patients?.data?.patient?.length > 1 ? ` - ${index + 1}` : ""}
                        </p>{" "}
                      </div>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                        alignItems: "center",
                        marginBottom: "0.8rem",
                      }}
                    >
                      <Grid container spacing={3}>
                        {patient.speciality?.map((speciality, sindex) => (
                          <Grid item xs={6} key={sindex}>
                            <p>
                              <span
                                style={{
                                  fontWeight: "bold",
                                }}
                              >
                                {speciality?.type ? `${speciality?.type}` : " "}
                              </span>

                              <span
                                style={{
                                  textTransform: "uppercase",
                                  fontWeight: "bold",
                                  color: `${
                                    patient?.type === "Covid-19 Antibody Test" ? "#034c9c" : getColor(speciality?.variantName)
                                  }`,
                                }}
                              >
                                {speciality?.type?.includes("Antibody")
                                  ? " - Antibody"
                                  : speciality?.variantName
                                  ? ` - ${speciality?.variantName}`
                                  : ""}
                              </span>
                            </p>
                          </Grid>
                        ))}
                      </Grid>
                    </div>

                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                      }}
                    >
                      <div style={{ width: "380px" }}>
                        {" "}
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "space-between",
                            marginRight: "3rem",
                          }}
                        >
                          <div
                            style={{
                              marginRight: "20px",
                              color: "#5a5a5a",
                            }}
                          >
                            <p>
                              <span style={{ width: "fit-content" }}>Name: </span> <br />
                              <span style={{ width: "fit-content" }}>Date of Birth: </span> <br />
                              <span style={{ width: "fit-content" }}>Gender: </span> <br />
                              <span style={{ width: "fit-content" }}>E-mail: </span> <br />
                              <span style={{ width: "fit-content" }}>Phone no.: </span> <br />
                              <span style={{ width: "max-content" }}>Alternate Phone no.: </span>
                            </p>
                          </div>
                          <div style={{ color: "rgb(19, 19, 19)" }}>
                            <p>
                              <span
                                style={{
                                  textTransform: "capitalize",
                                  fontWeight: "bold",
                                  width: "fit-content",
                                }}
                              >
                                {patient?.name ? patient?.name : " "}
                              </span>{" "}
                              <br />
                              <span style={{ width: "fit-content" }}>
                                {patient?.dateOfBirth ? patient?.dateOfBirth : " "}
                              </span>{" "}
                              <br />
                              <span style={{ width: "fit-content" }}>{patient?.sex ? patient?.sex : " "}</span> <br />
                              <span style={{ width: "fit-content" }}>{patient?.email ? patient?.email : " "}</span> <br />
                              <span style={{ width: "fit-content" }}>{patient?.phoneNumber ? patient?.phoneNumber : " "}</span>
                              <br />
                              <span style={{ width: "fit-content" }}>
                                {patients?.data?.alternatePhoneNumber ? patients?.data?.alternatePhoneNumber : " "}
                              </span>
                            </p>
                          </div>
                        </div>
                      </div>
                      <div style={{ width: "400px" }}>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "space-between",
                          }}
                        >
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              marginRight: "20px",
                              color: "#5a5a5a",
                              width: "fit-content",
                            }}
                          >
                            <p style={{ width: "150px" }}>
                              <span style={{ width: "fit-content" }}>EID/Passport no.: </span> <br />
                              <span style={{ width: "fit-content" }}>Nationality: </span>
                              <br />
                              <span style={{ width: "fit-content" }}> Location: </span>
                              <br />
                              <span style={{ width: "fit-content" }}> Flat/Villa No: </span>
                              <br />
                              <span style={{ width: "fit-content" }}> Address: </span>
                              <br />
                              <Tooltip title="Copy location">
                                <span
                                  style={{
                                    cursor: "pointer",
                                    color: "#008690",
                                  }}
                                  className="noprint"
                                  onClick={() =>
                                    handleCopyLocation(patients?.data?.address?.lattitude, patients?.data?.address?.longitude)
                                  }
                                >
                                  Copy location <FaCopy size="14" />
                                </span>
                              </Tooltip>
                            </p>
                          </div>
                          <div style={{ color: "rgb(19, 19, 19)" }}>
                            <p>
                              <span
                                style={{
                                  textTransform: "capitalize",
                                  fontWeight: "bold",
                                  width: "fit-content",
                                }}
                              >
                                {patient?.nationalId ? patient?.nationalId : " "}
                              </span>
                              <br />
                              <span style={{ width: "fit-content" }}>{patient?.nationality ? patient?.nationality : " "}</span>
                              <br />
                              <span style={{ width: "fit-content" }}>
                                {patients?.data?.emirate ? patients?.data?.emirate : " "}
                              </span>
                              <br />
                              <span style={{ width: "fit-content" }}>
                                {patients?.data?.address?.flatOrVillaNumber ? patients?.data?.address?.flatOrVillaNumber : " "}
                              </span>{" "}
                              <br />
                              <span style={{ width: "fit-content" }}>
                                {patients?.data?.address?.address ? patients?.data?.address?.address : " "}
                              </span>
                              <br />
                              <span style={{ width: "fit-content" }}>
                                {patients?.data?.address?.detail ? patients?.data?.address?.detail : " "}
                              </span>
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      style={{
                        marginTop: "1rem",
                        marginBottom: "1rem",
                        // marginLeft: "1rem",
                      }}
                      className="noshowindetails"
                    >
                      {patient?.declarationForm ? <p style={{ color: "#5a5a5a" }}>Assessment form:</p> : ""}

                      {patient?.declarationForm?.map((qa, key) => (
                        <div key={key} style={{ marginBottom: "1rem" }}>
                          <p
                            style={{
                              marginBottom: "0",
                              color: "black",
                            }}
                          >
                            {key + 1}. <span style={{ textTransform: "capitalize" }}>{qa?.question}</span>
                          </p>
                          <p style={{ marginLeft: "1rem" }}>
                            {qa?.answer?.length > 0 ? (
                              qa?.answer?.map((ans, ansKey) => (
                                <span
                                  style={{
                                    textTransform: "capitalize",
                                    marginRight: "1rem",
                                  }}
                                  key={ansKey}
                                >
                                  {ans?.value}
                                  {ansKey < qa?.answer?.length - 1 ? " ," : ""}
                                </span>
                              ))
                            ) : (
                              <span style={{ textTransform: "capitalize" }}>{qa?.answer?.value}</span>
                            )}
                          </p>
                        </div>
                      ))}
                    </div>

                    <p style={{ padding: "20px 0" }} className="hidePrint">
                      {patients?.data?.patient?.length === index + 1 ? "" : <Line />}
                    </p>
                    {patients?.data?.patient?.length === index + 1 ? "" : <p className="pageBreak"></p>}
                  </div>
                ))
              ) : (
                <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
              )}
            </div>

            <div style={{ display: "flex", justifyContent: "center" }}>
              <ReactToPrint
                trigger={() => (
                  <Button
                    variant="contained"
                    color="primary"
                    size="large"
                    style={{
                      borderRadius: "1.8rem",
                      marginRight: "1rem",
                      width: "250px",
                    }}
                    className="noprint"
                  >
                    Download Patient Data
                  </Button>
                )}
                content={() => componentRef.current}
              />
              {getViewAssesmmentDisabled(patients?.data?.patient) ? null : (
                <Button
                  style={{ borderRadius: "1.8rem", width: "250px" }}
                  variant="contained"
                  color="primary"
                  className="noprint"
                  onClick={handleOpenModal}
                  disabled={getViewAssesmmentDisabled(patients?.data?.patient)}
                >
                  View Assesment
                </Button>
              )}
            </div>
          </div>
        </div>
      ) : (
        <div
          style={{
            marginTop: "8vh",
            display: "flex",
            justifyContent: "center",
            height: "fit-content",
            maxHeight: "80vh",
            color: "#5a5a5a",
          }}
        >
          <div
            style={{
              backgroundColor: "white",
              padding: "2rem",
              borderRadius: "0.8rem",
              overflowY: "scroll",
              color: "#5a5a5a",
              width: "50vw",
              maxWidth: "80vw",
            }}
          >
            <p style={{ float: "right", cursor: "pointer" }} onClick={handleClose}>
              <MdCancel color="#008690" size="25" />{" "}
            </p>
            <h1 style={{ fontWeight: "bold", textAlign: "center" }}>Upload Report</h1>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <div>
                <div>Booking ID</div>
                <div
                  style={{
                    fontWeight: "bold",
                    color: "rgb(19, 19, 19)",
                  }}
                >
                  {reportData?.data?.orderUUID}
                </div>
              </div>
              <div>
                <div>Patient</div>
                <div
                  style={{
                    fontWeight: "bold",
                    color: "rgb(19, 19, 19)",
                  }}
                >
                  {reportData?.data?.patient[reportData.index]?.name ? reportData?.data?.patient[reportData.index]?.name : " "}
                </div>
              </div>
              <div>
                <div>Service</div>
                <div
                  style={{
                    display: "flex",
                    fontWeight: "bold",
                  }}
                >
                  {reportData?.data?.patient[reportData.index]?.type}
                  <div
                    style={{
                      color: `${
                        reportData?.data?.patient[reportData.index]?.type === "Covid-19 Antibody Test"
                          ? "#034c9c"
                          : getColor(reportData?.data?.patient[reportData.index]?.variantName)
                      }`,
                      textTransform: "uppercase",
                      fontWeight: "bold",
                    }}
                  >
                    {reportData?.data?.patient[reportData.index]?.type?.includes("Antibody")
                      ? " - Antibody"
                      : reportData?.data?.patient[reportData.index]?.variantName
                      ? ` - ${reportData?.data?.patient[reportData.index]?.variantName}`
                      : ""}
                  </div>
                </div>
              </div>
            </div>
            <div style={{ marginTop: "1rem" }}>
              {/* <p
                style={{
                  textAlign: "center",
                  width: "100%",
                  marginBottom: "0",
                }}
              >
                {reportData?.data?.patientId[getpatientIDIndex()]?.report ? (
                  <span
                    style={{
                      textAlign: "center",
                      fontWeight: "bold",
                      color: "rgb(19, 19, 19)",
                    }}
                  >
                    Existing Report:&nbsp;
                    <span
                      style={{
                        color: "#008690",
                        textTransform: "capitalize",
                        cursor: "pointer",
                      }}
                      onClick={() => window.open(`${reportData?.data?.patientId[getpatientIDIndex()]?.report}`, "_blank")}
                    >
                      {getreport(reportData?.data?.patientId[getpatientIDIndex()]?.report)}
                    </span>
                  </span>
                ) : (
                  <span
                    style={{
                      textAlign: "center",
                      fontWeight: "bold",
                      color: "rgb(19, 19, 19)",
                    }}
                  >
                    Upload Report
                  </span>
                )}
              </p> */}
              <Dragger
                {...props}
                style={{
                  borderColor: "#008690",
                }}
              >
                <div
                  style={{
                    height: "140px",
                  }}
                >
                  <p
                    style={{
                      textAlign: "center",
                      fontWeight: "bold",
                    }}
                  >
                    {reportData?.data?.patientId[getpatientIDIndex()]?.report ? "Replace " : "Upload "}
                    Report
                  </p>
                  <p
                    style={{
                      textAlign: "center",
                    }}
                  >
                    Click here to browse or drag file here to upload
                  </p>
                  {pdf?.name ? (
                    <p
                      style={{
                        textAlign: "center",
                        fontSize: "20px",
                        color: "#008690",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        fontWeight: "bold",
                      }}
                    >
                      <IoAttach size={28} />
                      {pdf?.name}
                    </p>
                  ) : (
                    <p
                      style={{
                        textAlign: "center",
                        fontSize: "20px",
                        color: "#b8b8b8",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <IoAttach size={28} />
                      Upload file here
                    </p>
                  )}

                  {uploadfileloader ? (
                    <div>
                      <CircularProgress size={"1.6rem"} />
                    </div>
                  ) : null}
                </div>
              </Dragger>

              {pdf?.name ? (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    marginTop: "1rem",
                  }}
                >
                  <Button
                    color="secondary"
                    variant="outlined"
                    style={{
                      borderRadius: "2rem",
                      minWidth: "130px",
                    }}
                    onClick={() => {
                      setPdf(null);
                    }}
                  >
                    Cancel
                  </Button>
                  <Button
                    color="primary"
                    variant="contained"
                    style={{
                      borderRadius: "2rem",
                      minWidth: "130px",
                    }}
                    onClick={(e) => handleSaveReport(e, reportData)}
                  >
                    Upload
                  </Button>
                </div>
              ) : null}
            </div>
          </div>
        </div>
      )}

      <Modal
        open={openModal}
        onClose={handleCloseModal}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <div
          style={{
            backgroundColor: "white",
            border: "none",
            borderRadius: "1rem",
            padding: "2rem",
            minWidth: "500px",
            maxWidth: "800px",
            maxHeight: "80vh",
            overflowY: "scroll",
          }}
          id="assesmentForm"
        >
          <div ref={assessmentRef}>
            <p
              style={{
                cursor: "pointer",
                float: "right",
              }}
              onClick={handleCloseModal}
            >
              <MdCancel className="noprint" style={{ position: "fixed" }} color="#008690" size="25" />{" "}
            </p>
            <h2
              style={{
                textAlign: "center",
                marginTop: "1rem",
                fontWeight: "bold",
              }}
            >
              Assesment Form
            </h2>
            <div style={{ marginBottom: "2rem" }}>
              {modalData?.map((patient, index) => (
                <div key={index} style={{ marginBottom: "2rem" }}>
                  <h4
                    style={{
                      borderBottom: "1px solid #d9d9d9",

                      paddingBottom: "0.5rem",
                    }}
                  >
                    <span style={{ fontWeight: "bold" }}>EID/Password No.:</span>
                    <span>&nbsp;{patient?.nationalId}</span>
                    <br />
                    <span style={{ fontWeight: "bold" }}>Patient Name:</span>
                    <span>&nbsp;{patient?.name}</span>
                  </h4>

                  <div>
                    {patient?.declarationForm?.map((qa, key) => (
                      <div key={key} style={{ marginBottom: "1rem", marginLeft: "1rem" }}>
                        <p
                          style={{
                            marginBottom: "0",
                            color: "black",
                          }}
                        >
                          {key + 1}. <span style={{ textTransform: "capitalize" }}>{qa?.question}</span>
                        </p>
                        <p style={{ marginLeft: "1rem" }}>
                          {qa?.answer?.length > 0 ? (
                            qa?.answer?.map((ans, ansKey) => (
                              <span
                                style={{
                                  textTransform: "capitalize",
                                  marginRight: "1rem",
                                }}
                                key={ansKey}
                              >
                                {ans?.value}
                                {ansKey < qa?.answer?.length - 1 ? " ," : ""}
                              </span>
                            ))
                          ) : (
                            <span style={{ textTransform: "capitalize" }}>{qa?.answer?.value}</span>
                          )}
                        </p>
                      </div>
                    ))}
                  </div>
                </div>
              ))}
            </div>
          </div>
          {/* <div style={{ display: "flex", justifyContent: "center" }}>
            <ReactToPrint
              trigger={() => (
                <Button
                  variant="outlined"
                  color="primary"
                  size="large"
                  style={{
                    borderRadius: "1.8rem",
                    marginRight: "1rem",
                    width: "250px",
                    color: "rgb(19, 19, 19)",
                  }}
                  className="noprint"
                >
                  <FaFilePdf size="20" color="#e86956" /> &nbsp;Download PDF
                </Button>
              )}
              content={() => assessmentRef.current}
            />
            <ExportToExcelAssessment data={modalData} className="noprint" />
          </div> */}
        </div>
      </Modal>
    </>
  );
}

export default ModalPopUp;
