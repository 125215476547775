import { assignedType } from "../types";

export const initialState = {
  bookingsOrders: [],
  quickScheduleOrders: [],
  loader: false,
};

export function assignedReducer(state = initialState, action) {
  switch (action.type) {
    case assignedType.REQUEST_ASSIGNED_BOOKING:
      return {
        ...state,
        loader: true,
      };
    case assignedType.REQUEST_ASSIGNED_BOOKING_DONE:
      return {
        ...state,
        loader: false,
      };
    case assignedType.GET_ASSIGNED_ORDERS:
      return {
        ...state,
        bookingsOrders: action.data,
        loader: false,
      };

    case assignedType.GET_QUICK_SCHEDULE_ORDERS:
      return {
        ...state,
        quickScheduleOrders: action.data,
        loader: false,
      };

    default: {
      return {
        ...state,
      };
    }
  }
}
