import moment from "moment";
import BookingModel from "../models/BookingModel";
import { StaffMemberModel } from "../models/StaffMemberModel";
import { useMemo } from "react";
import getStartMomentFromDateObj from "../utils/getStartMomentFromDateObj";
import useAllPartnerNurses from "./useAllPartnerNurses";
import useProductDetails from "./useProductDetails";

export type DelayedNurseStartServiceAlertData = { nurse?: StaffMemberModel; dateMoment: moment.Moment };

export default function useBookingHasDelayedNurse({ order }: { order: BookingModel }) {
  const { query } = useAllPartnerNurses();
  const { query: productDetailsQuery } = useProductDetails({
    productUUID: order.patientId?.[0]?.productUUID,
    area: order.emirate,
  });

  const delayedNurseData = useMemo<DelayedNurseStartServiceAlertData | undefined>(() => {
    const now = moment();
    const formattedNow = now.format("DD/MM/YYYY");
    if (order.status !== "PROCESSING") return;
    if (productDetailsQuery.data?.product?.isDarLabProduct) return;
    // if (productDetailsQuery.data?.product?.isDarLabProduct && order.isSampleCollected) return;
    for (const assignedNurse of order.assignedNurses ?? []) {
      const nurseDetails: StaffMemberModel | undefined = query.data?.data.nurses?.find((nurse: StaffMemberModel) => {
        return nurse.nurseUUID === assignedNurse.nurseUUID;
      });
      for (const dateObj of assignedNurse.dateArray ?? []) {
        if (dateObj.date !== formattedNow) continue;
        const dateMoment = getStartMomentFromDateObj({ date: dateObj.date, time: dateObj.time ?? "" }); // moment(`${dateObj.date} ${dateObj.time?.split(" - ")[1]}`, "DD/MM/YYYY hh:mmA").subtract(30, "minute");
        if (
          productDetailsQuery.data?.product?.isDarLabProduct &&
          now.isSameOrAfter(dateMoment.clone().add(20, "minute")) &&
          !order.isSampleCollected
        ) {
          return {
            nurse: nurseDetails,
            dateMoment: dateMoment,
          };
        } else if (now.isSameOrAfter(dateMoment.clone().add(20, "minute")) && !dateObj.startTimestamp) {
          return {
            nurse: nurseDetails,
            dateMoment: dateMoment,
          };
        }
      }
    }
  }, [
    order.assignedNurses,
    order.isSampleCollected,
    order.status,
    productDetailsQuery.data?.product?.isDarLabProduct,
    query.data?.data.nurses,
  ]);

  return { delayedNurseData };
}
