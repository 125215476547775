import { LoadingButton } from "@mui/lab";
import { InputAdornment, TextField } from "@mui/material";
import { FC, useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import { PartnerUserURLData } from "./useUserSignUpLink";
import PartnerUserRoleSelect from "../../components/PartnerUserRoleSelect";
import decrypt from "../../services/decrypt";

const SignUpForm: FC<SignUpFormProps> = ({ submitLoading, handleFormSubmit }) => {
  const params = useParams<{ userData: string }>();
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [role, setRole] = useState("");
  const [mobileNumber, setMobileNumber] = useState("");

  const userData = useMemo<PartnerUserURLData | null>(() => {
    try {
      return decrypt(decodeURIComponent(params.userData));
    } catch (e) {
      return null;
    }
  }, [params.userData]);

  useEffect(() => {
    if (userData?.name) {
      setName(userData?.name);
    }
    if (userData?.email) {
      setEmail(userData?.email);
    }
    if (userData?.role) {
      setRole(userData.role);
    }
  }, [userData?.email, userData?.name, userData?.role]);

  return (
    <form
      style={{ display: "flex", flexDirection: "column", gap: "16px", padding: "0px 16px" }}
      onSubmit={(e) => {
        e.preventDefault();
        e.stopPropagation();
        handleFormSubmit({
          email: email.trim(),
          mobileNumber: mobileNumber.replaceAll(" ", "").trim(),
          name: name.trim(),
          role: role.trim(),
        });
      }}
    >
      <TextField
        value={email}
        onChange={(e) => {
          setEmail(e.target.value);
        }}
        inputProps={{ style: { WebkitTextFillColor: "black" } }}
        disabled={true}
        type="email"
        required={true}
        id="email"
        label="Email"
        sx={{ width: "100%" }}
      />
      <PartnerUserRoleSelect
        value={role}
        onChange={function (value: string): void {
          setRole(value);
        }}
      />
      <TextField
        value={name}
        onChange={(e) => {
          setName(e.target.value);
        }}
        required={true}
        id="name"
        label="Name"
        sx={{ width: "100%" }}
      />
      <TextField
        InputProps={{
          startAdornment: <InputAdornment position="start">+971</InputAdornment>,
        }}
        prefix="+971"
        value={mobileNumber}
        onChange={(e) => {
          setMobileNumber(e.target.value);
        }}
        required={true}
        id="phone"
        label="Mobile Number"
        sx={{ width: "100%" }}
      />
      <LoadingButton variant="contained" type="submit" loading={submitLoading}>
        <span style={{ color: "white" }}>Continue</span>
      </LoadingButton>
    </form>
  );
};

export type SignUpFormType = { name: string; email: string; role: string; mobileNumber: string };

interface SignUpFormProps {
  submitLoading: boolean;
  handleFormSubmit: (params: SignUpFormType) => void;
}

export default SignUpForm;
