import { useQuery } from "@tanstack/react-query";
import { getProductDetails } from "../services/schemas.services";
import { useMemo } from "react";

function useProductDetails(params: { productUUID?: string; area?: string }) {
  const queryKey = useMemo(
    () => ["schemas/getparticularproductwithlocation", params.area, params.productUUID],
    [params.area, params.productUUID]
  );

  const query = useQuery(queryKey, () => getProductDetails({ area: params.area ?? "", productUUID: params.productUUID ?? "" }), {
    enabled: !!params.area && !!params.productUUID,
    refetchOnWindowFocus: false,
    cacheTime: Infinity,
    staleTime: Infinity,
  });

  return { query, queryKey };
}

export default useProductDetails;
