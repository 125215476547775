import BookingModel, { PatientReportModel } from "../../models/BookingModel";
import { Button, Dialog, DialogActions, DialogTitle } from "@mui/material";
import { FC, useCallback } from "react";
import { useMutation } from "@tanstack/react-query";
import { deletePatientBookingReport } from "../../services";
import { LoadingButton } from "@mui/lab";
import { showMessage } from "../../components/common/notification";

const DeletePatientReportModal: FC<DeletePatientReportModalProps> = ({
  onReportDeletedSuccessfully,
  onClose,
  open,
  report,
  orderUUID,
  patientID,
}) => {
  const deleteReportMutation = useMutation({ mutationFn: deletePatientBookingReport });

  const handleYesClicked = useCallback(async () => {
    const res = await deleteReportMutation.mutateAsync({
      orderUUID: orderUUID,
      patientID: patientID,
      reportURL: report.report ?? "",
    });
    if (res?.order?.orderUUID) {
      onReportDeletedSuccessfully(res.order);
      onClose();
    } else {
      showMessage("error", "Something went wrong");
    }
  }, [deleteReportMutation, onClose, onReportDeletedSuccessfully, orderUUID, patientID, report.report]);

  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="delete-report-confirmation-dialog-title"
      aria-describedby="delete-report-confirmation-dialog-description"
    >
      <DialogTitle id="delete-report-confirmation-dialog-title">
        Are you sure you want to delete the {report.tests?.join(", ")} report ?
      </DialogTitle>
      <DialogActions>
        <Button variant="outlined" onClick={onClose}>
          Cancel
        </Button>
        <LoadingButton
          autoFocus
          variant="outlined"
          color="error"
          onClick={handleYesClicked}
          loading={deleteReportMutation.isLoading}
        >
          Yes
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

interface DeletePatientReportModalProps {
  open: boolean;
  report: PatientReportModel;
  orderUUID: string;
  patientID: string;
  onReportDeletedSuccessfully: (order: BookingModel) => void;
  onClose: () => void;
}

export default DeletePatientReportModal;
