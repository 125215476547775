// import { LoadingButton } from "@mui/lab";
import { Button, Dialog, DialogContent, DialogTitle, TextField } from "@mui/material";
import { FC, useCallback, useMemo, useState } from "react";
import { PartnerUserModel, PartnerUserRoleType } from "../../models/PartnerUserModel";
import PartnerUserRoleSelect from "../../components/PartnerUserRoleSelect";
import useGetPartnerUsers from "../../hooks/useGetPartnerUsers";
import ReduxStateModel from "../../models/ReduxStateModel";
import { useDispatch, useSelector } from "react-redux";
import { useMutation } from "@tanstack/react-query";
import { editPartnerUser } from "../../services/partner.service";
import { showMessage } from "../../components/common/notification";
import { authAction } from "../../actions";

const EditUserModal: FC<EditUserModalProps> = ({ onClose, open, user, onEditSuccess }) => {
  const dispatch = useDispatch();
  const [name, setName] = useState(user.name ?? "");
  const [role, setRole] = useState(user.role ?? "");
  const [email, setEmail] = useState(user.email ?? "");
  const authReducer = useSelector((state: ReduxStateModel) => state.authReducer?.setting);
  const currentUserID = authReducer?.partnerUserUUID;

  const { query } = useGetPartnerUsers({ partnerUUID: authReducer?.partnerUUID });

  const editUserMutation = useMutation({ mutationFn: editPartnerUser });

  const handleFormSubmit = useCallback(async () => {
    const res = await editUserMutation.mutateAsync({
      currentUserID: currentUserID ?? "",
      email: email,
      name: name,
      partnerUserUUID: user.partnerUserUUID ?? "",
      role: role as PartnerUserRoleType,
    });
    if (res?.success) {
      showMessage("success", "User was edit successfully");
      onClose();
      onEditSuccess();
      dispatch(authAction.getUserData({ userName: authReducer?.userName, phoneNumber: authReducer?.phoneNumber }));
    } else {
      showMessage("error", "Something went wrong");
    }
  }, [
    authReducer?.phoneNumber,
    authReducer?.userName,
    currentUserID,
    dispatch,
    editUserMutation,
    email,
    name,
    onClose,
    onEditSuccess,
    role,
    user.partnerUserUUID,
  ]);

  const disableRoleSelect = useMemo(() => {
    const superAdminUsers = query.data?.users?.filter((user) => user.role === "SUPER_ADMIN");
    return superAdminUsers?.length === 1 && user.partnerUserUUID === superAdminUsers[0].partnerUserUUID;
  }, [query.data?.users, user.partnerUserUUID]);

  return (
    <Dialog open={open} onClose={onClose} aria-labelledby="edit-partner-user-modal" maxWidth="sm" fullWidth={true}>
      <DialogTitle sx={{ textAlign: "center" }}>Edit {user.name}</DialogTitle>
      <DialogContent>
        <form
          style={{ width: "100%", display: "flex", flexDirection: "column", gap: "16px" }}
          onSubmit={(e) => {
            e.stopPropagation();
            e.preventDefault();
            handleFormSubmit();
          }}
        >
          <TextField
            id="partner-user-name"
            label="Name"
            variant="outlined"
            style={{ width: "100%" }}
            required={true}
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
          <TextField
            id="partner-user-email"
            label="Email"
            variant="outlined"
            style={{ width: "100%" }}
            type="email"
            required={true}
            value={email}
            onChange={(e) => {
              setEmail(e.target.value);
            }}
          />
          <PartnerUserRoleSelect
            value={role ?? ""}
            disabled={disableRoleSelect}
            onChange={function (value: string): void {
              setRole(value as PartnerUserRoleType);
            }}
          />
          <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
            <Button variant="outlined" onClick={() => onClose()}>
              Cancel
            </Button>
            <Button variant="contained" type="submit">
              <span style={{ color: "white" }}>Save</span>
            </Button>
          </div>
        </form>
      </DialogContent>
    </Dialog>
  );
};

interface EditUserModalProps {
  open: boolean;
  user: PartnerUserModel;
  onEditSuccess: () => void;
  onClose: () => void;
}

export default EditUserModal;
