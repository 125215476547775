import { FC } from "react";
import { BsClockHistory, BsGeoFill } from "react-icons/bs";
import { CiRoute } from "react-icons/ci";
import { Select, Switch, Tooltip } from "antd";
import useAutoNavMapViewControlsCard from "../../hooks/useAutoNavMapViewControlsCard";

const AutoNavMapViewControlsCard: FC<AutoNavMapViewControlsCardProps> = () => {
  const {
    avoidTolls,
    delayOptions,
    useDriverSkills,
    selectedNurseDelay,
    bookingEndPickupDelay,
    loadingDriverSettings,
    handleAvoidTollsChanged,
    handleNurseDelayChanged,
    handleUseDriverSkillsChanged,
    handleBookingEndPickupDelayChange,
  } = useAutoNavMapViewControlsCard();

  return (
    <div
      style={{
        // height: "50px",
        backgroundColor: "white",
        borderRadius: "10px",
        boxShadow: "rgba(17, 12, 46, 0.15) 0px 48px 100px 0px",
        padding: "16px 32px",
      }}
    >
      <div className="controls" style={{ display: "flex", gap: "32px", color: "black", fontWeight: "500" }}>
        <div style={{ width: "280px" }}>
          <div style={{ display: "flex", alignItems: "center", gap: "4px", position: "relative" }}>
            <BsClockHistory style={{ color: "#bf9001", position: "absolute", left: "-18px" }} /> Permissible Delay
          </div>
          <div style={{ display: "flex", gap: "8px", width: "100%" }}>
            <div style={{ flex: "auto" }}>
              <div style={{ color: "gray", fontWeight: "500" }}>Drop delay</div>
              <Select
                className="permissible-delay-select"
                style={{ color: "gray", fontWeight: "500", marginTop: "-3px", width: "100%" }}
                value={selectedNurseDelay ?? null}
                loading={loadingDriverSettings}
                disabled={loadingDriverSettings}
                onChange={handleNurseDelayChanged}
                options={delayOptions}
              />
            </div>
            <div style={{ flex: "auto" }}>
              <div style={{ color: "gray", fontWeight: "500" }}>Retrieval delay</div>
              <Select
                className="permissible-delay-select"
                style={{ color: "gray", fontWeight: "500", marginTop: "-3px", width: "100%" }}
                value={bookingEndPickupDelay ?? null}
                loading={loadingDriverSettings}
                disabled={loadingDriverSettings}
                onChange={handleBookingEndPickupDelayChange}
                options={delayOptions}
              />
            </div>
          </div>
        </div>
        <div style={{ width: "180px", display: "flex", flexDirection: "column", alignItems: "flex-start" }}>
          <Tooltip
            className="driver-base-location-restriction-tooltip"
            overlayClassName="driver-base-location-restriction-tooltip"
            color="white"
            title="Applying this filter will limit the drivers to operate only in their base location city or emirate."
          >
            <div style={{ display: "flex", alignItems: "center", gap: "4px", position: "relative" }}>
              <BsGeoFill style={{ color: "#bf9001", position: "absolute", left: "-18px" }} />
              Base Location Restriction
            </div>
          </Tooltip>
          <Switch size="small" checked={useDriverSkills} onChange={handleUseDriverSkillsChanged} />
          {/* <Select
            className="permissible-time-spent-on-road-select"
            style={{ color: "gray", fontWeight: "500", height: "32px", maxHeight: "32px" }}
            value={selectedNurseVehicleTime}
            loading={loadingDriverSettings}
            disabled={loadingDriverSettings}
            defaultValue={5}
            onChange={handleNurseTimeSpentInVehicleChanged}
            options={[
              // {
              //   label: "5 minutes",
              //   value: 5,
              // },
              // {
              //   label: "10 minutes",
              //   value: 10,
              // },
              // {
              //   label: "15 minutes",
              //   value: 15,
              // },
              // {
              //   label: "20 minutes",
              //   value: 20,
              // },
              // {
              //   label: "25 minutes",
              //   value: 25,
              // },
              {
                label: "30 minutes",
                value: 30,
              },
              // {
              //   label: "35 minutes",
              //   value: 35,
              // },
              {
                label: "40 minutes",
                value: 40,
              },
              // {
              //   label: "45 minutes",
              //   value: 45,
              // },
              {
                label: "50 minutes",
                value: 50,
              },
              // {
              //   label: "55 minutes",
              //   value: 55,
              // },
              {
                label: "60 minutes",
                value: 60,
              },
              // {
              //   label: "65 minutes",
              //   value: 65,
              // },
              {
                label: "70 minutes",
                value: 70,
              },
              // {
              //   label: "75 minutes",
              //   value: 75,
              // },
              {
                label: "80 minutes",
                value: 80,
              },
              // {
              //   label: "85 minutes",
              //   value: 85,
              // },
              {
                label: "90 minutes",
                value: 90,
              },
              {
                label: "100 minutes",
                value: 100,
              },
              {
                label: "110 minutes",
                value: 110,
              },
              {
                label: "120 minutes",
                value: 120,
              },
              {
                label: "180 minutes",
                value: 180,
              },
            ]}
          /> */}
        </div>
        <div style={{ width: "80px", display: "flex", flexDirection: "column", alignItems: "flex-start", gap: "4px" }}>
          <div style={{ display: "flex", alignItems: "center", gap: "4px", position: "relative", marginBottom: "-3px" }}>
            <CiRoute style={{ color: "#bf9001", position: "absolute", left: "-20px", fontSize: "18px" }} />
            Avoid Tolls
          </div>
          <Switch size="small" checked={avoidTolls} onChange={handleAvoidTollsChanged} />
        </div>
      </div>
    </div>
  );
};

interface AutoNavMapViewControlsCardProps {}

export default AutoNavMapViewControlsCard;
