import { Accordion, AccordionDetails, AccordionSummary, Grid } from "@mui/material";
import { FC } from "react";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import PatientAssesmentFormItem from "./PatientAssesmentFormItem";
import BookingModel from "../../models/BookingModel";
import useUserByID from "../../hooks/useUserByID";

const TRTAssesmentForm: FC<TRTAssesmentFormProps> = ({ order }) => {
  const { query } = useUserByID({ userUUID: order.userUUID });

  const user = query.data?.user;

  if (query.isLoading) return null;
  if (!user?.trtProgramAssesment) return null;

  return (
    <Accordion style={{ marginTop: "1rem", width: "100%" }}>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <div style={{ fontWeight: "bold" }}>Assesment Form</div>
      </AccordionSummary>
      <AccordionDetails>
        <Grid container>
          <Grid xs={12} md={6}>
            <PatientAssesmentFormItem
              label={"Eligible"}
              value={
                <span style={{ color: user?.trtProgramAssesment?.isEligible ? "green" : "red" }}>
                  {user?.trtProgramAssesment?.isEligible ? "Yes" : "No"}
                </span>
              }
            />
          </Grid>
          <Grid xs={12} md={6}>
            <PatientAssesmentFormItem label={"Age"} value={<span>{user?.trtProgramAssesment?.age} Years</span>} />
          </Grid>
          <Grid xs={12} md={6}>
            <PatientAssesmentFormItem
              label={"Chronic Medical Conditions"}
              value={
                <span>
                  {(user?.trtProgramAssesment?.chronicMedicalConditions?.length ?? 0) === 0
                    ? "None"
                    : (user?.trtProgramAssesment?.chronicMedicalConditions?.length ?? 0) === 1 &&
                      user?.trtProgramAssesment?.chronicMedicalConditions?.[0] === "None of the above"
                    ? "None"
                    : user?.trtProgramAssesment?.chronicMedicalConditions?.join(", ")}
                </span>
              }
            />
          </Grid>
          <Grid xs={12} md={6}>
            <PatientAssesmentFormItem
              label={"Consume Alcohol"}
              value={<span>{user?.trtProgramAssesment?.consumeAlcohol ? "Yes" : "No"}</span>}
            />
          </Grid>
          <Grid xs={12} md={6}>
            <PatientAssesmentFormItem
              label={"Symptoms"}
              value={<span>{user?.trtProgramAssesment?.currentSymptoms?.join(", ")}</span>}
            />
          </Grid>
          <Grid xs={12} md={6}>
            <PatientAssesmentFormItem label={"Exercise ?"} value={<span>{user?.trtProgramAssesment?.exercise}</span>} />
          </Grid>
          <Grid xs={12} md={6}>
            <PatientAssesmentFormItem label={"Gender"} value={<span>{user?.trtProgramAssesment?.gender}</span>} />
          </Grid>
          <Grid xs={12} md={6}>
            <PatientAssesmentFormItem label={"Height"} value={<span>{user?.trtProgramAssesment?.height} CM</span>} />
          </Grid>
          <Grid xs={12} md={6}>
            <PatientAssesmentFormItem label={"Weight"} value={<span>{user?.trtProgramAssesment?.weight} KG</span>} />
          </Grid>
          <Grid xs={12} md={6}>
            <PatientAssesmentFormItem
              label={"Are you taking any medication or supplements ?"}
              value={<span>{user?.trtProgramAssesment?.isTakingAnyMedicationOrSupplements ? "Yes" : "No"}</span>}
            />
          </Grid>
          <Grid xs={12} md={6}>
            <PatientAssesmentFormItem
              label={"Medications"}
              value={<span>{user?.trtProgramAssesment?.usingMedications || "-"}</span>}
            />
          </Grid>
          <Grid xs={12} md={6}>
            <PatientAssesmentFormItem
              label={"Objectives"}
              value={<span>{user?.trtProgramAssesment?.objective?.join(", ")}</span>}
            />
          </Grid>
          <Grid xs={12} md={6}>
            <PatientAssesmentFormItem
              label={"Consuming tobacco ?"}
              value={<span>{user?.trtProgramAssesment?.useTobaccoProducts ? "Yes" : "No"}</span>}
            />
          </Grid>
        </Grid>
      </AccordionDetails>
    </Accordion>
  );
};

interface TRTAssesmentFormProps {
  order: BookingModel;
}

export default TRTAssesmentForm;
