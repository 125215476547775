import moment from "moment";
import useDriverForDriverProfile from "./useDriverForDriverProfile";
import DriverProfileImageCard from "./DriverProfileImageCard";
import PersonalDetailsTab from "./PersonalDetailsTab";
import ProfessionalDetailsTab from "./ProfessionalDetailsTab";
import CoverImage from "./CoverImage";
import { FC, useCallback, useState } from "react";
import { Button, Col, Divider, Form, Row, Spin, Tabs } from "antd";
import { useQueryClient, useMutation } from "@tanstack/react-query";
import { useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { showMessage } from "../../components/common/notification";
import { StaffMemberModel } from "../../models/StaffMemberModel";
import { editDriver, createDriver } from "../../services";
import { DriverForm } from "./OldDriverProfile";
import useAutomatedDriverScheduleQueryKey from "../../hooks/useAutomatedDriverScheduleQueryKey";
import ReduxStateModel from "../../models/ReduxStateModel";
import { driverSchedulePageBackgroundColor } from "../../constants";
import useCurrentUserRole from "../../hooks/useCurrentUserRole";
import BasePageLayout from "../../components/BasePageLayout";
import "../NewNurseProfile/new-styles.css";
import { Box } from "@mui/material";

type DriverProfileTabKey = "personal-details" | "professional-details";

const NewDriverProfile: FC = () => {
  const { canUpdate } = useCurrentUserRole();
  const { id: driverUUID } = useParams<{ id?: string }>();
  const [driver, setDriver] = useState<StaffMemberModel>({});
  const [form] = Form.useForm<DriverForm>();
  const [selectedTab, setSelectedTab] = useState<DriverProfileTabKey>("personal-details");
  const history = useHistory();
  const queryClient = useQueryClient();
  const partnerUUID = useSelector((state: ReduxStateModel) => state?.authReducer?.setting?.partnerUUID ?? "");
  const partnerUserUUID: string | undefined = useSelector(
    (state: ReduxStateModel) => state?.authReducer?.setting?.partnerUserUUID
  );
  // const sidebarCollapsed: boolean | undefined = useSelector((state: ReduxStateModel) => state?.sidebarReducer?.collapsed);
  // const width = `calc(100% - ${sidebarCollapsed ? sideMenuCollapsedWidth : sideMenuExpandedWidth}px)`;

  const onDriverFetched = useCallback((driver: StaffMemberModel) => {
    setDriver(driver);
  }, []);

  const { isFetching } = useDriverForDriverProfile(onDriverFetched);

  const editDriverMutation = useMutation({ mutationFn: editDriver });
  const createDriverMutation = useMutation({ mutationFn: createDriver });

  const teamPageQueryKey: [string, string | undefined] = ["nurses", partnerUserUUID];

  const handleEditDriver = async (values: DriverForm) => {
    const res = await editDriverMutation.mutateAsync({
      priority: Number(values.priority),
      activeDays: values.activeDays ?? [],
      address: driver.address ?? "",
      startTime: values.startTime?.format("hh:mmA") ?? "",
      endTime: values.endTime?.format("hh:mmA") ?? "",
      breakStartTime: values.breakStartTime?.format("hh:mmA"),
      breakEndTime: values.breakEndTime?.format("hh:mmA"),
      addressLink: driver.addressLink ?? "",
      capacity: values.capacity ?? 1,
      dob: values.dob?.format("DD/MM/YYYY") ?? "",
      driverUUID: driverUUID!,
      email: values.email ?? driver.email ?? "",
      emiratesIdBackImageUrl: driver.emiratesIdBackImageUrl ?? "",
      emiratesIdFrontImageUrl: driver.emiratesIdFrontImageUrl ?? "",
      isActive: driver.isActive ?? false,
      isDeleted: false,
      licenseImageUrl: driver.licenseImageUrl ?? "",
      mobileNumber: `${values.mobileNumber?.countryCode}${values.mobileNumber?.number}`,
      name: values.name ?? "",
      nationality: values.nationality ?? "",
      partnerUUID: partnerUUID,
      passportImageUrl: driver.passportImageUrl ?? "",
      profileImageUrl: driver.profileImageUrl ?? "",
      sex: values.sex ?? "male",
      emiratesIdBack: driver.emiratesIdBack,
      emiratesIdFront: driver.emiratesIdFront,
      holidays: driver.holidays ?? [],
      license: driver.license,
      passport: driver.passport,
      profileImage: driver.profileImageData,
    });
    if (!res?.success) {
      return showMessage("error", res?.error ?? "Something went wrong");
    }
    showMessage("success", "Driver was updated successfully");
    // queryClient.resetQueries(teamPageQueryKey);
    handleDriverUpdatedSuccessfully({
      ...driver,
      name: values.name ?? driver.name,
      dob: values.dob?.format("DD/MM/YYYY") ?? driver.dob,
      sex: values.sex ?? driver.sex,
      email: values.email ?? driver.email,
      mobileNumber: `${values.mobileNumber?.countryCode}${values.mobileNumber?.number}`,
      nationality: values.nationality ?? driver.nationality,
      address: driver.address,
      addressLink: driver.addressLink,
      capacity: driver.capacity,
      emiratesIdFrontImageUrl: values.emiratesIdFront?.data ?? driver.emiratesIdFrontImageUrl,
      emiratesIdBackImageUrl: values.emiratesIdBack?.data ?? driver.emiratesIdBackImageUrl,
      passportImageUrl: values.passport?.data ?? driver.passportImageUrl,
      licenseImageUrl: values.license?.data ?? driver.licenseImageUrl,
    });
  };

  const handleCreatedDriver = async (values: DriverForm) => {
    const res = await createDriverMutation.mutateAsync({
      activeDays: values.activeDays ?? [],
      priority: Number(values.priority),
      address: driver.address ?? "",
      startTime: values.startTime?.format("hh:mmA") ?? "",
      endTime: values.endTime?.format("hh:mmA") ?? "",
      breakStartTime: values.breakStartTime?.format("hh:mmA"),
      breakEndTime: values.breakEndTime?.format("hh:mmA"),
      addressLink: driver.addressLink ?? "",
      capacity: values.capacity ?? 1,
      dob: values.dob?.format("DD/MM/YYYY") ?? "",
      email: values.email ?? driver.email ?? "",
      emiratesIdBackImageUrl: driver.emiratesIdBackImageUrl ?? "",
      emiratesIdFrontImageUrl: driver.emiratesIdFrontImageUrl ?? "",
      isActive: true,
      isDeleted: false,
      licenseImageUrl: driver.licenseImageUrl ?? "",
      mobileNumber: `${values.mobileNumber?.countryCode}${values.mobileNumber?.number}`,
      name: values.name ?? "",
      nationality: values.nationality ?? "",
      partnerUUID: partnerUUID,
      passportImageUrl: driver.passportImageUrl ?? "",
      profileImageUrl: driver.profileImageUrl ?? "",
      sex: values.sex ?? "male",
      emiratesIdBack: driver.emiratesIdBack,
      emiratesIdFront: driver.emiratesIdFront,
      license: driver.license,
      passport: driver.passport,
      profileImage: driver.profileImageData,
    });
    if (!res?.success) {
      return showMessage("error", res?.error ?? "Something went wrong");
    }
    showMessage("success", "Driver was created successfully");
    // queryClient.resetQueries(teamPageQueryKey);
    const newDriver = {
      ...driver,
      name: values.name ?? driver.name,
      dob: values.dob?.format("DD/MM/YYYY") ?? driver.dob,
      sex: values.sex ?? driver.sex,
      email: values.email ?? driver.email,
      mobileNumber: `${values.mobileNumber?.countryCode}${values.mobileNumber?.number}`,
      nationality: values.nationality ?? driver.nationality,
      address: driver.address,
      addressLink: driver.addressLink,
      capacity: driver.capacity,
      emiratesIdFrontImageUrl: values.emiratesIdFront?.data ?? driver.emiratesIdFrontImageUrl,
      emiratesIdBackImageUrl: values.emiratesIdBack?.data ?? driver.emiratesIdBackImageUrl,
      passportImageUrl: values.passport?.data ?? driver.passportImageUrl,
      licenseImageUrl: values.license?.data ?? driver.licenseImageUrl,
    };
    handleDriverUpdatedSuccessfully(newDriver);
    history.replace("/team");
  };

  const handleFormValidationFinished = (values: DriverForm) => {
    if (!driver?.profileImageData?.data && !driver?.profileImageUrl) {
      return showMessage("error", "Please select a profile image");
    }
    if (!driver.address || !driver.addressLink) {
      setSelectedTab("professional-details");
      return showMessage("error", "Please enter the driver address");
    }
    if (driverUUID) {
      return handleEditDriver(values);
    } else {
      return handleCreatedDriver(values);
    }
  };

  const selectedDate = useSelector((state: ReduxStateModel) => state?.driverSchedulerReducer?.selectedDate ?? moment());

  const queryKey = useAutomatedDriverScheduleQueryKey({ selectedDate });

  const handleDriverUpdatedSuccessfully = (updatedDriver: StaffMemberModel) => {
    setDriver(updatedDriver);
    if (driverUUID) {
      queryClient.resetQueries(queryKey);
      queryClient.setQueryData(teamPageQueryKey, (prevValue: unknown) => {
        if (!prevValue) return prevValue;
        const newValue = JSON.parse(JSON.stringify(prevValue));
        newValue.data.drivers = newValue.data.drivers.map((driver: StaffMemberModel) => {
          if (driver.driverUUID === updatedDriver.driverUUID) {
            return updatedDriver;
          }
          return driver;
        });
        return newValue;
      });
    } else {
      queryClient.setQueryData(teamPageQueryKey, (prevValue: unknown) => {
        if (!prevValue) return prevValue;
        const newValue = JSON.parse(JSON.stringify(prevValue));
        newValue.data.drivers.unshift(updatedDriver);
        return newValue;
      });
    }
    // queryClient.resetQueries(teamPageQueryKey);
  };

  const handleFormValidationFailed = (errors: { errorFields?: { name?: (string | number)[] }[] }) => {
    // console.log("--- onFinishFailed", errors);
    showMessage("error", "Please fill all the mandatory fields");
    const errorFieldNames: string[] = [];
    for (const errorField of errors.errorFields ?? []) {
      errorFieldNames.push(...((errorField.name ?? []) as string[]));
    }
    for (const fieldName of errorFieldNames) {
      if (personalDetailsTabRequiredFieldNames.includes(fieldName)) {
        return setSelectedTab("personal-details");
      } else if (professionalDetailsTabRequiredFieldNames.includes(fieldName)) {
        return setSelectedTab("professional-details");
      }
    }
  };

  let content = (
    <Row
      style={{
        rowGap: "1rem",
        marginTop: "16px",
        zIndex: 1,
        alignItems: "flex-start",
        justifyContent: "space-between",
      }}
      // className="container"
    >
      <Col xs={24} sm={24} md={24} lg={8}>
        <DriverProfileImageCard
          showActiveSwitch={!!driverUUID}
          setMember={setDriver}
          staffMember={driver}
          handleDriverUpdatedSuccessfully={handleDriverUpdatedSuccessfully}
        />
      </Col>
      <Col xs={24} sm={24} md={24} lg={15}>
        <div
          style={{
            padding: "16px 16px",
            borderRadius: "5px",
            border: "1px solid #f1f1f1",
            backgroundColor: "white",
          }}
        >
          <Tabs
            defaultActiveKey="personal-details"
            activeKey={selectedTab}
            onChange={(e) => {
              setSelectedTab(e as DriverProfileTabKey);
            }}
            items={[
              {
                label: `Personal details`,
                key: "personal-details",
              },
              {
                label: `Professional details`,
                key: "professional-details",
              },
            ]}
          />
          <div
            style={{
              display: selectedTab === "personal-details" ? "flex" : "none",
              width: "100%",
            }}
          >
            <PersonalDetailsTab />
          </div>
          <div
            style={{
              display: selectedTab === "professional-details" ? "flex" : "none",
              width: "100%",
            }}
          >
            <ProfessionalDetailsTab
              form={form}
              setMember={setDriver}
              staffMember={driver}
              // handleDriverUpdatedSuccessfully={() => {
              //   queryClient.resetQueries(teamPageQueryKey);
              // }}
            />
          </div>
          <Divider />
          <div>
            <Button htmlType="submit" type="primary" style={{ borderRadius: "5px" }}>
              Save
            </Button>
          </div>
        </div>
      </Col>
    </Row>
  );

  if (driver.driverUUID || !driverUUID) {
    content = (
      <Form
        layout="vertical"
        size="large"
        scrollToFirstError={true}
        form={form}
        disabled={!canUpdate}
        onFinish={handleFormValidationFinished}
        onFinishFailed={handleFormValidationFailed}
        initialValues={{
          name: driver.name ?? "",
          mobileNumber: {
            countryCode: "+971",
            number: driver?.mobileNumber?.replace("+971", ""),
          },
          nationality: driver?.nationality,
          sex: driver.sex,
          capacity: driver.capacity,
          email: driver.email,
          dob: driver?.dob ? moment(driver?.dob, "DD/MM/YYYY") : undefined,
          startTime: driver.startTime ? moment(driver.startTime, "hh:mmA") : undefined,
          endTime: driver.endTime ? moment(driver.endTime, "hh:mmA") : undefined,
          breakStartTime: driver.breakStartTime ? moment(driver.breakStartTime, "hh:mmA") : undefined,
          breakEndTime: driver.breakEndTime ? moment(driver.breakEndTime, "hh:mmA") : undefined,
          activeDays: driver.activeDays ?? [],
          priority: driver.priority,
        }}
      >
        {content}
      </Form>
    );
  }

  return (
    <BasePageLayout
      mainContent={
        <Box
          sx={{
            backgroundColor: driverSchedulePageBackgroundColor,
            paddingLeft: "1rem",
            paddingRight: "1rem",
            "@media (max-width: 700px)": {
              paddingLeft: "0px",
              paddingRight: "0px",
            },
          }}
        >
          <div
            className="main_page driver-profile-form web"
            style={{
              width: "100%",
              maxWidth: "100%",
              marginBottom: "6rem",
              padding: "0F 2rem",
            }}
          >
            <CoverImage alt={driver.name ?? ""} />
            <Spin spinning={isFetching || editDriverMutation.isLoading || createDriverMutation.isLoading}>{content}</Spin>
          </div>
          <Box
            className="main_page driver-profile-form mobile"
            sx={{
              width: "100%",
              maxWidth: "100%",
              position: "relative",
              "@media (max-width: 700px)": {
                position: "static",
              },
              padding: "0px 1rem 4rem 1rem",
            }}
          >
            <CoverImage alt={driver.name ?? ""} />
            <Spin spinning={isFetching || editDriverMutation.isLoading || createDriverMutation.isLoading}>{content}</Spin>
          </Box>
        </Box>
      }
    />
  );
};

const personalDetailsTabRequiredFieldNames = ["name", "dob", "sex", "email", "mobileNumber", "nationality"];
const professionalDetailsTabRequiredFieldNames = [
  "activeDays",
  "capacity",
  "emiratesIdFrontImageUrl",
  "emiratesIdBackImageUrl",
  "passportImageUrl",
  "licenseImageUrl",
  "startTime",
  "endTime",
  "breakStartTime",
  "breakEndTime",
  // "priority",
];

export default NewDriverProfile;
