import moment from "moment";
import SchedulerExploreViewResponseModel from "../../models/SchedulerExploreViewResponseModel";
import useGetStaffSchedulerQueryData from "../../hooks/useGetStaffSchedulerQueryData";
import useGetStaffSchedulerWeekViewQueryKey from "../../hooks/useGetStaffSchedulerWeekViewQueryKey";
import useGetStaffSchedulerDayViewQueryKey from "../../hooks/useGetStaffSchedulerDayViewQueryKey";
import useGetStaffSchedulerMonthViewQueryKey from "../../hooks/useGetStaffSchedulerMonthViewQueryKey";
import { Alert, Button, DatePicker, Divider, Form, Modal, Spin, Typography } from "antd";
import { FC, useMemo, useState } from "react";
import { useHistory } from "react-router-dom";
import { NursesDatum, SlotPatientModel } from "../../models/SchedulerExploreViewResponseModel";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { nurseService } from "../../services";
import { showMessage } from "../../components/common/notification";
import { StaffMemberModel } from "../../models/StaffMemberModel";
import { CalendarViewType } from ".";
import useCheckIfNurseIsAvailableForBookingDates from "../../customHooks/useCheckIfNurseIsAvailableForBookingDates";

const MarkNurseOnLeaveModal: FC<{ viewType: CalendarViewType; renderModals: boolean }> = ({ viewType, renderModals }) => {
  const now = moment();
  const history = useHistory<
    { nurseData: NursesDatum; patientsList: SlotPatientModel[]; initialSelectedDate: number } | undefined
  >();
  const queryClient = useQueryClient();
  const [form] = Form.useForm();
  const [nurseOccupiedDatesResponse, setNurseOccupiedDatesResponse] = useState<NurseOccupiedDatesResponseModel | undefined>();
  const timeRange: moment.Moment[] | undefined = Form.useWatch("timeRange", form);
  const locationState = history.location?.state;

  const weekViewQueryKey = useGetStaffSchedulerWeekViewQueryKey();
  const monthViewQueryKey = useGetStaffSchedulerMonthViewQueryKey();
  const dayViewQueryKey = useGetStaffSchedulerDayViewQueryKey();

  const { refetch: checkIfDateCanBeDisabled, isFetching } = useQuery(
    [
      "nurses/checkifnursecanbeinactivated",
      locationState?.nurseData.nurseUUID,
      ...((timeRange ?? [])?.map((m) => m.valueOf()) ?? []),
    ],
    () => nurseService.checkDateCanBeDisabled({ nurseUUID: locationState?.nurseData.nurseUUID ?? "", newHoliday: [] }),
    { enabled: false }
  );

  const { data: nurseDetailsResponse, isLoading: loadingNurseDetails } = useQuery(
    ["nurses/getparticularnurse", locationState?.nurseData.nurseUUID],
    () => nurseService.getNurseDetails(locationState?.nurseData.nurseUUID ?? ""),
    { refetchOnWindowFocus: false, enabled: !!locationState?.nurseData.nurseUUID }
  );

  const momentUserClickOnFromCalendar = useMemo<moment.Moment>(
    () => moment(locationState?.initialSelectedDate),
    [locationState?.initialSelectedDate]
  );

  const selectedRangeDatesValues = useMemo<moment.Moment[]>(() => {
    if (!timeRange?.length) return [];
    const currentMoment = timeRange[0].clone();
    const results: moment.Moment[] = [];
    while (currentMoment.isSameOrBefore(timeRange[1])) {
      results.push(currentMoment.clone());
      currentMoment.add(1, "day");
    }
    return results;
  }, [timeRange]);

  const { data: checkNurseAvailableResponse } = useCheckIfNurseIsAvailableForBookingDates({
    enableQuery: true,
    onlyCheckForBookings: true,
    editMode: false,
    isSingleDay: false,
    startTime: moment("00:00", "HH:mm"),
    endTime: moment("23:59", "HH:mm"),
    selectedNurseUUID: locationState?.nurseData.nurseUUID,
    selectedDatesArray: selectedRangeDatesValues, // timeRange?.map((dateMoment) => dateMoment.valueOf()) ?? [],
    momentUserClickOnFromCalendar,
  });

  const nurseDetails: StaffMemberModel | undefined = nurseDetailsResponse?.nurse;

  const addNurseHolidaysMutation = useMutation({ mutationFn: nurseService.addNurseHolidays });

  const handleValidationFinished = async (values: { timeRange: moment.Moment[] | undefined }) => {
    const enumratedDates: string[] = [];
    const [startDate, endDate] = values.timeRange ?? [];
    if (!startDate || !endDate) {
      return showMessage("error", "Something went wrong");
    }
    const currentDate = startDate.clone();
    while (currentDate.isSameOrBefore(endDate)) {
      enumratedDates.push(currentDate.format("DD/MM/YYYY"));
      currentDate.add(1, "day");
    }
    const res = await checkIfDateCanBeDisabled();
    if (Object.keys(res.data?.data?.dateData ?? {}).length) {
      setNurseOccupiedDatesResponse(res.data?.data);
    } else {
      handleAddNurseHolidays(enumratedDates);
    }
  };

  const handleAddNurseHolidays = async (selectedDates: string[]) => {
    const res = await addNurseHolidaysMutation.mutateAsync({
      holidaysToUpdate: [...(nurseDetails?.holidays ?? []), ...selectedDates],
      nurseUUID: nurseDetails?.nurseUUID ?? "",
    });
    if (!res?.success) {
      showMessage("error", "Something went wrong");
    } else {
      handleNurseHolidayAddedSuccessfully(selectedDates);
    }
  };

  const exploreViewData = useGetStaffSchedulerQueryData(viewType);

  const handleNurseHolidayAddedSuccessfully = (selectedDates: string[]) => {
    const queryUpdater = (oldValue: SchedulerExploreViewResponseModel | null | undefined) => {
      if (!oldValue) return oldValue;
      const newValue: SchedulerExploreViewResponseModel = JSON.parse(JSON.stringify(oldValue));
      const nurse = newValue.nursesData?.find((nurse) => nurse.nurseUUID === nurseDetails?.nurseUUID);
      if (!nurse?.data) return oldValue;
      nurse.holidays = Array.from(new Set([...(nurse.holidays ?? []), ...selectedDates]));
      return newValue;
    };
    const newQueryValue = queryUpdater(exploreViewData);
    queryClient.setQueryData(weekViewQueryKey, newQueryValue);
    queryClient.setQueryData(monthViewQueryKey, newQueryValue);
    queryClient.setQueryData(dayViewQueryKey, newQueryValue);
    showMessage("success", "Nurse time off was added successfully");
    history.replace("/scheduler");
  };

  const content = (
    <div className="modal-container">
      {(checkNurseAvailableResponse?.assignNurseErrors?.length ?? 0) > 0 && (
        <Alert
          style={{ marginBottom: "16px" }}
          message={
            <div style={{ display: "flex", flexDirection: "column", gap: "8px" }}>
              {checkNurseAvailableResponse?.assignNurseErrors?.map((errorMsg, index) => {
                return (
                  <div key={index} style={{ textAlign: "center" }}>
                    <Typography.Text strong={true} style={{ textAlign: "center" }}>
                      {errorMsg}
                    </Typography.Text>
                  </div>
                );
              })}
            </div>
          }
          type="warning"
        />
      )}
      <Spin spinning={isFetching || loadingNurseDetails || addNurseHolidaysMutation.isLoading}>
        <Form
          form={form}
          onFinish={handleValidationFinished}
          initialValues={{
            timeRange: locationState?.initialSelectedDate
              ? [moment(locationState?.initialSelectedDate), moment(locationState?.initialSelectedDate)]
              : undefined,
          }}
        >
          <Form.Item
            label=""
            name="timeRange"
            rules={[
              {
                required: true,
                message: "Please select the dates when the nurse should be inactive",
              },
            ]}
          >
            <DatePicker.RangePicker
              style={{ width: "100%" }}
              format="DD MMM"
              disabledDate={(date) => {
                return (
                  date.isBefore(now.clone().startOf("day")) ||
                  (nurseDetails?.holidays?.includes(date.format("DD/MM/YYYY")) ?? false)
                );
              }}
            />
          </Form.Item>
          <div className="modal-footer">
            <Divider
              style={{
                margin: "0px",
              }}
            />
            <div
              style={{
                paddingTop: "12px",
                display: "flex",
                justifyContent: "flex-end",
                alignContent: "center",
                gap: "1rem",
              }}
            >
              <Button
                onClick={() => {
                  history.replace("/scheduler");
                }}
                htmlType="button"
              >
                Cancel
              </Button>
              <Button htmlType="submit" style={{ backgroundColor: "#ff9184", borderColor: "#ff9184", color: "white" }}>
                Mark as leave
              </Button>
            </div>
          </div>
        </Form>
      </Spin>
    </div>
  );

  if (!renderModals) return content;

  return (
    <>
      <Modal
        title={`Select when nurse ${locationState?.nurseData.name} should be disabled`}
        width={600}
        footer={null}
        maskClosable={false}
        centered={true}
        open={true}
        onCancel={() => {
          history.replace("/scheduler");
        }}
      >
        {content}
      </Modal>
      <Modal
        open={!!nurseOccupiedDatesResponse}
        onCancel={() => {
          setNurseOccupiedDatesResponse(undefined);
        }}
        onOk={() => {
          setNurseOccupiedDatesResponse(undefined);
        }}
        centered={true}
        title={`Nurse ${nurseDetails?.firstName} ${nurseDetails?.lastName} could not be marked as on leave because they are already occupied with bookings on the following dates`}
        cancelButtonProps={{ style: { display: "none" } }}
      >
        {Object.keys(nurseOccupiedDatesResponse?.dateData ?? {}).map((key) => {
          return (
            <div key={key} style={{ marginBottom: "16px" }}>
              <div style={{ textAlign: "center" }}>
                <strong>{key}</strong>
              </div>
              <div style={{ textAlign: "center" }}>{nurseOccupiedDatesResponse?.dateData[key]?.join(", ")}</div>
            </div>
          );
        })}
      </Modal>
    </>
  );
};

interface NurseOccupiedDatesResponseModel {
  success: number | boolean;
  dateData: { [date: string]: string[] };
}

export default MarkNurseOnLeaveModal;
