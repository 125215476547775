import { Button, Dialog, DialogContent, DialogTitle, Divider, List, TextField } from "@mui/material";
import { FC, useCallback, useMemo, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { ConsultationLabTestModel } from "../../models/GetProductsForConsultationResponseModel";
import { ConsultationRecommendationsInitialState } from "../../reducer/consultation.recommendations.reducer";
import { ConsultationRecommendationsType } from "../../types";
import BiotechRoundedIcon from "@mui/icons-material/BiotechRounded";
import LoadingSpinner from "../../components/LoadingSpinner";
import useProductsForConsultationQuery from "../../hooks/useProductsForConsultationQuery";
import SuggestLabTestsListItem from "./SuggestLabTestsListItem";
import Mixpanel from "../../utils/Mixpanel";

const SuggestLabTestsModal: FC<SuggestLabTestsModalProps> = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { orderUUID } = useParams<{ orderUUID: string }>();
  const [searchText, setSearchText] = useState("");
  const [selectedTests, setSelectedTests] = useState<Map<string, boolean>>(new Map());

  const { query } = useProductsForConsultationQuery();

  const fullLabTestsList = useMemo(() => {
    return query.data?.labTests ?? [];
  }, [query.data?.labTests]);

  const labTestsList = useMemo(() => {
    if (!searchText.trim().length) return fullLabTestsList;
    return (
      fullLabTestsList?.filter((test) => {
        return test.name?.toLocaleLowerCase().includes(searchText.toLocaleLowerCase().trim());
      }) ?? []
    );
  }, [fullLabTestsList, searchText]);

  const handleClose = useCallback(() => {
    history.push(`/order/${orderUUID}`);
  }, [history, orderUUID]);

  const handleContinueClicked = useCallback(() => {
    const selectedTestsList = fullLabTestsList.filter((test) => {
      return selectedTests.get(test.productUUID ?? "");
    });
    const payload: Partial<ConsultationRecommendationsInitialState> = {
      selectedLabTests: selectedTestsList ?? [],
    };
    dispatch({ type: ConsultationRecommendationsType.SET_CONSULTATION_RECOMMENDATIONS, payload: payload });
    Mixpanel.track("SuggestLabTestsModal continue clicked", {
      ...payload,
      partnerUser: localStorage.getItem("userName"),
      partnerUserUUID: localStorage.getItem("partnerUserUUID"),
    });
    history.push(`/order/${orderUUID}/suggestions/lab-tests/addons`);
  }, [dispatch, fullLabTestsList, history, orderUUID, selectedTests]);

  const handleSkipClicked = useCallback(() => {
    const payload: Partial<ConsultationRecommendationsInitialState> = {
      selectedLabTests: "SKIP",
    };
    Mixpanel.track("SuggestLabTestsModal skip clicked", {
      ...payload,
      partnerUser: localStorage.getItem("userName"),
      partnerUserUUID: localStorage.getItem("partnerUserUUID"),
    });
    dispatch({ type: ConsultationRecommendationsType.SET_CONSULTATION_RECOMMENDATIONS, payload: payload });
    history.push(`/order/${orderUUID}/suggestions/lab-tests/addons`);
  }, [dispatch, history, orderUUID]);

  const didSelectAtLeastOneTest = useMemo(() => {
    return Array.from(selectedTests.values()).some((value) => value);
  }, [selectedTests]);

  return (
    <Dialog open={true} onClose={handleClose} fullWidth={true} maxWidth="sm">
      <DialogTitle sx={{ marginBottom: "2rem", lineHeight: "1" }} color="teal">
        <BiotechRoundedIcon /> Lab Tests
      </DialogTitle>
      <DialogContent
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          gap: "1rem",
          paddingBottom: "2rem",
          height: "100%",
          "& .MuiButtonBase-root": {
            minWidth: "300px",
          },
          "& .MuiButton-containedPrimary": {
            color: "white",
          },
        }}
      >
        <TextField
          label="Search Lab Tests"
          variant="outlined"
          size="small"
          fullWidth={true}
          sx={{ marginTop: "0.3rem" }}
          autoFocus={true}
          value={searchText}
          onChange={(e) => {
            setSearchText(e.target.value);
          }}
        />
        <Divider sx={{ width: "100%", borderColor: "black" }} />
        <LoadingSpinner spinning={query.isLoading} styles={{ minHeight: "100px" }}>
          <List
            dense
            sx={{
              width: "100%",
              bgcolor: "background.paper",
              height: "100%",
              minHeight: "60vh",
              maxHeight: "60vh",
              overflow: "scroll",
            }}
          >
            {labTestsList?.map((test) => {
              return (
                <SuggestLabTestsListItem
                  key={test.productUUID}
                  test={test}
                  selected={selectedTests.get(test.productUUID ?? "") ?? false}
                  onSelectClicked={function (test: ConsultationLabTestModel): void {
                    setSelectedTests((value) => {
                      const newValue = new Map(value);
                      if (newValue.get(test.productUUID ?? "")) {
                        newValue.set(test.productUUID ?? "", false);
                      } else {
                        newValue.set(test.productUUID ?? "", true);
                      }
                      return newValue;
                    });
                  }}
                />
              );
            })}
          </List>
        </LoadingSpinner>
        <Button
          variant={didSelectAtLeastOneTest ? "contained" : "text"}
          fullWidth={true}
          disableElevation={true}
          onClick={didSelectAtLeastOneTest ? handleContinueClicked : handleSkipClicked}
        >
          {didSelectAtLeastOneTest ? "Continue" : "Skip"}
        </Button>
      </DialogContent>
    </Dialog>
  );
};

interface SuggestLabTestsModalProps {}

export default SuggestLabTestsModal;
