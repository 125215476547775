import { nurseService } from "../services";
import { nurseType } from "../types";
import { showMessage } from "../components/common/notification";
import { saveNurse } from "./localStorage.action";

export const nurseAction = {
  addNurse,
  getAllNurses,
  deleteNurse,
  editNurse,
  getSingleNurse,
  activeNurse,
  addnurseinbulk,
  updateNurseTimeslot,
  getNurseReview,
  checkDateCanBeDisabled,
  fetchValidNursesForAnOrder,
};

function fetchValidNursesForAnOrder(body, onSuccess) {
  return (dispatch) => {
    dispatch(request(true));
    nurseService.fetchValidNursesForAnOrder(body).then((response) => {
      // if (response?.data?.success) {
      dispatch(
        success(
          // [
          //   "4739b392-6610-487e-ba79-6760a6cb85c1",
          //   "331168fb-cb28-4a7f-ab80-2b244a13370e",
          //   "448c5a71-daa6-4696-92a6-6c1046748b35",
          //   "8c305496-1621-4e16-bbe3-c14d237b37bb",
          //   "87b8a0b4-e4c5-427e-a28c-9529254a5878",
          // ]
          response?.data?.nurses?.length ? response?.data?.nurses : []
        )
      );
      // }
      if (onSuccess) {
        onSuccess(body, response?.data);
      }
      dispatch(request(false));
    });
  };

  function request(data) {
    return { type: nurseType.GET_VALID_NURSES_LOADER, data };
  }

  function success(data) {
    return { type: nurseType.GET_VALID_NURSES_FOR_ORDER, data };
  }
}

function checkDateCanBeDisabled(profile, body, onSuccess) {
  return () => {
    nurseService.checkDateCanBeDisabled(body).then((response) => {
      if (onSuccess) {
        onSuccess(profile, body, response?.data);
      } else {
        showMessage("error", "Nurse could not be disabled due to ongoing bookings");
      }
    });
  };
}

function getNurseReview(body) {
  return (dispatch) => {
    dispatch(success({}));
    nurseService.getNurseReview(body).then((response) => {
      if (response?.data?.success) {
        dispatch(success(response?.data?.reviewsAndRatings));
      }
    });
  };

  function success(data) {
    return { type: nurseType.GET_NURSE_REVIEW, data };
  }
}
function addnurseinbulk(body, onSuccess) {
  return (dispatch) => {
    dispatch(request());
    nurseService.addnurseinbulk(body?.nurses).then((res) => {
      if (res.data.success === 1) {
        showMessage("success", "Nurse Added in Bulk!");
        nurseService.getAllNurses(body?.partnerUUID).then((res) => {
          if (res?.data?.success === 1) {
            dispatch(success(res.data.nurses?.length > 0 ? res.data.nurses : [], res?.data?.drivers ?? []));
            if (onSuccess) {
              onSuccess();
            }
          }
        });
      } else {
        showMessage("error", "Failed to add nurses in Bulk, try again!");
      }
    });
  };

  function request() {
    return { type: nurseType.GET_LOADER };
  }

  function success(data, drivers) {
    return { type: nurseType.GET_NURSES, data, drivers };
  }
}

function addNurse(addObject, body, onSuccess) {
  return (dispatch) => {
    dispatch(request(true));
    nurseService
      .addNurse(addObject)
      .then(
        (response) => {
          if (response?.data?.success) {
            if (onSuccess) {
              onSuccess(body, response?.data?.nurseUUID, true);
            }
          }
          response.data.success === 1
            ? showMessage("success", "New nurse created!")
            : showMessage("error", "Failed to craete new nurse, try again!");
        },
        (error) => {}
      )
      .finally(() => dispatch(request(false)));
  };

  function request(data) {
    return { type: nurseType.GET_NURSE_UPDATE_LOADER, data: data };
  }
}

function getAllNurses(partnerUUID, onSuccess) {
  return (dispatch) => {
    dispatch(request());
    nurseService.getAllNurses(partnerUUID).then(
      (response) => {
        if (onSuccess) {
          onSuccess(response);
        }

        dispatch(success(response?.data?.nurses ?? [], response?.data?.drivers ?? []));
      },
      () => {}
    );
  };
  function request() {
    return { type: nurseType.GET_LOADER };
  }

  function success(data, drivers) {
    return { type: nurseType.GET_NURSES, data, drivers };
  }
}

function getSingleNurse(data) {
  return (dispatch) => {
    saveNurse(data);
    dispatch(request(data));
  };

  function request(data) {
    return { type: nurseType.SINGLE_NURSE, data };
  }
}

function deleteNurse(deleteObject, onSuccess) {
  return (dispatch) => {
    nurseService.deleteNurse(deleteObject).then((response) => {
      if (onSuccess) {
        onSuccess();
      }
      if (response?.data?.success) {
        showMessage("success", "Nurse is deleted!");
      } else {
        showMessage("error", "Failed to delete a nurse, try again!");
      }
    });
  };
}

function updateNurseTimeslot(body) {
  return (dispatch) => {
    nurseService.updateNurseTimeslot(body).then((response) => {});
  };
}

function editNurse(editObject, body, onSuccess) {
  return (dispatch) => {
    dispatch(request(true));
    nurseService
      .editNurse(editObject)
      .then(
        (response) => {
          if (response?.data?.success) {
            if (onSuccess) {
              onSuccess(body, response?.data?.nurseUUID, false);
            }
          }
          response.data.success === 1
            ? showMessage("success", "Nurse profile updated!")
            : showMessage("error", "Failed to update a nurse, try again!");

          return response;
        },
        (error) => {
          showMessage("error", "Failed to update a nurse, try again!");
        }
      )
      .finally(() => dispatch(request(false)));
  };
  function request(data) {
    return { type: nurseType.GET_NURSE_UPDATE_LOADER, data: data };
  }
}

function activeNurse(editObject, onSuccess) {
  return (dispatch) => {
    nurseService.activeNurse(editObject).then(
      (response) => {
        if (onSuccess) {
          onSuccess();
        }

        response.data.success === 1
          ? showMessage("success", "Nurse status updated!")
          : showMessage("error", "Failed to update nurse status, try again!");

        return response;
      },
      (error) => {}
    );
  };
}
