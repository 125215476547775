import { FC } from "react";
import { Button } from "@mui/material";
import { Col, Row } from "antd";
import { getOrderStatusForAssignNurseModal } from "./AssignNurseModal";
import BookingModel from "../../../models/BookingModel";
import SubscriptionBookingRibbon from "./SubscriptionBookingRibbon";
import useCurrentUserRole from "../../../hooks/useCurrentUserRole";

const AssignNurseModalTitleRow: FC<AssignNurseModalTitleRowProps> = ({ order, handleAssignNurseClicked, onClose }) => {
  const { canUpdate } = useCurrentUserRole();

  return (
    <Row style={{ alignItems: "center", rowGap: "1rem" }}>
      <Col span={24}>
        <Row className="nurseModalHeaderRow">
          <Col className="nurseModalHeaderCol">
            <Row
              style={{
                justifyContent: "flex-start",
                alignItems: "center",
                rowGap: "1rem",
                columnGap: "2%",
              }}
            >
              <Col>
                <p
                  style={{
                    fontSize: "16px",
                    fontWeight: "bold",
                    color: "#000",
                    margin: 0,
                  }}
                >
                  {order?.orderUUID}
                </p>
              </Col>
              <Col>
                <p
                  style={{
                    fontSize: "16px",
                    fontWeight: "bold",
                    color: "#000",
                    margin: 0,
                  }}
                >
                  {order && getOrderStatusForAssignNurseModal(order)}
                </p>
              </Col>
              {order.isSubscriptionOrder && (
                <Col>
                  <SubscriptionBookingRibbon />
                </Col>
              )}
            </Row>
          </Col>
          <Col className="nurseModalHeaderCol2">
            <Row>
              {canUpdate && (
                <Col>
                  <Button
                    style={{
                      width: "160px",
                      borderRadius: "2rem",
                      color: "#fff",
                    }}
                    variant="contained"
                    color="primary"
                    // disabled={!selectedNurse}
                    onClick={(e) => {
                      e.stopPropagation();
                      handleAssignNurseClicked();
                    }}
                  >
                    Save
                  </Button>
                </Col>
              )}
              <Col>
                <Button
                  style={{ width: "120px", borderRadius: "2rem" }}
                  onClick={(e) => {
                    e.stopPropagation();
                    onClose();
                  }}
                  color="secondary"
                  variant="outlined"
                >
                  Cancel
                </Button>
              </Col>
            </Row>
          </Col>
        </Row>
      </Col>
      <Col span={24}>
        {order?.patient && order?.patient.length && (
          <div
            style={{
              fontSize: "16px",
              fontWeight: "bold",
              marginBottom: "1rem",
            }}
          >
            {order?.patient![0].type}
          </div>
        )}
      </Col>
    </Row>
  );
};

interface AssignNurseModalTitleRowProps {
  order: BookingModel;
  onClose: () => void;
  handleAssignNurseClicked: () => void;
}

export default AssignNurseModalTitleRow;
