import { Accordion, AccordionDetails, AccordionSummary } from "@mui/material";
import { FC } from "react";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import BookingModel from "../../models/BookingModel";
import ReferenceDocListItem from "./ReferenceDocListItem";

const BookingReferenceDocs: FC<BookingReferenceDocsProps> = ({ booking }) => {
  if (!booking?.referenceDocs?.length) return null;

  return (
    <Accordion style={{ marginTop: "1rem" }} defaultExpanded={true}>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <div style={{ fontWeight: "bold" }}>Reference Documents</div>
      </AccordionSummary>
      <AccordionDetails>
        <div style={{ display: "flex", flexWrap: "wrap", gap: "1rem", alignItems: "center" }}>
          {booking.referenceDocs.map((doc, i) => {
            return <ReferenceDocListItem doc={doc} key={i} booking={booking} />;
          })}
        </div>
      </AccordionDetails>
    </Accordion>
  );
};

// const mapDocNames: { [name: string]: string } = {
//   LAB_RESULT: "lab result",
// };

interface BookingReferenceDocsProps {
  booking?: BookingModel;
}

export default BookingReferenceDocs;
