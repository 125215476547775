import { Spin } from "antd";

const RenderGoogleMapsWrapper = (status: string) => {
  if (status === "FAILURE") {
    return (
      <div className="d-flex justify-content-center pt-5">
        <h3>Something went wrong</h3>
      </div>
    );
  }
  return (
    <div
      style={{
        width: "100%",
        padding: "3rem",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Spin />
    </div>
  );
};

export default RenderGoogleMapsWrapper;
