import cryptLib from "@skavinvarnan/cryptlib";
const key = "w0HXiDp6baR2uLNRQ3FpVDePii3aeP7R";

const secretKey = key.substring(0, 32);

const encrypt = (body) => {
  const stringfiedData = JSON.stringify(body);
  const encryptedResult = cryptLib.encryptPlainTextWithRandomIV(stringfiedData, secretKey);
  return encryptedResult;
};

export default encrypt;
