import { useQuery } from "@tanstack/react-query";
import { getBiomarkersMasterList } from "../services/schemas.services";
import { useMemo } from "react";

export default function useBiomarkersListQuery() {
  const queryKey = useMemo(() => {
    return ["schemas/get-addons-new"];
  }, []);

  const query = useQuery(queryKey, getBiomarkersMasterList);

  return { query, queryKey };
}
