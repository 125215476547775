import { useMemo } from "react";
import BookingModel from "../models/BookingModel";
import getStartMomentFromDateObj from "../utils/getStartMomentFromDateObj";
import moment from "moment";

export default function useBookingHasUnassignedDates({ order }: { order: BookingModel }) {
  const hasUnassignedDates = useMemo<boolean>(() => {
    const bookingAssignedDatesMap = new Map();
    for (const nurse of order?.assignedNurses ?? []) {
      for (const dateObj of nurse.dateArray ?? []) {
        bookingAssignedDatesMap.set(dateObj.date, true);
      }
    }
    const now = moment().startOf("day");
    return (
      order.date
        ?.filter((dateObj) => getStartMomentFromDateObj({ date: dateObj.date, time: dateObj.time }).isSameOrAfter(now))
        ?.some((dateObj) => !bookingAssignedDatesMap.get(dateObj.date)) ?? false
    );
  }, [order?.assignedNurses, order.date]);

  if (order.status === "CANCELLED" || order.status === "REPORTED" || order.status === "DRAFT") return false;
  return hasUnassignedDates;
}
