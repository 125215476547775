import { useQuery } from "@tanstack/react-query";
import { SearchBookingsType } from "../components/SearchBookingsInput";
import { searchServices } from "../services";
import { useMemo } from "react";
import ReduxStateModel from "../models/ReduxStateModel";
import { useSelector } from "react-redux";

export default function useSearchOrderData(type?: SearchBookingsType, value?: string, pageNumber?: number) {
  const authReducer = useSelector((state: ReduxStateModel) => state.authReducer?.setting);

  const queryKey = useMemo(() => {
    return ["orders/search", type, value, authReducer?.partnerUUID, pageNumber];
  }, [pageNumber, authReducer?.partnerUUID, type, value]);

  const query = useQuery(queryKey, () => searchServices.searchOrderData(type!, value!, authReducer!.partnerUUID!, pageNumber!), {
    enabled: !!type && !!value && !!authReducer?.partnerUUID && !!pageNumber,
    refetchOnWindowFocus: true,
    staleTime: Infinity,
    cacheTime: Infinity,
  });

  return { query, queryKey };
}
