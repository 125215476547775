import { Tooltip, ListItem, IconButton, ListItemText } from "@mui/material";
import { Divider } from "antd";
import { FC, useState } from "react";
import { useSelector } from "react-redux";
import { userRoleMap } from "../../constants";
import ReduxStateModel from "../../models/ReduxStateModel";
import DeleteIcon from "@mui/icons-material/Delete";
import PartnerUserInviteModel from "../../models/PartnerUserInviteModel";
import DeletePartnerUserInviteModal from "./DeletePartnerUserInviteModal";

const PartnerUserInviteListItem: FC<PartnerUserInviteListItemProps> = ({ invite, onDeleteSuccess }) => {
  const authReducer = useSelector((state: ReduxStateModel) => state.authReducer?.setting);
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  return (
    <div>
      <ListItem
        key={invite._id}
        secondaryAction={
          <div style={{ display: "flex", alignItems: "center", gap: "16px" }}>
            {authReducer?.role === "SUPER_ADMIN" && (
              <Tooltip title="Delete Invite" placement="top">
                <IconButton edge="end" aria-label="delete" color="error" onClick={() => setShowDeleteModal(true)}>
                  <DeleteIcon />
                </IconButton>
              </Tooltip>
            )}
          </div>
        }
      >
        <ListItemText
          primary={<span style={{ color: "black" }}>{invite.email}</span>}
          secondary={userRoleMap[invite.role ?? ""]}
        />
      </ListItem>
      <Divider style={{ margin: "0px" }} />
      <DeletePartnerUserInviteModal
        open={showDeleteModal}
        invite={invite}
        onDeleteSuccess={onDeleteSuccess}
        onClose={function (): void {
          setShowDeleteModal(false);
        }}
      />
    </div>
  );
};

interface PartnerUserInviteListItemProps {
  invite: PartnerUserInviteModel;
  onDeleteSuccess: () => void;
}

export default PartnerUserInviteListItem;
