import { useMemo } from "react";
import { useSelector } from "react-redux";
import PartnerSettingsModel from "../models/PartnerSettingsModel";
import usePartnerDetails from "./usePartnerDetails";
import ReduxStateModel from "../models/ReduxStateModel";

function useCurrentPartnerDetails() {
  // const dispatch = useDispatch();
  const partnerSettings: PartnerSettingsModel | undefined = useSelector((state: ReduxStateModel) => state.authReducer?.setting);

  const partnerSettingsFromLocalStorage = useMemo<PartnerSettingsModel | undefined>(() => {
    const settingsStr = localStorage.getItem("setting");
    if (!settingsStr || typeof settingsStr !== "string") return;
    try {
      return JSON.parse(settingsStr);
    } catch (e) {
      console.log();
    }
  }, []);

  const partnerUUID = partnerSettings?.partnerUUID ?? partnerSettingsFromLocalStorage?.partnerUUID;

  const queryEnabled = useMemo(() => !!partnerUUID, [partnerUUID]);

  const queryData = usePartnerDetails({
    partnerUUID: partnerUUID,
  });

  const isDeliveryPartner = queryData.query.data?.partner?.isDeliveryPartner ?? false;

  return { queryData, queryEnabled, isDeliveryPartner };
}

export default useCurrentPartnerDetails;
