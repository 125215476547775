import { analyticsTypes } from "../types";

export const analyticsInitState = {
  loading: false,
  products: [],
  dashboardData: null,
};

export function analyticsReducer(state = analyticsInitState, { type, payload }) {
  if (type === analyticsTypes.SET_LOADING) {
    return {
      ...state,
      loading: payload,
    };
  } else if (type === analyticsTypes.SET_ELIGIBLE_PRODUCTS_LIST) {
    return {
      ...state,
      loading: false,
      products: payload,
    };
  } else if (type === analyticsTypes.SET_DASHBOARD_DATA) {
    return {
      ...state,
      loading: false,
      dashboardData: payload,
    };
  } else {
    return state;
  }
}
