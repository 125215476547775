import { Box, Typography } from "@mui/material";
import { Select } from "antd";
import { FC } from "react";
import useAutoNavMapViewControlsCard from "../../hooks/useAutoNavMapViewControlsCard";

const AutoNavMobileControls: FC = () => {
  const {
    delayOptions,
    selectedNurseDelay,
    bookingEndPickupDelay,
    loadingDriverSettings,
    handleNurseDelayChanged,
    handleBookingEndPickupDelayChange,
  } = useAutoNavMapViewControlsCard();

  return (
    <Box
      display={"flex"}
      gap={2}
      paddingX={2}
      sx={{
        "@media (min-width: 768px)": {
          display: "none",
        },
        "& .ant-select:not(.ant-select-customize-input) .ant-select-selector": {
          borderRadius: "6px",
        },
      }}
    >
      <Box width={"100%"}>
        <Typography variant="caption" paddingInlineStart={"2px"}>
          Drop Delay
        </Typography>
        <Select
          value={selectedNurseDelay ?? null}
          loading={loadingDriverSettings}
          disabled={loadingDriverSettings}
          onChange={handleNurseDelayChanged}
          options={delayOptions}
          style={{ width: "100%", marginTop: 8 }}
        />
      </Box>
      <Box width={"100%"}>
        <Typography variant="caption" paddingInlineStart={"2px"}>
          Retrieval Delay
        </Typography>
        <Select
          value={bookingEndPickupDelay ?? null}
          loading={loadingDriverSettings}
          disabled={loadingDriverSettings}
          onChange={handleBookingEndPickupDelayChange}
          options={delayOptions}
          style={{ width: "100%", marginTop: 8 }}
        />
      </Box>
    </Box>
  );
};

export default AutoNavMobileControls;
