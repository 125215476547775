import { FC } from "react";
import { StaffMemberModel } from "../../models/StaffMemberModel";
import { Box } from "@mui/material";
import DriverListItem from "./DriverListItem";
import NurseListItem from "./NurseListItem";

const TeamMembersList: FC<TeamMembersListProps> = ({ teamMembers }) => {
  return (
    <Box
      sx={{
        height: `calc(100vh - 320px)`,
        "@media (max-width: 700px)": {
          height: `calc(100vh - 420px)`,
        },
        overflow: "scroll",
      }}
    >
      <ul style={{ display: "flex", flexDirection: "column", marginBottom: "0px", paddingLeft: "15px" }}>
        {teamMembers.map<JSX.Element>((member, index) => {
          if (member.type === "DRIVER") {
            return <DriverListItem driver={member} key={member.driverUUID} renderDivider={index < teamMembers.length - 1} />;
          }
          return <NurseListItem nurse={member} key={member.nurseUUID} renderDivider={index < teamMembers.length - 1} />;
        })}
      </ul>
    </Box>
  );
};

interface TeamMembersListProps {
  teamMembers: StaffMemberModel[];
}

export default TeamMembersList;
