import { Button, Col, Row } from "antd";
import { LeftOutlined, RightOutlined } from "@ant-design/icons";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { setSelectedWeekAction as setSelectedWeekExploreViewAction } from "../../actions/schedulerExploreViewActions";
import { setSelectedWeekAction as setSelectedWeekDetailViewAction } from "../../actions/schedulerDetailViewActions";

const WeekSelector = () => {
  const dispatch = useDispatch();
  const selectedWeek: moment.Moment = useSelector(
    (state) => (state as any)?.schedulerExploreViewReducer?.selectedWeek
  );
  const weekEnd = selectedWeek.clone().add(6, "day");
  const weekStartMonthName = selectedWeek.format("MMMM");
  const weekEndMonthName = weekEnd.format("MMMM");

  const onPrevWeekClicked = () => {
    const prevWeek = selectedWeek.clone().subtract(1, "week");
    dispatch(setSelectedWeekExploreViewAction(prevWeek));
    dispatch(setSelectedWeekDetailViewAction(prevWeek));
  };

  const onNextWeekClicked = () => {
    const nextWeek = selectedWeek.clone().add(1, "week");
    dispatch(setSelectedWeekExploreViewAction(nextWeek));
    dispatch(setSelectedWeekDetailViewAction(nextWeek));
  };

  return (
    <Row className="week-selector">
      <Col>
        <Button className="prev-week-btn" onClick={onPrevWeekClicked}>
          <LeftOutlined />
        </Button>
      </Col>
      <Col className="week-date">
        {weekStartMonthName} {selectedWeek.format("D")} -{" "}
        {weekStartMonthName === weekEndMonthName ? "" : weekEndMonthName}{" "}
        {weekEnd.format("D")}, {selectedWeek.format("YYYY")}
      </Col>
      <Col>
        <Button className="next-week-btn" onClick={onNextWeekClicked}>
          <RightOutlined />
        </Button>
      </Col>
    </Row>
  );
};

export default WeekSelector;
