import { Alert } from "@mui/material";
import { FC } from "react";
import { DelayedNurseStartServiceAlertData } from "../../hooks/useBookingHasDelayedNurse";
import NotificationImportantRoundedIcon from "@mui/icons-material/NotificationImportantRounded";

const DelayedNurseStartServiceTableRowAlert: FC<DelayedNurseStartServiceTableRowAlertProps> = ({ data }) => {
  return (
    <Alert
      icon={<NotificationImportantRoundedIcon />}
      sx={{
        position: "absolute",
        top: "0",
        left: "0",
        width: "100%",
      }}
      color="warning"
    >
      Reminder: The professional {`${data.nurse?.firstName ?? ""} ${data.nurse?.lastName ?? ""}`} has not started the service as
      scheduled at {data.dateMoment.format("DD/MM/YYYY hh:mm A")}.
    </Alert>
  );
};

interface DelayedNurseStartServiceTableRowAlertProps {
  data: DelayedNurseStartServiceAlertData;
}

export default DelayedNurseStartServiceTableRowAlert;
