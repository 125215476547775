import { FC, useCallback, useMemo } from "react";
import { BsCircle, BsCheck2Circle } from "react-icons/bs";
import { Accordion, AccordionDetails, AccordionSummary, Typography } from "@mui/material";
import { Col, Row, Tooltip } from "antd";
import { FaCopy } from "react-icons/fa";
import { getColor } from "../../components/common/getColor";
import { mapAreaToEmirate } from "../../constants";
import { useSelector } from "react-redux";
import { showMessage } from "../../components/common/notification";
import BookingModel, { BookingPatientModel, DeclarationForm } from "../../models/BookingModel";
import ShowBookingAssignedNurse from "../../components/common/BookingsTable/ShowBookingAssignedNurse";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import PatientAssesmentFormAccordion from "./PatientAssesmentFormAccordion";
import PatientImages from "./PatientImages";
import PatientInfoItem from "./PatientInfoItem";
import PatientReportsList from "./PatientReportsList";
import ShowBookingAddress from "./ShowBookingAddress";
import ShowUserEmail from "./ShowUserEmail";
import ReduxStateModel from "../../models/ReduxStateModel";
import CallUserButton from "../../components/CallUserButton";
import TRTAssesmentForm from "./TRTAssesmentForm";
import useProductDetails from "../../hooks/useProductDetails";

const OrderDetailsPatientListItem: FC<OrderDetailsPatientListItemProps> = ({
  patient,
  order,
  index,
  setorder,
  openNurseModal,
}) => {
  const getClinicalType = useMemo(() => {
    if (order.productType !== "S-D") return null;
    const getPatinetID = order?.patientId?.filter((p) => p?.id === patient?._id);
    if (getPatinetID?.length) {
      const patinetId = getPatinetID[0];
      if (patinetId?.diagnosticType === "CLINICAL") {
        return <PatientInfoItem name="Care Type" value="Clinical" />;
      }
      if (patinetId?.diagnosticType === "NON_CLINICAL") {
        return <PatientInfoItem name="Care Type" value="Non-Clinical" />;
      }
    }
  }, [order?.patientId, order.productType, patient?._id]);

  const services = useSelector((state: ReduxStateModel) => state.settingReducer?.manageDateTime?.services ?? []);

  const getAllAnswers = useCallback(
    (qa: DeclarationForm) => {
      const productUUID = order?.patientId?.filter((pt) => pt.id === patient?._id)[0]?.productUUID;
      const product = services?.filter((sr) => sr?.productUUID === productUUID)[0];
      const allAnswers: string[] = [];
      for (let i = 0; i < (qa?.answer?.length ?? 0); i++) {
        allAnswers.push(qa?.answer?.[i]?.value ?? "");
      }
      if (product?.declarationForm) {
        const tempform = product?.declarationForm.filter((form) => form?.engQuestion === qa?.question);
        if (tempform?.length) {
          const form = { ...tempform[0] };
          if (form?.options?.length) {
            return (
              <Row
                style={{
                  rowGap: "0.2rem",
                }}
              >
                {form?.options?.map((op, id) => (
                  <Col span={24}>
                    <Row
                      key={id}
                      style={{
                        alignItems: "center",
                        justifyContent: "space-between",
                      }}
                    >
                      <Col xs={2} sm={1} md={1} lg={1} style={{ minWidth: "15px" }}>
                        {allAnswers?.filter((an) => an === op?.engOption)?.length ? (
                          <BsCheck2Circle color="green" fontSize="15" />
                        ) : (
                          <BsCircle />
                        )}
                      </Col>
                      <Col xs={21} sm={22} md={22} lg={22}>
                        <p
                          style={{
                            margin: 0,
                            lineBreak: "anywhere",
                          }}
                        >
                          {op?.engOption}
                        </p>
                      </Col>
                    </Row>
                  </Col>
                ))}
              </Row>
            );
          }
        }
      }
      return (
        <span
          style={{
            textTransform: "capitalize",
          }}
        >
          <BsCheck2Circle color="green" />
          &nbsp;&nbsp;
          {qa?.answer?.[0]?.value}
        </span>
      );
    },
    [order?.patientId, patient?._id, services]
  );

  const getAssessmentForm = useMemo(() => {
    const checkEmptyObject = (obj: DeclarationForm) => {
      if (!obj?.question) {
        return true;
      }
      return false;
    };
    return (
      <>
        {Array.isArray(patient?.declarationForm) &&
          patient?.declarationForm?.map(
            (qa, key) =>
              !checkEmptyObject(qa) && (
                <div key={key} className="assesment-question">
                  <p
                    style={{
                      color: "black",
                    }}
                  >
                    {key + 1}.{" "}
                    <span
                      style={{
                        textTransform: "capitalize",
                      }}
                    >
                      {qa?.question}
                    </span>
                  </p>
                  <p className="assesment-answer">
                    {qa?.answer?.length ? <>{getAllAnswers(qa)}</> : <span style={{ color: "#b8b8b8" }}>No answer provided</span>}
                  </p>
                </div>
              )
          )}
      </>
    );
  }, [getAllAnswers, patient?.declarationForm]);

  const { query: orderDetailsQuery } = useProductDetails({
    area: order.emirate ?? "",
    productUUID: order.patientId?.[0]?.productUUID ?? "",
  });

  const isDarLabProduct = orderDetailsQuery.data?.product?.isDarLabProduct ?? false;

  const handleCopyLocation = useCallback((lattitude: string, longitude: string) => {
    const link = `https://maps.google.com/?q=${lattitude},${longitude}`;
    navigator.clipboard.writeText(link);
    showMessage("info", "Location copied successfully!");
  }, []);

  return (
    <div style={{ marginBottom: "1px" }}>
      <div className="doNotPrintThis">
        <Accordion defaultExpanded={true}>
          <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
            <Typography
              style={{
                color: "black",
                fontWeight: "bold",
              }}
            >
              {patient?.name}
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
              }}
            >
              <Row
                style={{
                  rowGap: "1rem",
                }}
              >
                <Col span={24}>
                  <Row
                    style={{
                      justifyContent: "space-between",
                      rowGap: "1rem",
                    }}
                  >
                    <Col xs={24} sm={24} md={24} lg={11}>
                      <div style={{ width: "100%" }}>
                        {patient?.speciality?.map((speciality, sindex) => (
                          <p
                            key={sindex}
                            style={{
                              marginBottom: 0,
                              fontSize: "16px",
                            }}
                          >
                            <div
                              style={{
                                fontWeight: "bold",
                                color: "black",
                              }}
                            >
                              <div
                                style={{
                                  marginBottom: "8px",
                                }}
                              >
                                {speciality?.type ? `${speciality?.type}` : " "}
                              </div>
                            </div>
                            <span
                              style={{
                                textTransform: "uppercase",
                                fontWeight: "bold",
                                color: `${
                                  patient?.type === "Covid-19 Antibody Test" ? "#034c9c" : getColor(speciality?.variantName)
                                }`,
                              }}
                            >
                              {speciality?.type?.includes("Antibody")
                                ? " - Antibody"
                                : speciality?.variantName
                                ? ` - ${speciality?.variantName}`
                                : ""}
                            </span>
                          </p>
                        ))}
                      </div>
                    </Col>
                    {!order?.isDeliveryOrder && (
                      <Col xs={24} sm={24} md={24} lg={11}>
                        {order?.productType === "S-D" ? (
                          <PatientInfoItem
                            name="Nurse"
                            value={
                              <ShowBookingAssignedNurse booking={order} onAssignNurseClicked={() => openNurseModal(order)} />
                            }
                          />
                        ) : null}
                      </Col>
                    )}
                  </Row>
                </Col>
                <Col span={24}>
                  <Row
                    style={{
                      justifyContent: "space-between",
                    }}
                  >
                    <Col xs={24} sm={24} md={24} lg={11}>
                      <PatientInfoItem name="Name" value={patient?.name} />
                      <PatientInfoItem name="Gender" value={patient?.sex} />
                      {!order.isDeliveryOrder && <PatientInfoItem name="Date of Birth" value={patient?.dateOfBirth} />}
                      {order.alternatePhoneNumber && (
                        <PatientInfoItem name="User's Phone" value={<CallUserButton order={order} />} />
                      )}
                      {patient?.phoneNumber && (
                        <PatientInfoItem name="Patient's Phone" value={<CallUserButton order={order} />} />
                      )}
                      {isDarLabProduct && <ShowUserEmail booking={order} />}
                      {getClinicalType}
                    </Col>
                    <Col xs={24} sm={24} md={24} lg={11}>
                      {!order.isDeliveryOrder && <PatientInfoItem name="EID/Passport" value={patient?.nationalId} />}
                      {!order.isDeliveryOrder && <PatientInfoItem name="Nationality" value={patient?.nationality} />}
                      <PatientInfoItem name="Location" value={mapAreaToEmirate[order?.emirate ?? ""] ?? order?.emirate} />
                      {/* <PatientInfoItem name="Flat/Villa No." value={order?.address?.flatOrVillaNumber} /> */}
                      {order.address?._id && (
                        <PatientInfoItem name="Address" value={<ShowBookingAddress labelWidth="90px" booking={order} />} />
                      )}
                    </Col>
                  </Row>
                </Col>
                {order?.orderUUID && <TRTAssesmentForm order={order} />}
                {order?.orderUUID && <PatientAssesmentFormAccordion patient={patient} booking={order} />}
                <PatientImages patientID={patient._id} />
                {order.orderUUID && (patient.reports?.length ?? 0) > 0 && (
                  <Col span={24}>
                    <PatientReportsList order={order} patient={patient} setOrder={setorder} />
                  </Col>
                )}
              </Row>
              {(patient?.declarationForm?.length ?? 0) > 0 && (
                <Accordion>
                  <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
                    <p
                      style={{
                        fontSize: "16px",
                        fontWeight: "bold",
                        marginBottom: "0",
                      }}
                    >
                      Assesment Form
                    </p>
                  </AccordionSummary>
                  <AccordionDetails>
                    <div>{getAssessmentForm}</div>
                  </AccordionDetails>
                </Accordion>
              )}

              {order?.guardianData && (
                <Accordion>
                  <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
                    <Typography
                      style={{
                        color: "black",
                        fontWeight: "bold",
                      }}
                    >
                      Guardian
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                      }}
                    >
                      <Row
                        style={{
                          justifyContent: "space-between",
                        }}
                      >
                        <Col xs={24} sm={24} md={24} lg={11}>
                          {order?.guardianData?.name && <PatientInfoItem name="Name" value={order?.guardianData?.name} />}
                          {order?.guardianData?.sex && <PatientInfoItem name="Gender" value={order?.guardianData?.sex} />}

                          {order?.guardianData?.dateOfBirth && (
                            <PatientInfoItem name="Date of Birth" value={order?.guardianData?.dateOfBirth} />
                          )}
                          {order?.guardianData?.email && isDarLabProduct && (
                            <PatientInfoItem name="E-mail" value={order?.guardianData?.email} />
                          )}
                          {order?.guardianData?.phoneNumber && (
                            <PatientInfoItem name="Phone no." value={<CallUserButton order={order} />} />
                          )}
                        </Col>
                        <Col xs={24} sm={24} md={24} lg={11}>
                          {order?.guardianData?.nationalId && (
                            <PatientInfoItem name="EID/Passport No." value={order?.guardianData?.nationalId} />
                          )}

                          {order?.guardianData?.nationality && (
                            <PatientInfoItem name="Nationality" value={order?.guardianData?.nationality} />
                          )}

                          {order?.guardianData?.emirate && (
                            <PatientInfoItem name="Location" value={order?.guardianData?.emirate} />
                          )}

                          {order?.address?.flatOrVillaNumber && (
                            <PatientInfoItem name="Flat/Villa No." value={order?.address?.flatOrVillaNumber} />
                          )}
                          {order?.address?.flatOrVillaNumber && (
                            <PatientInfoItem
                              name="Address"
                              value={
                                <Row
                                  style={{
                                    justifyContent: "flex-start",
                                    alignItems: "center",
                                  }}
                                >
                                  <Col>
                                    {order?.address?.address?.includes("https://") ? (
                                      <a href={order?.address?.address} target="_blank">
                                        {order?.address?.detail}
                                      </a>
                                    ) : (
                                      <p
                                        style={{
                                          lineBreak: "anywhere",
                                          margin: 0,
                                        }}
                                      >
                                        {order?.address?.detail}
                                      </p>
                                    )}
                                  </Col>
                                  <Col>
                                    <Tooltip title="Copy location">
                                      <span
                                        style={{
                                          cursor: "pointer",
                                          color: "#008690",
                                          fontWeight: "bold",
                                          fontSize: "14px",
                                          marginLeft: "10px",
                                        }}
                                        className="noprint"
                                        onClick={() =>
                                          handleCopyLocation(order?.address?.lattitude ?? "", order?.address?.longitude ?? "")
                                        }
                                      >
                                        Copy location <FaCopy size="14" />
                                      </span>
                                    </Tooltip>
                                  </Col>
                                </Row>
                              }
                            />
                          )}
                        </Col>
                      </Row>
                    </div>
                  </AccordionDetails>
                </Accordion>
              )}
            </div>
          </AccordionDetails>
        </Accordion>
      </div>
      <div className="doPrintThis pagebreak">
        <div
          style={{
            display: "flex",
            flexDirection: "column",
          }}
        >
          <h3
            style={{
              color: "black",
              fontWeight: "bold",
            }}
          >
            Patient {index + 1}
          </h3>
          <table className="table">
            <tbody className="tbody">
              <tr className="tr">
                <td colSpan={2} className="td">
                  {patient?.speciality?.map((speciality, sindex) => (
                    <p
                      key={sindex}
                      style={{
                        marginBottom: 0,
                        fontSize: "16px",
                      }}
                    >
                      <span
                        style={{
                          fontWeight: "bold",
                          color: "black",
                        }}
                      >
                        {speciality?.type ? `${speciality?.type}` : " "}
                      </span>

                      <span
                        style={{
                          textTransform: "uppercase",
                          fontWeight: "bold",
                          color: `${patient?.type === "Covid-19 Antibody Test" ? "#034c9c" : getColor(speciality?.variantName)}`,
                        }}
                      >
                        {speciality?.type?.includes("Antibody")
                          ? " - Antibody"
                          : speciality?.variantName
                          ? ` - ${speciality?.variantName}`
                          : ""}
                      </span>
                    </p>
                  ))}
                </td>
              </tr>
              <tr className="tr">
                <td className="td">
                  <p
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "flex-start",
                    }}
                  >
                    Name:&nbsp;&nbsp;
                    <span
                      style={{
                        color: "black",
                        fontWeight: "bold",
                      }}
                    >
                      {patient?.name}
                    </span>
                  </p>
                  <p
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "flex-start",
                    }}
                  >
                    Gender:&nbsp;&nbsp;
                    <span style={{ color: "black" }}>{patient?.sex}</span>
                  </p>
                  <p
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "flex-start",
                    }}
                  >
                    Date of Birth:&nbsp;&nbsp;
                    <span style={{ color: "black" }}>{patient?.dateOfBirth}</span>
                  </p>
                  {isDarLabProduct && (
                    <p
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "flex-start",
                      }}
                    >
                      E-mail:&nbsp;&nbsp;
                      <span style={{ color: "black" }}>{patient?.email}</span>
                    </p>
                  )}
                  {patient?.phoneNumber && (
                    <p
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "flex-start",
                      }}
                    >
                      Phone no.:&nbsp;&nbsp;
                      <span style={{ color: "black" }}>
                        <CallUserButton order={order} />
                      </span>
                    </p>
                  )}
                  {order?.alternatePhoneNumber && (
                    <p
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "flex-start",
                      }}
                    >
                      Alternate Phone no.:&nbsp;&nbsp;
                      <span style={{ color: "black" }}>
                        <CallUserButton order={order} />
                      </span>
                    </p>
                  )}
                </td>
                <td className="td">
                  <p
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "flex-start",
                    }}
                  >
                    EID/Passport No.:&nbsp;&nbsp;
                    <span
                      style={{
                        color: "black",
                        fontWeight: "bold",
                      }}
                    >
                      {patient?.nationalId}
                    </span>
                  </p>
                  <p
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "flex-start",
                    }}
                  >
                    Nationality:&nbsp;&nbsp;
                    <span style={{ color: "black" }}>{patient?.nationality}</span>
                  </p>
                  <p
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "flex-start",
                    }}
                  >
                    Location:&nbsp;&nbsp;
                    <span style={{ color: "black" }}>{order?.emirate}</span>
                  </p>
                  <p
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "flex-start",
                    }}
                  >
                    Flat/Villa No.:&nbsp;&nbsp;
                    <span style={{ color: "black" }}>{order?.address?.flatOrVillaNumber}</span>
                  </p>
                  <p
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "flex-start",
                    }}
                  >
                    Address:&nbsp;&nbsp;
                    <span style={{ color: "black" }}>
                      {order?.address?.address?.includes("https://") ? (
                        <a href={order?.address?.address} target="_blank">
                          {order?.address?.detail}
                        </a>
                      ) : (
                        <p>
                          {order?.address?.address} {order?.address?.detail}
                        </p>
                      )}
                    </span>
                  </p>
                  <p
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "flex-start",
                    }}
                  ></p>
                </td>
              </tr>

              {(patient?.declarationFormData?.length ?? 0) > 0 && (
                <tr className="tr">
                  <td className="td" colSpan={2}>
                    <div>
                      <p
                        style={{
                          fontSize: "16px",
                          fontWeight: "bold",
                        }}
                      >
                        Assesment Form
                      </p>

                      <div>{getAssessmentForm}</div>
                    </div>
                  </td>
                </tr>
              )}
            </tbody>
          </table>
          {order?.guardianData && (
            <>
              {" "}
              <h3
                style={{
                  color: "black",
                  fontWeight: "bold",
                }}
              >
                Guardian
              </h3>
              <table className="table">
                <tbody className="tbody">
                  <tr className="tr">
                    <td className="td">
                      <p
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "flex-start",
                        }}
                      >
                        Name:&nbsp;&nbsp;
                        <span
                          style={{
                            color: "black",
                            fontWeight: "bold",
                          }}
                        >
                          {order?.guardianData?.name}
                        </span>
                      </p>
                      <p
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "flex-start",
                        }}
                      >
                        Gender:&nbsp;&nbsp;
                        <span
                          style={{
                            color: "black",
                          }}
                        >
                          {order?.guardianData?.sex}
                        </span>
                      </p>
                      <p
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "flex-start",
                        }}
                      >
                        Date of Birth:&nbsp;&nbsp;
                        <span
                          style={{
                            color: "black",
                          }}
                        >
                          {order?.guardianData?.dateOfBirth}
                        </span>
                      </p>
                      {isDarLabProduct && (
                        <p
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "flex-start",
                          }}
                        >
                          E-mail:&nbsp;&nbsp;
                          <span
                            style={{
                              color: "black",
                            }}
                          >
                            {order?.guardianData?.email}
                          </span>
                        </p>
                      )}
                      {order?.guardianData?.phoneNumber && (
                        <p
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "flex-start",
                          }}
                        >
                          Phone no.:&nbsp;&nbsp;
                          <span
                            style={{
                              color: "black",
                            }}
                          >
                            <CallUserButton order={order} />
                          </span>
                        </p>
                      )}
                    </td>
                    <td className="td">
                      <p
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "flex-start",
                        }}
                      >
                        EID/Passport No.:&nbsp;&nbsp;
                        <span
                          style={{
                            color: "black",
                            fontWeight: "bold",
                          }}
                        >
                          {order?.guardianData?.nationalId}
                        </span>
                      </p>
                      <p
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "flex-start",
                        }}
                      >
                        Nationality:&nbsp;&nbsp;
                        <span
                          style={{
                            color: "black",
                          }}
                        >
                          {order?.guardianData?.nationality}
                        </span>
                      </p>
                    </td>
                  </tr>
                </tbody>
              </table>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

interface OrderDetailsPatientListItemProps {
  patient: BookingPatientModel;
  order: BookingModel;
  index: number;
  setorder: React.Dispatch<React.SetStateAction<BookingModel>>;
  openNurseModal: (order: BookingModel) => void;
}

export default OrderDetailsPatientListItem;
