import { settingType } from "../types";

export const initialState = {
  manageDateTime: {},
  settingLoader: false,
};

export function settingReducer(state = initialState, action) {
  switch (action.type) {
    case settingType.GET_SETTING_LOADER_TRUE:
      return {
        ...state,
        settingLoader: true,
      };
    case settingType.GET_SETTING_LOADER_FALSE:
      return {
        ...state,
        settingLoader: false,
      };
    case settingType.GET_MANAGE_DATE_TIME:
      return {
        ...state,
        manageDateTime: action.data,
      };

    case settingType.LOGOUT:
      return {
        manageDateTime: {},
        disabledDates: [],
        settingLoader: false,
        service: [],
      };

    default: {
      return {
        ...state,
      };
    }
  }
}

// {
//   partnerUUID: "64dea9a7-d735-45e2-858a-765b929ecf7b",
//   service: [
//     {
//       serviceName: "Covid 19 PCR",
//       variantName: "Standard",
//       withinHours: "12-24",
//       status: "true",
//       bookingAcceptanceHourPerDay: [
//         {
//           dayName: "monday",
//           timeSlot: {
//             timeStart:
//               "Mon Sep 27 2021 13:52:42 GMT+0530 (India Standard Time)",
//             timeEnd:
//               "Mon Sep 27 2021 13:52:42 GMT+0530 (India Standard Time)",
//             capacity: "3",
//           },
//         },
//       ],
//       specialHour: [
//         {
//           date: "Mon Sep 27 2021 13:52:42 GMT+0530 (India Standard Time)",
//           timeStart:
//             "Mon Sep 27 2021 13:52:42 GMT+0530 (India Standard Time)",
//           timeEnd: "Mon Sep 27 2021 13:52:42 GMT+0530 (India Standard Time)",
//           status: "true",
//         },
//         {
//           date: "Mon Sep 28 2021 13:52:42 GMT+0530 (India Standard Time)",
//           timeStart:
//             "Mon Sep 27 2021 13:52:42 GMT+0530 (India Standard Time)",
//           timeEnd: "Mon Sep 27 2021 13:52:42 GMT+0530 (India Standard Time)",
//           status: "true",
//         },
//       ],
//       holiday: [
//         {
//           dayStart: "Mon Sep 27 2021 13:52:42 GMT+0530 (India Standard Time)",
//           dayEnd: "Mon Sep 27 2021 13:52:42 GMT+0530 (India Standard Time)",
//         },
//       ],
//     },
//   ],
//   workingHourPerDay: [
//     {
//       dayName: "monday",
//       timeSlot: [
//         {
//           timeStart:
//             "Mon Sep 17 2021 13:52:42 GMT+0530 (India Standard Time)",
//           timeEnd: "Mon Sep 27 2021 13:52:42 GMT+0530 (India Standard Time)",
//           capacity: "3",
//         },
//       ],
//     },
//   ],
//   specialHour: [
//     {
//       date: "Mon Sep 26 2021 13:52:42 GMT+0530 (India Standard Time)",
//       timeStart: "Mon Sep 27 2021 13:52:42 GMT+0530 (India Standard Time)",
//       timeEnd: "Mon Sep 27 2021 13:52:42 GMT+0530 (India Standard Time)",
//       status: "true",
//     },
//     {
//       date: "Mon Sep 26 2021 13:52:42 GMT+0530 (India Standard Time)",
//       timeStart: "Mon Sep 27 2021 13:52:42 GMT+0530 (India Standard Time)",
//       timeEnd: "Mon Sep 27 2021 13:52:42 GMT+0530 (India Standard Time)",
//       status: "true",
//     },
//     {
//       date: "Mon Sep 26 2021 13:52:42 GMT+0530 (India Standard Time)",
//       timeStart: "Mon Sep 27 2021 13:52:42 GMT+0530 (India Standard Time)",
//       timeEnd: "Mon Sep 27 2021 13:52:42 GMT+0530 (India Standard Time)",
//       status: "false",
//     },
//     {
//       date: "Mon Sep 28 2021 13:52:42 GMT+0530 (India Standard Time)",
//       timeStart: "Mon Sep 27 2021 13:52:42 GMT+0530 (India Standard Time)",
//       timeEnd: "Mon Sep 27 2021 13:52:42 GMT+0530 (India Standard Time)",
//       status: "true",
//     },
//   ],
//   holiday: [
//     {
//       dayStart: "Mon Sep 28 2021 13:52:42 GMT+0530 (India Standard Time)",
//       dayEnd: "Mon Sep 28 2021 13:52:42 GMT+0530 (India Standard Time)",
//     },
//   ],
// }
