import { FC } from "react";
import PharmacyBookingModel from "../../models/PharmacyBookingModel";
import MarkOrderDispatchedButton from "./MarkOrderDispatchedButton";
import MarkDeliveredButton from "./MarkDeliveredButton";
import StatusChip from "./StatusChip";

const DetailsViewDrawerHeader: FC<DetailsViewDrawerHeaderProps> = ({ order }) => {
  return (
    <div
      style={{
        padding: "16px 24px",
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        height: "64px",
        // marginTop: "50px",
      }}
    >
      {order.appStatus && <StatusChip status={order.appStatus} />}
      {order.appStatus === "PRESCRIPTION_UPLOADED" && <MarkOrderDispatchedButton order={order} />}
      {order.appStatus === "DISPATCHED" && <MarkDeliveredButton order={order} />}
    </div>
  );
};

interface DetailsViewDrawerHeaderProps {
  order: PharmacyBookingModel;
}

export default DetailsViewDrawerHeader;
