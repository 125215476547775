import { useQuery } from "@tanstack/react-query";
import { getPatientDetails } from "../services";
import { useMemo } from "react";

function usePatientDetails(params: { patientID?: string }) {
  const queryKey = useMemo(() => {
    return ["/api/v1/patients/getparticularpatient", params.patientID];
  }, [params.patientID]);

  const query = useQuery(queryKey, () => getPatientDetails({ patientID: params.patientID ?? "" }), {
    enabled: !!params.patientID,
    refetchOnWindowFocus: false,
    staleTime: Infinity,
    cacheTime: Infinity,
  });

  return { query, queryKey };
}

export default usePatientDetails;
