import { FC } from "react";
import { Grid } from "@mui/material";
import useUserSignUpLink from "./useUserSignUpLink";
import DarDocLogo from "../../assets/images/logo.jpg";
import SignUpForm from "./SignUpForm";
import OTPForm from "./OTPForm";

const UserSignUpLink: FC<UserSignUpLinkProps> = () => {
  const {
    checkUniqueUserMutation,
    sendOTPLoading,
    sendFireAuthOTPConfirmationResult,
    resendOTPLoading,
    formValues,
    confirmFirebaseOTPLoading,
    verifyWhatsappOTPMutation,
    sendWhatsappOTPMutation,
    firebaseCustomTokenMutation,
    loginPartnerUser,
    handleRequestCallClicked,
    handleFormSubmit,
    handleSignupOTPEntered,
    handleResendOTPClicked,
  } = useUserSignUpLink({ mode: "SIGNUP" });

  return (
    <div
      style={{
        width: "100vw",
        height: "100vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: "white",
      }}
    >
      <Grid
        id="login-form-container"
        container={true}
        spacing={2}
        sx={{ alignItems: "center", justifyContent: "center", marginBottom: "200px" }}
      >
        <div id="login-captcha" />
        <Grid xs={12} sm={12} md={6} lg={4} item={true}>
          <div style={{ display: "flex", justifyContent: "center", width: "100%" }}>
            <img src={DarDocLogo} alt="DarDoc" style={{ width: "120px" }} />
          </div>
          <h3 style={{ textAlign: "center" }}>Sign Up</h3>
          {sendFireAuthOTPConfirmationResult ? (
            <OTPForm
              mobileNumber={formValues?.mobileNumber ?? ""}
              resendOTPLoading={resendOTPLoading}
              submitLoading={
                confirmFirebaseOTPLoading ||
                checkUniqueUserMutation.isLoading ||
                verifyWhatsappOTPMutation.isLoading ||
                firebaseCustomTokenMutation.isLoading ||
                loginPartnerUser.isLoading
              }
              requestCallLoading={sendWhatsappOTPMutation.isLoading}
              handleCallRequestClicked={handleRequestCallClicked}
              handleFormSubmit={handleSignupOTPEntered}
              handleResendOTPClicked={handleResendOTPClicked}
            />
          ) : (
            <SignUpForm submitLoading={checkUniqueUserMutation.isLoading || sendOTPLoading} handleFormSubmit={handleFormSubmit} />
          )}
        </Grid>
      </Grid>
    </div>
  );
};

interface UserSignUpLinkProps {}

export default UserSignUpLink;
