import { useQuery } from "@tanstack/react-query";
import { getDeliveryPartnerOrders } from "../services/inventory";
import { useMemo } from "react";

export default function useDeliveryPartnerOrders({ partnerUUID }: { partnerUUID?: string }) {
  const queryKey = useMemo(() => ["inventory-requests/get-delivery-partner-requests", partnerUUID], [partnerUUID]);

  const query = useQuery(queryKey, () => getDeliveryPartnerOrders({ partnerUUID: partnerUUID ?? "" }), {
    enabled: !!partnerUUID,
  });

  return { query, queryKey };
}
