import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import { FC } from "react";
import { Spin } from "antd";
import BookingsTableRow from "./BookingsTableRow";
import useBookingsList from "../../hooks/useBookingsList";
import BookingsTableProps from "./BookingsTableProps";

const BookingsTable: FC<BookingsTableProps> = ({
  bookings,
  loading,
  loadingNextPage,
  inViewRes,
  handleInvalidateQueries,
  onAssignNurseSuccess,
}) => {
  const { filteredBookings } = useBookingsList({ bookings: bookings });

  if (loading) {
    return (
      <div style={{ width: "100%", textAlign: "center", marginTop: "100px", display: "flex", justifyContent: "center" }}>
        <Spin spinning={true} />
      </div>
    );
  } else if (!filteredBookings?.length) {
    return <div style={{ width: "100%", textAlign: "center", marginTop: "100px" }}>No Bookings</div>;
  }

  return (
    <TableContainer component={Paper}>
      <Table
        sx={{
          minWidth: 1250,
          height: "100%",
          maxHeight: "100%",
          display: "flex",
          position: "relative",
          flexDirection: "column",
          "& .MuiTableRow-root": {
            width: "100%",
            display: "flex",
          },
          "& .MuiTableRow-root .MuiTableCell-root": {
            display: "flex",
            flex: "1 1 0",
            flexDirection: "column",
            // justifyContent: "center",
            alignItems: "flex-start",
          },
          "& .MuiTableRow-root .MuiTableCell-root.date-time-cell": {
            // maxWidth: "150px",
            flex: "1 1 0",
          },
          "& .MuiTableRow-root .MuiTableCell-root.service-cell": {
            // maxWidth: "180px",
            flex: "2 1 0",
          },
          "& .MuiTableRow-root .MuiTableCell-root.status-cell": {
            // maxWidth: "200px",
            flex: "2 1 0",
          },
          "& .MuiTableRow-root .MuiTableCell-root.patient-cell": {
            maxWidth: "240px",
            flex: "2 1 0",
          },
          "& .MuiTableRow-root .MuiTableCell-root.address-cell": {
            flex: "3 1 0",
          },
        }}
        aria-label="simple table"
        stickyHeader={true}
      >
        <TableHead sx={{ display: "flex", width: "100%" }}>
          <TableRow>
            <TableCell className="service-cell">Service</TableCell>
            <TableCell className="date-time-cell">Date & Time</TableCell>
            <TableCell className="patient-cell">Patient</TableCell>
            <TableCell className="status-cell">Status</TableCell>
            <TableCell className="address-cell">Location</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {filteredBookings?.map((booking) => {
            return (
              <BookingsTableRow
                key={booking.orderUUID}
                booking={booking}
                onAssignNurseSuccess={onAssignNurseSuccess}
                handleInvalidateQueries={handleInvalidateQueries}
              />
            );
          })}
          <TableRow sx={{ height: "100px", position: "relative" }} id="load-more-row">
            <div
              ref={inViewRes.ref}
              style={{
                position: "absolute",
                width: "100%",
                height: "100%",
                top: "0",
                left: "0",
                display: "flex",
                justifyContent: "center",
                padding: "2rem",
              }}
            >
              <Spin spinning={loadingNextPage} />
            </div>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default BookingsTable;
