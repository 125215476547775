import { Col, Row, Tooltip } from "antd";
import { FC, useCallback } from "react";
import { FaCopy } from "react-icons/fa";
import BookingModel from "../../../models/BookingModel";
import { showMessage } from "../notification";

const AssignNurseModalPatientAddress: FC<
  AssignNurseModalPatientAddressProps
> = ({ order }) => {
  const handleCopyLocation = useCallback(
    (lattitude?: string, longitude?: string) => {
      if (!lattitude || !longitude) return;
      const link = `https://maps.google.com/?q=${lattitude},${longitude}`;
      navigator.clipboard.writeText(link);
      showMessage("info", "Location copied successfully!");
    },
    []
  );

  return (
    <>
      <p
        style={{
          color: "gray",
        }}
      >
        Address
      </p>
      <Row
        style={{
          backgroundColor: "white",
          boxShadow:
            "rgb(0 0 0 / 10%) 0px 2px 5px 0px, rgb(0 0 0 / 12%) 0px 4px 6px 0px",
          padding: "1rem 1rem",
          borderRadius: "7px",
          alignItems: "center",
          gap: "0.3rem",
        }}
      >
        <Col span={24}>
          <Row>
            <Col>
              <p style={{ color: "gray", margin: 0 }}>Flat/Villa No.: </p>
            </Col>
            <Col>
              <p style={{ lineBreak: "anywhere", margin: 0 }}>
                {order?.address?.flatOrVillaNumber}
              </p>
            </Col>
          </Row>
        </Col>
        <Col span={24}>
          <Row>
            <Col>
              <p style={{ color: "gray", margin: 0 }}>Detail Address: </p>
            </Col>
            <Col>
              {order?.address?.address?.includes("https://") ? (
                <a href={order?.address?.address} target="_blank">
                  {order?.address?.detail}
                </a>
              ) : (
                <p style={{ lineBreak: "anywhere", margin: 0 }}>
                  {order?.address?.detail}
                </p>
              )}
            </Col>
          </Row>
        </Col>
        <Col>
          <Tooltip title="Copy location">
            <span
              style={{
                cursor: "pointer",
                color: "#008690",
                fontWeight: "bold",
                fontSize: "14px",
              }}
              className="noprint"
              onClick={() =>
                handleCopyLocation(
                  order?.address?.lattitude,
                  order?.address?.longitude
                )
              }
            >
              Copy location <FaCopy size="14" />
            </span>
          </Tooltip>
        </Col>
      </Row>
    </>
  );
};

interface AssignNurseModalPatientAddressProps {
  order: BookingModel;
}

export default AssignNurseModalPatientAddress;
