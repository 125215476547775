import { Button } from "antd";
import { FC } from "react";
import { FaCarSide } from "react-icons/fa";
import { Link } from "react-router-dom";
import useCurrentUserRole from "../../hooks/useCurrentUserRole";

const CreateDriverButton: FC<CreateDriverButtonProps> = () => {
  const { canUpdate } = useCurrentUserRole();

  if (!canUpdate) return null;

  return (
    <Button
      style={{
        borderRadius: "10px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        gap: "8px",
        width: "100%",
        maxWidth: "210px",
      }}
      size="large"
    >
      <Link to="/create-driver">
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            gap: "8px",
          }}
        >
          <FaCarSide />
          <span style={{ paddingTop: "3px", fontWeight: "500" }}>Create Driver</span>
        </div>
      </Link>
    </Button>
  );
};

interface CreateDriverButtonProps {}

export default CreateDriverButton;
