import { FC } from "react";
import { SlMenu } from "react-icons/sl";
import { Box, Typography, styled } from "@mui/material";
import { drawerWidth } from "../constants";
import { useSelector } from "react-redux";
import { grey } from "@mui/material/colors";
import MuiAppBar, { AppBarProps as MuiAppBarProps } from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import NotificationsButton from "./NotificationsButton";
import ReduxStateModel from "../models/ReduxStateModel";
import InventoryRequestButton from "./InventoryRequestButton";
import OutSourceInventoryButton from "./OutSourceInventoryButton";

const BaseLayoutAppBar: FC<BaseLayoutAppBarProps> = ({
  open,
  backgroundColor,
  zIndex,
  textColor,
  appBarContent,
  notificationsIconColor,
  setOpen,
}) => {
  const partnerName = useSelector((state: ReduxStateModel) => state.authReducer?.setting?.name);

  return (
    <AppBar
      position="fixed"
      open={open}
      elevation={0}
      className="app-bar"
      sx={{
        zIndex: zIndex,
        backgroundColor: backgroundColor,
        left: "0px",
        width: "100%",
        margin: "0px",
        height: "50px",
        borderBottom: `1px solid ${grey[200]}`,
        "& .MuiToolbar-root": {
          minHeight: "50px",
        },
      }}
    >
      <Toolbar
        sx={{ paddingLeft: "16px", paddingRight: "16px", position: "relative", justifyContent: "space-between" }}
        className="tool-bars"
      >
        <div style={{ position: "relative", zIndex: "2", display: "flex", gap: "8px", alignItems: "center" }}>
          <IconButton color="inherit" aria-label="open drawer" onClick={() => setOpen(!open)} sx={{ color: textColor }}>
            <SlMenu style={{ fontSize: "18px" }} />
          </IconButton>
          <Typography
            fontWeight={600}
            sx={{
              color: textColor,
              "@media (max-width: 700px)": {
                display: "none",
              },
            }}
          >
            {partnerName}
          </Typography>
        </div>
        <Box display={"flex"} gap={2} paddingY={1} height={"100%"}>
          <OutSourceInventoryButton />
          <InventoryRequestButton />
          <NotificationsButton iconColor={notificationsIconColor} />
        </Box>
        {appBarContent && (
          <div
            style={{
              position: "absolute",
              width: "100%",
              height: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            {appBarContent}
          </div>
        )}
      </Toolbar>
    </AppBar>
  );
};

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})<AppBarProps>(({ theme, open }) => ({
  transition: theme.transitions.create(["margin", "width"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: `${drawerWidth}px`,
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
}

interface BaseLayoutAppBarProps {
  open: boolean;
  backgroundColor: string;
  zIndex: number;
  textColor: string;
  appBarContent?: React.ReactNode;
  notificationsIconColor?: string;
  setOpen: (value: boolean) => void;
}

export default BaseLayoutAppBar;
