import { FC } from "react";
import { StaffMemberStatus } from "../../models/StaffMemberModel";
import { Tooltip } from "antd";
import InfoRoundedIcon from "@mui/icons-material/InfoRounded";
import PendingActionsRoundedIcon from "@mui/icons-material/PendingActionsRounded";
import CancelRoundedIcon from "@mui/icons-material/CancelRounded";
import VerifiedRoundedIcon from "@mui/icons-material/VerifiedRounded";

const ProfessionalStatusIcon: FC<ProfessionalStatusIconProps> = ({ status, rejectReason }) => {
  return (
    <>
      {status === "APPROVED" && (
        <Tooltip title="Professional is verified.">
          <VerifiedRoundedIcon color="primary" />
        </Tooltip>
      )}
      {status === "REJECTED" && (
        <Tooltip title={rejectReason || "Professional verification is rejected."}>
          <CancelRoundedIcon color="error" />
        </Tooltip>
      )}
      {status === "PENDING_VERIFICATION" && (
        <Tooltip title="Documents uploaded. Pending verification.">
          <PendingActionsRoundedIcon color="success" />
        </Tooltip>
      )}
      {status === "PENDING_DOCUMENT_UPLOAD" && (
        <Tooltip title="Professional has to upload verification documents from the Darlink app.">
          <InfoRoundedIcon color="warning" />
        </Tooltip>
      )}
    </>
  );
};

interface ProfessionalStatusIconProps {
  status: StaffMemberStatus;
  rejectReason?: string;
}

export default ProfessionalStatusIcon;
