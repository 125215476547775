import { useCallback, useEffect, useMemo, useState } from "react";
import { Button, Divider, Modal } from "antd";
import { Redirect, useHistory, useParams, useRouteMatch } from "react-router-dom";
import { LeftOutlined, RightOutlined } from "@ant-design/icons";
import { useSelector } from "react-redux";
import moment from "moment";
import { generateRangeArray } from "../../utils/utilFunctions";
import { NurseBookingModel } from "../../models/SchedulerExploreViewResponseModel";
import { NursesDatum } from "../../models/SchedulerExploreViewResponseModel";
import { minimumBookingHoursPerDay } from "../../constants";

const AssignDateModal = () => {
  const history = useHistory<{
    caseDescription?: string;
    patients?: string;
    initialSelectedDate?: number;
    nurseUUID?: string;
    nurseData?: NursesDatum;
  }>();
  // console.log("--- location", history.location);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const selectedDetailViewWeek: moment.Moment = useSelector((state) => (state as any)?.schedulerDetailViewReducer?.selectedWeek);
  const [selectedMonth, setSelectedMonth] = useState<moment.Moment>(
    selectedDetailViewWeek.clone().endOf("week").startOf("month")
  );
  const [selectedDates, setSelectedDates] = useState<Map<number, boolean>>(new Map());
  const monthName = selectedMonth.format("MMMM");
  const firstCalendarDay = useMemo(() => selectedMonth.clone().startOf("week"), [selectedMonth]);
  const startOfCurrentDay = useMemo<moment.Moment>(() => moment().startOf("day"), []);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const nurseUUID = (useParams() as any)?.nurseUUID ?? history.location?.state?.nurseUUID;
  const { url } = useRouteMatch();
  const bookingsNurseDatesMap: { [prop: string]: NurseBookingModel[] } = useSelector(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (state) => (state as any)?.schedulerExploreViewReducer?.nurseDayBookings
  );
  const nurseData = history.location.state?.nurseData;
  // console.log("--- nurseData", nurseData);

  const nurseStartMoment = useMemo<moment.Moment | undefined>(() => {
    const now = moment();
    if (!nurseData) return undefined;
    return moment(`${now.format("DD/MM/YYYY")} ${nurseData?.startTime}`, "DD/MM/YYYY hh:mmA");
  }, [nurseData]);

  // console.log("--- nurseStartMoment", nurseStartMoment?.format(), nurseStartMoment?.format("A"));

  const nurseEndMoment = useMemo<moment.Moment | undefined>(() => {
    const now = moment();
    if (!nurseData) return undefined;
    return moment(`${now.format("DD/MM/YYYY")} ${nurseData?.endTime}`, "DD/MM/YYYY hh:mmA")
      .set("second", 0)
      .set("millisecond", 0);
  }, [nurseData]);

  // console.log("--- nurseEndMoment", nurseEndMoment?.format(), nurseEndMoment?.format("A"));

  const nurseEndHour = (nurseEndMoment?.get("hour") ?? 0) === 0 ? 24 : nurseEndMoment?.get("hour") ?? 0;
  const nurseHoursPerDay = nurseEndHour - (nurseStartMoment?.get("hour") ?? 0);

  const checkIfDateIsOccupied = useCallback(
    (date: moment.Moment) => {
      const dateBookings = bookingsNurseDatesMap[`${nurseUUID}_${date.format()}`] ?? [];
      let sumOfBookingsHours = 0;
      for (const booking of dateBookings) {
        sumOfBookingsHours += booking.endMoment.get("hour") - booking.startMoment.get("hour");
      }
      // console.log("--- checkIfDateIsOccupied", date.format(), nurseHoursPerDay, sumOfBookingsHours, minimumBookingHoursPerDay);
      // console.log("--- end", nurseEndMoment?.format(), nurseEndMoment?.get("hour"), nurseStartMoment?.get("hour"));
      // console.log(nurseHoursPerDay - sumOfBookingsHours < minimumBookingHoursPerDay);
      return nurseHoursPerDay - sumOfBookingsHours < minimumBookingHoursPerDay;
    },
    [bookingsNurseDatesMap, nurseHoursPerDay, nurseUUID]
  );

  useEffect(() => {
    if (!history.location?.state?.initialSelectedDate) return;
    if (checkIfDateIsOccupied(moment(history.location?.state?.initialSelectedDate))) return;
    setSelectedDates((prevState) => {
      const newMap = new Map<number, boolean>();
      for (const key of Array.from(prevState.keys())) {
        newMap.set(key, prevState.get(key) ?? false);
      }
      newMap.set(history.location.state.initialSelectedDate!, true);
      return newMap;
    });
  }, [history.location?.state, checkIfDateIsOccupied]);
  // const calendarHeaderElements = useMemo<JSX.Element[]>(() => {
  //   const elements: JSX.Element[] = [];
  //   const currentDay = selectedMonth.clone();
  //   const startOfNextWeek = currentDay.clone().add(1, "week");
  //   while (currentDay.isBefore(startOfNextWeek)) {
  //     elements.push(
  //       <div
  //         style={{
  //           display: "flex",
  //           flexDirection: "column",
  //           alignItems: "center",
  //           justifyContent: "center",
  //           width: "14.2%",
  //         }}
  //       >
  //         <div>{currentDay.format("ddd")}</div>
  //       </div>
  //     );
  //     currentDay.add(1, "day");
  //   }
  //   return elements;
  // }, [selectedMonth]);

  if (!history.location.state?.patients) {
    const baseURL = url.split("/")[1];
    if (baseURL === "scheduler-details") {
      return <Redirect to={`/${baseURL}/${nurseUUID}/assign-patients`} />;
    } else {
      return <Redirect to={`/${baseURL}/assign-patients`} />;
    }
  }

  const handleDateSelected = (date: moment.Moment) => {
    setSelectedDates(() => {
      const newMap = new Map<number, boolean>();
      for (const key of Array.from(selectedDates.keys())) {
        newMap.set(key, selectedDates.get(key) ?? false);
      }
      if (newMap.get(date.valueOf())) {
        newMap.delete(date.valueOf());
      } else {
        newMap.set(date.valueOf(), true);
      }
      return newMap;
    });
  };

  const handleContinueClicked = () => {
    const baseURL = url.split("/")[1];
    if (baseURL === "scheduler-details") {
      history.replace(`/${baseURL}/${nurseUUID}/assign-start-end-time`, getHistoryState());
    } else {
      history.replace(`/${baseURL}/assign-start-end-time`, getHistoryState());
    }
  };

  const getHistoryState = () => {
    return {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      ...((history.location?.state as any) ?? {}),
      selectedDate: Array.from(selectedDates.keys()),
    };
  };

  const onCloseClicked = () => {
    const baseURL = url.split("/")[1];
    if (baseURL === "scheduler-details") {
      history.replace(`/${baseURL}/${nurseUUID}`, getHistoryState());
    } else {
      history.replace(`/${baseURL}`, getHistoryState());
    }
  };

  const onBackClicked = () => {
    const baseURL = url.split("/")[1];
    if (baseURL === "scheduler-details") {
      history.replace(`/${baseURL}/${nurseUUID}/assign-patients`, getHistoryState());
    } else {
      history.replace(`/${baseURL}/assign-patients`, getHistoryState());
    }
  };

  return (
    <Modal
      centered
      className="scheduler-modal scheduler-assign-date-modal"
      title="Select Date"
      open={true}
      maskClosable={false}
      footer={null}
      onCancel={onCloseClicked}
    >
      <div
        style={{
          paddingLeft: "24px",
          paddingRight: "24px",
          paddingTop: "24px",
          paddingBottom: "24px",
          display: "flex",
          flexDirection: "column",
          gap: "1rem",
        }}
      >
        <p style={{ margin: "0px" }}>Please select the date</p>
        <div className="month-selector">
          <Button
            className="prev-month-btn"
            type="text"
            onClick={() => {
              setSelectedMonth((prevState) => prevState.clone().subtract(1, "month"));
            }}
          >
            <LeftOutlined />
          </Button>
          <div className="month-date">{monthName}</div>
          <Button
            className="next-month-btn"
            type="text"
            onClick={() => {
              setSelectedMonth((prevState) => prevState.clone().add(1, "month"));
            }}
          >
            <RightOutlined />
          </Button>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          {generateRangeArray({ end: 6 }).map<JSX.Element>((item) => {
            const date = firstCalendarDay.clone().add(item, "day");
            return (
              <div
                key={date.valueOf()}
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                  width: "14.2%",
                }}
              >
                {date.format("ddd")}
              </div>
            );
          })}
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            flexWrap: "wrap",
          }}
        >
          {generateRangeArray({ end: 41 }).map<JSX.Element>((item) => {
            const date = firstCalendarDay.clone().add(item, "day");
            const isInCurrentMonth = date.get("month") === selectedMonth.get("month");
            const isDisabled = !isInCurrentMonth || date.isBefore(startOfCurrentDay) || checkIfDateIsOccupied(date);
            // console.log(
            //   "---- isDisabled",
            //   date.format(),
            //   !isInCurrentMonth,
            //   date.isBefore(startOfCurrentDay),
            //   checkIfDateIsOccupied(date)
            // );
            // const selected = !!selectedDates?.isSame(date);
            const selected = !isDisabled && (selectedDates.get(date.valueOf()) ?? false);
            let color: string;
            if (selected) {
              color = "white";
            } else if (!isDisabled) {
              color = "black";
            } else {
              color = "#dadada";
            }
            return (
              <div
                key={date.valueOf()}
                onClick={isDisabled ? undefined : () => handleDateSelected(date)}
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                  width: "14.2%",
                  aspectRatio: "1",
                  color: color,
                  cursor: !isDisabled ? "pointer" : "default",
                  padding: "1rem",
                }}
              >
                <div
                  style={{
                    transition: "all 0.2s ease-out",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    backgroundColor: selected ? "#008690" : "",
                    borderRadius: selected ? "50%" : "",
                    width: "100%",
                    height: "100%",
                    lineHeight: "1",
                  }}
                >
                  {date.format("DD")}
                </div>
              </div>
            );
          })}
        </div>
      </div>
      <Divider
        style={{
          margin: "0px",
        }}
      />
      <div
        style={{
          paddingLeft: "24px",
          paddingRight: "24px",
          paddingBottom: "12px",
          paddingTop: "12px",
          display: "flex",
          justifyContent: "flex-end",
          alignContent: "center",
          gap: "1rem",
        }}
      >
        <Button onClick={onBackClicked} type="default" className="ant-btn-cancel">
          Back
        </Button>
        <Button type="primary" disabled={!selectedDates.size} onClick={handleContinueClicked}>
          Continue
        </Button>
      </div>
    </Modal>
  );
};

export default AssignDateModal;
