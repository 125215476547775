import { FC } from "react";
import { HiMiniCake } from "react-icons/hi2";
import { IoMedical } from "react-icons/io5";
import { LiaUserCircleSolid } from "react-icons/lia";
import { BiUserCircle } from "react-icons/bi";
import { LiaIdCardSolid } from "react-icons/lia";
import { useTheme } from "@mui/material";
import { AiOutlineMobile } from "react-icons/ai";
import PatientModel from "../../models/PatientModel";

const DetailsViewPatientCard: FC<DetailsViewPatientCardProps> = ({ patients }) => {
  const theme = useTheme();

  return (
    <div style={{ display: "flex", flexWrap: "wrap", gap: "16px", marginTop: "16px" }}>
      {patients.map((patient) => {
        return (
          <div
            key={patient.nationalId}
            style={{
              padding: "16px",
              borderRadius: "6px",
              border: `1px solid ${theme.components?.MuiDivider?.defaultProps?.style?.borderColor}`,
              backgroundColor: "rgb(248, 248, 248)",
            }}
          >
            <div style={{ display: "flex", justifyContent: "center", width: "100%", fontSize: "30px" }}>
              <LiaUserCircleSolid />
            </div>
            <div style={{ width: "100%", textAlign: "center", fontWeight: "600", fontSize: "15px", marginBottom: "16px" }}>
              {patient.name}
            </div>
            <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
              <div style={{ display: "flex", alignItems: "center", gap: "4px", width: "200px" }}>
                <HiMiniCake />
                {patient.dateOfBirth}
              </div>
              <div style={{ display: "flex", alignItems: "center", gap: "4px", width: "200px" }}>
                <IoMedical />
                {patient.diagnosticType === "CLINICAL" ? "Clinical" : "Non Clinical"}
              </div>
            </div>
            <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
              {patient.sex && (
                <div style={{ display: "flex", alignItems: "center", gap: "4px", width: "200px" }}>
                  <BiUserCircle />
                  {patient.sex}
                </div>
              )}
              {patient.nationalId && (
                <div style={{ display: "flex", alignItems: "center", gap: "4px", width: "200px" }}>
                  <LiaIdCardSolid />
                  {patient.nationalId}
                </div>
              )}
            </div>
            <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
              {patient.phoneNumber && (
                <div style={{ display: "flex", alignItems: "center", gap: "4px", width: "200px" }}>
                  <AiOutlineMobile />
                  {patient.phoneNumber}
                </div>
              )}
            </div>
          </div>
        );
      })}
    </div>
  );
};

interface DetailsViewPatientCardProps {
  patients: PatientModel[];
}

export default DetailsViewPatientCard;
