import { useMemo } from "react";
import BookingModel, { AssignedBookingNurseModel, BookingDateModel, NurseBookingDateModel } from "../models/BookingModel";
import moment from "moment";
import getStartMomentFromDateObj from "../utils/getStartMomentFromDateObj";

export type OngoingOrUpcomingNurse = { nurse: AssignedBookingNurseModel; dateObj?: NurseBookingDateModel };

export default function useCurrentBookingDateObj({ order }: { order: BookingModel }) {
  const dateObj = useMemo<BookingDateModel | undefined>(() => {
    const now = moment();
    const ongoingDateMoment = order.date?.find((dateObj) => {
      const startMoment = getStartMomentFromDateObj({ date: dateObj.date ?? "", time: dateObj.time ?? "" });
      const endMoment = startMoment.clone().add(dateObj.numberOfDurationOrSession ?? 0, "hour");
      return now.isSameOrAfter(startMoment) && now.isBefore(endMoment);
    });
    if (ongoingDateMoment) return ongoingDateMoment;
    const upcomingDateMoment = order.date?.find((dateObj) => {
      const momentObj = getStartMomentFromDateObj({ date: dateObj.date ?? "", time: dateObj.time ?? "" });
      // const endMoment = momentObj.clone().add(dateObj.numberOfDurationOrSession ?? 0, "hour");
      return momentObj.isSameOrAfter(now);
    });
    if (upcomingDateMoment) return upcomingDateMoment;
    const lastDate = order.date?.[(order.date?.length ?? 1) - 1];
    return lastDate;
  }, [order.date]);

  const currentAssignedNurse = useMemo<OngoingOrUpcomingNurse | null>(() => {
    const now = moment();
    let assignedNurse: OngoingOrUpcomingNurse | null = null;
    for (const nurse of order.assignedNurses ?? []) {
      const ongoingDateMoment = nurse.dateArray?.find((dateObj) => {
        const startMoment = getStartMomentFromDateObj({ date: dateObj.date ?? "", time: dateObj.time ?? "" });
        const endMoment = startMoment.clone().add(dateObj.duration ?? 0, "hour");
        return now.isSameOrAfter(startMoment) && now.isBefore(endMoment);
      });
      if (ongoingDateMoment) {
        return { nurse: nurse, dateObj: ongoingDateMoment };
      }
      const upcomingDateMoment = nurse.dateArray?.find((dateObj) => {
        const momentObj = getStartMomentFromDateObj({ date: dateObj.date ?? "", time: dateObj.time ?? "" });
        return momentObj.isAfter(now);
      });
      if (upcomingDateMoment) {
        assignedNurse = { nurse: nurse, dateObj: upcomingDateMoment };
      }
    }
    // if (!assignedNurse && order.assignedNurses?.length) {
    //   const nurse = order.assignedNurses[order.assignedNurses.length - 1];
    //   assignedNurse = { nurse: nurse, dateObj: nurse.dateArray?.[0] };
    // }
    return assignedNurse;
  }, [order.assignedNurses]);

  const assignedNurse = useMemo<AssignedBookingNurseModel | undefined>(() => {
    if (!order.assignedNurses?.length) return;
    // if (currentDateObj) {
    //   const is24HoursShift = currentDateObj.numberOfDurationOrSession === 24;
    //   const currentNurse = booking.assignedNurses.find((nurse) => {
    //     return nurse.dateArray?.some((dateObj) => dateObj.date === currentDateObj.date && dateObj.time === currentDateObj.time);
    //   });
    //   if (currentNurse) return currentNurse;
    // }
    let nurse: AssignedBookingNurseModel | undefined = undefined;
    for (const assignedNurse of order.assignedNurses ?? []) {
      if (!nurse?.dateArray?.length) {
        nurse = assignedNurse;
      } else if ((assignedNurse.dateArray?.length ?? 0) > (nurse?.dateArray?.length ?? 0)) {
        nurse = assignedNurse;
      }
    }
    return nurse;
  }, [order.assignedNurses]);

  return { dateObj, currentAssignedNurse, nurseWithMostDays: assignedNurse };
}
