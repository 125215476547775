import { Button, Dialog, DialogContent, DialogTitle } from "@mui/material";
import { FC, useCallback } from "react";
import { Link, useHistory, useParams, useRouteMatch } from "react-router-dom";
import ArrowForwardIosRoundedIcon from "@mui/icons-material/ArrowForwardIosRounded";
import VaccinesRoundedIcon from "@mui/icons-material/VaccinesRounded";
import BiotechRoundedIcon from "@mui/icons-material/BiotechRounded";
import Mixpanel from "../../utils/Mixpanel";

const SuggestionsModal: FC<SuggestionsModalProps> = () => {
  const { orderUUID } = useParams<{ orderUUID: string }>();
  const history = useHistory();
  const { url } = useRouteMatch();

  const handleClose = useCallback(() => {
    history.push(`/order/${orderUUID}`);
  }, [history, orderUUID]);

  return (
    <Dialog open={true} onClose={handleClose} fullWidth={true} maxWidth="sm">
      <DialogTitle sx={{ marginBottom: "2rem" }}>Suggestions</DialogTitle>
      <DialogContent
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          gap: "1rem",
          paddingBottom: "2rem",
          "& .MuiButtonBase-root": {
            minWidth: "300px",
          },
        }}
      >
        <Link to={`${url}/iv-drips`}>
          <Button
            disableElevation={true}
            variant="outlined"
            color="success"
            onClick={() => {
              Mixpanel.track("SuggestionsModal iv drips click", {
                partnerUser: localStorage.getItem("userName"),
                partnerUserUUID: localStorage.getItem("partnerUserUUID"),
              });
            }}
            startIcon={<VaccinesRoundedIcon />}
            endIcon={<ArrowForwardIosRoundedIcon />}
          >
            Suggest an IV Drip
          </Button>
        </Link>
        <Link to={`${url}/lab-tests`}>
          <Button
            variant="outlined"
            disableElevation={true}
            startIcon={<BiotechRoundedIcon />}
            endIcon={<ArrowForwardIosRoundedIcon />}
            onClick={() => {
              Mixpanel.track("SuggestionsModal lab tests click", {
                partnerUser: localStorage.getItem("userName"),
                partnerUserUUID: localStorage.getItem("partnerUserUUID"),
              });
            }}
          >
            Suggest a Lab Test
          </Button>
        </Link>
        <Button sx={{ color: "grey" }} onClick={handleClose}>
          Skip
        </Button>
      </DialogContent>
    </Dialog>
  );
};

interface SuggestionsModalProps {}

export default SuggestionsModal;
