import { FC, useCallback } from "react";
import LoadingButton from "@mui/lab/LoadingButton";
import BookingModel from "../../../models/BookingModel";
import { Tooltip } from "@mui/material";
import { useMutation } from "@tanstack/react-query";
import { acknowledgeDeliveryBooking } from "../../../services";
import { showMessage } from "../notification";
import Mixpanel from "../../../utils/Mixpanel";

const AcknowledgeDeliveryBooking: FC<AcknowledgeDeliveryBookingProps> = ({ height, booking, setBooking }) => {
  const acknowledgeBookingMutation = useMutation({
    mutationFn: acknowledgeDeliveryBooking,
  });

  const handleClicked = useCallback(async () => {
    const res = await acknowledgeBookingMutation.mutateAsync({
      orderUUID: booking?.orderUUID ?? "",
    });
    if (res?.success) {
      Mixpanel.track("Acknowledge WLP Booking", {
        orderUUID: booking?.orderUUID ?? "",
        partnerUser: localStorage.getItem("partnerUserUUID"),
      });
      setBooking((value) => {
        if (!value) return value;
        const newValue: BookingModel = { ...value };
        newValue.isDeliveryAcknowledged = true;
        return newValue;
      });
      showMessage("success", "Delivery was acknowledged successfully");
    } else {
      showMessage("error", "Something went wrong");
    }
  }, [acknowledgeBookingMutation, booking?.orderUUID, setBooking]);

  if (!booking?.orderUUID) return null;
  if (!booking?.isDeliveryOrder) return null;
  if (booking.isDeliveryAcknowledged) return null;
  if (booking.status !== "PROCESSING") return null;

  return (
    <Tooltip title="Acknowledge delivery">
      <LoadingButton
        sx={{ padding: "6px 8px", height: height, maxHeight: height }}
        loading={acknowledgeBookingMutation.isLoading}
        disabled={acknowledgeBookingMutation.isLoading}
        disableElevation={true}
        variant="contained"
        color="success"
        onClick={handleClicked}
      >
        Acknowledge Booking
      </LoadingButton>
    </Tooltip>
  );
};

interface AcknowledgeDeliveryBookingProps {
  booking?: BookingModel;
  height: string;
  setBooking: React.Dispatch<React.SetStateAction<BookingModel>>;
}

export default AcknowledgeDeliveryBooking;
