import { useQuery } from "@tanstack/react-query";
import { getPharmacyOrders } from "../services/pharmacy.orders.service";
import { useSelector } from "react-redux";
import { useMemo } from "react";
import ReduxStateModel from "../models/ReduxStateModel";
import { PharmacyBookingStatus } from "../models/PharmacyBookingModel";

function usePharmacyOrders(params: { status?: PharmacyBookingStatus }) {
  const partnerUUID = useSelector((state: ReduxStateModel) => state.authReducer?.setting?.partnerUUID);

  const queryKey = useMemo(
    () => ["pharmacy-orders/fetch-pharmacy-orders", params.status, partnerUUID],
    [params.status, partnerUUID]
  );

  const query = useQuery(queryKey, () => getPharmacyOrders({ partnerUUID: partnerUUID ?? "", status: params.status! }), {
    enabled: !!partnerUUID && !!params.status,
  });

  return { query, queryKey };
}

export default usePharmacyOrders;
