export const buildYourLabTestProductUUID = "77ece661-378e-470a-a197-df7d81efc49e";
export const trtVideoConsultationProductUUID = "dcb437b3-e40a-437c-9dc1-cc90afe5425a";
export const allowRecommendationsProductUUIDs = [
  "0f5ecf63-275a-45a8-81c6-cadde3edbc0f",
  trtVideoConsultationProductUUID,
  "a41e1618-d0b4-491f-87fa-b6fd761fbb37",
];
export const tractionTableunitColumnWidth = "50px";
export const tractionTableBookingIDColumnWidth = "120px";
export const tractionTableReceivedColumnWidth = "80px";
export const tractionTableHomeNursingProductNames = ["Newborn Care & Babysitting", "Child Care", "Adult & Elderly Care"];
export const tractionTablePhysioProductNames = ["Physio for Kids", "Physio for the Elderly", "Sports & Orthopedic Physio"];
export const tractionTableFilterOptions = [
  { key: "current-month", label: "Current Month" },
  { key: "previous-month", label: "Previous Month" },
  // { key: "last-3-month", label: "Last 3 Months" },
  // { key: "select-range", label: "Select range" },
];
export const tracktionTableCellWidth = "250px";
export const driverSchedulePageBackgroundColor = "#f5f5f5";
export const dayViewBookingHeight = 20;
export const encryptionKey = "w0HXiDp6baR2uLNRQ3FpVDePii3aeP7R";
export const minimumBookingHoursPerDay = 4;
export const dardocPartnerUUID = "6a6e48c3-38a5-462d-b943-3d34622590d0";
// export const showSchedulerParterUUIDs = [
//   dardocPartnerUUID,
//   "2fbb0c8f-eae5-403b-a4e0-abc8a4b8e180",
//   "dbd78668-ceed-4b87-aefd-17cb42827fee",
// ];
export const showDriverSchedulePartnerUUIDs = [
  dardocPartnerUUID,
  "dbd78668-ceed-4b87-aefd-17cb42827fee",
  "6a6e48c3-38a5-462d-b943-3d34622590d1",
  "af52730c-c6c1-4144-9b90-aa5110bb9d33",
  "f5c5c107-fa38-440f-8f71-8d32c7c3f57e",
  "bb0c0d55-7e07-486d-ba6a-28d72cdc5805",
];
export const ivDripProductUUID = "8597e64d-7afc-48dd-a79f-73076ac5ee73";
export const sideMenuCollapsedWidth = 80;
export const sideMenuExpandedWidth = 240;
export const qualificationMap = new Map(
  Object.entries({
    REGISTERED: "Registered Nurse",
    CERTIFIED: "Certified Caregiver",
    PHYSIOTHERAPIST: "Physiotherapist",
    TECHNICIAN: "Technician",
    PHYSICIAN: "Physician",
    MIDWIFE: "Midwife",
    "SLEEP COACH": "Sleep Coach",
    "SLEEP CONSULTANT": "Sleep Consultant",
    "LACTATION SPECIALIST": "Lactation Specialist",
    "LACTATION CONSULTANT": "Lactation Consultant",
    PHLEBOTOMIST: "Phlebotomist",
    NURSE_ASSISTANT: "Assistant Nurse",
    CLINICAL_DIETITIAN: "Clinical Dietitian",
  })
);
export const qualificationSkillsMap = new Map(
  Object.entries({
    CLINICAL_DIETITIAN: [],
    REGISTERED: [
      "Intensive Care",
      "Pediatric Care",
      "Respiratory Care (Mechanical Ventilation)",
      "Central line management",
      "Wound care (Complex wounds & VAC therapy)",
      "Tracheotomy care",
      "Hemodialysis",
      "Peritoneal dialysis",
      "Peripheral Intravenous Line Management",
    ],
    PHYSIOTHERAPIST: [
      "Pediatric Physio",
      "Geriatric Care",
      "Cardio & Pulmonary Physio",
      "Sports & Orthopedic Physio",
      "Neurological Physio",
    ],
    CERTIFIED: [
      "Newborn care activities",
      "Breast feeding support",
      "Childcare activities",
      "Autistic Care",
      "Down syndrome care",
      "Elderly Assisted Daily Living",
      "Bedridden Care",
      "Dementia & Alzheimer",
      "Parkinson Care",
      "Newborn care 0-3 months",
      "Newborn care >3 months",
    ],
    NURSING_ASSISTANT: [
      "Newborn care activities",
      "Breast feeding support",
      "Childcare activities",
      "Autistic Care",
      "Down syndrome care",
      "Elderly Assisted Daily Living",
      "Bedridden Care",
      "Dementia & Alzheimer",
      "Parkinson Care",
      "Newborn care 0-3 months",
      "Newborn care >3 months",
    ],
  })
);
export const colors = [
  "#d81b60",
  "#8e24aa",
  "#5e35b1",
  "#3949ab",
  "#1e88e5",
  "#00acc1",
  "#43a047",
  "#fdd835",
  "#fb8c00",
  "#6d4c41",
];
export const allowIVDripInventoryPartnerUUIDs = [
  "bb0c0d55-7e07-486d-ba6a-28d72cdc5805",
  "f9887193-0841-4cfe-86ff-6e4e8895e976",
  "e4c53224-1c94-4169-9638-cf4c72cf115b",
  "5bbc3a0a-593e-4cab-9d15-c170e13bdaac"
];
export const excelFileType = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
export const initialMapCenter = { lat: 24.44005128626116, lng: 54.41008347134661 };
export const allAbuDhabiAreas = [
  "Abu-Dhabi-A",
  "Abu-Dhabi-B",
  "Abu-Dhabi-C",
  "Abu-Dhabi-D",
  "Abu-Dhabi-E",
  "Abu-Dhabi-F",
  "Abu-Dhabi-G",
  "Abu-Dhabi-H",
];
export const allDubaiAreas = ["Dubai-A", "Dubai-B", "Dubai-C", "Dubai-D", "Dubai-E"];
export const allSharjahAreas = ["Sharjah-A"];
export const allAlAinAreas = ["Al-Ain"];
export const allRAKAreas = ["RAK"];
export const mapAreaToEmirate: { [key: string]: string } = {
  "Abu-Dhabi-A": "Abu Dhabi",
  "Abu-Dhabi-B": "Abu Dhabi",
  "Abu-Dhabi-C": "Abu Dhabi",
  "Abu-Dhabi-D": "Abu Dhabi",
  "Abu-Dhabi-E": "Abu Dhabi",
  "Abu-Dhabi-F": "Abu Dhabi",
  "Abu-Dhabi-G": "Abu Dhabi",
  "Abu-Dhabi-H": "Abu Dhabi",
  "Dubai-A": "Dubai",
  "Dubai-B": "Dubai",
  "Dubai-C": "Dubai",
  "Dubai-D": "Dubai",
  "Sharjah-A": "Sharjah",
  "Al-Ain": "Al Ain",
};
export const userRoleMap: { [name: string]: string } = {
  SUPER_ADMIN: "Super Admin",
  READ_WRITE: "Read & Write",
  READ: "Read",
};
export type TopBarTabType = "/pharmacy" | "/pharmacy/revenue-tracker";
export const mapTopBarTabToIndex: { [str: string]: number } = {
  "/pharmacy": 0,
  "/pharmacy/revenue-tracker": 1,
  // "driver-scheduler": 2,
  // staff: 3,
};
export const mapTopBarToTabName: { [index: number]: TopBarTabType } = {
  0: "/pharmacy",
  1: "/pharmacy/revenue-tracker",
  // 2: "driver-scheduler",
  // 3: "staff",
};
export const mapPharmacyOrderStatus: { [name: string]: string } = {
  PRESCRIPTION_UPLOADED: "New",
  DISPATCHED: "Dispatched",
  DELIVERED: "Delivered",
};
export const drawerWidth = 240;
export const wlpLabResultConsultationProductUUID = "a41e1618-d0b4-491f-87fa-b6fd761fbb37";
