import { FC, useMemo } from "react";
import { Autocomplete, Box, Button, TextField, Tooltip } from "@mui/material";
import PartnerInventoryListItemModel from "../models/PartnerInventoryListItemModel";
import useInventoryListQuery from "../hooks/useInventoryListQuery";
import LoadingSpinner from "./LoadingSpinner";
import RemoveCircleOutlineRoundedIcon from "@mui/icons-material/RemoveCircleOutlineRounded";
import useOutsourceInventoryItemsQuery from "../hooks/useOutsourceInventoryItemsQuery";

const AddInventoryRequestListItem: FC<AddInventoryRequestListItemProps> = ({ item, index, isOutsource, setItems }) => {
  const { query } = useInventoryListQuery();

  const { query: outsourceQuery } = useOutsourceInventoryItemsQuery();

  const options = useMemo<string[]>(() => {
    if (isOutsource) {
      return outsourceQuery.data?.items ?? [];
    }
    return query.data?.list?.map((item) => item.name ?? "") ?? [];
  }, [isOutsource, outsourceQuery.data?.items, query.data?.list]);

  return (
    <LoadingSpinner spinning={query.isLoading} showBackdrop={false} styles={{ minHeight: "50px" }}>
      <Box display={"flex"} gap={2} marginTop={3}>
        <Autocomplete
          fullWidth={false}
          sx={{ width: "50%" }}
          size="small"
          multiple={false}
          value={item.name ? item.name : null}
          loading={query.isLoading}
          onChange={(_, values) => {
            setItems((prev) => {
              const newItems = [...prev];
              newItems[index] = { ...newItems[index], name: values ?? "" };
              return newItems;
            });
          }}
          options={options}
          renderInput={(params) => <TextField {...params} label={"Select an item"} size="small" />}
        />
        <TextField
          size="small"
          placeholder="Quantity"
          label="Quantity (vials)"
          value={item.quantity}
          onChange={(e) => {
            const value = e.target.value;
            const numberValue = Number(value);
            if (isNaN(numberValue)) return;
            setItems((prev) => {
              const newItems = [...prev];
              newItems[index] = { ...newItems[index], quantity: numberValue };
              return newItems;
            });
          }}
        />
        <Tooltip title="Remove item">
          <Button
            variant="outlined"
            size="small"
            color="error"
            onClick={() => {
              setItems((prev) => prev.filter((_, i) => i !== index));
            }}
          >
            <RemoveCircleOutlineRoundedIcon />
          </Button>
        </Tooltip>
      </Box>
    </LoadingSpinner>
  );
};

interface AddInventoryRequestListItemProps {
  index: number;
  isOutsource?: boolean;
  item: PartnerInventoryListItemModel;
  setItems: React.Dispatch<React.SetStateAction<PartnerInventoryListItemModel[]>>;
}

export default AddInventoryRequestListItem;
