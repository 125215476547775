import { Form, Select } from "antd";
import { FC, useMemo } from "react";
import { useSelector } from "react-redux";
import ReduxStateModel from "../../models/ReduxStateModel";

const NurseServicesFormItem: FC<NurseServicesFormItemProps> = () => {
  const settings = useSelector((state: ReduxStateModel) => state?.authReducer?.setting);

  const allServices = useMemo(() => {
    const sortedservices = settings?.nonClinicalSellingPrice?.sort((a, b) => {
      if ((a.productName ?? "") < (b.productName ?? "")) return -1;
      if ((a.productName ?? "") > (b.productName ?? "")) return 1;
      return 0;
    });
    return sortedservices ?? [];
  }, [settings?.nonClinicalSellingPrice]);

  return (
    <Form.Item
      name="services"
      label="Services"
      className="professional-services-form-item"
      style={{ flex: "auto" }}
      rules={[{ required: true, message: "Please select professional services" }]}
    >
      <Select
        mode="multiple"
        style={{ width: "100%" }}
        filterOption={(input, option) => {
          return (option?.children?.toString() ?? "").toLowerCase().includes(input.toLowerCase());
        }}
      >
        {allServices?.map((service) => (
          <Select.Option value={service?.productUUID} key={service?.productUUID}>
            {service?.productName}
            {/* {service?.productName === "Covid-19 PCR Test" ? ` (${service?.productSpeciality?.engName})` : ""} */}
          </Select.Option>
        ))}
      </Select>
    </Form.Item>
  );
};

interface NurseServicesFormItemProps {
  // editMode: boolean;
}

export default NurseServicesFormItem;
