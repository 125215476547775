import type { ConfirmationResult } from "@firebase/auth";
import { RecaptchaVerifier, signInWithPhoneNumber } from "@firebase/auth";
import { useCallback, useState } from "react";
import useFireAuth from "./useFireAuth";

const useSendMobileNumberOTPWithFirebase = ({
  recaptchaElementID,
  recaptchaContainerID,
}: {
  recaptchaElementID: string;
  recaptchaContainerID: string;
}) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | undefined>();

  const { fireAuth } = useFireAuth();

  const sendPhoneOTP = useCallback<(mobileNumber: string) => Promise<ConfirmationResult | string>>(
    async (mobileNumber) => {
      if (!fireAuth) {
        console.log("no fireAuth error");
        return "Something went wrong";
      }
      setLoading(true);
      try {
        const verifier = new RecaptchaVerifier(fireAuth, recaptchaElementID, { size: "invisible" });
        const confirmationResult = await signInWithPhoneNumber(fireAuth, mobileNumber, verifier);
        reInitCaptchaDiv({
          captchaDivID: recaptchaElementID,
          containerID: recaptchaContainerID,
        });
        setLoading(false);
        return confirmationResult;
      } catch (e) {
        console.log("--- useSendMobileNumberOTPWithFirebase error");
        console.log(e);
        setLoading(false);
        reInitCaptchaDiv({
          captchaDivID: recaptchaElementID,
          containerID: recaptchaContainerID,
        });
        let errorMsg: string;
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        if ((e as any).code === "auth/invalid-phone-number") {
          errorMsg = "Invalid phone number";
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
        } else if ((e as any).code === "auth/too-many-requests") {
          errorMsg = "Too many requests";
        } else {
          errorMsg = "Something went wrong";
        }
        setError(errorMsg);
        return errorMsg;
      }
    },
    [fireAuth, recaptchaContainerID, recaptchaElementID]
  );

  return { sendPhoneOTP, loading, error };
};

function reInitCaptchaDiv({ captchaDivID, containerID }: { captchaDivID: string; containerID: string }) {
  document.getElementById(captchaDivID)?.remove();
  const newDiv = document.createElement("div");
  newDiv.id = captchaDivID;
  document.getElementById(containerID)?.appendChild(newDiv);
}

// type useSendMobileNumberOTPWithFirebaseType = (params: ) => {
//   sendPhoneOTP: (mobileNumber: string) => Promise<ConfirmationResult | string>;
//   loading: boolean;
//   error: string | undefined;
// };

export default useSendMobileNumberOTPWithFirebase;
