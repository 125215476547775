import { FC } from "react";
import { deliveryPartnerMenuItems } from "../../components/BasePageLayoutSideMenuItems";
import { Box } from "@mui/material";
import BasePageLayout from "../../components/BasePageLayout";
import RevenueHeader from "./RevenueHeader";
import RevenueTable from "./RevenueTable";

const DeliveryPartnerRevenue: FC = () => {
  return (
    <BasePageLayout
      mainContent={
        <Box padding={2}>
          <RevenueHeader />
          <RevenueTable />
        </Box>
      }
      sideMenuItems={deliveryPartnerMenuItems}
    />
  );
};

export default DeliveryPartnerRevenue;
