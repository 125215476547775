import { useEffect } from "react";

export default function useLeavePageAlert({ alertMessage }: { alertMessage?: string }) {
  useEffect(() => {
    const handleWindowClose = (event: BeforeUnloadEvent) => {
      event.preventDefault();
      const confirmationMessage = alertMessage ?? "Are you sure you want to leave this page ?";
      return confirmationMessage;
    };
    window.addEventListener("beforeunload", handleWindowClose);
    return () => {
      window.removeEventListener("beforeunload", handleWindowClose);
    };
  }, [alertMessage]);
}
