import React, { useState, useMemo, useEffect } from "react";
import moment from "moment";
import { Button, Col, Row } from "antd";
import { LeftOutlined, RightOutlined } from "@ant-design/icons";
import { generateRangeArray } from "../../../utils/utilFunctions";

const defaultDateFormat = "DD/MM/YYYY";

const MonthCalendar: React.FC<MonthCalendarProps> = ({
  title,
  mutipleSelect,
  dateFormat,
  enabledDates,
  width,
  initialSelectedDates,
  lastCalendarSelectableMoment,
  firstSelectableMoment,
  // style,
  selectedDatesFromProps,
  onSelectedDatesChanged,
  onDateClicked,
}) => {
  const [selectedMonth, setSelectedMonth] = useState<moment.Moment>(moment().startOf("month"));
  const [selectedDates, setSelectedDates] = useState<Map<number, boolean>>(new Map());
  const [enabledDatesMap, setEnabledDatesMap] = useState<Map<string, boolean>>(new Map());
  const firstCalendarDay = useMemo(() => selectedMonth.clone().startOf("week"), [selectedMonth]);

  useEffect(() => {
    if (!initialSelectedDates || !initialSelectedDates.length) {
      setSelectedDates((prevValue) => {
        if (!prevValue.size) return prevValue;
        return new Map<number, boolean>();
      });
    } else {
      const selected = new Map<number, boolean>();
      for (const date of initialSelectedDates) {
        selected.set(moment(date, "DD/MM/YYYY").valueOf(), true);
      }
      setSelectedDates(selected);
    }
  }, [initialSelectedDates]);

  useEffect(() => {
    if (!enabledDates || !enabledDates.length) return;
    const map = new Map<string, boolean>();
    for (const date of enabledDates) {
      map.set(date, true);
    }
    setEnabledDatesMap(map);
  }, [enabledDates]);

  useEffect(() => {
    if (onSelectedDatesChanged) {
      onSelectedDatesChanged(Array.from(selectedDates.keys()).map<moment.Moment>((value) => moment(value)));
    }
  }, [selectedDates, onSelectedDatesChanged]);

  const handleDateSelected = (date: moment.Moment) => {
    if (onDateClicked) {
      return onDateClicked(date);
    }
    setSelectedDates(() => {
      const newMap = new Map<number, boolean>();
      if (mutipleSelect) {
        for (const key of Array.from(selectedDates.keys())) {
          newMap.set(key, selectedDates.get(key) ?? false);
        }
      }
      if (newMap.get(date.valueOf())) {
        newMap.delete(date.valueOf());
      } else {
        newMap.set(date.valueOf(), true);
      }
      return newMap;
    });
  };

  return (
    <Row
      style={{
        gap: "1rem",
        width: width ?? "600px",
      }}
    >
      {title ?? (
        <Col span={24}>
          <p style={{ margin: "0px" }}>Please select the date</p>
        </Col>
      )}
      <Col span={24}>
        <Row
          style={{
            backgroundColor: "white",
            borderRadius: "10px",
            boxShadow: "rgb(0 0 0 / 10%) 0px 1px 8px 0px, rgb(0 0 0 / 6%) 0px 1px 2px 0px",
            padding: "1rem 1rem",
            gap: "1rem",
          }}
        >
          <Col span={24}>
            <Row
              style={{
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Col style={{ width: "120px" }}>
                <div
                  className="month-date"
                  style={{
                    fontSize: "1.2rem",
                    fontWeight: "bold",
                  }}
                >
                  {selectedMonth.format("MMMM")}
                </div>
              </Col>
              <Col>
                <Button
                  disabled={
                    firstSelectableMoment
                      ? selectedMonth
                          .clone()
                          .subtract(1, "month")
                          .startOf("month")
                          .isBefore(firstSelectableMoment?.startOf("month"))
                      : false
                  }
                  className="prev-month-btn"
                  type="text"
                  onClick={() => {
                    setSelectedMonth((prevState) => prevState.clone().subtract(1, "month"));
                  }}
                >
                  <LeftOutlined />
                </Button>
              </Col>
              <Col>
                <Button
                  disabled={
                    lastCalendarSelectableMoment
                      ? selectedMonth.clone().add(1, "month").isAfter(lastCalendarSelectableMoment)
                      : false
                  }
                  className="next-month-btn"
                  type="text"
                  onClick={() => {
                    setSelectedMonth((prevState) => prevState.clone().add(1, "month"));
                  }}
                >
                  <RightOutlined />
                </Button>
              </Col>
            </Row>
          </Col>
          <Col span={24}>
            <Row
              style={{
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              {generateRangeArray({ start: 0, end: 6 }).map<JSX.Element>((item) => {
                const date = firstCalendarDay.clone().add(item, "day");
                return (
                  <Col
                    key={date.valueOf()}
                    style={{
                      width: "14.2%",
                    }}
                  >
                    {date.format("ddd")}
                  </Col>
                );
              })}
            </Row>
          </Col>
          <Col span={24}>
            <Row
              style={{
                justifyContent: "space-between",
                flexWrap: "wrap",
              }}
            >
              {generateRangeArray({ start: 0, end: 41 }).map<JSX.Element>((item) => {
                const date: moment.Moment = firstCalendarDay.clone().add(item, "day");

                let isDisabled: boolean;
                if (enabledDatesMap.size) {
                  if (
                    enabledDatesMap.get(date.format(dateFormat ?? defaultDateFormat)) &&
                    date.isSameOrAfter(firstSelectableMoment)
                  ) {
                    isDisabled = false;
                  } else {
                    isDisabled = true;
                  }
                } else if (firstSelectableMoment && date.isBefore(firstSelectableMoment)) {
                  isDisabled = true;
                } else {
                  isDisabled = false;
                }

                const selectedFromProps = selectedDatesFromProps?.get(date.valueOf()) ?? false;
                const selectedFromState = selectedDates.get(date.valueOf()) ?? false;
                const selected = !isDisabled && (selectedFromState || selectedFromProps);
                let color: string;
                if (selected) {
                  color = "white";
                } else if (!isDisabled) {
                  color = "black";
                } else {
                  color = "#dadada";
                }
                const hideDate = date.isAfter(selectedMonth.clone().endOf("month")) || date.isBefore(selectedMonth);
                return (
                  <Col
                    key={date.valueOf()}
                    onClick={isDisabled ? undefined : () => handleDateSelected(date)}
                    style={{
                      visibility: hideDate ? "hidden" : "visible",

                      width: "14.2%",
                      aspectRatio: "1",
                      color: color,
                      cursor: !isDisabled ? "pointer" : "default",
                      padding: "1rem",
                    }}
                  >
                    <div
                      style={{
                        transition: "all 0.2s ease-out",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        backgroundColor: selected ? "#25bcbd" : "",
                        borderRadius: selected ? "50%" : "",
                        width: "100%",
                        height: "100%",
                        lineHeight: "1",
                        minWidth: "26px",
                        minHeight: "26px",
                        maxWidth: "26px",
                        maxHeight: "26px",
                      }}
                    >
                      {date.format("DD")}
                    </div>
                  </Col>
                );
              })}
            </Row>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

interface MonthCalendarProps {
  title?: JSX.Element;
  disabledDates?: string[];
  enabledDates?: string[];
  initialSelectedDates?: string[];
  mutipleSelect?: boolean;
  dateFormat?: string;
  width?: string;
  style?: React.CSSProperties;
  firstSelectableMoment?: moment.Moment;
  lastCalendarSelectableMoment?: moment.Moment;
  selectedDatesFromProps?: Map<number, boolean>;
  onSelectedDatesChanged?: (dates: moment.Moment[]) => void;
  onDateClicked?: (date: moment.Moment) => void;
}

export default MonthCalendar;
