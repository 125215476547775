import { useQueryClient } from "@tanstack/react-query";
import { Button, Modal } from "antd";
import { FC } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { getNursePatientsList, NursesDatum, SlotPatientModel } from "../../models/SchedulerExploreViewResponseModel";

const MarkLeaveOrAssignNurseModal: FC = () => {
  const selectedWeek: moment.Moment = useSelector((state: any) => state?.schedulerExploreViewReducer?.selectedWeek);
  const history = useHistory<any>();
  const queryClient = useQueryClient();
  const locationState: { nurseData: NursesDatum; patientsList: SlotPatientModel[]; initialSelectedDate: number } =
    history.location?.state;
  const nurseData = locationState?.nurseData;

  const handleAssignNurseClicked = () => {
    queryClient.setQueryData(
      [
        "nursetimeslots/getparticularnursedatawithtimeslot",
        nurseData.nurseUUID,
        selectedWeek.clone().startOf("week").set("second", 0).set("millisecond", 0).valueOf(),
      ],
      { nurseData: [nurseData] }
    );
    history.replace(`/scheduler/assign-patients`, {
      initialSelectedDate: locationState?.initialSelectedDate,
      nurseUUID: nurseData.nurseUUID,
      patientsList: getNursePatientsList(nurseData),
      nurseData: nurseData,
    });
  };

  const handleMarkAsLeaveClicked = () => {
    history.replace(`/scheduler/mark-nurse-as-on-leave`, {
      initialSelectedDate: locationState?.initialSelectedDate,
      nurseUUID: nurseData.nurseUUID,
      patientsList: getNursePatientsList(nurseData),
      nurseData: nurseData,
    });
  };

  return (
    <Modal
      className="mark-leave-or-assign-nurse-modal"
      title="Would you like to assign the nurse or make them on leave ?"
      width={600}
      footer={null}
      maskClosable={false}
      centered={true}
      open={true}
      onCancel={() => {
        history.replace("/scheduler");
      }}
    >
      <div className="modal-container" style={{ display: "flex", gap: "16px", justifyContent: "flex-end" }}>
        <Button onClick={handleMarkAsLeaveClicked}>Mark as leave</Button>
        <Button type="primary" onClick={handleAssignNurseClicked}>
          Assign nurse
        </Button>
      </div>
    </Modal>
  );
};

export default MarkLeaveOrAssignNurseModal;
