import RenderGoogleMapsWrapper from "../../components/common/RenderGoogleMapsWrapper";
import StaffProfileAddressSearchInput from "../NurseProfile/StaffProfileAddressSearchInput";
import EmiratesIDFrontFormItem from "./EmiratesIDFrontFormItem";
import EmiratesIDBackFormItem from "./EmiratesIDBackFormItem";
import PassportFormItem from "./PassportFormItem";
import DrivingLicenseFormItem from "./DrivingLicenseFormItem";
import ShiftStartTimeFormItem from "./ShiftStartTimeFormItem";
import ShiftEndTimeFormItem from "./ShiftEndTimeFormItem";
import BreakStartTime from "./BreakStartTime";
import BreakEndTime from "./BreakEndTime";
import { Wrapper } from "@googlemaps/react-wrapper";
import { Form, FormInstance, InputNumber } from "antd";
import { Dispatch, FC, SetStateAction } from "react";
import { useSelector } from "react-redux";
import { StaffMemberModel } from "../../models/StaffMemberModel";
import { DriverForm } from "./OldDriverProfile";
import DriverActiveDaysFormItem from "./DriverActiveDaysFormItem";
import ReduxStateModel from "../../models/ReduxStateModel";
import DriverPriorityFormItem from "./DriverPriorityFormItem";
import useAppSettings from "../../hooks/useAppSettings";
import { Box } from "@mui/material";

const ProfessionalDetailsTab: FC<ProfessionalDetailsTabProps> = ({ staffMember, setMember, form }) => {
  const { query } = useAppSettings();
  const googleAPIKey = query.data?.key?.googleKey;
  const partnerUUID = useSelector((state: ReduxStateModel) => state?.authReducer?.setting?.partnerUUID);

  return (
    <div style={{ display: "flex", flexDirection: "column", width: "100%" }}>
      <div style={{ display: "flex", gap: "16px", width: "100%" }}>
        {partnerUUID && googleAPIKey && (
          <Wrapper render={RenderGoogleMapsWrapper} libraries={["places"]} apiKey={googleAPIKey}>
            <StaffProfileAddressSearchInput
              label="Base Location"
              showRequiredMark={true}
              profile={staffMember}
              setProfile={setMember}
            />
          </Wrapper>
        )}
        <Form.Item
          name="capacity"
          label="Vehicle capacity"
          rules={[{ required: true, message: "Please enter the vehicle capacity" }]}
        >
          <InputNumber className="driver-capacity-input" />
        </Form.Item>
      </div>
      <DriverPriorityFormItem driver={staffMember} />
      <div style={{ display: "flex", gap: "16px", width: "100%" }}>
        <ShiftStartTimeFormItem form={form} />
        <ShiftEndTimeFormItem form={form} />
      </div>
      <DriverActiveDaysFormItem />
      <div style={{ display: "flex", gap: "16px", width: "100%" }}>
        <BreakStartTime form={form} />
        <BreakEndTime form={form} />
      </div>
      <div style={{ display: "flex", gap: "16px", width: "100%" }}>
        <Box width={{ xs: "100%", md: "50%" }}>
          <EmiratesIDFrontFormItem driver={staffMember} setDriver={setMember} form={form} />
        </Box>
        <Box width={{ xs: "100%", md: "50%" }}>
          <EmiratesIDBackFormItem driver={staffMember} setDriver={setMember} form={form} />
        </Box>
      </div>
      <div style={{ display: "flex", gap: "16px", width: "100%" }}>
        <Box width={{ xs: "100%", md: "50%" }}>
          <PassportFormItem driver={staffMember} setDriver={setMember} form={form} />
        </Box>
        <Box width={{ xs: "100%", md: "50%" }}>
          <DrivingLicenseFormItem driver={staffMember} setDriver={setMember} form={form} />
        </Box>
      </div>
    </div>
  );
};

interface ProfessionalDetailsTabProps {
  staffMember: StaffMemberModel;
  // handleDriverUpdatedSuccessfully: (nurse: StaffMemberModel) => void;
  setMember: Dispatch<SetStateAction<StaffMemberModel>>;
  form: FormInstance<DriverForm>;
}

export default ProfessionalDetailsTab;
