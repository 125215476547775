import { useQuery } from "@tanstack/react-query";
import { getPartnerDetails } from "../services/partner.service";
import { useMemo } from "react";

function usePartnerDetails({ partnerUUID }: { partnerUUID?: string }) {
  const queryKey = useMemo(() => ["partners/getparticularpartner", partnerUUID], [partnerUUID]);

  const query = useQuery(queryKey, () => getPartnerDetails({ partnerUUID: partnerUUID ?? "" }), {
    enabled: !!partnerUUID,
    refetchOnWindowFocus: false,
  });

  return { query, queryKey };
}

export default usePartnerDetails;
