import { FC } from "react";
import { Redirect } from "react-router-dom";

const AuthOnlyRoute: FC<{ child: JSX.Element }> = ({ child }) => {
  const settings = localStorage.getItem("setting");

  if (!settings) {
    return <Redirect to="/login" />;
  }

  return child;
};

export default AuthOnlyRoute;
