import UploadFilePayloadModel from "../../models/UploadFilePayloadModel";
import { Form, FormInstance, Upload } from "antd";
import { FC, useEffect } from "react";
import { StaffMemberModel } from "../../models/StaffMemberModel";
import { getBase64 } from "../../utils/utilFunctions";

const PassportFormItem: FC<PassportFormItemProps> = ({ form, driver, setDriver }) => {
  const value = Form.useWatch("passportImageUrl", form);

  useEffect(() => {
    if (!value) return;
    (async () => {
      const base64 = await getBase64(value.file);
      const imageObj: UploadFilePayloadModel = {
        data: `data:${value.file.type};base64,${base64}`,
        fileName: value.file.name,
        type: value.file.type ?? "",
      };
      setDriver((prevValue) => {
        const newValue: StaffMemberModel = { ...prevValue };
        newValue.passport = imageObj;
        return newValue;
      });
    })();
  }, [setDriver, value]);

  useEffect(() => {
    if (driver.passport?.data) {
      form.validateFields(["passportImageUrl"]);
    }
  }, [driver.passport?.data, form]);

  const imgSrc = driver.passport?.data ?? driver.passportImageUrl;

  return (
    <Form.Item
      className="upload-image-form-item"
      name="passportImageUrl"
      label="Passport"
      // style={{ width: "45%", maxWidth: "45%" }}
      style={{ flex: "auto" }}
      // required={true}
      // rules={[
      //   () => ({
      //     validator() {
      //       if (!driver.passportImageUrl && !driver.passport?.data) {
      //         return Promise.reject("Please select the passport image");
      //       }
      //       return Promise.resolve();
      //     },
      //   }),
      // ]}
    >
      <Upload.Dragger showUploadList={false} accept="image/*" beforeUpload={() => false}>
        <div
          style={{
            borderRadius: "4px",
            width: "100%",
            aspectRatio: "16 / 9",
          }}
        >
          {imgSrc && (
            <img
              style={{
                width: "100%",
                height: "100%",
                objectFit: "cover",
                borderRadius: "4px",
              }}
              src={imgSrc}
              alt="Passport"
            />
          )}
        </div>
      </Upload.Dragger>
    </Form.Item>
  );
};

interface PassportFormItemProps {
  // editMode: boolean;
  form: FormInstance;
  driver: StaffMemberModel;
  setDriver: React.Dispatch<React.SetStateAction<StaffMemberModel>>;
}

export default PassportFormItem;
