import { useQuery } from "@tanstack/react-query";
import { checkIfAreaIsServicable } from "../services";
import { useMemo } from "react";

export default function useAreaServicableQuery(params: { lat: string; lng: string }) {
  const queryKey = useMemo(() => {
    return ["users/checktheareaisserviceable", params.lat, params.lng];
  }, [params.lat, params.lng]);

  const query = useQuery(queryKey, () => checkIfAreaIsServicable(params), {
    refetchOnWindowFocus: false,
    staleTime: Infinity,
    cacheTime: Infinity,
  });

  return { query };
}
