import { useQuery } from "@tanstack/react-query";
import { nurseService } from "../services";
import { useMemo } from "react";

function useNurseReviews(nurseUUID: string | undefined) {
  const queryKey = useMemo(() => ["darflowusers/nursereview", nurseUUID], [nurseUUID]);

  const query = useQuery(queryKey, () => nurseService.getNurseReview(nurseUUID), {
    enabled: !!nurseUUID,
  });

  return { query, queryKey };
}

export default useNurseReviews;
