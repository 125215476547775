import { FC, useState } from "react";
import { Document, Page } from "react-pdf";
import "react-pdf/dist/Page/AnnotationLayer.css";
import "react-pdf/dist/Page/TextLayer.css";

// pdfjs.GlobalWorkerOptions.workerSrc = new URL("pdfjs-dist/build/pdf.worker.min.js", import.meta.url).toString();

const options = {
  cMapUrl: "/cmaps/",
  standardFontDataUrl: "/standard_fonts/",
};

const PDFView: FC<PDFViewProps> = ({ url, maxHeight, numberOfPagesToShow }) => {
  const [numberOfPages, setNumberOfPages] = useState(0);
  //   function onDocumentLoadSuccess({ numPages: nextNumPages }: PDFDocumentProxy): void {
  //     setNumberOfPages(nextNumPages);
  //   }

  return (
    <>
      <Document
        file={url}
        options={options}
        onLoadSuccess={(doc) => {
          setNumberOfPages(doc.numPages);
        }}
      >
        {Array.from(new Array(numberOfPagesToShow ?? numberOfPages), (_el, index) => (
          <Page key={`page_${index + 1}`} pageNumber={index + 1} height={maxHeight ?? 500} />
        ))}
      </Document>
    </>
  );
};

interface PDFViewProps {
  url: string;
  maxHeight?: number;
  numberOfPagesToShow?: number;
}

export default PDFView;
